import { faFileExport, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MuiThemeProvider } from '@material-ui/core';
import MaterialTable, { MTableBody } from 'material-table';
import { TableCell, TableFooter, TableRow } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import util from '../../../utils/miniUtils';
import { RECRUITER_ROLE } from "../../../../constants";
import SidebarFilter from '../../shared/SidebarFilter';
// import useReport from './useReport';

const NewRecuiterReport = () => {
    // const { totalsRow } = useReport();
    const [data, setData] = useState( [] );
    const [statusColumns, setStatusColumns] = useState( [] );
    const [parentStatusList, setParentStatusList] = useState( [] );
    const [total, setTotal] = useState( {} );
    const [filterData, setFilterData] = useState( {
        firstTime: true,
        recruiters:[],
        countries: [],
        clients:[],
        partners:[],
        sales_lead: [],
        startDate: new Date( new Date().setDate( new Date().getDate() - 0 ) ).toISOString().split( "T" )[0],
        endDate: new Date().toISOString().split( "T" )[0],
    } );

    // calculate total value for different status
    function getTotal( arr, statusList ) {
        const result = statusList.reduce( ( acc, status ) => {
            acc[status.jts_id] = [0, status.order];
            return acc;
        }, { submittedToday: 0 } );
      
        Object.keys( result ).forEach( ( key ) => {
            if ( key !== 'submittedToday' ) {
                result[key][0] = arr.reduce( ( sum, item ) => {
                    return sum + item[key];
                }, 0 );
            } else {
                result[key] = arr.reduce( ( sum, item ) => {
                    return sum + item[key];
                }, 0 );
            }
        } );
        setTotal( result );
        return result;
    }

    const getStatusList = async () => {
        try{
            const request = await util.axios.get( '/job_talent_status/list/parentStatus' );
            const { data, error, msg } = request.data;
            if( error ) throw msg;
            setStatusColumns( data.length > 0 ? data.map( el => ( { width:"5%", title: el.name, field: el.jts_id, hidden:false,
                hiddenByColumnsButton:false, render: d => {
                    return <div className="text-center">{d[el.jts_id]}</div>;
                } } ) ) : [] );
            setParentStatusList( data );
        } catch ( e ) {
            util.handleError( e );
        }
    };

    const getFilterData = async () => {
        try {
            const request = await util.axios.get( `dashboard/filters_values` );
            const { error, msg, recruiters, countries, clients, partners, sales_lead } = request.data;
            if ( error ) throw  msg;
            setFilterData( { ...filterData, firstTime: false, recruiters, countries, clients, partners, sales_lead } );
        }catch ( e ) {
            util.handleError( e );
        }
    };

    const applyFilter = async ( type ) => {
        // Put only required data in dataObject to filter the table
        let dataObject = { 
            recruiters: filterData.recruiters.filter( item => { if ( item.checked === true ) return item.id;} ),
            country: filterData.countries.filter( item => { if ( item.checked === true ) return item.value;} ),
            partners: filterData.partners.filter( item => { if ( item.checked === true ) return item.id; } ),
            clients: filterData.clients.filter( item => { if ( item.checked === true ) return item.id; } ),
            sales_lead: filterData.sales_lead.filter( item => { if ( item.checked === true ) return item.id; } ),
            startDate: filterData.startDate + " 00:00:00",
            endDate: filterData.endDate + " 23:59:59",
        };
        // In the case that the user is a recruiter, will send only hes OWN ID in the recruiters property
        if ( util.user.role === RECRUITER_ROLE ){
            dataObject.recruiters = [{ id: util.user.id }];
        }

        try {
            let url = `dashboard/report_recruiter_by_status`;
            if ( type === "jobs" ) {
                url = `dashboard/report_jobs`;
                dataObject.sales_lead = dataObject.recruiters;
                delete dataObject.recruiters;
            }
            const request = await util.axios.post( url, dataObject );
            let { error, msg, result } = request.data;
            if ( error ) throw  msg;
            setData( result );
        }catch ( e ) {
            util.handleError( e );
        }
    };

    const handleChange = ( e ) => {
        let { name, value } = e.target;
        setFilterData( { ...filterData, [name]: value } );
    };

    const updatedNewData = ( field, newData ) => {
        setFilterData( { ...filterData, [field]: newData } );
    };

    useEffect( () => {
        // Will run applyFilter() until the filterData is filled with API information        
        getStatusList();
        getFilterData();
    },[ ] );

    useEffect( () => {
        if( !filterData.firstTime ){
            applyFilter();  
        }
    },[ filterData ] );

    useEffect( () => {
        if( data.length && parentStatusList.length )
            getTotal( data, parentStatusList );
    },[data, parentStatusList] );

    const totalStatusData = () => {
        const sortedKeys = Object.keys( total ).sort( ( a, b ) => {
            return total[a][1] - total[b][1];
        } );
        return sortedKeys.map( ( key ) => {
            // Skip the 'submittedToday' property
            if ( key === "submittedToday" ) {
                return null;
            }
            return <TableCell key={key} colSpan={1} className='text-dark text-center'>{total[key][0]}</TableCell>;
        } );
    };

    return (
        <Fragment> 
            <div className="container-fluid mt-2">
                <div className="row">
                    <div className="col-md-2">
                        <Card elevation={1} className="p-2">
                            <SidebarFilter filterData={filterData} handleChange={handleChange} updatedNewData={updatedNewData} />
                        </Card>
                    </div>
                    <div className="col-md-10">
                        <Card elevation={1} className="p-2">
                            <MuiThemeProvider theme={util.defaultTableTheme}>
                                <MaterialTable
                                    columns={
                                        [
                                            { width:"30%", title: 'Country', field: 'job_country', hidden:false,
                                                hiddenByColumnsButton:false,
                                                render: d => {
                                                    return (
                                                        <div className="d-flex align-items-center">
                                                            <img width={25} src={util.countryFlag( d.job_country )} alt={d.job_country} className="mr-1"/>
                                                            { d.job_country }
                                                        </div>
                                                    );
                                                } },
                                            { width:"5%", title: 'JobID', field: 'job_id', hidden:false,
                                                hiddenByColumnsButton:false,
                                                render: data => {
                                                    return(
                                                        <a href={`/jobs/position/detail/${data.job_id}`} target="new">
                                                            {util.zeroPad( data.job_id, 5 )}
                                                        </a>
                                                    );
                                                }
                                            },
                                            { width:"50%", title: 'Job Title', field: 'job_title', hidden:false,
                                                hiddenByColumnsButton:false, },
                                            { width:"5%", title: 'Lead Recruiter', field: 'leadRecruiterName',  hidden:false,
                                                hiddenByColumnsButton:false, },
                                            { width:"5%", title: 'Sales Lead', field: 'salesLeadName', hidden:false,
                                                hiddenByColumnsButton:false, },
                                            { width:"5%", title: 'Submitted Today', field: 'submittedToday', hidden:false,
                                                hiddenByColumnsButton:false, render: d =>  <div className="text-center">{d.submittedToday}</div> },
                                            // { width:"5%", title: <span>Submitted To Date</span>, field: 'submittedToDate', render: d =>  {
                                            //     return <div className="text-center">{d.submittedToDate}</div>;
                                            // } },
                                            ...statusColumns,
                                            { width:"30%", title: 'Onboarded Talents', field: 'onBoardedTalents', hidden:false,
                                                hiddenByColumnsButton:false,
                                                render: d =>  {
                                                    return (
                                                        <ol className="text-left">
                                                            { 
                                                                d.onBoardedTalents.map( ( o, i ) => {
                                                                    return (
                                                                        <li key={i * Math.random() }>
                                                                            {o.name} 
                                                                        </li>
                                                                    );
                                                                } )
                                                            }
                                                        </ol>
                                                    );
                                                } },
                                        ]
                                    }
                                    data={ data }
                                    components={{
                                        Body: ( props ) => (
                                            <>
                                                <MTableBody {...props} />
                                                <TableFooter>
                                                    <TableRow>
                                                        <TableCell colSpan={4}/>
                                                        <TableCell colSpan={1} className='text-dark text-center'><strong>Total:</strong></TableCell>
                                                        <TableCell colSpan={1} className='text-dark text-center'>{total?.submittedToday}</TableCell>
                                                        {totalStatusData()}
                                                    </TableRow>
                                                </TableFooter>
                                            </>
                                        ),
                                    }}
                                    options={{
                                        searchFieldAlignment:"left",
                                        toolbarButtonAlignment:'left',
                                        emptyRowsWhenPaging: false,
                                        pageSizeOptions:[20,50,100],
                                        pageSize: 50,
                                        tableLayout:"auto",
                                        columnsButton:true,
                                        showTitle: false,
                                        headerStyle:{ position:'sticky',top:0 },
                                        maxBodyHeight:'525px',
                                    }}
                                    actions={[
                                        {
                                            icon: () => <div className={"btn btn-success btn-sm"}>
                                                <FontAwesomeIcon icon={faFileExport} className="mr-2" />
                                Export All
                                            </div>,
                                            tooltip: "Export All",
                                            isFreeAction: true,
                                            onClick: () => util.DownloadTableAsCsv( 'MuiTable-root', 1, "RecruiterReport" ),
                                        },
                                        {
                                            icon: () => <FontAwesomeIcon icon={faSync} style={{ width:'15px' }} />,
                                            tooltip: "Reload Data",
                                            isFreeAction: true,
                                            onClick: () => applyFilter( ),
                                        }
                                    ]}
                                />
                            </MuiThemeProvider>
                        </Card>
                    </div>
                    {/* <div className="col-md-2">
                        <FunnelGraph totals={ funnelTotals( data ) } />
                    </div> */}
                </div>
            </div>
        </Fragment>
    );
};

export default NewRecuiterReport;