import React from 'react';

const ScreeningComments = () => {
    const comments = [
        {
            id:1,
            author: "author 1",
            date: "Date",
            time:"time",
            comment: 'Lorem Ipsum is essentially unchanem'
        },
        {
            id:2,
            author: "author 2",
            date: "Date",
            time:"time",
            comment: 'Lorem Ipsum is essentially unchanem'
        },
        {
            id:3,
            author: "author 3",
            date: "Date",
            time:"time",
            comment: 'Lorem Ipsum is essentially unchanem'
        },
        {
            id:4,
            author: "author 4",
            date: "Date",
            time:"time",
            comment: 'Lorem Ipsum is essentially unchanem'
        },
    ];
    return (
        <div className='screening-comment'>
            {comments.map( ( item ) => 
                ( 
                    <div key={item.id} className="screening-comments">
                        
                        <div className='screening-content'>
                            <div className='screening-comment-image' ><img alt='img' src=''  /></div>

                            <div className='screening-content-author'>{item.author}</div>
                            <div className='screening-content-comment'>{item.comment}</div>
                        </div>
                        <div className='screening-timestamp' >
                            <div className="screening-question-date">{item.date}</div>
                            <div className="screening-question-date">{item.time}</div>
                        </div>
                    </div>

                )
            )  }
        </div>
    );
};

export default ScreeningComments;