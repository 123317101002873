import React, { Fragment, useEffect, useState } from "react";
import util from "../../../utils/miniUtils";
import Checkbox from "@material-ui/core/Checkbox";
import { ResumeStore } from "./ResumeStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faPlusCircle, faSave, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import SAlert from "../../../microComponents/AlertSA";
import LocationCX from "../../../shared/LocationCX";

function WorkHistoryResume() {

    const data = ResumeStore.useState( s => s.data );
    
    return(
        <Fragment>
            <div className="container">
                <br/>
                {data.workHistory !== undefined && data.workHistory.length > 0 && data.workHistory.map( ( w,i ) =>{
                    return <div className="row" key={i}>
                        <WorkHistorySingle data={w} index={i}  />
                        <hr/>
                    </div>;
                } )}
                <hr/>
                <AddWorkHistory />
            </div>
        </Fragment>
    );
}

function AddWorkHistory( props ) {

    const [show, setShow] = useState( false );
    const [showConfirmation, setShowConfirmation] = useState( false );
    const [data, setData] = useState( {
        company:"",
        position:"",
        desc:"",
        from:"",
        to:"",
        location:"",
        actual_job:0,
    } );

    const dataX = ResumeStore.useState( s => s.data );
    const datas = ResumeStore.useState( s => s.datas );
    const index = ResumeStore.useState( s => s.index );

    function onChangeHandler( e, index ) {
        let { name, value } = e.target;
        if ( name === "actual_job" ){
            value = e.target.checked ? 1:0;
        }
        let tempData = { ...data };
        tempData[name] = value;
        setData( tempData );
    }

    function cancelNewWH() {
        setData( {
            company:"",
            position:"",
            desc:"",
            from:"",
            to:"",
            location:"",
            actual_job:0,
        } );
        setShow( false );
    }

    function saveNWH() {
        try{
            // Validate the wh is filled properly
            if ( data.company === "" ) throw new Error( "Insert a company name" );
            if ( data.position === "" ) throw new Error( "Insert a position in the company" );
            if ( data.from === "" ) throw new Error( "Select start date" );
            if ( data.actual_job === 0 ){
                if ( data.to === "" ) throw new Error( "Select end date" );
            }
            if ( data.desc === "" ) throw new Error( "Enter description of the position" );

            // Update store
            let t = { ...dataX };
            let wh = JSON.parse( JSON.stringify( dataX.workHistory ) );
            wh.push( data );
            t.workHistory = wh;
    
            let datasTemp = [...datas];
            datasTemp[index] = t;
            
            ResumeStore.update( s => {
                s.data =  t;
                s.datas =  datasTemp;
            } );
            cancelNewWH(); // To clean and hide form
        }catch ( e ) {
            util.handleError( e );
        }
    }

    return (
        <div className="col-md-12 mb-2 ">
            {!show ?
                <div className="text-right">
                    <button onClick={()=> setShow( true )}
                        className="btn btn-sm btn-primary ">
                        <FontAwesomeIcon icon={faPlusCircle} className="mr-2"/>
                        Work History
                    </button>
                </div>:
                <div className="createNWH">
                    <h4>New Work History</h4>
                    <div onClick={()=>setShow( !show )} className="isCursor text-black-50"  >
                        <b>{data.position} at {data.company} from {data.from} {data.actual_job === 1 ? "until today": `to ${data.to}`}</b>
                    </div>
                    <div className="form-row align-items-center"  >
                        <util.RowComponent
                            c={6}
                            t={"Company"}
                            n={"company"}
                            m={( e )=> onChangeHandler( e )}
                            v={data.company}
                        />
                        <util.RowComponent
                            c={6}
                            t={"Job Title"}
                            n={"position"}
                            m={( e )=> onChangeHandler( e )}
                            v={data.position}
                        />
                        <util.RowComponent
                            c={3}
                            t={"Start date"}
                            n={"from"}
                            ty="date"
                            m={( e )=> onChangeHandler( e )}
                            v={data.from}
                        />
                        {data.actual_job ? "":
                            <util.RowComponent
                                c={3}
                                t={"End date"}
                                n={"to"}
                                ty="date"
                                m={( e )=> onChangeHandler( e )}
                                v={data.to}
                            />
                        }

                        {/* // Checkbox if candidate still working */}
                        <div className={`form-group col-md-2`}>
                            <div className="text-center">
                                <label htmlFor="checkBoxStillWorking">Current Job</label>
                                <br />
                                <Checkbox
                                    id="checkBoxStillWorking"
                                    checked={data.actual_job === 1}
                                    name="actual_job"
                                    onChange={( e )=> onChangeHandler( e )}
                                    style={{ color:"dodgerblue" }}
                                />
                            </div>
                        </div>
    
                        <div className="container">
                            <div className="row">
                                <LocationCX
                                    cols={4}
                                    data={data} // contains everything
                                    onChange={( e ) => onChangeHandler( e, index )}
                                />
                            </div>
                        </div>
                        
     
                        <util.RowComponent
                            c={12}
                            mul={true}
                            t={"Job Responsibilities"}
                            n={"desc"}
                            m={( e )=> onChangeHandler( e )}
                            v={data.desc}
                        />
                    </div>

                    <div className="text-right">
                        <div>
                            <button onClick={()=> setShowConfirmation( true )}
                                className="btn btn-sm text-danger ">
                                <FontAwesomeIcon icon={faBan} className="mr-2"/>
                                Cancel
                            </button>
                            <button onClick={()=> saveNWH()}
                                className="btn btn-sm btn-success ">
                                <FontAwesomeIcon icon={faSave} className="mr-2"/>
                                Save
                            </button>
                        </div>
                    </div>


                </div>

            }
    
            {showConfirmation ?
                <SAlert
                    show={showConfirmation}
                    confirmText="Yes"
                    typeButton="danger"
                    msg="You will lost this Work History?"
                    hideAlert={() => setShowConfirmation( false )}
                    onConfirm={()=> {
                        setShowConfirmation( false );
                        cancelNewWH();
                    }}
                />:""}
        </div>
    );
}

function WorkHistorySingle( props ) {

    const [show, setShow] = useState( false );
    const [data, setData] = useState( props.data );
    const [index] = useState( props.index );
    const [state, setState] = useState( {
        show: false,
        index: null
    } );
    
    useEffect( () => {
        setData( props.data );
    },[props.data] );

    const dataX = ResumeStore.useState( s => s.data );
    const datas = ResumeStore.useState( s => s.datas );
    const indexGlobal = ResumeStore.useState( s => s.index );

    function onChangeHandler( e, index ) {
        let { name, value } = e.target;
        if ( name === "actual_job" ){
            value = e.target.checked ? 1:0;
        }
        let tempData = { ...data };
        tempData[name] = value;
        setData( tempData );

        let t = { ...dataX };
        let wh = JSON.parse( JSON.stringify( dataX.workHistory ) );
        wh[index] = tempData;
        t.workHistory = wh;
    
        let datasTemp = [...datas];
        datasTemp[indexGlobal] = t;
        
        ResumeStore.update( s => {
            s.data =  t;
            s.datas =  datasTemp;
        } );
    }

    function deleteWH( index ) {
        let t = { ...dataX };
        let wh = [...dataX.workHistory];
        const tempWorkHistory = wh[index];
        delete wh[index];
        wh = wh.filter( val => val ); // reindexing the array
        t.workHistory = wh;
        util.toast().success( `${tempWorkHistory.position} at ${tempWorkHistory.company} position removed` );
    
        let datasTemp = [...datas];
        datasTemp[indexGlobal] = t;
        
        ResumeStore.update( s => {
            s.data =  Object.assign( {}, t );
            s.datas = datasTemp;
        } );
    }

    function fixDate( date ) {
        if ( date === "" ){
            return new Date().toISOString().split( "T" )[0];
        }
        if( !Date.parse( date ) ){
            return new Date().toISOString().split( "T" )[0];
        }else{
            return new Date( date ).toISOString().split( "T" )[0];
        }
    }

    return (
        <div className="card col-md-12 mb-2">
            {!show ?
                <div  className="text-black-50 likeLink"  >
                    <b onClick={()=>setShow( !show )} className="w-100 isPointer">
                        {data.position} at {data.company} from {fixDate( data.from )} {data.actual_job === 1 ? "until today": `to ${fixDate( data.to )}`}
                    </b>
                    <FontAwesomeIcon onClick={()=>setState( { index: index, show: true } )} icon={faTimesCircle} className="isPointer float-right pt-1 text-danger" />
                </div>:
                <Fragment>
                    <div  className="text-black-50 likeLink"  >
                        <b onClick={()=>setShow( !show )} className="isPointer w-100">
                            {data.position} at {data.company} from {fixDate( data.from )} {data.actual_job === 1 ? "until today": `to ${fixDate( data.to )}`}
                        </b>
                        <FontAwesomeIcon onClick={()=>setState( { index: index, show: true } )} icon={faTimesCircle} className="isPointer float-right pt-1 text-danger" />
                    </div>
                    <div className="form-row align-items-center"  >
                        <util.RowComponent
                            c={6}
                            t={"Company"}
                            n={"company"}
                            m={( e )=> onChangeHandler( e,index )}
                            v={data.company}
                        />
                        <util.RowComponent
                            c={6}
                            t={"Job Title"}
                            n={"position"}
                            m={( e )=> onChangeHandler( e,index )}
                            v={data.position}
                        />
                        <util.RowComponent
                            c={3}
                            t={"Start date"}
                            n={"from"}
                            ty="date"
                            m={( e )=> onChangeHandler( e,index )}
                            v={data.from}
                        />
                        {data.actual_job ? "":
                            <util.RowComponent
                                c={3}
                                t={"End date"}
                                n={"to"}
                                ty="date"
                                m={( e )=> onChangeHandler( e,index )}
                                v={data.to}
                            />
                        }

                        {/* // Checkbox if candidate still working */}
                        <div className={`form-group col-md-2`}>
                            <div className="text-center">
                                <label htmlFor="checkBoxStillWorking">Current Job</label>
                                <br />
                                <Checkbox
                                    id="checkBoxStillWorking"
                                    checked={data.actual_job === 1}
                                    name="actual_job"
                                    onChange={( e )=> onChangeHandler( e,index )}
                                    style={{ color:"dodgerblue" }}
                                />
                            </div>
                        </div>
    
                        <div className="container">
                            <div className="row">
                                <LocationCX
                                    cols={4}
                                    data={data} // contains everything
                                    onChange={( e ) => onChangeHandler( e, index )}
                                />
                            </div>
                        </div>
                        
                        <util.RowComponent
                            c={12}
                            mul={true}
                            t={"Job Responsibilities"}
                            n={"desc"}
                            m={( e )=> onChangeHandler( e,index )}
                            v={data.desc}
                        />
                    </div>
                </Fragment>
            }
            {state.show ?
                <SAlert
                    show={state.show}
                    confirmText="Yes"
                    typeButton="danger"
                    msg="Delete Work History?"
                    hideAlert={() => setState( { show: false, index:null } )}
                    onConfirm={()=> {
                        setState( { show: false, index:null } );
                        deleteWH( state.index );
                    }}
                />:""}
        </div>
    );
}

export default WorkHistoryResume;