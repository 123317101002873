import React, { Fragment, useEffect, useState } from "react";
import util from "../../../utils/miniUtils";
import Modal from "react-bootstrap/Modal";

export default function AddTest( { handleClose, show, refreshTestAssignations, talent_id } ) {

    const [testSelected, setTestSelected] = useState( "" );
    const [tests, setTests] = useState( [] );
    const [loading, setLoading] = useState( false );

    useEffect( () => {
        getAvailableTests().then( null );
    }, [] );

    async function getAvailableTests() {
        try {
            const response = await util.axios.get( `/tests/list` );
            const { error, msg, tests } = response.data;
            if ( error ) throw msg;
            setTests( tests );
        } catch ( e ) {
            util.handleError( e );
        }
    }

    const assignTestToTalent = async () => {
        try {
            setLoading( true );
            const response = await util.axios.post( `/tests_user/assign_test/${talent_id}/${testSelected}` );
            const { error, msg, tests } = response.data;
            if ( error ) throw msg;
            handleClose();
            refreshTestAssignations();
            setLoading( false );
        } catch ( e ) {
            setLoading( false );
            util.handleError( e );
        }
    };

    return (
        <Fragment>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Assign Test</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12">
                        <h5>Select the test for this talent</h5>
                        <select
                            value={testSelected}
                            onChange={( e => setTestSelected( e.target.value ) )}
                            className="form-control"
                            name="selected_test"
                            id="selected_test"
                        >
                            <option value="0" />
                            {tests?.map( ( t, i ) => {
                                return <option key={i} value={t.id}>{t.name} - {t.numQuestions} Questions - {t.time} minutes</option>;
                            } )}
                        </select>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={handleClose} className="btn btn-sm text-danger">Close</button>
                    <button onClick={assignTestToTalent} className="btn btn-sm btn-primary">Assign test</button>
                </Modal.Footer>
            </Modal>
            {util.LOADING_SCREEN( loading )}
        </Fragment>
    );
}