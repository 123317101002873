import React, { Fragment, useEffect, useState } from "react";
import NavigationButton from "../shared/NavigationButton";
import { Route, Switch, Redirect, useParams, useRouteMatch } from "react-router-dom";
import WorkLoadTable from './components/WorkLoadTable';
import StatusView from "../shared/pipeview/StatusView";
import PriorityView from "../shared/pipeview/PriorityView";
import CreateWorkLoad from "../shared/CreateWorkLoad";
import util from "../../utils/miniUtils";

const queryHandler = ( data ) => {
    if( data ) {
        return Object.keys( data ).map( key => {
            return `${key}=${encodeURIComponent( data[key] )}`;
        } ).join( '&' );
    }
};

const GroupWorkLoad = () => {
    let { path, url } = useRouteMatch();
    const [showTableStatus, setShowTableStatus] = useState( true );
    const [showCategorySelector, setShowCategorySelector] = useState( true );
    const [categoryList, setCategoryList] = useState( [] );
    const [tableStatus, setTableStatus] = useState( { tableStatus: "all", } );
    const GET_WORKLOAD = `/workload/get_all_my_group_workload?${queryHandler( tableStatus )}`;

    const showComponent = ( option ) => {
        switch ( option ) {
            case "view2":
                setShowTableStatus( true );
                setShowCategorySelector( true );
                return <WorkLoadTable url={GET_WORKLOAD} />;
            case "view3":
                setShowTableStatus( true );
                setShowCategorySelector( false );
                return <StatusView getPath={GET_WORKLOAD} />;
            case "view1":
                setShowTableStatus( true );
                setShowCategorySelector( true );
                return <PriorityView getPath={GET_WORKLOAD} />;
            case "new":
                setShowTableStatus( false );
                setShowCategorySelector( false );
                return <CreateWorkLoad />;
            default:
                setShowTableStatus( true );
                setShowCategorySelector( true );
                return <PriorityView getPath={GET_WORKLOAD} />;
        }
    };

    function WorkLoadContainer() {
        let { component_selected } = useParams();

        return (
            <div>
                {/* <h3>{component_selected}</h3> */}
                {showComponent( component_selected )}
            </div>
        );
    }

    const getCategories = async () => {
        try {
            const request = await util.axios.get( 'wl_categories/get_categories_by_employee/' );
            const { error, msg, data } = request.data;
            setCategoryList( data );
            if( error ) throw msg;
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const handleChange = ( e ) => {
        const { name, value } = e.target;
        setTableStatus( prev => {
            return {
                ...prev,
                [name]: value
            };
        } );
    };

    useEffect( () => {
        getCategories();
    },[] );

    return (
        <Fragment>
            <div className="d-flex align-items-center">
                <NavigationButton url={url}/>  
                {showTableStatus && <div>
                    <span className="label font-weight-bold">Status</span>
                    <select className="form-control" name="tableStatus" value={tableStatus.tableStatus} onChange={handleChange}>
                        <option value="all">All</option>
                        <option value="open">In Progress</option>
                        <option value="closed">Closed</option>
                    </select>
                </div>}
                {showCategorySelector && <div className="ml-2">
                    <span className="label font-weight-bold">Workload Category</span>
                    <select className="form-control" name="wl_category" value={tableStatus.wl_category} onChange={handleChange}>
                        <option value="">All</option>
                        {categoryList && categoryList.map( ( el, i ) => <option key={i} value={el.id}>{el.name}</option> )}
                    </select>
                </div>}
            </div>
            <Switch>
                <Route exact path={path}>
                    <Redirect to={`${path}/view1`} />
                </Route>
				
                <Route path={`${path}/:component_selected`}>
                    <WorkLoadContainer />
                </Route>
            </Switch>
        </Fragment>
    );
};

export default GroupWorkLoad;