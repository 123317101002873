import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  Tooltip } from "@material-ui/core";
import React from 'react';

const BackButton = ( { handleBack } ) => {
    return ( 
        <div className='w-100'>
            <Tooltip title="Previous">  
                <span>
                
                    <button className="isPointer btn btn-outline-primary btn-sm rounded-circle" onClick={handleBack}>
                        <FontAwesomeIcon icon={faArrowLeft} /> 
                    </button>
                </span>
            </Tooltip>
        </div>
    );
};
 
export default BackButton;