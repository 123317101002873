import React, { Fragment, useEffect, useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Personal from "./Personal";
import SkillsResume from "./SkillsResume";
import WorkHistoryResume from "./WorkHistoryResume";
import SweetAlert from "react-bootstrap-sweetalert";
import { ResumeStore } from "./ResumeStore";
import util from "../../../utils/miniUtils";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ReviewResumeInformation( props ) {

    const [section, setSection] = useState( "personal" );
    const [show, setShow] = useState( false );
    const [loading, setLoading] = useState( false );
    const talent = ResumeStore.useState( s => s.data );
    const resumeStore = ResumeStore.useState( s => s );
    
    
    useEffect( () => {
        // update section text every change
        let sec = "Personal";
        switch ( section ) {
            case 'personal': sec = "Personal information"; break;
            case 'skills': sec = "Skills"; break;
            case 'workHistory': sec = "Work History"; break;
            default: break;
        }
        ResumeStore.update( s => {
            s.section = sec;
        } );

    },[section] );


    async function confirmTalentInformation() {
        try {
            // Create the new talent from the resume
            setLoading( true );
            // Validate work history is formed correctly
            talent.workHistory.forEach( wh =>{
                if ( wh.company === "" ) throw new Error( "Insert a company name" );
                if ( wh.position === "" ) throw new Error( "Insert a position in the company" );
                if ( wh.from === "" ) throw new Error( "Select start date" );
                if ( wh.actual_job === 0 ){
                    if ( wh.to === "" ) throw new Error( "Select end date" );
                }
                if ( wh.desc === "" ) throw new Error( "Enter description of the position" );
            } );

            // Send information already validated to the server
            const request = await util.axios.post( `${util.url}/candidates/new-candidate-from-resume`, { talent } );
            let { error, msg, candidate } = request.data;
            if ( error ) throw msg;
            util.toast().success( msg );

            // After we create the talent, we upload the resume
            const data = new FormData();
            data.append( "file", resumeStore.file );
            const requestFile = await util.axios.post( `${util.url}/candidates/upload_resume/${candidate.id}`, data );
            let d  = requestFile.data;
            if ( d.error ) throw d.msg;
            // Reset store information
            ResumeStore.update( s => {
                s.data =  {};
                s.file = null;
            } );
            // Close modal
            setLoading( false );
            setShow( false );
            props.closeModal();
        } catch ( e ) {
            setLoading( false );
            setShow( false );
            util.handleError( e );
        }
    }

    function stepForward() {
        switch ( section ) {
            case 'personal': setSection( "skills" ); break;
            case 'skills': setSection( "workHistory" ); break;
            case 'workHistory': setShow( true ); break;
            default: break;
        }
    }

    function stepBack() {

        switch ( section ) {
            case 'workHistory': setSection( "skills" ); break;
            case 'skills': setSection( "personal" ); break;
            default: break;
        }
    }

    function navigationButtons() {
        return <div className="text-center pr-3 pl-3">
            {/*<button className="btn btn-sm btn-danger m-1" onClick={()=> props.handleClose()}>*/}
            {/*    <FontAwesomeIcon icon={faBan} className="mr-2"/>*/}
            {/*    Cancel*/}
            {/*</button>*/}
            {section !== "personal" ?
                <button className="btn btn-sm btn-primary m-1" onClick={stepBack}>
                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2"/>
                    Previous
                </button>
                :
                ""
            }
            {section !== "workHistory" ?
                <button className="btn btn-sm btn-primary m-1" onClick={stepForward}>Next<FontAwesomeIcon
                    icon={faArrowRight} className="ml-2"/></button>
                :
                // <button className="btn btn-sm btn-success m-1" onClick={stepForward}><FontAwesomeIcon
                //     icon={faCheckCircle} className="mr-2"/>Finish</button>
                null
            }
        </div>;
    }

    return(
        <Fragment>
            {navigationButtons()}
            <div>
                <h5>{talent.name}</h5>
            </div>
            <Tabs defaultActiveKey={section} activeKey={section} onSelect={( k ) => setSection( k )}>
                <Tab eventKey="personal" title="Personal">
                    <Personal/>
                </Tab>
                <Tab eventKey="skills" title="Skills">
                    <SkillsResume/>
                </Tab>
                <Tab eventKey="workHistory" title="Work History">
                    <WorkHistoryResume/>
                </Tab>
            </Tabs>
            <hr/>
            {navigationButtons()}
            <SweetAlert
                show={show}
                warning
                showCancel
                confirmBtnText="Yes!"
                confirmBtnBsStyle="primary"
                title="Create a talent"
                onConfirm={() => confirmTalentInformation()}
                onCancel={() => setShow( false )}
                focusCancelBtn
            >

            </SweetAlert>
            {util.LOADING_SCREEN( loading )}
        </Fragment>
    );
}

export default  ReviewResumeInformation;