import React, { useEffect, useState } from "react";
import { faFilter, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import selector from "../../../utils/selector";
import util from "../../../utils/miniUtils";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

const AdvancedFilter = ( { filterData, searchData, closeAdvanceSearch } ) => {

    const [skillsList, setSkillsList] = useState( [] );
    const [statesList, setStatesList] = useState( [] );
    const [state, setState] = useState( filterData.state );
    
    function handleChanges( e ) {
        const { name, value } = e.target;
        if ( name === "skill_name" ){
            // Search skill is making a request
            searchSkill( value ).then( null );
        }
        setState( { ...state, [name]:value } );
    }
    
    async function searchSkill( sk ){
        try {
            let query = `skill=${sk}`;
            const request = await util.axios.get( `${util.url}/generic_data/search_skills?${query}` );
            const { error, msg, skills } = request.data;
            if ( error ) throw  new Error( msg );
            setSkillsList( skills );
        }catch ( e ) {
            util.handleError( e );
        }
    }
    
    useEffect( () => {
        // Every time country is changed, will retrieve the list of states
        if ( state.country && state.country.length === 0 ){
            setStatesList( selector.statesByCountry( [] ) );
        }else{
            setStatesList( selector.statesByCountry( state.country ) );
        }
        
    },[state.country] );
    
    return (
        <div className="advancedFilterBox">
            <div className="row ">
                <div className="col-md-12">
                    <h5>Advanced Filter</h5>
                </div>
            </div>
            <div className="d-flex flex-row align-self-end">
                <div className="col-md-2 p-2 mt-2 align-self-end">
                    <TextField
                        label="Name"
                        name="name"
                        value={state.name}
                        onChange={handleChanges}
                        variant="standard"
                    />
                </div>
                <div className="col-md-2 p-2 mt-2 align-self-end">
                    <Autocomplete
                        freeSolo
                        fullWidth
                        disableClearable
                        onChange={() => null}
                        options={skillsList.map( ( option ) => option.skill_name )}
                        renderInput={( params ) => (
                            <TextField
                                {...params}
                                label="Skill"
                                name="skill_name"
                                size={"small"}
                                value={state.skill_name}
                                onChange={handleChanges}
                                variant="standard"
                                className="w-100"
                                InputProps={{ ...params.InputProps, type: "search" }}
                            />
                        )}
                    />
                </div>
                <div className="col-md-2 p-2 mt-2 align-self-end">
                    <TextField
                        label="Last job"
                        name="job_title"
                        className="w-70"
                        value={state.job_title}
                        onChange={handleChanges}
                        variant="standard"
                    />
                </div>
                
                <util.RowComponent
                    c={2}
                    t={"Country"}
                    n={"country"}
                    m={handleChanges}
                    v={state.country}
                    type={1}
                    d={selector.country()}
                />
        
                <util.RowComponent
                    c={2}
                    t={"State"}
                    n={"state"}
                    m={handleChanges}
                    v={state.state}
                    type={1}
                    d={statesList}
                />
                <div className="col-md-1 p-2 mt-2 align-self-end">
                    <TextField
                        label="City"
                        name="city"
                        value={state.city}
                        onChange={handleChanges}
                        variant="standard"
                        className="w-70"
                    />
                </div>
        
                <div className="col-md-1 p-2  d-flex align-self-center justify-content-center" >
                    <button className="btn btn-sm btn-primary"
                        onClick={() => searchData( state )}
                    >
                        <FontAwesomeIcon icon={faFilter} className="mr-2"/>
                        Search
                    </button>
                    <button className="btn btn-sm"
                        title="Close advanced search"
                        onClick={closeAdvanceSearch}
                    >
                        <FontAwesomeIcon icon={faTimes}/>
                    </button>
                </div>
                <hr />
            </div >
        </div>
    );
};

export default AdvancedFilter;
