import React from 'react';
import User from "../../../../assets/images/user.png";
import Green from '../../../../assets/images/green.png';

//final appraisal both flows completed image
export const CompletedStatus = ( { l2_exist,l3_exist } ) => {
    return(
        <div title="status" className={`${( l2_exist ||l3_exist ) ? "" : "final_st"} d-flex` }>                            
            <div>
                <div>
                    <img
                        className="appraisee mb-1 "
                        alt="user"
                        src={User}
                    />
                </div>

                <img
                    className='imgs mt-1'
                    alt="completed"
                    src={Green}
                />
            </div>
            <div>
                <div className="mb-1">L1</div>
                <img
                    className='imgs'
                    alt="completed"
                    src={Green}
                />
            </div>
            {l2_exist ? 
                 
                <div>
                    <div className="mb-1">L2</div>
                    <img
                        className='imgs'
                        alt="completed"
                        src={Green}
                    />
                </div>
                : "" }
            {l3_exist ? 
                <div>
                    <div className="mb-1">L3</div>
                    <img
                        className='imgs'
                        alt="completed"
                        src={Green}
                    />
                </div> : "" }

           
            <div>
                <div className="mb-1">HR</div>
                <img
                    className='imgs'
                    alt="completed"
                    src={Green}
                />
            </div>
                                           
        </div>
    );
};