import { Store } from "pullstate";
import util from "../../utils/miniUtils";

export const PositionsStore = new Store( {
    loading: false,
    job:null,
    firstTime: true,
    job_position:"",
    location:"",
    locations:[],
    isLogged: util.user.name ? true : false,
    jobs:[],
    total:0,
    page:0,
    limit:10,
    showFilter:false,
    country: "",
    state: "",
    city: "",
    duration: "",
    level: "",
    sell_rate_type: "",
    postedDaysAgo: 3,
    applyModal: false,
    loginModal: false,
    createAccount: false,
    profileProcess: false,
    recoveryModal: false,
    newSearch: null,
    sectionSelected: "Main",
} );