import React, { useEffect, useState } from 'react';
import util from '../../utils/miniUtils';

const ScreeningQuestionnaire = ( { pcId } ) => {
    const [sqData, setSqData] = useState( [] );

    async function getSqData(){
        try {
            const request = await util.axios.get( `/get_candidate_response/${pcId}` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setSqData( data );
        } catch ( error ) {
            util.handleError( error );
        }
    }

    function handleAnswer( item ) {
        if( item.type === 1 ) {
            return JSON.parse( item?.response ).join( ", " );
        } else return item?.response;
    }

    useEffect( () => {
        getSqData();
        return () => {
            setSqData( [] );
        };
    },[] );

    if(sqData.length < 0) {
        return null;
    }
    
    return (
        <>
            {sqData.length > 0 && sqData.map( ( item, index ) =>(
                <div key={item.id}>
                    <div className="screening-question">{`Q ${index + 1}. `}{item.question}</div>
                    <div className="screening-answer">{`A. `}{handleAnswer( item )}</div>
                </div>
            )
            ) }
          
        </>
    );
};

export default ScreeningQuestionnaire;