import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBuilding,
    faFilter,
    faSearch,
    faSignOutAlt,
    faTrash,
    faUser, faUserCircle,faEnvelope
} from "@fortawesome/free-solid-svg-icons";
import { TextField } from "@material-ui/core";
import { PositionsStore } from "../stores/PositionsStore";
import util from "../../utils/miniUtils";
import AdvancedSearch from "./AdvancedSearch";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";
import Dropdown from "react-bootstrap/Dropdown";
import FillProfile from "../modals/FillProfileNew";
import Register from "../modals/Register";
import Login from "../modals/Login";
import TalentNotification from "./TalentNotification";
import ContactForm from "../../shared/Contact";

export  default function NavBar() {
	
    // Retrieve store
    const position = PositionsStore.useState( state => state );
    const [show, setShow] = useState( false );
    const target = useRef( null );
    const [showContactForm,setShowContactForm] = useState( false );
    // Handle display reCaptcha
    useEffect( () => {
        setTimeout( ()=>{
            setShow( false );
        },2500 );
    },[show] );

    useEffect( () => {
        
    },[position.isLogged] );

    // when we get any value in the "tokenServer" cookie reload
    
	
    // If the URl have a job, it will display the job content
    // This needs to be executed only one time
    useEffect( () => {
        if ( window.document.location.search !== "" ){
            const urlParams = new URLSearchParams( window.location.search );
            const jobId = urlParams.get( 'job' );
            if ( jobId ){
                let obj = {};
                obj.id = parseInt( jobId );
                setTimeout( ()=>{
                    PositionsStore.update( s=>{
                        s.job = obj;
                    } );
                },1000 );
            }
        }
    }, [] );
	
    // Handle changes in search bar or advanced search
    function handleChange( e ) {
        const { name, value } = e.target;
        PositionsStore.update( s => {
            s[name]         =  value;
        } );
    }
	
    function openFilter() {
        PositionsStore.update( s => {
            s.advanceFilter =  !s.advanceFilter;
        } );
    }
	
    // Make a search/ request on the server using stored parameters for the search
    async function search() {
        try {
            let p = { ...position };
            if ( p.job_position === "" && p.advanceFilter === false ) {
                setShow( !show );
                return;
            }
			
            PositionsStore.update( s => { s.loading = true;} );
			
            let data = `page=0
						&limit=${p.limit}
						&job_position=${p.job_position}
						&country=${p.country}
						&advanceFilter=${p.advanceFilter}
						&state=${p.state}
						&city=${p.city}
						&duration=${p.duration}
						&postedDaysAgo=${p.postedDaysAgo}`;
			
            const request = await util.axios.get( `${util.url}/jobs/position?${data}` );
            const { error, msg, jobPositions, total } = request["data"];
			
            const pageUrl = `?search=${p.job_position}`;
            window.history.pushState( '', '', pageUrl );
            // Save new parameters into Store
            PositionsStore.update( s => {
                s.jobs      =   jobPositions;
                s.job       =   null;
                s.firstTime =   false;
                s.total     =   total;
                s.page      =   p.page;
                s.loading   =   false;
                // Values for components
                s.job_position  = p.job_position;
                s.advanceFilter = p.advanceFilter;
                s.country       = p.country;
                s.state         = p.state;
                s.city          = p.city;
                s.postedDaysAgo = p.postedDaysAgo;
                s.sectionSelected = "Main";
            } );
            if ( error ) throw  msg;
        }catch ( e ) {
            util.handleError( e );
        }
    }

    const deleteAccount = async() => {
        try {
            const request = await util.axios.get( `/delete_data` );
            const { error, msg } = request.data;
            util.logOut();
            if ( error ) throw msg;
        } catch ( error ) {
            util.handleError( error );
        }
    };
	
    return(
        <div className="container-fluid bg-white border-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-md-2">
                        <a className="navbar-brand" href="/">
                            <img src={"/images/demo_logo.png"}  width="160px" height="40px"/>
                        </a>
                    </div>
                    <div className="col-md-6">
                        {/* Principal search*/}
                        <Overlay target={target.current} show={show} placement="bottom">
                            {( props ) => (
                                <Tooltip {...props}>
									Please enter something to search
                                </Tooltip>
                            )}
                        </Overlay>
                        <TextField
                            disabled={position.advanceFilter}
                            onKeyDown={
                                ( key ) => {
                                    if ( key.keyCode === 13 ){
                                        search().then( null );
                                    }
                                }
                            }
                            ref={target}
                            margin="normal"
                            placeholder="Search Workload Items"
                            name="job_position"
                            value={position.job_position}
                            fullWidth
                            type="search"
                            onChange={handleChange}
                            variant="standard"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <FontAwesomeIcon icon={faSearch} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <div className="col-md-4 d-flex justify-content-around">
                        <button
                            disabled={position.advanceFilter}
                            onClick={search}
                            className="btn btn-sm btn-primary align-self-center mr-1">
                            <FontAwesomeIcon icon={faSearch} className="mr-2" />
							Search
                        </button>
                        <button
                            onClick={openFilter}
                            className="btn btn-sm btn-info align-self-center mr-1">
                            <FontAwesomeIcon icon={faFilter} className="mr-2" />
							Filter
                        </button>
                        {position.isLogged ?
                            <div className="align-self-center">
                                <Dropdown>
                                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                        {util.customDropDownName( util.user?.name )}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#" onClick={()=>PositionsStore.update( s=>{
                                            s.sectionSelected = "JobsAppliedTalent";
                                            s.firstTime = false;
                                        } )}>
                                            <FontAwesomeIcon color="#aeaeae" icon={faBuilding} className="mr-1" />
											Applied Jobs
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#" onClick={()=>{
                                            window.history.pushState( '', '', "/" );
                                            PositionsStore.update( s=>{
                                                s.sectionSelected = "Main";
                                                s.firstTime = true;
                                            } );
                                        }}>
                                            <FontAwesomeIcon color="#aeaeae" icon={faSearch} className="mr-1" />
											Search Jobs
                                        </Dropdown.Item>
                                        {(  util.user && util.user.permissions && util.user.permissions.talent && !util.user.permissions.employee ) && <Dropdown.Item onClick={()=>{
                                            // window.history.pushState('', '', "/");
                                            PositionsStore.update( s=>{
                                                s.sectionSelected = "Profile";
                                                s.firstTime = false;
                                            } );
                                        }}>
                                            <FontAwesomeIcon color="#aeaeae" icon={faUserCircle} className="mr-1" />
											Profile
                                        </Dropdown.Item>}
                                        <util.AvailableRole role="Recruiter" selectedRole="recruiter" />
                                        {util.user.role === 2 ? null : <util.AvailableRole role="Talent" selectedRole="talent" />}
                                        <util.AvailableRole role="Client" selectedRole="client" />
                                        <util.AvailableRole role="Partner" selectedRole="partner" />
                                        <util.AvailableRole role="Evaluation" selectedRole="evaluator" />
                                        <util.AvailableRole role="Employee" selectedRole="em
                                        loyee" />
                                        <util.AvailableRole role="Recruitment Manager" selectedRole="recruiter_manager" />
                                        <Dropdown.Item>
                                            <div onClick={()=> deleteAccount()}><FontAwesomeIcon color="#ff7272" icon={faTrash} className="mr-2"/> Delete Account</div>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                            <div onClick={()=> util.logOut()}><FontAwesomeIcon color="#ff7272" icon={faSignOutAlt} className="mr-2"/> Logout</div>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            :
                            <button
                                onClick={()=>{
                                    PositionsStore.update( s => {
                                        s.loginModal = true;
                                    } );
                                }}
                                className="btn btn-sm btn-primary align-self-center align-content-end">
                                <FontAwesomeIcon icon={faUser} className="mr-2" />
								Login
                            </button>
                        }
                        <button
                            className="btn"
                            onClick={( e ) =>
                                setShowContactForm( true )
                            }
                        >
                            <FontAwesomeIcon icon={faEnvelope} />
                        </button>
                        <ContactForm show={showContactForm}
                            handleClose={() => setShowContactForm( false )}
                            index={5000006}/>
                        {util.user.role === 2 &&<TalentNotification />}
                    </div>
                </div>
            </div>
            {position.createAccount ? <Register/>: null}
            {position.loginModal ? <Login/>: null}
            {position.advanceFilter ? <AdvancedSearch makeSearch={()=>search()} />:""}
            {position.profileProcess ? <FillProfile  />:""}
        </div>
    );
}
