import React, { Fragment, useState } from "react";
import util from "../../../../../utils/miniUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";

export default function JobSettings( { markUp, billableDaysInYear, workingHoursInDay, averageBillableDaysInMonth, additionalDays, ctc, onChange } ) {
    
    const [ show, setShow ] = useState( false );

    return (
        <Fragment>
            <span onClick={()=> setShow( !show )} className="likeLink m-2"><FontAwesomeIcon icon={faCog}/> CTC-Parameter Settings for {ctc.employmentType}</span>
            {
                show ?
                    
                    <Fragment>
                        <div className="row">
                            <div className="col-md-6">
                                <b>Additional Days of Benefits Offered to Talent (Special Case)</b>
                            </div>
                            <div className="col-md-6">
                                <input type="number"  className="form-control" value={ctc.additionalDays} name="additionalDays" onChange={onChange} />
                            </div>
                        </div>
                        <div className="row text-center">
                            <div className="col-md-3">Loading</div>
                            <div className="col-md-3">Billable Days in a Year</div>
                            <div className="col-md-3">Working Hours in Day</div>
                            <div className="col-md-3">Avg. Billable Days in a Month</div>
                        </div>
                        <div className="row text-center">
                            <div className="col-md-3">{markUp}%</div>
                            <div className="col-md-3">{billableDaysInYear}
                                {!util.noValues.includes( additionalDays ) ? ` (-${additionalDays})` : null}</div>
                            <div className="col-md-3">{workingHoursInDay}</div>
                            <div className="col-md-3">{averageBillableDaysInMonth}</div>
                        </div>
                    </Fragment>
                    :
                    null
            }
            
        </Fragment>
    );
}
