import MaterialTable from "material-table";
import React, { Fragment, useState } from "react";
import util from "../../../utils/miniUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import NewNoteDialog from "./NewNoteDialog";

export default function TableOfNotes ( { data, title, talent_id, skillType, reloadData } ){
	
    const [show, setShow] = useState( false );
    const [note, setNote] = useState( { note:"" } );
    const [loading, setLoading] = useState( false );
	
    const startLoading = () => setLoading( true );
    const stopLoading = () => setLoading( false );
	
    const deleteSkill = async ( row ) => {
        try {
            startLoading();
            let { candidate_id, id } = row;
            const response = await util.axios.delete( `talent/note_delete/${candidate_id}/${id}/` );
            const { error, msg, data } = response["data"];
            if ( error ) throw new Error( msg );
            reloadData( data );
            util.toast().success( "Note Deleted" );
            stopLoading();
        } catch ( e ) {
            stopLoading();
            util.handleError( e );
        }
    };
	
    const saveNewNote = async ( ) => {
        try {
            startLoading();
            const response = await util.axios.post( `talent/note_add/${talent_id}/`, note );
            const { error, msg, data } = response["data"];
            if ( error ) throw new Error( msg );
            reloadData( data );
            util.toast().success( "Note Created" );
            setNote( { note:"" } );
            setShow( false );
            stopLoading();
        }catch ( e ){
            stopLoading();
            util.handleError( e );
        }
    };
		
    const handleChangeNewNote = ( e ) => {
        const { name, value } = e.target;
        setNote( { ...note, [name]: value } );
    };
	
	
    return (
        <Fragment>
            <MaterialTable
                isLoading={loading}
                columns={[
                    {
                        title: 'Date', field: 'createdAt',
                        width:"10%",
                        render: row =>{
                            return util.humanDate( row.createdAt, true );
                        }
                    },
                    {
                        title: 'By', field: 'create_By',
                        width:"5%"
                    },
                    {
                        title: 'Description', field: 'note',
                        width:"80%",
                        render: d => <div className="text-justify">{d.note}</div>
                    },
                    {
                        title: '', sorting: false,
                        width:"5%",
                        render: row => {
                            return (
                                <util.PopOverConfirmation
                                    callBackOk={()=> deleteSkill( row )}
                                    callBackNo={()=> console.log( "" )}
                                />
							
                            );
                        }
                    },
                ]}
                data={data}
                options={{
                    pageSize: 10,
                    sorting: true,
                    search: true,
                    showFirstLastPageButtons: true,
                    pageSizeOptions: [10, 30, 50, 100],
                    padding: "dense",
                    paginationType: "normal",
                    tableLayout: "auto",
                    addRowPosition: "first",
                    emptyRowsWhenPaging:false,
                    toolbar: true,
                    showTitle: false
                }}
                actions={[
                    {
                        icon: ()=> <FontAwesomeIcon  icon={faPlus}/>,
                        isFreeAction: true,
                        tooltip: `Add new note`,
                        onClick: ( event, rowData ) => {
                            setShow( true );
                        }
                    }
                ]}
            />
			
            <NewNoteDialog
                talent_id={talent_id}
                show={show}
                hideNote={()=> setShow( false )}
                saveNewNote={saveNewNote}
                note={note}
                handleChangeNewNote={handleChangeNewNote}
            />
        </Fragment>
    );
}