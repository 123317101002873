import React from "react";
import { faPlusCircle, faSignal, faStar, faTable } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";

/**
 * This component hold the 4 buttons to:
 *  1 - Table view
 *  2 - Pipe View
 *  3 - Priority View
 *  4 - Add new Workload
 */
const NavigationButton = ( { url } ) => {

    return (
        <div className="d-flex align-items-center mb-2 pt-2 pb-1">
            <div className="viewBtnDiv border d-flex align-items-center">
                <Tooltip title={"Priority View"}>
                    <Link className="btn" to={`${url}/view1`}>
                        <FontAwesomeIcon className="viewBtn" icon={faStar} id="priorityView" />
                    </Link>
                </Tooltip>
                <Tooltip title={"Table View"}>
                    <Link className="btn" to={`${url}/view2`}>
                        <FontAwesomeIcon className="viewBtn" icon={faTable} id="tableView" />
                    </Link>
					
                </Tooltip>
                <Tooltip title={"Status View"}>
                    <Link className="btn" to={`${url}/view3`}>
                        <FontAwesomeIcon className="viewBtn" icon={faSignal} id="statusView" />
                    </Link>
                </Tooltip>
            </div>
            <div className="d-flex align-items-center p-0 mt-1">
                <Tooltip title={"New Workload"}>
                    <Link className="btn" to={`${url}/new`}>
                        <FontAwesomeIcon className="wl-addBtn" icon={faPlusCircle} id="addWorkloadIcon" />
                    </Link>
                </Tooltip>
            </div>
        </div>
    );
};

export default NavigationButton;
