import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import util from "../../utils/miniUtils";
import { faArrowRight, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useDropDownOptions from "./useDropDownOptions";
import { ListGroup } from "react-bootstrap";
import { Tooltip } from "@material-ui/core";

export default function ConfirmationModal( { show, hideModal, data, reloadTableData } ) {

    const [state, setState] = useState( { data } );

    const { changeStatus, getDroppedReasons, reasons, onSelectCheckBox } = useDropDownOptions();

    useEffect( () => {
        setState( data );
    }, [data] );
    
    
    useEffect( () => {
        if ( state.status === 6 )
            getDroppedReasons();
    }, [ state.status ] );

    return(
        <Modal
            show={show}
            onHide={hideModal}
            size={"lg"}
        >
            <Modal.Header closeButton>
                <Modal.Title>Change {state.data.length === 1 ? `Job` : `Jobs`} Status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <b>Original Job Status</b>
                            {
                                state.data.length > 0 ?
                                    <ul>
                                        {
                                            state.data && state.data.map( ( j, i ) => {
                                                return (
                                                    <li key={i}>
                                                        {util.jobStatusOnlyIcons( j.status, false )}
                                                        {j.job_title}
                                                    </li>
                                                );
                                            } )
                                        }
                                    </ul>
                                    :
                                    null

                            }

                        </div>
                        <div className="col-md-2 d-flex justify-content-center align-items-center">
                            <h2><FontAwesomeIcon icon={faArrowRight} /></h2>
                        </div>
                        <div className="col-md-5">
                            <b>New Job Status</b>
                            {
                                state.data.length > 0 ?
                                    <ul>
                                        {
                                            state.data && state.data.map( ( j, i ) => {
                                                return (
                                                    <li key={i}>
                                                        {util.jobStatusOnlyIcons( state.status, false )}
                                                        {j.job_title}
                                                    </li>
                                                );
                                            } )
                                        }
                                    </ul>
                                    :
                                    null
                            }
                        </div>
                    </div>
                    {
                        state.status === 6 ?
                            <div className="row">
                                <div className="col-md-12 p-2">
                                    <b>Dropped reasons</b>
                                    <ListGroup>
                                        {
                                            reasons.map( ( r,i ) => {
                                                return (
                                                    <ListGroup.Item key={i}>
                                                        <Tooltip title={r.tooltip}>
                                                            <span>
                                                                <label htmlFor={`input_${i}`}>
                                                                    <input
                                                                        onClick={( e )=>onSelectCheckBox( e, i )}
                                                                        type="checkbox"
                                                                        id={`input_${i}`}
                                                                        className="mr-2"
                                                                    />
                                                                    { r.title }
                                                                </label>
                                                            </span>
                                                        </Tooltip>

                                                    </ListGroup.Item>
                                                );
                                            } )
                                        }
                                    </ListGroup>

                                </div>
                            </div>
                            :
                            ""
                    }

                    <div className="row">
                        <div className="col-md-12 text-center p-3">
                            <hr/>
                            <p>
                                <label htmlFor="sendEmailCheckbox">
                                    <input id="sendEmailCheckbox"
                                        type="checkbox"
                                        className="mr-1"
                                        name="sendEmailCheckbox"
                                        onClick={( e )=> setState( { ...state, sendEmail: e.target.checked } )}/>
                                    Notify the talents inside the {state.data.length === 1 ? `job` : `jobs`} about the changes?
                                </label>
                            </p>
                        </div>
                    </div>
                </div>



            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-sm btn-danger" onClick={hideModal}>
                    Cancel
                </button>
                <button className="btn btn-sm btn-primary" onClick={()=>changeStatus( state, reloadTableData )}>
                    <FontAwesomeIcon icon={ faSync} className="mr-2" />
                    Change Status
                </button>
            </Modal.Footer>
        </Modal>
    );
}