import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import Checkbox from "@material-ui/core/Checkbox";
import { Collapse } from "@material-ui/core";
import LocationCX from "../../LocationCX";
import util from "../../../utils/miniUtils";

export default function WorkHistorySingle( { dataX, reloadData } ) {

    const [show, setShow] = useState( false );
    const [workHistory, setWorkHistory] = useState( dataX );
    const [dataBase, setDataBase] = useState( dataX );
    const [forceRender, setForceRender] = useState( null );
    const [loading, setLoading] = useState( false );

    const startLoading = () => setLoading( true );
    const stopLoading = () => setLoading( false );

    useEffect( () => {
        setWorkHistory( dataX );
    }, [dataX] );

    useEffect( () => {
        setWorkHistory( dataBase );
    }, [forceRender] );

    function onChangeHandler( e ) {
        let { name, value } = e.target;
        if ( name === "actual_job" ) {
            value = e.target.checked ? 1 : 0;
        }
        setWorkHistory( { ...workHistory, [name]: value } );
    }

    function fixDate( date ) {
        if ( date === "" ) {
            return new Date().toISOString().split( "T" )[0];
        }
        if ( !Date.parse( date ) ) {
            return new Date().toISOString().split( "T" )[0];
        } else {
            return new Date( date ).toISOString().split( "T" )[0];
        }
    }

    const deleteWH = async () => {
        try {
            startLoading();
            let { candidate_id, id } = dataX;
            const response = await util.axios.delete( `talent/work_history_delete/${candidate_id}/${id}/` );
            const { error, msg, data } = response["data"];
            if ( error ) throw new Error( msg );
            reloadData( data );
            util.toast().success( "Skill Deleted" );
            stopLoading();
        } catch ( e ) {
            stopLoading();
            util.handleError( e );
        }
    };



    const updateWH = async () => {
        try {
            startLoading();
            let { candidate_id, id } = workHistory;
            const response = await util.axios.put( `talent/work_history_update/${candidate_id}/${workHistory.id}/`, workHistory );
            const { error, msg, data } = response["data"];
            if ( error ) throw new Error( msg );
            util.toast().success( "Work History Updated" );
            reloadData( data );
            setForceRender( Math.random() );
            stopLoading();
        } catch ( e ) {
            stopLoading();
            util.handleError( e );
        }
    };

    return (
        <div className="col-md-12 mb-2">
            <div className="text-black-50  d-flex justify-content-around p-2"  >
                <h5 onClick={() => setShow( !show )} className="w-100 isPointer likeLink">
                    {workHistory.position} at {workHistory.company} from {fixDate( workHistory.from )} {workHistory.actual_job === 1 ? "until today" : `to ${fixDate( workHistory.to )}`}
                </h5>
                {/* {dataBase !== data ? <Badge variant="warning" className="align-self-center">Not saved</Badge> : null} */}

                <util.PopOverConfirmation
                    callBackOk={() => deleteWH()}
                    callBackNo={() => console.log( "is fail" )}
                />

            </div>
            <Fragment>
                <Collapse in={show}>
                    <div className="form-row align-items-center"  >
                        <util.RowComponent
                            c={6}
                            t={"Company"}
                            n={"company"}
                            m={onChangeHandler}
                            v={workHistory.company || ""}
                        />
                        <util.RowComponent
                            c={6}
                            t={"Job Title"}
                            n={"position"}
                            m={onChangeHandler}
                            v={workHistory.position || ""}
                        />
                        <util.RowComponent
                            c={3}
                            t={"Start date"}
                            n={"from"}
                            ty="date"
                            m={onChangeHandler}
                            v={workHistory.from || ""}
                        />
                        {workHistory.actual_job ? "" :
                            <util.RowComponent
                                c={3}
                                t={"End date"}
                                n={"to"}
                                ty="date"
                                m={onChangeHandler}
                                v={workHistory.to || ""}
                            />
                        }

                        {/* // Checkbox if candidate still working */}
                        <div className={`form-group col-md-2`}>
                            <div className="text-center">
                                <label htmlFor="checkBoxStillWorking">Current Job</label>
                                <br />
                                <Checkbox
                                    id="checkBoxStillWorking"
                                    checked={workHistory.actual_job === 1}
                                    name="actual_job"
                                    onChange={( e ) => onChangeHandler( e )}
                                    style={{ color: "dodgerblue" }}
                                />
                            </div>
                        </div>

                        <div className="container-fluid p-0">
                            <div className="row">
                                <LocationCX
                                    cols={4}
                                    data={workHistory} // contains everything
                                    onChange={onChangeHandler}
                                />
                            </div>
                        </div>

                        <util.RowComponent
                            c={12}
                            mul={true}
                            t={"Job Responsibilities"}
                            n={"desc"}
                            m={onChangeHandler}
                            v={workHistory.desc || ""}
                        />
                    </div>
                    {dataBase !== workHistory ?
                        <div className="d-flex justify-content-end">
                            <button
                                onClick={() => {
                                    setForceRender( Math.random() );
                                }
                                }
                                className="btn m-1 text-danger btn-sm mr-2 "
                            >
								Cancel
                            </button>
                            <button
                                onClick={() => {
                                    updateWH();
                                }
                                }
                                className="btn mr-2 btn-primary btn-sm "
                            >
                                <FontAwesomeIcon icon={faSave} /> Save
                            </button>
                        </div>
                        :
                        null
                    }
                </Collapse>
                {util.LOADING_SCREEN( loading )}
            </Fragment>
        </div>
    );
}