import { PositionsStore } from "../stores/PositionsStore";
import Badge from "react-bootstrap/Badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faMapPin, faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import util from "../../utils/miniUtils";
import React from "react";
import en from 'javascript-time-ago/locale/en';
import TimeAgo from 'javascript-time-ago';
TimeAgo.addDefaultLocale( en );
const timeAgo = new TimeAgo( 'en-US' );
export default function JobSingle( { job } ) {
	
    const { job_title,city,employment_type, state, country, createdAt, level, role, primary_skill, secondary_skill, buy_from ,buy_to, sell_rate_type } = job;
    const a = [level, role, primary_skill, secondary_skill];
    const stateJob = PositionsStore.useState( state => state );
    let fromatedDate = new Date( createdAt.replaceAll( '/','-' ).replace( ' ','T' ) );

    let chips = [];
    for ( let i = 0; i < a.length; i++ ) {
        if ( !util.noValues.includes( a[i] ) ){
            chips.push( a[i].toString() );
        }
    }
	
    const badgeX = () =>{
        switch ( employment_type ) {
            case "Any": return <Badge pill variant="primary" className="badgeEmploymentType_JP ml-1">{employment_type}</Badge>;
            case "Fulltime": return <Badge pill variant="success" className="badgeEmploymentType_JP ml-1">{employment_type}</Badge>;
            case "Contractor": return <Badge pill variant="info" className="badgeEmploymentType_JP ml-1">{employment_type}</Badge>;
        }
    };
	
    /// Utility function to display in wish Position the talent already applied
    function handleBadgeStatus( job ) {
        if ( job["applied"] ){
            if ( job["applied"] && job["talent_dropped"] === 0 ){
                // Just applied
                return <Badge variant="success" className="badgeApplied" >Applied</Badge>;
            }else{
                // Applied and rejected?
                return <Badge variant="danger" className="badgeWithdraw" >Withdrawn</Badge>;
            }
        }
    }
	
    return(
        <div className={` ${job.id === stateJob.job?.id ? 'positionSelected':''} singleJobBox_PJ`}>
            <div className="d-flex justify-content-between">
                <span>
                    <span className="jobTitle_PJ">{job_title}</span>
                </span>
                <span>
                    <b>{timeAgo.format( new Date( fromatedDate ) )}</b>
                    <p className="text-right">{handleBadgeStatus( job )}</p>
                </span>
            </div>
            <div className="">
                <FontAwesomeIcon icon={faMoneyBill} className="mr-1 iconGray"/>
                <b>Salary Range</b>: {util.buyFromBuyTo( buy_from, buy_to, country )} {sell_rate_type}
            </div>
            <div className="companyLocation_PJ">
                <b>
                    <FontAwesomeIcon icon={faBuilding} color="#007bff" className="mr-1" />
					eJAmerica
                </b>
                <span className="ml-2">
                    <FontAwesomeIcon icon={faMapPin}  color="#ff5a5a" className="mr-1" />
                    {util.location( country, state, city )}
                </span>
                <span>
                    {badgeX()}
                </span>
            </div>
            <div className="">
                {chips.map( ( c,i )=>{
                    return <span className="chip_JP" key={i}>
                        {c}
                    </span>;
                } )}
            </div>
			
        </div>
    );
}