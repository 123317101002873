import React, { useState, Fragment, useEffect } from "react";
import up from "./images/up.png";
import down from "./images/down.png";
import './checkboxSelector.css';
import { Collapse, Tooltip } from "@material-ui/core";
import util from "../../utils/miniUtils";

export default function CheckBoxSelector( { dataArray = [], title = "", updateData, isOpenByDeafult = null } ) {

    const OPEN = 1;
    const CLOSED = 0;
    // Create UNIQUE ID for "Select ALL" of this component instance
    const unique_id = `field_id_${title.split( " " ).join( "_" )}`;

    // handle Collapse of list of data
    const [open, setOpen] = useState( isOpenByDeafult ? OPEN : CLOSED );
    const [list, setList] = useState( [] );
    const [listBase, setListBase] = useState( [] );
    const [check, setCheck] = useState( false );

    useEffect( () => {
        setList( dataArray );
        setListBase( dataArray );
    }, [dataArray] );

    // Simple validation if the checkbox is checked or not
    const checked = ( opc ) => setOpen( opc === open ? CLOSED : opc );

    const quickSearch = async ( e ) => {
        let temp = [];
        let value = e.target.value;
        if ( value === "" ) {
            setList( dataArray );
            return;
        }

        for ( let i = 0; i < listBase.length; i++ ) {
            if (
        listBase[i].value?.toLowerCase().includes( value.toLowerCase() ) ||
        listBase[i].name?.toLowerCase().includes( value.toLowerCase() )
            ) {
                temp.push( listBase[i] );
            }
        }

        setList( temp );
    };

    // Update checkmark
    const setChecked = ( e, index ) => {
        let t = [...dataArray]; // External data
        let t2 = [...list]; // local data
        t2[index].checked = e.target.checked;
        for ( let i = 0; i < dataArray.length; i++ ) {
            if ( t[i].id === t2[index].id ) {
                t[i] = t2[index];
            }
        }
        updateData( t );
    };

    // Utility, just to add class of scroll if result are more than 10
    const needToUseScroll = ( info ) => {
        if ( info.length > 10 ) return "defaultScroll350";
        return "";
    };

    // Utility to change icon on Collapse, depending if is open or closed
    const caretIcon = ( opc, open ) => {
        return open === opc ? (
            <img src={up} alt="" style={{ width: "10px" }} />
        ) : (
            <img src={down} alt="" style={{ width: "10px", height: "8px" }} />
        );
    };

    const onSelectAll = ( e ) => {
        let t = [...dataArray];
        t.map( ( d ) => ( d.checked = e.target.checked ) );
        setCheck( e.target.checked );
        updateData( t );
    };

    const onResetSelectAll = ( ) => {
        let t = [...dataArray];
        for ( let i = 0; i < t.length; i++ ) {
            delete t[i].checked;
        }
        setCheck( false );
        updateData( t );
    };

    return (
        <div className="w-100">
            <div
                className="isPointer d-flex justify-content-between"
                onClick={() => checked( 1 )}
            >
                <span>
                    <b>{title}</b>
                </span>
                <span>{caretIcon( OPEN, open )}</span>
            </div>
            <Collapse in={open === 1} collapsedHeight={0}>
                <div >
                    <div className="d-flex fontSize13 align-items-center align-content-center justify-content-between isCursor text-black-50 p-0">
                        <div className="d-flex pt-0 pb-0">
                            <input
                                className="form-control-sm"
                                type="checkbox"
                                checked={check}
                                id={unique_id}
                                onChange={ () => null }
                                onClick={( e ) => onSelectAll( e )}
                            />
                            <label
                                htmlFor={unique_id}
                                className="pl-2  d-flex align-items-center mb-0"
                            >
                Select All
                            </label>
                        </div>
                        <div>
                            <div className="isPointer" onClick={onResetSelectAll}>
                Reset
                            </div>
                        </div>
                    </div>
                    <hr className="pt-1 pb-0 m-0" />
                    <input
                        type="search"
                        className="form-control"
                        onChange={quickSearch}
                    />
                    <hr className="pt-1 pb-0 m-0" />
                    <div className={`${needToUseScroll( list )}`}>
                        {list.map( ( single, index ) => {
                        // Create UNIQUE ID using the id of the single data
                            const element_id = `element_id_${util.randomStringPassword()}`;

                            return (
                                <Fragment key={index}>
                                    <div>
                                        <div className="d-flex fontSize13 align-items-center align-content-center isCursor hoverSelectOption text-black-50">
                                            <input
                                                type="checkbox"
                                                id={element_id}
                                                checked={single.checked ? single.checked : false}
                                                onChange={( e ) => setChecked( e, index )}
                                            />
                                            {
                                                single.name === undefined ?
                                                    <label htmlFor={element_id} className="p-0 m-0 pl-2">
                                                        <span>{single.value}</span>
                                                    </label>
                                                    :
                                                    <Tooltip title={`${single.name} - ${single.email}`}>
                                                        <label htmlFor={element_id} className="p-0 m-0 pl-2">
                                                            <span>{single.value}</span>
                                                        </label>
                                                    </Tooltip>
                                            }
                                        </div>
                                    </div>
                                    <div className="border-darken-1" />
                                </Fragment>
                            );
                        } )}
                    </div>
                </div>
            
            </Collapse>
            

            <hr className="p-2 m-0" />
        </div>
    );
}