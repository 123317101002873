import React, { useState, useEffect } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { TextField, Tooltip } from "@material-ui/core";

const CountryAutoFill = ( { newState,onChangeHandler } ) => {
    const [countryState, setCountryState] = useState( {
        loading: false,
        countries: [],
        errorMessage: "",
    } );

    useEffect( () => {
        const fetchData = async () => {
            try {
                // fetch spinner
                setCountryState( {
                    ...countryState,
                    loading: true,
                } );

                //  fetch data
                const dataUrl = `https://restcountries.com/v3.1/all`;
                const response = await axios.get( dataUrl );
                setCountryState( {
                    ...countryState,
                    countries: response.data,
                    loading: false,
                } );
            } catch ( error ) {
                setCountryState( {
                    ...countryState,
                    loading: false,
                    errorMessage: "Sorry Something went wrong",
                } );
            }
        };

        fetchData();
    }, [] );
    const { loading, countries } = countryState;
    // console.log( "loading", loading );
    // console.log( "countries", countries );
    // console.log( "errorMessage", errorMessage );

    // const [selectedCountry, setSelectedCountry] = useState();
    // console.log( "selectedCountry", selectedCountry );

    //   find selected country data
    //search selected country
    const searchSelectedCountry = countries.find( ( obj ) => {
        if ( obj.name.common === newState.country ) {
            return true;
        }
        return false;
    } );
    // console.log( "searchSelectedCountry", searchSelectedCountry );
    const handleChangePhone = ( e ) => {
        const inputValue = e.target.value;
        const formattedValue = inputValue.replace( /[^0-9]/g, '' ); // Remove non-numeric characters
      
        if ( formattedValue.length <= 10 ) {
            onChangeHandler( e );
        }
    };

    return (
        <React.Fragment>
                   

            <div className="mb-3">
                { loading === true?
                    <div>
                        <p>...loading</p>
                    </div>:
                    <div>
            
                        <div>
                            <Tooltip title={newState.country ? newState.country :"No country selected"}>
                                <TextField
                                    select
                                    size="small"
                                    type="number"
                                    variant="outlined"
                                    name="country"
                                    label="Country"
                                    value={newState.country}
                                    onChange={onChangeHandler}
                                    className='form-control'
                                    // error={newState.phone_mobile.length !== 10}
                                    // helperText={newState.phone_mobile.length !== 10 ? "Phone number must be 10 digits" : ""}
                                    InputLabelProps={{ shrink: true }}
                                    SelectProps={{
                                        native: true
                                    }}
                                >
                                    <optgroup label="Most Active">
                                        <option value="">--</option>
                                        <option value="United States">USA</option>
                                        <option value="Canada">Canada</option>
                                        <option value="India">India</option>
                                        <option value="Australia">Australia</option>
                                        <option value="Mexico">Mexico</option>
                                        <option value="Japan">Japan</option>
                                        <option value="Germany">Germany</option>
                                        <option value="Puerto Rico">Puerto Rico</option>
                                    </optgroup>
                                    <optgroup label="All Countries">
                                        <option value=""/>
                                        {countries.map( ( item ) => {
                                            return (
                                                <option key={uuidv4()} value={item.name.common}>
                                                    {item.name.common}
                                                </option>
                                            );
                                        } )}
                                    </optgroup>
                                </TextField>
                            </Tooltip>
                            {/* <select
                                        value={selectedCountry}
                                        onChange={( e ) => setSelectedCountry( e.target.value )}
                                        className='form-control w-100'
                                    >
                                        <option>--Select Country--</option>
                                        {countries.map( ( item ) => {
                                            return (
                                                <option key={uuidv4()} value={item.name.common}>
                                                    {item.name.common}
                                                </option>
                                            );
                                        } )}
                                    </select> */}
                        </div>
                        <div className="mt-3">
                            {searchSelectedCountry && (
                                <div className="d-flex row">
                                    <div className="col-md-1 mt-2 form-control ml-3">
                                        <img
                                            style={{ width:'25px',height:'20px' }}
                                            src={
                                                searchSelectedCountry &&
                            searchSelectedCountry.flags.png
                                            }
                                            alt=""
                                        />
                                    </div>
                                    <div className="col-md-2 mt-2 form-control ml-1">
                                        <p>
                                            {searchSelectedCountry &&
                          searchSelectedCountry.idd.root}
                                            {searchSelectedCountry &&
                          searchSelectedCountry.idd.suffixes[0]}
                                        </p>
                                    </div>
                                    <div className="col-md-8 ml-1">
                                        <TextField
                                            style={{ width:'106%' }}
                                            variant="outlined"
                                            size="small"
                                            type="number"
                                            placeholder="Phone"
                                            className='mt-2 form-control'
                                            // country={newState.country}
                                            name={"phone_mobile"}
                                            value={newState.phone_mobile}
                                            // onChange={phone => {
                                            //     let e={ target:{ name:"phone_mobile", value: phone } };
                                            //     onChangeHandler( e );
                                            // }}
                                            onChange={handleChangePhone}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
              
                    </div>
                }
            </div>
            
        </React.Fragment>
    );
};

export default CountryAutoFill;