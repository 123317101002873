import React from 'react';
import FieldValue from "./components/FieldValue";
import Input from "./components/input";
import SelectorCTC from "./components/SelectorCTC";
import useCtcCalculation from "./useCtcCalculation";
import { OFFER_TYPE, TALENT_ENGAGEMENT_TYPE } from "./components/constants";
import BySellRate from "./components/BySellRate";
import ByMargin from "./components/ByMargin";
import JobSettings from "./components/JobSettings";
import SaveChangesButton from "./components/SaveChangesButton";
import { Drawer } from "@material-ui/core";
import SectionSelector from './components/sectionSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import util from '../../../../utils/miniUtils';
import { CtcComments } from './components/CtcComments';

export default function CtcDrawer( { showDrawer, closeDrawer,  talent, jobTemp, reloadData } ) {
    
    const { ctc, onChange, saveComments, onChangeSlider, currentJobSettings, showSaveButton, saveChangesOnMarginEstimate, selectSection } = useCtcCalculation( talent, jobTemp, reloadData );
    const { markUp, billableDaysInYear, workingHoursInDay, averageBillableDaysInMonth } = currentJobSettings(  reloadData );
    return (
        <Drawer anchor="right" open={showDrawer} onClose={closeDrawer}>
            <div style={{ maxWidth:window.innerWidth * .30 ,minWidth:window.innerWidth * .30 }}>
                <div className="drawerTitle_JP" >
                    <h4>{ talent?.id } - { talent?.name }</h4>
                </div>
                <div>
                    <div className="container text-size-14">
                        <div className="d-flex justify-content-between pt-1 pb-1">
                            <b className="text-size-19">Margin Estimate</b>
                            <SaveChangesButton saveChangesOnMarginEstimate={saveChangesOnMarginEstimate} showSaveButton={showSaveButton} />
                        </div>

                        <SelectorCTC typeSelector={TALENT_ENGAGEMENT_TYPE} title="Talent Engagement Type" name="employmentType" value={ctc.employmentType} onChange={onChange} />
                        {
                            ctc.employmentType === 'Fulltime Employee' ?
                                <Input isCurrency={true} job={jobTemp} title="eJ Offer Per Year" name="ejOffer" value={ctc.ejOffer} onChange={onChange} />
                                :
                                <Input isCurrency={true} job={jobTemp} title="eJ Offer" name="ejOffer" value={ctc.ejOffer} onChange={onChange} />
                        }
                        {ctc.employmentType !== "Fulltime Employee" ? <SelectorCTC typeSelector={OFFER_TYPE} title="Offer Type" name="offerType" value={ctc.offerType} onChange={onChange} /> : null}
                        
                        <Input isCurrency={true} job={jobTemp}  title="Talent Expectation" name="talentExpectation" value={ctc.talentExpectation} onChange={onChange} />
                        <FieldValue country={jobTemp.country} name="Cost To Company Per Hour" value={ctc.ctcPerHour.toFixed( 2 )} />
                        <FieldValue country={jobTemp.country} name="Cost To Company Per Day" value={ctc.ctcPerDay.toFixed( 2 )} />
                        <FieldValue country={jobTemp.country} name="Cost To Company Per Month" value={ctc.ctcPerMonth.toFixed( 2 )} />
                        <FieldValue country={jobTemp.country} name="Cost To Company Per Annum" value={ctc.ctcPerAnnum.toFixed( 2 )} />
                        <br />
                        {/* <b className="text-size-19">Job Settings</b> */}
                        <div className="mt-1"/>
                        <JobSettings onChange={onChange} ctc={ctc} markUp={markUp} additionalDays={ctc.additionalDays} billableDaysInYear={billableDaysInYear} workingHoursInDay={workingHoursInDay} averageBillableDaysInMonth={averageBillableDaysInMonth} job={jobTemp} />
                        <hr />
                        <SectionSelector mode={ctc.mode} selectSection={selectSection}  />
                        {
                            ctc.mode === 1 ? 
                                <BySellRate job={jobTemp} ctc={ctc} onChange={onChange} /> 
                                : 
                                <ByMargin job={jobTemp} ctc={ctc} onChange={onChangeSlider} />
                        }
                        <br />
                        <div>
                            <CtcComments ctc={ctc} saveComments={saveComments}  />
                        </div>
                    </div>
                </div>
            </div>
        </Drawer>
        
    );
}