import React, { Fragment, useEffect } from "react";
import PersonalContainer from "./personal_information/PersonalContainer";
import util from "../../../components/utils/miniUtils";
import { Paper, Tab, Tabs } from "@material-ui/core";
import usePersonalInformation from './personal_information/usePersonalInformation';
import Sidebar from "./sidebar/Sidebar";
import SkillsContainer from "./skills/SkillsContainer";
import WorkHistoryContainer from "./work_history/WorkHistoryContainer";
import SocialMediaContainer from "./social_media/SocialMediaContainer";
import { TalentStore } from "./TalentStore";
import CertificationsContainer from "./certifications/CertificationsContainer";
import NotesContainer from "./notes/NotesContainer";
import JobsAppliedContainer from "./jobs_applied/JobsAppliedContainer";
import TestsContainer from "./test_talent/TestsContainer";
import EvaluationsContainer from "./evaluations/EvaluationsContainer";
import EducationContainer from "./education/EducationContainer";
import { TALENT_ROLE } from "../../../constants";
import Employee from "./employee/Employee";

export default function ProfileComponent( { talent_id, elevation = 1, hideEmployeeTab = false, modal } ) {
	
    // Retrieve all methods and setStates you want to share
    const { getUserInformation } = usePersonalInformation( talent_id );

    const handleChange = ( event, newValue ) => {
        setSelectedTab( newValue );
    };

	
    const talent = TalentStore.useState( state => state.talent );
    // console.log( util.user.role , TALENT_ROLE , talent?.employee_id );
    const [selectedTab, setSelectedTab] = React.useState( "skills" );
	
    useEffect( () => {
        getUserInformation( talent_id ).then( null );
    }, [] );

    function TabPanelCustom( { children, selectedTab, tabValue } ) {
        if ( selectedTab !== tabValue ) return "";
        return children;
    }
    
	
    if ( talent === null ) {
        return util.LOADING_SCREEN( true );
    }

    return (
		
        <Fragment>
            <div className="container-fluid mb-5">
                <div className="row">
                    {!modal && <div className="col-md-2">
                        <Paper elevation={elevation}>
                            <div className="p-2"><Sidebar/></div>
                        </Paper>
                    </div>}
                    <div className={modal ? "col-md-12" :"col-md-10"}>
                        <Paper elevation={elevation}>
                            <div className="p-2"><PersonalContainer /></div>
                        </Paper>
                        <hr/>
                        {
                            util.user.role === TALENT_ROLE ?
                                <Tabs
                                    value={selectedTab}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant={"scrollable"}
                                >
                                    <Tab value="skills" label="Skills"/>
                                    <Tab value="certification" label="Certification"/>
                                    <Tab value="work_history" label="Work History"/>
                                    <Tab value="social_media" label="Social Media"/>
                                    <Tab value="education" label="Education"/>
                                </Tabs>
                                :
                                <Tabs
                                    value={selectedTab}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant={"scrollable"}
                                >
                                    { talent.employee_id && !hideEmployeeTab && <Tab value="employee" label="Employee"/> }
                                    <Tab value="skills" label="Skills"/>
                                    <Tab value="certification" label="Certification"/>
                                    <Tab value="work_history" label="Work History"/>
                                    <Tab value="social_media" label="Social Media"/>
                                    <Tab value="education" label="Education"/>
                                    <Tab value="jobs_applied" label="Jobs Applied"/>
                                    <Tab value="notes" label="Notes"/>
                                    <Tab value="test" label="Test"/>
                                    <Tab value="evaluations" label="Evaluations"/>
                                </Tabs>
                        }
                        
                        { talent.employee_id && !hideEmployeeTab &&
                        <TabPanelCustom tabValue="employee" selectedTab={selectedTab}>
                            <Employee employee_id={talent.employee_id}/>
                        </TabPanelCustom>
                        }
                        
                        
                        <TabPanelCustom tabValue="skills" selectedTab={selectedTab}>
                            <SkillsContainer talent_id={talent_id}/>
                        </TabPanelCustom>
						
                        <TabPanelCustom tabValue="certification" selectedTab={selectedTab}>
                            <CertificationsContainer talent_id={talent_id}/>
                        </TabPanelCustom>
						
                        <TabPanelCustom tabValue="work_history" selectedTab={selectedTab}>
                            <WorkHistoryContainer talent_id={talent_id}/>
                        </TabPanelCustom>
						
                        <TabPanelCustom tabValue="social_media" selectedTab={selectedTab}>
                            <SocialMediaContainer talent_id={talent_id}/>
                        </TabPanelCustom>
						
                        <TabPanelCustom tabValue="education" selectedTab={selectedTab}>
                            <EducationContainer talent_id={talent_id}/>
                        </TabPanelCustom>
						
                        <TabPanelCustom tabValue="jobs_applied" selectedTab={selectedTab}>
                            <JobsAppliedContainer talent_id={talent_id}/>
                        </TabPanelCustom>
						
                        <TabPanelCustom tabValue="notes" selectedTab={selectedTab}>
                            <NotesContainer talent_id={talent_id}/>
                        </TabPanelCustom>
						
						
                        <TabPanelCustom tabValue="test" selectedTab={selectedTab}>
                            <TestsContainer talent_id={talent_id}/>
                        </TabPanelCustom>
						
                        <TabPanelCustom tabValue="evaluations" selectedTab={selectedTab}>
                            <EvaluationsContainer talent_id={talent_id} talent={talent}/>
                        </TabPanelCustom>
					
                    </div>
                </div>
            </div>
            {util.LOADING_SCREEN( talent.loading )}
        </Fragment>
    );
}

