import React, { useState } from 'react';
import { useEffect } from 'react';
import util from '../../../utils/miniUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAlt, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Paper, Tooltip } from '@material-ui/core';
import ExAchievementsAccordion from '../ExceptionalAchievments/ExAchievementsAccordion';
import ReviewDetailsAccordion from './ReviewDetailsAccordion';
import Status from '../Status/Status';
import MediumDeviceStatus from '../Status/MediumDeviceStatus';
import StartReviewAccordion from './StartReviewAccordion';
import AppraisalLog from '../AppraisalLog';





function StartReview( { appraisal_id,handleBack, user, role } ) {

    const [items, setItems] = useState( [] );
    const [loading, setLoading] = useState( true );
    const [isAllComplete, setIsAllComplete] = useState( true );
    const [ appraisalInfo, setAppraisalInfo ] = useState( null );
    const [evaluated,setEvaluated]=useState( false );
    
    useEffect( ( ) => {
        getDetails( );
    },[] );


    // request for evaluation all items
    const getDetails = async (  ) => {
        try {
            setLoading( true );
            const request = await util.axios.get( `get_appraisal_items_evaluator/${appraisal_id }` );
            const { error, RO,appraisal_info,checkIfCompleted, msg } = request.data;
            if ( error ) throw msg;
            setAppraisalInfo( appraisal_info );
            setItems( RO );
            setEvaluated( checkIfCompleted );
            setLoading( false );
            // console.log( appraisal_info );
            // console.log( RO, evaluated );

        } catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }
    };
    const checkIfCompleted = async (  ) => {
        try {
            const request = await util.axios.get( `get_appraisal_items_evaluator/${appraisal_id }` );
            const { error, checkIfCompleted,RO,msg } = request.data;
            if ( error ) throw msg;
            setEvaluated( checkIfCompleted );
            setItems( RO );
            // console.log( appraisalInfo, evaluated );

        } catch ( error ) {
            util.handleError( error );
        }
    };

   
    //request for moving status or updating status
    const finishAppraisal = async (  ) => {
        try {
            const res = { response: true };            
            const request = await util.axios.put( `/appraisal_status_update/${appraisal_id}`, res );
            const { error, msg, appraisalComplete } = request.data;
            if ( error ) throw msg;
            handleBack();
            setIsAllComplete( !appraisalComplete );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    

    function sumOfAllWeights() {
        let totalWeight = 0;
        {
            items &&
				items.map( ( item ) => {
				    totalWeight = totalWeight + parseInt( item.weight );
				} );
            return totalWeight;
        }
    }


    if ( loading ) return util.LOADING_SCREEN( loading );

    return (
        <>
            <div className='w-100'>
                <Tooltip title="Previous">  
                    <span>
                
                        <button className="isPointer btn btn-outline-primary btn-sm rounded-circle mt-1" onClick={handleBack}>
                            <FontAwesomeIcon icon={faArrowLeft} /> 
                        </button>
                    </span>
                </Tooltip>
            </div>
            <Status appraiseeStatus={appraisalInfo.status} l2_exist={appraisalInfo.l2_exist} l3_exist={appraisalInfo.l3_exist}/>
            <MediumDeviceStatus appraiseeStatus={appraisalInfo.status} l2_exist={appraisalInfo.l2_exist} l3_exist={appraisalInfo.l3_exist}/>
           
            <Paper elevation={1}>
                <div className="container-fluid mt-3">
                    <div className="row">
                        
                        <div className="col-md-12 d-flex justify-content-between mb-3 px-4 py-2">
                            <div className="userPicture d-flex justify-content-between align-items-center">
                                <span className='p-1'><FontAwesomeIcon color='dodgerblue' size="1x" icon={faUserAlt} /></span>
                                <span className='p-1'>{user.name}</span>
                            </div>
                            <div className="appraisalWeight"><b>Total weight:{sumOfAllWeights()}%</b></div>
                        </div>
                    </div>
                    {
                        items && items.sort( ( a, b )=> a.type - b.type ).map( ( item, index ) => {
                            console.log( appraisalInfo.status );
                            if( ( role === "employee" && appraisalInfo.status === 13 )||  ( ( role.includes( 'L1' ) && appraisalInfo.status === 14 ) && ( util.user.employee_id === item.l1_evaluator ) )  || ( ( role.includes( 'L2' ) && appraisalInfo.status === 15 ) && ( util.user.employee_id === item.l2_evaluator ) ) ||
                             ( ( role.includes( 'L3' ) && appraisalInfo.status === 16 ) && ( util.user.employee_id === item.l3_evaluator ) ) || ( ( role.includes( 'HR' ) && appraisalInfo.status === 17 ) && ( util.user.employee_id === item.hr_evaluator )  ) ){
                                return (
                                    <StartReviewAccordion key={index} item={item} role={role} reloadCheck={() => checkIfCompleted()} status={appraisalInfo.status} l2_exist={appraisalInfo.l2_exist} l3_exist={appraisalInfo.l3_exist}/>
                                );
                            }
                            else{
                                return (
                                    <ReviewDetailsAccordion key={index} item={item} role={role} status={appraisalInfo.status} l2_exist={appraisalInfo.l2_exist} l3_exist={appraisalInfo.l3_exist}/>
                                );
                            }
                        } )
                    }
                    {( ( role.includes( 'HR' ) && appraisalInfo.status === 17 ) && ( util.user.employee_id === items[0].hr_evaluator ) ) ? ( 
                        <ExAchievementsAccordion appraisal_id={appraisal_id} role={role} status={appraisalInfo.status}/>
                    ): ""}
                    {( ( role === "employee" && appraisalInfo.status === 18  ) || ( ( role.includes( 'HR' ) && appraisalInfo.status === 18 )  && ( util.user.employee_id === items[0].hr_evaluator ) ) ) ?  ( 
                        <ExAchievementsAccordion appraisal_id={appraisal_id} role="employee" status={appraisalInfo.status}/>
                    ): ""}
                    <div>
                        <AppraisalLog appraisalInfo={appraisalInfo}/>
                    </div>
                    
                    { ( ( role === "employee" && appraisalInfo.status === 13 )||  ( ( role.includes( 'L1' ) && appraisalInfo.status === 14 ) && ( util.user.employee_id === items[0].l1_evaluator ) )  || ( ( role.includes( 'L2' ) && appraisalInfo.status === 15 ) && ( util.user.employee_id === items[0].l2_evaluator ) ) ||
                             ( ( role.includes( 'L3' ) && appraisalInfo.status === 16 ) && ( util.user.employee_id === items[0].l3_evaluator ) ) || ( ( role.includes( 'HR' ) && appraisalInfo.status === 17 )&& ( util.user.employee_id === items[0].hr_evaluator ) ) ) ?
                        <div className="row">
                            <div className="col-md-12 d-flex justify-content-end">
                                <Tooltip title={evaluated ?"You can finish this appraisal": "You can't finish this appraisal until you filled up all scores"}> 
                                    <span>
                                        <button  disabled={!evaluated} onClick={finishAppraisal} className="btn btn-sm btn-success mb-3">Finish</button>
                                    </span>
                                </Tooltip> 
                        
                            </div>
                        </div> : ""}

                 

                </div>
            </Paper>
        </>
    );
}
export default StartReview;