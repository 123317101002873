import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Switch, withStyles } from '@material-ui/core';
import React, { Fragment, useEffect } from 'react';
import { useState } from 'react';
import util from '../../utils/miniUtils';
import NotificationsByModule from './NotificationsByModule';


const PrimarySwitch = withStyles( {
    switchBase: {
        color: "#fff",
        '&$checked': {
            color: "#1a7fe4",
        },
        '&$checked + $track': {
            backgroundColor: "#1a7fe4",
        },
    },
    checked: {},
    track: {},
} )( Switch );

const NotificationModal = ( { show, handleClose } ) => {

    const [loading, setLoading] = useState( false );
    const [permissions,setPermissions] = useState( {
        email_switch: false,
        screen_switch: false,
        job_mail: false,
        job_screen: false,
        talent_mail: false,
        talent_screen: false,
        asset_mail: false,
        asset_screen: false,
        appraisal_mail: false,
        appraisal_screen: false,
        workload_mail: false,
        workload_screen: false
    } );
   

    const handleChange = ( e ) => {
        let { name } = e.target;
        let temp = { ...permissions };
        temp[name] = !permissions[name];
        setPermissions( Object.assign( {}, temp ) );
        // setPermissions( { ...permissions,[name]:!checked } );
    };

    async function getNotificationPermissions() {
        setLoading( true );
        try {
            const request = await util.axios.get( `/notifications/get_permissions` );
            const { error, msg, data } = request.data;
            setPermissions( data );
            if ( error ) throw msg;
        } catch ( e ) {
            util.handleError( e );
        }
        setLoading( false );

    }
    async function saveNotificationPermissions() {
        try {
            const request = await util.axios.put( `/notifications/edit_permissions`, permissions );
            const { error, msg } = request.data;
            if ( error ) throw msg;
        } catch ( e ) {
            util.handleError( e );
        }
        handleClose();

    }
    useEffect( () => {
        getNotificationPermissions().then( null );
    }, [] );

    return (
        <Fragment>
            <Dialog
                open={show}
                onClose={()=>{saveNotificationPermissions();}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'sm'}
                fullWidth={true}
            >
                <DialogTitle id="alert-dialog-title">
                    {"Customize Your Notifications"}
                </DialogTitle>
                {loading ? (
                    <div style={{ width: "100%", height: "100px" }}>
                        <p>Customizing Notifications..</p>
                    </div>
                ) : (
                    <div >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <div className='d-flex justify-content-between align-items-center'>
                                    <b style={{ color: 'black' }}>All Modules</b>
                                    {/* <b style={{ color: 'black' }}>All Email Notifications</b> */}
                                    <b style={{ color: 'black' }}>All On-Screen Notifications</b>
                                </div>
                                <hr style={{ height: 0, borderTop: '2px solid black' }} />
                                <div className='d-flex justify-content-between align-items-center'>
                                    <b style={{ color: 'black' }}>Turn On/Off All Modules</b>
                                    {/* <PrimarySwitch className='my_switch'
                                        checked={permissions.email_switch}
                                        onChange={handleChange}
                                        name="email_switch"
                                        color="primary"
                                        size='small'
                                    /> */}
                                    <PrimarySwitch className='screen_switch'
                                        checked={permissions.screen_switch}
                                        onChange={handleChange}
                                        name="screen_switch"
                                        color="primary"
                                        size='small'
                                    />
                                </div>
                            </DialogContentText>
                            <div className='d-flex justify-content-between align-items-center'>
                                <b>Module</b>
                                {/* <b>Email Notifications</b> */}
                                <b>On-Screen Notifications</b>
                            </div>
                            <hr style={{ height: 0, borderTop: '2px solid black' }} />
                            <div className='d-flex justify-content-between align-items-center'>
                                <span>Jobs</span>
                                {/* <PrimarySwitch
                                    checked={permissions.job_mail}
                                    onChange={handleChange}
                                    name="job_mail"
                                    color="primary"
                                    size='small'
                                /> */}
                                <PrimarySwitch className='screen_switch'
                                    checked={permissions.job_screen}
                                    onChange={handleChange}
                                    name="job_screen"
                                    color="primary"
                                    size='small'
                                />
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <span>Talents</span>
                                {/* <PrimarySwitch className='talent_switch'
                                    checked={permissions.talent_mail}
                                    onChange={handleChange}
                                    name="talent_mail"
                                    color="primary"
                                    size='small'
                                /> */}
                                <PrimarySwitch className='screen_switch'
                                    checked={permissions.talent_screen}
                                    onChange={handleChange}
                                    name="talent_screen"
                                    color="primary"
                                    size='small'
                                />
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <span>Assets</span>
                                {/* <PrimarySwitch className='asset_switch'
                                    checked={permissions.asset_mail}
                                    onChange={handleChange}
                                    name="asset_mail"
                                    color="primary"
                                    size='small'
                                /> */}
                                <PrimarySwitch className='screen_switch'
                                    checked={permissions.asset_screen}
                                    onChange={handleChange}
                                    name="asset_screen"
                                    color="primary"
                                    size='small'
                                />
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <span>Appraisal</span>
                                {/* <PrimarySwitch className='appr_switch'
                                    checked={permissions.appraisal_mail}
                                    onChange={handleChange}
                                    name="appraisal_mail"
                                    color="primary"
                                    size='small'
                                /> */}
                                <PrimarySwitch className='screen_switch'
                                    checked={permissions.appraisal_screen}
                                    onChange={handleChange}
                                    name="appraisal_screen"
                                    color="primary"
                                    size='small'
                                />
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <span>Workload</span>
                                {/* <PrimarySwitch className='work_switch'
                                    checked={permissions.workload_mail}
                                    onChange={handleChange}
                                    name="workload_mail"
                                    color="primary"
                                    size='small'
                                /> */}
                                <PrimarySwitch className='screen_switch'
                                    checked={permissions.workload_screen}
                                    onChange={handleChange}
                                    name="workload_screen"
                                    color="primary"
                                    size='small'
                                />
                            </div>
                            {/* <div className='mt-2'>
                                <b>Customize Noification By Module</b>
                            </div>
                            <hr style={{ height: 0, borderTop: '2px solid black' }} /> */}
                           
                            {/* <NotificationsByModule /> */}

                        </DialogContent>
                        <DialogActions>
                            <button className='btn btn-sm btn-primary' onClick={() =>{saveNotificationPermissions();}} color="default">
                                Close
                            </button>

                        </DialogActions>
                    </div>
                )}
            </Dialog>
            {util.LOADING_SCREEN( loading )}
        </Fragment>
    );
};

export default NotificationModal;
