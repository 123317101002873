import React, { useEffect, useState } from 'react';
import { createTheme, MuiThemeProvider, Popover } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, } from '@fortawesome/free-solid-svg-icons';
import util from '../../../utils/miniUtils';
import { Modal } from 'react-bootstrap';

const TalentStatusSelector = ( { statusName, status, candidateId, positionId, getPositionCandidates, disabled=false } ) => {
    const [statusList, setStatusList] = useState( [] );
    // Comment Modal related state variables
    const [commentModalShow, setCommentModalShow] = useState( false );
    const [state, setState] = useState( { status: status, comment: "" } );

    // Anchor and open variable for the popover. 
    const [anchorEl, setAnchorEl] = React.useState( null );
    const open = Boolean( anchorEl );

    // Triggering the popover on the double click
    const handleClick = ( event ) => {
        if( !disabled ) {
            setAnchorEl( event.currentTarget );
        }
    };

    const getStatusList = async () => {
        try{
            const request = await util.axios.get( 'job_talent_status/list' );
            const { data, error, msg } = request.data;
            if( error ) throw msg;
            setStatusList( data );
        } catch ( e ) {
            util.handleError( e );
        }
    };
    
    const handleChange = ( val ) => {
        // const { name, value } = e.target;
        setState( prev =>( { ...prev, status: val } ) );
        setCommentModalShow( true );
        handleClose();
    };

    const handleCommentChange = ( e ) => {
        const { name, value } = e.target;
        setState( prev =>( { ...prev, [name]: value } ) );
    };

    const handleClose = async ( ) => {
        setAnchorEl( null );
    };

    const handleCancel = () => {
        setState( { status: status, comment: "" } );
        // resetting the anchor for the popover
        setAnchorEl( null );
    };

    const theme = createTheme( {
        overrides: {
            MuiTooltip: {
                tooltip: {
                    maxWidth: "250px",
                    fontSize: "0.85em",
                }
            }
        }
    } );

    useEffect( () => {
        getStatusList();
    },[status] );

    function editValue() {
        return <Popover
            id={open ? 'text-input-popover' : undefined}
            open={open}
            anchorEl={anchorEl}
            onClose={handleCancel}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}>
            <div className='d-flex align-items-start flex-column'>
                <div className='d-flex justify-content-between w-100 align-items-center mb-1 p-1'>
                    <label htmlFor="status"><strong>Status</strong></label>
                    <span className='btn btn-outline-danger' onClick={handleCancel}><FontAwesomeIcon icon={faTimes} /></span>
                </div>
                <StatusDropDown list={statusList} value={state.status} handleChange={handleChange} />
                {/* <div className="d-flex align-items-end m-1">
                    <span className='btn btn-outline-danger' onClick={handleCancel}><FontAwesomeIcon icon={faTimes} /></span>
                    <span className='btn btn-outline-primary' onClick={handleClose}><FontAwesomeIcon icon={faCheck} /></span>
                </div> */}
            </div>

        </Popover>;
    }

    return <>
        <MuiThemeProvider theme={theme}>
            {editValue()}
            <div className='isPointer' onClick={handleClick}>
                {statusName ? statusName : util.candidateStatus( status )}
            </div>
        </MuiThemeProvider>
        <CommentModal            
            show={commentModalShow} 
            handleClose={() => setCommentModalShow( false )} 
            form={state}
            setForm={setState}
            candidateId={candidateId}
            positionId={positionId}
            handleChange={handleCommentChange}
            getPositionCandidates={getPositionCandidates}
        />
    </>;
};

export default TalentStatusSelector;

const StatusDropDown = ( { list, value, handleChange } ) => {
    return <div className='talentStatusSelectorContainer'>
        <ul className="talentStatusSelector">
            {list ? list.map( el => ( el.is_parent ? 
                <li key={el.jts_id}
                    className={`talentStatusSelector-parentItem talentStatusSelectorItem ${el.jts_id == value ? ` bg-primary text-light` : ''}`} onClick={() => handleChange( el.jts_id )}>
                    {el.name}
                </li> :
                <li key={el.jts_id}
                    className={`talentStatusSelector-childItem talentStatusSelectorItem ${el.jts_id == value ? ` bg-primary text-light` : ''}`} onClick={() => handleChange( el.jts_id )}>
                    {el.name}
                </li>
            ) ) : ""}
        </ul>
    </div>;
};

const CommentModal = ( { show, handleClose, form, positionId, candidateId, handleChange, getPositionCandidates, setForm } ) => {
    const [statusName, setStatusName] = useState( '' );

    const getStatusName = async ( id ) => {
        try{
            const request = await util.axios.get( `/job_talent_status/byId/${id}` );
            const { data, error, msg } = request.data;
            if( error ) throw msg;
            setStatusName( data[0]?.name ); 
        } catch ( e ) {
            util.handleError( e );
        }
    };

    const updateCandidateStatus = async( updatedData, position_id, candidate_id ) => {
        try {
            const request = await util.axios.put( `/requirements/update_position_candidate/${position_id}/${candidate_id}`, updatedData );
            const { error, msg } = request.data;
            if( error ) throw msg;
            util.toast().success( msg );
            getPositionCandidates( position_id );
            setForm( prev => ( { status: prev.status, comment: "" } ) );
        } catch ( error ) {
            util.handleError( error );  
        }
        handleClose();
    };

    const handleSubmit = () => {
        updateCandidateStatus( form, positionId, candidateId );
    };

    useEffect( () => {
        if( show ) {
            getStatusName( form.status );
        }
    },[form.status] );

    return <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Add Comment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
                <span >Status will be updated to <strong>{statusName}</strong></span>
                <div>
                    <label className='mt-3' htmlFor='comment-box'>Comment</label>
                    <textarea className="form-control" id="comment-box" name="comment" value={form.comment} onChange={handleChange}/>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <span className="btn btn-sm btn-danger" onClick={handleClose}>Close</span>
            <span className='btn btn-sm btn-success' onClick={handleSubmit}>Save</span>
        </Modal.Footer>
    </Modal>;
};
