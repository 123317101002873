import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import { createStore } from "redux";
import allReducers from "./redux/reducers/";
import './fonts/Roboto-Regular.ttf';
import { composeWithDevTools } from 'redux-devtools-extension';
import LandingPage from "./components/landing/LandingPage";
import NotFound from './components/landing/content/NotFound';

import App from "./App";

const store = createStore(
    allReducers,
    composeWithDevTools()
);

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <Switch>
                <Route exact path="/" component={LandingPage} />
                <Route exact path="/app" component={App} />
                <Route exact path="/not_found" component={NotFound} />
            </Switch>
        </Router>
    </Provider>,
    document.getElementById( "root" )
);
