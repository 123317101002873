import React, { useState, Fragment, useEffect } from "react";
import {
    HIDE_SWEET_ALERT_CHANGE_STATUS,
    HIDE_SWEET_ALERT_DELETE_JOB_POSITION,
    HIDE_SWEET_ALERT_DUPLICATE,
    HIDE_SWEET_ALERT_STOP_PROCESS
} from '../../../../../constants';
import util from "../../../../utils/miniUtils";
import { Link, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tab, Tabs } from "react-bootstrap";
// import { PositionsStore } from "../../../../positions/stores/PositionsStore";
// import JobsResult from "../../../../positions/main/JobsResult";
// import JobDetail from "../../../../positions/main/job_detail/JobDetail";
import JobsTable from "./components/JobsTable";
import { faCopy, faEdit, faEllipsisV, faEye, faIdCard, faTable, faTrash } from "@fortawesome/free-solid-svg-icons";
// import JobStatusView from "./components/JobStatusView";
import CardView from "./components/CardView";
import { MuiThemeProvider, Popover, createTheme } from "@material-ui/core";

const tableRef = React.createRef();
const LocalStorageKey = "jobColumns";
function ListOfRequirements() {
    const [showDuplicate, setShowDuplicate] = useState( false );
    const [saShow, setSaShow] = useState( false );
    const [csShow, setCsShow] = useState( false );
    const [deleteShow, setDeleteShow] = useState( false );
    const [data, setData] = useState( null );
    const [backDropOpen, setBackDropOpen] = useState( false );
    const [redirectToDuplicated, setRedirectToDuplicated] = useState( 0 );
    const [state, setState] = useState( {
        sendEmailCheckbox: false,
        jobs:[]
    } );

    const [filterState, setFilterState] = useState( {
        showFilter: false,
        id: "",
        startDate:"",
        endDate:"",
        countries:[],
        status:[],
        leadRecruiters:[],
        clients:[],
        partners:[],
        salesLead:[],
        sendEmailCheckbox: false,
    } );
    // Tablestate for setting table data
    const [tableState, setTableState] = useState( {
        orderDirection: "desc",
        search: "",
        page: 0,
        totalCount: 0,
        pageSize: 50,
    } );

    const [jobList, setJobList] = useState( [] );

    const [columns, setColumns] = useState( [
        {
            title: "Job Id",
            field: "id",
            hidden: state.id,
            type: "numeric",
            defaultSort: "desc",
            render: ( row ) => {
                return (
                    <div className="text-center">
                        <a href={`/jobs/position/detail/${row.id}`} target="_blank" rel="noopener noreferrer">
                            {util.zeroPad( row.id, 5 )}
                        </a>
                    </div>
                );
            },
        },
        { title: "",
            field:"Actions",
            sorting:false,
            render:data =>{
                return <TableMenu
                    row={data}
                    showJobDetail={()=>viewJobPosition( data.id )}
                    handleJobDuplicateBtn={()=>duplicateJobPosition( data.id )}
                    handleDelete={()=>deleteJobPosition( data.id )}
                />;
            }
        },
        {
            title: "Location",
            field: "country",
            hidden: state.country,
            render: ( row ) => {
                return (
                    <div className="text-left d-flex justify-content-start align-items-center">
                        <img width={30} src={util.countryFlag( row.country )} alt={row.country} className="mr-1"/>
                        {util.location( row.country, row.state, row.city )}
                    </div>
                );
            },
        },
        {
            title: "Job Title",
            field: "jobTitle",
            width:"40%",
            cellStyle: {
                whiteSpace: 'nowrap'
            },
            hidden:state.job_title,
            render: ( row ) => {
                return (
                    <div className="inline">
                        <div className="d-flex justify-content-start align-items-center align-self-center">
                            <a href={`/jobs/position/detail/${row.id}`} target="_blank" rel="noopener noreferrer">
                                <div className="text-align-middle">
                                    {row.job_title.length > 35 ? `${row.job_title.substr( 0,35 )}...`:row.job_title}
                                </div>
                            </a>
                            <div className="ml-auto p-2">
                                {util.suspenseDotPopOverJobDescription( row,"" )}
                            </div>
                        </div>
                    </div>
                );
            },
        },
        {
            title: "Client Reference ID",
            field: "client_requirement_id",
            hidden: state.client_requirement_id,
            render: data =>{
                return <div className="text-center">{data.client_requirement_id ? `${data.client_requirement_id}`: " "}</div>;
            }
        },
        {
            title: "Job Sell Rate",
            field: "sell_rate",
            hidden: state.sell_rate,
            render: data =>{
                return <div className="text-center">{data.sell_rate ? `${util.symbolDependingCurrencySelected( data.country )}${data.sell_rate}`: `${util.symbolDependingCurrencySelected( null )}0.0`}</div>;
            }
        },
        {
            title: "Created",
            field: "createdAt",
            hidden:state.createdAt,
            render: data => {
                return <span className="textSmallDate">{util.humanDate( data["createdAt"] )}</span>;
            }
        },
        {
            title: "Created By",
            field: "created_by",
            hidden: state.created_by,
            render: d =>{
                return (
                    <div className="text-left">
                        {util.nameToInitials( d )}
                    </div>
                );
            }
        },
        {
            title: "Updated By", field: "updated_by",
            hidden: state.updated_by,
            render: d =>{
                return (
                    <div className="text-left">
                        {util.nameToInitials( d )}
                    </div>
                );
            }
        },
        {
            title: "Partner Name",
            field: "company",
            hidden: state.company,
            render:data =>{
                return ( data["partnert_company"] ) ? data["partnert_company"]: <span className="badge badge-primary ">{data["deal_type"]}</span>;
            }
        },
        {
            title: "End Client",
            field: "client_company",
            hidden: state.client_company,
        },

        {
            title: <div className="text-center">Status</div>,
            field: "status",
            hidden: state.status,
            render: ( row ) => {
                return <div className="text-left"><span>{util.jobStatusOnlyIcons( row.status, true,  row.droppedReasons )}</span></div>;
            },
        },
        {
            title: <div className="text-center">Talents</div>,
            field: "candidates_added",
            hidden: state.candidates_added,
            render: ( d ) => {
                return <div className="text-center"><a target="_blank" rel="noopener noreferrer" href={`/jobs/position/detail/${d.id}`}>{d["candidates_added"]}</a></div>;
            },
        },

        { title: "Reporting Manager", field: "reporting_manager", hidden:state.reporting_manager, },
        { title: "Sales Lead", field: "sales_lead", hidden:state.sales_lead, },
        { title: "Lead Recruiter", field: "lead_recruiter", hidden:state.lead_recruiter, },
        { title: "Secondary Recruiter", field: "secondary_recruiter", hidden:state.secondary_recruiter, },
        { title: "Restricted to", field: "restricted_to", hidden:state.restricted_to, },
    ]  );

    const [showColumns, setShowColumns] = useState( columns.map( ( c ) => c.field ) );
    const [order, setOrder] = useState( localStorage.getItem( LocalStorageKey ) );
    // const [dragging, setDragging] = useState( false );

    //State for the read-only pop up
    const [showReadOnly, setShowReadOnly] = useState( false );
    const [readOnlyPosition, setReadOnlyPosition] = useState( '' );

    // Function for closing the Read-only view
    const handleReadOnlyClose = () => {
        setShowReadOnly( false );
    };
  
    useEffect( () => {
        if ( order ) {
            setShowColumns( JSON.parse( order ) );
        }
    }, [order] );
  
    // Function for triggering the read-only mode
    const viewJobPosition = ( position ) => {
        setReadOnlyPosition( position );
        setShowReadOnly( true );
    };

    const handleSave = () => {
        localStorage.setItem( LocalStorageKey, JSON.stringify( showColumns ) );
    };
  
    const handleChange = ( event ) => {
        const field = event.target.value;
        if ( showColumns.includes( field ) ) {
            setShowColumns( showColumns.filter( ( c ) => c !== field ) );
        } else {
            setShowColumns( [...showColumns, field] );
        }
    };
    const tableColumns = columns.filter( ( c ) => showColumns.includes( c.field ) );
    function handleColumnDrag( sourceIndex, destinationIndex ) {
        setShowColumns( ( prevArray ) => {
            const newArray = [...prevArray];
            const temp = newArray[sourceIndex];
            newArray[sourceIndex] = newArray[destinationIndex];
            newArray[destinationIndex] = temp;
            return newArray;
        } );
    }


    async function onConfirmStopProcess() {
        try {
            setSaShow( false );
            let ids = [];
            data.forEach( ( row )=>{
                if ( row.status!==3 ){
                    ids.push( row.id );
                }
            } );
            const request = await util.axios.put( `${util.url}/jobs/stop_job_process`, { jobs:ids, sendEmail: state.sendEmailCheckbox } );
            const { error, msg } = request.data;
            if ( error ) throw  msg;
            util.toast().success( msg );
            setData( { ...state, sendEmailCheckbox: false } );
            tableRef.current && tableRef.current.onQueryChange( undefined );
        }catch ( e ) {
            util.handleError( e );
        }
    }

    const reloadTableData = () => {
        tableRef.current && tableRef.current.onQueryChange( undefined );
    };

    async function onConfirmChangeStatus() {
        try {
            setCsShow( false );
            setBackDropOpen( true );
            let ids = [];
            data.forEach( ( row )=>{
                if ( row.status!==3 ){
                    ids.push( row.id );
                }
            } );
            // newStatus have the next status of the selected positions
            const newStatus = data[0].status === 1 ? 2:1;
            const request = await util.axios.put( `${util.url}/jobs/change_status_job_process`, { jobs:ids, newStatus, sendEmail: state.sendEmailCheckbox } );
            const { error, msg } = request.data;
            if ( error ) throw  msg;
            setBackDropOpen( false );
            setData( { ...state, sendEmailCheckbox: false } );
            util.toast().success( msg );
            tableRef.current && tableRef.current.onQueryChange();
        }catch ( e ) {
            setBackDropOpen( false );
            util.handleError( e );
        }
    }
 
    async function onConfirmDeleteJob() {
        try {
            setDeleteShow( false );
            setBackDropOpen( true );
            // newStatus have the next status of the selected positions
            const request = await util.axios.delete( `${util.url}/jobs/${data}` );
            const { error, msg } = request.data;
            if ( error ) throw  msg;
            setBackDropOpen( false );
            util.toast().success( msg );
            getJobsList();
        }catch ( e ) {
            setBackDropOpen( false );
            util.handleError( e );
        }
    }
 
    function duplicateJobPosition( id ) {
        setShowDuplicate( true );
        setData( id );
    }
 
    function deleteJobPosition( id ) {
        setDeleteShow( true );
        setData( id );
    }

    useEffect( ()=> {
        // console.log( filterState );
    },[filterState] );

    const urlHandlerJobPositions = ( query )  => {
        let orderBy = ( query.orderBy ) ? query.orderBy.field:"createdAt";
        let direction = ( query.orderDirection ==="" ) ? "desc": query.orderDirection;
        let search = ( query.search === "" ) ? "": state.showFilter ? "": query.search;
        const { showFilter, startDate, endDate, countries, status, leadRecruiters, clients, partners, salesLead } = filterState;

        // Get all checked values and parse them into URL Encode
        const fixCheckedToUrlEncode = ( data = [], field ) => {
            let pairs = [];
            data.map( ( value ) => {
                if ( value.checked === true ) {
                    pairs.push( `${ field }=` + encodeURIComponent( value.value ) );
                }
            } );
            return pairs.join( "&" );
        };
        
        let q = `advanced_filter=${showFilter}&${fixCheckedToUrlEncode( status, 'status' )}&${fixCheckedToUrlEncode( countries, 'countries' )}&${fixCheckedToUrlEncode( clients, 'clients', true )}&${fixCheckedToUrlEncode( partners, 'partners', true )}&${fixCheckedToUrlEncode( salesLead, 'salesLead' )}&${fixCheckedToUrlEncode( leadRecruiters, 'leadRecruiters' )}&startDate=${startDate}&endDate=${endDate}`;
        return `${util.url}/jobs/list/recruiter/${query.page}/${query.pageSize}?page=${query.page}&rows=${query.pageSize}&orderBy=${orderBy}&direction=${direction}&search=${search}&${q}`;
    };

    const getJobsList = async () => {
        try {
            const request = await util.axios.get( urlHandlerJobPositions( tableState ) );
            const { jobPositions, total, page, error, msg } = request.data;
            if( error ) throw msg;
            setTableState( prev => ( { ...prev, page, totalCount: total } ) );
            setJobList( jobPositions );
            setState( { ...state, jobs: jobPositions } );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    useEffect( () => {
        getJobsList();
    },[tableState.page, tableState.search] );
 
    async function onConfirmDuplicate() {
        try {
            setShowDuplicate( false );
            setBackDropOpen( true );
            const request = await util.axios.post( `${util.url}/jobs/duplicate_job_position/${data}` );
            const { error, msg, job } = request.data;
            if ( error ) throw  msg;
            setBackDropOpen( false );
            util.toast().success( msg );
            setRedirectToDuplicated( job.id );
            // tableRef.current && tableRef.current.onQueryChange()
        }catch ( e ) {
            setBackDropOpen( false );
            util.handleError( e );
        }
    }
 
    function hideAlert( sAlert ) {
        switch ( sAlert ) {
            case HIDE_SWEET_ALERT_DUPLICATE:
                setShowDuplicate( false );
                break;
            case HIDE_SWEET_ALERT_STOP_PROCESS:
                setSaShow( false );
                break;
            case HIDE_SWEET_ALERT_CHANGE_STATUS:
                setCsShow( false );
                break;
            case HIDE_SWEET_ALERT_DELETE_JOB_POSITION:
                setDeleteShow( false );
                break;
            default:
                setShowDuplicate( false );
                setSaShow( false );
                setCsShow( false );
                setDeleteShow( false );
                break;
        }
    }

    const sendEmailCheckbox = ( e ) => {
        const {  checked } = e.target;
        setState( { ...state, sendEmailCheckbox: checked } );
    };
    
    if ( redirectToDuplicated !== 0 ){
        return <Redirect to={`/jobs/position/detail/${redirectToDuplicated}`} />;
    }

    return (
        <Fragment>
            <Tabs mountOnEnter={true} unmountOnExit={true} defaultActiveKey="table" >
                {/* <Tab eventKey="card1" title={<span><FontAwesomeIcon icon={faIdCard}/>1</span>}>
                    <div className="row">
                        <div className="w-100 m-0">
                            <JobStatusView />
                        </div>
                    </div>
                </Tab> */}
                <Tab eventKey="card" title={<span><FontAwesomeIcon icon={faIdCard}/></span>}>
                    <CardView
                        filterState={filterState}
                        setFilterState={setFilterState} 
                        list={jobList}
                        setTableState={setTableState}
                        page={tableState.page}
                        totalCount={tableState.totalCount}
                        pageSize={tableState.pageSize}
                        reloadTableData={getJobsList}

                    />
                </Tab>
                <Tab eventKey="table" title={<span><FontAwesomeIcon icon={faTable}/></span>}>
                    <JobsTable filterState={filterState} setFilterState={setFilterState}
                        reloadTableData={reloadTableData}
                        tableRef={tableRef}
                        tableColumns={tableColumns}
                        handleColumnDrag={handleColumnDrag}
                        urlHandlerJobPositions={urlHandlerJobPositions}
                        state={state} setState={setState}
                        data={data} setData={setData}
                        showColumns={showColumns}
                        handleChange={handleChange}
                        handleSave={handleSave}
                        showDuplicate={showDuplicate}
                        hideAlert={hideAlert}
                        onConfirmDuplicate={onConfirmDuplicate}
                        HIDE_SWEET_ALERT_DUPLICATE={HIDE_SWEET_ALERT_DUPLICATE}
                        csShow={csShow}
                        onConfirmChangeStatus={onConfirmChangeStatus}
                        HIDE_SWEET_ALERT_CHANGE_STATUS={HIDE_SWEET_ALERT_CHANGE_STATUS}
                        sendEmailCheckbox={sendEmailCheckbox}
                        saShow={saShow}
                        onConfirmStopProcess={onConfirmStopProcess}
                        HIDE_SWEET_ALERT_STOP_PROCESS={HIDE_SWEET_ALERT_STOP_PROCESS}
                        deleteShow={deleteShow}
                        onConfirmDeleteJob={onConfirmDeleteJob}
                        HIDE_SWEET_ALERT_DELETE_JOB_POSITION={HIDE_SWEET_ALERT_DELETE_JOB_POSITION}
                        backDropOpen={backDropOpen}
                        columns={columns}
                        list={jobList}
                        setTableState={setTableState}
                        page={tableState.page}
                        totalCount={tableState.totalCount}
                        pageSize={tableState.pageSize}
                        // reloadTableData={getJobsList}
                        showReadOnly={showReadOnly}
                        readOnlyPosition={readOnlyPosition}
                        handleReadOnlyClose={handleReadOnlyClose}

                    />
                </Tab>
            </Tabs>
        </Fragment>
    );
}

export default ListOfRequirements;

const TableMenu = ( { row, handleDelete,handleJobDuplicateBtn, showJobDetail } ) => {
    // Anchor and open variable for the popover. 
    const [anchorEl, setAnchorEl] = React.useState( null );
    const open = Boolean( anchorEl );

    // Triggering the popover on the double click
    const handleClick = ( event ) => {
        setAnchorEl( event.currentTarget );
    };

    const handleClose = async ( ) => {
        // resetting the anchor for the popover
        setAnchorEl( null );
    };

    const theme = createTheme( {
        overrides: {
            MuiTooltip: {
                tooltip: {
                    maxWidth: "250px",
                    fontSize: "0.85em",
                }
            }
        }
    } );

    function editValue() {
        return <Popover
            id={open ? 'text-input-popover' : undefined}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}>
            <div className="workloadTable-menu text-secondary">
                <span onClick={() => {
                    handleClose();
                    showJobDetail( row );
                }}>
                    <FontAwesomeIcon icon={faEye} /> View
                </span>
                {/* <span> */}
                <Link to={`/jobs/position/detail/${row.id}?opc=1`}>
                    <FontAwesomeIcon icon={faEdit} /> Edit
                </Link>
                {/* </span> */}
                <span onClick={() => {
                    handleClose();
                    handleJobDuplicateBtn( row );
                }}>
                    <FontAwesomeIcon icon={faCopy} /> Duplicate
                </span>
                <span className="text-danger" onClick={() => {
                    handleClose();
                    handleDelete( row );
                }}>
                    <FontAwesomeIcon icon={faTrash} /> Delete
                </span>
            </div>
        </Popover>;
    }

    return <>
        <div className="workloadTable-menuBtn" onClick={handleClick}>
            <FontAwesomeIcon icon={faEllipsisV} />
        </div>
        <MuiThemeProvider theme={theme}>
            {editValue()}
        </MuiThemeProvider>
    </>;
};