import React, { useEffect, useState } from "react";
import { PositionsStore } from "../stores/PositionsStore";
import util from "../../utils/miniUtils";
import MaterialTable from "material-table";
import { MuiThemeProvider } from "@material-ui/core";
import JobSingle from "./JobSingle";


export default function JobsResult() {
	
    const state = PositionsStore.useState( state => state );
    const jobsX = PositionsStore.useState( state => state.jobs );
    const [jobs, setJobs] = useState( [] );
    const [page, setPage] = useState( 0 );
	
    // Display the selected Jon
    function displayJob( job ) {
        // When we receive a object from Material table, this should be a copy write mode
        const t = { ...job };
        PositionsStore.update( s => {
            s.job =  t;
        } );
        // Every click on a job, will update the URL params
        const a = t.job_title.replaceAll( /\s/g, "-" );
        const pageUrl = `?search=${state.job_position}&job=${t.id}&title=${a}`;
        window.history.pushState( '', '', pageUrl );
    }
	
    // Every time the paginator change, wil trigger a request for more data
    useEffect( () => {
        searchPaginator().then( null );
    },[page] );
	
    // Fill table with search result of Jobs found in the Server
    useEffect( () => {
        const editable = jobsX.map( o => ( { ...o } ) );
        setJobs( editable );
    },[jobsX,jobsX?.length, jobsX[0]?.id ] );
	
    // Fill table with search result of Jobs found in the Server
    useEffect( () => {
        // if (state["searchJob"]){
        // 	let x = window.document.location.search.split("?")[1].split("=");
        // 	// search
        // }
    },[state["searchJob"]] );
	
    // Make a search/ request on the server using stored parameters for the search
    async function searchPaginator() {
        try {
            let p = { ...state };
			
            PositionsStore.update( s => { s.loading = true;} );
			
            let data = `page=${page}
						&limit=${p.limit}
						&job_position=${p.job_position}
						&country=${p.country}
						&advanceFilter=${p.advanceFilter}
						&state=${p.state}
						&city=${p.city}
						&duration=${p.duration}
						&postedDaysAgo=${p.postedDaysAgo}`;
			
            const request = await util.axios.get( `${util.url}/jobs/position?${data}` );
            const { error, msg, jobPositions, total } = request["data"];
			
            // Save new parameters into Store
            PositionsStore.update( s => {
                s.jobs      =   jobPositions;
                s.job       =   null;
                s.firstTime =   false;
                s.total     =   total;
                s.page      =   page;
                s.loading   =   false;
                // Values for advanced search
                s.job_position  = p.job_position;
                s.advanceFilter = p.advanceFilter;
                s.country       = p.country;
                s.state         = p.state;
                s.city          = p.city;
                s.postedDaysAgo = p.postedDaysAgo;
            } );
            if ( error ) throw  msg;
        }catch ( e ) {
            util.handleError( e );
        }
    }
	
    return(
        <div className="row">
            {/* Card with simple job description */}
            <MuiThemeProvider theme={util.defaultTableThemeSlim}>
                <MaterialTable
                    columns={[
                        {
                            field: "job_title",
                            render: p => {
                                return <JobSingle job={p}/>;
                            }
                        }
                    ]}
                    totalCount={state.total}
                    page={page}
                    data={jobs}
                    options={{
                        pageSize: 10,
                        sorting: false,
                        showFirstLastPageButtons: true,
                        pageSizeOptions: [],
                        tableLayout:"fixed",
                        padding: "dense",
                        search:false,
                        paginationType: "normal",
                        showTitle:false,
                        toolbar:false,
                        emptyRowsWhenPaging:false,
                        paging:true,
                        paginationPosition:"bottom"
                    }}
                    onRowClick={( event, rowData ) =>{
                        displayJob( rowData );
                    }}
                    onChangePage={page => {
                        setPage( page );
                    }}
                />
            </MuiThemeProvider>
        </div>
    );
}

