import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from "react";
import util from '../../../utils/miniUtils';
const FileUploader = ( { appraisal_item_id, reloadAttachmentsOnItem } ) => {

    const onSelectFiles = async  ( e ) => {
        
        try {
            const data = new FormData();
            [...e.target.files].map( file => {
                data.append( "file", file );
            } );
            
            // console.log( data );
            const request = await util.axios.post( `/appraisal/upload_attachment/${appraisal_item_id}`, data );
            const { error, msg, attachments } = request.data;
            if ( error ) throw msg;
            reloadAttachmentsOnItem( attachments );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    return (
        <section className="section">
            <label>
                <FontAwesomeIcon icon={faPaperclip} className="iconGray" /> Attach Files
                <input accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
text/plain, application/pdf" 
                type="file" 
                className="invisible" 
                name="files" 
                onChange={onSelectFiles} 
                multiple 
                />
            </label>
        </section>
    );
};

export default FileUploader;

