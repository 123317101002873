import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import util from "../../utils/miniUtils";
import { Tooltip } from "@material-ui/core";

export default function RecordModalDetail( { show, handleClose, jobsId } ) {

    const [jobs, setJobs] = useState( null );

    const getJobsDetails = async () => {
        try {
            if ( jobsId === null ) return;
            const request = await util.axios.post( `job/jobs_information`, { ids: jobsId } );
            const { error, msg, jobs } = request.data;
            if ( error ) throw new Error( msg );
            setJobs( jobs );
        }catch ( e ) {
            util.handleError( e );
        }
    };

    useEffect( () => {
        getJobsDetails().then( null );
    }, [ jobsId ] );

    return (
        <Modal size="lg" show={show} onHide={handleClose}>
            <Modal.Header closebutton>
                <Modal.Title>Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    jobs ?
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Partner</th>
                                    <th>Client</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div>{jobs[0].partnerName}</div>
                                        <div>{jobs[0].partnerEmail}</div>
                                        <div>{jobs[0].partnerCompany}</div>
                                    </td>
                                    <td>
                                        <div>{jobs[0].clientName}</div>
                                        <div>{jobs[0].clientEmail}</div>
                                        <div>{jobs[0].clientCompany}</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        : 
                        ""
                }
                
                <table className="table">
                    <thead>
                        <tr>
                            <th>JobID</th>
                            <th>Country</th>
                            <th>Job Title</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            jobs && jobs.map( ( j,i )=>{
                                return(
                                    <tr key={i}>
                                        <td>
                                            <a href={`/jobs/position/detail/${j.id}`} target="new">
                                                {util.zeroPad( j.id, 5 )}
                                            </a>
                                        </td>
                                        <td className="d-flex align-items-center text-center"><img width={20} src={util.countryFlag( j.country )}  alt={j.country}/></td>
                                        <td>
                                            <a href={`/jobs/position/detail/${j.id}`} target="new">
                                                { j.job_title }
                                            </a>
                                        </td>
                                        <td>
                                            {util.jobStatusOnlyIcons( j.status )}
                                            {
                                                j.status === 6 ?
                                                    <ul>
                                                        {
                                                            j.dropped_reasons !== ""  && j.dropped_reasons.map( ( dr, i ) =>{
                                                                return (
                                                                    <li key={i}>
                                                                        <Tooltip title={dr.tooltip}>
                                                                            <span>{dr.title}</span>
                                                                        </Tooltip>
                                                                    </li>
                                                                );
                                                            } )
                                                        }
                                                    </ul>
                                                    :
                                                    ""
                                            }
                                        </td>
                                    </tr>
                                ) ;
                            } )
                        }
                    </tbody>
                </table>
                
                
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-sm btn-danger" onClick={handleClose}>
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    );
}