import React, { Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPlus,
} from "@fortawesome/free-solid-svg-icons";
import useCostToCompany from "./useCostToCompany";
import NewCountry from "./NewCountry";
import { CTCStore } from "./CTCStore";
import CountryCTCComponent from "./CountryCTCComponent";



function CostToCompany() {

    const { getCountryCTCSettings, handleClose, openModal } = useCostToCompany();

    const { countries, modalAddCountry } = CTCStore.useState( state => state );

    useEffect( () => {
        getCountryCTCSettings().then( null );
    },[ ] );



    return (
        <Fragment>
            <div className="card container-fluid mt-2">
                <div className="row mt-2 p-2">
                    <div className="col-md-12">
                        <div className="d-flex justify-content-between align-items-center">
                            <h3>CTC Settings by Country</h3>
                            <button onClick={openModal} className="btn btn-primary btn-sm">
                                <FontAwesomeIcon icon={faPlus} className="mr-1"/>
                                Country
                            </button>
                        </div>
                        <hr/>
                    </div>
                    {
                        countries && countries.map( ( c, i ) => {
                            return (
                                <CountryCTCComponent key={i} singleCountry={c} refreshData={getCountryCTCSettings}  />
                            );
                        } )
                    }
                </div>
            </div>
            {modalAddCountry ? <NewCountry show={modalAddCountry} handleClose={handleClose} refreshData={getCountryCTCSettings}/> : null}
        </Fragment>
    );
}

export default CostToCompany;


