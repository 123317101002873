import React, { Fragment, useEffect, useState } from "react";
import { MuiThemeProvider, TextField } from "@material-ui/core";
import util from "../utils/miniUtils";

import Tooltip from "@material-ui/core/Tooltip";
import MaterialTable from "material-table";
import EvaluationComponent from "./evaluation/EvaluationComponent";

function EvaluationsRole() {
    
    const [jobs, setJobs] = useState( [] );
    const [jobsBase, setJobsBase] = useState( [] );
    const [talents, setTalents] = useState( [] );
    const [talentsBase, setTalentsBase] = useState( [] );
    const [backDropOpen, setBackDropOpen] = useState( false );
    const [job,setJob] = useState( null );
    const [setSkillEvaluationResult] = useState( {} );
    const [serId, setSerId] = useState( null );
    const [tempTalentSelected] = useState( null );
    const [isFinished, setIsFinished] = useState( null );
    const [searchTalent, setSearchTalent] = useState( "" );
    
    useEffect( () => {
        setIsFinished( setSkillEvaluationResult.status === 2 );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[setSkillEvaluationResult] );
    
    useEffect( () => {
        getJobs().then( null );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[] );
    
    async function getJobs() {
        try {
            setBackDropOpen( true );
            const req = await util.axios.get( `${util.url}/evaluator_jobs/list` );
            const { error, msg, jobs } = req["data"];
            if ( error ) throw msg;
            setJobs( jobs );
            setJobsBase( jobs );
            setBackDropOpen( false );
        }catch ( e ) {
            setBackDropOpen( false );
            util.handleError( e );
        }
    }
    
    async function getTalents( id ) {
        try {
            setSearchTalent( "" );
            setJob( null );
            // setSelectedTalent(null)
            setBackDropOpen( true );
            const req = await util.axios.get( `${util.url}/evaluator_jobs/talents/${id}` );
            const { error, msg, talents } = req["data"];
            if ( error ) throw msg;
            setTalents( talents );
            setTalentsBase( talents );
            // setTempJobId(id)
            setBackDropOpen( false );
        }catch ( e ) {
            setBackDropOpen( false );
            util.handleError( e );
        }
    }
    


    
    // function handleSelectedJob( job_id ) {
    //     // setSelectedJob(job_id)
    // }
    
    
    function filterJobs( e ) {
        const { value } = e.target;
        if ( value ==="" ){
            setJobs( jobsBase );
        }else{
            let t = [...jobsBase];
            let temp = [];
            t.forEach( j => {
                if ( ( j.job_title.toLowerCase().indexOf( value.toLowerCase() ) !== -1 ) ||
                    ( j.job_id.toString().indexOf( value.toString() ) !== -1 ) ){
                    temp.push( j );
                }
            } );
            setJobs( temp );
        }
    }
    
    function filterTalents( e ) {
        const { value } = e.target;
        if ( value ==="" ){
            setSearchTalent( "" );
            setTalents( talentsBase );
        }else{
            let t = [...talentsBase];
            let temp = [];
            t.forEach( j => {
                if ( j.talent.toLowerCase().indexOf( value.toLowerCase() ) !== -1 ){
                    temp.push( j );
                }
            } );
            setSearchTalent( value );
            setTalents( temp );
        }
    }
    
    return(
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-2">
                        <b>Jobs</b>
                        <div>
                            <TextField
                                placeholder="Search Job"
                                type="search"
                                onChange={filterJobs}
                            />
                        </div>
                        
                        {jobs.length > 0 ?
                            <MuiThemeProvider theme={util.defaultTableThemeSlim}>
                                <MaterialTable
                                    columns={[
                                        {
                                            field: "job_title",
                                            title: "Job",
                                            render: j => {
                                                return (
                                                    <div className={serId === j.job_id ? "rowSelectedBorder":""}>
                                                        <table className="w-100">
                                                            <thead/>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        {util.jobStatusOnlyIcons( j.status, false )}
                                                                    </td>
                                                                    <td >
                                                                        <Tooltip title={j.job_title}>
                                                                            <span>
                                                                                {j.job_title.length > 27 ? j.job_title.slice( 0,27 ) + "...": j.job_title}
                                                                            </span>
                                                                        </Tooltip>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        {String( j.job_id ).padStart( 5, "0" )}
                                                                    </td>
                                                                    <td>
                                                                        {util.location( j.country, j.state, j.city )}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        
                                                        
                                                    </div>
                                                );
                                            }
                                        }
                                    ]}
                                    data={jobs}
                                    options={{
                                        pageSize: 10,
                                        sorting: true,
                                        showFirstLastPageButtons: false,
                                        pageSizeOptions: [],
                                        tableLayout:"fixed",
                                        padding: "dense",
                                        search:false,
                                        paginationType: "stepped",
                                        showTitle:false,
                                        toolbar:false
                                    }}
                                    onRowClick={( event, rowData ) =>{
                                        setSerId( null );
                                        getTalents( rowData.job_id ).then( null );
                                        // handleSelectedJob(rowData.job_id)
                                    }}
                                />
                            </MuiThemeProvider>
                            :""}
                    </div>
                    <div className="col-md-3">
                        <b>Talents</b>
                        <div>
                            <TextField
                                placeholder="Search Talent"
                                type="search"
                                fullWidth
                                value={searchTalent}
                                onChange={filterTalents}
                            />
                        </div>
                        {talents.length > 0 ?
                            (
                                <MuiThemeProvider theme={util.defaultTableThemeSlim}>
                                    <MaterialTable
                                        columns={[
                                            {
                                                field: "final_decision",
                                                title: "Status",
                                                width:"25",
                                                render: data => {
                                                    return (
                                                        <div className={`w-100 ${tempTalentSelected === data.id ? "rowSelectedBorder":""}`}>
                                                            {util.statusEvaluationSkillEvaluationResult( data.final_decision )}
                                                        </div>
                                                    );
                                                }
                                            },
                                            {
                                                field: "talent",
                                                title: "Talent",
                                            },
                                            {
                                                field: "date",
                                                title: "Date",
                                                width:"50"
                                            },
                                            {
                                                field: "score",
                                                title: "Score",
                                                width:"50"
                                            },
                                        ]}
                                        data={talents}
                                        options={{
                                            pageSize: 10,
                                            sorting: true,
                                            showFirstLastPageButtons: false,
                                            pageSizeOptions: [],
                                            tableLayout:"fixed",
                                            padding: "dense",
                                            search:false,
                                            paginationType: "stepped",
                                            showTitle:false,
                                            toolbar:false
                                        }}
                                        onRowClick={( event, rowData ) =>{
                                            setSerId( rowData.id );
                                        }}
                                    />
                                </MuiThemeProvider>
                            )
                            :""}
                    </div>
                    <div className="col-md-7">
                        {
                            serId ?
                                <Fragment>
                                    <EvaluationComponent
                                        evaluation_id={serId}
                                        reloadTalents={() => getTalents( serId )}/>
                                </Fragment>
                                :
                                ""
                        }
                    </div>
                </div>
            </div>
            {util.LOADING_SCREEN( backDropOpen )}
        </Fragment>
    );
}


export default EvaluationsRole;


