import React, { useState } from "react";
import {  Modal } from "react-bootstrap";
import util from '../../../utils/miniUtils';

export default function AcceptOrDeclienModal( { show,handleClose,handleBack, appraisal_id } ) {

    const [message, setMsg] = useState( "" );

    const handleChange = ( e ) => setMsg( e.target.value );

    const declineAppraisal = async () => {
        try {
            let temp = {
                response: false,
                decline_message: message
            };
            const request = await util.axios.put( `/appraisal_status_update/${appraisal_id}`,temp );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            handleBack();
            handleClose();
        } catch ( error ) {
            util.handleError( error );
        }
    };

    return (
        <div>
           
            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Please Explain Why Are You Declining?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <textarea onChange={handleChange} className="w-100" placeholder="Add Comment" />
                    
                </Modal.Body>
                <Modal.Footer>
                    <span className="likeLink" onClick={handleClose}>
                        Cancel
                    </span>
                    <button className="btn btn-danger btn-sm" onClick={declineAppraisal}> 
                        Decline Appraisal
                    </button>
                     
                </Modal.Footer>
            </Modal>
        </div>
    );
}


//TODO:Decline appraisal button redirect to myappraisal tabs page
//TODO:cancel button simply hide the pop up modal