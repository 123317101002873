import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@material-ui/core";
import React from "react";
const EvaluatorInfo = ( { evaluator, name, feedback, score  } ) => {
    return (
        <>
            <Grid container spacing={1} className="mt-2">
                <Grid item xs={12} md={12} lg={10}>
                    <b>{evaluator}</b>: 
                    <span className="p-1 bg-light border ml-3"><FontAwesomeIcon size="1x" icon={faUser} /> {name}
                    </span></Grid>
                <Grid item xs={12} md={12} lg={2}><span>Score: {score}%</span></Grid>
            </Grid>
            <Grid container spacing={1} className="mt-3">
                <Grid item xs={12} md={12} lg={1}>
                    <b>Comments:</b>

                </Grid>
                <Grid item xs={12} md={12} lg={11}>
                    <textarea  className="form-control" 
                        name="feedback"
                        value={feedback}
                        disabled
                    /></Grid>
            </Grid>
        </>
                    
    );
};

export default EvaluatorInfo;
