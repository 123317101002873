import React, { useEffect, useState } from "react";
import util from "../../../../utils/miniUtils";
import CheckBoxSelector from "./../../../../shared/checkboxSelector";
const countryListFixed = util.fixCountryList();
//Reusing the checkBoxSelector component instead of fieldSelector
export default function SideBarFilter( { reloadData } ) {
    // Data for filters
    const [filter, setFilter] = useState( {
        ready: false,
        loading: true,
        startDate: "",
        endDate: "",
        countries: countryListFixed,
        status: util.statusList(),
        leadRecruiters: [],
        clients: [],
        partners: [],
        salesLead: [],
    } );

    // Executed only one time after component loads
    useEffect( () => {
        getFilterInformation().then( null );
    }, [] );

    // Trigger in every change of filter
    useEffect( () => {
        if ( filter.ready ) {
            // console.log( 'query new data due changes' );
            reloadData( filter );
        }
    }, [filter] );

    // Handle dates changes
    const handleDateChange = ( e ) => {
        const { name, value } = e.target;
        setFilter( { ...filter, [name]: value, ready: true } );
    };

    const getFilterInformation = async () => {
        try {
            const request = await util.axios.get( `/jobs/filter-data` );
            const { error, msg, salesLead, leadRecruiter, clients, partners } = request.data;
            if ( error ) throw msg;
            setFilter( ( prevState ) => ( {
                ...prevState,
                loading: false,
                leadRecruiters: leadRecruiter,
                clients: clients,
                partners: partners,
                salesLead: salesLead,
            } ) );
        } catch ( e ) {
            util.handleError( e );
        }
    };

    if ( filter.loading ) {
        return util.LOADING_SCREEN( filter.loading );
    }
    // console.log( filter );

    return (
        <div className="container-fluid fontSize13">
            <div className="row sideBarFilter">
                <div className="col-md-12 p-sm-0 m-sm-0">
                    <div className="text-left w-100">
                        <div>
                            <label className="w-100" htmlFor="startDate">
                                <strong>Start Date</strong> <br />
                                <input
                                    className="form-control"
                                    value={filter.startDate}
                                    type="date"
                                    onChange={handleDateChange}
                                    id="startDate"
                                    name="startDate"
                                />
                            </label>
                        </div>
                        <div className="sss">
                            <label className="w-100" htmlFor="endDate">
                                <strong>End Date</strong> <br />
                                <input
                                    className="form-control"
                                    value={filter.endDate}
                                    type="date"
                                    onChange={handleDateChange}
                                    id="endDate"
                                    name="endDate"
                                />
                            </label>
                        </div>
                    </div>
                    <hr className="p-2 m-0" />

                    <CheckBoxSelector
                        isOpenByDeafult={true}
                        dataArray={filter.countries}
                        title="Country"
                        updateData={( data ) =>
                            setFilter( { ...filter, countries: data, ready: true } )
                        }
                    />

                    <CheckBoxSelector
                        dataArray={filter.status}
                        title="Status"
                        updateData={( data ) =>
                            setFilter( { ...filter, status: data, ready: true } )
                        }
                    />
                    <CheckBoxSelector
                        dataArray={filter.salesLead}
                        title="Sales Lead"
                        updateData={( data ) =>
                            setFilter( { ...filter, salesLead: data, ready: true } )
                        }
                    />

                    <CheckBoxSelector
                        dataArray={filter.leadRecruiters}
                        title="Lead Recruiter"
                        updateData={( data ) =>
                            setFilter( { ...filter, leadRecruiters: data, ready: true } )
                        }
                    />

                    <CheckBoxSelector
                        dataArray={filter.partners}
                        title="Partners"
                        updateData={( data ) =>
                            setFilter( { ...filter, partners: data, ready: true } )
                        }
                    />

                    <CheckBoxSelector
                        dataArray={filter.clients}
                        title="End Client"
                        updateData={( data ) =>
                            setFilter( { ...filter, clients: data, ready: true } )
                        }
                    />
                </div>
            </div>
        </div>
    );
}
