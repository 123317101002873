import { faBan, faPencilAlt, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useEffect, useState } from 'react';
import util from '../../../utils/miniUtils';

export default function ChangeSaleRate( props ) {
    const [typeInput] = useState( props.typeInput );
    const [form, setForm] = useState( false );
    const [value, setValue] = useState( () => {
        switch ( props.typeInput ) {
            case 1:
                return props.data.employment_type === null
                    ? 0
                    : parseFloat( props.data.employment_type );
            case 2:
                return props.data["talent_expectation"] === null
                    ? 0
                    : parseFloat( props.data["talent_expectation"] );
            case 3:
                return props.data.buy_rate === null
                    ? 0
                    : parseFloat( props.data.buy_rate );
            case 4:
                return props.data["talent_sell_rate"] === null
                    ? 0
                    : parseFloat( props.data["talent_sell_rate"] );
            default:
                break;
        }
    } );

    const [valueBase] = useState( () => {
        switch ( props.typeInput ) {
            case 1:
                return props.data.employment_type === null
                    ? 0
                    : parseFloat( props.data.employment_type );
            case 2:
                return props.data["talent_expectation"] === null
                    ? 0
                    : parseFloat( props.data["talent_expectation"] );
            case 3:
                return props.data.buy_rate === null
                    ? 0
                    : parseFloat( props.data.buy_rate );
            case 4:
                return props.data["talent_sell_rate"] === null
                    ? 0
                    : parseFloat( props.data["talent_sell_rate"] );
            default:
                break;
        }
    } );

    useEffect( () => {
        setValue( () => {
            switch ( props.typeInput ) {
                case 1:
                    return props.data.employment_type === null
                        ? 0
                        : parseFloat( props.data.employment_type );
                case 2:
                    return props.data["talent_expectation"] === null
                        ? 0
                        : parseFloat( props.data["talent_expectation"] );
                case 3:
                    return props.data.buy_rate === null
                        ? 0
                        : parseFloat( props.data.buy_rate );
                case 4:
                    return props.data["talent_sell_rate"] === null
                        ? 0
                        : parseFloat( props.data["talent_sell_rate"] );
                default:
                    break;
            }
        } );
    }, [props] );

    async function handleChangeSaleRate() {
        if ( form ) {
            try {
                const { position_id, candidate_id } = props.data;
                let data = { field: typeInput, value: parseFloat( value.toString() ) };
                const request = await util.axios.patch(
          `${util.url}/job/update_talent_position_rates/${position_id}/${candidate_id}`,
          data
                );
                const { error, msg } = request.data;
                if ( error ) throw msg;
                setForm( !form );
                props.updateChange();
            } catch ( e ) {
                util.handleError( e );
            }
        } else {
            setForm( !form );
        }
    }

    function handleChange( e ) {
    // Update sale rate on API
        let { value } = e.target;
        if ( value === "" ) value = 0;
        setValue( parseFloat( value.toString() ) );
    }

    function handleCancel() {
        setValue( valueBase );
        setForm( !form );
    }

    if ( form ) {
        return (
            <Fragment>
                <input
                    autoFocus={true}
                    onKeyDown={( e ) => {
                        if ( e.key === "Enter" ) {
                            handleChangeSaleRate().then( null );
                        }
                        if ( e.key === "Escape" ) {
                            handleCancel();
                        }
                    }}
                    className="w-50 mr-1"
                    value={value}
                    onChange={handleChange}
                />
                <FontAwesomeIcon
                    title="Update Sale Rating"
                    onClick={() => handleChangeSaleRate()}
                    className="isPointer text-success"
                    icon={faSave}
                />
                <FontAwesomeIcon
                    title="Cancel Action"
                    onClick={() => handleCancel()}
                    className="text-danger ml-1"
                    icon={faBan}
                />
            </Fragment>
        );
    } else {
        return (
            <Fragment>
                <span onClick={() => handleChangeSaleRate()}>
                    <span className="mr-1">
                        {util.currencyFormat( value, props.data.job.country )}
                    </span>
                    <FontAwesomeIcon
                        className="isPointer text-muted iconSizeSmall"
                        icon={faPencilAlt}
                    />
                </span>
            </Fragment>
        );
    }
}