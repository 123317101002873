import React, { Fragment, useEffect, useState } from "react";
import util from "../../../../utils/miniUtils";
import { MuiThemeProvider } from "@material-ui/core";
import MaterialTable from "material-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCommentAlt,
    faHandPaper,
    faPaperclip,
    faPencilAlt, faPlus,
    faThumbsDown,
    faThumbsUp
} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@material-ui/core/Tooltip";
import CommentBox from "./CommentBox";
import ButtonShowMoreInfo from "./ButtonShowMoreInfo";
import PreviousEvaluations from "./PreviousEvaluations";

const PARTNER_ROLE = 4;
const DISLIKE = 0;
const LIKE = 1;
const HOLD = 2;

const user = util.user;

function TableOfTalent( props ) {
    // Get the list of talents inside this position
    const [candidates, setCandidates] = useState( [] );
    // const [ setTalent] = useState(null)
    const [loading, setLoading] = useState( true );
    // const [ setIsOpen] = useState(false)
    const field = user.role === PARTNER_ROLE ? "partner_flag":"client_flag"; // Helps to manage field s of both roles
    const feedback = user.role === PARTNER_ROLE ? "partner_feedback":"client_feedback"; // Helps to manage field s of both roles
	
    useEffect( () => {
        util.axios.get( `${util.url}/requirements/candidates_of_position/${props.data.id}?hideDrop=true&hasEvaluation=true` )
            .then( response => {
                const { error, msg, candidates } = response.data;
                if ( error ) throw  msg;
                setCandidates( candidates );
                setLoading( false );
            } )
            .catch( error=>{
                util.handleError( error );
            } );
        // eslint-disable-next-line
    },[props.id] );
	
    async function handleClickLike( opc, candidate_id, position_id ) {
        try {
            // Prepare temp var
            let temp = candidates;
            // Send the change to the server
            let data = { candidate_id,position_id, flag:opc };
            const request = await util.axios.put( `${util.url}/jobs/flag_candidate`, data );
            const { error, msg } = request.data;
            if ( error ) throw  msg;
            // Update locally the option
            temp.forEach( ( c )=>{
                if ( c.candidate_id === candidate_id ){
                    if ( user.role === PARTNER_ROLE ){
                        c.partner_flag = opc;
                    }else{
                        c.client_flag = opc;
                    }
                }
            } );
            // reload candidate array with new opc
            setCandidates( Object.assign( [], temp ) );
        }catch ( e ) {
            util.handleError( e );
        }
    }
	
    function updateComment( id, comment ) {
        for ( let x = 0; x < candidates.length; x++ ){
            if ( candidates[x].candidate_id === id ){
                candidates[x][feedback] = comment;
                break;
            }
        }
        setCandidates( Object.assign( [], candidates ) );
    }
	
    const sellRateValueBySetting = ( offerTypeEstimate, ctcData ) => {
        // eslint-disable-next-line
        switch ( offerTypeEstimate ) {
            case 'Hour': return { time:"/Hour", value: ctcData.sellRatePerHour };
            case 'Day': return { time:"/Day", value: ctcData.sellRatePerDay };
            case 'Month': return { time:"/Month", value: ctcData.sellRatePerMonth };
        }

        return { time:"/Hour", value: ctcData.sellRatePerHour };
    }; 
    
    return(
        <Fragment>
            <MuiThemeProvider theme={util.defaultTableTheme}>
                <MaterialTable
                    isLoading={loading}
                    columns={[
                        {
                            title: "Name",
                            disableClick: true,
                            field: "name",
                            render: ( data ) => {
                                return (
                                    <Fragment>
                                        {data.resume ?
                                            <a href={util.resumeUrl( data )} className="text-decoration-none" target="new" >
                                                <FontAwesomeIcon icon={faPaperclip} className="mr-2" />
                                            </a>:
                                            ""
                                        }
                                        <span onClick={()=>{
                                            // setTalent(data)
                                            // setIsOpen(true)
                                        }} className="likeLink">
                                            {data.name}
                                        </span>
                                    </Fragment>
                                );
                            },
                        },
                        {
                            title: "Location",
                            field: "city",
                            disableClick: true,
                            render: ( data ) => {
                                return util.location( data.country, data.state, data.city );
                            },
                        },
                        {
                            title: "Rate",
                            field: "sale_rate",
                            disableClick: true,
                            render: data => {
                                if ( data.ctc_settings !== null ){
                                    let d = sellRateValueBySetting( data.ctc_settings.offerTypeEstimate,data.ctc_settings  );
                                    return `${util.currencyFormat( d.value , data.job.country )} ${d.time}`;
                                }

                                return util.currencyFormat( data["talent_sell_rate"], data.job.country );

                            }
                        },
                        {
                            title: "Status",
                            field: "status",
                            disableClick: true,
                            render: ( data ) => {
                                return util.candidateStatus( data.status, data["status_note"], );
                            },
                        },
                        {
                            title: "Test",
                            disableClick: true,
                            sorting:false,
                            render: ( data ) => {
                                if ( data["numberOfTests"] === 0 ){
                                    return "";
                                }else{
                                    return (
                                        <div className="text-center">
                                            <ButtonShowMoreInfo data={data}/>
                                        </div>
                                    );
                                }
								
                            },
                        },
                        {
                            title: "My Evaluation",
                            disableClick: true,
                            render: ( data ) => {
                                return (
                                    <Fragment>
                                        <div className="text-center">
                                            <button onClick={()=>handleClickLike( DISLIKE, data.candidate_id, data.position_id )}
											        className={`btn btn-default btn-sm ${data[field] === 0 ? "dislikeThumb":"defaultThumb"}`}>
                                                <FontAwesomeIcon
                                                    className="mr-2"
                                                    icon={faThumbsDown}
                                                />
                                            </button>
                                            <button onClick={()=>handleClickLike( LIKE, data.candidate_id, data.position_id )}
											        className={`btn btn-default btn-sm ${data[field] === 1 ? "likeThumb":"defaultThumb"}`}>
                                                <FontAwesomeIcon
                                                    className="mr-2"
                                                    icon={faThumbsUp}
                                                />
                                            </button>
											
                                            <Tooltip title="Hold with this talent">
                                                <button onClick={()=>handleClickLike( HOLD, data.candidate_id, data.position_id )}
												        className={`btn btn-default btn-sm ${data[field] === 2? "holdThumb":"defaultThumb"}`}>
                                                    <span>
                                                        <FontAwesomeIcon
	                                                    className="mr-2"
	                                                    icon={faHandPaper}
                                                        />
                                                    </span>
                                                </button>
                                            </Tooltip>
										
                                        </div>
                                    </Fragment>
                                );
                            },
                        },
                        {
                            title: "Previous Evaluation",
                            field: "numberOfEvaluations",
                            disableClick:true,
                            render: ( data ) => {
                                if ( data["numberOfEvaluations"] === 0 ){
                                    return "";
                                }else{
                                    return (
                                        <div className="text-center">
                                            <PreviousEvaluations data={data} />
                                        </div>
                                    );
                                }
                            },
                        },
                        {
                            title: "Feedback",
                            field: feedback,
                            render: ( data ) => {
                                if ( data[feedback] ){
                                    return <span>
                                        <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
                                        {util.suspenseDots( data[feedback] )}</span>;
                                }else{
                                    return <span>
                                        <button
	                                        className="btn btn-sm btn-primary">
                                            <FontAwesomeIcon icon={faCommentAlt} />
                                            <FontAwesomeIcon icon={faPlus} className="ml-2"/>
                                        </button></span>;
                                }
                            },
                        },
                    ]}
                    detailPanel={data => {
                        return <CommentBox comment={data[feedback]} talent={data} updateComment={updateComment}/>;
                    }}
                    onRowClick={( event, rowData, togglePanel ) => togglePanel()}
                    data={candidates}
                    options={{
                        sorting: true,
                        showTitle:false,
                        pageSize: 20,
                        pageSizeOptions:[20, 50, 100],
                        emptyRowsWhenPaging: false,
                    }}
                />
            </MuiThemeProvider>
        </Fragment>
    );
}

export default TableOfTalent;