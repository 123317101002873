import React, { Fragment, useEffect, useState } from "react";
import { TextField, Tooltip } from "@material-ui/core";
import countryList from '../../../utils/location/country.json';
import util from "../../../utils/miniUtils";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";


export  default function Location( { requiredData = null, data:{ country, state, city,phone_mobile }, onChange } ) {
   
    const [states, setStates] = useState( [] );
    const [required, setRequired] = useState( {} );
    
    useEffect( () => {
        if ( requiredData !== null ) {
            setRequired( requiredData );
        }
    }, [] );
    
    const [countryState, setCountryState] = useState( {
        loading: false,
        countries: [],
        errorMessage: "",
    } );

    useEffect( () => {
        const fetchData = async () => {
            try {
                // fetch spinner
                setCountryState( {
                    ...countryState,
                    loading: true,
                } );

                //  fetch data
                const dataUrl = `https://restcountries.com/v3.1/all`;
                const response = await axios.get( dataUrl );
                setCountryState( {
                    ...countryState,
                    countries: response.data,
                    loading: false,
                } );
            } catch ( error ) {
                setCountryState( {
                    ...countryState,
                    loading: false,
                    errorMessage: "Sorry Something went wrong",
                } );
            }
        };

        fetchData();
    }, [] );
    const { loading, countries } = countryState;
    // console.log( countries );
    useEffect( () => {
        if ( util.noValues.includes( country ) ){
            let e = { target:{ value: util.user.country, name:"country" } };
            onChange( e );
        }
    },[country] );
	
    useEffect( () => {
        getStates( country );
    },[country] );
	
    // Retrieve list of countries
    const getStates = ( country ) => {
        if ( util.noValues.includes( country ) ){
            for ( let i = 0; i < countryList.length; i++ ) {
                if ( countryList[i].name === util.user.country ){
                    setStates( Object.assign( [], countryList[i].states ) );
                }
            }
        }else{
            for ( let i = 0; i < countryList.length; i++ ) {
                if ( countryList[i].name === country ){
                    setStates( Object.assign( [], countryList[i].states ) );
                }
            }
        }
        // const v = states.sort( ( a,b ) => ( a.name > b.name ) ? 1 : ( ( b.name > a.name ) ? -1 : 0 ) );
        // console.log(v)
    };
    const searchSelectedCountry = countries.find( ( obj ) => {
        if ( obj.name.common === country ) {
            return true;
        }
        return false;
    } );
    // console.log( "searchSelectedCountry", searchSelectedCountry );
    const handleChangePhone = ( e ) => {
        const inputValue = e.target.value;
        const formattedValue = inputValue.replace( /[^0-9]/g, '' ); // Remove non-numeric characters
      
        if ( formattedValue.length <= 10 ) {
            onChange( e );
        }
    };
	
    return(
		
        <Fragment>
            <div className="row d-flex"
            >
                <div className="mb-3 col-5">
                    { loading === true?
                        <div>
                            <p>...loading</p>
                        </div>:
                        <div>
            
                            <div>
                                <Tooltip title={country ? country :"No country selected"}>
                                    <TextField
                                        select
                                        size="small"
                                        type="number"
                                        variant="outlined"
                                        name="country"
                                        label="Country"
                                        value={country}
                                        onChange={onChange}
                                        className='form-control'
                                        // error={phone_mobile.length !== 10}
                                        // helperText={phone_mobile.length !== 10 ? "Phone number must be 10 digits" : ""}
                                        InputLabelProps={{ shrink: true }}
                                        SelectProps={{
                                            native: true
                                        }}
                                    >
                                        <optgroup label="Most Active">
                                            <option value="">--</option>
                                            <option value="United States">USA</option>
                                            <option value="Canada">Canada</option>
                                            <option value="India">India</option>
                                            <option value="Australia">Australia</option>
                                            <option value="Mexico">Mexico</option>
                                            <option value="Japan">Japan</option>
                                            <option value="Germany">Germany</option>
                                            <option value="Puerto Rico">Puerto Rico</option>
                                        </optgroup>
                                        <optgroup label="All Countries">
                                            <option value=""/>
                                            {countries.map( ( item ) => {
                                                return (
                                                    <option key={uuidv4()} value={item.name.common}>
                                                        {item.name.common}
                                                    </option>
                                                );
                                            } )}
                                        </optgroup>
                                    </TextField>
                                </Tooltip>
                                {/* <select
                                        value={selectedCountry}
                                        onChange={( e ) => setSelectedCountry( e.target.value )}
                                        className='form-control w-100'
                                    >
                                        <option>--Select Country--</option>
                                        {countries.map( ( item ) => {
                                            return (
                                                <option key={uuidv4()} value={item.name.common}>
                                                    {item.name.common}
                                                </option>
                                            );
                                        } )}
                                    </select> */}
                            </div>
                            <div>
                                {searchSelectedCountry && (
                                    <div className="d-flex row">
                                        <div className="col-md-2 mt-2 form-control ml-3">
                                            <img
                                                style={{ width:'20px',height:'20px' }}
                                                src={
                                                    searchSelectedCountry &&
                            searchSelectedCountry.flags.png
                                                }
                                                alt=""
                                            />
                                        </div>
                                        <div className="col-md-2 mt-2 form-control ml-1">
                                            <p>
                                                {searchSelectedCountry &&
                          searchSelectedCountry.idd.root}
                                                {searchSelectedCountry &&
                          searchSelectedCountry.idd.suffixes[0]}
                                            </p>
                                        </div>
                                        <div className=" ml-1">
                                            <TextField
                                                style={{ width:'106%' }}
                                                variant="outlined"
                                                size="small"
                                                type="number"
                                                placeholder="Phone"
                                                className='mt-2 form-control'
                                                // country={country}
                                                name={"phone_mobile"}
                                                value={phone_mobile}
                                                // onChange={phone => {
                                                //     let e={ target:{ name:"phone_mobile", value: phone } };
                                                //     onChangeHandler( e );
                                                // }}
                                                onChange={handleChangePhone}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
              
                        </div>
                    }
                </div>
                
                <div className="col-4">
                    <Tooltip title={state ? state :"No state selected"}>
                        <span>
                            <TextField
                                variant="outlined"
                                select
                                size="small"
                                name="state"
                                className="w-100"
                                label={`State ${required.state ? "*":""}`}
                                value={state ? state : ""}
                                onChange={onChange}
                                InputLabelProps={{ shrink: true }}
                                SelectProps={{
                                    native: true
                                }}
                                fullWidth
                            >
                                <option value=""/>
                                {states?.sort( ( a, b ) => a.name.localeCompare( b.name ) ).map( ( option, i ) => (
                                    <option key={i} value={option.name}>
                                        {option.name}
                                    </option>
                                ) )}
                            </TextField>
                        </span>
                    </Tooltip>
                </div>
                <div className="col-3"
                    //  className={`col-md-${cols} ${extraStyleClasses ? extraStyleClasses:""} `}
                >
                    <TextField
                        variant="outlined"
                        size="small"
                        name="city"
                        className="w-100"
                        label={`City ${required.city ? "*":""}`}
                        value={city || ""}
                        onChange={onChange}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                    />
				
                </div>
            </div>

        </Fragment>
    );
}