import util from "../../../../../utils/miniUtils";

export default function TalentMethods( data, setData, loading, setLoading, state, setState ) {
	
    // Get the all Talent Profile Information
    async function getTalentInformation( talent_id, job_id ) {
        try {
            setLoading( true );
            const request = await util.axios.get( `talent_templates/${talent_id}/${job_id}` );
            const { error, msg, template } = request["data"];
            if ( error ) throw new Error( msg );
            setData( null );
            setData( Object.assign( {}, template ) );
            setState( { ...state, print:false } );
            setLoading( false );
        }catch ( e ) {
            setLoading( false );
            util.handleError( e );
        }
    }
	
    // Update job data
    const handleChange = ( e ) => {
        let { name, value } = e.target;
        if ( name === "relocation" ) {
            value = value === "Yes" ? 1:0;
        }
        setData( { ...data, [name]:value } );
    };
	
    // Update Work History Data
    const handleChangeWorkHistory = ( e, index ) => {
        let { name, value } = e.target;
		
        let temp = { ...data };
        temp.workHistory[index][name] = value;
        setData( temp );
    };
	
    // Delete Work History Item
    const deleteWorkHistory = ( index ) => {
        let temp = [...data.workHistory];
        delete temp[index];
        temp = temp.filter( t=> t );
        let all = { ...data };
        all.workHistory = temp;
        setData( all );
    };
	
    // Calculate years of experience using all dates from work history
    const calculateTotalYearsOfExperience = ( wh ) => {
        // Extract all start date and end date from all work history
        if ( util.noValues.includes( wh ) ) return 0;
        if ( wh.length === 0 ) return 0;
		
        let dates = [];
        wh.forEach( w =>{
            if ( !util.noValues.includes( w.from ) ){
                dates.push( w.from );
            }
            if ( !util.noValues.includes( w.to ) ){
                dates.push( w.to );
            }
        } );
        const sorted = dates.sort( ( a,b )=> new Date( b ) - new Date( a ) );
        let a = sorted[0];
        let b = sorted[dates.length - 1];
        let date1 = new Date( a );
        let date2 = new Date( b );
        return date1.getFullYear() - date2.getFullYear();
    };
	
    // Set default values for work history
    const defaultWH = ( wh, opt ) => {
        if ( util.noValues.includes( wh ) ) return "";
        if ( wh.length === 0 ) return "";
		
        if ( wh.length > 0 ) return wh[opt]?.desc;
        if ( wh.length > 1 ) return wh[opt]?.desc;
        if ( wh.length > 2 ) return wh[opt]?.desc;
        if ( wh.length > 3 ) return wh[opt]?.desc;
    };
	
    const generatePDF = async () => {
		
        let html, link, blob, url, css;
        css = (
            '<style>' +
			'@page WordSection1{size: 841.95pt 595.35pt;mso-page-orientation: landscape;}' +
			'div.WordSection1 {page: WordSection1;}' +
			'.selectedStyle {background: #C6D9F1;}' +
			'table{border-collapse:collapse; width: 100%; mso-horizontal-page-align: left}' +
			'td{border:1px gray solid;}'+
			'th{border:1px gray solid;}'+
			'.centeredText {mso-horizontal-page-align: center}' +
			'.leftText {mso-horizontal-page-align: justify}' +
			'</style>'
        );
		
        html = document.getElementById( "testPDF" ).innerHTML;
        blob = new Blob( ['\ufeff', css + html], {
            type: 'application/msword'
        } );
        url = URL.createObjectURL( blob );
        link = document.createElement( 'A' );
        link.href = url;
        // Word will append file extension - do not add an extension here.
        const { client_requirement_id,talentId, name } = data;
        let documentName= `eJangar-${client_requirement_id}.${talentId}-${name}.doc`;
        // Set default file name.
        link.download = documentName;
        document.body.appendChild( link );
        if ( navigator.msSaveOrOpenBlob ) navigator.msSaveOrOpenBlob( blob, documentName ); // IE10-11
        else link.click();  // other browsers
        document.body.removeChild( link );
		
    };
	
    // Update template data of the talent
    const saveData = async () => {
        try {
            setLoading( true );
            const request = await util.axios.put( `talent_templates/${data.talentId}/${data.jobId}`, data );
            const { error, msg } = request["data"];
            if ( error ) throw new Error( msg );
            util.toast().success( msg );
            setLoading( false );
        } catch ( e ) {
            setLoading( false );
            util.handleError( e );
        }
    };
	
    // Update Skills Data
    const handleChangeSkill = ( e, index, section ) => {
        let { name, value } = e.target;
		
        let temp = { ...data };
        temp[section][index][name] = value;
        setData( temp );
    };
	
    // Delete Skill
    const deleteSkill = ( index, section ) => {
        let temp = [...data[section]];
        delete temp[index];
        temp = temp.filter( t=> t );
        let all = { ...data };
        all[section] = temp;
        setData( all );
    };
	
    // Add Skills
    const addSkill = ( section ) => {
        let temp = [...data[section]];
        temp.push( {
            skill: "",
            yearsOfExperience:0,
            desc:"",
        } );
        temp = temp.filter( t=> t );
        let all = { ...data };
        all[section] = temp;
        setData( all );
    };
	
    // Add Work History
    const addWorkHistory = () => {
        let temp = [...data.workHistory];
        temp.push( {
            company: "Company Name",
            location: "Enter Location",
            position: "Position Title",
            from: "",
            to: "",
        } );
        temp = temp.filter( t=> t );
        let all = { ...data };
        all.workHistory = temp;
        setData( all );
    };
	
    return {
        addWorkHistory,
        handleChangeSkill,
        deleteSkill,
        addSkill,
        getTalentInformation,
        deleteWorkHistory,
        saveData,
        generatePDF,
        defaultWH,
        handleChange,
        handleChangeWorkHistory,
        calculateTotalYearsOfExperience
    };
}