import { faCopy, faEdit, faEllipsisV, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createTheme, MuiThemeProvider, Popover } from '@material-ui/core';
import React from 'react';

const TableMenu = ( { row, handleDelete,handleWorkloadDuplicateBtn, showWorkLoadDetail } ) => {
    // Anchor and open variable for the popover. 
    const [anchorEl, setAnchorEl] = React.useState( null );
    const open = Boolean( anchorEl );

    // Triggering the popover on the double click
    const handleClick = ( event ) => {
        setAnchorEl( event.currentTarget );
    };

    const handleClose = async ( ) => {
        // resetting the anchor for the popover
        setAnchorEl( null );
    };

    const theme = createTheme( {
        overrides: {
            MuiTooltip: {
                tooltip: {
                    maxWidth: "250px",
                    fontSize: "0.85em",
                }
            }
        }
    } );

    function editValue() {
        return <Popover
            id={open ? 'text-input-popover' : undefined}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}>
            <div className="workloadTable-menu">
                <span className="text-primary" onClick={() => {
                    handleClose();
                    showWorkLoadDetail( row );
                }}>
                    <FontAwesomeIcon icon={faEdit} /> Edit
                </span>
                <span className="text-info" onClick={() => {
                    handleClose();
                    handleWorkloadDuplicateBtn( row );
                }}>
                    <FontAwesomeIcon icon={faCopy} /> Duplicate
                </span>
                <span className="text-danger" onClick={() => {
                    handleClose();
                    handleDelete( row );
                }}>
                    <FontAwesomeIcon icon={faTrash} /> Delete
                </span>
            </div>
        </Popover>;
    }

    return <>
        <MuiThemeProvider theme={theme}>
            {editValue()}
            <div className="workloadTable-menuBtn" onClick={handleClick}>
                <FontAwesomeIcon icon={faEllipsisV} />
            </div>
        </MuiThemeProvider>
    </>;
};

export default TableMenu;