import React from 'react';
const ItemWithLead = ( { data, onEdit, onSelect, active, onDelete } ) => {
    const { itemName, name, id } = data;

    return (
        <div className={`d-flex justify-content-between align-items-center p-2
        border rounded mt-1 mb-1 department_item ${active === id ? "activeBlock" : ""} isCursor`}
        onClick={( e ) => onSelect( e, id )}>
            <div className="isCursor">
                <div>
                    <b className='text-dark'>{itemName}</b>
                </div>
                <div className="leadName">
                    <span>{name}</span>
                </div>
            </div>
            <div>
                <span className="miniButton btn btn-outline-primary" onClick={( e ) => onEdit( e, id )}>
					Edit
                </span>
                <span className="miniButton btn btn-outline-danger" onClick={( e ) => onDelete( e, id )}>
					Delete
                </span>
            </div>
        </div>
    );
};

export default ItemWithLead;
