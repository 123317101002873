import React, { Fragment, useEffect, useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faTimes } from "@fortawesome/free-solid-svg-icons";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import util from "../../utils/miniUtils";
import SAlert from "../../microComponents/AlertSA";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";

export default function Question( { q, index, removeQuestion } ) {
	
    const [loading, setLoading] = useState( false );
    const [question, setQuestion] = useState( q );
    const [baseQuestion, setBaseQuestion] = useState( q );
    const [state, setState] = useState( {
        confirmation: false
    } );
    const [selectedFile, setSelectedFile] = useState( null );
	
    const [expanded, setExpanded] = React.useState( false );
	
    useEffect( () => {
        // This enters only one time, to check if have empty fields
        emptyFields();
    },[] );
	
    useEffect( () => {
        emptyFields();
    },[question] );
	
    const onChangeHandler = async event => {
        const fileSize = ( event.target.files[0].size / 1024 / 1024 ).toString();
        if ( fileSize.split( "." )[0] > 2 ) {
            toast.error( "File size is exceeded, max 2 MB" );
            setSelectedFile( null );
        } else {
            // upload the file
            try {
                setLoading( true );
                let formData = new FormData();
                formData.append( 'filename', event.target.files[0] );
                const request = await util.axios.post( `${util.url}/test/upload_picture/${q["test_id"]}/${q.id}`, formData );
                const { error, msg, filename } = request.data;
                setQuestion( { ...question, filename: filename } );
                setBaseQuestion( { ...question, filename: filename } );
                if ( error ) throw msg;
                setLoading( false );
            } catch ( e ) {
                setLoading( false );
                util.handleError( e );
            }
			
        }
    };
	
    const handleChange = ( e ) => {
        const { name, value } = e.target;

        if ( name === "answer" && parseInt( question.type ) === 1 ){
            setQuestion( { ...question, [name]:value, answer1: value } );
        }else{
			
            if ( name === "type" ){
                setQuestion( { ...question, [name]:value, answer: null } );
            }else{
                setQuestion( { ...question, [name]:value } );
            }
			
			
        }
    };
	
    const saveChanges = async () => {
        try {
            if ( emptyFields() ) return util.handleError( "You have empty fields" );
            setLoading( true );
            const request = await util.axios.put( `${util.url}/tests/update_question/${q.id}`, { question } );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            setBaseQuestion( question );
            setLoading( false );
            setExpanded( !expanded );
        } catch ( e ) {
            setLoading( false );
			
            util.handleError( e );
        }
    };
	
    function emptyFields() {
		
        // Validate no empty fields
        if ( question.type === 1 ){
            // Text
            if ( util.noValues.includes( question.question ) ) return true;
            if ( util.noValues.includes( question.answer ) ) return true;
            if ( util.noValues.includes( question.answer1 ) ) return true;
            if ( util.noValues.includes( question.answer2 ) ) return true;
            if ( util.noValues.includes( question.answer3 ) ) return true;
            if ( util.noValues.includes( question.answer4 ) ) return true;
			
        }else{
            // Image
            if ( util.noValues.includes( question.question ) ) return true;
            if ( util.noValues.includes( question.answer ) ) return true;
            if ( util.noValues.includes( question.filename ) ) return true;
        }
        return false;
    }
	
    return(
        <Accordion expanded={expanded}>
            <AccordionSummary expandIcon={<FontAwesomeIcon icon={faCaretDown} /> }
			                  onClick={()=>setExpanded( !expanded )}
			                  aria-controls="panel1a-content"
			                  id="panel1a-header">
                <div className="row mb-2">
                    <div className="col-md-12">
                        <h5>#{index + 1} - {parseInt( question.type ) === 1 ? "Text":"Image"} </h5>
						
                        {
                            ( question !== baseQuestion || emptyFields() ) && !expanded ?
                                <span className="col-md-12 mt-2 text-right">
                                    <button onClick={saveChanges} className="btn btn-sm btn-info">
										Need to Save
                                    </button>
                                </span>
                                :
                                ""
                        }
                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <div className="container">
                    <div className="row">
                        <div className="col-md-2">
                            <label htmlFor="yyyyy">Type</label>
                            <select name="type" value={question.type} onChange={handleChange} id="typeOfQuestion" className="form-control">
                                <option value="1">Text</option>
                                <option value="2">Image</option>
                            </select>
                        </div>
                        <div className="col-md-7">
                            <div className="row">
                                <div className="col-md-12">
                                    <label htmlFor="questionSingle">Question</label>
                                    <ReactQuill
                                        placeholder="Write your question"
                                        id={`question${question.id}`}
                                        name={`question`}
                                        value={question.question? question.question:""}
                                        onChange={( value )=>{
                                            let e = { target:{ name: "question", value: value } };
                                            handleChange( e );
                                        }}
                                    />
                                </div>
                            </div>
							
                            {parseInt( question.type ) === 1?
                            // Text
                                <div className="mt-2">
                                    <label htmlFor="option4">Answer</label>
                                    <input className="form-control" name="answer" value={question.answer} onChange={handleChange} placeholder="Enter the answer for your question" />
                                </div>
                                :
                            // Image
                                <Fragment>
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {util.noValues.includes( question.filename ) ?
                                                        <Fragment>
                                                            <p>Click on the button to upload the image file</p>
                                                            <label htmlFor="filesX" className="btn btn-info btn-sm">
															Select file
                                                            </label>
                                                            <input
                                                                style={{ display: "none" }}
                                                                type="file"
                                                                id="filesX"
                                                                onChange={onChangeHandler}
                                                                name="file"
                                                                accept=".png,.jpg,.jpeg,.jfif"
                                                            />
                                                        </Fragment>
                                                        :
                                                        <Fragment>
                                                            <p className="text-center">
                                                                <img width="100%" src={`${util.url}/test/${q.test_id}/${question.filename}`} alt=""/>
                                                            </p>
                                                            <p className="text-center">
                                                                <button
                                                                    onClick={()=> setQuestion( { ...question, filename: null } )}
                                                                    className="btn btn-sm text-danger ml-2">
																Cancel
                                                                </button>
                                                            </p>
                                                        </Fragment>
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Fragment>
								
                            }
                        </div>
                        <div className="col-md-2">
                            {
                                parseInt( question.type ) !== 1 ?
                                    <Fragment>
                                        <label htmlFor="option4">Answer</label>
                                        <select name="answer" value={question.answer} onChange={handleChange} id="answer" className="form-control">
                                            <option value="0">-</option>
                                            <option value="A">A</option>
                                            <option value="B">B</option>
                                            <option value="C">C</option>
                                            <option value="D">D</option>
                                            <option value="AllOfTheAbove">All of the above</option>
                                            <option value="NoneOfTheAbove">None of the above</option>
                                        </select>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <label htmlFor="option4">Answers</label>
                                        <table>
                                            <tbody>
                                                <tr><td><input disabled onChange={handleChange} type="text" className="form-control" name="answer1" value={question.answer}/></td></tr>
                                                <tr><td><input onChange={handleChange} type="text" className="form-control" name="answer2" value={question.answer2}/></td></tr>
                                                <tr><td><input onChange={handleChange} type="text" className="form-control" name="answer3" value={question.answer3}/></td></tr>
                                                <tr><td><input onChange={handleChange} type="text" className="form-control" name="answer4" value={question.answer4}/></td></tr>
                                            </tbody>
                                        </table>
                                    </Fragment>
                            }
                        </div>
                        <div className="col-md-1">
                            <button id="btnDeleteQuestion" onClick={()=> setState( { ...state, confirmation: true } )} className="btn btn-sm text-danger">
                                <FontAwesomeIcon icon={faTimes} className="mr-2"/>
                            </button>
                        </div>
                        {/* {console.log( emptyFields() )} */}
                        {
                            question !== baseQuestion || emptyFields()  ?
                                <div className="col-md-12 mt-2 text-right">
                                    <button onClick={() => setQuestion( baseQuestion )} className="btn btn-sm text-danger mr-2">
										Cancel
                                    </button>
                                    <button onClick={saveChanges} className="btn btn-sm btn-info">
										Save
                                    </button>
                                </div>
                                :
                                ""
                        }
                    </div>
                </div>
            </AccordionDetails>
			
			
            {state.confirmation ?
                <SAlert
                    show={state.confirmation}
                    confirmText="Yes"
                    typeButton="info"
                    msg="Remove this question?"
                    hideAlert={()=> setState( { ...state, confirmation: false } )}
                    onConfirm={()=>
                    {
                        setState( { ...state, confirmation: false } );
                        removeQuestion( index );
                    }
                    }
                />:""}
            {util.LOADING_SCREEN( loading )}
        </Accordion>
    );
	
	
}