import { InputLabel, TextField } from '@material-ui/core';
import React, { useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import util from '../../utils/miniUtils';

const ModalNewTargets = ( { closeModal, show,goalOrCompetency,groupList,handleChange,handleCreate,form,handleChanges,handleRichTextChange } ) => {
    const isValidModal = () => {

        const msg = ( string ) => {
            return { pass: false, msg: `${string}` };
        };
       

        const { title, description, default_target } = form;
        if ( util.noValues.includes( title ) )  return msg( " Title  is missing" );
        if ( util.noValues.includes( description ) )   return msg( "Description is missing" );
        if ( util.noValues.includes( default_target ) ) return msg( "Default Target is missing" );

       
        return { pass: true };
    };

    const handleClick=()=>{
        const validation = isValidModal();
        if ( !validation.pass ) {
            return util.toast().error( validation.msg );
        }
        handleCreate();
        closeModal();    };
    
    return (
        <div>
            <Modal size='lg' show={show} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{goalOrCompetency?'Add New Competency':'Add New Goal'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row mt-1 ml-2'>
                        <TextField
                            className='col-md-6 p-2'
                            variant='outlined'
                            label={goalOrCompetency ? 'Competency Title' : 'Goal Title'}
                            name={"title"}
                            onChange={handleChanges}
                            value={form.title || ""}
                        />
                        <TextField
                            className='col-md-3 p-2'
                            variant='outlined'
                            label="Source of Evidence"
                            name={"evidence"}
                            onChange={handleChanges}
                            value={form.evidence || ""}
                        />
                        <TextField
                            className='col-md-2 p-2'
                            variant='outlined'
                            label={"Default Target"}
                            name="default_target"
                            type="number"
                            onChange={handleChanges}
                            value={form.default_target || ""}
                        />
                    </div>
                    <div className='row mt-2 ml-1'>
                        <div className='col-md-6'>
                            <TextField
                                fullWidth
                                label='Description'
                                name="description"
                                variant='outlined'
                                multiline
                                rows={5}
                                maxRows={5}
                                value={form.description || ""}
                                onChange={handleRichTextChange}

                            />
                        </div>
                       
                        <MultiSelect label="Applicable Group"  list={groupList} onChange={handleChange} name={"applicable_group"} />
                       
                    </div>
                  

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
            Close
                    </Button>
                    <Button variant="success" onClick={handleClick}>
           Create
                    </Button>
                </Modal.Footer>
            </Modal>
           
        </div>
    );
};

export default ModalNewTargets;
const MultiSelect = ( { label, list, onChange, name } ) => {
    const all=useRef();
   

    return (
        <div className='col-md-5'>
            
            <div className='employeeSettings_multiSelect form-control'>
                
                <InputLabel>
                    {label}
                </InputLabel>
                <input
                    ref={all}
                    type="checkbox"
                    className="mr-1"
                    name="All Select"
                    value="All"
                    checked={
                        list.filter( ( item ) => item?.checked !== true ).length < 1
                    }
                    // checked={!list.some( ( item ) => item?.checked !== true )}
                    onChange={onChange}
                />
                <label className='ml-1'>All Select</label>
                
                {list.map( item => <div key={item.id}>
                    <input type="checkbox" name={name} className="mr-1" value={item.id} checked={item.checked ? item.checked : false} onChange={onChange} /> 
                    <label>{item.name}</label>
                </div> )}
               
                                                               
                                                                
            </div>
           
        </div>
        
    );
};