import React, { Fragment, useState } from "react";
import util from "../../../../utils/miniUtils";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import SweetAlert from "react-bootstrap-sweetalert";
import DrawBackPosition from "./DrawBackPosition";


const user = util.user;

function JobDescription( props ) {
    let jd = props.jobDescription;
    const [isDrawingBack, setIsDrawingBack] = useState( false );
    const [show, setShow] = useState( false );
    const [loading, setLoading] = useState( false );
	
    async function applyForPosition() {
        try {
            setLoading( true );
            const data ={ id:user.candidate_id, fromTalent:true };
            const request = await util.axios.post( `${util.url}/requirements/add_candidate_position/${jd.id}`, data );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            util.toast().success( msg );
            setShow( false );
            props.refreshTable();
            props.jdHideModal(); // Hide modal
            setLoading( false );
        }catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }
    }
	
    function hideModal() {
        props.jdHideModal();
        setIsDrawingBack( false );
    }
	
    return (
        <Fragment>
            <Modal size={"lg"} show={props.jdModalVisible} onHide={()=>hideModal()}>
                <Modal.Header closeButton>
                    <Modal.Title>{jd.job_title}</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    {isDrawingBack ?
                        <DrawBackPosition
                            setIsDrawingBack={setIsDrawingBack}
                            closeModal={hideModal}
                            jd={jd}
                            refreshTable={()=>props.refreshTable()}
                        />
                        :
                        <Fragment>
                            <table className="table table-borderless table-responsive">
                                <thead/>
                                <tbody>
                                    <tr>
                                        <th>Employment Type</th>
                                        <td>{jd.employment_type}</td>
                                        <th>Location</th>
                                        <td>{util.location( jd.country, jd.state, jd.city )}</td>
                                        <th>Restricted to</th>
                                        <td>{jd.restricted_to}</td>
                                    </tr>
                                    <tr>
                                        <th>Minimum Pay rate</th>
                                        <td>{jd.min_pay}</td>
                                        <th>Maximum Pay rate</th>
                                        <td>{jd.max_pay}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <hr/>
                            <div dangerouslySetInnerHTML={{ __html: jd.job_description }}/>
                            {jd.applied ?
                                <Fragment>
                                    {[10,19,11].indexOf( jd.statusCandidate ) === 1 ?
                                        <button className="btn btn-md btn-danger float-right">
                                            <FontAwesomeIcon icon={faBan} className="mr-2" />
											Your application was already review and rejected
                                        </button>:
                                        <button className="btn btn-md btn-danger float-right"
										        onClick={()=> setIsDrawingBack( true )}
                                        >
                                            <FontAwesomeIcon icon={faTimes} className="mr-2" />
											Draw back
                                        </button>
                                    }
                                </Fragment>
                                :
                                <button className="btn btn-md btn-primary float-right"
								        onClick={()=>setShow( true )}
                                >
                                    <FontAwesomeIcon icon={faCheck} className="mr-2" />
									Apply for this Job
                                </button>}
                        </Fragment>
                    }
                </Modal.Body>
            </Modal>
            <SweetAlert
                show={show}
                warning
                showCancel
                confirmBtnText="Yes!"
                confirmBtnBsStyle="primary"
                title="Are you sure?"
                onConfirm={()=>applyForPosition()}
                onCancel={()=>setShow( false )}
                focusCancelBtn
            >
				Apply to this Job position
            </SweetAlert>
            {util.LOADING_SCREEN( loading )}
        </Fragment>
    );
}

export default JobDescription;