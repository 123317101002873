/* eslint-disable indent */
import { faBell, faEllipsisV, faEnvelope, faEye, faEyeSlash, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from "react";
import './notifi.css';
import util from "../../utils/miniUtils";
import { Tooltip } from '@material-ui/core';
import { Link } from "react-router-dom";
import useNotifications from './useNotifications';
import NotificationModal from './NotificationModal';
import Moment from 'react-moment';
import moment from 'moment';


function NotificationDropdown() {
    const { showDropdown,
        notRead,
        selectedOption, filteredNotifications,
        handleOptionChange ,handleMarkAsRead ,handleMarkAsUnread,handleMarkAsAllRead,
        handleMarkAsAllUnRead,handleDropdownClick,getNotifications,deleteNotification,
        getModelImage
    }=useNotifications();
    const [showNotificationModal,setNotificationModal]=useState( false );

    function handleCloseNotificationModal() {
        setNotificationModal( false );
    }
    const shouldShowNotification = ( role, model ) => {
        if ( role === 1 || role === 2 || role === 6 ) {
            return model !== 'talent';
        } else if ( role === 7 || role === 8 ) {
            return model !== 'job' && model !== 'talent';
        }
        return false;
    };
//    console.log( filteredNotifications );
    useEffect( () => {
        // getNotifications();
    }, [] );
   
    return (
        <div className="notification-dropdown">
            <button className="notification-dropdown-button" onClick={handleDropdownClick}>
                <FontAwesomeIcon icon={faBell} className='iconGray' />

                {notRead >= 0 && (
    <span className="notification-count">
    {notRead >= 0 && (
                    <span className="notification-count">
                        {filteredNotifications.filter( notification => shouldShowNotification( util.user.role, notification.model ) && !notification.read ).length}
                    </span>
                )}
    </span>
  )}
            </button>
            {showDropdown && (
                <>
                    <div className="notification-dropdown-content">
                        <div className='row d-flex justify-content-between p-1 mt-1 border-bottom'>
                            <b className='col-2'>Notifications</b>
                            <div className='col-5'>
                                <select className='drp_dwn' value={selectedOption} onChange={handleOptionChange}>
                                    <option value="All">All</option>
                                    <option value="Read">Read</option>
                                    <option value="Unread">Unread</option>
                                </select>
                            </div>
                            <div className="dropdown col-2 mr-2">
                                <button type="text" id="dropdownMenuButton" data-toggle="dropdown" className={"btn"} aria-haspopup="true" aria-expanded="true">
                                    <FontAwesomeIcon icon={faEllipsisV} />
                                </button>
                                <div className="dropdown-menu my_dp" aria-labelledby="dropdownMenuButton">
                                    <button className="dropdown-item" onClick={() => handleMarkAsAllRead()} >
                                        Mark all as read
                                    </button>
                                    <button className="dropdown-item" onClick={() => handleMarkAsAllUnRead()}>
                                        Mark all as unread
                                    </button>
                                    <button className="dropdown-item" onClick={() => setNotificationModal( true )}>
                                         Settings
                                    </button>
                                </div>
                            </div>
                        </div>
                        {filteredNotifications && filteredNotifications.length > 0 ? (
                            filteredNotifications.map( ( notification ) => {
                                if ( shouldShowNotification( util.user.role, notification.model ) ) {
                                    return (
                                <div
                                    key={notification.notification_id}
                                    className={`notification-item row ${notification.read ? 'read' : 'unread'}`}
                                    >
                                    <div className="notification-item-icon col-1">
                                    <img src={getModelImage( notification.model )} alt="img" className="mdl_img" />
                                    </div>
                                   
                                    {notification && notification.link ? (
                                        <Link to="#" className="notification-item-message col-8"  onClick={() => {
   
                                         window.open( notification.link ); // Open the provided link
                                         if ( !notification.read ) {
                                         handleMarkAsRead( notification.notification_id ); // Mark the notification as read if it's unread
                                         }
                                         }}>
                                            <div className="notification-item-title">{notification.title}</div>
                                            <div className="notification-item-body">{notification.message}</div>
                                            <div style={{ fontSize:'10px',float:'right' }}> <Moment format="MMMM Do, YYYY [@] h:mm A">
                                                    {moment( notification.createdAt )}
                                                </Moment></div>
                                        </Link>
                                    ) : (
                                        <div className="notification-item-message col-8">
                                            <div className="notification-item-title">{notification.title}</div>
                                            <div className="notification-item-body">{notification.message}</div>
                                            <div style={{ fontSize:'10px',float:'right' }}> <Moment format="MMMM Do, YYYY [@] h:mm A">
                                                    {moment( notification.createdAt )}
                                                </Moment></div>
                                        </div>
                                    )}

                                    <Tooltip title={notification.read ? 'Mark as Unread' : 'Mark as Read'}>
                                        <span className="col-1">
                                            <FontAwesomeIcon
                                                icon={notification.read ? faEyeSlash : faEye}
                                                onClick={() =>
                                                    notification.read
                                                        ? handleMarkAsUnread( notification.notification_id )
                                                        : handleMarkAsRead( notification.notification_id )
                                                }
                                                className="iconGray"
                                                style={{ width: '13px' }}
                                            />
                                        </span>
                                    </Tooltip>
                                    <Tooltip title="Remove Notifications">
                                        <span className="col-1">
                                            <FontAwesomeIcon
                                                icon={faTrash}
                                                onClick={() => {
                                                    deleteNotification( notification.notification_id );
                                                }}
                                                className="iconGray"
                                                style={{ width: '10px' }}
                                            />
                                        </span>
                                    </Tooltip>
                                </div>
                                );}else {
                                    return null;
                                }
                            } )
                        ) : (
                            <div className="notification-empty">No notifications</div>
                        )}
                    </div>
                </>
            )}
            <NotificationModal 
                show={showNotificationModal}
                handleClose={handleCloseNotificationModal}
            />
        </div>
        
    );
}

export default NotificationDropdown;
