/* eslint-disable react/jsx-key */
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import util from '../../../utils/miniUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAlt, faArrowLeft,  faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { Paper, Tooltip } from '@material-ui/core';
import FinalAppraisalAccordion from './FinalAppraisalAccordion';
import { CompletedStatus }  from '../Status/CompletedStatus';
import { useReactToPrint } from 'react-to-print';
import whatsapp from '../../../../assets/images/watsapp.png';
import gmail from '../../../../assets/images/gmail.png';
import printout from '../../../../assets/images/printout.png';
import download from '../../../../assets/images/download.png';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import AppraisalLog from '../AppraisalLog';
// import Pdf from "react-to-pdf";



function FinalAppraisal( { data,handleBack } ) {
    // const ref = React.createRef();
    const ref = useRef( null );

    const xportPdf = async () => {
        const canvas = await html2canvas( ref.current );
        const pdf = new jsPDF( 'p', 'mm', 'a4' );
        pdf.addImage( canvas.toDataURL( 'image/png' ), 'PNG', 0, 0, 211, 298 );
        pdf.save( 'page.pdf' );
    };
    
    const componentRef=useRef();
    const handlePrint=useReactToPrint( {
        content:()=>componentRef.current,
        documentTitle:'Final-Appraisal-Result',
        onAfterPrint:()=>alert( 'Print Success' )
        
    } );

    // const xportPdf = () => {
    //     html2canvas( document.querySelector( ".Final" ), {
    //         width: 1500,
    //         height: 900,
    //         allowTaint: true,
    //         useCORS: true
    //     } ).then( ( canvas ) => {
    //         document.body.appendChild( canvas ); // if you want see your screenshot in body.
    //         const imgData = canvas.toDataURL( "image/png" );
    //         const pdf = new jsPDF( "landscape", "pt","a4" );
    //         pdf.addImage( imgData, "PNG", 10, 10, 750, 500 );
    //         pdf.save( "download.pdf" );
    //     } );
    // };

   
   
    const [collapse, setCollapse] = useState( true );
    const [title, setTitle] = useState( "Expand All" );
    const collapseAll = () => {
        setCollapse( !collapse );
        
        setTitle( state => {
            return state === "Expand All" ? "Collapse All" : "Expand All";
        } );
    };
    const [items, setItems] = useState( [] );
    const [loading, setLoading] = useState( true );
    const[info,setInfo]=useState( [] );
    const{ appraisal_id,name }=data;
    const [period, setPeriod] = useState( {} );
    const [redirect,setRedirect]=useState( '' );
    
    const handleSocialIcon = ( url ) =>{
        if( redirect ){
            window.location.href=redirect;
            return null;
        }
        setRedirect( url );
    };
   
    // request for fetching final appraisal data
    const finalAppraisalDetail = async ( appraisal_id ) => {
        try {
            setLoading( true );
            const request = await util.axios.get( `get_appraisal_items/${appraisal_id }` );
            const { error, data, msg, period,appraisal_info } = request.data;
            if ( error ) throw msg;
            setItems( data );
            setPeriod( period );
            setInfo( appraisal_info );
            setLoading( false );
            // console.log( appraisal_info );

        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };
    useEffect( ( ) => {
        finalAppraisalDetail( appraisal_id );

    },[] );
    // console.log( appraisal_id );
    if ( loading ) return util.LOADING_SCREEN( loading );

    return (
        <>
            <div className="w-100">
                <Tooltip title="Previous">  
                    <span>
                
                        <button className="isPointer btn btn-outline-primary btn-sm rounded-circle mt-1" onClick={handleBack}>
                            <FontAwesomeIcon icon={faArrowLeft} /> 
                        </button>
                    </span>
                </Tooltip>
            </div>
            <Paper elevation={3} ref={ref}>
                <div className="container-fluid mt-3" ref={componentRef}>
               
                    <div className="row">
                        <div className="col d-flex justify-content-end mt-2">
                            <div className='share-buttons mr-1'>
                                <span onMouseEnter={collapseAll}>Share<FontAwesomeIcon icon={faShareAlt}  className="ml-1"/></span>
                                <a target={"_blank"} rel="noopener noreferrer" href={ `https://web.whatsapp.com/` }><img src={whatsapp}style={{ width:'20px',height:'20px' }} alt="whatsapp" /></a>
                                <a target={"_blank"} rel="noopener noreferrer" href={`https://mail.google.com/` }> <img src={gmail}style={{ width:'20px',height:'20px' }} alt="Gmail" /></a>
                                <a onClick={handlePrint} onMouseLeave={collapseAll}><img src={printout} style={{ width:'20px',height:'20px' }} alt="Print" /></a>
                                {/* <Pdf targetRef={ref} filename="final-appraisal.pdf">
                                    {( { toPdf } ) => ( */}
                                <a onClick={xportPdf}>
                                    <img src={download} onMouseEnter={collapseAll}alt="Download" style={{ width:'20px',height:'20px' }}/>
                                </a>
                                {/* )}
                                </Pdf> */}
                            </div>
                            {/* <div>
                                <button className="btn btn-sm" onClick={collapseAll} style={{ background:'#1a7fe4',color:'white' ,marginTop:'1px' }}>{title}</button> 
                            </div>  */}
                        </div>
                    </div>

                    <div className='row'>
                        
                        <div className="col-md-8 d-flex justify-content-between mt-2">
                                       
                            <div className="userPicture d-flex justify-content-between align-items-center">
                                <span className='p-1'><FontAwesomeIcon color='dodgerblue' size="1x" icon={faUserAlt} /></span>
                                <span className='p-1'>{name}</span>
                           
                            </div>
                        </div>
                        
                        <div className="col-md-4 d-flex justify-content-end mt-3"> 
                            <h5 className='p-1'>Appraisal Score:{info.appraisal_score}%</h5>  
                        </div>
                    </div>
                    <div className='row'>
                        
                        <div className="col-md-8 d-flex justify-content-between mt-3">
                                       
                            <h4 className='p-1'>Goals and Competencies of {period.period} Appraisal:</h4>
                           
                          
                        </div>
                        <div className='col-md-4 d-flex justify-content-end mt-3'>
                            <span>{util.humanDateMonth( period.startDate )} - {util.humanDateMonth( period.endDate )} - {util.returnYear( period.endDate )}</span>
                        </div>
                    
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                        <div className='ml-1'><CompletedStatus l2_exist={info.l2_exist} l3_exist={info.l3_exist}/>  </div>                
                        <span>{util.suspenseDotPopOverExceptionalAchievmentsFinal( info,"" )}</span>
                   
                    </div>
                    {/* {console.log( items )} */}
                    {
                        items.sort( ( a, b )=> a.type - b.type ).map( ( item, index ) => {
                            return (
                                <FinalAppraisalAccordion key={index} item={item} l2_exist={info.l2_exist} l3_exist={info.l3_exist} collapse={collapse}/>
                            
                            );
                        } )
                    }
               
                </div>
                <AppraisalLog appraisalInfo={info}/>
            </Paper>
        </>
    );
}
export default FinalAppraisal;