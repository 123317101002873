/* eslint-disable no-case-declarations */
/**
 * This reducer handle the information of the candidate
 * */
const initialState = {
    id: "",
    name: "",
    email: "",
    phone_mobile: "",
    phone_home: "",
    phone_other: "",
    country: "",
    state: "",
    city: "",
    rating: "",
    link_sent: 0,
    link_sent_date: "",
    skill_name:"unknown",
    notes:[]
};

const candidatesReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case "PROFILE_SET_CANDIDATE":
            return Object.assign( {}, action.payload ) ;
        case "PROFILE_SET_CANDIDATE_FIELD":
            let field = action.payload.field;
            let value = action.payload.value;
            return { ...state, [field]:value };
        default:
            return state;
    }
};

export default candidatesReducer;
