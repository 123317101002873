import TableOnboardedTalents from "./TableOnboardedTalents";
import Documents from "./Documents";
import React from "react";

function RequestDocuments( { data, setState, state, sendEmail } ) {
    return <>
        <div className="row mt-2">
            <div className="col-md-6">
                <TableOnboardedTalents
                    job_id={data.id}
                    selectedTalents={( d ) => setState( { ...state, selectedTalents: d } )}
                />
            </div>
            <div className="col-md-6">
                <Documents
                    data={data}
                    selectedDocuments={( d ) => setState( { ...state, selectedDocuments: d } )}
                />
            </div>
        </div>
		
        <div className="text-center mt-2">
            {
                state.selectedTalents.length > 0 &&state.selectedDocuments.length > 0 &&
				!state.loading ?
                    <button onClick={sendEmail} className="btn btn-sm btn-primary">Send Email</button>
                    :
                    ""
            }
        </div>
    </>;
}

export default RequestDocuments;