import React, { Fragment, useEffect, useState } from "react";
import NavBar from "../../navbar/NavBar";
import util from "../../../utils/miniUtils";
import PositionTitleSecondary from "./PositionTitleSecondary";
import {
    TwitterShareButton,
    FacebookShareButton,
    LinkedinShareButton,
} from "react-share";
import { Facebook, Twitter, LinkedIn, LinkRounded } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { PositionsStore } from "../../stores/PositionsStore";

export default function JobDetail( {} ) {
	
    const [loading, setLoading] = useState( true );
    const [job, setJob] = useState( null );
    const [open, setOpen] = useState( false );
    const state = PositionsStore.useState( state => state );
	
    useEffect( () => {
        // Once the page is loaded, will take the JOb ID from the url and make the request
        let id = parseInt( window.location.pathname.split( "/" )[3] );
        if ( state.job ){
            getJob( state.job.id ).then( null );
        }else{
            getJob( id ).then( null );
        }
		
        // Is executed only one time
    },[state.job.id] );
	
	
    useEffect( () => {
        // if (state.job) {
        // 	getJob(state.job.id).then(null);
        // 	const elmnt = window.document.getElementById("topSection");
        // 	if (elmnt){
        // 		elmnt.scrollTop = -200;
        // 	}
        // }
    },[state.job] );
    //
    // Get job information
    const getJob = async ( id ) => {
        try {
            // if (!id){
            // 	setLoading(false);
            // 	return
            // }
            const request = await util.axios.get( `${util.url}/job/position_information_public/${id}` );
            const { error, msg, position } = request["data"];
            if ( error ) throw  msg;
            PositionsStore.update( s=>{
                s.job = position;
            } );
            setJob( position );
            setLoading( false );
        } catch ( e ) {
            util.handleError( e );
        }
    };
	
    // Copy link to clipboard
    const copyToClipboard = () =>{
        const url = window.document.location.origin + `/careers?swe=${util.uuidv4()}&job=${job.id}&fs=true`;
        navigator.clipboard.writeText( url ).then( null );
        setOpen( true );
    };
	
    // Close snackbar
    const handleClose = ( event, reason ) => {
        if ( reason === 'clickaway' ) {
            return;
        }
        setOpen( false );
    };
	
    if ( loading ) return util.LOADING_SCREEN( loading );
	
    return(
        <Fragment>
            {window.location.pathname.split( "/" )[2] === 'job' ? <NavBar  />:null}
			
            <div className="container bg-white" id="topSection">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row ">
                            <div className="col-md-12">
                                <h3 className="pt-2"><p className="detailTitle">{job.job_title}</p></h3>
                            </div>
                            <div className="p-1 w-100 d-flex justify-content-start align-self-center">
                                <Tooltip title="Share it in Facebook">
                                    <span className="mr-3">
                                        <FacebookShareButton url={window.location.href} >
                                            <Facebook size={32} style={{ color:'dodgerblue' }} />
                                        </FacebookShareButton>
                                    </span>
                                </Tooltip>
                                <Tooltip title="Share it in Twitter">
                                    <span className="mr-3">
                                        <TwitterShareButton url={window.location.href} >
                                            <Twitter size={32} style={{ color:'dodgerblue' }} />
                                        </TwitterShareButton>
                                    </span>
                                </Tooltip>
                                <Tooltip title="Share it in Linkeding">
                                    <span className="mr-3">
                                        <LinkedinShareButton url={window.location.href} >
                                            <LinkedIn size={32} style={{ color:'dodgerblue' }}/>
                                        </LinkedinShareButton>
                                    </span>
                                </Tooltip>
                                <Tooltip title="Copy Job Link">
                                    <span className="likeLink" onClick={copyToClipboard}>
                                        <LinkRounded size={32} icon={faCopy} />
                                    </span>
                                </Tooltip>
                            </div>
                            <PositionTitleSecondary job={job} />
                        </div>
                        <div className="row">
                            <div
                                className="col-md-12 jobDescription"
                                dangerouslySetInnerHTML={{ __html: job.job_description }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                message="Job link copied"
            />
        </Fragment>
    );
}





