import React, { useEffect, Fragment } from "react";
import util from "../../utils/miniUtils";
import { Card, MuiThemeProvider, Tooltip } from "@material-ui/core";
import MaterialTable, { MTableBody } from "material-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faSync } from "@fortawesome/free-solid-svg-icons";
import FunnelGraphJobs from "../shared/FunnelGraphJobs";
import SidebarFilter from "../shared/SidebarFilter";
import RecordModalDetail from "./RecordModalDetail";
import useSalesReport from "./useSalesReport";

export default function ReportJobs(){
    // const { reportData, totalsJobs, showDetail } = JobReportStore.useState( state => state );
    const { filterData, handleChange, updatedNewData,  
        data, funnelTotals,
        getFilterData, applyFilter, totalsRowJobs, openDetail, setOpenDetail } = useSalesReport();

    useEffect( () => {
        getFilterData();
    },[ ] );

    useEffect( () => {
        // Will run applyFilter() until the filterData is filled with API information
        if( !filterData.firstTime ){
            applyFilter();  
        }
    },[ filterData ] );

    return(
       
        <Fragment>
            <div className="container-fluid mt-2">
                <div className="row">
                    <div className="col-md-2">
                        <Card elevation={1} className="p-2">
                            <SidebarFilter filterData={filterData} handleChange={handleChange} updatedNewData={updatedNewData} customTitle="Sales Lead" />
                        </Card>
                    </div>
                    <div className="col-md-8">
                        <Card elevation={1} className="p-2">
                            <MuiThemeProvider theme={util.defaultTableTheme}>
                                <MaterialTable
                                    columns={[
                                        { width:"30%", title: 'Region', field: 'job_country',
                                            render: d => {
                                                return (
                                                    <div >

                                                        {
                                                            d.country.map( ( c, i ) => {
                                                                return (
                                                                    <div className="d-flex align-items-center" key={i}>
                                                                        <img width={15} src={util.countryFlag( c )} alt={c} className="mr-1"/>
                                                                        { c }
                                                                    </div>
                                                                );
                                                            } )
                                                        }
                                                    </div>
                                                );
                                            } },
                                        { width:"5%", title: 'Sales Lead', field: 'sales_lead',
                                            render: d => {
                                                return (
                                                    <div >

                                                        {
                                                            d.sales_lead.map( ( c, i ) => {
                                                                return (
                                                                    <div className="d-flex align-items-center" key={i}>
                                                                        { c }
                                                                    </div>
                                                                );
                                                            } )
                                                        }
                                                    </div>
                                                );
                                            } },
                                        { width:"5%", title: 'Partner', field: 'partner_company', 
                                            render:d => {
                                                if ( d.partner_name === null ) return "Direct Client";
                                                return (
                                                    <Tooltip title={d.partner_name}>
                                                        <span>{d.partner_company}</span>
                                                    </Tooltip>
                                                );
                                            } },
                                        { width:"5%", title: 'Client', field: 'client_company',
                                            render:d => {
                                                return (
                                                    <Tooltip title={d.client_name}>
                                                        <span>{d.client_company}</span>
                                                    </Tooltip>
                                                );
                                            } },
                                        { width:"5%", title: 'New', field: 'new', render: d =>  <div className="text-center">{d.new}</div> },
                                        { width:"5%", title: 'Active', field: 'active', render: d =>  <div className="text-center">{d.active}</div> },
                                        { width:"5%", title: 'Hot', field: 'hot', render: d =>  <div className="text-center">{d.hot}</div> },
                                        { width:"5%", title: 'Hold', field: 'hold', render: d =>  <div className="text-center">{d.hold}</div> },
                                        { width:"5%", title: 'Closed', field: 'closed', render: d =>  <div className="text-center">{d.closed}</div> },
                                        { width:"5%", title: 'Filled', field: 'filled', render: d =>  <div className="text-center">{d.filled}</div> },
                                        { width:"5%", title: 'Dropped', field: 'dropped', 
                                            render: d => {
                                                return (
                                                    <div className="text-center">
                                                        { d.dropped }
                                                    </div>
                                                );
                                            } 
                                        },{ width:"5%", title: '',
                                            render: d => {
                                                return (
                                                    <div className="text-center">
                                                        <FontAwesomeIcon onClick={()=> setOpenDetail( { ...openDetail, showDetail: true, jobsId: d.jobs_id } )} icon={ faEye } className="iconGray isPointer" />
                                                    </div>
                                                );
                                            }
                                        },
                                    ]}
                                    data={ data }
                                    components={{
                                        Body: ( props ) => (
                                            <>
                                                <MTableBody {...props} />
                                                { totalsRowJobs( props.renderData ) }
                                            </>
                                        ),
                                    }}
                                    options={{
                                        searchFieldAlignment:"left",
                                        toolbarButtonAlignment:'left',
                                        columnsButton:true,
                                        emptyRowsWhenPaging: false,
                                        pageSizeOptions:[20,50,100],
                                        pageSize: 20,
                                        tableLayout:"auto",
                                        showTitle: false
                                    }}
                                    actions={[
                                        {
                                            icon: () => <FontAwesomeIcon icon={faSync} style={{ width:'15px' }} />,
                                            tooltip: "Reload Data",
                                            isFreeAction: true,
                                            onClick: () => applyFilter( ),
                                        }
                                    ]}
                                />
                            </MuiThemeProvider>
                        </Card>
                    </div>
                    <div className="col-md-2">
                        <FunnelGraphJobs totals={ funnelTotals( data ) } />
                    </div>
                </div>
            </div>
            {
                openDetail.showDetail ?
                    <RecordModalDetail show={openDetail.showDetail} jobsId={openDetail.jobsId} handleClose={()=> setOpenDetail( { showDetail: false, jobsId: null } ) } />
                    : 
                    null
            }
            
        </Fragment>
        
    );
}