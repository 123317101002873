/* eslint-disable indent */
import { useState } from "react";
import util from "../../../utils/miniUtils";
import countryList from "../../../utils/location/country.json";

function useModalNewCandidate() {
	// Getting Countries from the utils
	const [countries] = useState( countryList );
	// Defining the initial state for the New Candidate.
	const [newState, setNewState] = useState( {
		name: '',
		phone_mobile: '',
		email: '',
		skills: [],
		loading: false,
		country: ""
	} );

	// Save talent data on the submit of the new Talent form.
	const saveTalent = async ( closeModalAndReloadList ) => {
	    try {
	        const { name, phone_mobile, email, skills, country } = newState;
	        setNewState( { ...newState, loading: true } );

	        if ( name === "" ) throw new Error( "name is required.." );
	       
	        if ( country === "" ) throw new Error( "please select a country " );
			if ( phone_mobile === "" ) throw new Error( "phone number is required.. " );

	        if ( phone_mobile.length !== 10 ) throw new Error( "Invalid Phone Number " );
	        if ( !util.validateEmail( email ) ) throw new Error( "Invalid email " );
	        if ( skills.length === 0 ) throw new Error( "Add at least one skill" );
	        // if ( skills[0].skill === "" ) throw new Error( "select Skill title" );
	        // if ( skills[0].yearsOfExperience === "" ) throw new Error( "Enter years of experience" );

	        for ( let x = 0; x < skills.length; x++ ) {
	            if (  skills[x].yearsOfExperience === '0' ) {
	                throw new Error( "Please enter year of experience for the skill you selected" );
	            }
	        }
	        // Setting up the new candidate from the form data.
	        const candidate = {
	            name: name,
	            phone_mobile: phone_mobile,
	            email: email,
	            country: country,
	            skills
	        };
	        // Updating the server and adding the new candidate to the talents list
	        const request = await util.axios.post( `${util.url}/candidates/new-candidate`, candidate );
	        const { error, msg } = request.data;
	        if ( error ) throw msg;
	        util.toast().success( msg );
	        cleanFieldsBeforeClose();
	        closeModalAndReloadList();
	    } catch ( error ) {
	        setNewState( { ...newState, loading: false } );
	        util.handleError( error );
	    }
	};
	//Cleaning the form before closing
	const cleanFieldsBeforeClose = ( closeModalHandler = () => {} ) => {
	    closeModalHandler();
	    setNewState( {
	        name: "",
	        phone_mobile: "",
	        email: "",
	        skills: [],
	        loading: false,
	        country: ""
	    } );
	};
	// onChange handler for the form input fields
	const onChangeHandler = ( e ) => {
	    const { name, value } = e.target;
	    setNewState( { ...newState, [name]: value } );
	};

	const onExpChange = ( e, index ) => {
	    const { name, value } = e.target;
	    let t = newState.skills;
	    t[index][name] = value;
	    setNewState( { ...newState, skills: t } );
	};

	const addSkill = ( skill ) => {
	    let t = newState.skills;

  // Check if the skill already exists in the skills list
  const skillIndex = t.findIndex( ( item ) => item.id === skill.id );

  if ( skillIndex === -1 ) {
    // Skill does not exist, add it to the list
    t.push( { ...skill,yearsOfExperience:'0' }  );
  } else {
    // Skill exists, remove it from the list
    t.splice( skillIndex, 1 );
  }
  setNewState( { ...newState, skills: t } );
};
	
	const removeSkill = ( index ) => {
	    let t = newState.skills;
	    t.splice( index, 1 );
	    setNewState( { ...newState, skills: t } );
	};

	return {
		countries,
		newState, setNewState,
		saveTalent,
		cleanFieldsBeforeClose,
		onChangeHandler,
		onExpChange,
		addSkill,
		removeSkill
	};
}

export default useModalNewCandidate;
