import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faEdit, faMapPin, faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import en from 'javascript-time-ago/locale/en';
import TimeAgo from 'javascript-time-ago';
import util from '../../../../../../utils/miniUtils';

TimeAgo.addDefaultLocale( en );
const timeAgo = new TimeAgo( 'en-US' );

const JobCard = ( { job } ) => {
    const { job_title,city,employment_type, state, country, createdAt, level, role, primary_skill, secondary_skill, buy_from ,buy_to, sell_rate_type, candidates_added } = job;    
    const chips = [level, role, primary_skill, secondary_skill];
    let fromatedDate = new Date( createdAt.replaceAll( '/','-' ).replace( ' ','T' ) );

    return <div>
        <div className="jobsCardView-card">
            <div className='d-flex justify-content-between'>
                <h5>{job_title}</h5>
                <div><b>{timeAgo.format( new Date( fromatedDate ) )}</b></div>
            </div>
            <div className="">
                {chips.map( ( c,i )=>{
                    return c ? <span className="jobsCardView-chips" key={i}>
                        {c}
                    </span> : "";
                } )}
            </div>
            <div className="location d-flex justify-content-between">
                <span><FontAwesomeIcon icon={faMapPin}  color="#0071C1" className="mr-1" />{util.location( country, state, city )}</span>
                <span>
                    <b>
                        <FontAwesomeIcon icon={faBuilding} color="#0071C1" className="mr-1" />
					eJAmerica
                    </b>
                </span>
            </div>
            <div className="salary">
                <span>
                    <FontAwesomeIcon icon={faMoneyBill} color="#0071C1" className="mr-1"/>
                    <b>Salary Range:</b> {util.buyFromBuyTo( buy_from, buy_to, country )} {sell_rate_type}
                </span>
            </div>
            <div className="d-flex justify-content-between">
                <span>
                    <b htmlFor='employement-type'>Employement Type:</b> <span id="employement-type">{employment_type}</span>
                </span>
                <span>
                    <b htmlFor='candidates_added'>Candidates added:</b> <span id="employement-type">{candidates_added}</span>
                </span>
            </div>
        </div>
        <div disableSpacing className="jobsCardView-btn">
            <a
                className='text-primary'
                href={`/jobs/position/detail/${job.id}`} 
                target="_blank"
                rel="noopener noreferrer">
                <span className="btn btn-sm btn-light"><FontAwesomeIcon icon={faEdit}/></span>
            </a>
        </div>
    </div>;
};

export default JobCard;