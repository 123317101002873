import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import util from "../../../utils/miniUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faUpload } from "@fortawesome/free-solid-svg-icons";
import { ResumeStore } from "./ResumeStore";
import axios from "axios";
import { Tooltip } from "@material-ui/core";
// import TESTPARSED from "./resumesAllGood.json";


function UploadResume( { setResumeInformation } ) {
    
    const [selectedFiles, setSelectedFiles] = useState( null );
    const [loading, setLoading] = useState( false );
    const [settings, setSettings] = useState( null );
    
    useEffect( () => {
        getSettings().then( null );
    },[] );
    
    async function getSettings() {
        try {
            const request = await util.axios.get( `${util.url}/app_resources/settings` );
            const { error, msg, appSettings } = request.data;
            setSettings( appSettings );
            if ( error ) throw msg;
        }catch ( e ) {
            util.handleError( e );
        }
    }
    
    const onChangeHandler = event => {
        
        const files = [...event.target.files];
        
        if ( files.length > 5 ){
            util.toast().error( "You can select only 5 resumes at the time" );
            return;
        }
        
        for( let x = 0 ; x < files.length; x++ ) {
            let f = files[x];
            const fileSize = ( f.size / 1024 / 1024 ).toString();
            if ( fileSize.split( "." )[0] > 2 ) {
                toast.error( `File size of ${f[x].name} is exceeded, max 2 MB` );
                break;
            }
        }
        setSelectedFiles( files );
    };
    
    async function requestResumeParse() {
        try {
            setLoading( true );
            let resultArray = [];
            // loop to the files, send request to parse the files
            
            for ( let i = 0; i < selectedFiles.length; i++ ) {
                const data = new FormData();
                data.append( 'document', selectedFiles[i] );
                data.append( 'product_code', settings["product_code"] );
                const request = await axios.post( `https://processing.resumeparser.com/requestprocessing.html`, data );
                const { Results } = request.data;
                resultArray.push( Results );
            }
    
            // FOR TESTS
            // TESTPARSED.forEach(t =>{
            //     let fixedData = util.parseOldJsonToNewJson(t)
            //     fixedData.email = `${util.randomStringPassword()}@${util.randomStringPassword()}.com`
            //     fixedData.name = `testName-${util.randomStringPassword()}`
            //     console.log(fixedData.name)
            //     resultArray.push(fixedData)
            // })
            // FOR TESTS
            
            // Review all parsed files to transform them into new JSON
            if ( resultArray.length === 0 ) throw new Error( "Any resume were parsed" );
            
            // Check each resume for errors
            
            for ( let i = 0; i < resultArray.length; i++ ) {
                if ( resultArray[i][0]["HireAbilityJSONResults"].length === 1 ){
                    resultArray[i].errorMsg = resultArray[i][0]["HireAbilityJSONResults"][0]["ProcessingErrors"][0].Error[0]["ErrorMessage"];
                    resultArray[i].error = true;
                }else{
                    const fixedData = util.parseOldJsonToNewJson( resultArray[i][0]["HireAbilityJSONResults"][0] );
                    let oldData = resultArray[i][0]["HireAbilityJSONResults"][0];
                    const { accountValidThrough, currentUsage, remainingUsage, usageLimit, usageLimitType } = oldData;
                    resultArray[i] = fixedData;
                    resultArray[i].error = false;
                    resultArray[i].accountValidThrough = accountValidThrough;
                    resultArray[i].currentUsage = currentUsage;
                    resultArray[i].remainingUsage = remainingUsage;
                    resultArray[i].usageLimit = usageLimit;
                    resultArray[i].usageLimitType = usageLimitType;
                }
            }

            // Send new account status to DB using data from the last element of the array
            
            const { accountValidThrough, currentUsage, remainingUsage, usageLimit, usageLimitType } = resultArray[resultArray.length - 1];
            let parse_information = {
                accountValidThrough: accountValidThrough,
                currentUsage: currentUsage,
                remainingUsage: remainingUsage,
                usageLimit: usageLimit,
                usageLimitType: usageLimitType
            };

            await util.axios.put( `${util.url}/app_resources/settings/parser`, parse_information );
            
            // Send data to Store
            ResumeStore.update( s => {
                s.data =  resultArray[0];
                s.datas =  resultArray;
                s.file =   selectedFiles;
                s.files =   selectedFiles;
            } );
            // Change actionStep
            // LoadingStore.update(s => {s.isLoading =  false})
            setLoading( false );
            setResumeInformation();
        } catch ( e ) {
            // setResumeUploadFail()
            setLoading( false );
            util.handleError( e );
        }
    }
    
    if ( loading ){
        return (
            <Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h5 className="text-center">Parsing Resume</h5>
                            {util.LOADING_SCREEN( loading )}
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
    
    return(
        <Fragment>
            <div className="form-group col-md-12 mb-0">
                <p>Click on the button to upload the PDF or Doc files, max 5 files</p>
                <label htmlFor="filesX" className="btn btn-info btn-sm">
                    Select file
                </label>
                <input
                    style={{ display: "none" }}
                    type="file"
                    id="filesX"
                    onChange={onChangeHandler}
                    name="file"
                    multiple={true}
                    accept=".pdf,.doc,.docx"
                />
            </div>
            {selectedFiles === null ? "":
                <Fragment>
                    <table className="table table-sm">
                        <thead>
                            <tr>
                                <td/>
                                <th>Filename</th>
                            </tr>
                        </thead>
                        <tbody>
                            {[...selectedFiles].map( ( file, index ) => {
                                return(
                                    <tr key={index}>
                                        <td>
                                            <Tooltip title="Remove file">
                                                <span
                                                    onClick={()=>{
                                                        delete selectedFiles[index];
                                                        setSelectedFiles( [...selectedFiles].filter( s=>s ) );
                                                    }}
                                                    className="isPointer text-danger">
                                                    <FontAwesomeIcon icon={faTimes} />
                                                </span>
                                            </Tooltip>
                                        </td>
                                        <td>{file.name}</td>
                                    </tr>
                                );
                            } )}
                        </tbody>
                    </table>
                    {/*<p className="text-center">{selectedFile.name}</p>*/}
                    <p className="text-center">
                        <button
                            onClick={()=> setSelectedFiles( null )}
                            className="btn btn-sm text-danger ml-2">
                            Cancel
                        </button>
                        <button
                            onClick={()=> requestResumeParse()}
                            className="btn btn-sm btn-primary">
                            <FontAwesomeIcon icon={faUpload} className="mr-2"/>
                            Upload
                        </button>
                    </p>
                </Fragment>
            }
        </Fragment>
    );
}

export default  UploadResume;

//
// const TESTPARSED =  [
//     {
//         "id" : "2916331163231",
//         "usageLimitType":"allocation",
//         "usageLimit":"30",
//         "currentUsage":"9",
//         "remainingUsage":"21",
//         "accountValidThrough":"10-29-2020",
//         "LinkedInHeadline":"jesus.walker12@gmail.com Looking for the best.",
//         "GivenName":"Jose",
//         "MiddleName":"Kevin",
//         "FamilyName":"Walker",
//         "FormattedName":"Jesús Kevin Walker",
//         "DisabilityIndicator":false,
//         "Phone":[
//             {
//                 "Number":" 6861317291",
//                 "Label":"mobile"
//             }
//         ],
//         "Email":[
//             {
//                 "Address":"jesus.walker12@gmail.com",
//                 "Label":"personal"
//             }
//         ],
//         "LinkedIn":[
//             {
//                 "Address":"www.linkedin.com/in/jes%C3%BAs-kevin-Self",
//                 "Label":"personal",
//                 "LabelDescription":"Homepage"
//             }
//         ],
//         "MostRecentJobTitle":"Lead Engineer",
//         "PersonCompetency":[
//             {
//                 "CompetencyName":"Applications",
//                 "CompetencyLevel":"beginner"
//             },
//             {
//                 "CompetencyName":"Bilingual",
//                 "CompetencyLevel":"beginner"
//             },
//             {
//                 "CompetencyName":"Desktop Applications",
//                 "CompetencyLevel":"beginner"
//             },
//             {
//                 "CompetencyName":"Desktop Computer",
//                 "CompetencyLevel":"beginner"
//             },
//             {
//                 "CompetencyName":"English Language",
//                 "CompetencyLevel":"beginner"
//             },
//             {
//                 "CompetencyName":"French Language",
//                 "CompetencyLevel":"beginner"
//             },
//             {
//                 "CompetencyName":"Javascript",
//                 "CompetencyLevel":"beginner"
//             },
//             {
//                 "CompetencyName":"Linux",
//                 "CompetencyLevel":"beginner"
//             },
//             {
//                 "CompetencyName":"PHP Programming Language",
//                 "CompetencyLevel":"beginner"
//             },
//             {
//                 "CompetencyName":"Postgres",
//                 "CompetencyLevel":"intermediate"
//             },
//             {
//                 "CompetencyName":"PostgreSQL",
//                 "CompetencyLevel":"intermediate"
//             },
//             {
//                 "CompetencyName":"SCRUM",
//                 "CompetencyLevel":"beginner"
//             },
//             {
//                 "CompetencyName":"Scrum Agile Methodology",
//                 "CompetencyLevel":"beginner"
//             }
//         ],
//         "PositionHistory":[
//             {
//                 "Employer":"SRAX",
//                 "PositionTitle":"Lead Engineer",
//                 "StartDate":"2017-05-01",
//                 "CurrentIndicator":true,
//                 "Industry":{
//                     "Name":"engineering"
//                 },
//                 "Description":"Mexicali Area, Mexico Developing API's for mobile and web products Laravel Mongo Postgres",
//                 "UserArea":[
//                     {
//                         "EmployerNameConfidenceRating":"75",
//                         "JobTitleConfidenceRating":"100",
//                         "StartDateConfidenceRating":"100",
//                         "EndDateConfidenceRating":"100",
//                         "DatesConfidenceRating":"100",
//                         "DescriptionConfidenceRating":"100",
//                         "PositionConfidenceRating":"93"
//                     }
//                 ]
//             },
//             {
//                 "Employer":"AWS.",
//                 "PositionTitle":"Intern",
//                 "StartDate":"2016-12-01",
//                 "EndDate":"2017-05-01",
//                 "Description":"Iconos de Mexicali Carroseros sur, Mexicali Baja California Develop under SCRUM desktop applications powered by WinDev.",
//                 "UserArea":[
//                     {
//                         "EmployerNameConfidenceRating":"75",
//                         "JobTitleConfidenceRating":"100",
//                         "StartDateConfidenceRating":"100",
//                         "EndDateConfidenceRating":"100",
//                         "DatesConfidenceRating":"100",
//                         "DescriptionConfidenceRating":"100",
//                         "PositionConfidenceRating":"93"
//                     }
//                 ]
//             }
//         ],
//         "License":[
//             {
//                 "LicenseName":"Universidad Autónoma de Baja California Lic."
//             }
//         ],
//         "Languages":[
//             {
//                 "LanguageCode":"FR",
//                 "Read":false,
//                 "Write":false,
//                 "Speak":true
//             },
//             {
//                 "LanguageCode":"EN",
//                 "Read":false,
//                 "Write":false,
//                 "Speak":true
//             },
//             {
//                 "LanguageCode":"ES",
//                 "Read":false,
//                 "Write":false,
//                 "Speak":true
//             }
//         ],
//
//     },
//     {
//         "id" : "2916331163231",
//         "usageLimitType":"allocation",
//         "usageLimit":"30",
//         "currentUsage":"9",
//         "remainingUsage":"21",
//         "accountValidThrough":"10-29-2020",
//         "LinkedInHeadline":"jesus.walker12@gmail.com Looking for the best.",
//         "GivenName":"Helena",
//         "MiddleName":"Emma",
//         "FamilyName":"Walker",
//         "FormattedName":"Jesús Kevin Walker",
//         "DisabilityIndicator":false,
//         "Phone":[
//             {
//                 "Number":" 6861317291",
//                 "Label":"mobile"
//             }
//         ],
//         "Email":[
//             {
//                 "Address":"jesus.walker12@gmail.com",
//                 "Label":"personal"
//             }
//         ],
//         "LinkedIn":[
//             {
//                 "Address":"www.linkedin.com/in/jes%C3%BAs-kevin-Self",
//                 "Label":"personal",
//                 "LabelDescription":"Homepage"
//             }
//         ],
//         "MostRecentJobTitle":"Lead Engineer",
//         "PersonCompetency":[
//             {
//                 "CompetencyName":"Applications",
//                 "CompetencyLevel":"beginner"
//             },
//             {
//                 "CompetencyName":"Bilingual",
//                 "CompetencyLevel":"beginner"
//             },
//             {
//                 "CompetencyName":"Desktop Applications",
//                 "CompetencyLevel":"beginner"
//             },
//             {
//                 "CompetencyName":"Desktop Computer",
//                 "CompetencyLevel":"beginner"
//             },
//             {
//                 "CompetencyName":"English Language",
//                 "CompetencyLevel":"beginner"
//             },
//             {
//                 "CompetencyName":"French Language",
//                 "CompetencyLevel":"beginner"
//             },
//             {
//                 "CompetencyName":"Javascript",
//                 "CompetencyLevel":"beginner"
//             },
//             {
//                 "CompetencyName":"Linux",
//                 "CompetencyLevel":"beginner"
//             },
//             {
//                 "CompetencyName":"PHP Programming Language",
//                 "CompetencyLevel":"beginner"
//             },
//             {
//                 "CompetencyName":"Postgres",
//                 "CompetencyLevel":"intermediate"
//             },
//             {
//                 "CompetencyName":"PostgreSQL",
//                 "CompetencyLevel":"intermediate"
//             },
//             {
//                 "CompetencyName":"SCRUM",
//                 "CompetencyLevel":"beginner"
//             },
//             {
//                 "CompetencyName":"Scrum Agile Methodology",
//                 "CompetencyLevel":"beginner"
//             }
//         ],
//         "PositionHistory":[
//             {
//                 "Employer":"SRAX",
//                 "PositionTitle":"Lead Engineer",
//                 "StartDate":"2017-05-01",
//                 "CurrentIndicator":true,
//                 "Industry":{
//                     "Name":"engineering"
//                 },
//                 "Description":"Mexicali Area, Mexico Developing API's for mobile and web products Laravel Mongo Postgres",
//                 "UserArea":[
//                     {
//                         "EmployerNameConfidenceRating":"75",
//                         "JobTitleConfidenceRating":"100",
//                         "StartDateConfidenceRating":"100",
//                         "EndDateConfidenceRating":"100",
//                         "DatesConfidenceRating":"100",
//                         "DescriptionConfidenceRating":"100",
//                         "PositionConfidenceRating":"93"
//                     }
//                 ]
//             },
//             {
//                 "Employer":"AWS.",
//                 "PositionTitle":"Intern",
//                 "StartDate":"2016-12-01",
//                 "EndDate":"2017-05-01",
//                 "Description":"Iconos de Mexicali Carroseros sur, Mexicali Baja California Develop under SCRUM desktop applications powered by WinDev.",
//                 "UserArea":[
//                     {
//                         "EmployerNameConfidenceRating":"75",
//                         "JobTitleConfidenceRating":"100",
//                         "StartDateConfidenceRating":"100",
//                         "EndDateConfidenceRating":"100",
//                         "DatesConfidenceRating":"100",
//                         "DescriptionConfidenceRating":"100",
//                         "PositionConfidenceRating":"93"
//                     }
//                 ]
//             }
//         ],
//         "License":[
//             {
//                 "LicenseName":"Universidad Autónoma de Baja California Lic."
//             }
//         ],
//         "Languages":[
//             {
//                 "LanguageCode":"FR",
//                 "Read":false,
//                 "Write":false,
//                 "Speak":true
//             },
//             {
//                 "LanguageCode":"EN",
//                 "Read":false,
//                 "Write":false,
//                 "Speak":true
//             },
//             {
//                 "LanguageCode":"ES",
//                 "Read":false,
//                 "Write":false,
//                 "Speak":true
//             }
//         ],
//
//     },
//     {
//         "id" : "2916331163231",
//         "usageLimitType":"allocation",
//         "usageLimit":"30",
//         "currentUsage":"9",
//         "remainingUsage":"21",
//         "accountValidThrough":"10-29-2020",
//         "LinkedInHeadline":"jesus.walker12@gmail.com Looking for the best.",
//         "GivenName":"Jesús",
//         "MiddleName":"Kevin",
//         "FamilyName":"Walker",
//         "FormattedName":"Jesús Kevin Walker",
//         "DisabilityIndicator":false,
//         "Phone":[
//             {
//                 "Number":" 6861317291",
//                 "Label":"mobile"
//             }
//         ],
//         "Email":[
//             {
//                 "Address":"jesus.walker12@gmail.com",
//                 "Label":"personal"
//             }
//         ],
//         "LinkedIn":[
//             {
//                 "Address":"www.linkedin.com/in/jes%C3%BAs-kevin-Self",
//                 "Label":"personal",
//                 "LabelDescription":"Homepage"
//             }
//         ],
//         "MostRecentJobTitle":"Lead Engineer",
//         "PersonCompetency":[
//             {
//                 "CompetencyName":"Applications",
//                 "CompetencyLevel":"beginner"
//             },
//             {
//                 "CompetencyName":"Bilingual",
//                 "CompetencyLevel":"beginner"
//             },
//             {
//                 "CompetencyName":"Desktop Applications",
//                 "CompetencyLevel":"beginner"
//             },
//             {
//                 "CompetencyName":"Desktop Computer",
//                 "CompetencyLevel":"beginner"
//             },
//             {
//                 "CompetencyName":"English Language",
//                 "CompetencyLevel":"beginner"
//             },
//             {
//                 "CompetencyName":"French Language",
//                 "CompetencyLevel":"beginner"
//             },
//             {
//                 "CompetencyName":"Javascript",
//                 "CompetencyLevel":"beginner"
//             },
//             {
//                 "CompetencyName":"Linux",
//                 "CompetencyLevel":"beginner"
//             },
//             {
//                 "CompetencyName":"PHP Programming Language",
//                 "CompetencyLevel":"beginner"
//             },
//             {
//                 "CompetencyName":"Postgres",
//                 "CompetencyLevel":"intermediate"
//             },
//             {
//                 "CompetencyName":"PostgreSQL",
//                 "CompetencyLevel":"intermediate"
//             },
//             {
//                 "CompetencyName":"SCRUM",
//                 "CompetencyLevel":"beginner"
//             },
//             {
//                 "CompetencyName":"Scrum Agile Methodology",
//                 "CompetencyLevel":"beginner"
//             }
//         ],
//         "PositionHistory":[
//             {
//                 "Employer":"SRAX",
//                 "PositionTitle":"Lead Engineer",
//                 "StartDate":"2017-05-01",
//                 "CurrentIndicator":true,
//                 "Industry":{
//                     "Name":"engineering"
//                 },
//                 "Description":"Mexicali Area, Mexico Developing API's for mobile and web products Laravel Mongo Postgres",
//                 "UserArea":[
//                     {
//                         "EmployerNameConfidenceRating":"75",
//                         "JobTitleConfidenceRating":"100",
//                         "StartDateConfidenceRating":"100",
//                         "EndDateConfidenceRating":"100",
//                         "DatesConfidenceRating":"100",
//                         "DescriptionConfidenceRating":"100",
//                         "PositionConfidenceRating":"93"
//                     }
//                 ]
//             },
//             {
//                 "Employer":"AWS.",
//                 "PositionTitle":"Intern",
//                 "StartDate":"2016-12-01",
//                 "EndDate":"2017-05-01",
//                 "Description":"Iconos de Mexicali Carroseros sur, Mexicali Baja California Develop under SCRUM desktop applications powered by WinDev.",
//                 "UserArea":[
//                     {
//                         "EmployerNameConfidenceRating":"75",
//                         "JobTitleConfidenceRating":"100",
//                         "StartDateConfidenceRating":"100",
//                         "EndDateConfidenceRating":"100",
//                         "DatesConfidenceRating":"100",
//                         "DescriptionConfidenceRating":"100",
//                         "PositionConfidenceRating":"93"
//                     }
//                 ]
//             }
//         ],
//         "License":[
//             {
//                 "LicenseName":"Universidad Autónoma de Baja California Lic."
//             }
//         ],
//         "Languages":[
//             {
//                 "LanguageCode":"FR",
//                 "Read":false,
//                 "Write":false,
//                 "Speak":true
//             },
//             {
//                 "LanguageCode":"EN",
//                 "Read":false,
//                 "Write":false,
//                 "Speak":true
//             },
//             {
//                 "LanguageCode":"ES",
//                 "Read":false,
//                 "Write":false,
//                 "Speak":true
//             }
//         ],
//
//     }
// ]
