import React, { Fragment, useEffect, useState } from "react";
import util from "../../utils/miniUtils";
import { Drawer } from "@material-ui/core";
import JobSingle from "./JobSingle";
import DrawBackPosition from "../../jobs/components/jobLists/talent/DrawBackPosition";
import ModalCX from "../modals/ModalCX";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export default function JobsAppliedTalent( { isValid, userId } ) {
	
    const [jobs, setJobs] = useState( [] );
    const [loading, setLoading] = useState( false );
    const [showDrawer, setShowDrawer] = useState( false );
    const [jobData, setJobData] = useState( null );
    const [isDrawingBack, setIsDrawingBack] = useState( false );
	
    useEffect( () => {
        getJobsApplied().then( null );
    },[isValid] );
	
    const getJobsApplied = async () => {
        try {
            if ( !isValid ) return;
            setLoading( true );
            const request = await util.axios.get( `${util.url}/talent/applied_jobs_talent/${userId}` );
            const { error, msg, jobsApplied } = request["data"];
            if ( error ) throw msg;
            setJobs( jobsApplied );
            setLoading( false );
        } catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }
    };
	
    function openDrawer( job ) {
        let t = job;
        t.id = job.jobId;
        setJobData( t );
        setShowDrawer( true );
    }
	
    function closeDrawer() {
        // setJobData(null)
        setShowDrawer( false );
    }
	
    return(
        <div className="bg-white">
            <table className="table">
                <thead>
                    <tr>
                        <th>JobID</th>
                        <th>Job Status</th>
                        <th>Talent Status</th>
                        <th>Job Title</th>
                        <th>Location</th>
                    </tr>
                </thead>
                <tbody>
                    {jobs.map( ( j,i ) =>{
                        let location = j.city + " - " + j.state + " - " + j.country;
                        return (
                            <Fragment key={i}>
                                <tr>
                                    <td>
                                        <span className="likeLink" onClick={()=>openDrawer( j )}>
                                            <b>{util.zeroPad( j["jobId"], 5 )}</b>
                                        </span>
                                    </td>
                                    <td>{util.jobStatusOnlyIcons( parseInt( j["jobStatus"] ) )}</td>
                                    <td>{j["talentStatusName"]}</td>
                                    <td>
                                        <span className="likeLink" onClick={()=>openDrawer( j )}>
                                            {j["job_title"]}
                                        </span>
                                    </td>
                                    <td>{location}</td>
                                </tr>
                            </Fragment>
                        );
                    } )}
                </tbody>
            </table>
            <Drawer anchor="right" open={showDrawer} onClose={closeDrawer}>
                <div className="drawerTitle_JP" style={{ minWidth: window.innerWidth * .40 }}>
					Job Description
                </div>
                <div className="container">
                    <div className="row">
                        {jobData ?
                            <Fragment>
                                <div className="col-md-12">
                                    <JobSingle job={jobData} />
                                </div>
                                <div className="col-md-12 p-2">
                                    <Fragment>
                                        {[10,19,11].includes( parseInt( jobData["talentStatus"] ) ) || jobData["talent_dropped"] === 1?
                                            <p className="text-danger">You withdrew the application</p>
                                            :
                                            <button className="btn btn-sm btn-danger float-right"
											        onClick={()=> {
												        closeDrawer();
												        setIsDrawingBack( true );
											        }}
                                            >
                                                <FontAwesomeIcon icon={faTimes} className="mr-2" />
												Withdraw
                                            </button>
                                        }
                                    </Fragment>
                                </div>
                                <div className="col-md-12">
                                    <div
                                        className="drawerJobDescription_JP"
                                        dangerouslySetInnerHTML={{ __html:jobData.job_description }}
                                    />
                                </div>
                            </Fragment>
                            :
                            null
                        }
                    </div>
                </div>
            </Drawer>
            {util.LOADING_SCREEN( loading )}
            <ModalCX
                title="Withdraw Position"
                show={isDrawingBack}
                handleClose={()=>setIsDrawingBack( false )}
            >
                <DrawBackPosition
                    closeModal={()=>setIsDrawingBack( false )}
                    jd={jobData}
                    refreshTable={()=> {
                        setIsDrawingBack( false );
                        setJobData( null );
                        getJobsApplied().then( null );
                    }}
                    setIsDrawingBack={()=>setIsDrawingBack( false )}
                />
            </ModalCX>
        </div>
    );
}