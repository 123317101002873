import React, { Fragment, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import util from "../utils/miniUtils";


function EmailModal( props ) {
    // This modal will display the template of the email that will be send to the evaluator
    const [index] = useState( props.index );
    const [emailTemplate, setEmailTemplate] = useState( {} );
    const [emailTemplates, setEmailTemplates] = useState( [] );

    const textAreaRef = useRef();


    useEffect( () => {
        getEmailTemplates().then( r => null );
    },[] );

    function handleChangeAutoComplete( value ) {
        const t = emailTemplates.filter( ( template ) => template.title === value )[0];
        setEmailTemplate( t );
    }

    function handleChange( e ) {
        const { name, value } = e.target;
        setEmailTemplate( { ...emailTemplate, [name]:value } );
    }

    async function getEmailTemplates() {
        try {
            const request = await util.axios.get( `${util.url}/app_resources/email_templates` );
            const { error, msg, emails } = request.data;
            setEmailTemplates( emails );
            if ( error ) throw msg;

        } catch ( error ) {
            util.handleError( error );
        }
    }

    function variableSelected( e ) {
        let temp = emailTemplate;
        temp.description = temp.description + " ["+ e.target.innerText +"] ";
        setEmailTemplate( Object.assign( {}, temp ) );
        textAreaRef.current.focus();
    }

    function sendRequest() {
        props.sendRequest( index, emailTemplate );
    }

    return(
        <Fragment>
            <Modal centered size={"lg"} show={props.show} onHide={()=>props.handleClose()} backdrop="static" keyboard={false} open>
                <Modal.Header closeButton>
                    <Modal.Title>Email request for Evaluator</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12">
                        <Autocomplete
                            freeSolo
                            disableClearable
                            onChange={( e, value ) => handleChangeAutoComplete( value )}
                            options={emailTemplates.map( ( option ) => option.title )}
                            renderInput={( params ) => (
                                <TextField
                                    {...params}
                                    label="Title"
                                    margin="normal"
                                    name="title"
                                    value={emailTemplate.title}
                                    onChange={handleChange}
                                    variant="standard"
                                    InputProps={{ ...params.InputProps, type: "search" }}
                                />
                            )}
                        />
                    </div>
                    <div className="col-md-12 mt-1">
                        <textarea
                            ref={textAreaRef}
                            className="form-control"
                            rows={10}
                            value={emailTemplate.description}
                            name="description"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="variables mt-2">
                        <p className="text-center font-weight-bold">Available Merge Fields</p>
                        <ul onClick={( e )=>variableSelected( e )}>
                            {props.emailType === 2? <li>EVALUATOR_NAME</li>:""}
                            {props.emailType === 2? <li>EVALUATION_LINK</li>:""}
                            <li>TALENT_NAME</li>
                            <li>JOB_TITLE</li>
                            <li>JOB_DESCRIPTION</li>
                            <li>JOB_LOCATION</li>
                            {props.emailType === 2? <li>INTERVIEW_DATE</li>:""}
                            <li>RECRUITER_NAME</li>
                            <li>RECRUITER_EMAIL</li>
                            <li>RECRUITER_PHONE</li>
                            <li>EJ_AMERICA_LINK</li>
                        </ul>
                    </div>


                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger btn-sm" onClick={()=>props.handleClose()}>
                        Close
                    </button>
                    <button className="btn btn-sm btn-primary" onClick={()=>sendRequest()}>
                        <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                        Send
                    </button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}

export default EmailModal;


