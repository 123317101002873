import React from "react";
import { Tabs, Tab, Typography } from "@material-ui/core";
// import  TabPanel  from '@material-ui/lab/TabPanel';
import Box from '@material-ui/core/Box';

import PropTypes from 'prop-types';
import ScreeningQuestionnaire from "./ScreeningQuestionnaire";
import ScreeningComments from "./ScreeningComments";
import ScreeningLogs from "./ScreeningLogs";
function TabPanel( props ) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps( index ) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs( { pcData } ) {
    const [value, setValue] = React.useState( 0 );

    const handleChange = ( event, newValue ) => {
        setValue( newValue );
    };

    return (
        <Box className="screening-container" sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Screening Questionnaire" {...a11yProps( 0 )} />
                    <Tab label="Comments" {...a11yProps( 1 )} />
                    <Tab label="Logs" {...a11yProps( 2 )} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <ScreeningQuestionnaire pcId={pcData.id_position_candidate} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ScreeningComments />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <ScreeningLogs />
            </TabPanel>
        </Box>
    );
}
