import React, { Fragment, useState } from "react";
import util from "../../../../utils/miniUtils";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClock, faCommentAlt, faTags, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import StarRatings from "react-star-ratings";
import Drawer from "@material-ui/core/Drawer";

export default function PreviousEvaluations( { data } ) {
	
    const [evaluations, setEvaluations] = useState( [] );
    const [evaluation, setEvaluation] = useState( null );
    const [loading, setLoading] = useState( false );
    const [showDrawer, setShowDrawer] = useState( false );
    const numberOfEvaluations = 1;
	
	
    const getEvaluations = async () => {
        try {
            setLoading( true );
            const response = await util.axios.get( `/job_position/client_interviews_list/${data.position_id}/${data.candidate_id}` );
            const { error, msg, interviews } = response.data;
            if ( error ) throw msg;
            setEvaluations( interviews );
            setLoading( false );
        } catch ( e ) {
            setLoading( false );
            util.handleError( e );
        }
    };
	
    const openDrawer = ( t ) => {
        setEvaluation( t.evaluation );
        setShowDrawer( true );
    };
	
    const interviewStatus = ( status ) => {
        switch ( parseInt( status ) ){
            case 1: return "Interview Cleared";
            case 2: return "Rejected";
            case 3: return "Hold";
            default: return "0";
        }
    };
	
    return(
        <Fragment>
            <OverlayTrigger
                trigger="click"
                placement="right-start"
                rootClose={true}
                rootCloseEvent="click"
                overlay={
                    <Popover id={`popover-positioned-right`}>
                        <Popover.Title as="h3">Evaluations</Popover.Title>
                        <Popover.Content>
                            <table className="tableCustom">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Evaluation</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {evaluations?.map( ( e,i )=>{
                                        return(
                                            <tr key={i}>
                                                <td>
                                                    {util.humanDate( e.date, true )}
                                                </td>
                                                <td onClick={()=> openDrawer( e )} className="likeLink">
                                                    {interviewStatus( e.evaluation.final_decision )}
                                                </td>
                                            </tr>
                                        );
                                    } )}
                                </tbody>
                            </table>
                        </Popover.Content>
                    </Popover>
                }
            >
                {numberOfEvaluations === 0 ?
                    <button disabled className="btn btn-sm simpleBlankButton">Not Available</button>
                    :
                    <button onClick={( e )=>{
                        e.stopPropagation();
                        getEvaluations().then( null );
                    }
                    } className="btn btn-sm btn-primary simpleBlankButton">View Evaluations</button>
                }
			
            </OverlayTrigger>
            {util.LOADING_SCREEN( loading )}
			
            <Drawer anchor="right" open={showDrawer} onClose={()=>setShowDrawer( false )}>
                {evaluation ?
                    <div className="container pt-2" style={{ width: window.innerWidth * .30 }}>
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Evaluation</h2>
                                {evaluation ?
                                    <Fragment>
                                        <p><b>Final Decision:</b> {parseInt( evaluation.final_decision ) === 1 ?
                                            <Fragment>
                                                <FontAwesomeIcon icon={faCheck} className="mr-2 text-success"/>
												Interview Cleared
                                            </Fragment>
                                            :
                                            parseInt( evaluation.final_decision ) === 2 ?
                                                <Fragment>
                                                    <FontAwesomeIcon icon={faTimesCircle} className="mr-2 text-danger"/>
													Rejected
                                                </Fragment>
                                                :
                                                <Fragment>
                                                    <FontAwesomeIcon icon={faClock} className="mr-2 text-warning"/>
													Hold
                                                </Fragment>
                                        }</p>
                                        <p><FontAwesomeIcon icon={faCommentAlt} className="mr-2"/> <b>Notes:</b></p>
                                        <p className="textFeedbackDrawer">{evaluation.notes}</p>
                                        <hr/>
                                        <p><FontAwesomeIcon icon={faTags} className="mr-2"/> <b>Skills</b></p>
                                        {evaluation?.skills.map( skill => {
                                            return (
                                                <div key={skill.id}>
                                                    <h3>{skill["skillName"]}</h3>
                                                    <div className="d-flex ">
                                                        <b>Rate</b>:
                                                        <StarRatings
                                                            rating={skill["givenRate"] || 0}
                                                            starDimension="20px"
                                                            starSpacing="1px"
                                                            starRatedColor="rgb(233,227,92)"
                                                        /> {skill["givenRate"]}/5
                                                    </div>
                                                    <div><b>Feedback:</b></div>
                                                    <div className="textFeedbackDrawer">{skill.feedback}</div>
                                                </div>
                                            );
                                        } )}
                                    </Fragment> : ""
                                }
							
							
                            </div>
                        </div>
                    </div>
                    :
                    ""
                }
            </Drawer>
		
        </Fragment>
    );
}


