import React, { Fragment, useEffect, useState } from "react";
import { MuiThemeProvider } from "@material-ui/core";
import util from "../utils/miniUtils";
import MaterialTable from "material-table";
import EvaluationComponent from './evaluation/EvaluationComponent';

function EvaluationsByTalent() {
	
    const [talents, setTalents] = useState( [] );
    const [backDropOpen, setBackDropOpen] = useState( false );
    const [job, setJob] = useState( null );
    const [tempJobId] = useState( null );
    const [serId, setSerId] = useState( null );
	

	
    useEffect( () => {
        getTalents().then( null );
    },[] );
	
    async function getTalents() {
        try {
            setJob( null );
            setBackDropOpen( true );
            const req = await util.axios.get( `${util.url}/evaluator_by_talents/list/` );
            const { error, msg, talents } = req.data;
            if ( error ) throw msg;
            setTalents( talents );
            setBackDropOpen( false );
        }catch ( e ) {
            setBackDropOpen( false );
            util.handleError( e );
        }
    }
	
	
    return(
        <Fragment>
            <div className="container-fluid">
                <div className="row">
					
                    <div className="col-md-5">
                        {
                            talents && talents.length > 0 ?
                                <MuiThemeProvider theme={util.defaultTableThemeSlim}>
                                    <MaterialTable
                                        columns={[
                                            {
                                                field: "serId",
                                                title: "Job ID",
                                                width:"5%",
                                                render: d => {
                                                    return(
                                                        <div className={tempJobId === d["serId"] ? "rowSelectedBorder":""}>
                                                            {d.job_id ? util.zeroPad( d.job_id, 5 ) : "Internal"}
                                                        </div>
                                                    );
													
                                                }
                                            },
                                            {
                                                field: "statusJobPosition",
                                                title: "Job Status",
                                                width:"5%",
                                                render: data => {
                                                    return util.jobStatusOnlyIcons( data["statusJobPosition"] );
                                                }
                                            },
                                            {
                                                field: "serStatus",
                                                title: "Evaluation Status",
                                                width:"5%",
                                                render: data => {
                                                    return util.statusEvaluationSkillEvaluationResult( data["final_decision"] );
                                                }
                                            },
                                            {
                                                field: "talent",
                                                title: "Talent",
                                                width:"70%"
                                            },
                                            {
                                                field: "job_title",
                                                title: "Job Position",
                                                width:"70%"
                                            },
                                            {
                                                field: "date",
                                                title: "Date",
                                                width:"20%",
                                                searchable:false
                                            },
                                        ]}
                                        data={talents}
                                        options={{
                                            pageSize: 50,
                                            sorting: true,
                                            showFirstLastPageButtons: false,
                                            pageSizeOptions: [],
                                            tableLayout:"auto",
                                            padding: "dense",
                                            paginationType: "stepped",
                                            maxBodyHeight:document.body.scrollHeight - 250,
                                            showTitle:false,
                                            emptyRowsWhenPaging:false
                                        }}
                                        onRowClick={( event, rowData ) =>{
                                            setSerId( rowData["serId"] );
                                        }}
                                    />
                                </MuiThemeProvider>
                                :
                                ""
                        }
                    </div>
                    <div className="col-md-7">
                        {
                            serId ?
                                <EvaluationComponent
                                    evaluation_id={serId}
							    reloadTalents={() => getTalents()}/>
                                :
                                null
                        }
                    </div>
                </div>
            </div>
            {util.LOADING_SCREEN( backDropOpen )}
        </Fragment>
    );
}


export default EvaluationsByTalent;


