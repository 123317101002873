import { faCommentAlt, faFileExport, faFilter, faTable } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormControl, InputLabel, MenuItem, MuiThemeProvider, Select, Tooltip } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { Fragment, useEffect, useState } from "react";
import TextInput from "../shared/TextInput";
import WorkloadSelector from "../shared/WorkloadSelector";
import TicketNumber from "../shared/TicketNumber";
import util from "../../utils/miniUtils";
import useWorkload from "../shared/useWorkload";
import Filter from "../shared/Filter";
import useManageWorkload from "../manage_workload/useManageWorkLoad";
import DeleteWorkloadModal from "../shared/DeleteWorkloadModal";
import CreateDuplicateWorkload from "../manage_workload/components/CreateDuplicateWorkload";
import BulkEditWorkload from "../shared/BulkEditWorkload";
import MyTicketDetail from "./MyTicketDetail";
import ChildTicketList from "../shared/ChildTicketList";
import TableMenu from "../shared/TableMenu";
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
const tableRef = React.createRef();
const LocalStorageKey = "myTicketColumns";
const reloadTable = () => { tableRef.current && tableRef.current.onQueryChange( undefined ); };

const MyTickets = () => {

    const { loading } = useWorkload();
    const { deleteWorkload } = useManageWorkload();
    const [workloads, setWorkloads] = useState( [] );
    const [showFilter, setShowFilter] = useState( false );
    const [filterState, setFilterState] = useState( {} );
    const [bulkShow, setBulkShow] = useState( false );
    const [tableStatus, setTableStatus] = useState( "all" );
    const [columns,setColumns]=useState( [
        {
            title: "Ticket Number",
            field: 'id',
            render: value => value && value.ticket_number? value.ticket_number : <TicketNumber categoryId={value.wl_category_id} id={value.id} />
        },
        {
            title: "",
            field:'activities',
            sorting: false,
            render:( row ) => <TableMenu
                row={row} 
                handleDelete={handleDelete}
                handleWorkloadDuplicateBtn={handleWorkloadDuplicateBtn}
                showWorkLoadDetail={showWorkLoadDetail} />,
            cellStyle: {
                backgroundColor: "#f0f0f0"
            },
        },
        {
            title: "Title",
            field: 'short_description',
            cellStyle: {
                backgroundColor: "#f0f0f0"
            },
            render: d => {
                return <TextInput
                    title="Title"
                    name={'short_description'}
                    value={d.short_description}
                    type="text"
                    id={d.id}
                    reload={reloadData}
                />;
            },
        },
        {
            title: "Created On",
            field: 'createdAt',
            render: ( value ) => {
                return <div>{util.humanDateTime( value.createdAt )}</div>;
            },
            cellStyle: {
                whiteSpace: 'nowrap'
            },
        },
        
        {
            title: "Customer Name",
            field: 'customer_name',
        },
        {
            title: "Customer Department",
            field: 'department_name',
        },
        {
            title: "Priority",
            field: 'Priority',
            render: d => {
                return <WorkloadSelector
                    title="Priority"
                    name={'priority'}
                    value={d.priority}
                    id={d.id}
                    data={d}
                    reload={reloadData}
                />;
            },
            cellStyle: {
                backgroundColor: "#f0f0f0"
            },
        },
        {
            title: "Status",
            field: 'statusName',
            render: d => {
                return <WorkloadSelector
                    title="Status"
                    name={'status'}
                    value={d.status}
                    id={d.id}
                    data={d}
                    reload={reloadData}
                />;
            },
            cellStyle: {
                backgroundColor: "#f0f0f0"
            },
        },
        {
            title: "Child Tickets",
            field: 'childTickets',
            render: d => {
                return <ChildTicketList data={d.childTickets} myTicket={true} />;
            }
        },
        {
            title: "Parent Ticket",
            field: 'parentName',
            render: value => {
                return value.parent_ticket_id ?
                    <Tooltip title={value.parentName}>
                        <div className="parentTicket_col">{value.parentName && value.parentName.substring( 0,50 )}</div>
                    </Tooltip> : "";
            }
        },
        {
            title: "ETA",
            field: 'ETA',
            render: value => <div>{value.ETA && util.humanDateTime( value.ETA )}</div>
        },
        {
            title: "Long Description",
            field: 'long_description',
            render: value => {
                return value ?
                    <Tooltip title={value.long_description}>
                        <span className="text-info"><FontAwesomeIcon icon={faCommentAlt} /></span>
                    </Tooltip> : "";
            }
        },
        {
            title: "Assignment Group",
            field: 'group_name',
        },
        {
            title: "Workload Category",
            field: 'categoryName',
        },
        {
            title: "Assigned To",
            field: "resolver_name",
        },
        {
            title: "Assigned to Group Time",
            field: "RO_assigned_to_group_time",
            render: ( value ) => {
                return <div>{util.humanDateTime( value.RO_assigned_to_group_time )}</div>;
            },
            cellStyle: {
                whiteSpace: 'nowrap',
            },

        },
        {
            title: "Ticket Category",
            field: 'ticket_category',
            render: d => {
                return <TextInput
                    title="Ticket Category"
                    name={'ticket_category'}
                    value={d.ticket_category}
                    id={d.id}
                    type="text"
                    reload={reloadData}
                />;
            },
            cellStyle: {
                backgroundColor: "#f0f0f0"
            },
        },
        {
            title: "Ticket Type",
            field: "ticket_type",
            render: d => {
                return <TextInput
                    title="Ticket Type"
                    name={'ticket_type'}
                    value={d.ticket_type}
                    id={d.id}
                    type="text"
                    reload={reloadData}
                />;
            },
            cellStyle: {
                backgroundColor: "#f0f0f0"
            },
        },
        {
            title: "Item",
            field: 'item',
            render: d => {
                return <TextInput
                    title="Item"
                    name={'item'}
                    value={d.item}
                    type="text"
                    id={d.id}
                    reload={reloadData}
                />;
            },
            cellStyle: {
                backgroundColor: "#f0f0f0"
            },
        },
        {
            title: "Reference Number",
            field: 'reference_number',
            render: d => {
                return <TextInput
                    title="Reference Number"
                    name={'reference_number'}
                    value={d.reference_number}
                    id={d.id}
                    type="text"
                    reload={reloadData}
                />;
            },
            cellStyle: {
                backgroundColor: "#f0f0f0"
            },
        },
        {
            title: "Actual Response Time",
            field: 'RO_actual_response_sla',
            render: value =>{
                if( value.RO_actual_response_sla ){
                    return util.time_SLA_render( value.RO_actual_response_sla );
                }
            }
        },
        // Overall Resoltion SLA
        {
            title: "Overall Business Days Resolution",
            field: 'RO_sla_minuteso',
            render: value =>{
                if( value.RO_sla_bizdays ){
                    return util.time_SLA_days_render( value.RO_sla_bizdays );
                }
            }
        },
        {
            title: "Overall Calender Days Resolution",
            field: 'RO_sla_minutesc',
            render: value =>{
                if( value.RO_sla_caldays ){
                    return util.time_SLA_days_render( value.RO_sla_caldays );
                }
            }
        },
        // Group Resolution SLA
        {
            title: "Group Business Days Resolution",
            field: 'RO_sla_minutese',
            render: value =>{
                if( value.RO_group_sla_bizdays ){
                    return util.time_SLA_days_render( value.RO_group_sla_bizdays );
                }
            }
        },
        {
            title: "Group Calender Days Resolution",
            field: 'RO_sla_minutesg',
            render: value =>{
                if( value.RO_group_sla_caldays ){
                    return util.time_SLA_days_render( value.RO_group_sla_caldays );
                }
            }
        },
        // Resolver Resolution SLA
        {
            title: "Resolver Business Days Resolution",
            field: 'RO_sla_minutesb',
            render: value =>{
                if( value.RO_resolver_sla_bizdays ){
                    return util.time_SLA_days_render( value.RO_resolver_sla_bizdays );
                }
            }
        },
        {
            title: "Resolver Calender Days Resolution",
            field: 'RO_sla_minutesd',
            render: value =>{
                if( value.RO_resolver_sla_caldays ){
                    return util.time_SLA_days_render( value.RO_resolver_sla_caldays );
                }
            }
        },
        {
            title: "Ticket Hop Count",
            field: 'RO_ticket_hop_count',
        },
        {
            title: "Ticket Log",
            field: 'wTicketLog',
            render: value => {
                return value ?
                    <Tooltip title={value.RO_wl_log}>
                        <span className="text-info"><FontAwesomeIcon icon={faCommentAlt} /></span>
                    </Tooltip> : "";
            }
        },
    ] );
    const [showColumns, setShowColumns] = useState( columns.map( ( c ) => c.field ) );
    const [order, setOrder] = useState( localStorage.getItem( LocalStorageKey ) );
    // const [dragging, setDragging] = useState( false );
  
    useEffect( () => {
        if ( order ) {
            setShowColumns( JSON.parse( order ) );
        }
    }, [order] );
  
    const handleSave = () => {
        localStorage.setItem( LocalStorageKey, JSON.stringify( showColumns ) );
    };
  
    const handleChange = ( event ) => {
        const field = event.target.value;
        if ( showColumns.includes( field ) ) {
            setShowColumns( showColumns.filter( ( c ) => c !== field ) );
        } else {
            setShowColumns( [...showColumns, field] );
        }
    };
    const tableColumns = columns.filter( ( c ) => showColumns.includes( c.field ) );

    function handleColumnDrag( sourceIndex, destinationIndex ) {
        setShowColumns( ( prevArray ) => {
            const newArray = [...prevArray];
            const temp = newArray[sourceIndex];
            newArray[sourceIndex] = newArray[destinationIndex];
            newArray[destinationIndex] = temp;
            return newArray;
        } );
    }
  

    const [workloadDetail, setWorkloadDetail] = useState( {
        show: false,
        workload_id: null,
        permission_level: null,
    } );

    const [deleteWorkloadModal, setDeleteWorkloadModal] = useState( {
        show: false,
        workload_id: null,
        workload_title: null
    } );

    const [duplicateWorkload, setDuplicateWorkload] = useState( {
        show: false,
        workloadData: null,
        workload_title: {}
    } );

    const showWorkLoadDetail = ( data ) => {
        setWorkloadDetail( ( prev ) => {
            return {
                ...prev,
                show: true,
                workload_id: data.id,
                permission_level: 1
            };
        } );
    };

    const closeWorkLoadDetail = () => {
        setWorkloadDetail( ( prev ) => {
            return {
                ...prev,
                show: false
            };
        } );
    };

    const handleWorkloadDeleteBtn = ( id ) => {
        deleteWorkload( id );
    };

    const closeDeleteWorkload = () => {
        setDeleteWorkloadModal( {
            show: false,
            workload_id: null,
            workload_title: null
        } );
        tableRef.current && tableRef.current.onQueryChange( undefined );
    };

    const closeDuplicateWorkload = () => {
        setDuplicateWorkload( {
            show: false,
            workloadData: {}
        } );
        tableRef.current && tableRef.current.onQueryChange( undefined );
    };

    const handleFilterClose = () => {
        setFilterState( { ...filterState, showFilter: false } );
        tableRef.current && tableRef.current.onQueryChange( undefined );
    };

    const showBulkDrawer = () => {
        setBulkShow( true );
    };

    const closeBulkDrawer = () => {
        setBulkShow( false );
        reloadTable();
    };

    const handleWorkloadDuplicateBtn = ( data ) => {
        setDuplicateWorkload( ( prev ) => {
            return {
                ...prev,
                show: true,
                workloadData: {
                    department_id: data.department_id,
                    wl_category_id: data.wl_category_id,
                    parent_ticket_id: data.parent_ticket_id,
                    customer_id: data.customer_id,
                    resolver_id: data.resolver_id,
                    group_id: data.group_id,
                    updated_by: data.updated_by,
                    status: data.status,
                    status_from_categories: data.status_from_categories,
                    ticket_number: data.ticket_number,
                    short_description: data.short_description,
                    long_description: data.long_description,
                    ticket_category: data.ticket_category,
                    ticket_type: data.ticket_type,
                    item: data.item,
                    reference_number: data.reference_number,
                    priority: data.priority,
                    region: data.region,
                    country: data.country,
                }
            };
        } );
    };

    const urlHandler = ( query, opc = false ) => {
        let orderby = ( query.orderBy ) ? query.orderBy.field : "id";
        let direction = ( query.orderDirection === "" ) ? "asc" : query.orderDirection;
        let search = query.search !== "" ? query.search : "";
        let options = ( opc ) ? `&opt=${opc}` : "";
        // send only checked true values
        let q = `advanced_filter=${showFilter}&`;
        const fixCheckedToUrlEncode = ( data = [], field, especial ) => {
            let pairs = [];
            data.map( ( value ) => {
                if ( value.checked === true ) {
                    // console.log( value );
                    let val = value.value;
                    if ( especial ) {
                        val = val === "Yes" ? 1 : 0;
                    }
                    pairs.push( `${field}=` + encodeURIComponent( val ) );
                }
            } );
            q += pairs.join( "&" ) + "&";
        };

        fixCheckedToUrlEncode( filterState.countries, 'country' );
        fixCheckedToUrlEncode( filterState.assignedDepartments, 'department_name' );
        fixCheckedToUrlEncode( filterState.assignedGroups, 'group_name' );
        fixCheckedToUrlEncode( filterState.customerList, "customer_name" );
        fixCheckedToUrlEncode( filterState.resolverList, "resolver_name" );
        fixCheckedToUrlEncode( filterState.statusList, "status" );
        fixCheckedToUrlEncode( filterState.categoryList, "category" );
        fixCheckedToUrlEncode( filterState.priorityList, "priority" );
        fixCheckedToUrlEncode( filterState.region, "region" );

        return `workload/get_all_my_workload_customer?tableStatus=${tableStatus}&page=${query.page}&limit=${query.pageSize}&orderby=${orderby}&direction=${direction}&search=${search}&${q}${options}`;
    };

    const handleDelete = ( data ) => {
        setDeleteWorkloadModal( ( prev ) => {
            return {
                ...prev,
                show: true,
                workload_id: data.id,
                workload_title: data.short_description
            };
        } );
    };

    const reloadData = () => tableRef.current && tableRef.current.onQueryChange( undefined );

    useEffect( () => {
        // Runs only one time when the component is rendered
        tableRef.current && tableRef.current.onQueryChange( undefined );
    }, [filterState, tableStatus] );

    return (
        <Fragment>
            <div className="row">
                {showFilter && <Filter
                    handleClose={handleFilterClose}
                    open={filterState.showFilter}
                    reloadData={( d ) => setFilterState( d )}
                />}
                <div className={`col-md-${showFilter ? 10 : 12}`}>
                    <MuiThemeProvider theme={util.defaultTableTheme}>
                        <MaterialTable
                            tableRef={tableRef}
                            columns={tableColumns}
                            onColumnDragged={handleColumnDrag}
                            data={query =>
                                util.axios.get( urlHandler( query, true ) )
                                    .then( response => {
                                        const { error, msg, data, page, total } = response.data;
                                        if ( error ) throw msg;
                                        return ( {
                                            data: data,
                                            page: page,
                                            totalCount: total
                                        } );
                                    } )
                                    .catch( error => {
                                        util.handleError( error );
                                    } )
                            }
                            isLoading={loading}
                            options={
                                {
                                    searchFieldAlignment: "left",
                                    selection: true,
                                    showTitle: false,
                                    pageSize: 20,
                                    emptyRowsWhenPaging: false,
                                    pageSizeOptions: [20, 50, 100, 150, 500],
                                    // columnsButton: true,
                                    sorting: true,
                                    padding: "default",
                                    tableLayout: "auto",
                                    toolbarButtonAlignment: "left",
                                    headerStyle: { backgroundColor: "#007bff", color: "white" },
                                }
                            }
                            onSelectionChange={( rows ) => setWorkloads( [...rows] )}
                            actions={[
                                { icon:()=><div>
                                    <div className="dropdown">
                                        <div type="text" id="dropdownMenuButton" data-toggle="dropdown" className={"btn"} aria-haspopup="false" aria-expanded="true" >
                                            <span className="ml-2 isCursor text-secondary">
                                                <ViewColumnIcon title='Show/Hide Columns' className="iconGrey" />
                                            </span>
                                        </div>
                                        <div className="dropdown-menu drp " aria-labelledby="dropdownMenuButton" style={{ width:'2000%' }}>
                                                        
                                            <div className="drp-contain">
                                                        
                                                {columns.map( ( c, index ) => {
                                                    const mycolumn = index % 3;
                                                    const myrow = Math.floor( index / 3 );
                                                    return(
                                                        <button className="dropdown-item"  key={c.field} style={{ gridColumn: `${mycolumn + 1}`, gridRow: `${myrow + 1}` }}>
                                                            <input
                                                                type="checkbox"
                                                                value={c.field}
                                                                checked={showColumns.includes( c.field )}
                                                                onChange={handleChange}
                                                            />
                                                    
                                                            <label className='ml-1'>{c.title}</label> 
                                                        </button>
                                                    );} )}
                                            </div>
                                            <button  className="btn btn-success btn-sm mr-3 float-right" 
                                                onClick={()=>{handleSave(); }}
                                            >
                                           Save
                                            </button>
                                        </div>
                                    </div>
                                </div>,
                                isFreeAction: true,
                                onClick: () => null
                                },
                                {
                                    icon: () => <div className={"btn btn-success btn-sm"}>
                                        <FontAwesomeIcon icon={faFileExport} className="mr-2" />
                                        Export All
                                    </div>,
                                    tooltip: "Export All",
                                    isFreeAction: true,
                                    onClick: () => util.DownloadTableAsCsv( 'MuiTable-root', 1, "Workload" ),
                                },
                                {
                                    icon: () =>
                                        <div className="btn btn-info btn-sm">
                                            <FontAwesomeIcon icon={faFilter} className="mr-2" />
                                            Filter
                                        </div>
                                    ,
                                    position: "toolbar",
                                    isFreeAction: true,
                                    onClick: () => {
                                        setFilterState( { ...filterState } );
                                        setShowFilter( !showFilter );
                                    }
                                },
                                {
                                    icon: () => <FormControl variant="outlined" size="small" fullWidth>
                                        <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={tableStatus}
                                            label="Age"
                                            onChange={( e ) => setTableStatus( e.target.value )}
                                        >
                                            <MenuItem value="all">All</MenuItem>
                                            <MenuItem value="open">In Progress</MenuItem>
                                            <MenuItem value="closed">Closed</MenuItem>
                                        </Select>
                                    </FormControl>,
                                    isFreeAction: true,
                                }
                            ]}
                        />
                    </MuiThemeProvider>
                </div>
            </div>
            {
                workloadDetail.show &&
                <MyTicketDetail
                    workload_id={workloadDetail.workload_id}
                    handleClose={closeWorkLoadDetail}
                    show={workloadDetail.show}
                    permissionLevel={workloadDetail.permission_level}
                    reload={reloadData}
                />
            }
            {deleteWorkloadModal.show &&
                <DeleteWorkloadModal
                    show={deleteWorkloadModal.show}
                    id={deleteWorkloadModal.workload_id}
                    title={deleteWorkloadModal.workload_title}
                    handleClose={closeDeleteWorkload}
                    deleteWorkload={handleWorkloadDeleteBtn}
                />}
            {<CreateDuplicateWorkload
                show={duplicateWorkload.show}
                handleClose={closeDuplicateWorkload}
                data={duplicateWorkload.workloadData}
                reload={reloadData}
            />}
            <BulkEditWorkload
                workloads={workloads}
                bulkShow={bulkShow}
                showBulkDrawer={showBulkDrawer}
                closeBulkDrawer={closeBulkDrawer}
                tableName="managerTable"
            />
        </Fragment>
    );
};

export default MyTickets;
