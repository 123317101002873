import { MuiThemeProvider } from "@material-ui/core";
import MaterialTable from "material-table";
import React from "react";
import util from "../../../utils/miniUtils";
import AllStatus from "../../shared/Status/AllStatus";
import { CompletedStatus } from "../../shared/Status/CompletedStatus";

export const AppraisalsTable = ( {
    handleDetail,
    listOfAppraisals,
} ) => {
    // console.log( listOfAppraisals );

    return (
        <>
            <MuiThemeProvider theme={util.defaultTableTheme}>
                <MaterialTable
                    columns={[
                        {
                            title: "Period",
                            field: "ap_period",
                            render: ( rowData ) => {
                                return <div> {rowData.ap_period.period}</div>;
                            },
                        },
                        {
                            title: "StartToEnd",
                            field: "startDate",
                            render: ( rowData ) => {
                                return (
                                    <div>
                                        {" "}
                                        {util.humanDateMonth( rowData.ap_period.startDate )} -{" "}
                                        {util.humanDateMonth( rowData.ap_period.endDate )}{" "}
                                    </div>
                                );
                            },
                        },
                        {
                            title: "Status",
                            field: "status",
                            // align:"center",
                           
                            render: ( rowData ) => <CompletedStatus l2_exist={rowData.l2_exist} l3_exist={rowData.l3_exist}/>,
                        },

                        {
                            title: "Score",
                            field: "score",
                            // align:"center",
                            render: ( rowData ) => {
                                return <div> {rowData.appraisal_score}</div>;
                            },
                        },
                      
                        {
                            title: "Achievement",
                            field: "achievement",
                           
                            // align:"center",
                           
                            render: ( rowData ) => {
                                return (
                                    <div>
                                        
                                        {util.suspenseDotPopOverExceptionalAchievments( rowData,"" )}
                                    </div>
                                );
                            },
                        },

                        {
                            // title: "",
                            render: ( rowData ) => {
                                return (
                                    <div>
                                        <button
                                            className="btn btn-sm btn-success px-4" style={{ fontSize:'11px' }}
                                            onClick={() => handleDetail( rowData )}
                                        >
                      Past Appraisal Details
                                        </button>
                                    </div>
                                );
                            },
                        },
                    ]}
                    data={listOfAppraisals}
                    options={{
                        searchFieldAlignment: "left",
                        selection: true,
                        showTitle: false,
                        pageSize: 50,
                        emptyRowsWhenPaging: false,
                        columnsButton: true,
                        sorting: true,
                        padding: "default",
                        tableLayout: "auto",
                        toolbarButtonAlignment: "left",
                        headerStyle: { backgroundColor: "#007bff", color: "white" },
                    }}
                    //     actions={[
                    //         {
                    //             icon: () => (
                    //                 <div className="btn btn-info btn-sm">
                    //                     <FontAwesomeIcon icon={faFilter} className="mr-2" />
                    //   Filter
                    //                 </div>
                    //             ),

                    //             position: "toolbar",
                    //             isFreeAction: true,
                    //             onClick: () => {
                    //                 if ( filterState.showFilter ) {
                    //                     setFilterState( {
                    //                         ...filterState,
                    //                         startDate: "",
                    //                         endDate: "",

                //                         showFilter: false,
                //                     } );
                //                 } else {
                //                     setFilterState( { ...filterState, showFilter: true } );
                //                 }
                //             },
                //         },
                //     ]}
                />
            </MuiThemeProvider>
            <AllStatus />
        </>
    );
};

export default AppraisalsTable;
