import React, { Fragment, useEffect, useState } from 'react';
import util from '../../utils/miniUtils';
import CheckBoxSelector from '../shared/CheckBoxSelector';
const countryListFixed = util.fixCountryList();
const PRIORITY_LIST = [{ id: "priority1", name: "Low", value:"Low" }, { id: "priority2", name: "Medium", value:"Medium" }, { id: "priority3", name: "High", value:"High" }, { id: "priority4", name: "Critical", value:"Critical" }];

const Filter = ( { reloadData } ) => {
    const [filter, setFilter] = useState( {
        countries: countryListFixed,
        assignedDepartments:[],
        assignedGroups:[],
        categoryList: [],
        statusList: [],
        priorityList: [...PRIORITY_LIST],
        customerList: [],
        resolverList: [],
        parentWorkload: [],
        country:[],
        region: []
    } );

    // Sort data array to be accepted by the checkBoxSelector component
    const sortDataForCheckbox =( arr, idName, valueName, labelName ) => {
        return arr.map( item =>{
            return {
                id:item[idName],
                value: item[valueName],
                name: item[labelName]
            };
        } );
    };

    const getInitialRequest = async ( ) => {
        try {
            const request = await util.axios.get( `/workload/get_create_initial_request` );
            const { error, msg, workLoadList, customerList, grpList, depList } = request.data;
            if ( error ) throw msg;
            setFilter( ( prev ) => {
                return {
                    ...prev,
                    assignedDepartments: sortDataForCheckbox( depList, "id", "itemName", "itemName" ),
                    assignedGroups: sortDataForCheckbox( grpList, "id","itemName", "itemName" ),
                    customerList: sortDataForCheckbox( customerList, "employee_id", "name", "name" ),
                    resolverList: sortDataForCheckbox( customerList, "employee_id", "name", "name" ),
                    parentWorkload: sortDataForCheckbox( workLoadList, "id", "short_description", "short_description" )
                };
            } );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const getCategoryList = async () => {
        try {
            const request = await util.axios.get( 'wl_categories/get_all_categories' );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setFilter( ( prev ) => {
                return {
                    ...prev,
                    categoryList: sortDataForCheckbox( data, "id", "id", "name" ),
                };
            } );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    // Get status list
    const getStatusListToCategory = async () => {
        try {
            const request = await util.axios.get( 'get_all_status' );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setFilter( ( prev ) => {
                return {
                    ...prev,
                    statusList: sortDataForCheckbox( data, "id", "id", "name" ),
                };
            } );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    useEffect( () => {
        reloadData( filter );
    }, [filter] );

    // Requests on the component mounting
    useEffect( () => {
        getInitialRequest();
        getCategoryList();
        getStatusListToCategory();
    }, [] );

    return ( 
        <Fragment >
            <div className="col-md-2 wl-filter">
                <CheckBoxSelector
                    // isOpenByDeafult={true}
                    dataArray={filter.assignedDepartments}
                    title="Departments"
                    updateData={( data ) =>
                        setFilter( { ...filter, assignedDepartments: data, ready: true } )
                    }
                />
                
                <CheckBoxSelector
                    dataArray={filter.assignedGroups}
                    title="Groups"
                    updateData={( data ) =>
                        setFilter( { ...filter, assignedGroups: data, ready: true } )
                    }
                />

                <CheckBoxSelector
                    dataArray={filter.customerList}
                    title="Customer"
                    updateData={( data ) =>
                        setFilter( { ...filter, customerList: data, ready: true } )
                    }
                />

                <CheckBoxSelector
                    dataArray={filter.resolverList}
                    title="Resolver"
                    updateData={( data ) =>
                        setFilter( { ...filter, resolverList: data, ready: true } )
                    }
                />

                <CheckBoxSelector
                    dataArray={filter.priorityList}
                    title="Priority"
                    updateData={( data ) =>
                        setFilter( { ...filter, priorityList: data, ready: true } )
                    }
                />

                <CheckBoxSelector
                    dataArray={filter.categoryList}
                    title="Category"
                    updateData={( data ) =>
                        setFilter( { ...filter, categoryList: data, ready: true } )
                    }
                />

                <CheckBoxSelector
                    isOpenByDeafult={true}
                    dataArray={filter.statusList}
                    title="Status"
                    updateData={( data ) =>
                        setFilter( { ...filter, statusList: data, ready: true } )
                    }
                />

                {/* <CheckBoxSelector
                    dataArray={filter.parentWorkload}
                    title="Parent Workload"
                    updateData={( data ) =>
                        setFilter( { ...filter, parentWorkload: data, ready: true } )
                    }
                /> */}

                <CheckBoxSelector
                    dataArray={filter.countries}
                    title="Country"
                    updateData={( data ) =>
                        setFilter( { ...filter, countries: data, ready: true } )
                    }
                />
            </div>
        </Fragment>
    );
};
 
export default Filter;