import { faEllipsisV,  faPlus, faSave, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid,IconButton,InputAdornment,ListItem,Menu,MenuItem,TextField,Tooltip } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import util from "../../../utils/miniUtils";
import EvaluatorsSelectorAccordion from "../SetTargets/EvaluatorsSelectorAccordion";
import FileUploader from "../Upload/FileUploader";


const SetTargetsForm = ( { data, evaluators, reloadItems } ) => {
    const { appraisal_item_id, type } = data;
    const [formTitle, setFormTitle] = useState();
    const [form, setForm] = useState( data );
    const [isNew, setIsNew] = useState( false );
    const [appraisalItemId, setAppraisalItemId] = useState( appraisal_item_id );

    //Mui dropdown
    const [anchorEl, setAnchorEl] = React.useState( null );
    const open = Boolean( anchorEl );
    const handleClick = ( event ) => {
        setAnchorEl( event.currentTarget );
    };
    const handleClose = () => {
        setAnchorEl( null );
    };
    const ITEM_HEIGHT = 48;


    const handleChange = ( e ) => {
        setIsNew( true );
        let { name, value } = e.target;

        if( value === "" || value === '--' ) {
            value = null;
        }
        setForm( {
            ...form,
            [name]: value,
        } );
    };

    useEffect( () => {
        setForm( data );
        setAppraisalItemId( appraisal_item_id );
        setFormTitle( type === 1 ? "Goal Title" : "Competency Title" );
    }, [] );


    //validation
    const isValidSetTargetForm = () => {

        const msg = ( string ) => {
            return { pass: false, msg: `${string}` };
        };
       

        const { gc_title, gc_desc, weight,gc_target, l1_evaluator, l2_evaluator, l3_evaluator, hr_evaluator } = form;
        if ( util.noValues.includes( gc_title ) )  return msg( "Goal or Competency Title  is missing" );
        if ( util.noValues.includes( gc_desc ) )   return msg( "Description is missing" );
        if ( util.noValues.includes( gc_target ) ) return msg( "Target is missing" );

        // if ( parseInt( gc_target ) < 1 || parseInt( gc_target ) > 100 ) return msg( " Target value must be between 1 and 100" );
        if ( parseInt( weight ) < 1 || parseInt( weight ) > 100 ) return msg( " Weight value must be between 1 and 100" );

        if ( util.noValues.includes( l1_evaluator ) || util.noValues.includes( hr_evaluator ) )
            return msg( "L1 Evaluator and HR Evaluator are mandatory" );
        
        if ( !util.noValues.includes( l3_evaluator ) && util.noValues.includes( l2_evaluator ) )
            return msg( "Please select L2 Evaluator to enable L3 Evaluator selection" );

       

        // Validate no repeated  evaluators
        const idsEvaluators = [];
        if ( !isNaN( parseInt( l1_evaluator ) ) ) idsEvaluators.push( parseInt( l1_evaluator ) );
        if ( !isNaN( parseInt( l2_evaluator ) ) ) idsEvaluators.push( parseInt( l2_evaluator ) );
        if ( !isNaN( parseInt( l3_evaluator ) ) ) idsEvaluators.push( parseInt( l3_evaluator ) );
        if ( !isNaN( parseInt( hr_evaluator ) ) ) idsEvaluators.push( parseInt( hr_evaluator ) );
        if ( ( new Set( idsEvaluators ) ).size !== idsEvaluators.length )
            return msg( "Please select different Evaluators for L1,L2, and L3" );
        return { pass: true };
    };


    //editing set target form

    const saveChangesOfAppraisalItem = async () => {
        const validation = isValidSetTargetForm();
        if ( !validation.pass ) {
            return util.toast().error( validation.msg );
        }
        try {
            const request = await util.axios.put( `${util.url}/edit_appraisal_item/${appraisalItemId}`, form );
            const { error, msg } = request.data;
            setIsNew( false );
            // setForm( "" );
            // reloadItems();
            if ( error ) throw msg;
        } catch ( error ) {
            util.handleError( error );
        }
    };


    //deleting appraisal item

    const removeAppraisalItem = async () => {
        try {
            const request = await util.axios.put( `${util.url}/remove_appraisal_item/${appraisalItemId}` );
            const { error, msg } = request.data;
            reloadItems();
            //setIsNew( false );
            if ( error ) throw msg;
        } catch ( error ) {
            util.handleError( error );
        }
    };


    //reloading attachments after selectingbfrom file uploader to show in aform
    const reloadAttachmentsOnItem = ( attachments ) => {
        // console.log( "Make another request to get attachments" );
        // uploadAttachments();
        // console.log( form );
        let temp = { ... form };
        temp.attachments = attachments;
        setForm( Object.assign( {}, temp ) );
    };


    //delete attachments
    const deleteAttachment = async ( attachment_id ) => {
        try {
            const request = await util.axios.delete( `/appraisal/delete_attachment/${attachment_id}` );
            const { error, msg, attachments } = request.data;
            if ( error ) throw msg;
            reloadAttachmentsOnItem( attachments );
        } catch ( error ) {
            util.handleError( error );
        }
    }; 

    // goal or competency to add gc catalog
    const AddToGoal = async ( ) => {
        try {
            let temp = {
                type: type,
                title: form.gc_title,
                description: form.gc_desc,
                default_target: form.gc_target,
                evidence: form.evidence,
                applicable_group: []
            };
            const request = await util.axios.post( `/goals`, temp );
            const { error, msg } = request.data;
            if ( error ) throw msg;
        } catch ( error ) {
            util.handleError( error );
        }
    }; 

    return (
        <div className="card mb-4 p-4">
            <div>                
                <Grid container spacing={1}>
                    <Grid item xs={12} md={8} lg={8}>
                        <ListItem>
                            <TextField fullWidth label={formTitle} variant="outlined" name={"gc_title"} value={form.gc_title} onChange={handleChange} />
                        </ListItem>
                    </Grid>
                    <Grid item xs={6} md={2} lg={2}>
                        <ListItem> <TextField label='Weight' name={"weight"} value={form.weight} variant="outlined" onChange={handleChange} InputProps={{
                            endAdornment: <InputAdornment position='end'>%</InputAdornment>
                        }} /> 
                        </ListItem>
                    </Grid>
                    <Grid item xs={6} md={2} lg={2}>
                        <ListItem>   <TextField label='Target' variant="outlined" name={"gc_target"} value={form.gc_target} onChange={handleChange} />

                        </ListItem>
                    </Grid>
 
                </Grid>
                
            </div>
            <div className="mt-2 ml-3 ">
                <Grid container spacing={1}>
                    <Grid item xs={10} md={10} lg={11}>
                        <TextField
                            label='Description' variant="outlined"
                            fullWidth
                            placeholder="Description"
                            name="gc_desc" onChange={handleChange} value={form.gc_desc}
                            multiline
                            rows={4}
                            maxRows={6}
                        />
                    </Grid>
                    <Grid item xs={2} md={2} lg={1}>
                        <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? 'long-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                        >
                            <FontAwesomeIcon icon={faEllipsisV} />
                        </IconButton>
                        <Menu
                            id="long-menu"
                            MenuListProps={{
                                'aria-labelledby': 'long-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                                style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: '20ch',
                                },
                            }}
                        >
                            <MenuItem>
                                <FileUploader appraisal_item_id={appraisal_item_id} reloadAttachmentsOnItem={reloadAttachmentsOnItem} /> 
                            </MenuItem>
                            <MenuItem onClick={()=>AddToGoal()}> <FontAwesomeIcon icon={faPlus} className="iconGray" /> {type === 1 ? "Add To Goal" : "Add To Competency"}</MenuItem>
    
                        </Menu>
                    </Grid>
                </Grid>
               
                   
                    
            </div>
            <div className="mt-1">
            
                {form.attachments && form.attachments.length > 0?   <Grid container spacing={1}>
                    <Grid item xs={12} md={12} lg={2}>
                        <small className="text-secondary ml-4">Attachments:</small></Grid>
                    <Grid item xs={12} md={12} lg={10}>
                        {
                        form.attachments?.map( ( a,i )=> {
                            const { appraisal_item_id, name } = a;
                            return (
                                <Badge key={i}  className="isPointer ml-3 badgeCustom">
                                    <a href={`${util.url}/appraisal_attachments/${appraisal_item_id}/${name}`} target="_blank" rel="noopener noreferrer">{a.name}</a>
                                    <span className="p-1 isPointer" onClick={()=>deleteAttachment( a.attachment_id )}><FontAwesomeIcon icon={faTimes} className="text-danger"/></span>
                                </Badge>
                                
                            );
                        } )
                        }  
                    </Grid>

                </Grid> : ""}
            </div>

            <div className="evaluator-toggle mt-3">
                <EvaluatorsSelectorAccordion handleChange={handleChange} state={form} evaluators={evaluators} />
            </div>

            <div className="mt-2 d-flex justify-content-end align-items-center ">
                {isNew ? (
                    <Fragment>
                        <span className="text-danger isPointer mr-2" onClick={reloadItems} >Cancel</span>
                        <button className="btn btn-success btn-sm " onClick={() => saveChangesOfAppraisalItem()}>
                            <FontAwesomeIcon icon={faSave} className="mr-2" />
						Save
                        </button>
                    </Fragment>
                ) : (
                    ""
                )}
            </div>
            <div className="d-flex justify-content-end">
                <Tooltip title="Remove this Item from the appraisal">
                    <span>
                        <button className="btn-sm btn" onClick={() => removeAppraisalItem()}>
                            <FontAwesomeIcon icon={faTrash} color="#8b8989" />
                        </button>
                    </span>
                </Tooltip>
            </div>
        </div>
    );
};

export default SetTargetsForm;
