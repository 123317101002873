import React from 'react';
import Switch from "@material-ui/core/Switch";
import { Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

export default function PermissionRow( { role, permission, handleChangesPermission, disabled } ) {
    let fixedRole = role.toLowerCase().replace( " ", "_" );
    return(
        <tr>
            <td>
                <div  className="text-center">
                    <Switch
                        disabled={disabled}
                        checked={permission === 1}
                        onChange={( e ) => handleChangesPermission( e, fixedRole )}
                        color="primary"
                        name="rolePermission"
                        inputProps={{ "aria-label": "primary checkbox" }}
                    />
                </div>
            </td>
            <td>
                {role}
                {role === "Talent" || role === "Employee" || role === "Employee Manager" ?
                    <Tooltip title="This role requires a talent profile, if this user doesn't have one, we will create it">
                        <span><FontAwesomeIcon className="ml-2" icon={faQuestionCircle}/> </span>
                    </Tooltip>:""
                }
            </td>
        </tr>
    );
    
}