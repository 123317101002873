import React, { useEffect, useState } from 'react';
import { Popover } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, } from '@fortawesome/free-solid-svg-icons';
import useManageWorkload from '../useManageWorkLoad';
import util from '../../../utils/miniUtils';

const GroupSelector = ( { value, title, id, data, reload } ) => {
    const [edit, setEdit] = useState( false );
    const {
        groupList,
        categoriesByGroup,
        membersOfGroup,
        statusListByCategory,
        getGroupList,
        getCategories,
        getAssignedMembersToGroup,
        getStatusListToCategory } = useManageWorkload();
        // Anchor and open variable for the popover. 
    const [anchorEl, setAnchorEl] = React.useState( null );
    const open = Boolean( anchorEl );
    const [state, setState] = useState( {
        group_id: value,
        wl_category_id: data.wl_category_id,
        resolver_id: data.resolver_id,
        status: data.status,
        status_from_categories: data.status_from_categories
    } );

    useEffect( () => {
        if( edit ) {
            getGroupList();
            getCategories( state.group_id );
            getAssignedMembersToGroup( state.group_id );
        }
    },[edit] );

    useEffect( () => {
        if( edit ){
            if( state.group_id != "" ) {
                getCategories( state.group_id );
                getAssignedMembersToGroup( state.group_id );
            }
        }
    },[state.group_id] );

    useEffect( () => {
        if( edit ) {
            state.wl_category_id != "" && getStatusListToCategory( state.wl_category_id );
        }
    },[state.wl_category_id] );

    // Triggering the popover on the double click
    const handleDoubleClick = ( event ) => {
        setAnchorEl( event.currentTarget );
        setState( {
            group_id: value,
            wl_category_id: data.wl_category_id,
            resolver_id: data.resolver_id,
            status: data.status,
            status_from_categories: data.status_from_categories
        } );
        setEdit( true );
    };

    function handleChangeValue ( e ) {
        const { name, value } = e.target;
        switch( name ){
            case "group_id":
                setState( ( prev )=>{
                    return {
                        ...prev,
                        group_id: value,
                        wl_category_id: "",
                        resolver_id: "",
                        status: "",
                        status_from_categories: ""
                    };
                } );
                break;
            case "wl_category_id":
                if( state.group_id === data.group_id ){
                    setState( ( prev ) => {
                        return {
                            ...prev,
                            wl_category_id: value,
                            status: "",
                            status_from_categories: ""
                        };
                    } );
                } else {
                    setState( ( prev )=>{
                        return {
                            ...prev,
                            wl_category_id: value,
                            resolver_id: "",
                            status: "",
                            status_from_categories: ""
                        };
                    } );
                }
                break;
            case "resolver_id":
                setState( ( prev ) => {
                    return {
                        ...prev,
                        [name]: value
                    };
                } );
                break;
            case "status":
                setState( ( prev )=>{
                    return {
                        ...prev,
                        status: Number( value.split( " " )[0] ),
                        status_from_categories: Number( value.split( " " )[1] )
                    };
                } );
                break;
            default:
                return;
        }
        // console.log( state );
    }

    const handleClose = async ( workload_id ) => {
        if( edit ) {
            if( !util.noValues.includes( state.status_from_categories )
            && !util.noValues.includes( state.status )
            && !util.noValues.includes( state.resolver_id )
            && !util.noValues.includes( state.wl_category_id )
            && !util.noValues.includes( state.group_id ) ) {
                try {
                    const request = await util.axios.put( `/workload/update_workload/${workload_id}`, state );
                    const { error, msg } = request.data;
                    if( error ) throw msg;
                    util.toast().success( msg );
                    reload();
                } catch ( error ) {
                    util.handleError( error );
                }
            } else {
                util.handleError( "Please select all the Fields" );
            }
            setEdit( false );
        }
        // resetting the anchor for the popover
        setAnchorEl( null );
    };

    const handleCancel = () => {
        setEdit( false );
        setState( {
            group_id: value,
            wl_category_id: data.wl_category_id,
            resolver_id: data.resolver_id,
            status: data.status,
            status_from_categories: data.status_from_categories
        } );
        setAnchorEl( null );
    };

    const multipleOptions = () => {
        
        return <div className="d-flex justify-content-center align-items-center">
            <div className='form-group m-1'>
                <label htmlFor="group_id">{title}</label>
                <select className='w-100 form-control'
                    name="group_id" 
                    value={state.group_id}
                    onChange={handleChangeValue}>
                    <option></option>
                    {groupList.map( item => <option key={item.id} value={item.id}>{item.itemName}</option> )}
                </select>
            </div>
            <div className='form-group m-1'>
                <label htmlFor="wl_category_id">Workload Category</label>
                <select className='w-100 form-control'
                    name="wl_category_id"
                    value={state.wl_category_id}
                    onChange={handleChangeValue}>
                    <option></option>
                    {categoriesByGroup.map( item => <option key={item.id} value={item.id}>{item.name}</option> )}
                </select>
            </div>
            <div className='form-group m-1'>
                <label htmlFor="resolver_id">Assigned To</label>
                <select className='w-100 form-control'
                    name="resolver_id"
                    value={state.resolver_id}
                    onChange={handleChangeValue}>
                    <option></option>
                    {membersOfGroup.map( item => <option key={item.employee_id} value={item.employee_id}>{item.name}</option> )}
                </select>
            </div>
            {( state.wl_category_id !== data.wl_category_id || state.group_id !== data.group_id ) && <div className='form-group m-1'>
                <label htmlFor="status">Status</label>
                <select className='w-100 form-control'
                    name="status"
                    value={state.status + " " + state.status_from_categories}
                    onChange={handleChangeValue}>
                    <option></option>
                    {statusListByCategory.map( item => <option key={item.wl_status_category} value={item.wl_status_id + " " + item.wl_status_category}>
                        {item.name}
                    </option> )}
                </select>
            </div>}
        </div>;
    };

    function editValue() {
        return <Popover
            id={open ? 'text-input-popover' : undefined}
            open={open}
            anchorEl={anchorEl}
            onClose={handleCancel}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}>
            <div className='d-flex'>
                {multipleOptions()}
                <div className="d-flex align-items-end m-1">
                    <span className='btn btn-outline-danger' onClick={handleCancel}><FontAwesomeIcon icon={faTimes} /></span>
                    <span className='btn btn-outline-primary' onClick={() => handleClose( id ) }><FontAwesomeIcon icon={faCheck} /></span>
                </div>
            </div>
        </Popover>;
    }

    return <>
        {editValue()}
        <div className='d-flex justify-content-between editableWorkload' onDoubleClick={handleDoubleClick}>
            {data["group_name"] }
        </div>
    </>;
};

export default GroupSelector;