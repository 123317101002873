import { useState } from 'react';
import util from "../../utils/miniUtils";

function useMyAppraisals() {
    const [listOfAppraisals, setListOfAppraisals] = useState( [] );
    const [form, setForm] = useState( {} );
    const [id, setId] = useState();
    const [currentAppraisal,setCurrentAppraisal]=useState( {} );
    const [loading,setLoading]=useState( true );

    function handleRichTextChange( e ) {
        const value = e.target.value;
        setForm( { ...form,['desc']: value } );
    }   
    
    const getAllMyAppraisals = async ( ) => {
        try {
            setLoading( true );
            const request = await util.axios.get( `/my_appraisals` );
            const { error, data, msg,currentAppraisal } = request.data;
            if ( error ) throw msg;
            setListOfAppraisals( data );
            setCurrentAppraisal( currentAppraisal );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };
   
    function handleChanges( e ) {
        const { name, value } = e.target;
        setForm( { ...form, [name]: value } );
    }

    return (
        {
            listOfAppraisals,  setListOfAppraisals,
            form, setForm,
            id, setId,
            getAllMyAppraisals,
            handleChanges,
            handleRichTextChange,
            currentAppraisal,setCurrentAppraisal,
            loading
        }
    );
}

export default useMyAppraisals;