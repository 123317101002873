import React, { useEffect, useState } from 'react';
import { faFilter,faAward } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MuiThemeProvider } from '@material-ui/core';
import MaterialTable from 'material-table';
import Green from '../../../assets/images/green.png';
import GreenGrey from '../../../assets/images/green-grey.png';
import RedGrey from '../../../assets/images/red-grey.png';
import YelloGrey from '../../../assets/images/yellow-gry.png';
import Grey from '../../../assets/images/grey.png';
import GreenYellow from '../../../assets/images/green-yellow.png';
import User from "../../../assets/images/user.png";
import util from '../../utils/miniUtils';
import Filter from '../shared/Filter';
const tableRef = React.createRef();
const OtherTeamMembersTable = ( { setSelectedITem, buttonDependingOnStatus, role } ) => {
    const [showFilter, setShowFilter] = useState( false );
    const [filterData, setFilterData] = useState( {

    } );

    const [filterByStatus, setFilterByStatus] = useState( "all" );
    const filterAppraisee = ( status,data ) => {
        if ( status === 'all' ) {
            return data;
        } else if ( status === 'completed' ) {
            return data.filter( user => user.status == 18 );
        } else if( status === 'pendingwithl2l3' ){
            return data.filter( user =>  ( [4,15,6,16].includes( user.status )&&user.pending==true ) ); 
        // }else if( status === 'pendingwithl3' ){
        //     return data.filter( user =>  [6,16].includes( user.status ) ); 
        }else if( status === 'pendingwithHr' ){
            return data.filter( user =>  [8,17].includes( user.status ) ); 
        }else {
            return data.filter( user => user.status < 18 );
        }
    };
   
    useEffect( ()=>{
        tableRef.current && tableRef.current.onQueryChange( undefined );
    }, [filterData,filterByStatus] );

    const urlHandler = ( query )  => {
       
        // console.log( filterData );
        // send only checked true values
        let q = `advanced_filter=${showFilter}&`;
        let search = query.search;
        const fixCheckedToUrlEncode = ( data = [], field, especial ) => {
            let pairs = [];
            data.map( ( value ) => {
                if ( value.checked === true ) {
                    // console.log( value );
                    let val = value.value;
                    if ( especial ) {
                        val = val === "Yes" ? 1: 0;
                    }
                    pairs.push( `${ field }=` + encodeURIComponent( val ) );
                }
            } );
            q+= pairs.join( "&" ) + "&";
        };
      
        fixCheckedToUrlEncode( filterData.department, 'department' );
        fixCheckedToUrlEncode( filterData.status, 'status' );
        fixCheckedToUrlEncode( filterData.bonus, 'bonus' );
        fixCheckedToUrlEncode( filterData.period, 'period' );

       
       
        return `${util.url}/get_all_other_appraisal?page=${query.page}&rows=${query.pageSize}&search=${search}&either=${filterData}&${q}`;
    };
 
    // if ( loading ) {
    //     return  <Fragment>
    //         <div className="container-fluid p2 ">
    //             <div className="text-center">
    //                 <CircularProgress />
    //             </div>
    //         </div>
    //     </Fragment>;
    // }
   
    return (
        <div> 
            <div className="row mt-3">
                { showFilter && <Filter handleClose={()=> setShowFilter( false )} open={showFilter} reloadData={( d )=>setFilterData( d )} />}
                <div className={showFilter ? 'col-md-10' : 'col-md-12'}>
                    {  ( ( role.includes( "L1" ) ||  role.includes( "L2" ) ||  role.includes( "L3" ) ) && !role.includes( "HR" ) ) && <MuiThemeProvider theme={util.defaultTableTheme}>
                        <MaterialTable
                            tableRef={tableRef}
                            columns={[
                                {
                                    title: "EmployeeName",
                                    field: "employeeName",
                                  
                                    render: ( rowData ) => {
                                        return <div> {rowData.name}</div>;
                                    },
                                },

                                {
                                    title: "Department",
                                    field: "department",
                                    render: ( rowData ) => {
                                        return <div> {rowData.department}</div>;
                                    },
                                },
                                {
                                    title: "Period",
                                    field: "period",
                                    // align:"center",
                                    render: ( rowData ) => {
                                        return <div> {rowData.period}</div>;
                                    },
                                },
           
                                {
                                    title: "Status",
                                    field: "status",
                                    // align:'center',
                                    render: ( rowData ) => {
                           
                                        return (

                                            <div className={`${( rowData.l2_exist ||rowData.l3_exist ) ? "small_status" : "sm_status"} ${( rowData.l2_exist ||!rowData.l3_exist ) ? "small_status" : "sm_status"} d-flex justify-content-center align-items-center` }>

                                                <div
                                                    title="status"
                                                    className="d-flex"
                                                >
                                                    {rowData.status == 0 &&
                                    <div>
                                        <span>
                                            <img
                                                className="appraisee  "
                                                alt=""
                                                src={User} />
                                        </span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={Grey} />
                                    </div>}
                                                    {rowData.status == 1 &&
                                    <div>
                                        <span>
                                            <img
                                                className="appraisee  "
                                                alt=""
                                                src={User} />
                                        </span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={YelloGrey} />
                                    </div>}
                                                    {rowData.status == 3 &&
                                    <div>
                                        <span>
                                            <img
                                                className="appraisee  "
                                                alt=""
                                                src={User} />
                                        </span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={RedGrey} />
                                    </div>}
                                                    {( rowData.status == 2 || rowData.status == 4 || rowData.status == 6 || rowData.status == 8 || rowData.status == 10 || rowData.status == 12 || rowData.status == 5|| rowData.status == 7 || rowData.status == 9 || rowData.status == 11 ) &&
                                    <div>
                                        <span>
                                            <img
                                                className="appraisee  "
                                                alt=""
                                                src={User} />
                                        </span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={GreenGrey} />
                                    </div>}
                                                    {rowData.status == 13 &&
                                    <div>
                                        <span>
                                            <img
                                                className="appraisee  "
                                                alt=""
                                                src={User} />
                                        </span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={GreenYellow} />
                                    </div>}
                                                    {( rowData.status == 14 || rowData.status == 15 || rowData.status == 16 ||rowData.status == 17 || rowData.status == 18 ) &&
                                    <div>
                                        <span>
                                            <img
                                                className="appraisee  "
                                                alt=""
                                                src={User} />
                                        </span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={Green} />
                                    </div>}
                                    
                                                    {( rowData.status == 0 || rowData.status == 3 || rowData.status == 1  )&&
                                    <div>
                                        <span className="mb-1 ">L1</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={Grey} />
                                    </div>}
                                                    {rowData.status == 5 &&
                                    <div>
                                        <span className="mb-1 ">L1</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={RedGrey} />
                                    </div>}
                                                    {rowData.status == 2 &&
                                    <div>
                                        <span className="mb-1 ">L1</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={YelloGrey} />
                                    </div>}
                                                    {( rowData.status == 4 || rowData.status == 6 ||  rowData.status == 8 || rowData.status == 10  || rowData.status == 12 || rowData.status == 13 || 
                                    rowData.status == 7 || rowData.status == 9 || rowData.status == 11  ) &&
                                    <div>
                                        <span className="mb-1 ">L1</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={GreenGrey} />
                                    </div>}
                                                    {rowData.status == 14 &&
                                    <div>
                                        <span className="mb-1 ">L1</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={GreenYellow} />
                                    </div>}
                                                    {( rowData.status == 15 || rowData.status == 16 || rowData.status == 17 || rowData.status == 18 ) &&
                                    <div>
                                        <span className="mb-1 ">L1</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={Green} />
                                    </div>}

                                                    { ( rowData.l2_exist === true ) ? ( <div>
                                                        {( rowData.status == 0 ||  rowData.status == 3 || rowData.status ==5 || rowData.status == 1 || rowData.status == 2 )&&
                                    <div>
                                        <span className="mb-1 ">L2</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={Grey} />
                                    </div>}
                                                        {rowData.status == 7 &&
                                    <div>
                                        <span className="mb-1 ">L2</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={RedGrey} />
                                    </div>}
                                   

                                                        {rowData.status == 4 &&
                                    <div>
                                        <span className="mb-1 ">L2</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={YelloGrey} />
                                    </div>}
                                                        {( rowData.status == 6 ||  rowData.status == 8 || rowData.status == 10 || rowData.status == 12|| rowData.status == 13 || 
                                    rowData.status == 9 || rowData.status == 11 || rowData.status == 14 ) &&
                                    <div>
                                        <span className="mb-1 ">L2</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={GreenGrey} />
                                    </div>}
                                                        {rowData.status == 15 &&
                                    <div>
                                        <span className="mb-1 ">L2</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={GreenYellow} />
                                    </div>}
                                                        {( rowData.status == 16 || rowData.status == 17 || rowData.status == 18 ) &&
                                    <div>
                                        <span className="mb-1 ">L2</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={Green} />
                                    </div>}</div> ) : ( "" )}


                                                    { ( rowData.l3_exist === true ) ? ( <div>
                                                        {( rowData.status == 0 ||  rowData.status == 3 || rowData.status ==5 || rowData.status==7 || rowData.status == 1 || rowData.status == 2 || 
                                    rowData.status == 4  )&&
                                    <div>
                                        <span className="mb-1 ">L3</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={Grey} />
                                    </div>}
                                                        {rowData.status ==6 &&
                                    <div>
                                        <span className="mb-1 ">L3</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={YelloGrey} />
                                    </div>}
                                                        {rowData.status ==9 &&
                                    <div>
                                        <span className="mb-1 ">L3</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={RedGrey} />
                                    </div>}
                                                        {( rowData.status ==8 || rowData.status == 10 || rowData.status == 12 || rowData.status == 13 || rowData.status==11 || rowData.status == 14 || rowData.status == 15 ) &&
                                    <div>
                                        <span className="mb-1 ">L3</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={GreenGrey} />
                                    </div>}
                                                        {rowData.status == 16 &&
                                    <div>
                                        <span className="mb-1 ">L3</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={GreenYellow} />
                                    </div>}
                                                        {( rowData.status == 17 || rowData.status == 18 ) &&
                                    <div>
                                        <span className="mb-1 ">L3</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={Green} />
                                    </div>}</div> ) : ( "" )}
                                    
                                                    {( rowData.status == 0 ||  rowData.status == 3 || rowData.status ==5 || rowData.status==7 || rowData.status==9 || rowData.status == 1 || rowData.status == 2 || rowData.status == 4 || rowData.status ==6 )&&
                                    <div>
                                        <span className="mb-1 ">HR</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={Grey} />
                                    </div>}
                                                    {rowData.status == 8 &&
                                    <div>

                                        <span className="mb-1  ">HR</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={YelloGrey} />
                                    </div>}
                                                    {rowData.status == 11 &&
                                    <div>

                                        <span className="mb-1  ">HR</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={RedGrey} />
                                    </div>}
                                                    {( rowData.status == 10 || rowData.status == 12 || rowData.status == 13 || rowData.status == 14 || rowData.status == 15 || 
                                     rowData.status == 16 ) &&
                                    <div>

                                        <span className="mb-1  ">HR</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={GreenGrey} />
                                    </div>}
                                                    {rowData.status == 17 &&
                                    <div>

                                        <span className="mb-1  ">HR</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={GreenYellow} />
                                    </div>}
                                                    {rowData.status == 18 &&
                                    <div>

                                        <span className="mb-1  ">HR</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={Green} />
                                    </div>}
                                                </div>
                                            </div>
                                        );
                            
                                    },
                                },
                                // {
                                //     title: "Achievement",
                                //     field: "Achievement",
                                //     // align:"center",
                                //     hidden: ( ! role.includes( "HR" ) ),
                                //     render: ( rowData ) => {
                                //         return(
                                //             ( role.includes( "HR" ) && ( rowData.status===18 ) ) ?
                                //                 (
                                //                     <div>
                                                        
                                //                         {util.suspenseDotPopOverExceptionalAchievments( rowData,"" )}
                                //                     </div>
                                //                 ) : ( role.includes( "HR" )? <FontAwesomeIcon size="2x" className='iconGray' icon={faAward} />: "" )

                                //         );
                                //     }
                                // },
                   
                                // {
                                //     title: "Bonus",
                                //     editable: "onUpdate",
                                //     field: "Bonus",
                                //     hidden: ( ! role.includes( "HR" ) ),
                        
                                //     render: ( rowData ) => {
                                //         return(
                                //             (  role.includes( "HR" )  && rowData.status === 18 )?
                                //                 ( <div title="Approved Bonus">{rowData.bonus_approved} </div> )
                                  
                                //                 : ( role.includes( "HR" )? "Null" : "" )
                                //         );
                        
                                //     },
                                // },
                   

                                {
                                    // title: "",
                                    editable: "onUpdate",
                                    // align:"center",
                                    render: ( rowData ) => {
                                        return (
                                            <div>
                                               
                                                <button  className='btn btn-sm px-2 customBtn' style={{ backgroundColor:rowData.buttonControl.buttonColor,color:rowData.buttonControl.contentColor }}
                                                // className={`btn btn-sm px-2 other  customBtn  ${( role.includes( "L2" ) ) ? `statusesll${ status && status}` : ''} ${( role.includes( "L2" )  )||( role.includes( "L3" )  ) ? `statusesl${ status && status}` : ''} ${ role.includes( "HR" )  ? `statuseshr${ status && status}` : ''} `} 
                                                    onClick={() => setSelectedITem( rowData )}
                                                >
                                                    {/* {buttonDependingOnStatus( rowData )} */}
                                                    {rowData.buttonControl.return_message}
                                                </button>
                                               
                                    

                                            </div>
                                        );
                                    },
                                },
                            ]}
                            data={query =>
                                util.axios.get( urlHandler( query, true ) )
                                    .then( response => {
                                        const { data, error, msg, page, total } = response.data;
                                        if ( error ) throw msg;
                                        // console.log( employees );
                                        return ( {
                                            data: filterAppraisee( filterByStatus,data ),
                                            page: page,
                                            totalCount: total
                                        } );
                                    } )
                                    .catch( error=>{
                                        util.handleError( error );
                                    } )
                            }
                            localization={{
                                toolbar:{
                                    searchPlaceholder:"Search",
                                    nRowsSelected: ""
                                }
                            }}
                            options={{
                                searchFieldAlignment: "left",
                                selection: true,
                                showTitle: false,
                                pageSize: 50,
                                pageSizeOptions:[50,100,150],
                                emptyRowsWhenPaging: false,
                                columnsButton: true,
                                sorting: true,
                                padding: "default",
                                tableLayout: "auto",
                                toolbarButtonAlignment: "left",
                                headerStyle:{ position:'sticky',top:0 },
                                maxBodyHeight:'440px',
                            }}
                            actions={[
                                {
                                    icon: () =>
                                        <div className="btn btn-info btn-sm">
                                            <FontAwesomeIcon icon={faFilter} className="mr-2" />
                                Filter
                                        </div>
                                    ,
                                    position:"toolbar",
                                    isFreeAction: true,
                                    onClick: () => setShowFilter( !showFilter )
                                },
                               
                                { icon: () =>
                                    <>
                                        <label style={{ fontSize:'12px' }}>
          Filter by status:

                                            <select className="form-control" value={filterByStatus} onChange={( e ) => setFilterByStatus( e.target.value )}>
                                                <option value="all">All</option>
                                                <option value="inprogress">In Progress</option>
                                                <option value="completed">Completed</option>
                                                {(  role.includes( "HR" ) )?"": <option value="pendingwithl2l3">Pending With Me</option>}
                                                {/* {(  role.includes( "L3" ) ) && <option value="pendingwithl3">Pending With L3</option>} */}
                                                {(  role.includes( "HR" ) ) && <option value="pendingwithHr">Pending With Me</option>}
                                            </select></label>
                                    </>
                                ,
                                position:"toolbar",
                                isFreeAction: true,
                                },
                            ]}
                        />
                    </MuiThemeProvider>}
                    { role.includes( "HR" ) && <MuiThemeProvider theme={util.defaultTableTheme}>
                        <MaterialTable
                            tableRef={tableRef}
                            columns={[
                                {
                                    title: "EmployeeName",
                                    field: "employeeName",
                                  
                                    render: ( rowData ) => {
                                        return <div> {rowData.name}</div>;
                                    },
                                },

                                {
                                    title: "Department",
                                    field: "department",
                                    render: ( rowData ) => {
                                        return <div> {rowData.department}</div>;
                                    },
                                },
                                {
                                    title: "Period",
                                    field: "period",
                                    // align:"center",
                                    render: ( rowData ) => {
                                        return <div> {rowData.period}</div>;
                                    },
                                },
           
                                {
                                    title: "Status",
                                    field: "status",
                                    // align:'center',
                                    render: ( rowData ) => {
                           
                                        return (

                                            <div className={`${( rowData.l2_exist ||rowData.l3_exist ) ? "small_status" : "sm_status"} ${( rowData.l2_exist ||!rowData.l3_exist ) ? "small_status" : "sm_status"} d-flex justify-content-center align-items-center` }>

                                                <div
                                                    title="status"
                                                    className="d-flex"
                                                >
                                                    {rowData.status == 0 &&
                                    <div>
                                        <span>
                                            <img
                                                className="appraisee  "
                                                alt=""
                                                src={User} />
                                        </span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={Grey} />
                                    </div>}
                                                    {rowData.status == 1 &&
                                    <div>
                                        <span>
                                            <img
                                                className="appraisee  "
                                                alt=""
                                                src={User} />
                                        </span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={YelloGrey} />
                                    </div>}
                                                    {rowData.status == 3 &&
                                    <div>
                                        <span>
                                            <img
                                                className="appraisee  "
                                                alt=""
                                                src={User} />
                                        </span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={RedGrey} />
                                    </div>}
                                                    {( rowData.status == 2 || rowData.status == 4 || rowData.status == 6 || rowData.status == 8 || rowData.status == 10 || rowData.status == 12 || rowData.status == 5|| rowData.status == 7 || rowData.status == 9 || rowData.status == 11 ) &&
                                    <div>
                                        <span>
                                            <img
                                                className="appraisee  "
                                                alt=""
                                                src={User} />
                                        </span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={GreenGrey} />
                                    </div>}
                                                    {rowData.status == 13 &&
                                    <div>
                                        <span>
                                            <img
                                                className="appraisee  "
                                                alt=""
                                                src={User} />
                                        </span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={GreenYellow} />
                                    </div>}
                                                    {( rowData.status == 14 || rowData.status == 15 || rowData.status == 16 ||rowData.status == 17 || rowData.status == 18 ) &&
                                    <div>
                                        <span>
                                            <img
                                                className="appraisee  "
                                                alt=""
                                                src={User} />
                                        </span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={Green} />
                                    </div>}
                                    
                                                    {( rowData.status == 0 || rowData.status == 3 || rowData.status == 1  )&&
                                    <div>
                                        <span className="mb-1 ">L1</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={Grey} />
                                    </div>}
                                                    {rowData.status == 5 &&
                                    <div>
                                        <span className="mb-1 ">L1</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={RedGrey} />
                                    </div>}
                                                    {rowData.status == 2 &&
                                    <div>
                                        <span className="mb-1 ">L1</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={YelloGrey} />
                                    </div>}
                                                    {( rowData.status == 4 || rowData.status == 6 ||  rowData.status == 8 || rowData.status == 10  || rowData.status == 12 || rowData.status == 13 || 
                                    rowData.status == 7 || rowData.status == 9 || rowData.status == 11  ) &&
                                    <div>
                                        <span className="mb-1 ">L1</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={GreenGrey} />
                                    </div>}
                                                    {rowData.status == 14 &&
                                    <div>
                                        <span className="mb-1 ">L1</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={GreenYellow} />
                                    </div>}
                                                    {( rowData.status == 15 || rowData.status == 16 || rowData.status == 17 || rowData.status == 18 ) &&
                                    <div>
                                        <span className="mb-1 ">L1</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={Green} />
                                    </div>}

                                                    { ( rowData.l2_exist === true ) ? ( <div>
                                                        {( rowData.status == 0 ||  rowData.status == 3 || rowData.status ==5 || rowData.status == 1 || rowData.status == 2 )&&
                                    <div>
                                        <span className="mb-1 ">L2</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={Grey} />
                                    </div>}
                                                        {rowData.status == 7 &&
                                    <div>
                                        <span className="mb-1 ">L2</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={RedGrey} />
                                    </div>}
                                   

                                                        {rowData.status == 4 &&
                                    <div>
                                        <span className="mb-1 ">L2</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={YelloGrey} />
                                    </div>}
                                                        {( rowData.status == 6 ||  rowData.status == 8 || rowData.status == 10 || rowData.status == 12|| rowData.status == 13 || 
                                    rowData.status == 9 || rowData.status == 11 || rowData.status == 14 ) &&
                                    <div>
                                        <span className="mb-1 ">L2</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={GreenGrey} />
                                    </div>}
                                                        {rowData.status == 15 &&
                                    <div>
                                        <span className="mb-1 ">L2</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={GreenYellow} />
                                    </div>}
                                                        {( rowData.status == 16 || rowData.status == 17 || rowData.status == 18 ) &&
                                    <div>
                                        <span className="mb-1 ">L2</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={Green} />
                                    </div>}</div> ) : ( "" )}


                                                    { ( rowData.l3_exist === true ) ? ( <div>
                                                        {( rowData.status == 0 ||  rowData.status == 3 || rowData.status ==5 || rowData.status==7 || rowData.status == 1 || rowData.status == 2 || 
                                    rowData.status == 4  )&&
                                    <div>
                                        <span className="mb-1 ">L3</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={Grey} />
                                    </div>}
                                                        {rowData.status ==6 &&
                                    <div>
                                        <span className="mb-1 ">L3</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={YelloGrey} />
                                    </div>}
                                                        {rowData.status ==9 &&
                                    <div>
                                        <span className="mb-1 ">L3</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={RedGrey} />
                                    </div>}
                                                        {( rowData.status ==8 || rowData.status == 10 || rowData.status == 12 || rowData.status == 13 || rowData.status==11 || rowData.status == 14 || rowData.status == 15 ) &&
                                    <div>
                                        <span className="mb-1 ">L3</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={GreenGrey} />
                                    </div>}
                                                        {rowData.status == 16 &&
                                    <div>
                                        <span className="mb-1 ">L3</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={GreenYellow} />
                                    </div>}
                                                        {( rowData.status == 17 || rowData.status == 18 ) &&
                                    <div>
                                        <span className="mb-1 ">L3</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={Green} />
                                    </div>}</div> ) : ( "" )}
                                    
                                                    {( rowData.status == 0 ||  rowData.status == 3 || rowData.status ==5 || rowData.status==7 || rowData.status==9 || rowData.status == 1 || rowData.status == 2 || rowData.status == 4 || rowData.status ==6 )&&
                                    <div>
                                        <span className="mb-1 ">HR</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={Grey} />
                                    </div>}
                                                    {rowData.status == 8 &&
                                    <div>

                                        <span className="mb-1  ">HR</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={YelloGrey} />
                                    </div>}
                                                    {rowData.status == 11 &&
                                    <div>

                                        <span className="mb-1  ">HR</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={RedGrey} />
                                    </div>}
                                                    {( rowData.status == 10 || rowData.status == 12 || rowData.status == 13 || rowData.status == 14 || rowData.status == 15 || 
                                     rowData.status == 16 ) &&
                                    <div>

                                        <span className="mb-1  ">HR</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={GreenGrey} />
                                    </div>}
                                                    {rowData.status == 17 &&
                                    <div>

                                        <span className="mb-1  ">HR</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={GreenYellow} />
                                    </div>}
                                                    {rowData.status == 18 &&
                                    <div>

                                        <span className="mb-1  ">HR</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={Green} />
                                    </div>}
                                                </div>
                                            </div>
                                        );
                            
                                    },
                                },
                                {
                                    title: "Achievement",
                                    field: "Achievement",
                                    // align:"center",
                                    hidden: ( ! role.includes( "HR" ) ),
                                    render: ( rowData ) => {
                                        return(
                                            ( role.includes( "HR" ) && ( rowData.status===18 ) ) ?
                                                (
                                                    <div>
                                                        
                                                        {util.suspenseDotPopOverExceptionalAchievments( rowData,"" )}
                                                    </div>
                                                ) : ( role.includes( "HR" )? <FontAwesomeIcon size="2x" className='iconGray' icon={faAward} />: "" )

                                        );
                                    }
                                },
                   
                                {
                                    title: "Bonus",
                                    editable: "onUpdate",
                                    field: "Bonus",
                                    hidden: ( ! role.includes( "HR" ) ),
                        
                                    render: ( rowData ) => {
                                        return(
                                            (  role.includes( "HR" )  && rowData.status === 18 )?
                                                ( <div title="Approved Bonus">{rowData.bonus_approved} </div> )
                                  
                                                : ( role.includes( "HR" )? "Null" : "" )
                                        );
                        
                                    },
                                },
                   

                                {
                                    // title: "",
                                    editable: "onUpdate",
                                    // align:"center",
                                    render: ( rowData ) => {
                                        return (
                                            <div>
                                               
                                                <button  className='btn btn-sm px-2 customBtn' style={{ backgroundColor:rowData.buttonControl.buttonColor,color:rowData.buttonControl.contentColor }}
                                                // className={`btn btn-sm px-2 other  customBtn  ${( role.includes( "L2" ) ) ? `statusesll${ status && status}` : ''} ${( role.includes( "L2" )  )||( role.includes( "L3" )  ) ? `statusesl${ status && status}` : ''} ${ role.includes( "HR" )  ? `statuseshr${ status && status}` : ''} `} 
                                                    onClick={() => setSelectedITem( rowData )}
                                                >
                                                    {/* {buttonDependingOnStatus( rowData )} */}
                                                    {rowData.buttonControl.return_message}
                                                </button>
                                               
                                    

                                            </div>
                                        );
                                    },
                                },
                            ]}
                            data={query =>
                                util.axios.get( urlHandler( query, true ) )
                                    .then( response => {
                                        const { data, error, msg, page, total } = response.data;
                                        if ( error ) throw msg;
                                        // console.log( employees );
                                        return ( {
                                            data: filterAppraisee( filterByStatus,data ),
                                            page: page,
                                            totalCount: total
                                        } );
                                    } )
                                    .catch( error=>{
                                        util.handleError( error );
                                    } )
                            }
                            localization={{
                                toolbar:{
                                    searchPlaceholder:"Search",
                                    nRowsSelected: ""
                                }
                            }}
                            options={{
                                searchFieldAlignment: "left",
                                selection: true,
                                showTitle: false,
                                pageSize: 50,
                                pageSizeOptions:[50,100,150],
                                emptyRowsWhenPaging: false,
                                columnsButton: true,
                                sorting: true,
                                padding: "default",
                                tableLayout: "auto",
                                toolbarButtonAlignment: "left",
                                headerStyle:{ position:'sticky',top:0 },
                                maxBodyHeight:'440px',
                            }}
                            actions={[
                                {
                                    icon: () =>
                                        <div className="btn btn-info btn-sm">
                                            <FontAwesomeIcon icon={faFilter} className="mr-2" />
                                Filter
                                        </div>
                                    ,
                                    position:"toolbar",
                                    isFreeAction: true,
                                    onClick: () => setShowFilter( !showFilter )
                                },
                               
                                { icon: () =>
                                    <>
                                        <label style={{ fontSize:'12px' }}>
          Filter by status:

                                            <select className="form-control" value={filterByStatus} onChange={( e ) => setFilterByStatus( e.target.value )}>
                                                <option value="all">All</option>
                                                <option value="inprogress">In Progress</option>
                                                <option value="completed">Completed</option>
                                                {(  role.includes( "HR" ) )?"": <option value="pendingwithl2l3">Pending With Me</option>}
                                                {/* {(  role.includes( "L3" ) ) && <option value="pendingwithl3">Pending With L3</option>} */}
                                                {(  role.includes( "HR" ) ) && <option value="pendingwithHr">Pending With Me</option>}
                                            </select></label>
                                    </>
                                ,
                                position:"toolbar",
                                isFreeAction: true,
                                },
                            ]}
                        />
                    </MuiThemeProvider>}
                </div>
            </div>
        </div>
    );
};

export default OtherTeamMembersTable;
