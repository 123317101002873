import * as Yup from "yup";

let validations = {};

validations.validateLogin = Yup.object( {
    email: Yup.string().required(),
    password: Yup.string().required(),
} );

validations.validateRegister = Yup.object( {
    name: Yup.string()
        .required( 'Required' ),
    email: Yup.string()
        .email( 'Email is invalid' )
        .required( 'Email is required' )
        .max( 35, 'Max 35 digits' ),
    phone: Yup.string()
        .min( 10, 'Phone number needs 10 digits' )
        .max( 12, 'Max 12 digits' )
        .required( 'Phone number is required' ),
    // skills: Yup.array().of(Yup.object().shape({
    // 	skill_name: Yup.string()
    // 		.min(4, 'Minimum 4 characters')
    // 		.max(30, 'Max 30 characters')
    // 		.required('Required'),
    // 	years_experience: Yup.number()
    // 		.positive('Must be positive digit')
    // 		.required('Required'),
    // }))
} );

export default validations;