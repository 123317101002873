import React from "react";

export default function SkillsPrintVersion( { skill, index } ) {
	
    return(
        <tr key={index}>
            <td className="d-flex justify-content-around">
				
                {skill.skill}
			
            </td>
            <td>
                <div className="d-flex">
                    {skill.yearsOfExperience}
                </div>
			
            </td>
            <td>
                <div className="d-flex">
                    {skill.desc}
                </div>
			
            </td>
        </tr>
    );
	
}