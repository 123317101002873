/**
 * This reducer handle the Array of talents inside the Job Position
* */
const initialState = [];
const candidatesReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case "SET_CANDIDATES":
            // Add all talents of the position
            return Object.assign( [], action.payload );
        case "SET_CANDIDATE_STATUS":
            // Update the status of a individual candidate
            let c = state;
      
            c.forEach( ( talent,i ) => {
                if ( talent.id===action.payload.id ) {
                    c[i].status = action.payload.status.toString();
                    c[i].talent_desc_updated = action.payload.talent_desc_updated;
                    c[i].talent_updated = new Date().toISOString().split( '.' )[0];
                }
            } );
            return Object.assign( [], c );
        default:
            return state;
    }
};

export default candidatesReducer;
