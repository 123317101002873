import React, { Fragment, useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowLeft,
    faBan,
    faCaretDown,
    faCheck,
    faCircle,
    faCogs,
    faEnvelope,
    faExclamationCircle,
    faExclamationTriangle,
    faFileDownload,
    faFileExport,
    faFileInvoiceDollar,
    faFlagCheckered,
    faHandHoldingUsd,
    faPaperclip,
    faPaperPlane,
    faPhone,
    faPhoneAlt,
    faPlusCircle,
    faSave,
    faStar,
    faThumbsUp,
    faTimes,
    faTrashAlt,
    faUsers,
    faUserTimes,
} from "@fortawesome/free-solid-svg-icons";

import MaterialTable from "material-table";
import ModalAddCandidate from "./miniComponents/ModalAddCandidate";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CircularProgress,
    MuiThemeProvider,
    Menu,
    MenuItem,
} from "@material-ui/core";

import JobPositionForm from "./miniComponents/JobPositionForm";

import StopProcess from "./miniComponents/StopProcess";
import RestartProcess from "./miniComponents/RestartProcess";
import { connect } from "react-redux";
import { setCandidates } from "../../../redux/actions/JobCandidatesAction";
import { setPosition } from "../../../redux/actions/JobPositionAction";
import "../../../assets/css/main.css";
import SettingRequest from "../../utils/settingRequest";
import {
    INPUT_BUY_RATE,
    INPUT_TALENT_EXPECTATION,
    INPUT_TALENT_SELL_RATE,
    SELECT_EMPLOYMENT_TYPE,
} from "../../../constants";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectSimple from "../../microComponents/SelectSimple";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import ListOnboardedTalents from "./miniComponents/require_documents/ListOnboardedTalents";
import SubmitTalentModal from "./miniComponents/submit_talents/SubmitTalentModal";
import DropDownOptions from "../../shared/jobs/DropDownOptions";
import ModalJobSetting from "./miniComponents/cost_to_company/ModalJobSetting";
import CtcDrawer from "./miniComponents/cost_to_company/CtcDrawer";
import TeamCollaboration from "./team_collaboration";
import Feedback from "react-bootstrap/esm/Feedback";
import LikeDislike from "./miniComponents/LikeDislike";
// import TimeLineCandidate from "./position_detail/TimeLineCandidate";
import ChangeSaleRate from "./position_detail/ChangeSaleRate";
import TitleExpansion from "./position_detail/TitleExpansion";
import util from "../../utils/miniUtils";
// import CandidateProgress from "./position_detail/CandidateProgress";
// import { Tab, Tabs } from "react-bootstrap";
import TalentStatusSelector from "./position_detail/TalentStatusSelector";
import BulkStatusSelector from "./position_detail/BulkStatusSelector";
import TimeLineCandidate from "./position_detail/TimeLineCandidate";
import DisplayComments from "./position_detail/DisplayComments";
import SimpleEmailModal from "../../shared/SimpleEmailModal";

import BasicTabs from "./ScreeningTabs";
// import TabPanel from '@material-ui/lab/TabPanel';




/**
 * This Class Component will request for the generic data
 * of the forms created
 */

const PositionDetail = ( props ) => {
    const {
        match: { params },
    } = props;
    const query = new URLSearchParams( props.location.search );
    const opc = query.get( "opc" );
    const position_id = params.id;
    const [settings, setSettings] = useState( null );
    const [position, setPosition] = useState( null );
    const [candidates] = useState( [] );
    // const [setPositionId] = useState( null );
    const [modalVisible, setModalVisible] = useState( false );
    const [isLoading, setIsLoading] = useState( true );
    const [backDropOpen, setBackDropOpen] = useState( false );
    const [stopProcessShow, setStopProcessShow] = useState( false );
    const [stopProcessTalentShow, setStopProcessTalentShow] = useState( false );
    const [reStartProcessTalentShow, setReStartProcessTalentShow] =
        useState( false );
    const [deleteTalentShow, setDeleteTalentShow] = useState( false );
    const [deleteTalentsShow, setDeleteTalentsShow] = useState( false );
    const [positionFilledShow, setPositionFilledShow] = useState( false );
    const [changeStatusShow, setChangeStatusShow] = useState( false );
    const [sendEmail, setSendEmail] = useState( false );
    const [ids, setIds] = useState( [] );
    const [selectedRows, setSelectedRows] = useState( [] );
    const [submitModal, setSubmitModal] = useState( false );
    const [showModalDocuments] = useState( false );
    const [expanded, setExpanded] = useState( opc ? "panel1" : "panel2" );
    const [applyChanges, setApplyChanges] = useState( null );
    const [cancelChanges, setCancelChanges] = useState( null );
    const [changeStatusModal, setChangeStatusModal] = useState( false );
    const [statusSelected, setStatusSelected] = useState( null );
    const [showModalCTCSettings, setShowModalCTCSettings] = useState( false );
    const [talentRowData, setTalentRowData] = useState();
    const [showTeamCollaborationDrawer, setShowTeamCollaborationDrawer] =
        useState( false );
    const [showDrawer, setShowDrawer] = useState();
    const [state, setState] = useState( {
        showDropDownMenu: false,
    } );
    const [emailModal, setEmailModal] = useState( {
        show: false,
    } );
 
    useEffect( () => {
        testTest().then( null );
    }, [] );
    const testTest = async () => {
        const settings = await SettingRequest.getSettings();
        setSettings( settings );
        getJobPositionInformation();
    };
    const getJobPositionInformation = async () => {
        // After component mount, make the request to get the "positions of the current requirement"
        try {
            const request = await util.axios.get(
                `${util.url}/requirements/job_position_information/${position_id}`
            );
            const { error, msg, position } = request.data;
            if ( error ) throw msg;
            // Send position data to redux
            props.setPosition( position );
            setPosition( position );

            // setNewStatus( util.jobStatusOnlyIcons( position.status ) );

            await showCandidatesOfPosition( position_id );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const removeCandidateFromPosition = async () => {
        // This is activated after user confirm that wants to remove the talent using the trash icon
        try {
            let ids = [];
            for ( let x = 0; x < selectedRows.length; x++ )
                if ( ![19, 13].includes( parseInt( selectedRows[x].status ) ) )
                    ids.push( selectedRows[x].candidate_id );

            const request = await util.axios.post(
                `${util.url}/requirements/remove_candidates_from_position/${position_id}`,
                { candidates_ids: ids }
            );
            const { error, msg, candidates } = request.data;
            if ( error ) throw msg;
            // Send talents to redux
            props.setCandidates( candidates );
            util.toast().success( msg );
            // setPositionId( position_id );
            setDeleteTalentsShow( false );
            // setDeleteTalentShow( false );
        } catch ( error ) {
            setDeleteTalentShow( false );
            util.handleError( error );
        }
    };

    const showCandidatesOfPosition = async ( id ) => {
        // Retrieves the list of talents inside this job position
        try {
            const request = await util.axios.get(
                `${util.url}/requirements/candidates_of_position/${id}`
            );
            let { error, msg, candidates } = request.data;
            if ( error ) throw msg;

            if ( candidates.length > 0 ) {
                // Also get information about pending evaluation reviews
                const ids = [];
                candidates.map( ( c ) => ids.push( c.id ) );
                const p = { jobId: id, ids: ids };
                const requestEvaluations = await util.axios.post(
                    `${util.url}/job/talent_has_pending_review`,
                    p
                );
                let { talentPendingEvaluationReview } = requestEvaluations.data;

                if ( talentPendingEvaluationReview.length > 0 ) {
                    for ( let i = 0; i < candidates.length; i++ ) {
                        talentPendingEvaluationReview.map( ( t ) => {
                            if ( t.talent_id === candidates[i].id ) {
                                candidates[i].pendingEvaluationReview = t.hasPending;
                            }
                        } );
                    }
                }
            }

            // Send talents to redux
            props.setCandidates( Object.assign( [], candidates ) );

            // setPositionId( id );
            setIsLoading( false );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const addCandidate = () => {
        // Enable modal to add talent to the job
        setModalVisible( true );
    };
    const hideModal = () => {
        // Hide the modal used to add talents to the job
        setModalVisible( false );
    };

    const handleChangePanel = ( panel ) => () => {
        // Handles which panel is expanded

        setExpanded(
            panel === expanded ? ( panel === "panel1" ? "panel2" : "panel1" ) : panel
        );
    };
    const onConfirmChangeStatus = async () => {
        try {
            // Change status of the job position between Active or HOLD
            setChangeStatusShow( false );
            setBackDropOpen( true );
            let ids = [];
            ids.push( position.id );
            // newStatus have the next status of the selected positions
            const newStatus = position.status === 1 ? 2 : 1;
            const request = await util.axios.put(
                `${util.url}/jobs/change_status_job_process`,
                { jobs: ids, newStatus, sendEmail: sendEmail }
            );
            const { error, msg } = request.data;

            if ( error ) throw msg;
            util.toast().success( msg );
            await getJobPositionInformation();
            setBackDropOpen( false );
            setSendEmail( false );
        } catch ( e ) {
            setBackDropOpen( false );
            setSendEmail( false );
            util.handleError( e );
        }
    };
    const onConfirmStopProcess = async () => {
        // Stop the process of this job position
        try {
            setStopProcessShow( false );
            setBackDropOpen( true );
            let ids = [];
            ids.push( position.id );
            const request = await util.axios.put(
                `${util.url}/jobs/stop_job_process`,
                { jobs: ids, sendEmail: sendEmail }
            );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            util.toast().success( msg );
            await getJobPositionInformation();
            setBackDropOpen( false );
            setSendEmail( false );
        } catch ( e ) {
            setBackDropOpen( false );
            setSendEmail( false );
            util.handleError( e );
        }
    };

    const onPositionFilled = async () => {
        // Mark this job position as FILLED
        try {
            setPositionFilledShow( false );
            setBackDropOpen( true );
            const request = await util.axios.put(
                `${util.url}/jobs/job_position_filled/${position.id}`,
                { sendEmail: sendEmail }
            );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            util.toast().success( msg );
            await getJobPositionInformation();
            setBackDropOpen( false );
            setSendEmail( false );
        } catch ( e ) {
            setBackDropOpen( false );
            setSendEmail( false );
            util.handleError( e );
        }
    };
    const hideModalStopProcess = () => {
        setStopProcessTalentShow( false );
        setBackDropOpen( false );

        showCandidatesOfPosition( position_id ).then( null );
    };

    const hideModalReStartProcess = () => {
        setReStartProcessTalentShow( false );
        setBackDropOpen( false );
        showCandidatesOfPosition( position_id ).then( () => null );
    };

    const onChangeEmploymentType = async ( e ) => {
        try {
            const { position_id, candidate_id } = e.rowData;
            const { value } = e.target;
            let data = { field: SELECT_EMPLOYMENT_TYPE, value };
            const request = await util.axios.patch(
                `${util.url}/job/update_talent_position_rates/${position_id}/${candidate_id}`,
                data
            );
            const { error, msg } = request.data;
            let temp = props.candidates;
            temp.forEach( ( candidate, index ) => {
                if ( candidate.id === candidate_id ) {
                    temp[index].employment_type = value;
                    temp[index].talent_desc_updated = "Employment Type Updated";
                }
            } );

            props.setCandidates( temp );
            props.setPosition( temp );
            if ( error ) throw msg;
        } catch ( e ) {
            util.handleError( e );
        }
    };

    const updateChange = ( job = null ) => {
        if ( job ) {
            setPosition( job );
        }
        showCandidatesOfPosition( position_id ).then( () => null );
    };

    const refreshTalentTable = () => {
        // this.props.talents.map((c,i)=> console.log(c.talent_expectation))
        //
        // this.showCandidatesOfPosition(.position_id).then(r => null);
        // this.setState({forceRender: Math.random * Math.random})
    };

    const submitTalentsModal = () => {
        setSubmitModal( true );
    };

    const changeStatusOfTalent = async ( option ) => {
        try {
            let ids = [];
            for ( let x = 0; x < selectedRows.length; x++ ) {
                const element = selectedRows[x];
                ids.push( element.candidate_id );
            }

            const { id } = position;

            const request = await util.axios.patch(
                `job/update_talent_position_status/${id}`,
                { talents: ids, newStatus: option }
            );
            const { error, msg, candidates } = request.data;

            if ( error ) throw msg;
            setChangeStatusModal( false );
            setStatusSelected( null );
            props.setCandidates( candidates );
        } catch ( e ) {
            util.handleError( e );
        }
    };

    const sendEmailCheckbox = ( e ) => {
        const { checked } = e.target;
        setSendEmail( checked );
    };
    const whatsAppImage = "/images/whatsapp.png";

    if ( isLoading ) {
        return (
            <div className="pt-5 mt-5 text-center">
                <CircularProgress />
            </div>
        );
    }
    return (
        <Fragment>
            <div>
                <Link to={"/jobs"}>
                    <FontAwesomeIcon icon={faArrowLeft} className={"mr-2"} />
                    Back to Jobs
                </Link>
            </div>
            <div className="row mt-2">
                <div className="col-md-12 mb-1">
                    <Accordion
                        expanded={expanded === "panel1"}
                        onChange={handleChangePanel( "panel1" )}
                    >
                        <AccordionSummary
                            expandIcon={
                                <FontAwesomeIcon
                                    icon={faCaretDown}
                                    className="titleExpandible"
                                />
                            }
                        >
                            <div className="w-100 d-flex justify-content-around">
                                <TitleExpansion position={position} />

                                <div
                                    onClick={( event ) => event.stopPropagation()}
                                    onFocus={( event ) => event.stopPropagation()}
                                    className="w-50 pl-2 d-flex justify-content-end align-content-center align-items-center"
                                >
                                    <DropDownOptions
                                        jobArray={[position]}
                                        reloadTableData={getJobPositionInformation}
                                    />
                                    {position?.ctc_settings ? (
                                        <Fragment>
                                            <button
                                                className="btn btn-sm btn-info ml-2"
                                                onClick={() => setShowModalCTCSettings( true )}
                                            >
                                                <Tooltip title="Modify CTC settings on this job">
                                                    <span>
                                                        <FontAwesomeIcon
                                                            icon={faCogs}
                                                            className="mr-1 ml-1"
                                                        />
                                                        <span className="p-1"> CTC Settings</span>
                                                    </span>
                                                </Tooltip>
                                            </button>
                                            {showModalCTCSettings ? (
                                                <ModalJobSetting
                                                    reloadTalentsTable={() =>
                                                        showCandidatesOfPosition( position_id )
                                                    }
                                                    job={position}
                                                    show={showModalCTCSettings}
                                                    ctcSetting={position?.ctc_settings}
                                                    handleClose={() => setShowModalCTCSettings( false )}
                                                />
                                            ) : null}
                                        </Fragment>
                                    ) : null}

                                    {expanded === "panel1" ? (
                                        <Fragment>
                                            {position.status === 1 ? (
                                                <Fragment>
                                                    <button
                                                        onFocus={( event ) => event.stopPropagation()}
                                                        onClick={( e ) => {
                                                            e.stopPropagation();

                                                            setCancelChanges( Math.random() * 2 );
                                                        }}
                                                        className={"btn text-danger ml-2 btn-sm"}
                                                    >
                                                        <FontAwesomeIcon icon={faTimes} className="mr-1" />
                                                        Cancel
                                                    </button>
                                                    <button
                                                        onFocus={( event ) => event.stopPropagation()}
                                                        onClick={( event ) => {
                                                            event.stopPropagation();

                                                            setApplyChanges( Math.random() * 3 );
                                                        }}
                                                        className={"btn btn-primary ml-2 btn-sm"}
                                                    >
                                                        <FontAwesomeIcon icon={faSave} className="mr-1" />
                                                        Save
                                                    </button>
                                                </Fragment>
                                            ) : null}
                                        </Fragment>
                                    ) : null}
                                    <button
                                        className="btn btn-sm btn-primary ml-2"
                                        onClick={() => setShowTeamCollaborationDrawer( true )}
                                    >
                                        <Tooltip title="Team Colaborations in this Job">
                                            <span>
                                                <FontAwesomeIcon icon={faUsers} className="mr-1 ml-1" />
                                                <span className="p-1"> Team Collaboration</span>
                                            </span>
                                        </Tooltip>
                                    </button>
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <JobPositionForm
                                job={props.position}
                                handleChange={updateChange}
                                updateChanges={applyChanges}
                                cancelChanges={cancelChanges}
                                talents={candidates}
                            />
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <Accordion
                        expanded={expanded === "panel2"}
                        onChange={handleChangePanel( "panel2" )}
                    >
                        <AccordionSummary
                            expandIcon={
                                <FontAwesomeIcon
                                    icon={faCaretDown}
                                    className={"titleExpandible"}
                                />
                            }
                        >
                            <b className={"titleExpandible"}>Talent List</b>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="container-fluid">
                                {/* <Tabs mountOnEnter={true} unmountOnExit={true} defaultActiveKey="list"> */}
                                {/* <Tab eventKey="old" title="Timeline"> */}
                                <MuiThemeProvider theme={util.defaultTableTheme}>
                                    <MaterialTable
                                        columns={[
                                            {
                                                title: "",
                                                field: "",
                                                hidden: position.ctc_settings === null,
                                                sorting: false,
                                                disableClick: true,
                                                render: ( data ) => {
                                                    return (
                                                        <button
                                                            onClick={() => {
                                                                setTalentRowData( data );
                                                                setShowDrawer( true );
                                                            }}
                                                            className="btn btn-sm btn-primary btnsmall"
                                                        >
                                                            <Tooltip title="Edit Cost">
                                                                <span>
                                                                    <FontAwesomeIcon icon={faHandHoldingUsd} />
                                                                </span>
                                                            </Tooltip>
                                                        </button>
                                                    );
                                                },
                                            },
                                            {
                                                title: "Talent ID",
                                                field: "id",
                                                disableClick: true,
                                                render: ( data ) => {
                                                    return (
                                                        <div className="justify-content-between">
                                                            <Link
                                                                to={`/candidates/${data.id}?jp=true&job_id=${position_id}`}
                                                            >
                                                                {data.id}
                                                            </Link>
                                                        </div>
                                                    );
                                                },
                                            },
                                            {
                                                title: "Status",
                                                field: "status",
                                                render: ( data ) =>
                                                    util.canUseNewMethod( position.createdAt ) ? (
                                                        <TalentStatusSelector
                                                            statusName={data.statusName}
                                                            status={data.status}
                                                            candidateId={data.candidate_id}
                                                            positionId={data.position_id}
                                                            getPositionCandidates={showCandidatesOfPosition}
                                                        />
                                                    ) : (
                                                        <div className="text-center">
                                                            {util.candidateStatus(
                                                                data.status,
                                                                data["status_note"]
                                                            )}
                                                        </div>
                                                    ),
                                                cellStyle: {
                                                    backgroundColor: "#f0f0f0",
                                                },
                                            },
                                            {
                                                title: "Name",
                                                field: "name",
                                                render: ( data ) => {
                                                    return (
                                                        <div className="justify-content-between">
                                                            {data.pendingEvaluationReview ? (
                                                                <Tooltip title="Evaluated">
                                                                    <span>
                                                                        <FontAwesomeIcon
                                                                            icon={faExclamationTriangle}
                                                                            className="text-warning"
                                                                        />
                                                                    </span>
                                                                </Tooltip>
                                                            ) : null}
                                                            {data.resume ? (
                                                                <a
                                                                    target={"_blank"}
                                                                    rel="noopener noreferrer"
                                                                    href={util.resumeUrl( data )}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={faPaperclip}
                                                                        className="mr-2"
                                                                    />
                                                                </a>
                                                            ) : (
                                                                ""
                                                            )}
                                                            <Link
                                                                to={`/candidates/${data.id}?jp=true&job_id=${position_id}`}
                                                            >
                                                                {data.name}
                                                            </Link>
                                                        </div>
                                                    );
                                                },
                                            },
                                            {
                                                title: "Email",
                                                field: "email",
                                                disableClick: true,
                                                render: ( data ) => {
                                                    return (
                                                        <Fragment>
                                                            <a href={`mailto:${data.email}`}>{data.email}</a>
                                                        </Fragment>
                                                    );
                                                },
                                            },
                                            {
                                                title: "Comments",
                                                field: "status_note",
                                                disableClick: true,
                                                render: ( data ) => {
                                                    let statusNote = "";
                                                    let isStr = false;
                                                    try {
                                                        statusNote = JSON.parse( data?.status_note );
                                                    } catch ( ex ) {
                                                        isStr = true;
                                                        statusNote = data.status_note;
                                                    }
                                                    return <DisplayComments statusNote={statusNote} isStr={isStr} />;
                                                },

                                            },
                                            {
                                                title: "Send Email",
                                                disableClick: true,
                                                render: ( data ) => {
                                                    return (
                                                        <Fragment>
                                                            <button
                                                                className="btn"
                                                                onClick={( e ) =>
                                                                    setEmailModal( {
                                                                        ...emailModal,
                                                                        show: e.currentTarget,
                                                                        data: data
                                                                    } )
                                                                }
                                                            >
                                                                <FontAwesomeIcon icon={faEnvelope} />
                                                            </button>
                                                            <SimpleEmailModal
                                                                show={emailModal.show}
                                                                data={emailModal.data}
                                                                handleClose={() => setEmailModal( { show: false } )}
                                                                index={5000006}
                                                            />
                                                        </Fragment>
                                                    );
                                                },
                                            },
                                            {
                                                title: "Phone",
                                                field: "phone_mobile",
                                                disableClick: true,
                                                render: ( data ) => {
                                                    return (
                                                        <Fragment>
                                                            <button
                                                                className="btn"
                                                                onClick={( e ) =>
                                                                    setState( {
                                                                        ...state,
                                                                        showDropDownMenu: e.currentTarget,
                                                                        rowData: data
                                                                    } )
                                                                }
                                                            >
                                                                <FontAwesomeIcon icon={faPhoneAlt} />
                                                            </button>
                                                            <Menu
                                                                anchorEl={state.showDropDownMenu}
                                                                open={state.showDropDownMenu}
                                                                onClose={() =>
                                                                    setState( {
                                                                        ...state,
                                                                        showDropDownMenu: false,
                                                                    } )
                                                                }
                                                                MenuListProps={{
                                                                    "aria-labelledby": "basic-button",
                                                                }}
                                                            >
                                                                <MenuItem
                                                                    onClick={() =>
                                                                        setState( {
                                                                            ...state,
                                                                            showDropDownMenu: false,
                                                                        } )
                                                                    }
                                                                >
                                                                    <a
                                                                        target="_new"
                                                                        href={`tel:${state.rowData?.phone_mobile}`}
                                                                        className="fontSize12"
                                                                    >
                                                                        <FontAwesomeIcon icon={faPhone} className="mr-1" />
                                                                        {state.rowData?.phone_mobile ? util.formatPhone( state.rowData?.phone_mobile ) : ''}
                                                                    </a>
                                                                </MenuItem>
                                                                <MenuItem
                                                                    onClick={() =>
                                                                        setState( {
                                                                            ...state,
                                                                            showDropDownMenu: false,
                                                                        } )
                                                                    }
                                                                >
                                                                    { state.rowData ?
                                                                        <a
                                                                            target="new"
                                                                            className="fontSize12"
                                                                            href={util.whatsapp(
                                                                                state.rowData.phone_mobile,
                                                            `Hello, my name is ${util.user.name} from eJAmerica`

                                                                            )}
                                                                        >
                                                                            <img
                                                                                width={16}
                                                                                src={whatsAppImage}
                                                                                alt="whatsapp"
                                                                                className="mr-1"
                                                                            />
                                                          Send WhatsApp
                                                                        </a>
                                                                        :
                                                                        ""
                                                                    }

                                                                </MenuItem>
                                                            </Menu>
                                                        </Fragment>
                                                    );
                                                },
                                            },
                                            {
                                                title: "Location",
                                                field: "city",
                                                render: ( data ) => {
                                                    return util.location(
                                                        data.country,
                                                        data.state,
                                                        data.city
                                                    );
                                                },
                                            },
                                            {
                                                title: "Employment Type",
                                                disableClick: true,
                                                customSort: ( a, b ) =>
                                                    a.ctc_settings.employmentType.length -
                                                    b.ctc_settings.employmentType.length,
                                                hidden: position.ctc_settings === null,
                                                render: ( data ) => {
                                                    return data.ctc_settings.employmentType;
                                                },
                                            },
                                            {
                                                title: "Sell Margin",
                                                disableClick: true,
                                                customSort: ( a, b ) =>
                                                    a.ctc_settings.sell_margin -
                                                    b.ctc_settings.sell_margin,
                                                hidden: position.ctc_settings === null,
                                                render: ( data ) => {
                                                    return util.marginColors(
                                                        data.ctc_settings.sell_margin
                                                    );
                                                },
                                            },
                                            {
                                                title: "eJOffer",
                                                disableClick: true,
                                                customSort: ( a, b ) =>
                                                    a.ctc_settings.ejOffer - b.ctc_settings.ejOffer,
                                                hidden: position.ctc_settings === null,
                                                render: ( data ) => {
                                                    return util.currencyFormat(
                                                        data.ctc_settings.ejOffer,
                                                        data.job.country
                                                    );
                                                },
                                            },
                                            {
                                                title: "CTC Per Hour",
                                                disableClick: true,
                                                customSort: ( a, b ) =>
                                                    a.ctc_settings.ctcPerHour - b.ctc_settings.ctcPerHour,
                                                hidden: position.ctc_settings === null,
                                                render: ( data ) => {
                                                    return util.currencyFormat(
                                                        data.ctc_settings.ctcPerHour,
                                                        data.job.country
                                                    );
                                                },
                                            },
                                            {
                                                title: "Sell Rate Per Hour",
                                                disableClick: true,
                                                customSort: ( a, b ) =>
                                                    a.ctc_settings.sellRatePerHour -
                                                    b.ctc_settings.sellRatePerHour,
                                                hidden: position.ctc_settings === null,
                                                render: ( data ) => {
                                                    return util.currencyFormat(
                                                        data.ctc_settings.sellRatePerHour,
                                                        data.job.country
                                                    );
                                                },
                                            },
                                            {
                                                title: "Employment Type",
                                                field: "employment_type",
                                                disableClick: true,
                                                hidden: position.ctc_settings !== null,
                                                render: ( data ) => {
                                                    return (
                                                        <SelectSimple
                                                            rData={data}
                                                            onChange={onChangeEmploymentType}
                                                            value={data.employment_type}
                                                            name="employment_type"
                                                            data={util.employment_type}
                                                        />
                                                    );
                                                },
                                            },
                                            {
                                                title: "Talent Expectation",
                                                field: "talent_expectation",
                                                disableClick: true,
                                                hidden: position.ctc_settings !== null,
                                                render: ( data ) => {
                                                    return (
                                                        <Fragment>
                                                            <Tooltip title={"Talent expectation by HOUR"}>
                                                                <div className="text-center">
                                                                    <ChangeSaleRate
                                                                        updateChange={updateChange}
                                                                        typeInput={INPUT_TALENT_EXPECTATION}
                                                                        data={data}
                                                                    />
                                                                </div>
                                                            </Tooltip>
                                                        </Fragment>
                                                    );
                                                },
                                            },
                                            {
                                                title: "eJOffer",
                                                field: "buy_rate",
                                                disableClick: true,
                                                hidden: position.ctc_settings !== null,
                                                render: ( d ) => {
                                                    return (
                                                        <div className="text-center">
                                                            <ChangeSaleRate
                                                                updateChange={updateChange}
                                                                typeInput={INPUT_BUY_RATE}
                                                                data={d}
                                                            />
                                                        </div>
                                                    );
                                                },
                                            },
                                            {
                                                title: "T Sell rate",
                                                field: "talent_sell_rate",
                                                disableClick: true,
                                                hidden: position.ctc_settings !== null,
                                                render: ( d ) => {
                                                    return (
                                                        <div className="text-center">
                                                            <ChangeSaleRate
                                                                updateChange={updateChange}
                                                                typeInput={INPUT_TALENT_SELL_RATE}
                                                                data={d}
                                                            />
                                                        </div>
                                                    );
                                                },
                                            },
                                            {
                                                title: "Job Sell Rate",
                                                field: "sell_rate",
                                                hidden: position.ctc_settings !== null,
                                                disableClick: true,
                                                render: ( row ) => {
                                                    return (
                                                        <div className="text-center">
                                                            {util.currencyFormat(
                                                                parseFloat( position.sell_rate | 0 ),
                                                                row.job.country
                                                            )}
                                                        </div>
                                                    );
                                                },
                                            },
                                            {
                                                title: "CTC",
                                                field: "ctc",
                                                disableClick: true,
                                                hidden: position.ctc_settings !== null,
                                                customSort: ( a, b ) =>
                                                    util.calculateCTCNew( a, settings ) -
                                                    util.calculateCTCNew( b, settings ),
                                                render: ( data ) => {
                                                    return util.currencyFormat(
                                                        util.calculateCTCNew( data, settings )
                                                    );
                                                },
                                            },
                                            {
                                                title: "Margin %",
                                                disableClick: true,
                                                hidden: position.ctc_settings !== null,
                                                customSort: ( a, b ) =>
                                                    util.calculateMargin( a, settings ) -
                                                    util.calculateMargin( b, settings ),
                                                render: ( data ) => {
                                                    return util.marginColors(
                                                        util.calculateMargin( data, settings )
                                                    );
                                                },
                                            },
                                            {
                                                title: "Application Date",
                                                disableClick: true,
                                                field: "application_date",
                                                render: ( data ) => {
                                                    return (
                                                        <div className="text-center">
                                                            {util.humanDate( data["application_date"] )}
                                                        </div>
                                                    );
                                                },
                                            },
                                            {
                                                title: "Latest Update",
                                                disableClick: true,
                                                field: "talent_updated",
                                                render: ( data ) => {
                                                    return (
                                                        <div className="text-center">
                                                            {util.humanDate( data["talent_updated"] )}
                                                        </div>
                                                    );
                                                },
                                            },
                                            {
                                                title: "Description Update",
                                                disableClick: true,
                                                field: "talent_desc_updated",
                                                render: ( data ) => {
                                                    return (
                                                        <div className="text-center">
                                                            <Tooltip
                                                                title={
                                                                    data["talent_desc_updated"]
                                                                        ? data["talent_desc_updated"]
                                                                        : ""
                                                                }
                                                            >
                                                                <span className="text-primary">
                                                                    {" "}
                                                                    {data["talent_desc_updated"]}
                                                                </span>
                                                            </Tooltip>
                                                        </div>
                                                    );
                                                },
                                            },
                                            {
                                                title: "Evaluation",
                                                disableClick: true,
                                                sorting: false,
                                                render: ( data ) => {
                                                    return (
                                                        <div className="text-center">
                                                            <LikeDislike data={data} />
                                                        </div>
                                                    );
                                                },
                                            },
                                            {
                                                title: "Feedback",
                                                disableClick: true,
                                                sorting: false,
                                                render: ( data ) => {
                                                    return (
                                                        <div className="text-center">
                                                            <Feedback data={data} />
                                                        </div>
                                                    );
                                                },
                                            },
                                            {
                                                title: "Rating",
                                                disableClick: true,
                                                field: "rating",
                                                render: ( data ) => {
                                                    return (
                                                        <div className="text-center">
                                                            <FontAwesomeIcon
                                                                icon={faStar}
                                                                color="gold"
                                                                className="mr-1"
                                                            />
                                                            {data.rating ? data.rating.toFixed( 2 ) : "0"}
                                                        </div>
                                                    );
                                                },
                                            },
                                            //                 {
                                            //                     title: "Delete",
                                            //                     disableClick: true,
                                            //                     render: ( data ) => {
                                            //                         return data.status === "19" ||
                                            // data.status === "11" ||
                                            // data.status === "13" ||
                                            // position.status !== 1 ? (
                                            //                                 ""
                                            //                             ) : (
                                            //                                 <div className="text-center">
                                            //                                     <FontAwesomeIcon
                                            //                                         className="isPointer removeItem"
                                            //                                         icon={faTrashAlt}
                                            //                                         onClick={() => {
                                            //                                             setData();
                                            //                                             setDeleteTalentShow( true );
                                            //                                         }}
                                            //                                     />
                                            //                                 </div>
                                            //                             );
                                            //                     },
                                            //                 },
                                        ]}
                                        data={props.candidates}
                                        options={{
                                            pageSize: 20,
                                            pageSizeOptions: [20, 50, 100],
                                            padding: "default",
                                            sorting: true,
                                            selection: true,
                                            showTitle: false,
                                            draggable: true,
                                            toolbarButtonAlignment: "left",
                                            // searchFieldAlignment:"left",
                                            emptyRowsWhenPaging: false,
                                            paginationPosition: "both",
                                            showSelectAllCheckbox: false,
                                            // selectionProps: ( rowData ) => ( {
                                            //     disabled:   [19, 13, 11, 6].includes( parseInt( rowData.status ) )
                                            // } ),
                                            headerStyle: { position: "sticky", top: 0 },
                                            maxBodyHeight: "500px",
                                        }}
                                        onSelectionChange={( rows ) => setSelectedRows( rows )}
                                        actions={[
                                            {
                                                icon: () => (
                                                    <>
                                                        {util.canUseNewMethod( position.createdAt ) ? (
                                                            <BulkStatusSelector
                                                                candidates={selectedRows}
                                                                positionId={position.jobId}
                                                                getPositionCandidates={showCandidatesOfPosition}
                                                                setDeleteTalentsShow={setDeleteTalentsShow}
                                                            />
                                                        ) : (
                                                            <Dropdown as="div" className={`dropMenuStatus`}>
                                                                <Dropdown.Toggle
                                                                    as="div"
                                                                    variant="success"
                                                                    id="dropdown-basic"
                                                                    className="customDropDownChangeStatus"
                                                                >
                                                                    <Tooltip title="This action will not trigger any email notification">
                                                                        <span className="text-white">
                                                                            Change Status
                                                                            <FontAwesomeIcon
                                                                                icon={faExclamationCircle}
                                                                                className="ml-2"
                                                                            />
                                                                        </span>
                                                                    </Tooltip>
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item
                                                                        className="optionSubmitted"
                                                                        href="#"
                                                                        onClick={() => changeStatusOfTalent( 22 )}
                                                                    >
                                                                        <Tooltip title="Talent submitted">
                                                                            <span>
                                                                                <FontAwesomeIcon
                                                                                    icon={faPaperPlane}
                                                                                    className="mr-2"
                                                                                />{" "}
                                                                                Submitted
                                                                            </span>
                                                                        </Tooltip>
                                                                    </Dropdown.Item>

                                                                    <Dropdown.Item
                                                                        className="optionAccepted"
                                                                        href="#"
                                                                        onClick={() => changeStatusOfTalent( 12 )}
                                                                    >
                                                                        <Tooltip title="The talent is selected and move to onboarding process">
                                                                            <span>
                                                                                <FontAwesomeIcon
                                                                                    icon={faCheck}
                                                                                    className="mr-2"
                                                                                />{" "}
                                                                                Accepted
                                                                            </span>
                                                                        </Tooltip>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item
                                                                        className="optionRejected"
                                                                        href="#"
                                                                        onClick={() => {
                                                                            setChangeStatusModal( true );
                                                                            setStatusSelected( 11 );
                                                                        }}
                                                                    >
                                                                        <Tooltip title="The talent is rejected (Interview level/ Missing Skills/ Capability)">
                                                                            <span>
                                                                                <FontAwesomeIcon
                                                                                    icon={faCircle}
                                                                                    className="mr-2"
                                                                                />{" "}
                                                                                Rejected
                                                                            </span>
                                                                        </Tooltip>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item
                                                                        className="optionTOReceived"
                                                                        href="#"
                                                                        onClick={() => changeStatusOfTalent( 20 )}
                                                                    >
                                                                        <Tooltip title="Task Order Received from the client">
                                                                            <span>
                                                                                <FontAwesomeIcon
                                                                                    icon={faFileDownload}
                                                                                    className="mr-2"
                                                                                />{" "}
                                                                                TO Received
                                                                            </span>
                                                                        </Tooltip>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item
                                                                        className="optionOfferReleased"
                                                                        href="#"
                                                                        onClick={() => changeStatusOfTalent( 21 )}
                                                                    >
                                                                        <Tooltip title="Offer Letter is released to the talent">
                                                                            <span>
                                                                                <FontAwesomeIcon
                                                                                    icon={faFileInvoiceDollar}
                                                                                    className="mr-2"
                                                                                />{" "}
                                                                                Offer Released
                                                                            </span>
                                                                        </Tooltip>
                                                                    </Dropdown.Item>

                                                                    <Dropdown.Item
                                                                        className="optionOnBoarded"
                                                                        href="#"
                                                                        onClick={() => {
                                                                            setChangeStatusModal( true );
                                                                            setStatusSelected( 13 );
                                                                        }}
                                                                    >
                                                                        <Tooltip title="The talent started the job">
                                                                            <span>
                                                                                <FontAwesomeIcon
                                                                                    icon={faFlagCheckered}
                                                                                    className="mr-2"
                                                                                />{" "}
                                                                                Onboarded
                                                                            </span>
                                                                        </Tooltip>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item
                                                                        className="optionDropped"
                                                                        href="#"
                                                                        onClick={() => {
                                                                            setChangeStatusModal( true );
                                                                            setStatusSelected( 19 );
                                                                        }}
                                                                    >
                                                                        <Tooltip title="Talent or Client dropped">
                                                                            <span>
                                                                                <FontAwesomeIcon
                                                                                    icon={faUserTimes}
                                                                                    className="mr-2"
                                                                                />{" "}
                                                                                Dropped
                                                                            </span>
                                                                        </Tooltip>
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        )}
                                                    </>
                                                ),
                                                tooltip: "",
                                                isFreeAction: false,
                                                onClick: () => null,
                                            },
                                            {
                                                icon: () => (
                                                    <div className={"btn btn-success btn-sm"}>
                                                        <FontAwesomeIcon
                                                            icon={faFileExport}
                                                            className="mr-2"
                                                        />
                                                        Export
                                                    </div>
                                                ),
                                                tooltip: "Export",
                                                isFreeAction: true,
                                                onClick: () => util.returnLinktoDownload( position_id ),
                                            },
                                            {
                                                icon: () => (
                                                    <div
                                                        className={"btn btn-primary btn-sm"}
                                                        id="btnAddTalent"
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faPlusCircle}
                                                            className="mr-2"
                                                        />
                                                        Add Talent
                                                    </div>
                                                ),
                                                tooltip: "Add Talent",
                                                isFreeAction: true,
                                                hidden: position.status === 3 || position.status === 4,
                                                onClick: () => addCandidate(),
                                            },
                                            {
                                                icon: () => (
                                                    <div
                                                        className={"btn btn-info btn-sm"}
                                                        id="btnSubmitTalent"
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faThumbsUp}
                                                            className="mr-2"
                                                        />
                                                        Submit Talent
                                                    </div>
                                                ),
                                                tooltip: "Submit Talent",
                                                isFreeAction: true,
                                                hidden: position.status === 3 || position.status === 4,
                                                onClick: () => submitTalentsModal(),
                                            },
                                            {
                                                tooltip: "Remove All Selected Candidates",
                                                icon: () => <FontAwesomeIcon icon={faTrashAlt} />,
                                                onClick: () => {
                                                    setDeleteTalentsShow( true );
                                                    // deleteCandidates(data)
                                                },
                                            },
                                            !util.canUseNewMethod( position.createdAt ) && {
                                                tooltip: "Stop Process",
                                                icon: () => (
                                                    <div className={"btn JobDetailButtonOrange btn-sm"}>
                                                        <FontAwesomeIcon icon={faBan} className="mr-2" />
                                                        Stop Process
                                                    </div>
                                                ),
                                                onClick: ( evt, data ) => {
                                                    let ids = [];
                                                    data.forEach( ( talent ) => {
                                                        ids.push( talent.id );
                                                    } );
                                                    setIds( ids );
                                                    setStopProcessTalentShow( true );
                                                },
                                            },
                                        ]}
                                        
                                        detailPanel={
                                            util.canUseNewMethod( position.createdAt )
                                                ? [
                                                    {
                                                        tooltip: "Candidate Details",
                                                        render: ( data ) => {
                                                            return (
                                                                <div className="screening-tab">
                                                                    <BasicTabs pcData={data} />
                                                                </div>
                                                            );
                                                        },
                                                    },
                                                ]
                                                : [
                                                    {
                                                        tooltip: "Timeline Process",
                                                        render: ( data ) => {
                                                            // console.log( "open" );
                                                            // return <TimeLineCandidate data={data} />;
                                                            return (
                                                                <>
                                                                    <TimeLineCandidate data={data} />
                                                                </>
                                                            );
                                                        },
                                                    },
                                                ]
                                        }
                                        localization={{
                                            toolbar: {
                                                searchPlaceholder: "Search by Name, Email and Phone",
                                            },
                                        }}
                                        title="Detail Panel With RowClick Preview"
                                        
                                        // detailPanel = {rowData => {
                                        //     return (
                                        //         <div className="screening-tab">                                 <BasicTabs />
                                        //         </div>
                                        //     );
                                        // }}
                                        // onRowClick={( event, rowData, togglePanel ) => togglePanel()}
                                        // onRowClick={( event, rowData, togglePanel ) => {
                                        //     togglePanel();
                                        // }}
                                    />
                                </MuiThemeProvider>
                                {/* </Tab> */}

                                {/* <Tab eventKey="list" title="List">
                                        <CandidateProgress data={props.candidates} position_id={position_id} />
                                    </Tab>
                                </Tabs> */}
                            </div>
                            {showDrawer ? (
                                <CtcDrawer
                                    showDrawer={showDrawer}
                                    closeDrawer={() => {
                                        setShowDrawer( false );
                                    }}
                                    jobTemp={talentRowData.job}
                                    talent={talentRowData}
                                    reloadData={() => showCandidatesOfPosition( position_id )}
                                />
                            ) : null}
                            <Fragment>
                                {showModalDocuments ? (
                                    <ListOnboardedTalents
                                        data={position}
                                        hideModal={() => ( {
                                            setShowModalDocuments: false,
                                        } )}
                                        show={showModalDocuments}
                                    />
                                ) : null}
                                {deleteTalentShow ? (
                                    <SweetAlert
                                        show={deleteTalentShow}
                                        warning
                                        showCancel
                                        confirmBtnText="Yes, remove it!"
                                        confirmBtnBsStyle="danger"
                                        title="Are you sure?"
                                        onConfirm={() => removeCandidateFromPosition()}
                                        onCancel={() => {
                                            setDeleteTalentShow( false );
                                        }}
                                        focusCancelBtn
                                    >
                                        Do you really want to remove this talent from the position?
                                    </SweetAlert>
                                ) : (
                                    ""
                                )}

                                {deleteTalentsShow ? (
                                    <SweetAlert
                                        show={deleteTalentsShow}
                                        warning
                                        showCancel
                                        confirmBtnText="Yes, remove it!"
                                        confirmBtnBsStyle="danger"
                                        title="Are you sure?"
                                        onConfirm={() => removeCandidateFromPosition()}
                                        onCancel={() => {
                                            setDeleteTalentsShow( false );
                                        }}
                                        focusCancelBtn
                                    >
                                        Do you really want to remove this talent from the position?
                                    </SweetAlert>
                                ) : (
                                    ""
                                )}

                                {changeStatusShow ? (
                                    <SweetAlert
                                        show={changeStatusShow}
                                        warning
                                        showCancel
                                        confirmBtnText="Yes, change it!"
                                        confirmBtnBsStyle="danger"
                                        title="Are you sure?"
                                        onConfirm={() => onConfirmChangeStatus()}
                                        onCancel={() => {
                                            setChangeStatusShow( false );
                                        }}
                                        focusCancelBtn
                                    >
                                        <div className="">
                                            You will change status of this job position from{" "}
                                            <b>{util.statusJP( position.status )}</b> to{" "}
                                            <b>{position.status === 1 ? "Hold" : "Active"}</b>.
                                        </div>
                                        <div>
                                            <br />
                                            <label htmlFor="sendEmailCheckbox">
                                                <input
                                                    id="sendEmailCheckbox"
                                                    type="checkbox"
                                                    className="mr-1"
                                                    name="sendEmailCheckbox"
                                                    onClick={sendEmailCheckbox}
                                                />
                                                Send email notification to talents
                                            </label>
                                        </div>
                                    </SweetAlert>
                                ) : (
                                    ""
                                )}

                                {changeStatusModal ? (
                                    <SweetAlert
                                        show={changeStatusModal}
                                        warning
                                        showCancel
                                        confirmBtnText="Yes!"
                                        confirmBtnBsStyle="warning"
                                        title="Are you sure?"
                                        onConfirm={() => changeStatusOfTalent( statusSelected )}
                                        onCancel={() => {
                                            setChangeStatusModal( false );
                                            setStatusSelected( null );
                                        }}
                                        focusCancelBtn
                                    >
                                        <div className="">This action cannot be undone</div>
                                    </SweetAlert>
                                ) : (
                                    ""
                                )}

                                {positionFilledShow ? (
                                    <SweetAlert
                                        show={positionFilledShow}
                                        warning
                                        showCancel
                                        confirmBtnText="Yes, is filled!"
                                        confirmBtnBsStyle="success"
                                        title="Are you sure?"
                                        onConfirm={() => onPositionFilled()}
                                        onCancel={() => {
                                            setPositionFilledShow( false );
                                        }}
                                        focusCancelBtn
                                    >
                                        <div className="">
                                            You will mark this job position as Filled
                                        </div>
                                        <div>
                                            <br />
                                            <label htmlFor="sendEmailCheckbox">
                                                <input
                                                    id="sendEmailCheckbox"
                                                    type="checkbox"
                                                    className="mr-1"
                                                    name="sendEmailCheckbox"
                                                    onClick={sendEmailCheckbox}
                                                />
                                                Send email notification to talents
                                            </label>
                                        </div>
                                    </SweetAlert>
                                ) : (
                                    ""
                                )}

                                {stopProcessShow ? (
                                    <SweetAlert
                                        show={stopProcessShow}
                                        warning
                                        showCancel
                                        confirmBtnText="Yes, stop it!"
                                        confirmBtnBsStyle="danger"
                                        title="Are you sure?"
                                        onConfirm={() => onConfirmStopProcess()}
                                        onCancel={() => setStopProcessShow( false )}
                                        focusCancelBtn
                                    >
                                        <div className="">You will stop this position.</div>
                                        <div>
                                            <br />
                                            <label htmlFor="sendEmailCheckbox">
                                                <input
                                                    id="sendEmailCheckbox"
                                                    type="checkbox"
                                                    className="mr-1"
                                                    name="sendEmailCheckbox"
                                                    onClick={sendEmailCheckbox}
                                                />
                                                Send email notification to talents
                                            </label>
                                        </div>
                                    </SweetAlert>
                                ) : (
                                    ""
                                )}

                                {stopProcessTalentShow ? (
                                    <StopProcess
                                        modalVisible={stopProcessTalentShow}
                                        hideModal={hideModalStopProcess}
                                        ids={ids}
                                        position_id={position_id}
                                    />
                                ) : (
                                    ""
                                )}
                                {reStartProcessTalentShow ? (
                                    <RestartProcess
                                        modalVisible={reStartProcessTalentShow}
                                        hideModal={hideModalReStartProcess}
                                        ids={ids}
                                        position_id={position_id}
                                    />
                                ) : (
                                    ""
                                )}
                            </Fragment>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
            <ModalAddCandidate
                position_id={position_id}
                hideModal={hideModal}
                modalVisible={modalVisible}
                refreshTalentTable={refreshTalentTable}
            />
            {submitModal ? (
                <SubmitTalentModal
                    show={submitModal}
                    closeHandler={() => setSubmitModal( false )}
                    candidates={props.candidates}
                    job={position}
                />
            ) : (
                ""
            )}

            <TeamCollaboration
                id={position_id}
                show={showTeamCollaborationDrawer}
                closeDrawer={() => setShowTeamCollaborationDrawer( false )}
            />
            {/* {
                showTeamCollaborationDrawer ?
                    <TeamCollaboration show={showTeamCollaborationDrawer} closeDrawer={()=> setShowTeamCollaborationDrawer( false )}  />
                    :
                    null
            } */}

            {util.LOADING_SCREEN( backDropOpen )}
        </Fragment>
    );
};

export default connect( mapStateToProps, mapDispatchToProps )( PositionDetail );

function mapStateToProps( state ) {
    return {
        candidates: state.jobPositionCandidates,
        position: state.jobPosition,
    };
}

function mapDispatchToProps( dispatch ) {
    return {
        setCandidates: ( data ) => dispatch( setCandidates( data ) ),
        setPosition: ( data ) => dispatch( setPosition( data ) ),
    };
}
