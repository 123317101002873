import React, {  Fragment, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import util from "../../../utils/miniUtils";
import { connect } from "react-redux";
import { setCandidates, setCandidateStatus } from "../../../../redux/actions/JobCandidatesAction";
import { CircularProgress } from "@material-ui/core";

const Step5 = ( props ) => {
    const [isLoading, setIsLoading] = useState( false );
    const [isDisabled, setIsDisabled] = useState( parseInt( props.candidate.status ) > 5 );

    const [result, setResult] = useState( props.candidate.background_check.result );
    const [date, setDate] = useState( props.candidate.background_check.date !== null && props.candidate.background_check.date !== "" ?
        props.candidate.background_check : new Date().toISOString().split( "T" )[0] );
    const [notes, setNotes] = useState( props.candidate.background_check.notes );
    const [startDate, setStartDate] = useState( props.candidate.background_check.startDate || "" );

    function handleChanges( e ) {
        const { name, value } = e.target;
        if ( name === "result" ) setResult( value );
        if ( name === "date" ) setDate( value );
        if ( name === "notes" ) setNotes( value );
    }

    async function saveNote() {
        try {
            setIsLoading( true );
            const candidate = props.candidate;
            if ( result === "" ) throw new Error( "Select a result" );
            if ( date === "" ) throw new Error( "Select a date" );
            if ( notes === "" ) throw new Error( "Write a note before clear this step" );
            const d = { result,date,notes };
            const request = await util.axios.post( `${util.url}/job_position/background_check_cleared/${candidate.position_id}/${candidate.candidate_id}`,d );
            const { error, msg } = request.data;
            if ( error ) return msg;
            util.toast().success( msg );
            setIsDisabled( true );
            props.setCandidateStatus( { id:candidate.candidate_id, status: 6, talent_desc_updated: "Backgorund Check Cleared" } );
            setIsLoading( false );
        } catch ( error ) {
            setIsLoading( false );
            util.handleError( error );
        }
    }

    if ( isLoading ){
        return <div className="text-center"><CircularProgress /></div>;
    }


    async function bgcStarted() {
        try {
            setIsLoading( true );
            const candidate = props.candidate;
            const request = await util.axios.post( `${util.url}/job_position/background_check_started/${candidate.position_id}/${candidate.candidate_id}` );
            const { error, msg, background_check } = request.data;
            setStartDate( background_check.startDate );
            if ( error ) return msg;
            util.toast().success( msg );
            props.setCandidateStatus( { id:candidate.candidate_id, status: 5.5, talent_desc_updated: "Backgorund Check Started" } );
            setIsLoading( false );
        } catch ( error ) {
            setIsLoading( false );
            util.handleError( error );
        }
    }

    return (
        <Fragment>
            <div className="row">
                <div className="col-auto text-center flex-column d-none d-sm-flex">
                    <div className="row h-50">
                        <div className="col">&nbsp;</div>
                        <div className="col">&nbsp;</div>
                    </div>
                    <h5 className="m-2">
                        <span
                            className={`badge badge-pill ${
                                isDisabled ? "bg-success" : "bg-light"
                            } border `}
                        >
              &nbsp;
                        </span>
                    </h5>
                    <div className="row h-50">
                        <div className="col border-right">&nbsp;</div>
                        <div className="col">&nbsp;</div>
                    </div>
                </div>
                <div className="col py-2">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title text-muted">Background Check {props.candidate.status === "5.5" ? `Started on ${startDate}`: ""}</h4>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td>Result</td>
                                        <td>
                                            <select
                                                value={result}
                                                name="result"
                                                onChange={handleChanges}
                                                className="form-control"
                                                disabled={isDisabled}
                                            >
                                                <option> -- </option>
                                                <option value="Positive">Positive</option>
                                                <option value="Negative">Negative</option>
                                                <option value="Conditional">Conditional</option>
                                                <option value="Unfinished">Unfinished</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Date</td>
                                        <td>
                                            <input
                                                onChange={handleChanges}
                                                type="date"
                                                value={date}
                                                name="date"
                                                className="form-control"
                                                disabled={isDisabled}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Notes</td>
                                        <td>
                                            <textarea value={notes} onChange={handleChanges} name="notes" className="form-control" disabled={isDisabled}    />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {isDisabled ? (
                                ""
                            ) : (
                                <Fragment>
                                    <button
                                        onClick={saveNote}
                                        className="btn btn-success btn-sm float-right"
                                    >
                                        <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />
                        Background Check cleared
                                    </button>
                                    {/* Background Check Started button only is displayed when status is 5 */}
                                    {props.candidate.status === "5.5" || parseFloat( props.candidate.status ) >= 6 ? ""
                                        :
                                        <button
                                            onClick={bgcStarted}
                                            className="btn btn-info btn-sm float-right mr-2"
                                        >
                                            <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />
                            Background Check Started
                                        </button>}
                                </Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
function mapDispatchToProps( dispatch ) {
    return{
        setCandidates:( data ) => dispatch( setCandidates( data ) ),
        setCandidateStatus:( data ) => dispatch( setCandidateStatus( data ) ),
    };
}


export default connect( null,mapDispatchToProps )( Step5 );
