import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles( {
    root: {
        color: '#1a7fe4',
        width:'100%' 
    },
    // valueLabel: {
    //     width: 50, 
    //     height: 50, 
    //     fontSize: 8,
    // },
    input: {
        width: 150,
    },
} );

function CustomSlider( { setFilter, filter } ) {
    const classes = useStyles();
    const [range, setRange] = useState( [filter.min, filter.max] );
    // const [minInput, setMinInput] = useState( range[0] );
    // const [maxInput, setMaxInput] = useState( range[1] );

    const handleSliderChange = ( event, newValue ) => {
        setRange( newValue );
        // setMinInput( newValue[0] );
        // setMaxInput( newValue[1] );
        setFilter( { ...filter, min: newValue[0], max: newValue[1], ready: true } );
    };

    const handleMinInputChange = ( event ) => {
        // setMinInput( event.target.value === '' ? '' : Number( event.target.value ) );
        setRange( [event.target.value === '' ? '' : Number( event.target.value ), range[1]] );
        setFilter( { ...filter, min: event.target.value, ready: true } );

    };

    const handleMaxInputChange = ( event ) => {
        // setMaxInput( event.target.value === '' ? '' : Number( event.target.value ) );
        setRange( [range[0], event.target.value === '' ? '' : Number( event.target.value )] );
        setFilter( { ...filter, max: event.target.value, ready: true } );

    };

    return (
        <div className={classes.root}>
            
            <Slider
                classes={{
                    root: classes.root,
                    // valueLabel: classes.valueLabel,
                }}
                value={range}
                onChange={handleSliderChange}
                // valueLabelDisplay="auto"
                aria-labelledby="range-slider"
            />
            <div className='row'>
                <div className='col-md-12'>
                    <TextField
                        variant='outlined'
                        label='min'
                        fullWidth
                        className={classes.input}
                        value={range[0]}
                        margin="dense"
                        onChange={handleMinInputChange}
                        inputProps={{
                            step: 1,
                            min: 0,
                            max: 10000000000,
                            type: 'number',
                            'aria-labelledby': 'min-slider',
                        }}
                    /></div>
                <div className='col-md-12'>
                    <TextField 
                        variant='outlined'
                        label='max'
                        className={classes.input}
                        value={range[1]}
                        margin="dense"
                        onChange={handleMaxInputChange}
                        inputProps={{
                            step: 1,
                            min: 0,
                            max: 10000000000,
                            type: 'number',
                            'aria-labelledby': 'max-slider',
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default CustomSlider;
