import React from 'react';

const ScreeningLogs = () => {
    const logs = [
        {
            id:1,
            modifiedBy: "modifier 1",
            timestamps: "Date1",
            status:'Status1',
            action: 'action 1',
            type: 'Lorem Ipsum is essentially unchanem'
        },
        {
            id:2,
            modifiedBy: "modifier 2",
            timestamps: "Date1",
            status:'Status1',
            action: 'action 2',
            type: 'Lorem Ipsum is essentially unchanem'
        },
        {
            id:3,
            modifiedBy: "modifier 3",
            timestamps: "Date1",
            status:'Status1',
            action: 'action 3',
            type: 'Lorem Ipsum is essentially unchanem'
        },
        {
            id:4,
            modifiedBy: "modifier 4",
            timestamps: "Date1",
            status:'Status1',
            action: 'action 4',
            type: 'Lorem Ipsum is essentially unchanem'
        },
    ];
    
    return (
        <>
            {logs.map( ( item ) => (
                <div key={ item.id } className='screening-logs'> 
                    <div>{item.timestamps}</div>
                    <div>{item.modifiedBy}</div>
                    <div>{item.status}</div>
                    <div>{item.action}</div>
                    <div>{item.type}</div>
                </div>
            ) )}
            
        </>
    );
};

export default ScreeningLogs;