import React, { Fragment, useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import ModalSelectPartnerClient from "./miniComponents/ModalSelectPartnerClient";
import util from "../../utils/miniUtils";
import selector from "../../utils/selector";
import { Paper, CircularProgress } from "@material-ui/core";
import { Redirect } from "react-router";
import FormJobPosition, { TitleAndLink } from "./FormJobPosition";
import CreateScreeningQuestions from "../../screeningQuestions/CreateScreeningQuestions";

const PARTNER = 4;

const NewRequirement = () => {
    const [modalVisible, setModalVisible] = useState( false );
    const [jobIndex] = useState( [] );
    const [modalType, setModalType] = useState( PARTNER );
    const [job, setJob] = useState( selector.job_position_base() );
    const [requirementID, setRequirementID] = useState( null );
    const [catalog_job_desc, setCatalog_job_desc] = useState( [] );
    const [isLoading, setIsLoading] = useState( true );
    const [addScreeningQuestions, setAddScreeningQuestions] = useState( false );
    const [jobCreated, setJobCreated] = useState( false );

    useEffect( () => {
        if( requirementID === null ) {
            getCatalog().then( null );
        }
    }, [] );

    const getCatalog = async () => {
    // Make a request for catalog of job descriptions
        try {
            const { data } = await util.axios.get(
        `${util.url}/app_resources/job_descriptions`
            );
            if ( data.error ) throw data.msg;
            setCatalog_job_desc( data.job_descriptions );
            setIsLoading( false );
        } catch ( error ) {
            util.handleError( error );
        }
    };
    const selectAction = ( modalOption ) => {
        setModalType( modalOption );
        setModalVisible( true );
    };
    const hideModal = () => {
        setModalType( PARTNER );
        setModalVisible( false );
    };
    const handleChange = ( name, value ) => {
        if ( name === "skills" ) {
            setJob( ( prevJob ) => ( { ...prevJob, skills: [...prevJob.skills, value] } ) );
        } else if ( name === "skills_delete" ) {
            setJob( ( prev ) => ( { ...prev, skills: [...value] } ) );
        } else {
            job[name] = value;
            // If country or State is selected "city" value should be removed
            if ( name === "country" || name === "state" ) job.city = "";
            setJob( { ...job, [name]: value } );
        }
    };
      
    
    const selectedData = ( opc, data ) => {
        const j = job;
        if ( opc === PARTNER ) {
            j.partner = data;
        } else {
            j.client = data;
        }
        setJob( { ...j, data } );
    };
    //Adding validation
    const isValidNewJobRequirement = () => {
        const msg = ( string ) => {
            return { pass: false, msg: `${string} is missing` };
        };
        if ( util.noValues.includes( job.job_title ) ) return msg( "Job Title" );
        if ( util.noValues.includes( job.job_description ) ) return msg( "Job Description" );
        if ( util.noValues.includes( job.duration ) )
            return msg( "Duration in months" );
        if ( util.noValues.includes( job.city ) ) return msg( "City " );
        if ( util.noValues.includes( job.sales_lead ) ) return msg( "Sales Lead" );
        if ( util.noValues.includes( job.lead_recruiter ) )
            return msg( "Lead Recruiter " );
        if ( job.deal_type === "Channel Partner" ) {
            if (
                util.noValues.includes( job.client ) ||
                util.noValues.includes( job.partner )
            )
                return msg( " Client and Partner detail" );
        } else if ( util.noValues.includes( job.client ) )
            return msg( " Client details" );

        return { pass: true };
    };

    //method for saving the new job to the list
    const saveJobOpening = async () => {
        const validation = isValidNewJobRequirement();
        if ( !validation.pass ) {
            return util.toast().error( validation.msg );
        }
        setIsLoading( true );
        try {
            const request = await util.axios.post( `${util.baseUrl()}/jobs/new`, {
                job: job,
            } );
            const { error, msg, requirement_id } = request.data;
            if ( error ) return util.toast().error( msg );
            util.toast().success( msg );
            console.log( requirement_id );
            setRequirementID( requirement_id );
        } catch ( error ) {
            util.handleError( error );
        }
        setIsLoading( false );
        // }
    };

    useEffect( () => {
        if( requirementID !== null ) {
            setJobCreated( true );
            console.log( requirementID );
        }
    },[requirementID] );
    
    if ( requirementID !== null && addScreeningQuestions === false ) {
        return <Redirect to={`/jobs/`} />;
    }
    return ( ( jobCreated && addScreeningQuestions === true ) ? <CreateScreeningQuestions jobId={requirementID} /> : <Fragment>
        <TitleAndLink />
        <Paper elevation={1} className={"mt-2"}>
            <div className="container-fluid">
                <div className="row">
                    {isLoading ? (
                        <Fragment>
                            <center className="col-md-12 m-5">
                                <CircularProgress />
                            </center>
                        </Fragment>
                    ) : (
                        <Fragment>
                            
                            <div className="col-md-12 pt-2">
                                <FormJobPosition
                                    catalog_job_desc={catalog_job_desc}
                                    job={job}
                                    index={jobIndex}
                                    selectAction={selectAction}
                                    handleChange={handleChange}
                                    addScreeningQuestions={addScreeningQuestions}
                                    setAddScreeningQuestions={setAddScreeningQuestions}
                                />
                                <div className="float-md-right float-sm-none m-2">
                                    {/*<button   className="btn btn-default mr-2" >CANCEL</button>*/}
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => saveJobOpening()}
                                    >
                      SAVE
                                    </button>
                                </div>
                            </div>
                        </Fragment>
                    )}
                </div>
                <ModalSelectPartnerClient
                    selectedData={selectedData}
                    hideModal={() => hideModal()}
                    modalVisible={modalVisible}
                    modalType={modalType}
                />
            </div>
        </Paper>
    </Fragment>
    );
};

export default NewRequirement;
