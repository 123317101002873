import util from "../../utils/miniUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faPauseCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";

export default function useEvaluationMethods( reloadTalents, evaluation_id ) {
	
    const [state, setState] = useState( {
        loading: false,
        valid: false, // Hide everything if haven't received and evaluationId
        job: null,
        talent:null,
        ser:null,
        clientEvaluation:null,
        skill:{ skill_name:"", years_experience:"" },
        skills:null,
        jobId:null,
        showLongDesc:false,
        showSA: false,
        showAddSkill:false
    } );
	
    async function getInterviewData( id ) {
        try {
            setState( { ...state, loading: true } );
            const req = await util.axios.get( `${util.url}/evaluator_jobs/interview_data/${id}` );
            const { error, msg, skills, job, talent, skillEvaluationResult, client_evaluation } = req["data"];
            if ( error ) throw msg;
            // Update all data that comes form server
            setState( {
                ...state,
                valid: true,
                job: job,
                talent: talent,
                ser: skillEvaluationResult,
                clientEvaluation: client_evaluation,
                skills: skills,
                isFinished: util.noValues.includes( skillEvaluationResult.token ) ||
					skillEvaluationResult["status"] === 2 ||
					!util.noValues.includes( skillEvaluationResult.final_decision ),
                loading: false,
                showAddSkill:false,
                showSA: false,
                skill: { skill_name:"", years_experience:"" }
            } );
        }catch ( e ) {
            setState( { ...state, loading: false } );
            util.handleError( e );
        }
    }
	
    function realFinalDecision() {
        switch ( parseInt( state.ser.final_decision ) ) {
            case 1: return <u className="text-success"><FontAwesomeIcon className="mr-2" icon={faCheckCircle} />Selected At Interview Level</u>;
            case 2: return <u className="text-danger"><FontAwesomeIcon className="mr-2" icon={faTimesCircle} />Rejected At Interview Level</u>;
            case 3: return <u className="text-warning"><FontAwesomeIcon className="mr-2" icon={faPauseCircle} />On Hold</u>;
            case 4: return <u className="text-success"><FontAwesomeIcon className="mr-2" icon={faCheckCircle} />Selected At Resume Level</u>;
            case 5: return <u className="text-danger"><FontAwesomeIcon className="mr-2" icon={faTimesCircle} />Rejected At Resume Level</u>;
            default: return "Unknown status";
        }
    }
	
    function checkThisOption( option ) {
        const ser = { ...state.ser };
        ser.final_decision = option;
        const t = { ...state };
        t.ser = ser;
        setState( Object.assign( {}, t ) );
    }
	
    function handleChanges( e ) {
        const { name, value } = e.target;
        const ser = { ...state.ser };
        const t = { ...state };
        ser[name] = value;
        t.ser = ser;
        setState( Object.assign( {}, t ) );
    }
	
    function handleChange( e ) {
        const { name, value } = e.target;
        const skill = { ...state.skill };
        skill[name] = value;
        setState( { ...state, skill: skill } );
    }
	
    async function submitEvaluationConfirmed() {
        try {
            if ( state.ser.final_decision === null ) throw new Error( "Select final decision" );
            if ( state.ser.notes === null ) throw new Error( "Please add some notes" );
            setState( { ...state, showSA: false, loading: true } );
            const request = await util.axios.put( `${util.url}/evaluator_jobs/skill_evaluation/finish?token=${state.ser.token}`, state.ser );
            const { error, msg } = request["data"];
            if ( error ) throw msg;
            // Reload list of talent to update status
            await reloadTalents();
            // Reload selected evaluation
            await getInterviewData( evaluation_id );
        } catch ( error ) {
            setState( { ...state, loading: false } );
            util.handleError( error );
        }
    }
	
    async function addNeSkill( evaluation_id ) {
        try {
            setState( { ...state, loading:true } );
            // Validate the new skill
            if ( state.skill.skill_name === "" ) throw new Error( "Please write the skill name" );
            if ( parseInt( state.skill.years_experience ) === 0 || state.skill.years_experience === "" ) throw new Error( "Please enter years of experience" );
			
            // Create the skill in the talent profile
            let skillNewFull = {};
            skillNewFull.candidate_id               = state.talent.id;
            skillNewFull.skill_type                 = 1;
            skillNewFull.rating                     = 0;
            skillNewFull.skill_name                 = state.skill.skill_name;
            skillNewFull.years_experience           = state.skill.years_experience;
            skillNewFull.skill_evaluation_result_id = evaluation_id;
			
            const request = await util.axios.put( `${util.url}/evaluator_jobs/add_skill/`, skillNewFull );
            const { error, msg } = request["data"];
            if ( error ) throw msg;
            await getInterviewData( evaluation_id );
            util.toast().success( msg );
        }catch ( e ){
            setState( { ...state, loading:false } );
            util.handleError( e );
        }
		
    }
	
    function resetNewSkill() {
        setState( { ...state, skill: { skill_name:"", years_experience:"" } } );
    }
	
    async function deleteSkill( index ) {
        try {
            if ( state.skills.length === 1 ) return util.handleError( "You must have at least one skill to evaluate in the interview" );
            setState( { ...state, loading:true } );
            let tempSkills = [...state.skills];
            let skillToDelete = state.skills[index];
			
            const request = await util.axios.post( `${util.url}/evaluator_jobs/remove_skill/`, skillToDelete );
            const { error, msg } = request["data"];
            if ( error ) throw msg;
            // If everything was ok, then delete the skill form the current array
            delete tempSkills[index];
            tempSkills = tempSkills.filter( s => s );
            let t = { ...state };
            t.skills = tempSkills;
            t.loading = false;
            setState( Object.assign( {}, t ) );
        } catch ( e ) {
            setState( { ...state, loading:false } );
            util.handleError( e );
        }
    }
	
    const styles = {
        buttonBlue: {
            background: '#5cb85c',
            color:"white"
        },
        buttonRed: {
            background: '#f50000',
            color:"white"
        },
    };
	
    return {
        state,
        setState,
        getInterviewData,
        submitEvaluationConfirmed,
        handleChanges,
        handleChange,
        checkThisOption,
        realFinalDecision,
        addNeSkill,
        resetNewSkill,
        deleteSkill,
        styles
    };
	
	
}