import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Button from "./button";
import "../../../assets/css/landingPage.css";

const MiddleRightContent = ( handleRedirect ) => {
    const title_1 = "Cool Features you Get";
    // const title_2 = "Enjoy 3 Recruiter Subscription at no cost for 45 days. Then pay just $15/month. Additional Recruiters for only $2 per month per Recruiter";
    const titleFontSize = 18;
    const titleColor = "black";
    const bulletColor = "#0071C1";
    const bulletPoints = [
        "Track all work in one place",
        "Link tasks and sub-tasks easily",
        "Use a modern drag-and-drop interface",
        "Create your own custom work types",
        "Create custom statuses for different work types",
        "Visualize individual and group workloads",
        "Visualize by priority and/or status",
        "Export reports and dashboards",
        "Gain easy insights to work status",
        "Track Response and Resolution Time SLAs",
        "Gain insights into all updates with time-stamps",
        "Collaborate easily across teams",
        "Enjoy Peace Of Mind From Encrypted Data"
    ];

    const titleStyle = {
        fontSize: titleFontSize,
        color: titleColor,
        fontWeight: "bold",
    };
    const titleStyle_2= {
        fontSize: 15,
        color: "#0071C1",
        fontWeight: "bold",
    };

    const buttonColor = "black";
    const buttonHoverColor = "#0071C1";
    const buttonText = "SIGN UP NOW";
    const buttonLink = "https://forms.gle/959LQDRkJnhNUGbe6";
    const buttonFontSize = "15px";

    const containerStyle = {
    };
    const buttonContainerStyle = {
        display: "flex",
        justifyContent: "center",
        marginTop: "20px", // Adjust the margin top as needed
    };

    return (
        <div style={containerStyle} className="RightSide">
            <h1 style={titleStyle}>{title_1}</h1>
            <ul style={{ listStyleType: "none", padding: 0 }} >
                {bulletPoints.map( ( point, index ) => (
                    <li key={index} style={{ display: "flex", alignItems: "center" }} className="keylist">
                        <FontAwesomeIcon
                            icon={faCheck}
                            style={{ color: bulletColor, marginRight: "5px" }}
                        />
                        <span style={{ color: "black", marginTop: "10px", fontSize: 15 }}>{point}</span>
                    </li>
                ) )}
            </ul>
            {/* <h1 style={titleStyle_2}>{title_2}</h1> */}
            <div style={buttonContainerStyle}>
                <Button
                    text={buttonText}
                    link={buttonLink}
                    color={buttonColor}
                    hoverColor={buttonHoverColor}
                    fontSize={buttonFontSize}
                    handleRedirect={handleRedirect}

                />
            </div>
        </div>
    );
};

export default MiddleRightContent;
