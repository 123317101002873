import React, { Fragment } from "react";
import { Modal } from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import TableOfTalent from "./TableOfTalent";


function JobDescription( props ) {
    // Display the title and description of the Job selected
    return (
        <Fragment>
            <Modal size={"xl"} show={props.jdModalVisible} onHide={props.jdHideModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.jobDescription.job_title}</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Tabs defaultActiveKey="talents"  id="uncontrolled-tab-example">
                        <Tab eventKey="talents" title="Talent">
                            <TableOfTalent data={props.jobDescription} />
                        </Tab>
                        <Tab eventKey="job_description" title="Job Description">
                            <div dangerouslySetInnerHTML={{ __html: props.jobDescription.job_description }}/>
                        </Tab>
                    </Tabs>
                </Modal.Body>
            </Modal>
        </Fragment>
    );
}

export default JobDescription;