import React, { Fragment, useState } from "react";
import LocationCX from "../../shared/LocationCX";
import TextField from "@material-ui/core/TextField";
import { PositionsStore } from "../stores/PositionsStore";
import selector from "../../utils/selector";

export default function AdvancedSearch( { makeSearch } ) {
	
    const position = PositionsStore.useState( state => state );
	
    // Start state with values form the store
    const [state, setState] = useState( {
        job_position: position.job_position,
        country: position.country,
        state: position.state,
        city: position.city,
        duration: position.duration,
        experience: position.experience,
        postedDaysAgo: position.postedDaysAgo,
    } );
	
    const onChange = ( e ) => {
        // Changes in AdvancedSearch should be saved also in the Store
        const { name, value } = e.target;
        // Update local state
        setState( {
            ...state,
            [name]: value
        } );
        // update store  data
        PositionsStore.update( s => {
            s[name] = value;
        } );
    };
	
    const onKeyDown = ( e ) => {
        if ( e.keyCode === 13 ){
            makeSearch();
        }
    };
	
    return(
        <Fragment>
            <div className="container">
                <div className="row text-center justify-content-center p-2">
                    <div className={`col-md-2 mt-1`}>
                        <TextField
                            variant="outlined"
                            onKeyDown={onKeyDown}
                            size={"small"}
                            label="Job Position"
                            type="text"
                            value={state.job_position ? state.job_position : ""}
                            name="job_position"
                            onChange={onChange}
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>
                    {/* Custom component for general location */}
                    <div className="col-md-7">
                        <LocationCX onChange={onChange}  data={state} />
                    </div>
					
                    <div className={`col-md-2 mt-1`}>
                        <TextField
                            select
                            variant="outlined"
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            name="postedDaysAgo"
                            className="w-100"
                            label="Last updated"
                            value={state.postedDaysAgo ? state.postedDaysAgo : 180} // By default  get 6 months
                            onChange={onChange}
                            SelectProps={{
                                native: true
                            }}
                        >
                            <option value=""/>
                            {selector.datesSelector?.map( ( option, i ) => (
                                <option key={i} value={option.value}>
                                    {option.postedDaysAgo}
                                </option>
                            ) )}
                        </TextField>
                    </div>
                    <div className={`col-md-1 mt-1`}>
                        <button
                            onClick={()=> makeSearch()}
                            className="btn btn-primary btn-sm align-self-auto"
                        >
							Search
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}