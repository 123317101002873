import React, { Fragment } from "react";
import { RECRUITER_MANAGER } from "../../../constants";
import util from "../../utils/miniUtils";
import CheckBoxSelector from '../../shared/checkboxSelector';


export default function SidebarFilter( { filterData, handleChange, updatedNewData, customTitle = "Recruiter" } ) {

  

    return (
        <Fragment>
            <div className="container-fluid fontSize13">
                <div className="row">
                    <div className="col-md-12 p-sm-0 m-sm-0">
                        <div className="text-left w-100">
                            <div className="sss">
                                <label className="w-100" htmlFor="startDate"> Start Date <br/><input className="form-control" value={filterData.startDate} type="date" onChange={handleChange}  id="startDate" name="startDate"/></label>
                            </div>
                            <div className="sss">
                                <label className="w-100" htmlFor="endDate"> End Date <br/><input className="form-control" value={filterData.endDate} type="date" onChange={handleChange}  id="endDate" name="endDate"/></label>
                            </div>
                        </div>
                        <hr className="p-2 m-0"/>
                    </div>
                    {
                        util.user.role === RECRUITER_MANAGER ?

                            <CheckBoxSelector
                                dataArray={filterData.recruiters} 
                                title={customTitle}
                                updateData={ ( data ) => updatedNewData( "recruiters", data ) }  
                                isOpenByDeafult={true}
                            />
                            :
                            null
                    }
                    <CheckBoxSelector
                        dataArray={filterData.countries} 
                        title="Country"
                        updateData={ ( data ) => updatedNewData( "countries", data ) }
                        isOpenByDeafult={util.user.role !== RECRUITER_MANAGER}
                    />
                    <CheckBoxSelector
                        dataArray={filterData.clients} 
                        title="Client"
                        updateData={ ( data ) => updatedNewData( "clients", data ) }  
                    />
                    <CheckBoxSelector
                        dataArray={filterData.partners} 
                        title="Partner"
                        updateData={ ( data ) => updatedNewData( "partners", data ) }  
                    />
                    {customTitle === "Recruiter" ? <CheckBoxSelector
                        dataArray={filterData.sales_lead} 
                        title="Sales Lead"
                        updateData={ ( data ) => updatedNewData( "sales_lead", data ) }  
                    /> : ""}
                </div>
            </div>
        </Fragment>
    );
}