import React, { Fragment, useEffect } from "react";
import util from "../../../../../utils/miniUtils";
import { MuiThemeProvider } from "@material-ui/core";
import MaterialTable from "material-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCommentAlt,
    faFileExport,
    faHandPaper,
    faPaperclip,
    faPencilAlt, faPlus,
    faThumbsDown,
    faThumbsUp
} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@material-ui/core/Tooltip";
import CommentBox from "../CommentBox";
import ButtonShowMoreInfo from "../ButtonShowMoreInfo";
import PreviousEvaluations from "../PreviousEvaluations";
import useMyTalents from "./useMyTalents";
import { CLIENT_ROLE } from "../../../../../../constants";

const DISLIKE = 0;
const LIKE = 1;
const HOLD = 2;


function MyTalents() {
	
    const { candidates,
        getTalentList,
        loading,
        field, feedback, handleClickLike, updateComment } = useMyTalents();
	
    useEffect( () => {
        getTalentList().then( null );
        // eslint-disable-next-line
    },[] );


    

	
    return(
        <Fragment>
            <MuiThemeProvider theme={util.defaultTableTheme}>
                <MaterialTable
                    isLoading={loading}
                    columns={[
                        {
                            title: "Job ID",
                            field: "positionId",
                            disableClick: true,
                            render: ( data ) => {
                                return util.zeroPad( data.positionId, 5 );
                            },
                        },
                        {
                            title: "Job Title",
                            field: "job_title",
                            disableClick: true,
                            render: ( data ) => {
                                return <span className="likeLink">{data.job_title}</span>;
                            },
                        },
                        {
                            title: "Name",
                            disableClick: true,
                            field: "name",
                            render: ( data ) => {
                                return (
                                    <Fragment>
                                        {data.resume ?
                                            <a href={util.resumeUrl( data )} className="text-decoration-none" target="new" >
                                                <FontAwesomeIcon icon={faPaperclip} className="mr-2" />
                                            </a>:
                                            ""
                                        }
                                        <span onClick={()=>{
                                        }} className="likeLink">
                                            {data.name}
                                        </span>
                                    </Fragment>
                                );
                            },
                        },
                        {
                            title: "Location",
                            field: "city",
                            sorting:false,
                            disableClick: true,
                            render: ( data ) => {
                                return data.jobLocation;
                            },
                        },
                        {
                            title: "Rate",
                            field: "sale_rate",
                            hidden: util.user.role === CLIENT_ROLE,
                            disableClick: true,
                            render: data => {
                                const ctc = JSON.parse( data.ctc_settings );
                                return util.currencyFormat( ctc.sellRatePerHour ) + "/" + ctc.offerType; 
								
                            }
                        },
                        {
                            title: "Status",
                            field: "status",
                            disableClick: true,
                            render: ( data ) => {
                                return util.candidateStatus( data.status, data["status_note"], );
                            },
                        },
                        {
                            title: "Test",
                            disableClick: true,
                            export:false,
                            sorting:false,
                            render: ( data ) => {
                                if ( data["numberOfTests"] === 0 ){
                                    return "";
                                }else{
                                    return (
                                        <div className="text-center">
                                            <ButtonShowMoreInfo data={data}/>
                                        </div>
                                    );
                                }
								
                            },
                        },
                        {
                            title: "My Evaluation",
                            export:false,
                            disableClick: true,
                            render: ( data ) => {
                                return (
                                    <Fragment>
                                        <div className="text-center">
                                            <button onClick={()=>handleClickLike( DISLIKE, data.candidate_id, data.position_id )}
											        className={`btn btn-default btn-sm ${data[field] === 0 ? "dislikeThumb":"defaultThumb"}`}>
                                                <FontAwesomeIcon
                                                    className="mr-2"
                                                    icon={faThumbsDown}
                                                />
                                            </button>
                                            <button onClick={()=>handleClickLike( LIKE, data.candidate_id, data.position_id )}
											        className={`btn btn-default btn-sm ${data[field] === 1 ? "likeThumb":"defaultThumb"}`}>
                                                <FontAwesomeIcon
                                                    className="mr-2"
                                                    icon={faThumbsUp}
                                                />
                                            </button>
											
                                            <Tooltip title="Hold with this talent">
                                                <button onClick={()=>handleClickLike( HOLD, data.candidate_id, data.position_id )}
												        className={`btn btn-default btn-sm ${data[field] === 2? "holdThumb":"defaultThumb"}`}>
                                                    <span>
                                                        <FontAwesomeIcon
	                                                    className="mr-2"
	                                                    icon={faHandPaper}
                                                        />
                                                    </span>
                                                </button>
                                            </Tooltip>
										
                                        </div>
                                    </Fragment>
                                );
                            },
                        },
                        {
                            title: "Previous Evaluation",
                            export:false,
                            field: "numberOfEvaluations",
                            disableClick:true,
                            render: ( data ) => {
                                if ( data["numberOfEvaluations"] === 0 ){
                                    return "";
                                }else{
                                    return (
                                        <div className="text-center">
                                            <PreviousEvaluations data={data} />
                                        </div>
                                    );
                                }
                            },
                        },
                        {
                            title: "Feedback",
                            export:false,
                            field: feedback,
                            render: ( data ) => {
                                if ( data[feedback] ){
                                    return <span>
                                        <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
                                        {util.suspenseDots( data[feedback] )}</span>;
                                }else{
                                    return <span>
                                        <button
	                                        className="btn btn-sm btn-primary">
                                            <FontAwesomeIcon icon={faCommentAlt} />
                                            <FontAwesomeIcon icon={faPlus} className="ml-2"/>
                                        </button></span>;
                                }
                            },
                        },
                    ]}
                    detailPanel={data => {
                        return <CommentBox comment={data[feedback]} talent={data} updateComment={updateComment}/>;
                    }}
                    onRowClick={( event, rowData, togglePanel ) => togglePanel()}
                    data={candidates}
                    options={{
                        sorting: true,
                        showTitle:false,
                        toolbarButtonAlignment: "left",
                        searchFieldAlignment:"left",
                        pageSize: 50,
                        pageSizeOptions:[50, 100, 150],
                        emptyRowsWhenPaging: false,
                    }}
                    actions={[
                        {
                            icon: () => (
                                <div className={"btn btn-success btn-sm"}>
                                    <FontAwesomeIcon icon={faFileExport} className="mr-2" />
                                    Export
                                </div>
                            ),
                            tooltip: "Export",
                            isFreeAction: true,
                            onClick: () =>util.tableToCSV()
                        },
                    ]}
                />
            </MuiThemeProvider>


            {/* HTML TABLE TO EXPORT */}
            <table id="#exportTable" className="displayOff">
                <thead>
                    <tr><td>Job ID</td>
                        <td>Job Title</td>
                        <td>Name</td>
                        {
                            util.user.role === CLIENT_ROLE ?
                                null:
                                <Fragment>
                                    <td>Sale rate</td>
                                    <td>Offer Type</td>
                                </Fragment>

                        }
                        
                        <td>Location</td>
                        <td>Status</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        candidates.length > 0 && candidates.map( ( c, i ) => {

                            const ctc = JSON.parse( c.ctc_settings );
                            return(
                                <tr key={i}>
                                    <td>{util.zeroPad( c.positionId, 5 )}</td>
                                    <td>{c.job_title}</td>
                                    <td>{c.name}</td>
                                    {
                                        util.user.role === CLIENT_ROLE ?
                                            null:
                                            <Fragment>
                                                <td>{ ctc.sellRatePerHour === null ? 0.0 : parseFloat( ctc.sellRatePerHour ) }</td>
                                                <td>{ctc.offerType}</td>
                                            </Fragment>

                                    }
                                    
                                    <td>{c.jobLocation}</td>
                                    <td>{util.candidateStatusToString( c.status )}</td>
                                </tr>
                            );
                        } )
                    }
                </tbody>
            </table>

        </Fragment>
    );
}

export default MyTalents;