import React, { Fragment, useState, useCallback } from "react";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBan,
    faCheckCircle,
    faPlusCircle,
    faSyncAlt,
    faTrashAlt,
    faUserCheck,
    faUserTimes,
    faCheckSquare,
    faCheck,
} from "@fortawesome/free-solid-svg-icons";
import util from "../../../../utils/miniUtils";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import {
    setCandidates,
    setCandidateStatus,
} from "../../../../../redux/actions/JobCandidatesAction";
import { CircularProgress, Select, TextField } from "@material-ui/core";
import BeelineDocuments from "./BeelineDocuments";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";


const Step6 = ( props ) => {
    const [isLoading, setIsLoading] = useState( false );
    const [candidate] = useState( props.candidate );
    const [status, setStatus] = useState( parseInt( props.candidate.status ) );
    // const [setIsDisabled] = useState(status === 10 || status === 12);
    let [checklist, setCheckList] = useState(
        props.candidate.final_decision.checklist
    );
    let [finalDecision, setFinalDecision] = useState(
        props.candidate.final_decision
    );
    const [, updateState] = useState();
    const forceUpdate = useCallback( () => updateState( {} ), [] );
  
    async function clientResolution( opc ) {
        try {
            setIsLoading( true );
            const request = await util.axios.post(
                `${util.url}/job_position/client_resolution/${candidate.position_id}/${candidate.candidate_id}/${opc}`
            );
            const { error, msg, final_status } = request.data;
            if ( error ) return msg;
            util.toast().success( msg );
            // // setIsDisabled(true);
            props.setCandidateStatus( {
                id: candidate.candidate_id,
                status: final_status,
                talent_desc_updated: msg,
            } );
            setStatus( parseInt( final_status ) );
      
            setIsLoading( false );
            forceUpdate();
        } catch ( error ) {
            setIsLoading( false );
            util.handleError( error );
        }
    }
  
    async function updateCheckList( array ) {
        try {
            const request = await util.axios.post(
                `${util.url}/job_position/update_checklist/${candidate.position_id}/${candidate.candidate_id}`,
                array ? array : checklist
            );
            const { error, msg } = request.data;
            if ( error ) return msg;
            props.setCandidateStatus( {
                id: candidate.candidate_id,
                status: 12,
                talent_desc_updated: msg,
            } );
        } catch ( error ) {
            util.handleError( error );
        }
    }
  
    function updateSelector( e ) {
    // This method will update the response of the select
        const { name, value } = e.target;
        const index = name.split( "_" )[1];
        let temp = checklist;
        temp[index].response = value;
        setCheckList( temp );
        forceUpdate();
        updateCheckList();
    }
  
    function onClickHandler( id ) {
    // This method will remove the item from the list
        let array = [];
        checklist.forEach( ( item ) => {
            if ( item.id !== id ) {
                array.push( item );
            }
        } );
        setCheckList( array );
        forceUpdate();
        updateCheckList( array );
    }
  
    function addItems( data ) {
        const temp = checklist;
        temp.push( data );
        setCheckList( Object.assign( [], temp ) );
        forceUpdate();
        updateCheckList( temp );
    }
  
    function handleChangerX( e ) {
    // This handle changes in the notes,
    // but the changes will be sended when the user blur the input field
        const { name, value } = e.target;
        const temp = checklist;
        const index = name.split( "_" )[1];
        temp[index].note = value;
        setCheckList( temp );
        forceUpdate();
    }
  
    if ( isLoading ) {
        return (
            <div className="text-center">
                <CircularProgress />
            </div>
        );
    }
  
    function getDotStatus( s ) {
        switch ( parseInt( s ) ) {
            case 11:
                return "bg-light";
            case 20:
                return "bg-danger";
            case 13:
                return "bg-success";
            case 6:
                return "bg-light";
            case 10:
                return "bg-danger";
            default:
                return "bg-info";
        }
    }
  
    return (
        <Fragment>
            <div className="row">
                <div className="col-auto text-center flex-column d-none d-sm-flex">
                    <div className="row h-50">
                        <div className="col">&nbsp;</div>
                        <div className="col">&nbsp;</div>
                    </div>
                    <h5 className="m-2">
                        <span className={`badge badge-pill ${getDotStatus( status )}`}>
              &nbsp;
                        </span>
                    </h5>
                    <div className="row h-50">
                        <div className="col border-right">&nbsp;</div>
                        <div className="col">&nbsp;</div>
                    </div>
                </div>
                <div className="col py-2">
            
                    {/* FINAL DECISION BUTTONS*/}
                    {status === 6 ? (
                        <div className="text-center">
                            <Button
                                onClick={() => clientResolution( 1 )}
                                variant="success"
                                className="mr-2"
                            >
                                <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />
                    Accepted
                            </Button>
                            <Button
                                onClick={() => clientResolution( 2 )}
                                variant="danger"
                            >
                                <FontAwesomeIcon icon={faBan} className="mr-1" />
                    Rejected
                            </Button>
                        </div>
                    ) : (
                        ""
                    )}
            
                    {[6].includes( parseInt( status ) ) ? "":
                        <Tabs defaultActiveKey="final_decision" mountOnEnter={true} unmountOnExit={true}>
                            <Tab eventKey="final_decision" title="Final decision">
                                {/*<h4 className="card-title text-muted">Final decision</h4>*/}
                                <div className="p-2">
                                    <div className="text-center">
                        
                                        {/*  Candidate is 10 */}
                                        {status === 10 ? (
                                            <Fragment>
                                                <h4 className="text-danger">
                                                    <FontAwesomeIcon icon={faUserTimes} /> Talent Rejected
                                                </h4>
                                            </Fragment>
                                        ) : (
                                            ""
                                        )}
                                        {/*  Candidate is 11 */}
                                        {status === 11 ? (
                                            <Fragment>
                                                <h4 className="text-success">
                                                    <FontAwesomeIcon icon={faUserCheck} /> Talent Accepted
                                                </h4>
                                            </Fragment>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                      
                                    {/*Only display the checklist if the user is accepted or onboarded*/}
                                    {status === 11 || status === 12 || status === 13 ? (
                                        <div className="p-2">
                                            <table className="table table-condensed tableCustomChecklist">
                                                <tbody>
                                                    {checklist.map( ( item, i ) => {
                                                        return (
                                                            <Row
                                                                key={i}
                                                                handleChangerX={handleChangerX}
                                                                item={item}
                                                                index={i}
                                                                onChange={updateSelector}
                                                                update={() => updateCheckList()}
                                                                delete={onClickHandler}
                                                            />
                                                        );
                                                    } )}
                                                </tbody>
                                            </table>
                                            <AddItem addItems={addItems} />
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    {status === 11 || status === 12 ? (
                                        <Fragment>
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        <th>Onboarding Date</th>
                                                        <td>
                                                            <input
                                                                className="form-control"
                                                                type="date"
                                                                name="date"
                                                                value={
                                                                    finalDecision.startDate === ""
                                                                        ? ""
                                                                        : finalDecision.startDate
                                                                }
                                                                onChange={onChangeDate}
                                                            />
                                                        </td>
                                                        <td>
                                                            <button
                                                                className="btn btn-sm btn-info"
                                                                onClick={onSetDate}
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={faSyncAlt}
                                                                    className="mr-2"
                                                                />
                                                                {finalDecision.startDate === ""
                                                                    ? "Save Date"
                                                                    : "Update Date"}
                                                            </button>{" "}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Fragment>
                                    ) : (
                                        ""
                                    )}
                                    {status === 13 ? (
                                        <div className="text-center">
                                            <h3 className="text-success">
                                                <FontAwesomeIcon icon={faCheck} /> Talent onboarded on{" "}
                                                {util.humanDate( finalDecision.startDate )}
                                            </h3>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                      
                                    {/* Button to finish process */}
                                    {status === 12 ? (
                                        <div className="text-center">
                                            <p>End process of this talent and the job position</p>
                                            <Button onClick={talentOnboarded}>
                                                <FontAwesomeIcon icon={faCheckSquare} className="mr-2" />
                              Talent onboarded
                                            </Button>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </Tab>
                            {candidate.job["isBeeline"] === 1 ?
                                <Tab eventKey="beeline_documents" title="Beeline Documents" >
                                    <BeelineDocuments  data={candidate} />
                                </Tab>
                                :""}
                        </Tabs>
                    }
          
                </div>
            </div>
        </Fragment>
    );
  
    async function talentOnboarded() {
    // Finish the process
        try {
            const request = await util.axios.post(
                `${util.url}/job_position/win_position/${candidate.position_id}/${candidate.candidate_id}`
            );
            const { error, msg } = request.data;
            // setIsDisabled(true);
            props.setCandidateStatus( {
                id: candidate.candidate_id,
                status: 13,
                talent_desc_updated: "Candidate on site",
            } );
            setStatus( 13 );
      
            if ( error ) return msg;
        } catch ( error ) {
            util.handleError( error );
        }
    }
  
    async function onSetDate() {
    // Send request to update date of onboarding
        try {
            const request = await util.axios.post(
                `${util.url}/job_position/checklist_update_onboarding_date/${candidate.position_id}/${candidate.candidate_id}`,
                { startDate: finalDecision.startDate }
            );
            const { error, msg } = request.data;
            if ( error ) return msg;
            util.toast().success( msg );
            props.setCandidateStatus( {
                id: candidate.candidate_id,
                status: 13,
                talent_desc_updated: "Onboarding date added",
            } );
        } catch ( error ) {
            util.handleError( error );
        }
    }
  
    function onChangeDate( e ) {
        const { value } = e.target;
        const temp = finalDecision;
        temp.startDate = value;
        setFinalDecision( temp );
        forceUpdate();
    }
};

const Row = ( props ) => {
    return (
        <tr>
            <td>
                <Select
                    fullWidth
                    native
                    name={`item_${props.index}`}
                    value={props.item.response}
                    onChange={props.onChange}
                >
                    <option value="">N/A</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </Select>
            </td>
            <td className="align-middle"> {props.item.title}</td>
            <td>
                <TextField
                    fullWidth
                    onChange={props.handleChangerX}
                    onBlur={props.update}
                    value={props.item.note}
                    name={`note_${props.index}`}
                    placeholder="Note"
                />
            </td>
            <td>
                <button onClick={() => props.delete( props.item.id )} className="btn">
                    <FontAwesomeIcon icon={faTrashAlt} />
                </button>
            </td>
        </tr>
    );
};

const AddItem = ( props ) => {
    const [name, setName] = useState( "" );
    const [note, setNote] = useState( "" );
    const [response, setResponse] = useState( "" );
  
    function handleChanger( e ) {
        const { name, value } = e.target;
        if ( name === "title" ) setName( value );
        if ( name === "note" ) setNote( value );
        if ( name === "response" ) setResponse( value );
    }
  
    function addItems() {
        const obj = {
            title: name,
            note,
            response,
            id: Math.round( Math.random() * 1210 ),
        };
        props.addItems( obj );
        setName( "" );
        setNote( "" );
        setResponse( "" );
    }
  
    return (
        <table className="table">
            <tbody>
                <tr>
                    <td>
                        <select
                            className="form-control"
                            onChange={handleChanger}
                            name="response"
                        >
                            <option value="">N/A</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </td>
                    <td>
                        <input
                            className="form-control"
                            value={name}
                            name="title"
                            placeholder="Title"
                            onChange={handleChanger}
                        />
                    </td>
                    <td>
                        <input
                            className="form-control"
                            value={note}
                            name="note"
                            placeholder="Notes"
                            onChange={handleChanger}
                        />
                    </td>
                    <td>
                        <button onClick={() => addItems()} className="btn btn-primary">
                            <FontAwesomeIcon icon={faPlusCircle} /> Add
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

function mapDispatchToProps( dispatch ) {
    return {
        setCandidates: ( data ) => dispatch( setCandidates( data ) ),
        setCandidateStatus: ( data ) => dispatch( setCandidateStatus( data ) ),
    };
}

export default connect( null, mapDispatchToProps )( Step6 );
