import MaterialTable from "material-table";
import React, { Fragment, useState } from "react";
import util from "../../../utils/miniUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import NewEducationDialog from "./NewEducationDialog";

export default function TableOfEducation( { data, talent_id, reloadData } ) {
	
    const [show, setShow] = useState( false );
    const [isEdit, setIsEdit] = useState( false );
    const [education, setEducation] = useState( {
        school: null,
        degree: null,
        fieldOfStudy: null,
        startYear: "",
        endYear: "",
        grade: "",
        activities: "",
    } );
    const [loading, setLoading] = useState( false );
	
    const startLoading = () => setLoading( true );
    const stopLoading = () => setLoading( false );
	
    const deleteEducation = async ( row ) => {
        try {
            startLoading();
            let { talent_id, id } = row;
            const response = await util.axios.delete( `talent/education_delete/${talent_id}/${id}/` );
            const { error, msg, data } = response["data"];
            if ( error ) throw new Error( msg );
            reloadData( data );
            util.toast().success( "Education Deleted" );
            stopLoading();
        } catch ( e ) {
            stopLoading();
            util.handleError( e );
        }
    };
	
    const saveNewEducation = async ( newEducationData ) => {
        try {
            startLoading();
            const d = {
                activities: newEducationData.activities,
                degree_id: newEducationData.degree.id,
                endYear: newEducationData.endYear,
                fieldOfStudy_id: newEducationData.fieldOfStudy.id,
                grade: newEducationData.grade,
                school_id: newEducationData.school.id,
                startYear: newEducationData.startYear,
            };
			
            const response = await util.axios.post( `talent/education_add/${talent_id}/`, d );
            const { error, msg, data } = response["data"];
            if ( error ) throw new Error( msg );
            reloadData( data );
            util.toast().success( "Education Created" );
            setEducation( {
                school: null,
                degree: null,
                fieldOfStudy: null,
                startYear: "",
                endYear: "",
                grade: "",
                activities: ""
            } );
            setShow( false );
            stopLoading();
        } catch ( e ) {
            stopLoading();
            util.handleError( e );
        }
    };
	
	
    const updateEducation = async ( editableData ) => {
        try {
            startLoading();
            // console.log( editableData );
			
            const d = {
                activities: editableData.activities,
                degree_id: editableData.degree.id,
                endYear: editableData.endYear,
                fieldOfStudy_id: editableData.fieldOfStudy.id,
                grade: editableData.grade,
                school_id: editableData.school.id,
                startYear: editableData.startYear,
            };
            // console.log( d );
            // return
            const response = await util.axios.put( `talent/education_update/${talent_id}/${editableData.id}`, d );
            const { error, msg, data } = response["data"];
            if ( error ) throw new Error( msg );
            reloadData( data );
            util.toast().success( "Education Created" );
            setEducation( {
                school: null,
                degree: null,
                fieldOfStudy: null,
                startYear: "",
                endYear: "",
                grade: "",
                activities: ""
            } );
            setShow( false );
            stopLoading();
        } catch ( e ) {
            stopLoading();
            util.handleError( e );
        }
    };
	
    const editEducationRecord = row => {
        setIsEdit( true );
        setEducation( row );
        setShow( true );
    };
	
	
    const handleChangeNewEducation = ( e ) => {
        const { name, value } = e.target;
        setEducation( { ...education, [name]: value } );
    };
	
    const hideModal = () => {
        setShow( false );
        setEducation( {
            school: null,
            degree: null,
            fieldOfStudy: null,
            startYear: "",
            endYear: "",
            grade: "",
            activities: ""
        } );
    };
	
	
    return (
        <Fragment>
            <MaterialTable
                isLoading={loading}
                columns={[
                    {
                        title: 'Start Year', field: 'startYear',
                        width: "10%",
                        render: row => {
                            return util.humanDate( row.startYear, true );
                        }
                    },
                    {
                        title: 'End Year', field: 'endYear',
                        width: "10%",
                        render: row => {
                            return util.humanDate( row.endYear, true );
                        }
                    },
                    {
                        title: 'School', field: 'school',
                        width: "5%",
                        sorting: false,
                        render: row => row.school.name
                    },
                    {
                        title: 'Degree', field: 'degree',
                        width: "5%",
                        sorting: false,
                        render: row => row.degree.name
                    },
                    {
                        title: 'Field Of Study', field: 'fieldOfStudy',
                        width: "5%",
                        sorting: false,
                        render: row => row.fieldOfStudy.name
                    },
                    {
                        title: 'Grade', field: 'grade',
                        width: "5%"
                    },
                    {
                        title: 'Activities', field: 'activities',
                        width: "5%"
                    },
                    {
                        title: '', sorting: false,
                        width: "5%",
                        render: row => {
                            return <FontAwesomeIcon icon={faPencilAlt} className="iconGray isPointer" onClick={()=>editEducationRecord( row )} />;
                        }
                    },
                    {
                        title: '', sorting: false,
                        width: "5%",
                        render: row => {
                            return (
                                <util.PopOverConfirmation
                                    callBackOk={() => deleteEducation( row )}
                                    callBackNo={() => console.log( "" )}
                                />
							
                            );
                        }
                    },
                ]}
                data={data}
                options={{
                    pageSize: 10,
                    sorting: true,
                    search: true,
                    showFirstLastPageButtons: true,
                    pageSizeOptions: [10, 30, 50, 100],
                    padding: "dense",
                    paginationType: "normal",
                    tableLayout: "auto",
                    addRowPosition: "first",
                    emptyRowsWhenPaging: false,
                    toolbar: true,
                    showTitle: false
                }}
                actions={[
                    {
                        icon: () => <FontAwesomeIcon icon={faPlus}/>,
                        isFreeAction: true,
                        tooltip: `Add new Education`,
                        onClick: () => {
                            setShow( true );
                        }
                    }
                ]}
            />
			
            <NewEducationDialog
                talent_id={talent_id}
                show={show}
                isEdit={isEdit}
                hideEducation={() => hideModal()}
                saveNewEducation={saveNewEducation}
                updateEducation={updateEducation}
                education={education}
                handleChangeNewEducation={handleChangeNewEducation}
            />
        </Fragment>
    );
}