import { MuiThemeProvider } from '@material-ui/core';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import JobDetail from './JobDetail';
import util from '../../../../../../utils/miniUtils';
import JobCard from './JobCard';
import SideBarFilter from '../../SideBarFilter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

const CardView = ( { list, filterState, setFilterState, reloadTableData, setTableState, page, totalCount, pageSize } ) => {
    const [selectedJob, setSelectedJob] = useState( {} );

    useEffect( () => {
        if ( filterState.ready ) {
            reloadTableData();
        }
    },[filterState] );

    return (
        <div className='row'>
            {  filterState.showFilter ?
                <div className='col-md-2'>
                    <div>
                        <SideBarFilter
                            reloadData={
                                ( filter )=> {
                                    setFilterState( prevState => ( {
                                        ...prevState,
                                        ...filter
                                    } ) );
                                }
                            }
                        />
                    </div>
                </div>
                :
                null
            }
            <div className={filterState.showFilter ? "col-md-10" : "col-md-12"}>
                <div className="row">
                    <div className='col-4'>
                        <MuiThemeProvider theme={util.defaultTableThemeSlim}>
                            <MaterialTable
                                columns={[
                                    {
                                        field: "job_title",
                                        render: p => {
                                            return <div className={selectedJob.id === p.id ? "bg-light p-1" : "p-1"}><JobCard job={p}/></div>;
                                        }
                                    }
                                ]}
                                totalCount={totalCount}
                                page={page}
                                data={list}
                                options={{
                                    pageSize: pageSize,
                                    sorting: false,
                                    showFirstLastPageButtons: true,
                                    pageSizeOptions: [],
                                    searchFieldAlignment:"left",
                                    toolbarButtonAlignment: "left",
                                    tableLayout:"fixed",
                                    // padding: "dense",
                                    search: true,
                                    paginationType: "normal",
                                    showTitle:false,
                                    emptyRowsWhenPaging:false,
                                    columnsButton: false,
                                    paging:true,
                                    paginationPosition:"bottom",
                                    maxBodyHeight:'70vh',
                                    debounceInterval:500,

                                }}
                                actions={[ {
                                    icon: () =>
                                        <span className="btn btn-info btn-sm">
                                            <FontAwesomeIcon icon={faFilter} />
                                        </span>
                                    ,
                                    position:"toolbar",
                                    isFreeAction: true,
                                    onClick: () => {
                                        if ( filterState.showFilter ) {
                                            setFilterState( { ...filterState, 
                                                startDate:"",
                                                endDate:"",
                                                countries:[],
                                                status:[],
                                                leadRecruiters:[],
                                                clients:[],
                                                partners:[],
                                                salesLead:[],
                                                showFilter: false 
                                            } );
                                        } else {
                                            setFilterState( { ...filterState, showFilter: true } );
                                        }
                                    }  
                                },]}
                                onRowClick={( event, rowData ) =>{
                                    setSelectedJob( rowData );
                                }}
                                onChangePage={page => {
                                    setTableState( prev => ( { ...prev, page: page } ) );
                                }}
                                onSearchChange={search => {
                                    setTableState( prev => ( { ...prev, search: search } ) );
                                }}
                            />
                        </MuiThemeProvider>
                    </div>
                    <div className='col-8'><JobDetail job={selectedJob} /></div>
                </div>
            </div>
        </div>
    );
};

export default CardView;