export const TALENT_ENGAGEMENT_TYPE = 1;
export const OFFER_TYPE = 2;
export const OFFER_TYPE_ESTIMATE = 3;
export const FULLTIME_EMPLOYEE = "Fulltime Employee";
export const CONTRACTOR = "Contractor";
export const CORP_TO_CORP = "Corp to Corp";
export const HOUR = "Hour";
export const HOURLY = "Hourly";
export const DAY = "Day";
export const DAILY = "Daily";
export const MONTH = "Month";
export const MONTHLY = "Monthly";
export const WEEKLY = "Weekly";
