import React, { Fragment, useState } from "react";
import util from "../../../../utils/miniUtils";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import DrawerTestInformation from "../../../../talents/components/detailComponents/test_talent/DrawerTestInformation";


export default function ButtonShowMoreInfo( { data:{ candidate_id, numberOfTests } } ) {
	
    const [tests, setTests] = useState( [] );
    const [test, setTest] = useState( null );
    const [loading, setLoading] = useState( false );
    const [showDrawer, setShowDrawer] = useState( false );
	
	
    const getTests = async () => {
        try {
            setLoading( true );
            const response = await util.axios.get( `/tests_user/list/${candidate_id}` );
            const { error, msg, tests } = response.data;
            if ( error ) throw msg;
            setTests( tests );
            setLoading( false );
        } catch ( e ) {
            setLoading( false );
            util.handleError( e );
        }
    };
	
    const openDrawer = ( t ) => {
        setTest( t );
        setShowDrawer( true );
    };
	
    return(
        <Fragment>
            <OverlayTrigger
                trigger="click"
                placement="right-start"
                rootClose={true}
                rootCloseEvent="click"
                overlay={
                    <Popover id={`popover-positioned-right`}>
                        <Popover.Title as="h3">Tests Results</Popover.Title>
                        <Popover.Content>
                            <table className="tableCustom">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Test</th>
                                        <th>Score</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tests?.map( ( t,i )=>{
                                        return(
                                            <tr key={i}>
                                                <td >
                                                    {util.humanDate( t.createdAt, true )}
                                                </td>
                                                <td onClick={()=> openDrawer( t )} className="likeLink">
                                                    {t.testName}
                                                </td>
                                                <td>{t.score}/{t.numQuestions}</td>
                                            </tr>
                                        );
                                    } )}
                                </tbody>
                            </table>
                        </Popover.Content>
                    </Popover>
                }
            >
                <button onClick={getTests} className="btn btn-sm btn-primary simpleBlankButton">View Test</button>
            </OverlayTrigger>
            {util.LOADING_SCREEN( loading )}
			
            <DrawerTestInformation
                data={test}
                showDrawer={showDrawer}
                closeDrawer={() => setShowDrawer( !showDrawer )}
            />
				
        </Fragment>
    );
}