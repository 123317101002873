import React, { useEffect, useState } from 'react';
import { Slider } from "@material-ui/core";
import util from '../../../../../utils/miniUtils';
import { InputGroup } from 'react-bootstrap';


export default function ByMargin( { ctc, onChange, job } ) {
    
    const [editValue] = useState( false );
    const [sell_margin, setSellMargin] = useState( ctc.sell_margin );
    
    useEffect( () => {
        setSellMargin( ctc.sell_margin.toFixed( 2 ) );
    }, [ctc.sell_margin] );
  
    const updateValue = ( e ) => {
        if ( e.key === "Enter" ) {
        // setEditValue(false);
            onChange( null, parseFloat( e.target.value ) );
        }
    };
  
    const onChangeLocal = ( e ) => {
        // Take of empty and values greater than 99 and lower that 0
        let { value } = e.target;
        if ( value === "" ) {
            value = 0;
        } else if ( parseFloat( value ) > 99.99 || parseFloat( value ) < 0 ) {
            return;
        } else {
            setSellMargin( value );
        }
    };
  
    // console.log( ctc.sell_margin );
  
    return (
        <table className="table table-condensed mb-0">
            <tbody>
                <tr>
                    {/*<th className="text-left">Margin</th>*/}
                    <td colSpan={2} className="text-center">
                        <div className="row d-flex align-content-center align-items-center">
                            <div className="col-md-6">
                                <Slider name="sell_margin" value={ctc.sell_margin ? ctc.sell_margin : 0} onChange={onChange} max={99} />
                            </div>
                            <div className="col-md-2">
                                <b>Sell Margin</b>
                            </div>
                            <div className="col-md-4">
                                <InputGroup className="mb-3">
                                    {editValue ? (
                                        <input className="form-control" value={!util.noValues.includes( sell_margin ) ? sell_margin : 0} name="sell_margin" onKeyUp={updateValue} onChange={onChangeLocal} />
                                    ) : (
                                        <input
                                            readOnly
                                            className="form-control"
                                            value={!util.noValues.includes( sell_margin ) ? parseFloat( sell_margin ).toFixed( 2 ) : 0}
                                            // onMouseEnter={( ) => {
                                            //     setEditValue( true );
                                            // }}
                                        />
                                    )}
                                    <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                                </InputGroup>
                            </div>
                        </div>
                        <hr className="m-1" />
                    </td>
                </tr>
                <tr>
                    <th className="text-left">Sell rate Per Hour</th>
                    <td className="text-right"> {util.currencyFormat( ctc.sellRatePerHour, job.country )}</td>
                </tr>
                <tr>
                    <th className="text-left">Sell Rate Per Day</th>
                    <td className="text-right">{util.currencyFormat( ctc.sellRatePerDay, job.country )}</td>
                </tr>
                <tr>
                    <th className="text-left">Sell Rate Per Month</th>
                    <td className="text-right">{util.currencyFormat( ctc.sellRatePerMonth, job.country )}</td>
                </tr>
                <tr>
                    <th className="text-left">Sell Rate Per Annum</th>
                    <td className="text-right">{util.currencyFormat( ctc.sellRatePerAnnum, job.country )}</td>
                </tr>
            </tbody>
        </table>
    );
}
  