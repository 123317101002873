import { useState } from 'react';
import util from './../utils/miniUtils';

function useAppraisal () {
    const [role, setRole] = useState( [] );
    const [loading, setLoading] = useState( true );

    // Request for user permissions
    
    const getPermissions = async() => {
        try {
            setLoading( true );
            const request = await util.axios.get( `/get_permissions` );
            const { error, data, msg } = request.data;
            if ( error ) throw msg;
            setRole( data );
            setLoading( false );
        } catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }
    };
    return {
        role, getPermissions, loading
    };

}
export default useAppraisal;