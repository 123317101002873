import React from 'react';
import { Grow } from "@material-ui/core";

export default function SaveChangesButton( { showSaveButton, saveChangesOnMarginEstimate } ) {
    return (
        <Grow in={showSaveButton} style={{ transformOrigin: "0 0 0" }} {...( showSaveButton ? { timeout: 200 } : {} )}>
            <div className="text-right">
                <button onClick={saveChangesOnMarginEstimate} className="btn btn-sm btn-primary">
                    {/* <FontAwesomeIcon icon={faSave} className="mr-2" /> */}
          Save Changes
                </button>
            </div>
        </Grow>
    );
}
