import React, { Fragment } from "react";
import SweetAlert from "react-bootstrap-sweetalert";

function SAlert( { show, title, confirmText, typeButton, msg, hideAlert, opc, onConfirm, showCancel } ) {
    return(
        <Fragment>
            <SweetAlert
                show={show}
                warning
                showCancel={showCancel ? showCancel: true}
                confirmBtnText={confirmText || "YES" }
                confirmBtnBsStyle={typeButton || "danger"}
                title={title ? title:"Are you sure?"}
                onConfirm={()=>onConfirm()}
                onCancel={()=>hideAlert( opc )}
                focusCancelBtn
            >
                {msg}
            </SweetAlert>
        </Fragment>
    );
}

export default  SAlert;
