import { MuiThemeProvider } from "@material-ui/core";
import util from "../../../../utils/miniUtils";
import MaterialTable from "material-table";
import React, { Fragment, useEffect, useState } from "react";


function ReportDocumentStatus( { job_id } ) {
	
    const [state, setState] = useState( {
        data:{},
        loading: false,
        showAlert: false,
        talents:[],
        columns:[]
    } );
	
    useEffect( () => {
        if ( job_id > 0 ){
            getOnboardedTalents().then( null );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[job_id] );
	
    const getOnboardedTalents = async () => {
        try {
            setState( { ...state, loading: true } );
            const request = await util.axios.get( `${util.url}/job_process/onboarded_talents/report/${job_id}` );
            const { error, msg, talents, columns } = request.data;
            if ( error ) return msg;
            const fixedColumns = createColumns( columns );
            setState( { ...state,
                talents: Object.assign( [], talents ),
                columns: fixedColumns,
                loading: false } );
        } catch ( error ) {
            setState( { ...state, loading: false } );
            util.handleError( error );
        }
    };
	
    function createColumns( data ) {
        let cols = [
            {
                field: "name",
                title: "Talent"
            },
        ];
		
        function statusFromServer( d,c ) {
            if ( d[c].includes( "Sent on" ) ){
                return <div className="text-center text-info border-dark">{d[c]}</div>;
            }
            if ( d[c] === "Pending" ){
                return <div className="text-center text-burlywood border-dark">{d[c]}</div>;
            }
            if ( d[c].includes( "Completed" ) ){
                return <div className="text-center text-success border-dark">{d[c]}</div>;
            }
        }
		
        data.forEach( c => {
            cols.push(
                {
                    field: c,
                    title: c,
                    render: d => {
                        return statusFromServer( d, c );
                    }
                },
            );
        } );
		
        return cols;
    }
	
    return(
        <Fragment>
            <MuiThemeProvider theme={util.defaultTableTheme}>
                <MaterialTable
                    columns={state.columns}
                    data={state.talents}
                    options={{
                        pageSize: 10,
                        tableLayout: "auto",
                        showFirstLastPageButtons: false,
                        pageSizeOptions: [],
                        toolbar:false,
                        padding: "dense",
                        paginationType: "stepped",
                        selection:false,
                        emptyRowsWhenPaging:false
                    }}
                />
            </MuiThemeProvider>
            {util.LOADING_SCREEN( state.loading )}
        </Fragment>
    );
}

export default ReportDocumentStatus;