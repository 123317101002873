
import React, { useEffect, useState } from "react";
import { Chip, IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";

const SkillSelector = ( { getSkillList, list, updateFilter, filteredSkills, setSkillsList } ) => {
    const [skillName, setSkillName] = useState( '' );

    const handleChange = ( e ) => {
        setSkillName( e.target.value );
    };
    const handleClick = ( val ) => {
        if( !filteredSkills.some( ( item )=> item.id === val.id ) ) {
            // updateFilter( prev => ( { ...prev, skills:[...prev.skills, val], ready: true } ) );
            updateFilter( "skills", val );
        }
    };

   
    const deleteSkills = ( val ) => {
        const temp = filteredSkills.filter( item => item.id != val );
        // updateFilter( prev => ( { ...prev, skills: [...temp] } ) );
        updateFilter( "skills_delete", temp );
    };

    const handleReset = () => {
        // updateFilter( prev => ( { ...prev, skills: [...temp] } ) );
        setSkillName( '' );
        setSkillsList( [] );
        
    };

    useEffect( () =>{
        if( skillName ) {
            getSkillList( skillName );
        }
    },[skillName] );

    return(
        <div>
            
            <div>{filteredSkills && filteredSkills.length > 0 ? filteredSkills.map( el => <Chip size="small" label={el.name} key={el.id} value={el} name="skills"className="mr-1 mb-1" onDelete={() => deleteSkills( el.id )} /> ) : ''}</div>
            <div>
                <TextField className="form-control" variant="standard" type="text" value={skillName} onChange={handleChange} label="Required Skills"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleReset}>
                                    <Clear />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                {/* <Autocomplete
                    freeSolo
                    value={skillName}
                    disableClearable
                    onChange={( e, value ) => handleClick( value )}
                    options={list.map( ( option ) => option.name )}
                    renderInput={( params ) => (
                        <TextField
                            {...params}
                            label="Required Skills"
                            margin="normal"
                            name="skillName"
                            // value={job.job_title}
                            onChange={handleChange}
                            variant="standard"
                            InputProps={{ ...params.InputProps, type: "search" }}
                        />
                    )}
                /> */}
            </div>
            <ul className="skillsSelectorList">{list.length ? list.map( el => <li className="skillsSelectorList-item" key={el.id} value={el} name="skills" onClick={() => handleClick( el )}>{el.name}</li> ) : ''}</ul>
        </div>
    );
};

export default SkillSelector;
