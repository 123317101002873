import React from 'react';
import { OFFER_TYPE_ESTIMATE } from "./constants";
import FieldValue from "./FieldValue";
import Input from "./input";
import SelectorCTC from "./SelectorCTC";

export default function BySellRate( { ctc, onChange, job } ) {
    return (
        <div>
            <Input isCurrency={true} job={job}  title="Sell Rate" name="sellRateValue" value={ctc.sellRateValue} onChange={onChange} />
            <SelectorCTC
                typeSelector={OFFER_TYPE_ESTIMATE}
                title="Offer Type Estimate"
                name="offerTypeEstimate"
                value={ctc.offerTypeEstimate}
                onChange={onChange}
            />
            <FieldValue name="Margin" value={ctc.sell_margin.toFixed( 2 )} extraIcon="%" />
        </div>
    );
}
