import React, { Fragment, useEffect, useState } from 'react';
import util from '../../../utils/miniUtils';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MaterialTable from "material-table";
import { faFilter, faTable } from "@fortawesome/free-solid-svg-icons";
import { MuiThemeProvider } from "@material-ui/core";
import Filter from './Filter';
import EmployeeModal from './EmployeeModal';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
const LocalStorageKey = "employeeColumns";
const tableRef = React.createRef();
const Employees = () => {


    const [showFilter, setShowFilter] = useState( false );
    const [selectedEmployee, setSelectedEmployee] = useState( {
        show: false,
        employee_id: null
    } );
    const [filterData, setFilterData] = useState( {

    } );
    const [columns,setColumns]=useState( [
        {
            title: 'Employee ID', field: 'employeeId',
            render: d => <span onClick={()=>setSelectedEmployee( { ...selectedEmployee, employee_id: d.employeeId, show: true, user: d } )} className='likeLink'>{util.zeroPad( d.employeeId, 5 ) }</span>             
        },
        {
            title: 'Name', field: 'name', defaultSort: "asc"            
        },
        {
            title: 'Departments', field: 'assignedDepartments',
            render: d => {
                if ( d.assignedDepartments !== null ) return d.assignedDepartments.split( "," ).join( ", " );
            }            
        },
        {
            title: 'Groups', field: 'assignedGroups',
            render: d => {
                if ( d.assignedGroups !== null ) return d.assignedGroups.split( "," ).join( ", " );
            }           
        },
        {
            title: 'Engagement Type', field: 'engagement_type'               
        },
        {
            title: 'Evaluation Frequency', field: 'evaluation_frequency'               
        },
        {
            title: 'Job Class', field: 'job_class'               
        },
        {
            title: 'Job Role', field: 'job_role'               
        },
        {
            title: 'Job Title', field: 'job_title'               
        },
        {
            title: 'Probation Period', field: 'probation_period'               
        },
        {
            title: 'Time Zone', field: 'time_zone'               
        },
            
    ] );
    const [showColumns, setShowColumns] = useState( columns.map( ( c ) => c.field ) );
    const [order, setOrder] = useState( localStorage.getItem( LocalStorageKey ) );
    // const [dragging, setDragging] = useState( false );
  
    useEffect( () => {
        if ( order ) {
            setShowColumns( JSON.parse( order ) );
        }
    }, [order] );
  
    const handleSave = () => {
        localStorage.setItem( LocalStorageKey, JSON.stringify( showColumns ) );
    };
  
    const handleChange = ( event ) => {
        const field = event.target.value;
        if ( showColumns.includes( field ) ) {
            setShowColumns( showColumns.filter( ( c ) => c !== field ) );
        } else {
            setShowColumns( [...showColumns, field] );
        }
    };
    const tableColumns = columns.filter( ( c ) => showColumns.includes( c.field ) );

    function handleColumnDrag( sourceIndex, destinationIndex ) {
        setShowColumns( ( prevArray ) => {
            const newArray = [...prevArray];
            const temp = newArray[sourceIndex];
            newArray[sourceIndex] = newArray[destinationIndex];
            newArray[destinationIndex] = temp;
            return newArray;
        } );
    }

    const urlHandler = ( query,  opc = false )  => {
        // console.log( query );
        let orderby = ( query.orderBy ) ? query.orderBy.field:"name";
        let direction = ( query.orderDirection ==="" ) ? "asc": query.orderDirection;
        let search = query.search !== ""  ? query.search : "";
        let options = ( opc ) ? `&opt=${opc}`: "";
        // console.log( filterData );
        // send only checked true values
        let q = `advanced_filter=${showFilter}&`;
        const fixCheckedToUrlEncode = ( data = [], field, especial ) => {
            let pairs = [];
            data.map( ( value ) => {
                if ( value.checked === true ) {
                    // console.log( value );
                    let val = value.value;
                    if ( especial ) {
                        val = val === "Yes" ? 1: 0;
                    }
                    pairs.push( `${ field }=` + encodeURIComponent( val ) );
                }
            } );
            q+= pairs.join( "&" ) + "&";
        };
        //  TODO fijdjdij
        // fixCheckedToUrlEncode( filterData.assignedDepartments, 'assignedDepartments' );
        // fixCheckedToUrlEncode( filterData.assignedGroups, 'assignedGroups' );
        fixCheckedToUrlEncode( filterData.country, 'country' );
        fixCheckedToUrlEncode( filterData.assignedDepartments, 'assignedDepartments' );
        fixCheckedToUrlEncode( filterData.assignedGroups, 'assignedGroups' );
        fixCheckedToUrlEncode( filterData.job_role, 'job_role' );
        fixCheckedToUrlEncode( filterData.job_class, 'job_class' );
        fixCheckedToUrlEncode( filterData.elegible_for_bonus, 'elegible_for_bonus' );
        fixCheckedToUrlEncode( filterData.is_billable, 'is_billable', true );
        fixCheckedToUrlEncode( filterData.job_class, 'job_class' );
        fixCheckedToUrlEncode( filterData.job_role, 'job_role' );
        fixCheckedToUrlEncode( filterData.job_title, 'job_title' );
        fixCheckedToUrlEncode( filterData.l1ManagerName, 'l1ManagerName' );
        fixCheckedToUrlEncode( filterData.l2ManagerName, 'l2ManagerName' );
        fixCheckedToUrlEncode( filterData.l3ManagerName, 'l3ManagerName' );
        fixCheckedToUrlEncode( filterData.hrManagerName, 'hrManagerName' );
        fixCheckedToUrlEncode( filterData.time_zone, 'time_zone' );
        fixCheckedToUrlEncode( filterData.annual_bonus, 'annual_bonus' );
        q+=`&evaluation_frequency=${filterData.evaluation_frequency}`;
        return `${util.url}/employee/list?page=${query.page}&limit=${query.pageSize}&orderby=${orderby}&direction=${direction}&search=${search}&${q}${options}`;
    };
 
    const handleClose = () => {
        setSelectedEmployee( { show: false, employee_id:null } );
        tableRef.current && tableRef.current.onQueryChange( undefined ); 
    };
    
    useEffect( ()=>{
        tableRef.current && tableRef.current.onQueryChange( undefined );
    }, [filterData] );


    return ( 
        <Fragment>
            <div className="container-fluid p-0">
                <div className="row">
                    { showFilter && <Filter handleClose={()=> setShowFilter( false )} open={showFilter} reloadData={( d )=>setFilterData( d )} />}
                    <div className={`col-md-${showFilter ? 10:12}`}>
                        <MuiThemeProvider theme={util.defaultTableTheme}>
                            <MaterialTable
                                title="Users"
                                tableRef={tableRef}
                                columns={tableColumns}
                                onColumnDragged={handleColumnDrag}
                                data={query =>
                                    util.axios.get( urlHandler( query, true ) )
                                        .then( response => {
                                            const { employees, total, page, error, msg } = response.data;
                                            if ( error ) throw msg;
                                            // console.log( employees );
                                            return ( {
                                                data: employees,
                                                page: page,
                                                totalCount: total
                                            } );
                                        } )
                                        .catch( error=>{
                                            util.handleError( error );
                                        } )
                                }
                                options={{
                                    pageSize: 20,
                                    pageSizeOptions:[20, 10, 50, 100],
                                    sorting: true,
                                    selection: true,
                                    showSelectAllCheckbox:false,
                                    showTitle:false,
                                    exportButton:true,
                                    debounceInterval:500,
                                    toolbarButtonAlignment:"left",
                                    searchFieldAlignment:"left",
                                    // search:!state.showFilter ,
                                    tableLayout:"auto",
                                    headerStyle:{ position:'sticky',top:0 },
                                    maxBodyHeight:'450px',
                                }}
                                localization={{ toolbar:{ searchPlaceholder:"Search for partner..." } }}
                                actions={[
                                // {
                                //     icon: () => <div className="btn btn-primary btn-sm text-white"><FontAwesomeIcon  icon={faPlusCircle} /> Add User</div>,
                                //     tooltip: 'Add User',
                                //     isFreeAction: true,
                                //     // onClick: () => createNewUser()
                                // },
                                // {
                                //     icon: () => <div className="btn btn-danger btn-sm text-white"><FontAwesomeIcon  icon={faTrash} /> Delete</div>,
                                //     tooltip: 'Delete',
                                //     onClick: ( event, data ) => {
                                //         setData( data );
                                //         show.deactivateUser = true;
                                //         setShow( show );
                                //     },
                                // },
                                    { icon:()=><div>
                                        <div className="dropdown">
                                            <div type="text" id="dropdownMenuButton" data-toggle="dropdown" className={"btn"} aria-haspopup="false" aria-expanded="true" >
                                                <span className="ml-2 isCursor text-secondary">
                                                    <ViewColumnIcon title='Show/Hide Columns' className="iconGrey" />
                                                </span>
                                            </div>
                                            <div className="dropdown-menu drp " aria-labelledby="dropdownMenuButton" style={{ width:'500%' }}>
                                                        
                                                        
                                                {columns.map( ( c, index ) => {
                                                    const mycolumn = index % 2;
                                                    const myrow = Math.floor( index / 2 );
                                                    return(
                                                        <button className="dropdown-item"  key={c.field} style={{ gridColumn: `${mycolumn + 1}`, gridRow: `${myrow + 1}` }}>
                                                            <input
                                                                type="checkbox"
                                                                value={c.field}
                                                                checked={showColumns.includes( c.field )}
                                                                onChange={handleChange}
                                                            />
                                                    
                                                            <label className='ml-1'>{c.title}</label> 
                                                        </button>
                                                    );} )}
                                                <button  className="btn btn-success btn-sm mr-3 float-right" 
                                                    onClick={()=>{handleSave(); }}
                                                >
                                           Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>,
                                    isFreeAction: true,
                                    onClick: () => null
                                    },
                                    {
                                        icon: () =>
                                            <div className="btn btn-info btn-sm">
                                                <FontAwesomeIcon icon={faFilter} className="mr-2" />
                            Filter
                                            </div>
                                        ,
                                        position:"toolbar",
                                        isFreeAction: true,
                                        onClick: () => setShowFilter( !showFilter )
                                    },
                                ]}
                            />
                        </MuiThemeProvider>
                    </div>
                </div>
                
            </div>
            
            <EmployeeModal employee_id={selectedEmployee.employee_id} data={selectedEmployee.user} show={selectedEmployee.show} handleClose={handleClose} />
        </Fragment>
    );
};
 
export default Employees;