import React, { Fragment, useState } from "react";
import { ResumeStore } from "./ResumeStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

function SkillsResume() {
    
    const data = ResumeStore.useState( s => s.data );
    const datas = ResumeStore.useState( s => s.datas );
    const indexGlobal = ResumeStore.useState( s => s.index );
    
    const [skill, setSkill] = useState( { skill_name:"", years_experience:"" } );
    
    function handleChanges( e, index ) {
        const { name, value } = e.target;
        let t = { ...data };
        let sk = JSON.parse( JSON.stringify( data.skills ) );
        sk[index][name] = value;
        t.skills = sk;
        
        
        let datasTemp = [...datas];
        datasTemp[indexGlobal] = t;
        
        
        ResumeStore.update( s => {
            s.data =  t;
            s.datas = datasTemp;
        } );
    }
    
    function deleteSkill( index ) {
        let t = { ...data };
        let sk = JSON.parse( JSON.stringify( data.skills ) );
        delete sk[index];
        sk = sk.filter( function(){return true;} );
        
        t.skills = sk;
        
        let datasTemp = [...datas];
        datasTemp[indexGlobal] = t;
        
        ResumeStore.update( s => {
            s.data =  t;
            s.datas = datasTemp;
        } );
    }
    
    function handleChangesNew( e ) {
        const { name, value } = e.target;
        let temp = { ...skill, [name]:value };
        setSkill( temp );
    }
    
    function addSkill() {
        let t = { ...data };
        let sk = JSON.parse( JSON.stringify( data.skills ) );
        sk.push( skill );
        t.skills = sk;
        
        let datasTemp = [...datas];
        datasTemp[indexGlobal] = t;
        
        ResumeStore.update( s => {
            s.data =  t;
            s.datas = datasTemp;
        } );
        setSkill( { skill_name:"", years_experience:"" } );
    }
    
    return(
        <Fragment>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <table className="w-100 table-hover">
                            <thead>
                                <tr>
                                    <th>Skill Name</th>
                                    <th className="text-center">Years of Exp.</th>
                                    <th/>
                                </tr>
                            </thead>
                            <tbody>
                                {data.skills && data.skills.map( ( s,i ) =>{
                                    return <tr key={i} >
                                        <td className="p-1" width={"85%"}>
                                            <input
                                                size={"small"}
                                                value={s.skill_name}
                                                className="form-control"
                                                name="skill_name"
                                                onChange={( e )=>handleChanges( e, i )}
                                            />
                                        </td>
                                        <td width={"10%"}>
                                            <input
                                                size={"small"}
                                                value={s.years_experience ? s.years_experience : 0}
                                                type="number"
                                                name="years_experience"
                                                className="form-control text-right"
                                                onChange={( e )=>handleChanges( e, i )}
                                            />
                                        </td>
                                        <td width={"5%"} className="p-1">
                                            <FontAwesomeIcon className="isCursor text-black-50 text-danger redIcon" icon={faTimesCircle} onClick={()=> deleteSkill( i )} />
                                        </td>
                                    </tr>;
                                } )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-md-6">
                        <input
                            size={"small"}
                            value={skill.skill_name}
                            placeholder="Skill Name"
                            name="skill_name"
                            className="form-control"
                            onChange={handleChangesNew}
                        />
                    </div>
                    <div className="col-md-6">
                        <input
                            size={"small"}
                            value={skill.years_experience}
                            placeholder="Years of Experience"
                            type="number"
                            name="years_experience"
                            className="form-control text-right"
                            onChange={handleChangesNew}
                        />
                    </div>
                    <div className="col-md-12 text-right p-2">
                        {skill.skill_name !== "" || skill.years_experience !== "" ?
                            <button onClick={()=> setSkill( { skill_name:"", years_experience:"" } )}
                                className="btn btn-sm text-danger ">
                                Reset
                            </button>:""}
                        <button onClick={addSkill} className="btn btn-sm btn-primary">
                            <FontAwesomeIcon icon={faPlusCircle} className="mr-2"/> Skill
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default SkillsResume;