import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Dropdown, Modal } from 'react-bootstrap';
import util from '../../../utils/miniUtils';

const BulkStatusSelector = ( { candidates, positionId, getPositionCandidates } ) => {
    const [statusList, setStatusList] = useState( [] );
    // Comment Modal related state variables
    const [commentModalShow, setCommentModalShow] = useState( false );
    const [state, setState] = useState( { candidates: [...candidates], status: '', comment: "" } );

    const getStatusList = async () => {
        try{
            const request = await util.axios.get( 'job_talent_status/list' );
            const { data, error, msg } = request.data;
            if( error ) throw msg;
            setStatusList( data.filter( el => el.is_parent == 1 ) );
        } catch ( e ) {
            util.handleError( e );
        }
    };

    const handleStatusSlection = ( value ) => {
        handleDropDown( value.jts_id );
    };

    // const handleStopProcess = () => {
    //     setDeleteTalentsShow( true );
    // };
    
    const handleDropDown = ( value ) => {
        setState( prev => ( { ...prev, status: value } ) );
        setCommentModalShow( true );
    };

    const handleCommentChange = ( e ) => {
        const { value } = e.target;
        setState( prev =>( { ...prev, comment: value } ) );
    };
    
    useEffect( () => {
        getStatusList();
    },[positionId] );

    useEffect( () => {
        if( candidates.length ) {
            setState( prev =>( { ...prev, candidates: [...candidates] } ) );
        }
    }, [candidates] );

    return <>                    
        <Dropdown as="div" className={`dropMenuStatus`}>
            <Dropdown.Toggle
                as="div"
                variant="success"
                id="dropdown-basic"
                className="customDropDownChangeStatus">
                <span className="text-white">
                    Change Status
                    <FontAwesomeIcon
                        icon={faExclamationCircle}
                        className="ml-2"
                    />
                </span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {statusList.map( el => ( <Dropdown.Item key={el.jts_id} eventKey={el.jts_id} onClick={() => handleStatusSlection( el )}>
                    {el.name}
                </Dropdown.Item> ) )}
            </Dropdown.Menu>
        </Dropdown>
        <CommentModal            
            show={commentModalShow} 
            handleClose={() => setCommentModalShow( false )} 
            form={state}
            positionId={positionId}
            handleChange={handleCommentChange}
            getPositionCandidates={getPositionCandidates}
        />
    </>;
};

export default BulkStatusSelector;

const CommentModal = ( { show, handleClose, form, positionId, handleChange, getPositionCandidates } ) => {
    const [statusName, setStatusName] = useState( '' );

    const getStatusName = async ( id ) => {
        try{
            const request = await util.axios.get( `/job_talent_status/byId/${id}` );
            const { data, error, msg } = request.data;
            if( error ) throw msg;
            setStatusName( data[0]?.name ); 
        } catch ( e ) {
            util.handleError( e );
        }
    };

    const updateCandidateStatus = async( updatedData, position_id ) => {
        try {
            const request = await util.axios.put( `/requirements/update_multiple_position_candidate_status/${position_id}`, updatedData );
            const { error, msg } = request.data;
            if( error ) throw msg;
            util.toast().success( msg );
            getPositionCandidates( position_id );
        } catch ( error ) {
            util.handleError( error );  
        }
        handleClose();
    };

    const handleSubmit = () => {
        updateCandidateStatus( form, positionId, );
    };

    useEffect( () => {
        if( show ) {
            getStatusName( form.status );
        }
    },[form.status] );

    return <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Add Comment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
                <span >Status will be updated to <strong>{statusName}</strong></span>
                <div>
                    <label className='mt-3' htmlFor='comment-box'>Comment</label>
                    <textarea className="form-control" id="comment-box" name="comment" value={form.comment} onChange={handleChange}/>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <span className="btn btn-sm btn-danger" onClick={handleClose}>Close</span>
            <span className='btn btn-sm btn-success' onClick={handleSubmit}>Submit</span>
        </Modal.Footer>
    </Modal>;
};