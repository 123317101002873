import React, { Fragment } from "react";
import NewRecuiterReport from "../manager_report/NewRecuiterReport";
// import ReportRecruiter from "../manager_report/ReportRecruiter";

export default function DailyReport(  ) {

    return (
        <Fragment>
            {/* <ReportRecruiter /> */}
            <NewRecuiterReport />
        </Fragment>
    );
}