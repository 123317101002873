
import React, { Fragment, useEffect, useState } from 'react';
//import { Collapse } from '@material-ui/core';
import CheckBoxSelector from "../../shared/checkboxSelector";
import util from "../../utils/miniUtils";
import CheckBoxStatusSelector from './CheckboxStatusSelector';

const statusList=util.fixStatusList();



const Filter = ( { reloadData } ) => {


    const [filter, setFilter] = useState( {
        loading: true,
        department: [],
        bonus:[{ value:!null,label:"Yes",id:1 },{ value:null,label:"No",id:2 }],
        status:statusList,
        period:[]
    } );

    const getFilterData = async ()=>{
        try {
            const request = await util.axios.get( `/appraisal/filter` );
            const { error, msg, data, period } = request.data;
            
            const dataArray=data && data.map( ( item,key )=>{
                return {
                    value: item.itemName,
                    id: item.id
                };
            } );
            const periodData=period && period.map( ( item,key )=>{
                return {
                    id: item.id,
                    value: item.period
                };
            } );
           
            setFilter( ( prevState ) => ( {
                ...prevState,
                loading: false,
                department: dataArray ,
                period: periodData
            } ) ); 

            if ( error ) throw msg;
           
        } catch ( error ) {
            util.handleError( error );
        }
    };

    
    useEffect( ()=>{
        getFilterData();
        // console.log( filter.department );
    },[] );

    useEffect( ()=>{
        // console.log( "Send new filter values to parent component" );
        reloadData( filter );
    }, [filter] );
    // console.log( filter );
    // if ( filter.loading ) {
    //     return util.LOADING_SCREEN( filter.loading );
    // }
    return ( 
        <Fragment >
            <div className="col-md-2">
               
                <CheckBoxSelector
                    isOpenByDeafult={true}
                    dataArray={filter.department}
                    title="Departments"
                    updateData={( data ) =>
                        setFilter( { ...filter,department: data, ready: true } )
                    }
                />
                <CheckBoxSelector
                    isOpenByDeafult={false}
                    dataArray={filter.period}
                    title="Periods"
                    updateData={( data ) =>
                        setFilter( { ...filter, period: data, ready: true } )
                    }
                />
                
                <CheckBoxStatusSelector
                    dataArray={filter.bonus}
                    title="Bonus"
                    updateData={( data ) =>
                        setFilter( { ...filter, bonus: data, ready: true } )
                    }
                /> 

                <CheckBoxStatusSelector
                    dataArray={filter.status}
                    title="Status"
                    updateData={( data ) =>
                        setFilter( { ...filter, status: data, ready: true } )
                    }
                /> 

              

                
            </div>
        </Fragment>
    );
};
 
export default Filter;