import React, { useState } from "react";
import { Route, Switch, Redirect, useParams, useRouteMatch } from "react-router-dom";
import WorkLoadTable from './components/WorkLoadTable';
import { Link } from "react-router-dom";
// import CTItable from "./components/cti/CTItable";
import WorkloadCategoryTable from "./components/workloadCategory/WorkloadCategoryTable";


const ManageWorkLoad = () => {
    // const [btnView, setBtnView] = useState( "workload" );
    // let { path, url } = useRouteMatch();

    // const showComponent = ( option ) => {
    //     switch ( option ) {
    //         case "workload":
    //             return <WorkLoadTable />;
    //         // case "cti":
    //         //     return <CTItable />;
    //         // case "sla":
    //         //     return <PriorityView />;
    //         // case "holidays":
    //         //     return <WorkloadCategoryTable />;
    //         case "workloadCategory":
    //             return <WorkloadCategoryTable />;
    //         default:
    //             return <WorkLoadTable />;
    //     }
    // };

    // function WorkLoadContainer() {
    //     let { component_selected } = useParams();
    //     setBtnView( component_selected );

    //     return (
    //         <div>
    //             {/* <h3>{component_selected}</h3> */}
    //             {showComponent( component_selected )}
    //         </div>
    //     );
    // }

    return (
        <div className="wl_manageContainer">
            <WorkLoadTable />
            {/* <div className=" wl-navLinkDiv d-flex align-items-center ml-3 mb-1">
                <Link
                    className={`btn mx-1 ${btnView === "workload" ? "btn-primary" : "btn-outline-primary"}`}
                    to={`${url}/workload`}>
                Workload
                </Link>

                <Link
                    className={`btn mx-1 ${btnView === "workloadCategory" ? "btn-primary" : "btn-outline-primary"}`}
                    to={`${url}/workloadCategory`}>
                Workload Category
                </Link> */}
					
            {/* <Link
                    className="btn btn-outline-primary mx-1"
                    to={`${url}/cti`}>
                CTI
                </Link> */}

            {/* <Link
                    className={`btn mx-1 ${btnView === "sla" ? "btn-primary" : "btn-outline-primary"}`}
                    to={`${url}/sla`}>
                SLA
                </Link>

                <Link
                    className={`btn mx-1 ${btnView === "holidays" ? "btn-primary" : "btn-outline-primary"}`}
                    to={`${url}/holidays`}>
                Holidays
                </Link> */}
                
            {/* </div>
            <Switch>
                <Route exact path={path}>
                    <Redirect to={`${path}/workload`} />
                </Route>
				
                <Route path={`${path}/:component_selected`}>
                    <WorkLoadContainer />
                </Route>
            </Switch> */}
        </div>
    );
};

export default ManageWorkLoad;