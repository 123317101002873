import React from "react";
import { useHistory } from "react-router-dom";
import Button from "./content/button";
import "../../assets/css/landingPage.css";
import MiddleLeftContent from "./content/middleLeftContent";
import MiddleRightContent from "./content/middleRightContent";
import { center } from "react-dom-factories";
const LandingPage = () => {
    const history = useHistory();

    const handleRedirect = () => {
        history.push( "/app" );
    };
    const handleRedirect2 = () => {
        history.push( "https://forms.gle/959LQDRkJnhNUGbe6" );
    };
    const font = "Arial, sans-serif";
    const primaryColor = "#0071C1";
    const headerColor = "#DFE1F0";
    const contentBackgroundColor = "DFE1F0";
    const footerColor = "white";
    const buttonColor = "#0071C1";
    const buttonHoverColor = "green";
    const buttonText = "Play Video";
    const buttonLink = "#video";
    const buttonFontSize = "15px";

    return (
        <div>
            <header
                style={{
                    backgroundColor: primaryColor,
                    color: headerColor,
                }}
            >
                {/* Navbar Header */}
                <nav 
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "10px",
                    }}
                >
                    <div>
                        {/* Navbar Logo/Image */}
                        <img className="logo"
                            src="/images/demo_logo_white.png"
                            style={{ height: "60px", width: "auto", paddingLeft: "40px" }}
                        />
                    </div>
                    <div className="item">
                        {/* Navbar Links */}
                        <a className="font"
                            href="/not_found"
                            style={{
                                marginLeft: "10px",
                                marginRight: "10px",
                                textDecoration: "none",
                                color: "white",
                                fontWeight: "bold",
                               

                            }}
                        >
            MY ACCOUNT
                        </a>
                        <a
                            href="https://app.ejrekruit.com/"
                            style={{
                                marginLeft: "10px",
                                marginRight: "10px",
                                textDecoration: "none",
                                color: "white",
                                fontWeight: "bold",
                                

                            }}
                        >
            SEARCH JOBS
                        </a>
                    </div>
                </nav>
            </header>

            <main
                style={{
                    display: "flex",
                    justifyContent: "center",
                    background: 'rgb(223, 225, 240)',
                }}
                className="back">
                <section className="backLeft"
                    style={{
                        backgroundColor: headerColor,
                        padding: "20px",
                        // width: "50%",
                    }}
                >
                    {/* Left Content */}
                    <MiddleLeftContent handleRedirect={handleRedirect}/>
                    {/* Add your left content here */}
                </section>

                <section className="backRight"
                    style={{
                        backgroundColor: headerColor,
                        padding: "20px",
                        // width: "30%",
                    }}
                >
                    {/* Right Content */}
                    <MiddleRightContent handleRedirect={handleRedirect2}/>
                </section>
            </main>


            <footer
                style={{
                    backgroundColor: "black",
                    color: "white",
                    marginTop: "auto",
                    
                }}
            >
                {/* Footer */}
                <nav
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "10px",
                    }}
                    className="info2">
                    <div >
                        {/* Footer Logo/Image */}
                        <img
                            src="/images/eJAmerica-white.png"
                            alt="Footer Logo"
                            style={{ height: "30px", marginRight: "10px" }}
                        />
                    </div>
                    <div style={{ display: "flex" }} className="info2">
                        {/* Address */}
                        <div className="connect" style={{ marginRight: "20px", fontFamily: 'sans-serif', color: "#5a5c76" }}>
                            <h3>Address</h3>
                            <p>100 SPECTRUM CENTER DR Suite 949 
                                <br/>IRVINE CA 92618,
                                <br/>P O BOX 54210 IRVINE CA USA 92619</p>
                        </div>
                        {/* Contact Us */}
                        <div className="connect" style={{ marginRight: "20px", fontFamily: 'sans-serif', color: "#5a5c76" }}>
                            <h3>Contact Us</h3>
                            <p>+1-800-259-9578</p>
                        </div>
                    </div>
                </nav>
            </footer>

        </div>
    );
};

export default LandingPage;
