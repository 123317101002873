import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProfileComponent from "../../../shared/profile_component/ProfileComponent";

function CandidateDetail ( props ) {

    const [state, setState] = useState( null );
    
    useEffect( () => {
        const { match: { params } } = props;
        const query = new URLSearchParams( props.location.search );
        const urlParams = new URLSearchParams( query );

        setState( {
            userId: params.id,
            isValid: false,
            fromJob: urlParams.get( 'jp' ) || false,
            job_id: urlParams.get( 'job_id' ) || 0,
        } );
    },[] );



    if ( state !== null ) {
        return (
            <Fragment>
                <div className="row mt-2">
                    <div className="col-md-12 text-right text-decoration-none">
                        {state.fromJob ?
                            <Link to={`/jobs/position/detail/${state.job_id}`}>
                                <FontAwesomeIcon icon={faArrowLeft} className={"mr-2"} />
                                    Back to Talent List
                            </Link>:
                            ""}
                    </div>
                        
                    <div className="col-md-12">
                        <ProfileComponent talent_id={state.userId}  />
                    </div>
                </div>
            </Fragment>

        );
    } else {
        return ( 'Nothing' );
    }

    
}

export default CandidateDetail;
