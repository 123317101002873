import React, { Fragment, useEffect, useMemo, useState } from "react";
import PhoneInput from "react-phone-input-2";
import util from "../../../utils/miniUtils";
import selector from "../../../utils/selector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import {
    // Collapse,
    InputAdornment,
    TextField,
    // FormControl,InputLabel, Select
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { currency } from '../../../utils/currencies';
import usePersonalInformation from "./usePersonalInformation";
import { TalentStore } from "../TalentStore";
import ResumeContainer from "./upload_resume/ResumeContainer";
import CountryAutoFill from "../../../talents/components/listComponents/CountryAutoFill";
import Location from "../../../talents/components/listComponents/Location";

export default function PersonalContainer() {
    const {
        // handleChanges,
        updateData,
        getListOfJobTitles,
        jobTitles,
        selected,
        setSelected,
        updateJobTitles
    } = usePersonalInformation();

    const [talentState, setTalentState] = useState( {} );

    function handleChanges( e ){
        const { name, value } = e.target;
        setTalentState( prevState => ( {
            ...prevState,
            [name]: value
        } ) );
    }
    
    // const style = {
    //     maxWidth: 120
    // };
	
    const talent = TalentStore.useState( state => state.talent );
    // const [showMoreFields, setShowMoreFields] = useState( false );
	
    useEffect( () => {
        setSelected( talent.jobTitles );
        // eslint-disable-next-line
    }, [] );
	
    useEffect( () => {
        iconDependingCurrencySelected();
        // eslint-disable-next-line
    }, [talent.currency] );
	
    useEffect( () => {
        getListOfJobTitles();
        // eslint-disable-next-line
    }, [] );
	
    useEffect( () => {
        updateJobTitles();
        // eslint-disable-next-line
    }, [selected] );
	
    useMemo( () => {
        setTalentState( talent );
    },[talent] );

    if ( talent === null ) {
        return "loading...";
    }
	
    function iconDependingCurrencySelected() {
        for ( let x = 0; x < currency.length; x++ ) {
            const c = currency[x];
            if ( c.code === talent.currency || c.symbol === talent.currency ) {
                return c.symbol;
            }
        }
        return `$`;
    }

    return (
        <Fragment>
			
            <div className="row pt-3">
                {/* Name, relocate and resume section */}
                <util.RowComponentClean
                    c={4}
                    t={"Full Name"}
                    n={"name"}
                    m={handleChanges}
                    v={talentState?.name || ""}
                />
				
                <util.RowComponentClean
                    disabled={util.user.role === 2}
                    c={4}
                    t={"Email"}
                    n={"email"}
                    m={handleChanges}
                    v={talentState?.email || ""}
                />
                <div className="col-md-2 pt-2">
                    <TextField
                        select
                        size={"small"}
                        name="relocation"
                        className="w-100"
                        label="Relocation"
                        value={talentState?.relocation}
                        onChange={handleChanges}
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                    >
						
                        <option value="0">No</option>
                        <option value="1">Yes</option>
						
                    </TextField>
                </div>
                
            </div>
			
            <div className="row pt-3">
                
                {/* <div className={`col-md-3`}>
                    <PhoneInput
                        specialLabel={"Phone (Mobile)"}
                        country={'us'}
                        name="phone_mobile"
                        value={talentState?.phone_mobile || ""}
                        onChange={phone => {
                            let e = { target: { name: "phone_mobile", value: phone } };
                            handleChanges( e );
                        }}
                    />
                </div> */}
                {/* <div className="col-md-5">
                    <CountryAutoFill newState={talentState} // contains everything
                        onChangeHandler={handleChanges} />
                </div> */}
                <div className="col-md-12">
                    <Location
                    // cols={2}
                        data={talentState} // contains everything
                        onChange={handleChanges}
                    /></div>
            </div>

            <div className="row pt-3">
                <util.RowComponentClean
                    c={4}
                    t={"Address"}
                    n={"address"}
                    m={handleChanges}
                    v={talentState?.address ? talentState?.address : ""}
                />
                <util.RowComponentClean
                    c={2}
                    ty={"number"}
                    t={"Zip Code"}
                    n={"zip_code"}
                    m={handleChanges}
                    v={talentState?.zip_code || ""}
                />
            </div>
			
            <div className="row pt-3">
                <div className="col-md-2">
                    {/*<label htmlFor="currencySelector" className="labelFormControl">Currency</label>*/}
                    {/*<select*/}
                    {/*	id="currencySelector"*/}
                    {/*	name="currency"*/}
                    {/*	onChange={handleChanges}*/}
                    {/*	value={talent.currency}*/}
                    {/*	className="form-control"*/}
                    {/*>*/}
                    {/*	{currency.map((o, i) => {*/}
                    {/*		return <option value={o.code} key={i}>{o.code} - ${o.name}</option>*/}
                    {/*	})}*/}
                    {/*</select>*/}
					
					
                    <TextField
                        select
                        label="Currency"
                        value={talentState?.currency}
                        onChange={handleChanges}
                        size={"small"}
                        name="currency"
                        SelectProps={{
                            native: true,
                        }}
                    >
                        {currency.map( ( option ) => (
                            <option key={option.name} value={option.code}>
                                {option.code} - ${option.name}
                            </option>
                        ) )}
                    </TextField>
                </div>
				
                <div className="col-md-4">
                    <TextField
                        size={"small"}
                        className="w-100"
                        InputLabelProps={{ shrink: true }}
                        value={talentState?.ctc ? talentState?.ctc : 0}
                        type="number"
                        label="Expected Salary"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    {iconDependingCurrencySelected()}
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                        name="ctc"
                        onChange={handleChanges}
                    />
                </div>
				
                <util.RowComponentClean
                    c={3}
                    t={"Per"}
                    n={"salary_mode"}
                    m={handleChanges}
                    v={util.handleValueByRecruiterCountry( talentState?.salary_mode, 'salary_mode' )}
                    type={1}
                    d={selector.sell_rate_type}
                />
				
                <util.RowComponentClean
                    c={3}
                    t={"Employment Type"}
                    n={"salary_type"}
                    m={handleChanges}
                    v={talentState?.salary_type || ""}
                    type={1}
                    d={[{ salary_type: "Contractor" }, { salary_type: "CorpToCorp(C2C)" }, { salary_type: "Fulltime" }, { salary_type: "Part-time" }, ]}
                />
				
                {/* <util.RowComponentClean
                    c={2}
                    t={"Work Authorization"}
                    n={"working_status"}
                    m={handleChanges}
                    v={util.handleValueByRecruiterCountry( talent.working_status, 'working_status' )}
                    type={1}
                    d={util.working_status}
                />
                {talent.working_status === "Other" ?
                    <util.RowComponentClean
                        c={3}
                        t={"Other"}
                        n={"working_status_other"}
                        m={handleChanges}
                        v={talent.working_status_other || ""}
                    />
                    : ""} */}

                <util.WorkAuthSelector value={talent.working_status} handleChange={handleChanges} />
                {talent.working_status=== "other" ? <div className="col-lg-6 col-md-6">
                    <util.RowComponent
                        c={12}
                        t={"Work Authorization (Others)"}
                        n={"working_status_other"}
                        m={handleChanges}
                        v={talentState?.working_status_other || ""} />
                            
                </div> : ""}
                    
			
            </div>
			
            <div className="row pt-3">
                <util.RowComponentClean
                    c={3}
                    t={"Highest Education"}
                    n={"highestEducation"}
                    m={handleChanges}
                    v={talentState?.highestEducation || ""}
                    type={1}
                    d={util.highestEducation}
                />

                <util.RowComponentClean
                    c={3}
                    t={"Last Job Title"}
                    n={"position"}
                    m={handleChanges}
                    v={talentState?.position || ""}
                    type={1}
                    d={talent.workHistory}
                />
				
                <util.RowComponentClean
                    c={3}
                    t={"Notice Period"}
                    n={"noticePeriod"}
                    m={handleChanges}
                    v={talentState?.noticePeriod || "2 Weeks"}
                    type={1}
                    d={selector.AvailabilityToStartObj}
                />
				
                <util.RowComponentClean
                    c={3}
                    t={"How did you find us?"}
                    n={"platform"}
                    m={handleChanges}
                    v={talentState?.platform || ""}
                    type={1}
                    d={[
                        { platform: " " },
                        { platform: "Indeed" },
                        { platform: "LinkedIn" },
                        { platform: "Monster" },
                        { platform: "ZipRecruiter" },
                        { platform: "Other" }
                    ]}
                />
            </div>
			
            <div className="row pt-3">
                <div className="col-md-12">
                    <Autocomplete
                        onInputChange={( event, newInputValue ) => {
                            getListOfJobTitles( newInputValue );
                        }}
                        onChange={( event, newValue ) => {
                            setSelected( newValue );
                        }}
                        getOptionLabel={option => option.name}
                        value={selected}
                        title="Job Title"
                        multiple
                        // limitTags={3}
                        getOptionSelected={( option, value ) => option.id === value.id}
                        options={jobTitles}
                        // style={{width: 300}}
                        fullWidth
                        renderInput={( params ) => <TextField label="Job titles" {...params} variant="standard"/>}
                    />
				
                </div>
			
			
            </div>
			
            <div className="row pt-3">
                {/* <Collapse in={showMoreFields} className="w-100"> */}
                <div className="container">
                    <div className="row pt-3">
                        <div className={`col-md-3`}>
                            <label className="labelFormControlMini">Phone (Home)</label>
                            <PhoneInput
                                placeholder={"Phone (Home)"}
                                country={'us'}
                                name="phone_home"
                                value={talentState?.phone_home || ""}
                                onChange={phone => {
                                    let e = { target: { name: "phone_home", value: phone } };
                                    handleChanges( e );
                                }}
                            />
                        </div>
							
                        <div className={`col-md-3`}>
                            <label className="labelFormControlMini">Phone (Other)</label>
                            <PhoneInput
                                placeholder={"Phone (Other)"}
                                country={'us'}
                                name="phone_other"
                                value={talentState?.phone_other || ""}
                                onChange={phone => {
                                    let e = { target: { name: "phone_other", value: phone } };
                                    handleChanges( e );
                                }}
                            />
                        </div>
							
                        <div className=" col-md-3">
                            <label>Resume</label>
                            <ResumeContainer talent={talent}/>
                        </div>
                    </div>
                </div>
                {/* </Collapse> */}
                {/* <div className="col-md-12 text-right">
                    <span onClick={() => setShowMoreFields( !showMoreFields )}
					      className="likeLink">{showMoreFields ? "Less fields" : "More fields"}</span>
                </div> */}
			
            </div>
			
            <div className="row ">
                <div className="col-md-12">
                    <div className="text-right">
                        <button
                            onClick={() => updateData( talentState )}
                            className="btn m-1 btn-primary btn-sm "
                        >
                            <FontAwesomeIcon icon={faSave}/> Save
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
	
}
