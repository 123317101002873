import React, { Fragment, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faColumns, faEnvelope, faFilter, faPaperclip, faPhone, faPlus, faSearch, faTable } from '@fortawesome/free-solid-svg-icons';
import ModalNewCandidate from "./ModalNewCandidate";
import MaterialTable from "material-table";
import { CircularProgress, MuiThemeProvider, Tooltip } from '@material-ui/core';
import StarRatings from 'react-star-ratings';
import Utils from "../../../utils/miniUtils";
import util from "../../../utils/miniUtils";
import TalentFromResumeProcess from "../talent_from_resume/TalentFromResumeProcess";
import SAlert from "../../../microComponents/AlertSA";
import useListOfCandidate from './useListOfCandidate';
import SideBarFilter from './sideBarFilter';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
const tableRef = React.createRef();


const LocalStorageKey = "talentColumns";

function ListOfCandidates ( { addCandidate, addModalShow, closeAddModa } ) {
    const {
        showForm,
        confirmCloseModal, setConfirmCloseModal,
        simpleSearch, //Only to carry a value on popover
        // fromResume,
        loading,
        closeAndReload,//closeModalAndReloadList
        closeFormAndReloadList,
        closeModal,
        handleClose,urlHandlerversion2, filterState, setFilterState,searchDataVersion2 } = useListOfCandidate(); 
    
    const reloadTableData = () => {
        tableRef.current && tableRef.current.onQueryChange( undefined );
    };
    const [columns,setColumns]=useState( [
        {
            title: <FontAwesomeIcon icon={faPaperclip} />,
            field: 'resume',
            sorting: false,
            // width: "200px",
            render: rowData => {
                const resume = util.isResumeAvailable( rowData );
                return (
                    resume !== "" ?
                        <div className="text-center">
                            <Tooltip title="Download the resume">
                                <span>
                                    {resume}
                                </span>
                            </Tooltip>
                        </div>
                        :
                        ""
                );
            }
        },
        {
            title: <FontAwesomeIcon icon={faEnvelope}  />, field: 'email',
            // width: "50px",
            sorting:false,
            render: rowData => {
                return (
                    <div className="text-center">
                        <Tooltip title="Send it an email">
                            <a href={`mailto:${rowData.email}`}>
                                <FontAwesomeIcon icon={faEnvelope}  />
                            </a>
                        </Tooltip>
                    </div>
                );
            }
        },
        {
            title: <FontAwesomeIcon icon={faPhone}  />, field: 'phone_mobile',
            align: "center",
            // width: "50px",
            sorting:false,
            render: d=>{
                return <div className="d-flex justify-content-around pl-1 pr-1">
                    <a href={`tel:${d.phone_mobile}`} className="p-1"><FontAwesomeIcon icon={faPhone} /></a>
                    {d.phone_mobile !== null && d.phone_mobile !== "null" ?
                        <a target={"_blank"} rel="noopener noreferrer" className="p-1" href={util.whatsapp( d.phone_mobile, `Hello, my name is ${util.user.name} from eJAmerica` )}>
                            <img width={16} src="images/whatsapp.png" alt="whatsapp"/>
                        </a>:""
                    }
                </div>;
            }
        },
        {
            title: '', field: 'profile_picture', sorting:false,
            render: rowData => {
                return <div className="text-center">{util.isProfilePicAvailable( rowData )}</div>;
            },
            width: "4%",
        },
        {
            title: 'Talent ID',
            field: 'id',
            disableClick: true
        },
        {
            title: 'Name', field: 'name', searchable: true,
            width:"10%",
            render: rowData => {
                return <a target="_blank" rel="noopener noreferrer" href={`/candidates/${rowData.id}`}>{rowData.name}</a>;
            },
        },
        {
            title: 'Skills', field: 'skillsSet', searchable: true,
            width:"10%",
            render: ( rowData ) => {
                return util.suspenseDotPopOver( rowData["skillsSet"], simpleSearch );
            },
        },
        {
            title: 'Last Job', field: 'wh_position', searchable: true,
            width:"10%",
            render: rowData => {
                return (
                    <div className="text-left">
                        {util.suspenseDotPopOverLastJob( rowData["wh_position"], simpleSearch )}
                    </div>
                );
            },
        },
        {
            title: 'Location', field: 'country' , searchable: true,
            width:"5%",
            render: row => {
                return  (
                    <div className="text-left">
                        {Utils.location( row.country, row.state, row.city )}
                    </div>
                );
            }
        },
        {
            title: 'Work Authorization', field: 'working_status', width:"5%"
        },
        {
            title: 'Expected Salary', field: 'ctc', width:"5%",
            render: d => {
                return (
                    <div className="text-center">
                        {util.expectedSalaryColumn( d )}
                    </div>
                );
            }
        },
        {
            title: 'Rating', field:'rating', width:"5%",
            render: rowData => {
                return (
                    <div className="text-center">
                        <StarRatings
                            rating={rowData.rating || 0}
                            starDimension="10px"
                            starSpacing="1px"
                            starRatedColor="rgb(233,227,92)"
                        />
                    </div>
                );
            }
        },
        {
            title: 'Created', field: 'createdAt', width:"5%",
            render: d => {
                return (
                    <div className="text-left">
                        {util.humanDate( d["createdAt"] )}
                    </div>
                );
            }
        },
        {
            title: 'Modified', field: 'latest_updated', width:"5%",
            render: d => {
                return (
                    <div className="text-left">
                        {util.humanDate( d["latest_updated"] )}
                    </div>
                );
            }
        },
        {
            title: 'Last action', field: 'latest_desc_updated', width:"5%",
            render: d => {
                return (
                    <div className="text-left">
                        <Tooltip title={d.latest_desc_updated ? d.latest_desc_updated:""}>
                            <span>
                                {
                                    d.latest_desc_updated
                                }
                            </span>
                        </Tooltip>
                    </div>
                );
            }
        },
    ] );
    const [showColumns, setShowColumns] = useState( columns.map( ( c ) => c.field ) );
    const [order, setOrder] = useState( localStorage.getItem( LocalStorageKey ) );
    // const [dragging, setDragging] = useState( false );
  
    useEffect( () => {
        if ( order ) {
            setShowColumns( JSON.parse( order ) );
        }
    }, [order] );
  
    const handleSave = () => {
        localStorage.setItem( LocalStorageKey, JSON.stringify( showColumns ) );
    };
  
    const handleChange = ( event ) => {
        const field = event.target.value;
        if ( showColumns.includes( field ) ) {
            setShowColumns( showColumns.filter( ( c ) => c !== field ) );
        } else {
            setShowColumns( [...showColumns, field] );
        }
    };
    const tableColumns = columns.filter( ( c ) => showColumns.includes( c.field ) );

    function handleColumnDrag( sourceIndex, destinationIndex ) {
        setShowColumns( ( prevArray ) => {
            const newArray = [...prevArray];
            const temp = newArray[sourceIndex];
            newArray[sourceIndex] = newArray[destinationIndex];
            newArray[destinationIndex] = temp;
            return newArray;
        } );
    }
  
    // const handleDragStart = ( event, index ) => {
    //     event.dataTransfer.setData( "text/plain", index );
    //     setDragging( true );
    // };
  
    // const handleDragOver = ( event ) => {
    //     event.preventDefault();
    // };
  
    // const handleDrop = ( event, index ) => {
    //     event.preventDefault();
    //     const dragIndex = Number( event.dataTransfer.getData( "text" ) );
    //     const newOrder = [...showColumns];
    //     newOrder.splice( dragIndex, 1 );
    //     newOrder.splice( index, 0, showColumns[dragIndex] );
    //     setShowColumns( newOrder );
    //     setDragging( false );
    // };
        
    // const [selectedColumns, setSelectedColumns] = useState( [] );


    // const handleColumnChange = ( column ) => {
    //     if ( selectedColumns.find( col => col.field === column.field ) ) {
    //         setSelectedColumns( selectedColumns.filter( col => col.field !== column.field ) );
    //     } else {
    //         setSelectedColumns( [...selectedColumns, column] );
    //     }
    // };

    // async function saveOrder(){
        
    //     let saved = selectedColumns.map( ( value ) => columns.indexOf( value ) );

    //     localStorage.setItem( 'talentTable', JSON.stringify( saved ) );

    // }
   
    // useEffect( () => {
    //     const savedState = JSON.parse( localStorage.getItem( 'talentTable' ) );
    //     if ( savedState ) {
    //         console.log( savedState.map( i => columns[i] ) );
    //         setSelectedColumns( savedState.map( i => columns[i] ) );
    //     }
    //     else
    //     {
    //         setSelectedColumns( columns );
    //     }
    // }, [] );
        
    
    if ( loading ) {
        return  <Fragment>
            <div className="container-fluid p2 ">
                <div className="text-center">
                    <CircularProgress />
                </div>
            </div>
        </Fragment>;
    }
    // console.log( filterState );
   
    
    return (
        <Fragment>
            <div className='row'>
               
                {  filterState.showFilter ?
                    <div className='col-md-2'>
                        <div>
                            <SideBarFilter
                                reloadData={
                                    ( filter )=> {
                                        setFilterState( prevState => ( {
                                            ...prevState,
                                            ...filter
                                        } ) );
                                        reloadTableData();
                                    }
                                }
                                searchDataVersion2={searchDataVersion2}
                                resetSearch={()=> {
                                    const reset = { job_title: "", state: "", skills: "", country: "", city: "", resume: "All" };
                                    setFilterState( Object.assign( {}, reset ) );
                                }
                                }
                            />
                        </div>
                    </div>
                    :
                    null
                }
                <div className={filterState.showFilter ? 'col-md-10': 'col-md-12'}>
                    <MuiThemeProvider theme={util.defaultTableTheme}>
                        {/* <div>
                            <label>Show columns:</label>
                            {columns.map( ( c ) => (
                                <div key={c.field}>
                                    <input
                                        type="checkbox"
                                        value={c.field}
                                        checked={showColumns.includes( c.field )}
                                        onChange={handleChange}
                                    />
                                    <span>{c.title}</span>
                                </div>
                            ) )}
                        </div>
                        <div>
                            <button onClick={handleSave}>Save</button>
                        </div> */}
                        <MaterialTable
                            title="Available candidates"
                            tableRef={tableRef}
                            columns={tableColumns}
                            onColumnDragged={handleColumnDrag}
                           
                            data={query =>
                                util.axios.get( urlHandlerversion2( query ) )
                                    .then( response => {
                                        const { candidates, total, page, error, msg } = response.data;
                                        if ( error ) throw  msg;
                                        return ( {
                                            data: candidates,
                                            page: page,
                                            totalCount: total
                                        } );
                                    } ).catch( error=>{
                                        util.handleError( error );
                                    } )
                            }
                            options={
                                {
                                    pageSize: 50,
                                    pageSizeOptions:[50, 100, 150],
                                    sorting: true,
                                    searchFieldAlignment:"left",
                                    toolbarButtonAlignment: "left",
                                    // padding:"dense",
                                    tableLayout:"auto",
                                    showTitle:false,
                                    debounceInterval:500,
                                    paginationPosition:"both",
                                    // search:!listState.showFilter ,
                                    headerStyle:{ position:'sticky',top:0 },
                                    maxBodyHeight:'485px',
                                }
                            }
                            actions={[
                                // { icon:()=><div>
                                //     <div className="dropdown">
                                //         <div type="text" id="dropdownMenuButton" data-toggle="dropdown" className={"btn"} aria-haspopup="false" aria-expanded="true" >
                                //             <span className="ml-2 isCursor text-secondary"><FontAwesomeIcon title='show/Hide Columns' size='1x' icon={faTable} className="iconGrey"/></span>
                                //         </div>
                                //         <div className="dropdown-menu drp " aria-labelledby="dropdownMenuButton" style={{ width:'500%' }}>
                                                        
                                                        
                                //             {columns.map( ( column, index ) => (
                                //                 <button className="dropdown-item"  key={index}>
                                //                     <input
                                //                         type="checkbox"
                                //                         name={column.field}
                                //                         checked={selectedColumns.find( col => col.field === column.field )}
                                //                         value={column}
                                //                         onClick={() => handleColumnChange( column )}
                                //                     />
                                //                     <label className='ml-1'>{column.title}</label> 
                                //                 </button>
                                //             ) )}
                                //             <button  className="btn btn-success btn-sm mr-3 float-right" 
                                //                 onClick={()=>{saveOrder(); }}
                                //             >
                                //            Save
                                //             </button>
                                //         </div>
                                //     </div>
                                // </div>,
                                // isFreeAction: true,
                                // onClick: () => null
                                // },
                                { icon:()=><div>
                                    <div className="dropdown">
                                        <div type="text" id="dropdownMenuButton" data-toggle="dropdown" className={"btn"} aria-haspopup="false" aria-expanded="true" >
                                            <span className="ml-2 isCursor text-secondary">
                                                <ViewColumnIcon title='Show/Hide Columns' className="iconGrey" />
                                            </span>                             </div>
                                        <div className="dropdown-menu drp " aria-labelledby="dropdownMenuButton" style={{ width:'1200%' }}>
                                            <div className="drp-contain">             
                                                        
                                                {columns.map( ( c, index ) => {
                                                    const mycolumn = index % 3;
                                                    const myrow = Math.floor( index / 3 );
                                                    return(
                                                        <button className="dropdown-item"  key={c.field} style={{ gridColumn: `${mycolumn + 1}`, gridRow: `${myrow + 1}` }}>
                                                            <input
                                                                type="checkbox"
                                                                value={c.field}
                                                                checked={showColumns.includes( c.field )}
                                                                onChange={handleChange}
                                                            />
                                                    
                                                            <label className='ml-1'>{c.title}</label> 
                                                        </button>
                                                    );} )}</div>
                                            <button  className="btn btn-success btn-sm mr-3 float-right" 
                                                onClick={()=>{handleSave(); }}
                                            >
                                           Save
                                            </button>
                                        </div>
                                    </div>
                                </div>,
                                isFreeAction: true,
                                onClick: () => null
                                },
                                {
                                    icon: () =><>
                                        <div className="btn btn-primary btn-sm"
                                            //className="dropdown-toggle"
                                            // data-toggle="dropdown"
                                            onClick={()=>addCandidate()}
                                        >
                                            <FontAwesomeIcon icon={faPlus} className="mr-2" />
                                        Talent
                                        </div>
                                        {/* <div className="dropdown-menu"> */}
                                        {/* <div className="dropdown-item" onClick={()=>addCandidate()} >Manual</div> */}
                                        {/* <div className="dropdown-item" onClick={()=> fromResume()} >Upload Resume</div> */}
                                        {/* </div> */}
                                    </>,
                                    position:"toolbar",
                                    isFreeAction: true,
                                    onClick: () => null
                                },
                                {
                                    icon: () =>
                                        <div className="btn btn-info btn-sm">
                                            <FontAwesomeIcon icon={faFilter} className="mr-2" />
                                    Filter
                                        </div>
                                    ,
                                    position:"toolbar",
                                    isFreeAction: true,
                                    // onClick: () => {
                                    //     if ( listState.showFilter ) {
                                    //         setListState( { ...listState, 
                                    //             showFilter: false,
                                               
                                    //         } );
                                    //     } else {
                                    //         setListState( { ...listState, showFilter: true } );
                                    //     }
                                    // }  
                                    onClick: () => {
                                        if ( filterState.showFilter ) {
                                            setFilterState( { ...filterState, 
                                               
                                                showFilter: false,
                                                skill_name: [],
                                                job_title: [],
                                                country: [],
                                                state: [],
                                                city: [],
                                                resume: [],
                                                noticePeriod:[],
                                                // min: 0,
                                                // max: 100000
                                            } );
                                        } else {
                                            setFilterState( { ...filterState, showFilter: true } );
                                        }
                                    }  
                                },
                    
                            ]}
                        />
                    </MuiThemeProvider>
                    <ModalNewCandidate
                        closeModalAndReloadList={() => closeAndReload( closeAddModa ) }
                        show={addModalShow}
                        onHide={''}
                        closeModal={() => closeModal( closeAddModa )} />
            
                    {!showForm ? "":
                        <TalentFromResumeProcess
                            handleClose={()=> setConfirmCloseModal( true )}
                            closeFormAndReloadList={()=>{
                                setConfirmCloseModal( false );
                                closeFormAndReloadList();
                            }
                            }
                            showForm={showForm}
                        />
                    }
                    {confirmCloseModal ?
                        <SAlert
                            show={confirmCloseModal}
                            confirmText="Yes"
                            typeButton="info"
                            msg="You can't undo this action"
                            hideAlert={()=> setConfirmCloseModal( false )}
                            onConfirm={()=> {
                                setConfirmCloseModal( false );
                                handleClose();
                            }}
                        />:""}
                    {util.LOADING_SCREEN( loading )}
                </div>
            </div>
        </Fragment>
    );
    
}

export default ListOfCandidates;
