import React, { useState } from 'react';
import { Popover } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import util from '../../utils/miniUtils';
import Tooltip from "@material-ui/core/Tooltip";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import MaterialTable from 'material-table';
import ManageWorkLoadDetail from '../manage_workload/components/ManageWorkLoadDetail';
import MyTicketDetail from '../my_tickets/MyTicketDetail';

const ChildTicketList = ( { data, myTicket=false } ) => {

    const [workloadDetail, setWorkloadDetail] = useState( {
        show: false,
        workload_id: null,
        permission_level: null,
    } );

    const [myWorkloadDetail, setMyWorkloadDetail] = useState( {
        show: false,
        workload_id: null,
        permission_level: null,
    } );

    const reloadData = () => {
        // getStatusListToCategory( workloadId );
    };

    const showWorkLoadDetail = ( id ) => {
        if( myTicket ) {
            setMyWorkloadDetail( ( prev ) =>{
                return {
                    ...prev,
                    show: true,
                    workload_id: id,
                    permission_level: 1
                };
            } );
        } else {
            setWorkloadDetail( ( prev ) =>{
                return {
                    ...prev,
                    show: true,
                    workload_id: id,
                    permission_level: 1
                };
            } );
        }
        handleCancel();
    };

    const closeWorkLoadDetail = () => {
        if( myTicket ) {
            setMyWorkloadDetail( ( prev ) =>{
                return {
                    ...prev,
                    show: false
                };
            } );
        } else {
            setWorkloadDetail( ( prev ) =>{
                return {
                    ...prev,
                    show: false
                };
            } );
        }
    };

    // Anchor and open variable for the popover. 
    const [anchorEl, setAnchorEl] = React.useState( null );
    const open = Boolean( anchorEl );


    // Triggering the popover on the double click
    const handleClick = ( event ) => {
        setAnchorEl( event.currentTarget );
    };

    const handleCancel = () => {
        setAnchorEl( null );
    };

    const theme = createTheme( {
        overrides: {
            MuiTooltip: {
                tooltip: {
                    maxWidth: "250px",
                    fontSize: "0.85em",
                }
            }
        }
    } );

    function editValue() {
        return <Popover
            id={open ? 'text-input-popover' : undefined}
            open={open}
            anchorEl={anchorEl}
            onClose={handleCancel}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}>
            <div className='d-flex wl_textInputPopover'>
                <MuiThemeProvider theme={util.defaultTableTheme}>
                    <MaterialTable
                        title="Child Tickets"
                        // tableRef={tableRef}
                        columns={[
                            {
                                title: "Ticket Number",
                                field: 'ticket_number',
                                render: value => <div>
                                    <a className="text-primary link-primary" onClick={() => showWorkLoadDetail( value.id )}>{value && value.ticket_number}</a>
                                </div>
                            },
                            {
                                title: "Title",
                                field: 'short_description',
                            },
                            {
                                title: "Created On",
                                field: 'createdAt',
                                render: ( value ) => {
                                    return <div>{util.humanDateTime( value.createdAt )}</div>;
                                },
                                cellStyle: {
                                    whiteSpace: 'nowrap'
                                },
                            },
                            {
                                title: "Customer Name",
                                field: 'customer_name',
                            },
                            {
                                title: "Customer Department",
                                field: 'department_name',
                            },
                            {
                                title: "Priority",
                                field: 'priority',
                            },
                            {
                                title: "Status",
                                field: 'statusName',
                            },
                        ]}
                        data={data}
                        options={
                            {
                                searchFieldAlignment: "left",
                                search: false,
                                pageSize: 20,
                                emptyRowsWhenPaging: false,
                                pageSizeOptions:[20,50,100,150,500],
                                sorting: true,
                                padding: "default",
                                tableLayout: "auto",
                                toolbarButtonAlignment: "left",
                                headerStyle: { backgroundColor: "#007bff", color: "white" },
                            }
                        }
                    />
                </MuiThemeProvider>
            </div>
        </Popover>;
    }

    return <>
        <MuiThemeProvider theme={theme}>
            {editValue()}
            <div onClick={handleClick}>
                {data.length > 0 && <span className="btn btn-sm btn-light">{data.length}</span>}
            </div>
        </MuiThemeProvider>
        
        {workloadDetail.show &&
        <ManageWorkLoadDetail
            workload_id={workloadDetail.workload_id}
            handleClose={closeWorkLoadDetail}
            show={workloadDetail.show}
            permissionLevel={workloadDetail.permission_level}
            reload={reloadData}
        />}
        {myWorkloadDetail.show &&
                <MyTicketDetail
                    workload_id={myWorkloadDetail.workload_id}
                    handleClose={closeWorkLoadDetail}
                    show={myWorkloadDetail.show}
                    permissionLevel={myWorkloadDetail.permission_level}
                    reload={reloadData}
                />
        }
    </>;
};


export default ChildTicketList;