import { PositionsStore } from "../../stores/PositionsStore";
import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCalendarAlt,
    faClock,
    faLevelUpAlt,
    faMapMarkerAlt,
    faMoneyBill,
    faTimes
} from "@fortawesome/free-solid-svg-icons";
import util from "../../../utils/miniUtils";
import ApplyModal from "../../modals/apply_modal/ApplyModal";
import DrawBackPosition from "../../../jobs/components/jobLists/talent/DrawBackPosition";
import ModalCX from "../../modals/ModalCX";

export default function PositionTitleSecondary( { job } ) {
    const position = PositionsStore.useState( state => state );
    const [isDrawingBack, setIsDrawingBack] = useState( false );
	
    useEffect( () => {
        PositionsStore.update( s=>{
            s.job = job;
        } );
    },[] );
	
    function paymentUtility( amount, country ) {
        if ( util.noValues.includes( amount ) ) return "~";
        return util.currencyFormat( amount.toString(), country );
		
    }
	
    return (
        <Fragment>
			
            <div className="col-md-12">
                <div className="jobPublicStyle d-flex justify-content-between">
                    <div className="">
                        <div>
                            <FontAwesomeIcon icon={faMapMarkerAlt} className="iconGray"/>
                            <b>Location</b>: {util.location( job.country, job.state, job.city )}
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faLevelUpAlt} className="iconGray"/>
                            <b>Level</b>: {job["level"]}
                        </div>
                    </div>
                    <div className="">
                        <div>
                            <FontAwesomeIcon icon={faCalendarAlt} className="iconGray"/>
                            {util.humanDate( job["createdAt"] )}
                        </div>
                        <div className="">
							Job ID: <b>{util.zeroPad( job.id, 5 )}</b>
                        </div>
                    </div>
					
                </div>
				
                <div className="jobPublicStyle">
                    <div className="">
                        <FontAwesomeIcon icon={faClock} className="mr-1 iconGray"/>
                        <b>Duration</b>: <u>{job.duration}</u>
                    </div>
                    <div className="">
                        <FontAwesomeIcon icon={faMoneyBill} className="mr-1 iconGray"/>
                        <b>Salary Range</b>: {util.buyFromBuyTo( job.buy_from, job.buy_to, job.country )} {job.sell_rate_type}
                    </div>
					
                </div>
				
                <div className="jobPublicStyle d-flex justify-content-end">
                    {job["applied"] ?
                        <Fragment>
                            {[10,19,11].indexOf( job["statusCandidate"] ) === 1 || job["talent_dropped"] === 1?
                                <p>You withdrew the application</p>
                                :
                                <button className="btn btn-sm btn-danger float-right"
								        onClick={()=> setIsDrawingBack( true )}
                                >
                                    <FontAwesomeIcon icon={faTimes} className="mr-2" />
									Withdraw
                                </button>
                            }
                        </Fragment>
                        :
                        <button
                            onClick={() => PositionsStore.update( s => {
                                s.applyModal = true;
                            } )}
                            className="btn btn-sm btn-success">
							Apply
                        </button>
                    }
                </div>
            </div>
			
			
            <ModalCX
                title="Withdraw Position"
                show={isDrawingBack}
                handleClose={()=>setIsDrawingBack( false )}
            >
                <DrawBackPosition
                    closeModal={()=>setIsDrawingBack( false )}
                    jd={job}
                    refreshTable={()=> window.location.reload()}
                    setIsDrawingBack={()=>setIsDrawingBack( false )}
                />
            </ModalCX>
			
            {position.applyModal ? <ApplyModal/>: ""}
            {/*{position.createAccount ? <Register/>: null}*/}
            {/*{position.loginModal ? <Login/>: null}*/}
        </Fragment>
    );
}



