import Modal from "react-bootstrap/Modal";
import React, { Fragment, useState,useEffect } from "react";
import { PositionsStore } from "../../stores/PositionsStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import ApplyToJob from "./ApplyToJob";
import util from "../../../utils/miniUtils";

export default function ApplyModal() {
	
    const show = PositionsStore.useState( state => state.applyModal );
    const state = PositionsStore.useState( state => state );
    const j = PositionsStore.useState( state => state.job );
    const handleClose = () => {PositionsStore.update( s => { s.applyModal = false;} );};
    const [showScreening, setShowScreening ] = useState( false );
    // state related to screening questions
    const [response, setResponse ] = useState( [ ] );
    const [sqData, setSqData] = useState( [] );
    
    async function getSqData() {
        try {
            const request = await util.axios.get( `/get_job_questions/${j.id}` );
            const { data, error, msg } = request.data;
            if( error ) throw msg;
            setSqData( data );
        } catch ( error ) {
            util.handleError( error );
        }
    }
    async function applyToJob() {
        try {
            const data = {
                id: util.user.candidate_id,
                fromTalent: true
            };
            const request = await util.axios.post( `/requirements/add_candidate_new_method/${j.id}`, { data, sqResponse: response } );
            const { error, msg } = request.data;
            util.toast().success( msg );
            setTimeout( ()=>{
                window.location.reload();
            },1500 );
            if ( error ) throw  msg;
			
        } catch ( e ) {
            util.handleError( e );
        }
        handleClose();
    }

    const handleRadioChange = ( event,id  ) => {
        const value = event.target.value;
        setResponse( ( prevOptions ) => {
            const newOptions = prevOptions.map( ( el ) =>{
                if( el.questionId === id ) {      
                    return { ...el, answer:value };
                }
                return el ;
            } );
            return newOptions;
        } );
    };

    const handleTextInputChange = ( event, id ) => {
        const value = event.target.value;
        setResponse( ( prevOptions ) => {
            const newOptions = prevOptions.map( ( el ) => {
                if ( el.questionId === id ) {
                    return { ...el, answer: value };
                }
                return el;
            } );
            return newOptions;
        } );
    };

    const handleNumericChange = ( event, id ) => {
        const value = event.target.value;
      
        setResponse( ( prev ) => {
            const response = prev.map( ( el ) => {
                if ( el.questionId === id ) {
                    return { ...el, answer: value };
                }
                return el;
            } );
            return response;
        } );
    };

    const handleMultiCheckBoxChange = ( event, id ) => {
        const option = event.target.value;
        setResponse( ( prevResponse ) => {
            const updatedResponse = prevResponse.map( ( item ) => {
                if ( item.questionId === id ) {
                    const updatedAnswer = item.answer || [];
      
                    if ( updatedAnswer.includes( option ) ) {
                        updatedAnswer.splice( updatedAnswer.indexOf( option ), 1 );
                    } else {
                        updatedAnswer.push( option );
                    }
                    return {
                        ...item,
                        answer: updatedAnswer,
                    };
                }
                return item;
            } );
            return updatedResponse;
        } );
    };
      
   
    const renderInputType = ( type, options, id ) => {
        switch ( type ) {
            case 4:                
                return (
                    <input className="custom-input"  value={response.find( ( el ) =>( el.questionId === id ) )?.answer || ""} onChange={( event ) => handleTextInputChange(  event,id, type )} />
                );
            case 1:
                return (
                    <><p className="small-text">
                        <span className="star">*</span>multiple option selected
                    </p>
                    <div className="options-container">
                            
                        {options.map( ( option, index ) => (
                            <div key={index}>
                                <input
                                    type="checkbox"
                                    name={`option_${index}`}
                                    value={option.option_text}
                                    className="screening-radio-input"
                                    // checked={multiSelected.includes( option )}
                                    checked={response.find( ( el ) =>( el.questionId === id ) )?.answer && response.find( ( el ) =>( el.questionId === id ) )?.answer.includes( option.option_text )}
                                    onChange={( event )=>handleMultiCheckBoxChange( event, id )}
                                />
                                <label>{option.option_text}</label>
                            </div>
                        ) )}
                    </div>
                    </>
                );
            case 2:
                return (
                    <>  
                        <div className="screening-option-input">
                            {options.map( ( option, index ) => (
                                <div key={index}>
                                    <input
                                        type="checkbox"
                                        name={`option_${index}`}
                                        value={option.option_text}
                                        className="screening-radio-input"
                                        checked={ response.find( ( el ) =>( el.questionId === id ) )?.answer === option.option_text }
                                        onChange={( event ) => handleRadioChange( event, id, type )}
                                    />
                                    <label>{option.option_text}</label>
                                </div>
                            ) )}
                        </div>
                    </>
                );
            case 3:
                return (
                    <>
                        <p className="small-text">
                            <span className="star">*</span>Please enter a number
                        </p>
                        <input type="number" value={response.find( ( el ) =>( el.questionId === id ) )?.answer || ""} onChange={( event ) => handleNumericChange( event,id,type )} className="screening-numeric" />
                    </>
                );
            default:
                return null;
        }
    };
	
    useEffect( () =>{
        getSqData();
    },[] );

    useEffect( () =>{
        if( sqData.length > 0 ) {
            setResponse( sqData.map( item => {
                return { questionId:item.id, answer:"" };
            } ) );
        }
    },[sqData] );

    console.log( response );
    return(
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            size= {showScreening ? 'lg': 'md'}
            
        >
            <Modal.Header closeButton={showScreening ? false : true } className={showScreening ? 'screening-modal-header' : null}>                
                <Modal.Title>{j.job_title}</Modal.Title>
            </Modal.Header>
            {
                showScreening ? (
                    <Modal.Body className="screening-popup">
                        {sqData.length > 0 && sqData.map( ( item ) => (
                            <div key={item.id}>
                                <div className='screening-questions-number'>
                                    {`Q ${item.id}  `} {item.question}
                                </div>
                                <div className="answer-type">
                                    {renderInputType( item.type, item.options, item.id )}
                                </div>
                            </div>
                        ) )}
                    </Modal.Body>
                ):(
                    <Modal.Body>
                        {!state.isLogged ?
                            <Fragment>
                                <p>You need to Login/Register before apply to this position</p>
                                <p className="d-flex justify-content-around">
                                    <button
                                        onClick={()=> {
                                            PositionsStore.update( s => {
                                                s.applyModal = false;
                                                s.loginModal = true;
                                                s.createAccount = false;
                                            } );
                                        }}
                                        className="btn btn-sm btn-info">
                                        <FontAwesomeIcon icon={faUser} className="mr-2"/>
                                    Login
                                    </button>
                                    <button
                                        onClick={()=> {
                                            PositionsStore.update( s => {
                                                s.createAccount = true;
                                                s.applyModal = false;
                                                s.loginModal = false;
                                            } );
                                        }}
                                        className="btn btn-sm btn-primary">
                                        <FontAwesomeIcon icon={faUserPlus} className="mr-2"/>
                                    Register
                                    </button>
                                </p>
                            </Fragment>
                            :
                            <Fragment>
                               
                                <ApplyToJob job={j} setShowScreening={setShowScreening} showScreening={showScreening} />
    
                            </Fragment>
                        }
                    </Modal.Body>
                )
            }
            { showScreening && 
            
            <Modal.Footer className="screening-modal-footer">
                <button
                // variant="secondary"
                // className="btn btn-sm btn-secondary "
                    className="screening-close-btn"
                    onClick={handleClose}
                >
  Cancel
                </button>
                <button
                // variant="primary"
                    className="screening-save-btn "
                    onClick={applyToJob}
                >
  Apply
                </button>
            </Modal.Footer>
            }
            
        </Modal>
    );
}


