import React, { Fragment, useEffect, useState } from "react";
import { TextField, Tooltip } from "@material-ui/core";
import countryList from '../utils/location/country.json';
import util from "../utils/miniUtils";


export  default function LocationCX( { requiredData = null, data:{ country, state, city }, onChange } ) {
    // console.log(`country: ${country}, state: ${state}, city: ${city}`)
    // Saves all the lists of countries, states and cities
    const [countries] = useState( countryList );
    const [states, setStates] = useState( [] );
    const [required, setRequired] = useState( {} );
    
    useEffect( () => {
        if ( requiredData !== null ) {
            setRequired( requiredData );
        }
    }, [] );
	
    useEffect( () => {
        if ( util.noValues.includes( country ) ){
            let e = { target:{ value: util.user.country, name:"country" } };
            onChange( e );
        }
    },[country] );
	
    useEffect( () => {
        getStates( country );
    },[country] );
	
    // Retrieve list of countries
    const getStates = ( country ) => {
        if ( util.noValues.includes( country ) ){
            for ( let i = 0; i < countryList.length; i++ ) {
                if ( countryList[i].name === util.user.country ){
                    setStates( Object.assign( [], countryList[i].states ) );
                }
            }
        }else{
            for ( let i = 0; i < countryList.length; i++ ) {
                if ( countryList[i].name === country ){
                    setStates( Object.assign( [], countryList[i].states ) );
                }
            }
        }
        // const v = states.sort( ( a,b ) => ( a.name > b.name ) ? 1 : ( ( b.name > a.name ) ? -1 : 0 ) );
        // console.log(v)
    };
	
    return(
		
        <Fragment>
            <div className="mt-2"
            // className={`col-md-${cols} ${extraStyleClasses ? extraStyleClasses:""} `}
            >
                <Tooltip title={country ? country :"No country selected"}>
                    <span>
                        <TextField
                            select
                            size="small"
                            name="country"
                            className="w-100"
                            label={`Country ${required.country ? "*":""}`}
                            value={country ? country : ""}
                            onChange={onChange}
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{
                                native: true
                            }}
                            fullWidth
                        >
                            <optgroup label="Most Active">
                                <option value="">--</option>
                                <option value="United States">USA</option>
                                <option value="Canada">Canada</option>
                                <option value="India">India</option>
                                <option value="Australia">Australia</option>
                                <option value="Mexico">Mexico</option>
                                <option value="Japan">Japan</option>
                                <option value="Germany">Germany</option>
                                <option value="Puerto Rico">Puerto Rico</option>
                            </optgroup>
                            <optgroup label="All Countries">
                                <option value=""/>
                                {countries?.map( ( option, i ) => (
                                    <option key={i} value={option.name}>
                                        {option.name}
                                    </option>
                                ) )}
                            </optgroup>
                        </TextField>
                    </span>
                </Tooltip>
            </div>
            <div className="mt-2"
            // className={`col-md-${cols}  ${extraStyleClasses ? extraStyleClasses:""} `}
            >
                <Tooltip title={state ? state :"No state selected"}>
                    <span>
                        <TextField
                            select
                            size="small"
                            name="state"
                            className="w-100"
                            label={`State ${required.state ? "*":""}`}
                            value={state ? state : ""}
                            onChange={onChange}
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{
                                native: true
                            }}
                            fullWidth
                        >
                            <option value=""/>
                            {states?.sort( ( a, b ) => a.name.localeCompare( b.name ) ).map( ( option, i ) => (
                                <option key={i} value={option.name}>
                                    {option.name}
                                </option>
                            ) )}
                        </TextField>
                    </span>
                </Tooltip>
            </div>
            <div className="mt-2"
            //  className={`col-md-${cols} ${extraStyleClasses ? extraStyleClasses:""} `}
            >
                <TextField
                    size="small"
                    name="city"
                    className="w-100"
                    label={`City ${required.city ? "*":""}`}
                    value={city || ""}
                    onChange={onChange}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                />
				
            </div>
        </Fragment>
    );
}