import React, { Fragment, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import util from "../utils/miniUtils";


function Contact( props ) {
    // This modal will display the template of the email that will be send to the evaluator
    const [index] = useState( 0 );
    const [emailTemplate, setEmailTemplate] = useState( {} );
    const textAreaRef = useRef();
    useEffect( () => {
    },[] );


    function handleChange( e ) {
        const { name, value } = e.target;
        setEmailTemplate( { ...emailTemplate, [name]:value } );
    }

    async function sendRequest( ) {
        try {
            const d = {
                emailTemplate
            };
            const request = await util.axios.post(
                `${util.url}/contact`,d );
            const { error, msg } = request.data;
            if ( error ) return util.toast().error( msg );
            util.toast().success( msg );
            props.handleClose();
        }catch ( error ) {
            util.toast().error( error );
        }
    }
    return(
        <Fragment>
            <Modal centered size={"lg"} show={props.show} onHide={()=>props.handleClose()} backdrop="static" keyboard={false} open>
                <Modal.Header closeButton>
                    <Modal.Title>Email Contact Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12 mt-1">
                        <TextField
                            label="Name"
                            name="name"
                            value={emailTemplate.name}
                            onChange={handleChange}
                            placeholder="Enter Contact Name"
                            fullWidth
                        />
                    </div>
                    <div className="col-md-12 mt-1">
                        <TextField
                            label="Email"
                            name="email"
                            value={emailTemplate.email}
                            onChange={handleChange}
                            placeholder="Enter Contact Email"
                            fullWidth
                        />
                    </div>
                    <div className="col-md-12 mt-1">
                        <textarea
                            ref={textAreaRef}
                            className="form-control"
                            rows={10}
                            value={emailTemplate.description}
                            name="description"
                            onChange={handleChange}
                            placeholder="Enter Detailed Info on the Issue"

                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger btn-sm" onClick={()=>props.handleClose()}>
                        Close
                    </button>
                    <button className="btn btn-sm btn-primary" onClick={()=>sendRequest()}>
                        <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                        Send
                    </button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}

export default Contact;


