import React, { useEffect, useState } from "react";
import WorkHistorySingle from "./WorkHistorySingle";
import AddWorkHistory from "./AddWorkHistory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import util from "../../../utils/miniUtils";
import { Paper } from '@material-ui/core';
import { TalentStore } from "../TalentStore";

export default function WorkHistoryContainer( { talent_id } ) {

    const [dataList, setDataList] = useState( [] );
    const [showModal, setShowModal] = useState( false );

    const [loading, setLoading] = useState( false );

    useEffect( () => {
        getWorkHistory().then( null );
    }, [] );


    // get all available work history
    const getWorkHistory = async () => {
        try {
            setLoading( true );
            const response = await util.axios.get( `talent/work_history_list/${talent_id}/` );
            const { error, msg, data } = response["data"];
            if ( error ) throw new Error( msg );
            setDataList( Object.assign( [], data ) );
            setLoading( false );
            TalentStore.update( t => {
                t.talent.workHistory =   data;
            } );
        } catch ( e ) {
            setLoading( false );
            util.handleError( e );
        }
    };

    return (
        <div className="container-fluid mt-2">
            <div className="row">
                <div className="col-md-12">
                    <div className="d-flex justify-content-end mb-2">
                        <button onClick={() => setShowModal( true )}
                            className="btn btn-sm btn-primary ">
                            <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
						Work History
                        </button>
                    </div>
                    <div>
                        {
                            dataList.map( ( wh, i ) => {
                                return (
                                    <Paper key={i} elevation={1}>
                                        <WorkHistorySingle
                                            dataX={wh}
                                            reloadData={( d ) => getWorkHistory()}
                                        />
                                    </Paper>
                                );
                            } )
                        }
                    </div>
                </div>
                {
                    showModal ?
                        <AddWorkHistory
                            show={showModal}
                            hideModal={() => setShowModal( false )}
                            reloadData={( d ) => getWorkHistory()}
                            talent_id={talent_id}
                        />
                        :
                        null
                }

            </div>
            {util.LOADING_SCREEN( loading )}
        </div>
    );
}

