import React, { Fragment, useEffect, useState } from "react";
import util from "../../../../utils/miniUtils";
import ResumeFile from "./ResumeFile";
import useUploadResume from "./useUploadResume";
import UploadResumeForm from "./UploadResumeForm";


export default function ResumeContainer( { talent } ) {
	
    const {
        isResumeAvailable,
        onUploadNewResume,
        onChangeHandler,
        resumeState,
    } = useUploadResume();
	
    const [action, setAction] = useState( 1 );
	
    useEffect( () => {
		
        if ( !util.noValues.includes( talent.resume ) ) {
            setAction( 1 );
        } else {
            setAction( 2 );
        }
		
    }, [talent.resume] );
	
    const updateStoredTalentResume = () => {
        setAction( 1 );
    };
	
	
    return (
        <Fragment>
            {
                action === 1 ?
                    <ResumeFile
                        resume={isResumeAvailable( talent )}
                        onChangeResumeFile={() => setAction( 2 )}
                    />
                    :
                    null
            }
            {
                action === 2 ?
                    <UploadResumeForm
                        talent_id={talent.id}
                        onCancelResumeUpload={() => setAction( 1 )}
                        onChangeHandler={onChangeHandler}
                        resumeLoading={resumeState.resumeLoading}
                        onUploadNewResume={onUploadNewResume}
                        resumeData={resumeState.selectedFile}
                        updateStoredTalentResume={()=>setAction( 1 )}
                    />
                    :
                    null
            }
        </Fragment>
    );
	
	
}