import React from 'react';
import "./styles.css";



//all status image on every page at footer
export default function AllStatus() {
    return (
        <div className=" d-flex justify-content-end">
            <div className="target column"></div>
            <h6 className="">To be Started</h6>
            <div className="">|</div>
            <div className="target" style={{ backgroundColor:"#1a7fe4" }}></div>
            <h6 className="">Pending With Me</h6>
            <div className="">|</div>
            <div className="target" style={{ backgroundColor:"#e5de00" }}></div>
            <h6 className="">Pending</h6>
            <div className="">|</div>
            <div className="target bg-success"></div>
            <h6 className="">Completed</h6>
            <div className="">|</div>
            <div className="target bg-danger"></div>
            <h6 className="">Declined</h6>
        </div>
    );
}
