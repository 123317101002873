import React from 'react';
import util from '../../../utils/miniUtils';

const CollaborationSingle = ( { data } )=> {

    const { desc, createdAt } = data;
    const { name, candidate_id, profile_picture } = data.account;

    return(
        <div className="col mt-1 mb-2">	
            { util.user.candidate_id === Number( candidate_id ) ? 
                ( <div className="row d-flex align-items-center">
                    <div className='col-md-2 d-flex align-items-center text-center'>
                        <small className='text-secondary'>{util.humanDateTime( createdAt ).split( "at" ).join( "-" )}</small>
                    </div>
                    <div className="collab-desc col-md-8">
                        <div className="py-1 px-2">
                            <span>{desc}</span>
                        </div>
                        <span className='btn collab-deleteButton'>x</span>
                    </div>
                    <div className="col-md-2 d-flex flex-column text-center align-items-center">
                        <img className="rounded-circle mb-1" width={50} height={50} src="/images/avatar.png" alt="emy" />
                        {/* {
                            profile_picture ?
                                <img className="rounded-circle mb-1" width={50} height={50} src={`${util.url}/candidate/${candidate_id}/${profile_picture}`} alt="emy" />
                                :<img className="rounded-circle mb-1" width={50} height={50} src="/images/avatar.png" alt="emy" />
                        } */}
                    
                        <small>{name}</small>
                    </div>
                </div>
                ) : (
                    <div className="row d-flex align-items-center">
                        <div className="col-md-2 d-flex flex-column text-center align-items-center">
                            <img className="rounded-circle mb-1" width={50} height={50} src="/images/avatar.png" alt="emy" />
                            {/* {
                                profile_picture ?
                                    <img className="rounded-circle mb-1" width={50} height={50} src={`${util.url}/candidate/${candidate_id}/${profile_picture}`} alt="emy" />
                                    :<img className="rounded-circle mb-1" width={50} height={50} src="/images/avatar.png" alt="emy" />
                            } */}
                    
                            <small>{name}</small>
                        </div>
                        <div className="collab-desc col-md-8">
                            <div className="py-1 px-2">
                                <span>{desc}</span>
                            </div>
                            <span className='btn collab-deleteButton'>x</span>
                        </div>
                        <div className='col-md-2 d-flex align-items-center text-center'>
                            <small className='text-secondary'>{util.humanDateTime( createdAt ).split( "at" ).join( "-" )}</small>
                        </div>
                    </div> )
            }
        </div>
    );
};

export default CollaborationSingle;