import up from "../../../shared/checkboxSelector/images/up.png";
import down from "../../../shared/checkboxSelector/images/down.png";
import React, { useEffect, useState } from "react";
import CheckBoxStatusSelector from "../../../AppraisalSystem/shared/CheckboxStatusSelector";
import CheckBoxSelector from "../../../shared/checkboxSelector";
import util from "../../../utils/miniUtils";
import CustomSlider from "./CustomSlider";
import { Collapse } from "react-bootstrap";
import { Chip } from "@material-ui/core";
import countryList from '../../../utils/location/country.json';
import JobTitleSelector from "./JobTiltleSelecor";
const countryListFixed = util.fixCountryList();
const noticePeriodList=util.noticePeriod();

//Reusing the checkBoxSelector component instead of fieldSelector

export default function SideBarFilter( { reloadData } ) {
    // Data for filters
    const [filter, setFilter] = useState( {
        ready: false,
        loading: false,
        skills: [],
        job_title: [],
        country: countryListFixed,
        state: [],
        city: [],
        resume:[{ value:"All",label:"All",id:1, checked: false },{ value:"Yes",label:"Yes",id:2,checked: false },{ value:"No",label:"No",id:3,checked: false }],
        noticePeriod:noticePeriodList,
        min: 0,
        max: 0,
    } );
    const [open,setOpen]=useState( false );
    const [skillsList, setSkillsList] = useState( [] );
    const [jobsList, setJobsList] = useState( [] );

    const handleOpen = () =>{
        setOpen( !open );
    };
    
    
    const getListofSkills = async ( search ) => {
        try {
            search.replace( /\//g, "" );
            const encodedSearch = encodeURIComponent( search );
            const response = await util.axios.get( `catalog/catalog_skills_search/${encodedSearch}` );            
            const { error, msg, data } = response.data;
            if ( error ) return util.handleError( msg );
            setSkillsList( data );
        } catch ( e ) {
            util.handleError( e );
        }
    };

    const getListOfJobTitles = async ( search ) => {
        try {
            const response = await util.axios.get( `catalog/job_title_search?search=${search}` );
            const { error, msg, data } = response.data;
            if ( error ) return util.handleError( msg );
            setJobsList( data );
        } catch ( e ) {
            util.handleError( e );
        }
    };

    // Get states list from country name
    const getStatesList = ( countryName ) =>{
        let states= {};
        countryList.forEach( ( item )=>{

            if( item.name === countryName ){
                states[countryName]= item.states ;
            }} );
        return states;
    };

    useEffect( () => {
        // Every time country is changed, will retrieve the list of states
        let countries=filter.country.filter( ( item )=>item.checked );
        let states = {};
        for( let i=0;i<countries.length;i++ ){
            states = { ...states, ...( getStatesList( countries[i].value ) ) };
        }

        let temp = [];
        Object.keys( states ).forEach( el => temp.push( ...states[el] ) );
        temp = temp.map( ( el,i ) => ( {
            value: el.name,
            id: i + 1,
            label: el.name
        } ) );
        setFilter( ( prev )=>( { ...prev, state: [...temp] } ) );
    },[filter.country] );

    // Trigger in every change of filter
    useEffect( () => {
        if ( filter.ready ) {
            // console.log( 'query new data due changes' );
            reloadData( filter );
        }
    }, [filter] );
    
    if ( filter.loading ) {
        return util.LOADING_SCREEN( filter.loading );
    }
    // console.log( filter );

    return (
        <div className="container-fluid fontSize13">
            <div className="row sideBarFilter">
                <div className="col-md-12 p-sm-0 m-sm-0">
                    <div className="col-md-12 p-0 my-2">
                       
                        <SkillSelector
                            getSkillList={getListofSkills}
                            list={skillsList}
                            updateFilter={setFilter}
                            filteredSkills={filter.skills}
                            setSkillsList={setSkillsList}
                        />
                    </div>
                    <div className="col-md-12 p-0 my-2">
                       
                        <JobTitleSelector 
                            getJobList={getListOfJobTitles}
                            jobsList={jobsList}
                            updateFilter={setFilter}
                            filteredJobs={filter.job_title}
                            setJobsList={setJobsList}
                        />
                    </div>
                    
                   
                    
                    <CheckBoxSelector
                        isOpenByDeafult={true}
                        dataArray={filter.country}
                        title="Country"
                        updateData={( data ) =>
                            setFilter( { ...filter, country: data, ready: true } )
                        }
                    />

                    {filter.state.length > 0 ? <CheckBoxSelector
                        // isOpenByDeafult={true}
                        dataArray={filter.state}
                        title="States"
                        updateData={( data ) =>
                            setFilter( { ...filter, state: data, ready: true } )
                        }
                    /> : ""}
                    
                    <div onClick={handleOpen}>
                        <b>Expected Salary</b> {open ?
                            <img src={up} alt="" style={{ width: "10px" ,float:'right',marginTop:'3px' }} /> :
                            <img src={down} alt="" style={{ width: "10px", height: "8px" ,float:'right',marginTop:'3px' }} />}
                    </div>
                    
                    <Collapse in={open} collapsedHeight={0}>
                        <div>
                            <CustomSlider setFilter={setFilter} filter={filter}/>
                        </div></Collapse>
                    <hr />
                    <CheckBoxStatusSelector
                        dataArray={filter.noticePeriod}
                        title="Notice Period"
                        updateData={( data ) =>
                            setFilter( { ...filter, noticePeriod: data, ready: true } )
                        }
                    /> 
                    <CheckBoxStatusSelector
                        dataArray={filter.resume}
                        title="Resume"
                        updateData={( data ) =>
                            setFilter( { ...filter, resume: data, ready: true } )
                        }
                    /> 
                   
                   

                   
                </div>
            </div>
        </div>
    );
}


const SkillSelector = ( { getSkillList, list, updateFilter, filteredSkills, setSkillsList } ) => {
    const [skillName, setSkillName] = useState( '' );
    const [openReset,setOpenReset]=useState( false );

    const handleChange = ( e ) => {
        setSkillName( e.target.value );
    };

    const handleClick = ( val ) => {
        if( !filteredSkills.includes( val ) ) {
            updateFilter( prev => ( { ...prev, skills:[...prev.skills, val], ready: true } ) );
        }
    };

    const handleOpenSkill = () =>{
        setOpenReset( !openReset );

    };
    const deleteSkills = ( val ) => {
        const temp = filteredSkills.filter( item => item.id != val );
        updateFilter( prev => ( { ...prev, skills: [...temp] } ) );
    };

    const handleReset = () => {
        const temp = [];
        updateFilter( prev => ( { ...prev, skills: [...temp] } ) );
        setSkillName( '' );
        setSkillsList( [] );
        
    };

    useEffect( () =>{
        if( skillName ) {
            getSkillList( skillName );
        }
    },[skillName] );

    return(
        <div className="border-bottom pb-1">
            <div className="d-flex justify-content-between w-100">
                <label><strong>Skills</strong></label>
                <span className="isCursor" onClick={handleOpenSkill}>{openReset ?
                    <img src={up} alt="" style={{ width: "10px" ,float:'right',marginTop:'2px' }} /> :
                    <img src={down} alt="" style={{ width: "10px", height: "8px" ,float:'right',marginTop:'2px' }} />}</span>
            </div>
            <div>{filteredSkills.length ? filteredSkills.map( el => <Chip size="small" label={el.name} key={el.id} className="mr-1 mb-1" onDelete={() => deleteSkills( el.id )} /> ) : ''}</div>
            <Collapse in={openReset} collapsedHeight={0}>
                <div>
                    <span className="bt_reset text-secondary isCursor" onClick={handleReset}>Reset</span>
                    <input className="form-control" type="text" value={skillName} onChange={handleChange}/></div></Collapse>
            <ul className="skillsSelectorList">{list.length ? list.map( el => <li className="skillsSelectorList-item" key={el.id} onClick={() => handleClick( el )}>{el.name}</li> ) : ''}</ul>
        </div>
    );
};