import React from 'react';
import Status from '../shared/Status/Status';
import util from '../../utils/miniUtils';
import { Fragment,useState } from 'react';
import useMyAppraisals from './useMyAppraisals';
import { useEffect } from 'react';
import AppraisalsTable from './components/AppraisalsTable';
import NoAppraisal  from '../../../assets/images/no_appraisal.png';
import StartReview from '../shared/StartReview/StartReview';
import CheckTargets from '../shared/CheckTargets/CheckTargets';
import FinalAppraisal from '../shared/FinalAppraisal/FinalAppraisal';
import AllStatus from '../shared/Status/AllStatus';
import MediumDeviceStatus from '../shared/Status/MediumDeviceStatus';

const I_DON_HAVE_ANYTHING       = null;
const APPRAISAL_IS_NOT_READY    = 0;
const DISPLAY_CURRENT_APPRAISAL = 1;
const DISPLAY_APPRAISAL_RESULT  = 2;
const DISPLAY_ACCEPT_OR_DECLINE = 3;
const DISPLAY_START_REVIEW      = 4;


const CHECK_TARGETS =1;
const DETAILS=[2,4,6,8,10];
const WAITING_FOR_MANAGER=[3,5,7,9,11];
const WAITING_TIME=[12];
const START_REVIEW              = 13;
const DISPLAY_REVIEW_DETAILS=[14,15,16,17];
const COMPLETED              = 18;

const MyAppraisal = (  ) => {

    const { listOfAppraisals, getAllMyAppraisals, currentAppraisal, loading } = useMyAppraisals();
    // const { appraisal_id } = currentAppraisal;
    // console.log( currentAppraisal );
    console.log( util.user );
    const [detailData, setDetailData] = useState( {
        appraisal_id: null,
        employee_id: null,
        componentToMount:  DISPLAY_CURRENT_APPRAISAL
    } );

    const [filterState, setFilterState] = useState( {
        showFilter: false,    
        id: "",
        startDate:"",
        endDate:"",
    } );

    
    function handleDetail( rowData ) {
        const { gl_employee, appraisal_id, employee_id, appraisal_score, ap_period } = rowData;
        setDetailData( {
            ...detailData,
            appraisal_id: appraisal_id,
            employee_id: employee_id,
            name:gl_employee.account.name,
            appraisal_score:appraisal_score,
            ap_period:ap_period.period,
            componentToMount: DISPLAY_APPRAISAL_RESULT
        } );
    }

    function handleCheck( ) {
        // console.log( currentAppraisal );
        setDetailData( {
            ...detailData,
            appraisal_id: currentAppraisal.appraisal_id,
            employee_id: currentAppraisal.employee_id,
            name:currentAppraisal.gl_employee.account.name,
            componentToMount: handleCheckComponent( currentAppraisal.status ) 
        } );
        
    }
    function handleCheckComponent( status ){
        switch( status ){
           

            case [CHECK_TARGETS,...DETAILS,...WAITING_FOR_MANAGER].find( element => element === status ):
                return DISPLAY_ACCEPT_OR_DECLINE;
            case [START_REVIEW,...DISPLAY_REVIEW_DETAILS,COMPLETED].find( element => element === status ):
                return DISPLAY_START_REVIEW;

            case WAITING_TIME.find( element => element === status ):
                return DISPLAY_ACCEPT_OR_DECLINE;
            
                
            default:
                return "NO-VALUE";
        }
    }

    const handleBack = () => {
        setDetailData( {
            ...detailData,
            componentToMount: DISPLAY_CURRENT_APPRAISAL,
        } );
        getAllMyAppraisals();
    };

    // Show an specifict text depending on the status
    const buttonText = ( status ) => {
        if ( [1].includes( status ) ) return "Review Targets";
        if ( [2].includes( status ) ) return "L1 Reviewing Targets";
        if ( [4].includes( status ) ) return "L2 Reviewing Targets";
        if ( [6].includes( status ) ) return "L3 Reviewing Targets";
        if ( [8].includes( status ) ) return "HR Reviewing Targets";
        // if ( [10].includes( status ) ) return "L1 Targets Review";
        if ( [3].includes( status ) ) return "Declined";
        if ( [5].includes( status ) ) return "L1 Declined";
        if ( [7].includes( status ) ) return "L2 Declined";
        if ( [9].includes( status ) ) return "L3 Declined";
        if ( [11].includes( status ) ) return "HR Declined";
        if ( [12].includes( status ) ) return "View Targets";
        if ( [13].includes( status ) ) return "Start Self Evaluation";
        if ( [14].includes( status ) ) return "L1 Evaluation";
        if ( [15].includes( status ) ) return "L2 Evaluation";
        if ( [16].includes( status ) ) return "L3 Evaluation";
        if ( [17].includes( status ) ) return "HR Evaluation";
        if ( [18].includes( status ) ) return "Completed";
        return "No Appraisal";
    };

    

    useEffect( () => {
        getAllMyAppraisals();
    }, [] );
   
    // Wait until first request is done
    if ( loading ) return util.LOADING_SCREEN( loading );


    
    // if( appraisal_id === null && listOfAppraisals.length === 0 ){
    //     return "show 2  images of nothing";
    // }
    
    // if( appraisal_id === null && listOfAppraisals.length > 0 ){
    //     return "show no current appraisal but show table of past appraisals";
    // }
    
    // if( appraisal_id !== null && listOfAppraisals.length === 0 ){
    //     return "show current appraisal info but show image of not past appraisals";
    // }
    
    // if( appraisal_id !== null && listOfAppraisals.length > 0 ){
    //     return "show current appraisal and show table of past appraisals";
    // }
    
    // After first request the appraisal_id doesnt exist yet or is ZERO 
    if ( currentAppraisal?.appraisal_id === undefined || currentAppraisal?.appraisal_id === I_DON_HAVE_ANYTHING  || currentAppraisal?.status === APPRAISAL_IS_NOT_READY ) {
        
        if ( detailData.componentToMount === DISPLAY_APPRAISAL_RESULT ){
            return (
                <Fragment>

                    <FinalAppraisal data={detailData} handleBack={handleBack} />
                    <AllStatus />
                </Fragment>
            );
        }

        return (
            <Fragment>
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-md-12">
                            <div className='d-flex justify-content-center p-5'>
                                <div>
                                    <div className='d-flex justify-content-center'>
                                        <h1 className="nocurrentappraisal">No Current Appraisal</h1> 
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <img
                                            className="no_currentappraisal"
                                            width="60%"
                                            height="auto"
                                            alt="nocurrentappraisals"
                                            src={NoAppraisal}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    {
                        listOfAppraisals.length > 0 ?
                            <AppraisalsTable filterState={filterState} handleDetail={handleDetail} listOfAppraisals={listOfAppraisals} setFilterState={setFilterState} />
                            :  ""
                           
                    }
                </div>   </Fragment>
        );
    }

    // Display information of the current appraisal, status and table of past appraisals
    if ( detailData.componentToMount === DISPLAY_CURRENT_APPRAISAL ){
        // console.log( "DISPLAY_CURRENT_APPRAISAL" );
        return (
            <Fragment>
                <Status appraiseeStatus={currentAppraisal.status} l2_exist={currentAppraisal.l2_exist} l3_exist={currentAppraisal.l3_exist}/> 
                <MediumDeviceStatus appraiseeStatus={currentAppraisal.status} l2_exist={currentAppraisal.l2_exist} l3_exist={currentAppraisal.l3_exist}/>
                <div className='my-border d-flex justify-content-between'>
                    <h6 className='ap_txt'>{currentAppraisal.ap_period.period}</h6>
                    <h6 className='ap_txt'>{util.humanDateMonth( currentAppraisal.ap_period.startDate )} - {util.humanDateMonth( currentAppraisal.ap_period.endDate )}</h6>
                    <button className={`btn btn-sm customBtn my_bt statuses${ currentAppraisal.status && currentAppraisal.status}`} onClick={() => handleCheck( )}>
                        {buttonText( currentAppraisal.status )}
                    </button>
                </div> 
                <div className="col-md-12">
                    {
                        listOfAppraisals.length > 0 ?
                            <AppraisalsTable filterState={filterState} handleDetail={handleDetail} listOfAppraisals={listOfAppraisals} setFilterState={setFilterState}  />
                            :
                            <> 
                                <div className='d-flex justify-content-center align-items-center'> 
                                    <h1 className="noappraisal">NoPastAppraisals</h1> 
                                    <img
                                        className="no_appraisal"
                                        width="60%"
                                        height="auto"
                                        alt="nopastappraisals"
                                        src={NoAppraisal}
                                    />
                                </div>
                                <AllStatus />
                            </> 
                    }
                </div>
            </Fragment>
        );
    }

    // Start Review
    if ( detailData.componentToMount === DISPLAY_START_REVIEW ){
        return (
            <Fragment>
                <StartReview  user={currentAppraisal.gl_employee.account} currentAppraisal={currentAppraisal}  appraisal_id={detailData.appraisal_id}
                    role="employee" handleBack={handleBack}/>
                <AllStatus />
            </Fragment>
        );
    }
   

    // Display appraisal for ACCEPTANCE OR DECLINE ( only buttons of accept or decline)
    if ( detailData.componentToMount === DISPLAY_ACCEPT_OR_DECLINE ){
        // console.log( "DISPLAY_ACCEPT_OR_DECLINE" );
        return (
            <Fragment>
                <CheckTargets data={detailData} id={detailData.appraisal_id} handleBack={handleBack} role="employee"/>

                <AllStatus />
            </Fragment>
        );
    }

    // Display appraisal on mode read only, is displayin the final resulto afetr everyone have given their evaluation
    if ( detailData.componentToMount === DISPLAY_APPRAISAL_RESULT ){
        return (
            <Fragment>
                <FinalAppraisal data={detailData} handleBack={handleBack}  role="employee" />
                <AllStatus />
            </Fragment>
        );
    }

};
export default MyAppraisal;



