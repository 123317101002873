import util from "../../../utils/miniUtils";
import MaterialTable from "material-table";
import { MuiThemeProvider } from "@material-ui/core";
import React, { useState, Fragment, useEffect } from "react";

/**
 * This component will take care of the CRUD
 * of "reasons" for the talent, when he wants to withdraw the position.
 * @returns {string}
 * @constructor
 */
export default function WithdrawCatalog() {
	
    const [reasons, setReasons] = useState( [] );
    const [loading, setLoading] = useState( false );
    const columns = [
        {
            title: 'Reason', field: 'reason',
            editComponent: props => (
                <input
                    autoFocus={true}
                    type="text"
                    value={props.value ? props.value : "" }
                    className="form-control"
                    onChange={e => props.onChange( e.target.value )}
                />
            )
        }
    ];
	
    useEffect( () => {
        getReasons().then( null );
    },[] );
	
    const getReasons = async () => {
        try {
            setLoading( true );
            const request = await util.axios.get( `${util.url}/withDrawReasons/list` );
            const { error, msg, reasons } = request["data"];
            if ( error ) throw msg;
            setReasons( reasons );
            setLoading( false );
        }catch ( e ) {
            setLoading( false );
            util.handleError( e );
        }
    };
	
	
    async function addReason( newData ) {
        try {
            const request = await util.axios.post( `${util.url}/withDrawReasons`, newData );
            const { error, msg, reasons } = request["data"];
            if ( error ) throw msg;
            setReasons( reasons );
        } catch ( e ) {
            util.handleError( e );
        }
    }
    async function updateReason( newData ) {
        try {
            const request = await util.axios.put( `${util.url}/withDrawReasons/${newData.id}`, newData );
            const { error, msg, reasons } = request["data"];
            if ( error ) throw msg;
            setReasons( reasons );
        } catch ( e ) {
            util.handleError( e );
        }
    }
    async function deleteReason( newData ) {
        try {
            const request = await util.axios.delete( `${util.url}/withDrawReasons/${newData.id}` );
            const { error, msg, reasons } = request["data"];
            if ( error ) throw msg;
            setReasons( reasons );
        } catch ( e ) {
            util.handleError( e );
        }
    }
	
    return (
        <Fragment>
            <div className="container-fluid">
                <MuiThemeProvider theme={util.defaultTableTheme}>
                    <MaterialTable
                        columns={columns}
                        data={reasons}
                        options={{
                            pageSize: 10,
                            sorting: false,
                            showFirstLastPageButtons: false,
                            pageSizeOptions: [],
                            showTitle: false,
                            padding: "default",
                            paginationType: "stepped",
                            emptyRowsWhenPaging:true
                        }}
                        editable={{
                            onRowAdd: newData =>
                                new Promise( ( resolve, reject ) => {
                                    setTimeout( () => {
                                        setReasons( [...reasons, newData] );
                                        addReason( newData ).then( null );
                                        resolve();
                                    }, 500 );
                                } ),
                            onRowUpdate: ( newData, oldData ) =>
                                new Promise( ( resolve, reject ) => {
                                    setTimeout( () => {
                                        const dataUpdate = [...reasons];
                                        const index = oldData.tableData.id;
                                        dataUpdate[index] = newData;
                                        setReasons( [...dataUpdate] );
                                        updateReason( newData ).then( null );
                                        resolve();
                                    }, 500 );
                                } ),
                            onRowDelete: oldData =>
                                new Promise( ( resolve, reject ) => {
                                    setTimeout( () => {
                                        const dataDelete = [...reasons];
                                        const index = oldData.tableData.id;
                                        dataDelete.splice( index, 1 );
                                        setReasons( [...dataDelete] );
                                        deleteReason( oldData ).then( null );
                                        resolve();
                                    }, 500 );
                                } ),
                        }}
                    />
                </MuiThemeProvider>
            </div>
            {util.LOADING_SCREEN( loading )}
        </Fragment>
    );
}