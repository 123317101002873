import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse, Grid,ListItem,MenuItem, TextField } from "@material-ui/core";
import React, { Fragment, useState } from "react";

const EvaluatorsSelectorAccordion = ( { handleChange, state, evaluators } ) => {
    // const [IsOpen, setIsOpen] = useState( true );
    const [open, setOpen] = useState( false );
    const { l1_evaluator, l2_evaluator, l3_evaluator, hr_evaluator } = state;

    return (
        <Fragment>
            {/* <div className="col-3 isPointer">
                <button className="toggle" onClick={() => setIsOpen( !IsOpen )}>
                    <small className="text-secondary">Evaluators </small>
                    <FontAwesomeIcon icon={IsOpen ? faCaretDown: faCaretRight} className='iconGray'/>
                </button>
            </div> */}
            <Grid container spacing={1}>
                <Grid item xs={12} md={3} lg={2}>
                    <ListItem><small className="text-secondary ml-2"
                        onClick={() => setOpen( !open )}>Evaluators<FontAwesomeIcon icon={!open ? faCaretDown: faCaretRight} className='iconGray ml-1'/></small>

                    </ListItem>
                </Grid>

                <Grid item xs={12} md={8} lg={8}>
                    <Collapse in={open}>
                        <Grid container spacing={1} >
                            <Grid item xs={12} md={6} lg={6} >
                                <Evaluators
                                    data={evaluators}
                                    handleChange={handleChange}
                                    id="l1ManagerID"
                                    name="l1_evaluator"
                                    title="L1 Evaluator"
                                    value={l1_evaluator}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <Evaluators
                                    data={evaluators}
                                    handleChange={handleChange}
                                    id="l2ManagerID"
                                    name="l2_evaluator"
                                    title="L2 Evaluator"
                                    value={l2_evaluator}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} style={{ marginTop:15 }}>
                            <Grid item xs={12} md={6} lg={6}>
                                <Evaluators
                                    data={evaluators}
                                    handleChange={handleChange}
                                    id="l3ManagerID"
                                    name="l3_evaluator"
                                    title="L3 Evaluator"
                                    value={l3_evaluator}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <Evaluators
                                    data={evaluators}
                                    handleChange={handleChange}
                                    id="hrManagerID"
                                    name="hr_evaluator"
                                    title="HR Evaluator"
                                    value={hr_evaluator}
                                />
                            </Grid>
                        </Grid></Collapse>
                </Grid>
            </Grid>
            {/* <div className={`col-md-9 ${IsOpen ? "content show" : "content"}`}>
                <Grid container spacing={1} >
                    <Grid item xs={12} md={6} lg={6}>
                        <Evaluators
                            data={evaluators}
                            handleChange={handleChange}
                            id="l1ManagerID"
                            name="l1_evaluator"
                            title="L1 Evaluator"
                            value={l1_evaluator}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Evaluators
                            data={evaluators}
                            handleChange={handleChange}
                            id="l2ManagerID"
                            name="l2_evaluator"
                            title="L2 Evaluator"
                            value={l2_evaluator}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Evaluators
                            data={evaluators}
                            handleChange={handleChange}
                            id="l3ManagerID"
                            name="l3_evaluator"
                            title="L3 Evaluator"
                            value={l3_evaluator}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Evaluators
                            data={evaluators}
                            handleChange={handleChange}
                            id="hrManagerID"
                            name="hr_evaluator"
                            title="HR Evaluator"
                            value={hr_evaluator}
                        />
                    </Grid>
                </Grid>
            </div> */}
        </Fragment>
    );
};

export default EvaluatorsSelectorAccordion;

//function for showing evaluators

const Evaluators = ( { id, title, name, value, handleChange, data } ) => {
    return (
        <div className="ap_eval m-1">
            <label className="fontSize11" htmlFor={id}>
                {title}
            </label>
            <select id={id} className="form-control" value={value} name={name} onChange={handleChange}>
                <option value={null}>--</option>;
                {data?.map( ( item, i ) => {

                    return (
                        <option key={i} value={item.id}>{item.name}</option>
                    );
                } )}
            </select>
        </div>
        // <>
           
    //     <TextField label={title} id={id}  value={value} name={name}  select variant='outlined' className="w-75" onChange={handleChange}>
    //         <MenuItem value={null}>
    //             None
    //         </MenuItem>
    //         {data?.map( ( item, i ) => {
    //             return (
    //                 <MenuItem key={i} value={item.id}>{item.name}</MenuItem>
    //             );
    //         } )}
    //     </TextField>
    // </>
    );
};
