import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { faSave } from "@fortawesome/free-solid-svg-icons/faSave";

export default function SkillsContainer( { data, onChange } ) {
	
    const [skills, setSkills] = useState( [] );
    const[isSaved, setIsSaved] = useState( true );
    // const [skill, setSkill] = useState( { skill_name:"", years_experience:"", skill_type:2 } );
	
    useEffect( () => {
        if ( data.skills?.length > 0 ){
            setSkills( data.skills );
        }
    },[data] );
	
    useEffect( () => {
    },[skills] );
	
    function handleChanges( e, index ) {
        const { name, value } = e.target;
        let t = [...skills];
        t[index][name] = value;
        setSkills( t );
        setIsSaved( false );
    }
	
    function deleteSkill( index ) {
        // console.log( index );
        let t = [...skills];
        delete t[index];
        // console.log( skills.length );
        t = t.filter( s => s );
        // console.log( t.length );
        setSkills( Object.assign( [], t ) );
    }
	
    // function handleChangesNew( e ) {
    //     const { name, value } = e.target;
    //     let temp = { ...skill, [name]:value };
    //     setSkill( temp );
    // }
	
    function addSkill() {
        let t = [...skills];
        t.push( { skill_name:"", years_experience:"", skill_type:1 } );
        setSkills( t );
    }
	
    const saveSkills = () => {
        let e = { target : { name: 'skills', value: skills } };
        onChange( e );
        setIsSaved( true );
        // updateData( skills );
    };
	
    return(
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <table className="w-100 table-hover">
                        <thead>
                            <tr>
                                <th>Skill Name</th>
                                <th className="text-center">Years of Exp.</th>
                                <th/>
                            </tr>
                        </thead>
                        <tbody>
                            {skills && skills.map( ( s,i ) =>{
                                return <tr key={i} >
                                    <td className="p-1" width={"85%"}>
                                        <input
                                            size={"small"}
                                            value={s.skill_name}
                                            className="form-control"
                                            name="skill_name"
                                            onChange={( e )=>handleChanges( e, i )}
                                        />
                                    </td>
                                    <td width={"10%"}>
                                        <input
                                            size={"small"}
                                            value={s.years_experience ? s.years_experience : 0}
                                            type="number"
                                            name="years_experience"
                                            className="form-control text-right"
                                            onChange={( e )=>handleChanges( e, i )}
                                        />
                                    </td>
                                    <td width={"5%"} className="p-1">
                                        <FontAwesomeIcon className="isCursor text-black-50 text-danger redIcon" icon={faTimesCircle} onClick={()=> deleteSkill( i )} />
                                    </td>
                                </tr>;
                            } )}
                        </tbody>
                    </table>
                </div>
            </div>
            <hr/>
            <div className="row">
                <div className="col-md-12 text-right p-2">
                    <button onClick={addSkill} className="btn btn-sm btn-info mr-1">
                        <FontAwesomeIcon icon={faPlusCircle} className="mr-2"/>
						Skill
                    </button>
                    {!isSaved ? <button onClick={saveSkills} className="btn btn-sm btn-primary">
                        <FontAwesomeIcon icon={faSave} className="mr-2"/>
						Save Skills
                    </button> : ""}
                </div>
            </div>
        </div>
    );
}