import util from "../../components/utils/miniUtils";

const intitialState = [];

const screeningQuestionsReducer = ( state = intitialState, action ) => {
    switch( action.type ) {
        case 'HANDLE_QUESTION_UPDATE': {
            {
                const { name, value, questionIndex } = action.payload;
                let tempList = [...state];
                let temp = state[questionIndex];
                temp[name] = value;
                tempList.splice( questionIndex, 1, temp );
                return state = ( [...tempList] );
            }
        }
        case 'HANDLE_QUESTION_TYPE_UPDATE': {
            const tempQuestionList = state;
            const { questionIndex, value } = action.payload;
            let temp = state[questionIndex];
            switch( value ) {
                case 1:
                    temp.type = value;
                    if( !temp.options || temp.options?.length === 0 ) {
                        temp.options = [
                            { option_text: "Option 1" },
                            { option_text: "Option 2" }
                        ];
                    }
                    break;
                case 2:
                    temp.type = value;
                    if( !temp.options || temp.options?.length === 0 ) {
                        temp.options = [
                            { option_text: "Yes" },
                            { option_text: "No" },
                        ];
                    }
                    break;
                default:
                    temp.type = value;
                    temp.options = [];
                    break;
            }
            tempQuestionList.splice( questionIndex, 1, temp );
            return state = [...tempQuestionList];
        }
        case 'ADD_QUESTION':
            return [...state, { question: "", type: 4, options: [] }];
        case 'REMOVE_QUESTION': {
            const tempQuestionList = state;
            tempQuestionList.splice( action.payload, 1 );
            return state = [...tempQuestionList];
        }
        case 'HANDLE_OPTION_UPDATE': {
            const { value, optionIndex, questionIndex } = action.payload;
            let tempQuestion = { ...state[questionIndex] };
            tempQuestion.options[optionIndex].option_text = value;
            let tempQuestionList = [...state];
            tempQuestionList.splice( questionIndex, 1, tempQuestion );
            return state = [...tempQuestionList];
        }
        case 'ADD_SQ_OPTION': {
            let questionIndex = action.payload;
            let tempQuestion = { ...state[questionIndex] };
            tempQuestion.options.push( { option_text: "" } );
            let tempQuestionList = [...state];
            tempQuestionList.splice( questionIndex, 1, tempQuestion );
            return state = [...tempQuestionList];
        }
        case 'REMOVE_SQ_OPTION': {
            const { optionIndex, questionIndex } = action.payload;
            let tempQuestion = { ...state[questionIndex] };
            tempQuestion.options.splice( optionIndex, 1 );
            let tempQuestionList = [...state];
            tempQuestionList.splice( questionIndex, 1, tempQuestion );
            return state = [...tempQuestionList];
        }
        case 'ADD_QUESTION_FROM_LIBRARY': {
            let question = action.payload;
            if( question.options === null ) {
                question.options = [];
            }
            if( state.filter( el => el.id == question.id ).length > 0 ) {
                util.toast().error( "Qustion is already added" );
                return state;
            }
            return [...state, question];
        }
        default: 
            return state;
    }
};

export default screeningQuestionsReducer;