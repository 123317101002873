import React, { Fragment, useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import util from "../../../utils/miniUtils";
import selector from "../../../utils/selector";
import { Editor } from "@tinymce/tinymce-react";
import "../../../../assets/css/main.css";
import SAlert from "../../../microComponents/AlertSA";
import ModalSelectPartnerClient from "./ModalSelectPartnerClient";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faSave } from "@fortawesome/free-solid-svg-icons";
import { TextField } from "@material-ui/core";
import LocationCX from "../../../shared/LocationCX";
import SkillSelector from "../../../shared/selector/SkillSelector";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ModalScreeningQuestions from "./ModalScreeningQuestions";
const classLabelsMUI = "MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled";

function JobPositionForm( props ) {
    const [job, setJobData] = useState( props.job );
    const [backUpJob, setBackUp] = useState( job );
    const [isDisabled, setIsDisabled] = useState( props.job.status === 3 );
    const [marginShow, setMarginShow] = useState( false );
    const [modalVisible, setModalVisible] = useState( false );
    const [modalType, setModalType] = useState( false );
    const [marginLower, setMarginLower] = useState( false );
    const [isVisible, setIsVisible] = useState( true );
    const [recruiters, setRecruiters] = useState( [] );
    const [catalogJobDesc, setCatalogJobDesc] = useState( [] );
    const [disabledByOnboarding, setDisabledByOnboarding] = useState( false );
    const [skillsList, setSkillsList] = useState( [] );
    const [showScreeningQuestions, setShowScreeningQuestions] = useState( false );
    // const [skills,setSkills] = useState( props.skills );
    const PARTNER = 4;
    const CLIENT = 3;
    useEffect( () => {
    // Listen when update is pressed from accordion
        if ( props.updateChanges !== null ){
            handleUpdateJobPosition();
        }
        // eslint-disable-next-line
    },[props.updateChanges] );
  
    useEffect( () => {
    // Listen when cancel is pressed from accordion
        if ( props.cancelChanges !== null ){
            setJobData( backUpJob );
        }
        // eslint-disable-next-line
    },[props.cancelChanges] );
  
    useEffect( () => {
    // After the job is loaded, we need to update the UI of the deal type and hide partner if is direct client
    // This occurs only one time after load the job information
        if ( job.deal_type === "Direct Client" ){
            setIsVisible( false );
        }else{
            setIsVisible( true );
        }
    },[job] );

    useEffect( () => {
        getRecruiters().then( null );
        getCatalog().then( null );
    }, [] );
    
    useEffect( () => {
        let disable = false;
        props.talents.forEach( d => {
            if ( parseInt( d.status ) === 13 ){
                disable = true;
            }
        } );
        setDisabledByOnboarding( disable );
    }, [props.talents] );

    useEffect( () => {
    // Refresh al fields with the data of the selected job from the sidebar
        setIsDisabled( props.job.status === 3  );
        return () => { };
    }, [props.job.status] );

    async function getRecruiters() {
        try {
            const request = await util.axios.get( `${util.url}/app_resources/users_by_role/1` );
            const { error, msg, users } = request.data;
            if ( error ) throw msg;
            setRecruiters( users );
        } catch ( error ) {
            util.handleError( error );
        }
    }

    // const getListofSkills = async ( search ) => {
    //     try {
    //         const response = await util.axios.get( `catalog/catalog_skills_search/${search}` );
    //         const { error, msg, data } = response.data;
    //         if ( error ) return util.handleError( msg );
    //         setSkillsList( data );
    //     } catch ( e ) {
    //         util.handleError( e );
    //     }
    // };
  
    const getListofSkills = async ( search ) => {
        try {
            search.replace( /\//g, "" );
            const encodedSearch = encodeURIComponent( search );
            const response = await util.axios.get( `catalog/catalog_skills_search/${encodedSearch}` );
            const { error, msg, data } = response.data;
            if ( error ) return util.handleError( msg );
            setSkillsList( data );
        } catch ( e ) {
            util.handleError( e );
        }
    };
    const getCatalog = async () => {
    // Make a request for catalog of job descriptions
        try {
            const response = await util.axios.get( `${util.url}/app_resources/job_descriptions` );
            const { error, msg, job_descriptions } = response["data"];
            if ( error ) return util.handleError( msg );
            setCatalogJobDesc( job_descriptions );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    function handleChange( e ) {
        let { name, value } = e.target;
        if ( name === "deal_type" ){
            if ( value === "Direct Client" ){
                setIsVisible( false );
            }else{
                setIsVisible( true );
            }
        }
        setJobData( { ...job, [name]: value } );
    }

    function handleRichTextChange( e ) {
        const value = e.toString( "html" );
        setJobData( { ...job, 'job_description': value } );
    }

    function handleUpdateJobPosition() {
        if ( marginLower && job.margin < 20 ){
            util.toast().warn( "You're using a lower margin tan 20%" );
            return;
        }
        util
            .axios
            .patch( `${util.url}/client_requirements/update/${job.id}`, job )
            .then( response => {
                const { error, msg, job } = response.data;
                if ( error ) throw ( msg );
                props.handleChange( job );
                util.toast().success( msg );
                setBackUp( job );
            } )
            .catch( err => util.toast().error( err ) );
    }

    function onConfirmLowerMargin() {
        setMarginLower( true );
    }

    function selectAction( modalOption ){
        setModalType( modalOption );
        setModalVisible( true );
    }
  
    function hideModal(){
        setModalType( PARTNER );
        setModalVisible( false );
    }

    function selectedData ( opc, data ) {
        let jobTemp  = job;
        if ( opc === PARTNER ) {
            jobTemp.partner =  data;
        } else {
            jobTemp.client =  data;
        }
        setJobData( Object.assign( {}, jobTemp ) );
    }
  
    function handleChangeAutoComplete( value ) {
    // let e ={target:{name: "job_title", value: value}}
    // handleChange(e);
    // must trigger job description
        const j = catalogJobDesc.filter( ( j ) => j.title === value )[0];
        // let e2 ={target:{name: "job_description", value: j.description}}
        // handleChange(e2);
        setJobData( { ...job, job_title: value, job_description: j.description } );
    }

    function handleSkillUpdate( name,value ) {
        // setJobData( { ...job, skills: [value] } );
        if( name === "skills" ) {
            setJobData( ( prevJob ) => ( { ...prevJob, skills: [...prevJob.skills, value] } ) );
        }
        else {
            setJobData( ( prev ) => ( { ...prev, skills: [...value] } ) );
        }
    }

    return (
        <Fragment>
            <div className="row">
                {/* LEFT SECTION */}
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-md-9">
                            <Autocomplete
                                freeSolo
                                value={job.job_title}
                                disableClearable
                                disabled={isDisabled}
                                onChange={( e, value ) => handleChangeAutoComplete( value )}
                                options={catalogJobDesc.map( ( option ) => option.title )}
                                renderInput={( params ) => (
                                    <TextField
                                        {...params}
                                        label="Job Title *"
                                        margin="normal"
                                        name="job_title"
                                        // value={job.job_title}
                                        onChange={handleChange}
                                        variant="standard"
                                        InputProps={{ ...params.InputProps, type: "search" }}
                                    />
                                )}
                            />
                        </div>
                        <util.RowComponent
                            disabled={isDisabled}
                            c={3}
                            t={"Certification"}
                            n={"certification"}
                            m={handleChange}
                            v={job.certification||""}
                        />

                    </div>
            
                    {/*<b>Skills and Scope</b>*/}
                    <div className="row">
                        {/* <util.RowComponent
                            disabled={isDisabled}
                            c={4}
                            t={"Primary Skill"}
                            n={"primary_skill"}
                            m={handleChange}
                            v={job.primary_skill || ""}
                        />
                        <util.RowComponent
                            disabled={isDisabled}
                            c={4}
                            t={"Secondary Skill"}
                            n={"secondary_skill"}
                            m={handleChange}
                            v={job.secondary_skill || ""}
                        /> */}
                        <div className="col-md-8">
                            <SkillSelector
                                getSkillList={getListofSkills}
                                list={skillsList}
                                updateFilter={handleSkillUpdate}
                                filteredSkills={job.skills}
                                setSkillsList={setSkillsList}
                            />
                        </div>
                        <util.RowComponent
                            disabled={isDisabled}
                            c={4}
                            t={"Role"}
                            n={"role"}
                            m={handleChange}
                            v={job.role || "Technician"}
                            type={1}
                            d={selector.role()}
                        />
                        <util.RowComponent
                            disabled={isDisabled}
                            c={4}
                            t={"Scope"}
                            n={"scope"}
                            m={handleChange}
                            v={job.scope || "Project Support"}
                            type={1}
                            d={selector.scope()}
                        />
                        <util.RowComponent
                            disabled={isDisabled}
                            c={4}
                            t={"Level"}
                            n={"level"}
                            m={handleChange}
                            v={job.level || "Experienced (L2)"}
                            type={1}
                            d={selector.level()}
                        />
                        <util.RowComponent
                            disabled={isDisabled}
                            c={4}
                            t={"Education"}
                            n={"education"}
                            m={handleChange}
                            v={job.education || "University"}
                            type={1}
                            d={selector.education()}
                        />
              
  
                    </div>
                    <div className="paddingleft0 col-md-12 mt-2">
                        <label className="text-muted textSmallLH">Job Description *</label>
                        <Editor
                            apiKey="co5tvvfh4ltli0l9urtqbvfbrevuswtkqdmu3lvf0t3lme9p"
                            value={job.job_description || ""}
                            disabled={isDisabled}
                            init={{
                                height: 500,
                                menubar: false,
                                toolbar:
                        "formatselect | bold italic| alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat"
                            }}
                            onEditorChange={handleRichTextChange}
                        />
                    </div>
                </div>

                {/* RIGHT SECTION */}
                <div className="col-md-6">
                    <b style={{ marginLeft:"-2px" }}>Basic Information</b>
                    <div className="row">
                        <div className={`col-md-3 p-1 mt-2`}>
                            <TextField
                                select
                                size={"small"}
                                disabled={isDisabled}
                                name="deal_type"
                                className="w-100"
                                label="Deal Type *"
                                value={job.deal_type || "Channel Partner"}
                                onChange={handleChange}
                                InputLabelProps={{ shrink: true }}
                                SelectProps={{
                                    native: true
                                }}
                    
                            >
                                {selector.dealType().map( option => (
                                    <option key={Math.random() * Math.random() } value={option["deal_type"]}>
                                        {option["deal_type"]}
                                    </option>
                                ) )}
                            </TextField>
                        </div>
                        {isVisible ?
                        // If is direct client, this will not be displayed
                            <div className={`col-md-3 col-xs-12 p-1 mt-2`}>
                                <div className={classLabelsMUI}>Partner Information</div>
                                {job.partner !== null ?
                                    <util.BoxSimple data={job.partner} editInfo={()=>selectAction( PARTNER )} disabled={isDisabled} />
                                    :<button disabled={isDisabled} className="btn btn-default"  onClick={()=> selectAction( PARTNER )}>Select partner <FontAwesomeIcon icon={faPencilAlt}/></button>}

                            </div>:""}

                        <div className={`col-md-3 col-xs-12 p-1  mt-2`}>
                            <div className={classLabelsMUI}>End Client</div>
                            {job.client !== null ? <util.BoxSimple data={job.client} editInfo={()=>selectAction( CLIENT )}  disabled={isDisabled} />: ""}
                        </div>
                        <div className={`col-md-3 col-xs-12 p-1  mt-2`}>
                            <div className={classLabelsMUI}>Client Reference ID</div>
                            <util.RowComponent
                                disabled={isDisabled}
                                n={"client_requirement_id"}
                                m={handleChange}
                                v={job.client_requirement_id || ""}
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <util.RowComponent
                            disabled={isDisabled}
                            c={3}
                            t={"Employment Type"}
                            n={"employment_type"}
                            m={handleChange}
                            v={job.employment_type || "Any"}
                            type={1}
                            d={selector.employment_type()}
                        />
                        <util.RowComponent
                            disabled={isDisabled}
                            c={3}
                            t={"Restricted To"}
                            n={"restricted_to"}
                            m={handleChange}
                            v={job.restricted_to}
                            type={1}
                            d={selector.restricted_to()}
                        />

                        <util.RowComponent
                            disabled={isDisabled}
                            c={3}
                            t={"Duration in months *"}
                            n={"duration"}
                            m={handleChange}
                            v={job.duration}
                        />

                        <util.RowComponent
                            disabled={isDisabled}
                            c={3}
                            t={"Priority"}
                            n={"priority"}
                            m={handleChange}
                            v={job.priority || "Mid"}
                            type={1}
                            d={selector.priority()}
                        />

                    </div>
                    <b>Compensation Range</b>
                    <div className="form-row">
                        
                        <div className={`col-md-3 p-1 mt-2 align-self-center`}>
                            <TextField
                                size={"small"}
                                className="w-100"
                                disabled={isDisabled}
                                InputLabelProps={{ shrink: true }}
                                value={ job.buy_from || "" }
                                type="number"
                                label="Minimum"
                                fullWidth={true}
                                InputProps={{
                                    startAdornment: util.symbolDependingCurrencySelected( job.country )
                                }}
                                variant="standard"
                                name="buy_from"
                                onChange={handleChange}
                            />
                        </div>
                        <div className={`col-md-3 p-1 mt-2 align-self-center`}>
                            <TextField
                                size={"small"}
                                className="w-100"
                                disabled={isDisabled}
                                InputLabelProps={{ shrink: true }}
                                value={ job.buy_to || "" }
                                type="number"
                                label="Maximum"
                                fullWidth={true}
                                InputProps={{
                                    startAdornment: util.symbolDependingCurrencySelected( job.country )
                                }}
                                variant="standard"
                                name="buy_to"
                                onChange={handleChange}
                            />
                        </div>
                        <div className={`col-md-3 p-1 mt-2 align-self-center`}>
                            <TextField
                                size={"small"}
                                className="w-100"
                                disabled={isDisabled}
                                InputLabelProps={{ shrink: true }}
                                value={ job.sell_rate || "" }
                                type="number"
                                label="Job Sell Rate"
                                fullWidth={true}
                                InputProps={{
                                    startAdornment: util.symbolDependingCurrencySelected( job.country )
                                }}
                                variant="standard"
                                name="sell_rate"
                                onChange={handleChange}
                            />
                        </div>

                        <util.RowComponent
                            disabled={isDisabled}
                            c={3}
                            t={"Offer Type"}
                            n={"sell_rate_type"}
                            m={handleChange}
                            v={job.sell_rate_type || "Per Hour"}
                            type={1}
                            d={selector.sell_rate_type3}
                        />
                    </div>

                    <b>Location</b>
                    <div className="form-row">
  
                        <LocationCX
                            cols={4}
                            data={job} // contains everything
                            onChange={handleChange}
                            requiredData={{
                                state: false,
                                country: false,
                                city: true,
                            }}
                        />
              
                        <util.RowComponent
                            disabled={isDisabled}
                            c={3}
                            t={"Zip Code"}
                            n={"zipCode"}
                            m={handleChange}
                            v={job.zipCode || ""}
                        />
                    </div>

            

                    <b>Onboarding Contacts</b>
                    <div className="form-row">
                        <util.RowComponent
                            disabled={isDisabled}
                            c={4}
                            t={"Reporting Manager"}
                            n={"reporting_manager"}
                            m={handleChange}
                            v={job.reporting_manager || ""}
                        />
                        <util.RowComponent
                            disabled={isDisabled}
                            c={4}
                            t={"Reporting Manager Phone"}
                            n={"reporting_manager_phone"}
                            m={handleChange}
                            v={job.reporting_manager_phone||""}
                        />
                        <util.RowComponent
                            disabled={isDisabled}
                            c={4}
                            t={"Reporting Manager Address"}
                            n={"reporting_manager_address"}
                            m={handleChange}
                            v={job.reporting_manager_address||""}
                        />

                        <div className={`col-md-4 p-1 mt-2`}>
                            <TextField
                                select
                                disabled={disabledByOnboarding}
                                name="sales_lead"
                                className="w-100"
                                label="Sales Lead *"
                                value={job.sales_lead || "None"}
                                onChange={handleChange}
                                InputLabelProps={{ shrink: true }}
                                SelectProps={{ native: true }}
                            >
                                <option value=""> -- </option>
                                {recruiters.map( ( option, index ) => (
                                    <option key={ index } value={option.id}>
                                        {option.name}
                                    </option>
                                ) )}
                            </TextField>
                        </div>

                        <div className={`col-md-4 p-1 mt-2`}>
                            <TextField
                                select
                                disabled={disabledByOnboarding}
                                name="lead_recruiter"
                                className="w-100"
                                label="Lead Recruiter *"
                                value={job.lead_recruiter || "None"}
                                onChange={handleChange}
                                InputLabelProps={{ shrink: true }}
                                SelectProps={{ native: true }}
                            >
                                <option value=""> -- </option>
                                {recruiters.map( ( option, index ) => (
                                    <option key={ index } value={option.id}>
                                        {option.name}
                                    </option>
                                ) )}
                            </TextField>
                        </div>

                        <div className={`col-md-4 p-1 mt-2`}>
                            <TextField
                                select
                                name="secondary_recruiter"
                                className="w-100"
                                label="Secondary Recruiter"
                                value={job.secondary_recruiter || "None"}
                                onChange={handleChange}
                                InputLabelProps={{ shrink: true }}
                                SelectProps={{ native: true }}
                            >
                                <option value=""> -- </option>
                                {recruiters.map( ( option, index ) => (
                                    <option key={ index } value={option.id}>
                                        {option.name}
                                    </option>
                                ) )}
                            </TextField>
                        </div>
                        {job.questions && <div className='col-md-12 p-1 mt-2'>
                            <span className="btn btn-sm btn-light" onClick={() => setShowScreeningQuestions( true )}>Screening Questions</span>
                        </div>}
                        
                    </div>
                    {
                        isDisabled ?
                            ""
                            :
                            <Fragment>
                                <button
                                    onClick={handleUpdateJobPosition}
                                    className="float-right btn btn-primary btn-sm"
                                >
                                    <FontAwesomeIcon icon={faSave} className="mr-2" />
                                    Save
                                </button>

                                <button
                                    onClick={()=> {
                                        setJobData( backUpJob );
                                    }}
                                    className="float-right btn text-danger mr-2"
                                >
                                    Cancel
                                </button>
                            </Fragment>

                    }
                </div>
            </div>

            {marginShow ?
                <SAlert
                    show={marginShow}
                    confirmText="Yes"
                    typeButton="warning"
                    msg={"Do you want to use a lower margin in this Job?"}
                    hideAlert={setMarginShow( false )}
                    onConfirm={onConfirmLowerMargin}
                    opc={0}
                />:""}

            <ModalSelectPartnerClient
                selectedData={selectedData}
                hideModal={hideModal}
                modalVisible={modalVisible}
                modalType={modalType}
            />
            <ModalScreeningQuestions
                show={showScreeningQuestions}
                hideModal={() => setShowScreeningQuestions( false )}
                questions={job.questions} />
        </Fragment>
    );
}

export default JobPositionForm;
