import React from "react";


export default function WelcomeScreen() {
    const welcomeImageUrl = '/images/newCoverImage.png';
    return (
        <div className="container h-100 text-center">
            <div className="row">
                <div className="col-md-12">
                    {/*<div className="mt-5 pt-2">*/}
                    {/*	<h2>Welcome to <b>eJRekruit</b></h2>*/}
                    {/*	<h2>Find Your Dream Job</h2>*/}
                    {/*</div>*/}

                    <div>
                        <img src={welcomeImageUrl} alt="welcome_image" className="welcome_image" />

                        {/* <video onClick={null} width="100%" autoPlay loop className="align-self-center" alt="logo">
                            <source src={welcomeImageUrl} type="video/mp4"/>
                        </video> */}
                    </div>
                </div>
            </div>
        </div>
    );
}