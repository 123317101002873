export const GET_CTC_STATE      = 'GET_CTC_STATE';
export const UPDATE_CTC_STATE   = 'UPDATE_CTC_STATE';
export const UPDATE_TALENT_CTC_STATE   = 'UPDATE_TALENT_CTC_STATE';
export const UPDATE_TALENT_CTC_STATE_COMPLETE   = 'UPDATE_TALENT_CTC_STATE_COMPLETE';

export const get_ctc_data = ( data ) => {
    return {
        type: GET_CTC_STATE,
        payload: data,
    };
};

export const update_talent_ctc_data = ( data ) => {
    return {
        type: UPDATE_TALENT_CTC_STATE,
        payload: data,
    };
};

export const update_talent_ctc_complete_data = ( data ) => {
    return {
        type: UPDATE_TALENT_CTC_STATE_COMPLETE,
        payload: data,
    };
};

export const update_ctc_data = ( data ) => {
    return {
        type: UPDATE_CTC_STATE,
        payload: data,
    };
};
