import React, { Fragment, useState, useEffect } from "react";
import {  Modal,  Button } from "react-bootstrap";
import util from "../../../utils/miniUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSave,
    faPlusCircle,
    faEnvelope, faPhone, faArrowLeft,  faExclamationTriangle,faSearch
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { setCandidates } from "../../../../redux/actions/JobCandidatesAction";
import { TextField, MuiThemeProvider, Tooltip } from "@material-ui/core";
import MaterialTable from "material-table";
import Paper from "@material-ui/core/Paper";
import PhoneInput from "react-phone-input-2";
import { NavLink } from "react-router-dom";
import AdvancedFilter from "./AdvancedFilter";
import countryList from "../../../utils/location/country.json";
import CountryAutoFill from "../../../talents/components/listComponents/CountryAutoFill";

const tableRef = React.createRef();

function ModalAddCandidate( props ) {
    const [show, setShow] = useState( props.modalVisible );
    const [candidate, setCandidate] = useState( { name:"", email:"", phone_mobile:"", skills: [],country: "", } );
    const [action, setAction] = useState( true );
    const [loading, setLoading] = useState( false );
    const [positionId] = useState( props.position_id );
    // const [countries] = useState( countryList );
    const [skillsList, setSkillsList] = useState( [] );
    const [skillName, setSkillName] = useState( '' );
    const [selectedSkills, setSelectedSkills] = useState( [] );


    const [state, setState] = useState( {
        showFilter: false,
        skill_name: "",
        job_title: "",
        country: "",
        state: "",
        city: "",
        name: ""
    } );
    const handleClose = () => {
        setAction( true );
        setShow( false );
        props.hideModal();
    };
  
    function handleChanges( e ) {
        const { name, value } = e.target;
        setCandidate( { ...candidate, [name]: value } );
    }
    const searchData = ( d ) => {
        setState( Object.assign( {}, d, { showFilter: true } ) );
        tableRef.current && tableRef.current.onQueryChange();
    };
    const urlHandler = ( query )  => {
        let orderby = ( query.orderBy ) ? query.orderBy.field:"name";
        let direction = ( query.orderDirection ==="" ) ? "asc": query.orderDirection;
        let search = ( query.search === "" ) ? "": state.showFilter ? "": query.search;
        const { skill_name, country, name,  resume, city, job_title } = state;
        let q = `name=${name}&skillsSet=${skill_name}&country=${country}&state=${state.state}&resume=${resume}&job_title=${job_title}&city=${city}`;
        return `${util.url}/candidates/${query.page}/${query.pageSize}?orderby=${orderby}&direction=${direction}&search=${search}&${q}`;
    };
  
    useEffect( () => {
        setShow( props.modalVisible );
        return () => {};
    }, [props.modalVisible] );
  
    function cleanFields() {
        props.hideModal();
        let t = {
            name:"",
            phone_mobile:"",
            email:"",
            skills: [],
            country: ""
        };
        setCandidate( Object.assign( {}, t ) );
    }
  
    async function saveData() {
        try {
            // This method saves the new talents and add them to the position
            setLoading( true );
            if ( candidate.name === "" ) throw new Error( "Please Enter name" );
            if ( !util.validateEmail( candidate.email ) ) throw new Error( "Invalid email" );
            if ( candidate.country === "" ) throw new Error( "Please Select a country" );
            if ( candidate.phone_mobile === "" ) throw new Error( "Please Enter a phone number " );
            if ( candidate.phone_mobile.length !== 10 ) throw new Error( "Invalid Phone Number " );
            if ( candidate.skills.length === 0 ) throw new Error( "Add at least 1 skill" );
            // if ( candidate.skills[0].skill === "" ) throw new Error( "Ingress Skill title" );
            // if ( candidate.skills[0].yearsOfExperience === "" ) throw new Error( "Ingress Skill years of experience" );
      
            for ( let x = 0; x < candidate.skills.length; x++ ){
                if (  candidate.skills[x].yearsOfExperience === "0" ){
                    throw new Error( "Please enter year of experience for the skill you selected" );
                }
            }
      
            const request = await util.axios.post( `${util.url}/requirements/create_add_candidate/${positionId}`, candidate );
            const { error, msg, candidates } = request.data;
            setLoading( false );
            if ( error ) return util.toast().error( msg );
            // Clean vars
            cleanFields();
            // Send talents to redux
            props.setCandidates( candidates );
            props.refreshTalentTable();
            util.toast().success( msg );
        }catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }
    }
  
    function handleAction( act ) {
        if ( act === "add" ) {
            setAction( false );
        } else {
            setAction( true );
        }
    }
  
    async function selectedData( data ) {
    // Select a candidate from the list and added to the position
        try {
            setLoading( true );
            const request = await util.axios.post( `requirements/add_candidate_new_method/${positionId}`, data );
            const { error, candidates, msg } = request.data;
            setLoading( false );
            if ( error ) return util.toast().error( msg );
            util.toast().success( "Talent added to the Job Position" );
            // Send talents to redux
            props.refreshTalentTable();
            props.setCandidates( candidates );
        }catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }
    }
  
    function validateSkills( data ) {
        return data["skillsSet"] === "";
    }
  
  
    function onSkillChange  ( e, index ) {
        const { name, value } = e.target;
        let t = candidate;
        t.skills[index][name] = value;
        setCandidate( Object.assign( {}, t ) );
    }
  
    function addSkill ( skill ) {
        let t = candidate;
        const skillIndex = t.skills.findIndex( ( item ) => item.id === skill.id );

        if ( skillIndex === -1 ) {
            // Skill does not exist, add it to the list
            t.skills.push( { ...skill }  );
        } else {
            // Skill exists, remove it from the list
            t.skills.splice( skillIndex, 1 );
        }
        // t.skills.push( { yearsOfExperience:"", skill:"" } );
        setCandidate( Object.assign( {}, t ) );
    }
  
    function removeSkill  ( index ) {
        let t = candidate;
        t.skills.splice( index, 1 );
        setCandidate( Object.assign( {}, t ) );
    }
   
    const getListofSkills = async ( search ) => {
        try {
            search.replace( /\//g, "" );
            const encodedSearch = encodeURIComponent( search );
            const response = await util.axios.get( `catalog/catalog_skills_search/${encodedSearch}` );
            const { error, msg, data } = response.data;
            if ( error ) return util.handleError( msg );
            setSkillsList( data );
        } catch ( e ) {
            util.handleError( e );
        }
    };
    const handleChange = ( e ) => {
        setSkillName( e.target.value );
    };
   
    const handleAddSkill = ( skill ) => {
        addSkill( skill );
        setSkillName( '' );
    };
    
    const handleRemoveSkill = ( index ) => {
        const updatedSkills = [...selectedSkills];
        updatedSkills.splice( index, 1 );
        setSelectedSkills( updatedSkills );
    };
    
    const handleReset = () => {
       
        setSkillName( '' );
        setSkillsList( [] );
        
    };
    
    useEffect( () =>{
        if( skillName ) {
            getListofSkills( skillName );
        }
    },[skillName] );
    

    return (
        <Fragment>
            <Modal size={action ? "xl" : "md"} show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Talent</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/*Depending on the boolean action, must display the list of talent or the form to create a new candidate*/}
                    {action ?
                        <Fragment>
                            <div className="w-100">
                                {
                                    state.showFilter ?
                                        <AdvancedFilter
                                            filterData={state}
                                            searchData={searchData}
                                            closeAdvanceSearch={()=> {
                                                const reset = { showFilter: false, job_title: "", state: "", skill_name: "", country: "", city: "", name: "" };
                                                setState( Object.assign( {}, reset ) );
                                            }
                                            }
                                        />
                                        :
                                        ""
                                }
                                <MuiThemeProvider theme={util.defaultTableTheme}>
                                    <MaterialTable
                                        title="Available candidates"
                                        tableRef={tableRef}
                                        columns={[
                                            {
                                                title: 'Name', field: 'name', searchable: true,
                                                editable:"onAdd",
                                                render: rowData => {
                                                    return <NavLink to={`/candidates/${rowData.id}?jp=true&job_id=${positionId}`}>{rowData.name}
                                                    </NavLink>;
                                                }
                                            },
                                            {
                                                title: 'Skills', field: 'skillsSet', searchable: true,
                                                width:"10%",
                                                render: rowData => {
                                                    return util.suspenseDots( rowData["skillsSet"] );
                                                },
                                            },
                                            {
                                                title: 'Last Job', field: 'wh_position', searchable: true,
                                                width:"10%",
                                                render: rowData => {
                                                    return util.suspenseDots( util.extractOnePositionTitle( rowData["wh_position"] ) );
                                                },
                                            },
                                            {
                                                title: 'Location', field: 'location',
                                                render: rowData => {
                                                    return util.location( rowData.country,rowData.state,rowData.city );
                                                }
                                            },
                                            {
                                                title: 'Work Authorization', field: 'working_status',
                                                render: rowData => {
                                                    if( rowData.working_status == null ){
                                                        return <p>Currently Not Working</p>;
                                                    }else{
                                                        return rowData.working_status;
                                                    }
                                                }
                                            },
                                            {
                                                title: 'Email', field: 'email',
                                                render: rowData => {
                                                    return <Tooltip title="Send it an email">
                                                        <a href={`mailto:${rowData.email}`}>
                                                            <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                                                        </a>
                                                    </Tooltip>;
                                                }
                                            },
                                            {
                                                title: 'Phone', field: 'phone_mobile',
                                                render: rowData => {
                                                    return <Tooltip title="Call him"><a href={`phone:${rowData.phone_mobile}`}>
                                                        <FontAwesomeIcon icon={faPhone} className="mr-2" />
                                                    </a></Tooltip>;
                                                }
                                            },
                                            {
                                                title: 'Available',
                                                editable:"onUpdate",
                                                render: rowData => {
                                                    if( validateSkills( rowData ) === false )
                                                    {
                                                        return <div onClick={()=> selectedData( rowData )} title="Select Talent" className="btn btn-outline-primary btn-sm">
                                                            <div className="text-center">Select</div>
                                                        </div>;
                                                    }
                                                    if( validateSkills( rowData ) === true )
                                                    {
                                                        return <Tooltip title="To use this talent, please add at least one skill">
                                                            <div className="icon-link"><div className="text-center"><FontAwesomeIcon  icon={faExclamationTriangle} /></div></div>
                                                        </Tooltip>;
                                                    }
                                                }
                                            }
                          
                                        ]}
                                        components={{
                                            Container: props => <Paper {...props} elevation={0}/>
                                        }}
                                        data={query =>
                                            util.axios.get( urlHandler( query ) )
                                                .then( response => {
                                                    const { candidates, total, page, error, msg } = response.data;
                                                    if ( error ) throw msg;
                                                    return ( {
                                                        data: candidates,
                                                        page: page,
                                                        totalCount: total
                                                    } );
                                                } )
                                                .catch( error=>{
                                                    util.handleError( error );
                                                } )
                                        }
                                        options={{
                                            debounceInterval:600,
                                            pageSize: 5,
                                            sorting: true,
                                            padding:"dense",
                                            search:!state.showFilter ,
                                            showTitle:false,
                                        }}
                                        localization={{
                                            toolbar:{
                                                searchPlaceholder:"Search for talent..."
                                            }
                                        }}
                                        actions={[
                                            {
                                                icon: () => <div className="btn btn-primary btn-sm text-white"><FontAwesomeIcon id="addCandidateIcon" icon={faPlusCircle} /> Add Talent</div>,
                                                tooltip: 'Add Candidate',
                                                isFreeAction: true,
                                                onClick: () => handleAction( "add" )
                                            },
                                            {
                                                icon: () =>
                                                    <div className="btn btn-info btn-sm">
                                                        <FontAwesomeIcon icon={faSearch} className="mr-2" />
                                    Advanced Search
                                                    </div>
                                                ,
                                                position:"toolbar",
                                                isFreeAction: true,
                                                onClick: () => setState( { ...state, showFilter: true } )
                                            }
                                        ]}
                                    />
                                </MuiThemeProvider>
                            </div>
                        </Fragment>:
                        <Fragment>
                            <div className="w-100">
                                <div>
                                    <button  className="btn btn-sm btn-primary" onClick={()=>handleAction( "list" )}><FontAwesomeIcon icon={faArrowLeft} /> Back to list</button>
                                </div>
                                <div className="row d-flex mt-2">
                                    <div className="col-12">
                                        {/* <util.RowComponent
                                                    c={12}
                                                    t={"Name"}
                                                    n={"name"}
                                                    m={handleChanges}
                                                    v={candidate.name}
                                                /> */}
                                        <TextField 
                                            label={"Name"}
                                            size='small'
                                            fullWidth
                                            variant='outlined'
                                            type={"text"}
                                            value={candidate.name}
                                            name={"name"}
                                            onChange={handleChanges}/>
                                    </div>
                                    {/* <tr>
                                            <td colSpan={3}>
                                                <Tooltip title={candidate.country ? candidate.country :"No country selected"}>
                                                    <span>
                                                        <TextField
                                                            select
                                                            size="small"
                                                            name="country"
                                                            className="w-100"
                                                            label="Country"
                                                            value={candidate.country ? candidate.country : ""}
                                                            onChange={handleChanges}
                                                            InputLabelProps={{ shrink: true }}
                                                            SelectProps={{
                                                                native: true
                                                            }}
                                                        >
                                                            <optgroup label="Most Active">
                                                                <option value="USA">USA</option>
                                                                <option value="Canada">Canada</option>
                                                                <option value="India">India</option>
                                                                <option value="Australia">Australia</option>
                                                                <option value="Mexico">Mexico</option>
                                                                <option value="Japan">Japan</option>
                                                                <option value="Germany">Germany</option>
                                                                <option value="Puerto Rico">Puerto Rico</option>
                                                            </optgroup>
                                                            <optgroup label="All Countries">
                                                                <option value=""/>
                                                                {countries?.map( ( option, i ) => (
                                                                    <option key={i} value={option.name}>
                                                                        {option.name}
                                                                    </option>
                                                                ) )}
                                                            </optgroup>
                                                        </TextField>
                                                    </span>
                                                </Tooltip>
                                            </td>
                                        </tr> */}
                                    <div className="col-12 mt-3">
                                        {/* <label className="labelFormControlMini">Phone</label> */}
                                        {/* <PhoneInput
                                                    placeholder={"Phone"}
                                                    country={'us'}
                                                    id="phoneInput"
                                                    name={"phone_mobile"}
                                                    value={candidate.phone_mobile}
                                                    onChange={phone => {
                                                        let e={ target:{ name:"phone_mobile", value: phone } };
                                                        handleChanges( e );
                                                    }}
                                                /> */}
                                        {/* <PhoneInput
                                                    country={"eg"}
                                                    enableSearch={true}
                                                    name={"phone_mobile"}
                                                    value={candidate.phone_mobile}
                                                    onChange={phone => {
                                                        let e={ target:{ name:"phone_mobile", value: phone } };
                                                        handleChanges( e );
                                                    }}
                                                /> */}
                                        <CountryAutoFill newState={candidate} onChangeHandler={handleChanges}/>
                                    </div>
                                    <div className="col-12 mt-1">
                                        {/* <util.RowComponent
                                                    c={12}
                                                    t={"Email"}
                                                    n={"email"}
                                                    m={handleChanges}
                                                    v={candidate.email}
                                                /> */}
                                        <TextField 
                                            label={"Email"}
                                            size='small'
                                            fullWidth
                                            variant='outlined'
                                            type={"Email"}
                                            value={candidate.email}
                                            name={"email"}
                                            onChange={handleChanges}/>
                                    </div>
                                    <util.skillTalentForm  removeSkill={removeSkill} onSkillChange={onSkillChange}
                                        handleAddSkill={handleAddSkill} handleChange={handleChange} handleRemoveSkill={handleRemoveSkill} handleReset={handleReset}
                                        selectedSkills={candidate.skills} skillName={skillName} skillsList={skillsList} />
                                    {/* <tr>
                                            <td colSpan={3}>
                                                <div className="text-center">
                                                    {candidate.skills.length > 3 ?
                                                        ""
                                                        :
                                                        <button onClick={()=> addSkill()} className="btn btn-sm btn-outline-primary">
                                                            <FontAwesomeIcon icon={faPlus} className="mr-2"/>
                                  Add Skill
                                                        </button>
                                                    }
                                                </div>
                                            </td>
                                        </tr> */}
                                </div>
                            </div>
                            <Modal.Footer>
                                <Button variant="default" onClick={cleanFields}>Discard</Button>
                                <button className="btn btn-sm btn-primary" onClick={()=> saveData()} >
                                    <FontAwesomeIcon icon={faSave} className="mr-2"/>
                      Save
                                </button>
                            </Modal.Footer>
                        </Fragment>}
          
                </Modal.Body>
            </Modal>
            {util.LOADING_SCREEN( loading )}
        </Fragment>
    );
}

function mapDispatchToProps( dispatch ) {
    return{
        setCandidates:( data ) => dispatch( setCandidates( data ) ),
    };
}

export default connect( null, mapDispatchToProps )( ModalAddCandidate );
