import React, { useState, useEffect } from 'react';
import { Modal } from "react-bootstrap";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import util from '../../utils/miniUtils';

const FieldForm = ( { show=false, handleClose, isNew=true, category, createField, updateField, fieldData } ) => {
    const [availableField, setAvailableField] = useState( { emptyFields: 1 } );
    const [state, setState] = useState( {
        wl_category_id: "",
        type: "",
        label: "",
    } );

    const [updateState, setUpdateState] = useState( { label: "" } );

    const handleChange = ( e ) => {
        const { name, value } = e.target;
        if( isNew ) {
            setState( ( prev ) => {
                return {
                    ...prev,
                    wl_category_id: category,
                    [name]: value
                };
            } );
        } else {
            if( name === "label" ) {
                setUpdateState( {
                    fieldId: fieldData.field_id,
                    categoryId: fieldData.wl_category_id,
                    label: value
                } );
            }
        }
    };

    const getAvailableFields = async ( category ) => {
        try {
            const request = await util.axios.get( `wl_fieldCategory/availablefields/${category}` );
            const { msg, error, data } = request.data;
            if ( error ) throw msg;
            setAvailableField( data );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const handleSave = () => {
        if( isNew ) {
            createField( state );
        } else {
            updateField( updateState );
        }
        handleClose();
        setState( {
            wl_category_id: "",
            type: "",
            label: "",
        } );

        setUpdateState( { label: "" } );
    };

    useEffect( () => {
        setUpdateState( {
            fieldId: fieldData.field_id,
            categoryId: fieldData.wl_category_id,
            label: fieldData.label
        } );
    },[fieldData] );

    useEffect( () => {
        if( show ) {
            getAvailableFields( category );

            return setAvailableField( { emptyFields: 1 } );
        }
    },[show, category] );

    return  <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <div className="d-flex justify-content-center">
                <b>{isNew ? "New Field" : "Edit field"}</b>
            </div>
        </Modal.Header>
        <Modal.Body>
            {isNew ? availableField.emptyFields > 0 ? <div>
                <label htmlFor="fieldType">Field Type</label>
                <select className='form-control' id="fieldType" name="type" value={state.type} onChange={handleChange}>
                    <option value=""></option>
                    {availableField.num > 0 && <option value="num" >Number</option>}
                    {availableField.text > 0 && <option value="text" >Text</option>}
                    {availableField.dateTime > 0 && <option value="dateTime" >Date</option>}
                </select>
                
                <label htmlFor="fieldLabel">Label</label>
                <input 
                    className="form-control"
                    id="fieldLabel" 
                    name="label" 
                    value={isNew ? state.label : updateState.label} 
                    onChange={handleChange} />
            </div> 
                : 
                <div>All the fields are assigned. Please remove existing fields to create new ones.</div>
                : <>
                    <label htmlFor="fieldLabel">Label</label>
                    <input 
                        className="form-control"
                        id="fieldLabel" 
                        name="label" 
                        value={isNew ? state.label : updateState.label} 
                        onChange={handleChange} />
                    
                    <label htmlFor="fieldType">Type</label>
                    <input className="form-control" disabled value={fieldData.field_type} />
                </>}
        </Modal.Body>
        <Modal.Footer>
            <span className="btn btn-outline-danger" onClick={handleClose}>
                <FontAwesomeIcon
                    icon={faTimes}
                />
            </span>
            <span className="btn btn-outline-primary" onClick={handleSave}>
                <FontAwesomeIcon
                    icon={faCheck}
                />
            </span>
        </Modal.Footer>
    </Modal>;
};

export default FieldForm;