import util from "./miniUtils";

const settingRequest = {};

settingRequest.getSettings = async () =>{
    try {
        const request = await util.axios.get( `${util.url}/app_resources/settings/` );
        const { error, msg, settings } = request.data;
        if ( error ) throw msg;
        return settings;
    }catch ( e ) {
        util.handleError( e );
    }
};


export default settingRequest;
