import React, { Fragment, useState } from "react";
import util from "../../../../utils/miniUtils";
import Modal from "react-bootstrap/Modal";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import RequestDocuments from "./RequestDocuments";
import ReportDocumentStatus from "./ReportDocumentStatus";

function ListOnboardedTalents( { data, show, hideModal } ) {
	
    const [state, setState] = useState( {
        selectedTalents:[],
        selectedDocuments:[],
        step: 1,
        loading: false,
        document:{},
        documents:[]
    } );
	
    async function sendEmail() {
        try {
            setState( { ...state, loading: true } );
            const info = {
                selectedTalents : state.selectedTalents,
                selectedDocuments : state.selectedDocuments,
            };
            const request = await util.axios.post( `${util.url}/job_process/documents/send_email_massive`, { info } );
            const { error, msg } = request.data;
            if ( error ) return msg;
            util.toast().success( msg );
            setState( { ...state, loading: false } );
            // hideModal()
        } catch ( error ) {
            setState( { ...state, loading: false } );
            util.handleError( error );
        }
    }
	
    return(
        <Fragment>
            <Modal
                show={show}
                onHide={() => hideModal()}
                backdrop="static"
                size="lg"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Onboarded Talents</Modal.Title>
                </Modal.Header>
                <Modal.Body>
					
                    <Tabs defaultActiveKey="document_requesting" unmountOnExit={true} mountOnEnter={true}>
                        <Tab eventKey="document_requesting" title="Talents & Documents">
                            <RequestDocuments data={data} setState={setState} state={state} sendEmail={sendEmail} />
                        </Tab>
                        <Tab eventKey="report" title="Report">
                            <ReportDocumentStatus  job_id={data.id} />
                        </Tab>
                    </Tabs>
                </Modal.Body>
            </Modal>
			
            {util.LOADING_SCREEN( state.loading )}
        </Fragment>
    );
}

export default ListOnboardedTalents;