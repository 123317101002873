import React, { useState } from "react";
import '../../assets/css/style.css';
import '../../assets/css/responsive.css';
import '../../assets/css/tableCustom.css';
import axios from "axios";
import util from "../utils/miniUtils";
import { Input } from "@material-ui/core";
import CryptoJS from 'crypto-js';

const BASE_URL = process.env.REACT_APP_BASE_URL;

function PasswordReset ( props ){

    const [token] = useState( props.token );
    const [password, setPassword] = useState( "" );
    const [passwordConfirm, setPasswordConfirm] = useState( "" );
    const [backDropOpen, setBackDropOpen] = useState( false );

    async function changePasswordRequest () {
        try {
            if ( password === "" ) throw new Error( "Please enter your password" );
            if ( passwordConfirm === "" ) throw new Error( "Please confirm your password" );
            // if ( passwordConfirm !== password ) throw new Error( "Your password are not equal" );
            setBackDropOpen( true );
            let data = { password, passwordConfirm, token };
            // data.password = new Buffer( password ).toString( 'base64' );
            // data.passwordConfirm =new Buffer( passwordConfirm ).toString( 'base64' );
            data.password = CryptoJS.AES.encrypt( password, process.env.REACT_APP_PASS_KEY ).toString();
            data.passwordConfirm = CryptoJS.AES.encrypt( passwordConfirm, process.env.REACT_APP_PASS_KEY ).toString();
            setPassword( "" );
            setPasswordConfirm( "" );
            const request = await axios.put( `${BASE_URL}/users/reset_password_confirmation`, data );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            setBackDropOpen( false );
            util.toast().success( msg );
            setTimeout( ()=>{
                window.history.replaceState( "", "", "/" );
                window.location.reload();
            }, 1000 );
        } catch ( e ) {
            setBackDropOpen( false );
            util.handleError( e );
        }
    }

    function onChangeHandler ( e ) {
        let { name, value } = e.target;
        if ( name === "password" )  setPassword( value );
        if ( name === "passwordConfirmation" )  setPasswordConfirm( value );
    }

    function captureEnterKey ( e ) {
        if ( e.key === "Enter" ) {
            changePasswordRequest().then( r => null );
        }
    }


    return (
        <div className="container">
            <div className="row">
                <div className="col-md-4 mx-auto">
                    <div className="card mt-4 text-center">
                        <img src="/images/demo_logo.png" className="mx-auto d-block m-4 logo" alt="eJRekruit" />
                        {/* <h3>eJRekruit</h3> */}

                        <p className="text-black-50">Enter your new password</p>
                        <div className="card-body">
                            <div className="form-group">
                                <Input
                                    onKeyDown={captureEnterKey}
                                    type="password"
                                    value={password}
                                    onChange={onChangeHandler}
                                    name="password"
                                    fullWidth
                                    placeholder="Password"
                                />
                            </div>
                            <div className="form-group">
                                <Input
                                    fullWidth
                                    onKeyDown={captureEnterKey}
                                    type="password"
                                    value={passwordConfirm}
                                    onChange={onChangeHandler}
                                    name="passwordConfirmation"
                                    placeholder="Password Confirmation"
                                />
                            </div>
                            <div className="form-group">
                                <button
                                    id="button-login"
                                    data-testid="button-login"
                                    className="btn btn-primary btn-block"
                                    onClick={changePasswordRequest}
                                >
                      Change password
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {util.LOADING_SCREEN( backDropOpen )}
        </div>
    );

}

export default PasswordReset;
