import { useState } from "react";
import util from "../../utils/miniUtils";
import {
    RECRUITER_ROLE,
    TALENT_ROLE,
    CLIENT_ROLE,
    PARTNER_ROLE,
    RECRUITER_MANAGER,
    EMPLOYEE_ROLE,
    EMPLOYEE_MANAGER
} from "../../../constants";

function useUsers( tableRef ) {    
    // Initialising the state
    const [isVisible, setIsVisible] = useState( false );
    const [loading, setLoading] = useState( false );
    const initialUser = { name:"", company:"", email:"", phone1:"", phone2:"", id:0, candidate_id:0, country: "", candidate_email: "" };
    const [user, setUser] = useState( initialUser );
    const [data, setData] = useState( "" );
    const [show, setShow] = useState( { password: false, deactivateUser:false } );
    const [state, setState] = useState( {
        showFilter: false,
        role: "",
        country: "",
        search: ""
    } );

    // Method for facilitating search in the users table
    const searchData = ( d ) => {
        setState( { ...d, showFilter: true } );
        tableRef.current && tableRef.current.onQueryChange();
    };
    // Method for handling the Advanced search
    const handleAdvancedSearchClose = () => {
        const reset = { showFilter: false, country: "", role: "", search: "" };
        setState( { ...reset } );
        tableRef.current && tableRef.current.onQueryChange();
    };
    // Method for handling the operations carried using the url
    const urlHandler = ( query,  opc = false )  => {
        let orderby = ( query.orderBy ) ? query.orderBy.field:"name";
        let direction = ( query.orderDirection ==="" ) ? "asc": query.orderDirection;
        let search = ( state.search === "" ) ? query.search : state.search;
        let options = ( opc ) ? `&opt=${opc}`: "";
        const { country,role } = state;
        let roleselected = 0;
        switch( role ){
            case "Recruiter":  			roleselected = RECRUITER_ROLE;			break;
            case "Talent":  			roleselected = TALENT_ROLE;				break;
            case "Client":  			roleselected = CLIENT_ROLE;				break;
            case "Partner":  			roleselected = PARTNER_ROLE;			break;
            case "Recruiter Manager":  	roleselected = RECRUITER_MANAGER;		break;
            case "Employee":  			roleselected = EMPLOYEE_ROLE;			break;
            case "Employee Manager":  	roleselected = EMPLOYEE_MANAGER;		break;
            default:  					roleselected = "All";
        }
        return `${util.url}/users/list?page=${query.page}&limit=${query.pageSize}&orderby=${orderby}&direction=${direction}&search=${search}&country=${country}&role=${roleselected}${options}`;
    };

    function modalVisible( opc, data ) {
        setIsVisible( opc );
        let u = data;

        u.name 		= ( data.name 		|| "" );
        u.role 		= ( data.role 		|| 1 );
        u.company 	= ( data.company 	|| "" );
        u.country 	= ( data.country 	|| "" );
        u.email 	= ( data.email 		|| "" );
        u.candidate_email 	= ( data.candidate_email 		|| "" );
        u.phone1 	= ( data.phone1 	|| "" );
        u.phone2 	= ( data.phone2 	|| "" );
        setUser( data );
    }

    function hideModal() {
        setIsVisible( false );
    }

    function createNewUser() {
        modalVisible( true,initialUser );
    }
    
    function hideAlert() {
        setShow( { password: false, deactivateUser:false } );
    }
    // Method to send request for changing the password
    async function onConfirmChangePassword() {
        try {
            hideAlert();
            setLoading( true );
            const request = await util.axios.get( `${util.url}/users/reset_password/${data.id}` );
            const { error, msg } = request.data;
            if ( error ) throw  msg;
            util.toast().success( msg );
        }catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    }
    // Method to request for deleting the user from the backend
    async function onConfirmDeleteUser() {
        try {
            hideAlert();
            setLoading( true );
            let ids = [];
            if ( data.length === 1 ){
                ids = [data[0].id];
            }else{
                data.map( ( r => ids.push( r.id ) ) );
            }
            const request = await util.axios.post( `${util.url}/users/`, { ids: ids } );
            const { error, msg } = request.data;
            if ( error ) throw  msg;
            util.toast().success( msg );
            tableRef.current && tableRef.current.onQueryChange();
        }catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    }

    return {
        isVisible, setIsVisible,
        loading, setLoading,
        user, setUser,
        data, setData,
        show, setShow,
        state, setState,
        searchData,
        handleAdvancedSearchClose,
        urlHandler,
        modalVisible,
        hideModal,
        createNewUser,
        hideAlert,
        onConfirmChangePassword,
        onConfirmDeleteUser
    };
}

export default useUsers;
