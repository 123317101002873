import React from 'react';
import "./../shared/style.css";
import util from '../../utils/miniUtils';
import CategoryStatus from './components/CategoryStatus';


const groupSettings = {
    createPath: `${util.url}/wl_categories/new_category`,
    getListPath: `${util.url}/wl_categories/get_all_categories`,
    getDetailPath: `${util.url}/wl_categories/detail`,
    deletePath: `${util.url}/wl_categories/delete`,
    updatePath: `${util.url}/wl_categories/update_category`,
    getEmployeeList: `${util.url}/employee/get_employees_list`,
    getGroupListPath: `${util.url}/groups/simple-list`,
    getAssignedStatusPath: `${util.url}/wl_categories/get_assigned_status`,
    deleteTeamMemberPath: `${util.url}/wl_categories/delete_member`,
    getAssignedTeamMemberPath: `${util.url}/wl_categories/get_assigned_members`,
    title: "Departments",
    subTitle: "Department Members"
};

// // Get Assigned Status to Category
// router.get( "/wl_categories/get_assigned_status/:category_id" ,isAuthenticated, async ( req, res ) => {
// 	await new wlCategory( req, res ).getAssignedStatusToCategory( req.params.category_id );
// } );

// // Delete Member from a  Category
// router.post( "/wl_categories/delete_member/:relation_id" , async ( req, res ) => {
// 	await new wlCategory( req, res ).deleteMember( req.params.relation_id );
// } );

// // Get Categories from  a particular Category
// router.get( "/wl_categories/get_categories/:category_id/" , async ( req, res ) => {
// 	await new wlCategory( req, res ).getCategoriesFromGroup( req.params.category_id );
// } );



export default function WorkloadCatalog() {
    return (
        <div className="container-fluid">
            <div className="row">
                <CategoryStatus settings={groupSettings} />
            </div>
        </div>
    );
}
