import { Chip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Collapse } from 'react-bootstrap';
import up from "../../../shared/checkboxSelector/images/up.png";
import down from "../../../shared/checkboxSelector/images/down.png";

export default function JobTitleSelector  ( { getJobList, jobsList, updateFilter, filteredJobs, setJobsList } ) {
    const [jobName, setjobName] = useState( '' );
    const [openReset,setOpenReset]=useState( false );

    const handleChange = ( e ) => {
        setjobName( e.target.value );
    };

    const handleClick = ( val ) => {
        if( !filteredJobs.includes( val ) ) {
            updateFilter( prev => ( { ...prev, job_title:[...prev.job_title, val], ready: true } ) );
        }
    };

    const handleOpenJobs = () =>{
        setOpenReset( !openReset );

    };
    const deleteJobsList = ( val ) => {
        const temp = filteredJobs.filter( item => item.id != val );
        updateFilter( prev => ( { ...prev, job_title: [...temp] } ) );
    };

    const handleReset = () => {
        const temp = [];
        updateFilter( prev => ( { ...prev, job_title: [...temp] } ) );
        setjobName( '' );
        setJobsList( [] );
        
    };

    useEffect( () =>{
        if( jobName ) {
            getJobList( jobName );
        }
    },[jobName] );

    return(
        <div className="border-bottom pb-1">
            <div className="d-flex justify-content-between w-100">
                <label><strong>Previous Job</strong></label>
                <span className="isCursor" onClick={handleOpenJobs}>{openReset ?
                    <img src={up} alt="" style={{ width: "10px" ,float:'right',marginTop:'2px' }} /> :
                    <img src={down} alt="" style={{ width: "10px", height: "8px" ,float:'right',marginTop:'2px' }} />}</span>
            </div>
            <div>{filteredJobs.length ? filteredJobs.map( el => <Chip size="small" label={el.name} key={el.id} className="mr-1 mb-1" onDelete={() => deleteJobsList( el.id )} /> ) : ''}</div>
            <Collapse in={openReset} collapsedHeight={0}>
                <div>
                    <span className="bt_reset text-secondary isCursor" onClick={handleReset}>Reset</span>
                    <input className="form-control" type="text" value={jobName} onChange={handleChange}/></div></Collapse>
            <ul className="skillsSelectorList">{jobsList.length ? jobsList.map( el => <li className="skillsSelectorList-item" key={el.id} onClick={() => handleClick( el )}>{el.name}</li> ) : ''}</ul>
        </div>
    );
}