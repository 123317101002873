import React, { Fragment, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import util from "../../../utils/miniUtils";
import { Button } from "react-bootstrap";

import { connect } from "react-redux";
import { setCandidates, setCandidateStatus } from "../../../../redux/actions/JobCandidatesAction";
import { CircularProgress } from "@material-ui/core";
import EmailModal from "../../../shared/EmailModal";


const Step1 = ( props ) => {
    const [isLoading, setIsLoading] = useState( false );
    const [data, setData] = useState( props.data );
    const [candidate] = useState( props.candidate );
    const [emailModal, setEmailModal] = useState( false );

    async function sendFirstContact( emailTemplate ) {
        try {
            setIsLoading( true );
            const d = {
                emailTemplate,
                contacted: true,
                date: new Date().toJSON().slice( 0, 19 ).replace( "T", " " ),
            };

            const request = await util.axios.post(
                `${util.url}/job_position/make_first_contact/${candidate.position_id}/${candidate.candidate_id}`,d );

            const { error, msg, date } = request.data;
            if ( error ) return util.toast().error( msg );
            // No errors
            // Update status of candidate in redux
            props.setCandidateStatus( { id:candidate.id, status: 2, talent_desc_updated: "Mail sent" } );
      
            setIsLoading( false );

            setData( { contacted: true, date } );
            util.toast().success( msg );
        }catch ( error ) {
            setIsLoading( false );
            util.toast().error( error );
        }
    }

    function SendFirstContact() {
        return (
            <Fragment>
                <div className="center">
                    <Button onClick={() => sendFirstContact()}>
                        <FontAwesomeIcon icon={faEnvelope} className="mr-1" />
              Send email
                    </Button>
                </div>
            </Fragment>
        );
    }

    function CandidateContacted() {
        return (
            <Fragment>
                <p>A email was sent to the candidate on {util.dateLong( data.date )}.</p>
            </Fragment>
        );
    }

    if ( isLoading ){
        return <div className="center"><CircularProgress /></div>;
    }
    return (
        <Fragment>
            <div className="row">
                <div className="col-auto text-center flex-column d-none d-sm-flex">
                    <div className="row h-50">
                        <div className="col">&nbsp;</div>
                        <div className="col">&nbsp;</div>
                    </div>
                    <h5 className="m-2">
                        <span
                            className={`badge badge-pill ${
                                data.contacted ? "bg-success" : "bg-light"
                            } border `}
                        >
              &nbsp;
                        </span>
                    </h5>
                    <div className="row h-50">
                        <div className="col border-right">&nbsp;</div>
                        <div className="col">&nbsp;</div>
                    </div>
                </div>
                <div className="col py-2">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title text-muted">First Contact</h4>
                            {data.contacted ? <CandidateContacted /> : <SendFirstContact />}
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal for Email to Evaluator */}
            {emailModal ?
                <EmailModal
                    emailType={1}
                    show={emailModal}
                    handleClose={()=>setEmailModal( false )}
                    sendRequest={( i, emailTemplate )=>sendFirstContact( emailTemplate )}
                    index={5000006}
                />
                :""}
        </Fragment>
    );
};

function mapStateToProps( state ) {
    return {
        candidates: state.jobPositionCandidates,
        position: state.jobPosition
    };
}
function mapDispatchToProps( dispatch ) {
    return{
        setCandidates:( data ) => dispatch( setCandidates( data ) ),
        setCandidateStatus:( data ) => dispatch( setCandidateStatus( data ) ),
    };
}

export default connect( mapStateToProps, mapDispatchToProps )( Step1 );
