import React, { useEffect, useState } from 'react';
import { Popover } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, } from '@fortawesome/free-solid-svg-icons';
import util from '../../utils/miniUtils';
import Tooltip from "@material-ui/core/Tooltip";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";

const TextInput = ( { name, value, type, title, id, reload } ) => {
    const [edit, setEdit] = useState( false );
    const [state, setState] = useState( {
        [name]: value,
    } );

    // Anchor and open variable for the popover. 
    const [anchorEl, setAnchorEl] = React.useState( null );
    const open = Boolean( anchorEl );

    // Triggering the popover on the double click
    const handleDoubleClick = ( event ) => {
        setAnchorEl( event.currentTarget );
    };

    function handleChangeValue ( e ) {
        const { name, value } = e.target;
        setEdit( true );
        setState( {
            [name]: value,
        } );
    }

    const handleClose = async ( ) => {
        if( edit ) {
            try {
                const request = await util.axios.put( `/workload/update_workload/${id}`, state );
                const { error, msg } = request.data;
                if( error ) throw msg;
                util.toast().success( msg );
                reload();
            } catch ( error ) {
                util.handleError( error );
            }
        }
        // resetting the anchor for the popover
        setAnchorEl( null );
    };

    const handleCancel = () => {
        setEdit( false );
        setAnchorEl( null );
    };

    const theme = createTheme( {
        overrides: {
            MuiTooltip: {
                tooltip: {
                    maxWidth: "250px",
                    fontSize: "0.85em",
                }
            }
        }
    } );

    useEffect( () => {
        setState( {
            [name]: value
        } );
    }, [value] );

    function editValue() {
        return <Popover
            id={open ? 'text-input-popover' : undefined}
            open={open}
            anchorEl={anchorEl}
            onClose={handleCancel}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}>
            <div className='d-flex wl_textInputPopover'>
                <div className='form-group m-1'>
                    <label htmlFor={name}>{title}</label>
                    <input
                        className='w-100 form-control'
                        type={type}
                        name={name} 
                        value={state[name]}
                        onChange={handleChangeValue}
                    />
                </div>
                <div className="d-flex align-items-end m-1">
                    <span className='btn btn-outline-danger' onClick={handleCancel}><FontAwesomeIcon icon={faTimes} /></span>
                    <span className='btn btn-outline-primary' onClick={handleClose}><FontAwesomeIcon icon={faCheck} /></span>
                </div>
            </div>
        </Popover>;
    }

    return <>
        <MuiThemeProvider theme={theme}>
            {editValue()}
            <Tooltip title={value}>
                <div className={`editableWorkload ${name === "short_description" ? "editableWorkload_title" : ""} `} onDoubleClick={handleDoubleClick}>
                    {value ? state[name] : "_ _ _ _"}
                </div>
            </Tooltip>
        </MuiThemeProvider>
    </>;
};

export default TextInput;