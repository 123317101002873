import { useState, useEffect } from "react";
import util from "../../utils/miniUtils";

const useAsset = () => {
    const [catalogSelected, setCatalogSelected] = useState( 0 );
    const [catalog, setCatalog] = useState( [] );
    const [item, setItem] = useState( {} );
    const [isEdit, setIsEdit] = useState( false );

    useEffect( () => {
        getCatalog().then( null );
        setItem( Object.assign( {}, { name: "", description: "", category_id: 0 } ) );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [catalogSelected] );

    async function getCatalog() {
        try {
            if ( catalogSelected === 0 ) return;
            const req = await util.axios.get(
        `${util.url}/assets_manager/catalog/${catalogSelected}`
            );
            const { error, msg, data } = req.data;
            if ( error ) throw msg;
            setCatalog( data );
        } catch ( e ) {
            util.handleError( e );
        }
    }

    function handleChange( e ) {
        const { name, value } = e.target;
        setItem( { ...item, [name]: value } );
    }

    function handleCategoryChange( e ) {
        setCatalogSelected( parseInt( e.target.value ) );
        setIsEdit( false );
    }

    async function handleClick() {
        try {
            if ( catalogSelected === 0 ) return;
            let request;
            if ( isEdit ) {
                request = await util.axios.put(
          `${util.url}/assets_manager/update/${catalogSelected}`,
          { item }
                );
            } else {
                let info = { type: catalogSelected };
                switch ( catalogSelected ) {
                    case 1:
                        info.type = 4;
                        break;
                    case 2:
                        info.type = 5;
                        break;
                    case 3:
                        info.type = 1;
                        break;
                    case 4:
                        info.type = 2;
                        break;
                    case 5:
                        info.type = 3;
                        break;
                    default:
                        break;
                }
                info.name = item.name;
                info.description = item.description;
                if ( catalogSelected === 2 ) {
                    info.category_id = item.category_id;
                    info.all = true;
                }
                request = await util.axios.post(
          `${util.url}/assets_manager/add/`,
          info
                );
            }

            const { error, msg, data, newData } = request.data;
            if ( error ) throw msg;
            util.toast().success( msg );
            setCatalog( isEdit ? data : newData );
            if ( !isEdit ) {
                setItem( { name: "", description: "", category_id: 0 } );
                setIsEdit( false );
            }
        } catch ( error ) {
            util.handleError( error );
        }
    }

    function createNewItem() {
        setItem( { name: "", description: "", category_id: 0 } );
        setIsEdit( false );
    }

    return {
        catalogSelected,
        setCatalogSelected,
        catalog,
        setCatalog,
        item,
        setItem,
        isEdit,
        setIsEdit,
        getCatalog,
        handleChange,
        handleCategoryChange,
        handleClick,
        createNewItem,
    };
};

export default useAsset;
