import "./styles.css";
import React from 'react';

// status color changing in big status images
  
export default function Status( { appraiseeStatus,l2_exist,l3_exist } ) {
    // console.log( appraiseeStatus );
    return (
        <div className={`${( l2_exist || l3_exist ) ? "grid-container" : "otherStyle"} ${(  !l3_exist ) ? "otherStyle" : "grid-container"}`}>
            <div className="appraisee1">
                
                <div className={`target-circle status${ appraiseeStatus && appraiseeStatus}`}></div> 
            
               
                <div className={`review-circle status${ appraiseeStatus && appraiseeStatus}`}></div> 
            
            </div>
            <div className='l1'>
                <div className={`target-circlel1 status${ appraiseeStatus && appraiseeStatus}`}></div>
                <div className={`review-circlel1 status${appraiseeStatus && appraiseeStatus}`}></div></div>

           
            {l2_exist ? ( <div className='l2'>
                <div className={`target-circlel2 status${appraiseeStatus && appraiseeStatus}`}></div>
                <div className={`review-circlel2 status${appraiseeStatus && appraiseeStatus}`}></div></div> ) : ""} 
                 
            {l3_exist ?( <div className='l3'>
                <div className={`target-circlel3 status${appraiseeStatus && appraiseeStatus}`}></div>
                <div className={`review-circlel3 status${appraiseeStatus && appraiseeStatus}`}></div>
            </div> ) : ""}
            <div className='hr'>
                <div className={`target-circlehr status${appraiseeStatus && appraiseeStatus}`}></div>
                <div className={`review-circlehr status${appraiseeStatus && appraiseeStatus}`}> </div>
            </div>
        </div>
    );
}

