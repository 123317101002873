import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, Fragment, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import util from "../../../utils/miniUtils";

export default function DragAndDrop( { statusList, deleteStatusCategory, updateCategoryStatusOrder, getAssignedStatus, id } ) {
    // React state to track order of items
    const [itemList, setItemList] = useState( statusList );
    useEffect( () => {setItemList( statusList );}, [statusList] );

    // Function to update list on drop
    const handleDrop = async ( result ) => {
        const { source, destination } = result;
        // Ignore drop outside droppable container
        if ( !destination ) return;

        // Ignore if the drop source.index is same as destination.index
        if( destination.index === source.index ) return;

        var updatedList = [...itemList];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice( source.index, 1 );

        // Add dropped item
        updatedList.splice( destination.index, 0, reorderedItem );
        // Update State
        setItemList( updatedList );

        // Update database
        try {
            for ( const [index, element] of updatedList.entries() ) {
                await updateCategoryStatusOrder( element.statusCategoryId, index + 1 );
            }
            getAssignedStatus( id );        
            util.toast().success( "Status Updated" );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const deleteItem = ( e, id ) => {
        e.stopPropagation();
        deleteStatusCategory( id );
    };

    return (
        <div className="w-100">
            <DragDropContext onDragEnd={handleDrop}>
                <Droppable droppableId="listContainer w-100">
                    {( provided ) => (
                        <div className="listContainer p-1" {...provided.droppableProps} ref={provided.innerRef}>
                            {itemList.map( ( item, index ) => ( 
                                <Draggable key={item.statusCategoryId} draggableId={`item_${item.statusCategoryId}`} index={index}>
                                    {( provided ) => (
                                        <Fragment>
                                            <div ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps}>
                                                <ItemCard
                                                    data={item}
                                                    deleteItem={deleteItem}
                                                    cannotbeDragged={item.name === "Open" || item.name === "Closed" || item.name === "Acknowledged" || item.name === "ReOpen"}
                                                />
                                            </div>
                                        </Fragment>
                                    )}
                                </Draggable>
                            ) )}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
}

const ItemCard = ( { data, deleteItem, cannotbeDragged } ) => {
    // const { value } = data;

    // const [isEdit, setIsEdit] = useState( false );
    // const [newValue, setNewValue] = useState( value );

    // const onChange = ( e ) => setNewValue( e.target.value );

    // const onCancel = () => {
    //     setNewValue( value );
    //     setIsEdit( false );
    // };
    return (
        <div className="w-100">
            {/* {!isEdit ? ( */}

            <div className={`d-flex justify-content-between border p-1 pl-2 m-2 align-items-center ${cannotbeDragged && "border-primary"}`}>
                <div>{data.name}</div>
                <button 
                    className="btn btn-sm p-1 btn-outline-danger" 
                    onClick={( e ) => deleteItem( e, data.statusCategoryId )}>
                    <FontAwesomeIcon icon={faTrash} />
                </button>
            </div>
            {/* ) : (
                <Fragment>
                    <div className="d-flex justify-content-between border p-1 pl-2 m-2 align-items-center">
                        <input value={newValue} onChange={onChange} className="form-control" />
                        <span onClick={onCancel} className="p-1 isCursor bg-danger rounded mx-1">
                            <FontAwesomeIcon icon={faBan} />
                        </span>
                        <span onClick={updateData} className="p-1 isCursor bg-success rounded">
                            <FontAwesomeIcon icon={faSave} />
                        </span>
                    </div>
                </Fragment>
            )} */}
        </div>
    );
};
