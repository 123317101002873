import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCaretDown,
    faCaretUp,
    faTimes,
} from "@fortawesome/free-solid-svg-icons";
import util from "../../../utils/miniUtils";
import {
    Collapse,
    Grid,
    InputAdornment,
    TextField,

} from "@material-ui/core";
import { Badge } from "react-bootstrap";
import UserBox from "../UserBox";
import FileUploader from "../Upload/FileUploader";

const StartReviewAccordion = ( { item, role, reloadCheck, status } ) => {
    const {
        appraisal_item_id,
        type,
        gc_desc,
        gc_title,
        weight,
        gc_target,
        l1_name,
        l2_name,
        l3_name,
        hr_name,
    } = item;
    // console.log( appraisal_item_id );

    const [state, setState] = useState( {
        expanded: false,
        item: item,
        itemBase: item,
        showSaveButton: false,
        save: false,
    } );
    const [loading, setLoading] = useState( false );
    const [attachmentsList, setAttachmentsList] = useState( [] );

    const reloadAttachmentsOnItem = ( attachments ) => {
        setAttachmentsList( attachments );
    };
    useEffect( () => {
        getAllAttachments();
    }, [] );
    //delete attachments
    const deleteAttachment = async ( attachment_id ) => {
        try {
            const request = await util.axios.delete(
        `/appraisal/delete_attachment/${attachment_id}`
            );
            const { error, msg, attachments } = request.data;
            if ( error ) throw msg;
            reloadAttachmentsOnItem( attachments );
        } catch ( error ) {
            util.handleError( error );
        }
    };
    // request for get all attachments
    const getAllAttachments = async () => {
        try {
            setLoading( true );
            const request = await util.axios.get(
        `appraisal/get_attachments/${appraisal_item_id}`
            );
            const { error, attachments, msg } = request.data;
            if ( error ) throw msg;
            setAttachmentsList( attachments );
            // console.log( attachments );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };

    const expand = () => setState( { ...state, expanded: !state.expanded } );

    const handleChange = ( e ) => {
        const { name, value } = e.target;
        let t = { ...state.item };
        t[name] = value;
        setState( { ...state, item: t, save: false } );
    };
    //validation for evaluation score
    const isValidationScore = () => {
        const msg = ( string ) => {
            return { pass: false, msg: `${string}` };
        };
        const { employee_score, l1_score, l2_score, l3_score, hr_score } =
      state.item;
        if ( parseInt( employee_score ) < 1 || parseInt( employee_score ) > 100 )
            return msg( " Self Evaluation Score value must be between 1 and 100" );
        if (
            role.includes( "L1" ) &&
      status === 14 &&
      util.user.employee_id === item.l1_evaluator
        ) {
            if ( parseInt( l1_score ) < 1 || parseInt( l1_score ) > 100 )
                return msg( " L1 Evaluation Score value must be between 1 and 100" );
        }
        if (
            role.includes( "L2" ) &&
      status === 15 &&
      util.user.employee_id === item.l2_evaluator
        ) {
            if ( parseInt( l2_score ) < 1 || parseInt( l2_score ) > 100 )
                return msg( " L2 Evaluation Score value must be between 1 and 100" );
        }
        if (
            role.includes( "L3" ) &&
      status === 16 &&
      util.user.employee_id === item.l3_evaluator
        ) {
            if ( parseInt( l3_score ) < 1 || parseInt( l3_score ) > 100 )
                return msg( " L3 Evaluation Score value must be between 1 and 100" );
        }
        if (
            role.includes( "HR" ) &&
      status === 17 &&
      util.user.employee_id === item.hr_evaluator
        ) {
            if ( parseInt( hr_score ) < 1 || parseInt( hr_score ) > 100 )
                return msg( " HR Evaluation Score value must be between 1 and 100" );
        }
        return { pass: true };
    };

    // Save changes of item in the database
    const saveChangesOnItem = async () => {
        const validation = isValidationScore();
        if ( !validation.pass ) {
            return util.toast().error( validation.msg );
        }
        try {
            let temp = [];
            if ( role === "employee" && status === 13 ) {
                temp.push( {
                    id: appraisal_item_id,
                    score: state.item.employee_score,
                    feedback: state.item.employee_feedback,
                } );
            }
            if (
                role.includes( "L1" ) &&
        status === 14 &&
        util.user.employee_id === item.l1_evaluator
            ) {
                temp.push( {
                    id: appraisal_item_id,
                    score: state.item.l1_score,
                    feedback: state.item.l1_feedback,
                } );
            }
            if (
                role.includes( "L2" ) &&
        status === 15 &&
        util.user.employee_id === item.l2_evaluator
            ) {
                temp.push( {
                    id: appraisal_item_id,
                    score: state.item.l2_score,
                    feedback: state.item.l2_feedback,
                } );
            }
            if (
                role.includes( "L3" ) &&
        status === 16 &&
        util.user.employee_id === item.l3_evaluator
            ) {
                temp.push( {
                    id: appraisal_item_id,
                    score: state.item.l3_score,
                    feedback: state.item.l3_feedback,
                } );
            }
            if (
                role.includes( "HR" ) &&
        status === 17 &&
        util.user.employee_id === item.hr_evaluator
            ) {
                temp.push( {
                    id: appraisal_item_id,
                    score: state.item.hr_score,
                    feedback: state.item.hr_feedback,
                } );
            }
            const request = await util.axios.put( `/appraisal_items_update/`, temp );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            {
                role == "employee" &&
          setState( {
              ...state,
              showSaveButton: false,
              itemBase: Object.assign( {}, state.item ),
              save: true
          } );
            }
            setState( ( prev ) => {
                return {
                    ...prev,
                    showSaveButton: false,
                    itemBase: { ...prev.item },
                    // item: {
                    //     employee_score: prev.item.employee_score,
                    //     employee_feedback: prev.item.employee_feedback,
                    // },
                    save: true,
                };
            } );
            reloadCheck();
            // console.log( state.showSaveButton );
            // setState( { showSaveButton: false } );

            // console.log( temp );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    // Save changes of item in the database
    const cancelChanges = () =>
        setState( {
            ...state,
            showSaveButton: false,
            item: Object.assign( {}, state.itemBase ),
        } );

    useEffect( () => {
        if ( JSON.stringify( state.item ) !== JSON.stringify( state.itemBase ) ) {
            // Something change.. display save button
            !state.save && setState( { ...state, showSaveButton: true } );
        }
    }, [state.item] );

    return (
        <div className="p-4 mb-3 border-bottom">
            <div
                onClick={expand}
                className="d-flex justify-content-between isPointer"
            >
                <div className="mb-3" style={{ flex: "9" }}>
                    <span className="position-absolute">
                        <FontAwesomeIcon
                            size="2x"
                            className="my-icon"
                            icon={state.expanded ? faCaretUp : faCaretDown}
                        />
                    </span>
                    <b className="ml-4">{util.isGoalOrCompetence( type )}:</b>
                    <b className="text-secondary ml-1">{gc_title}</b>
                </div>
                <div style={{ flex: "2" }}>
                    <b className="mb-3 text-secondary">Target: {gc_target}</b>
                </div>

                <div style={{ flex: "1" }}>
                    <b className="mb-5 text-secondary">Weight: {weight}%</b>
                </div>
            </div>
            <Collapse in={state.expanded}>
                <div className="p-1 border ap_frame_border">
                    <Grid container justifyContent="center">
                        <Grid xs={6} md={3} lg={2}>
                            <UserBox name={l1_name} title="L1 Evaluator" />
                        </Grid>
                        {l2_name ? (
                            <Grid xs={6} md={3} lg={2}>
                                <UserBox name={l2_name} title="L2 Evaluator" />
                            </Grid>
                        ) : (
                            ""
                        )}
                        {l3_name ? (
                            <Grid xs={6} md={3} lg={2}>
                                <UserBox name={l3_name} title="L3 Evaluator" />
                            </Grid>
                        ) : (
                            " "
                        )}
                        <Grid xs={6} md={3} lg={2}>
                            <UserBox name={hr_name} title="HR Evaluator" />
                        </Grid>
                    </Grid>

                    <div className="container-fluid mt-2">
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={12} lg={1}>
                                <b>Description:</b>
                            </Grid>
                            <Grid item xs={12} md={12} lg={8} className="mt-2 ml-2">
                                <textarea
                                    className="form-control"
                                    name="gc_desc"
                                    value={gc_desc}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={2}>
                                <FileUploader
                                    appraisal_item_id={item.appraisal_item_id}
                                    reloadAttachmentsOnItem={reloadAttachmentsOnItem}
                                />
                            </Grid>
                           
                        </Grid>
                        <Grid container spacing={2} className="mt-3">
                            {attachmentsList.length > 0 ? (
                                <>
                                    <Grid item xs={12} md={2} lg={1}>
                                        <b>Attachments:</b>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={7} className="ml-2">
                                        {attachmentsList &&
                      attachmentsList.map( ( a, i ) => {
                          const { appraisal_item_id, name } = a;
                          return (
                              <Badge
                                  key={i}
                                  pill
                                  bg="secondary"
                                  className="mr-1 isPointer badgeCustom"
                              >
                                  <a
                                      href={`${util.url}/appraisal_attachments/${appraisal_item_id}/${name}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                  >
                                      {a.name}
                                  </a>
                                  <span
                                      className="p-1 isPointer"
                                      onClick={() => deleteAttachment( a.attachment_id )}
                                  >
                                      <FontAwesomeIcon
                                          icon={faTimes}
                                          className="text-danger"
                                      />
                                  </span>
                              </Badge>
                          );
                      } )}
                                    </Grid>
                                </>
                            ) : (
                                ""
                            )}
                        </Grid>
                        {status > 13 &&item.employee_score !== null ? (
                            <Grid container spacing={2} className="mt-3">
                                <Grid item xs={12} md={12} lg={1}>
                                    <b>Appraisee Feedback:</b>
                                </Grid>
                                <Grid item xs={12} md={8} lg={8} className="mt-2 ml-2">
                                    {" "}
                                    <textarea
                                        className="form-control"
                                        name="l2_feedback"
                                        value={item.employee_feedback || ""}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} lg={2}>
                                    {" "}
                                    <b className="ml-1">Appraisee Evaluation:</b>
                                    <span className="ml-1">{item.employee_score}%</span>
                                </Grid>
                            </Grid>
                        ) : (
                            ""
                        )}

                        { status > 14 && item.l1_score !== null ? (
                            <Grid container spacing={1} className="mt-3">
                                <Grid item xs={12} md={12} lg={1}>
                                    <b>L1 Feedback:</b>
                                </Grid>
                                <Grid item xs={12} md={8} lg={8} className="mt-2 ml-2">
                                    {" "}
                                    <textarea
                                        className="form-control"
                                        name="l1_feedback"
                                        value={item.l1_feedback || ""}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} lg={2}>
                                    {" "}
                                    <b className="ml-1">L1 Evaluation :</b>
                                    <span className="ml-3 ">{item.l1_score}%</span>
                                </Grid>
                            </Grid>
                        ) : (
                            ""
                        )}
                        {status > 15 && item.l2_score !== null ? (
                            <Grid container spacing={1} className="mt-3">
                                <Grid item xs={12} md={12} lg={1}>
                                    <b>L2 Feedback:</b>
                                </Grid>
                                <Grid item xs={12} md={8} lg={8} className="mt-2 ml-2">
                                    {" "}
                                    <textarea
                                        className="form-control"
                                        name="l2_feedback"
                                        value={item.l2_feedback || ""}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} lg={2}>
                                    {" "}
                                    <b className="ml-1">L2 Evaluation :</b>
                                    <span className="ml-3 ">{item.l2_score}%</span>
                                </Grid>
                            </Grid>
                        ) : (
                            ""
                        )}
                        {status > 16 && item.l3_score !== null ? (
                            <Grid container spacing={1} className="mt-3">
                                <Grid item xs={12} md={12} lg={1}>
                                    <b>L3 Feedback:</b>
                                </Grid>
                                <Grid item xs={12} md={8} lg={8} className="mt-2 ml-2">
                                    {" "}
                                    <textarea
                                        className="form-control"
                                        name="l3_feedback"
                                        value={item.l3_feedback || ""}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} lg={2} className="mt-2 ml-2">
                                    {" "}
                                    <b className="ml-1">L3 Evaluation :</b>
                                    <span className="ml-3 ">{item.l3_score}%</span>
                                </Grid>
                            </Grid>
                        ) : (
                            ""
                        )}
                        {status > 17 && item.hr_score !== null ? (
                            <Grid container spacing={1} className="mt-3">
                                <Grid item xs={12} md={12} lg={1}>
                                    <b>HR Feedback:</b>
                                </Grid>
                                <Grid item xs={12} md={8} lg={8} className="mt-2 ml-2">
                                    {" "}
                                    <textarea
                                        className="form-control"
                                        name="hr_feedback"
                                        value={item.hr_feedback || ""}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} lg={2}>
                                    {" "}
                                    <b className="ml-1">HR Evaluation :</b>
                                    <span className="ml-3 ">{item.hr_score}%</span>
                                </Grid>
                            </Grid>
                        ) : (
                            ""
                        )}
                       
                       

                        {role == "employee" && status === 13 && (
                            <Grid container spacing={2} className="mt-3">
                                <Grid item xs={12} md={12} lg={1}>
                                    <b>Appraisee Feedback:</b>
                                </Grid>
                                <Grid item xs={12} md={8} lg={8} className="ml-2">
                                    {" "}
                                    <textarea
                                        placeholder="Write Your Feedback"
                                        className="form-control ml-2"
                                        name="employee_feedback"
                                        onChange={handleChange}
                                        value={state.item.employee_feedback || ""}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} lg={2}>
                                    <TextField
                                        label="Appraisee Evaluation"
                                        name="employee_score"
                                        variant="outlined"
                                        type="number"
                                        onChange={handleChange}
                                        value={state.item.employee_score || ""}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">%</InputAdornment>
                                            ),
                                        }}
                                    />{" "}
                                </Grid>
                            </Grid>
                        )}
                        {( role.includes( "MANAGER" ) ||
              ( role.includes( "L1" ) && status == 14 ) ) &&
              util.user.employee_id === item.l1_evaluator && (
                            <Grid container spacing={2} className="mt-3">
                                <Grid item xs={12} md={12} lg={1}>
                                    <b>L1 Feedback:</b>
                                </Grid>
                                <Grid item xs={12} md={8} lg={8} className="ml-2">
                                    {" "}
                                    <textarea
                                        placeholder="Write Your Feedback"
                                        className="form-control ml-2"
                                        name="l1_feedback"
                                        onChange={handleChange}
                                        value={state.item.l1_feedback || ""}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} lg={2}>
                                    <TextField
                                        label="L1 Evaluation"
                                        name="l1_score"
                                        variant="outlined"
                                        type="number"
                                        onChange={handleChange}
                                        value={state.item.l1_score || "" || ""}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">%</InputAdornment>
                                            ),
                                        }}
                                    />{" "}
                                </Grid>
                            </Grid>
                        )}

                        {role.includes( "L2" ) &&
              status == 15 &&
              util.user.employee_id === item.l2_evaluator && (
                            <Grid container spacing={2} className="mt-3">
                                <Grid item xs={12} md={12} lg={1}>
                                    <b>L2 Feedback:</b>
                                </Grid>
                                <Grid item xs={12} md={8} lg={8} className="ml-2">
                                    <textarea
                                        placeholder="Write Your Feedback"
                                        className="form-control"
                                        name="l2_feedback"
                                        onChange={handleChange}
                                        value={state.item.l2_feedback || ""}

                                    />
                                </Grid>
                                <Grid item xs={12} md={4} lg={2}>
                                    <TextField
                                        label="L2 Evaluation"
                                        name="l2_score"
                                        variant="outlined"
                                        type="number"
                                        onChange={handleChange}
                                        value={state.item.l2_score || "" || ""}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">%</InputAdornment>
                                            ),
                                        }}
                                    />{" "}
                                </Grid>
                            </Grid>
                        )}
                        {role.includes( "L3" ) &&
              status == 16 &&
              util.user.employee_id === item.l3_evaluator && (
                            <Grid container spacing={2} className="mt-3">
                                <Grid item xs={12} md={12} lg={1}>
                                    <b>L3 Feedback:</b>
                                </Grid>
                                <Grid item xs={12} md={8} lg={8} className="ml-2">
                                    <textarea
                                        placeholder="Write Your Feedback"
                                        className="form-control"
                                        name="l3_feedback"
                                        onChange={handleChange}
                                        value={state.item.l3_feedback || ""}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} lg={2}>
                                    <TextField
                                        label="L3 Evaluation"
                                        name="l3_score"
                                        variant="outlined"
                                        type="number"
                                        onChange={handleChange}
                                        value={state.item.l3_score || "" || ""}

                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">%</InputAdornment>
                                            ),
                                        }}
                                    />{" "}
                                </Grid>
                            </Grid>
                        )}

                        {role.includes( "HR" ) &&
              status === 17 &&
              util.user.employee_id === item.hr_evaluator && (
                            <Grid container spacing={2} className="mt-3">
                                <Grid item xs={12} md={12} lg={1}>
                                    <b>HR Feedback:</b>
                                </Grid>
                                <Grid item xs={12} md={8} lg={8} className="ml-2">
                                    <textarea
                                        placeholder="Write Your Feedback"
                                        className="form-control"
                                        name="hr_feedback"
                                        onChange={handleChange}
                                        value={state.item.hr_feedback || ""}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} lg={2}>
                                    <TextField
                                        label="HR Evaluation"
                                        name="hr_score"
                                        variant="outlined"
                                        type="number"
                                        onChange={handleChange}
                                        value={state.item.hr_score || "" || ""}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">%</InputAdornment>
                                            ),
                                        }}
                                    />{" "}
                                </Grid>
                            </Grid>
                        )}
                    </div>
                    <div className="float-right m-2">
                        {state.showSaveButton ? (
                            <Fragment>
                                <span className="likeLink mx-2" onClick={cancelChanges}>
                  Cancel
                                </span>
                                <button
                                    onClick={saveChangesOnItem}
                                    className="btn btn-sm btn-primary m-1"
                                >
                  Save
                                </button>
                            </Fragment>
                        ) : null}
                    </div>
                </div>
            </Collapse>
        </div>
    );
};

export default StartReviewAccordion;
