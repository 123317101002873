import { faCommentAlt, faFileExport, faFilter, faTable } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MuiThemeProvider, Tooltip } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import util from "../../../utils/miniUtils";
import CategorySelector from "../../manage_workload/components/CategorySelector";
import CreateDuplicateWorkload from "../../manage_workload/components/CreateDuplicateWorkload";
import GroupSelector from "../../manage_workload/components/GroupSelector";
import ManageWorkLoadDetail from "../../manage_workload/components/ManageWorkLoadDetail";
import useManageWorkload from "../../manage_workload/useManageWorkLoad";
import BulkEditWorkload from "../../shared/BulkEditWorkload";
import ChildTicketList from "../../shared/ChildTicketList";
import DeleteWorkloadModal from "../../shared/DeleteWorkloadModal";
import Filter from "../../shared/Filter";
import TableMenu from "../../shared/TableMenu";
import TextInput from "../../shared/TextInput";
import TicketNumber from "../../shared/TicketNumber";
import useWorkload from "../../shared/useWorkload";
import WorkloadSelector from "../../shared/WorkloadSelector";
import ViewColumnIcon from '@material-ui/icons/ViewColumn';

const tableRef = React.createRef();
const LocalStorageKey = "myWorkloadColumns";
const reloadTable = () => {tableRef.current && tableRef.current.onQueryChange( undefined );};

const WorkLoadTable = ( { url } ) => {
    const { loading, handleAcknowledge, workLoadCreated } = useWorkload();
    const { deleteWorkload } = useManageWorkload();
    const [workloads, setWorkloads] = useState( [] );
    const [showFilter, setShowFilter] = useState( false );
    const [filterState, setFilterState] = useState( {} );
    const [bulkShow, setBulkShow] = useState( false );
    const columns=[
        {
            title: "",
            field: 'sta',
            render: value => {
                if( value.statusName === "Open" || value.statusName === "New" ){
                    return <>
                        <Tooltip title={"Acknowledge"}>
                            <button className="btn btn-outline-primary btn-sm" onClick={( e ) => handleAcknowledge( e, value )}>
                                Ack.
                            </button>
                        </Tooltip>
                    </>;
                }}
        },
        {
            title: "Ticket Number",
            field: 'id',
            render: value => value && value.ticket_number ? value.ticket_number : <TicketNumber categoryId={value.wl_category_id} id={value.id} />
        },
        { title: "",
            field:"activity",
            render:( row ) => <TableMenu
                row={row} 
                handleDelete={handleDelete}
                handleWorkloadDuplicateBtn={handleWorkloadDuplicateBtn}
                showWorkLoadDetail={showWorkLoadDetail} />,
            cellStyle: {
                backgroundColor: "#f0f0f0",
            }
        },
        {
            title: "Title",
            field: 'short_description',
            cellStyle: {
                backgroundColor: "#f0f0f0"
            },
            render: d => {
                return <TextInput
                    title="Title"
                    name={'short_description'}
                    value={d.short_description}
                    type="text"
                    id={d.id}
                    reload={reloadData}
                />;
            },
        },
        {
            title: "Created On",
            field: 'createdAt',
            render: ( value ) => {
                return <div>{util.humanDateTime( value.createdAt )}</div>;
            },
            cellStyle: {
                whiteSpace: 'nowrap'
            },
        },
        {
            title: "Customer Name",
            field: 'customer_name',
        },
        {
            title: "Customer Department",
            field: 'department_name',
        },
        {
            title: "Priority",
            field: 'Priority',
            render: d => {
                return <WorkloadSelector
                    title="Priority"
                    name={'priority'}
                    value={d.priority}
                    id={d.id}
                    data={d}
                    reload={reloadData}
                />;
            },
            cellStyle: {
                backgroundColor: "#f0f0f0"
            },
        },
        {
            title: "Status",
            field: 'statusName',
            render: d => {
                return <WorkloadSelector
                    title="Status"
                    name={'status'}
                    value={d.status}
                    id={d.id}
                    data={d}
                    reload={reloadData}
                />;
            },
            cellStyle: {
                backgroundColor: "#f0f0f0"
            },
        },
        {
            title: "Child Tickets",
            field: 'childTickets',
            render: d => {
                return <ChildTicketList data={d.childTickets} />;
            }
        },
        {
            title: "Parent Ticket",
            field: 'parentName',
            render: value => {
                return value.parent_ticket_id ?
                    <Tooltip title={value.parentName}>
                        <div className="parentTicket_col">{value.parentName && value.parentName.substring( 0,50 )}</div>
                    </Tooltip> : "";
            }
        },
        {
            title: "ETA",
            field: 'ETA',
            render: value => <div>{value.ETA && util.humanDateTime( value.ETA )}</div>
        },
        {
            title: "Long Description",
            field: 'long_description',
            render: value => {
                return value ?
                    <Tooltip title={value.long_description}>
                        <span className="text-primary"><FontAwesomeIcon icon={faCommentAlt} /></span>
                    </Tooltip> : "";
            }
        },
        {
            title: "Assignment Group",
            field: 'group_name',
            render: d => {
                return <GroupSelector
                    title="Assignment Group"
                    name={'group_id'}
                    value={d.group_id}
                    id={d.id}
                    data={d}
                    reload={reloadData}
                />;
            },
            cellStyle: {
                backgroundColor: "#f0f0f0"
            },
        },
        {
            title: "Workload Category",
            field: 'categoryName',
            cellStyle: {
                backgroundColor: "#f0f0f0"
            },
            render: d => {
                if( d.statusName  === "New" || d.statusName === "Open" ) {
                    return <CategorySelector
                        title="Workload Category"
                        value={d.wl_category_id}
                        id={d.id}
                        data={d}
                        reload={reloadData}
                    />;
                } else {
                    return <div>{d.categoryName}</div>;
                }
            },
            
        },
        {
            title: "Assigned To",
            field: "resolver_name",
            cellStyle: {
                backgroundColor: "#f0f0f0"
            },
            render: d => {
                return <WorkloadSelector
                    title="Assigned To"
                    name={'resolver_id'}
                    value={d.resolver_id}
                    id={d.id}
                    data={d}
                    reload={reloadData}
                />;
            },
        },
        {
            title: "Assigned to Group Time",
            field: "RO_assigned_to_group_time",
            render: ( value ) => {
                return <div>{util.humanDateTime( value.RO_assigned_to_group_time )}</div>;
            },
            cellStyle: {
                whiteSpace: 'nowrap',
            }, 

        },
        {
            title: "Ticket Category",
            field: 'ticket_category',
            render: d => {
                return <TextInput
                    title="Ticket Category"
                    name={'ticket_category'}
                    value={d.ticket_category}
                    id={d.id}
                    type="text"
                    reload={reloadData}
                />;
            },
            cellStyle: {
                backgroundColor: "#f0f0f0"
            },
        },
        {
            title: "Ticket Type",
            field: "ticket_type",
            render: d => {
                return <TextInput
                    title="Ticket Type"
                    name={'ticket_type'}
                    value={d.ticket_type}
                    id={d.id}
                    type="text"
                    reload={reloadData}
                />;
            },
            cellStyle: {
                backgroundColor: "#f0f0f0"
            },
        },
        {
            title: "Item",
            field: 'item',
            render: d => {
                return <TextInput
                    title="Item"
                    name={'item'}
                    value={d.item}
                    type="text"
                    id={d.id}
                    reload={reloadData}
                />;
            },
            cellStyle: {
                backgroundColor: "#f0f0f0"
            },
        },
        {
            title: "Reference Number",
            field: 'reference_number',
            render: d => {
                return <TextInput
                    title="Reference Number"
                    name={'reference_number'}
                    value={d.reference_number}
                    id={d.id}
                    type="text"
                    reload={reloadData}
                />;
            },
            cellStyle: {
                backgroundColor: "#f0f0f0"
            },
        },
        {
            title: "Actual Response Time",
            field: 'RO_actual_response_sla',
            render: value =>{
                if( value.RO_actual_response_sla ){
                    return util.time_SLA_render( value.RO_actual_response_sla );
                }
            }
        },
        // Overall Resoltion SLA
        {
            title: "Overall Business Days Resolution",
            field: 'RO_sla_minuteso',
            render: value =>{
                if( value.RO_sla_bizdays ){
                    return util.time_SLA_days_render( value.RO_sla_bizdays );
                }
            }
        },
        {
            title: "Overall Calender Days Resolution",
            field: 'RO_sla_minutesc',
            render: value =>{
                if( value.RO_sla_caldays ){
                    return util.time_SLA_days_render( value.RO_sla_caldays );
                }
            }
        },
        // Group Resolution SLA
        {
            title: "Group Business Days Resolution",
            field: 'RO_sla_minutesb',
            render: value =>{
                if( value.RO_group_sla_bizdays ){
                    return util.time_SLA_days_render( value.RO_group_sla_bizdays );
                }
            }
        },
        {
            title: "Group Calender Days Resolution",
            field: 'RO_sla_minutesd',
            render: value =>{
                if( value.RO_group_sla_caldays ){
                    return util.time_SLA_days_render( value.RO_group_sla_caldays );
                }
            }
        },
        // Resolver Resolution SLA
        {
            title: "Resolver Business Days Resolution",
            field: 'RO_sla_minutesr',
            render: value =>{
                if( value.RO_resolver_sla_bizdays ){
                    return util.time_SLA_days_render( value.RO_resolver_sla_bizdays );
                }
            }
        },
        {
            title: "Resolver Calender Days Resolution",
            field: 'RO_sla_minutese',
            render: value =>{
                if( value.RO_resolver_sla_caldays ){
                    return util.time_SLA_days_render( value.RO_resolver_sla_caldays );
                }
            }
        },
        {
            title: "Ticket Hop Count",
            field: 'RO_ticket_hop_count',
        },
        {
            title: "Ticket Log",
            field: 'wTicketLog',
            render: value => {
                return value ?
                    <Tooltip title={value.RO_wl_log}>
                        <span className="text-primary"><FontAwesomeIcon icon={faCommentAlt} /></span>
                    </Tooltip> : "";
            }
        },
    ] ;
    const [showColumns, setShowColumns] = useState( columns.map( ( c ) => c.field ) );
    const [order, setOrder] = useState( localStorage.getItem( LocalStorageKey ) );
    // const [dragging, setDragging] = useState( false );
  
    useEffect( () => {
        if ( order ) {
            setShowColumns( JSON.parse( order ) );
        }
    }, [order] );
  
    const handleSave = () => {
        localStorage.setItem( LocalStorageKey, JSON.stringify( showColumns ) );
    };
  
    const handleChange = ( event ) => {
        const field = event.target.value;
        if ( showColumns.includes( field ) ) {
            setShowColumns( showColumns.filter( ( c ) => c !== field ) );
        } else {
            setShowColumns( [...showColumns, field] );
        }
    };
    const tableColumns = columns.filter( ( c ) => showColumns.includes( c.field ) );

    function handleColumnDrag( sourceIndex, destinationIndex ) {
        setShowColumns( ( prevArray ) => {
            const newArray = [...prevArray];
            const temp = newArray[sourceIndex];
            newArray[sourceIndex] = newArray[destinationIndex];
            newArray[destinationIndex] = temp;
            return newArray;
        } );
    }
  
    
    const [workloadDetail, setWorkloadDetail] = useState( {
        show: false,
        workload_id: null,
        permission_level: null,
    } );

    const [deleteWorkloadModal, setDeleteWorkloadModal] = useState( {
        show: false,
        workload_id: null,
        workload_title: null
    } );

    const [duplicateWorkload, setDuplicateWorkload] = useState( {
        show: false,
        workloadData: null,
        workload_title: {}
    } );

    const closeDeleteWorkload = () => {
        setDeleteWorkloadModal( {
            show: false,
            workload_id: null,
            workload_title: null
        } );
        tableRef.current && tableRef.current.onQueryChange( undefined );
    };

    const handleWorkloadDuplicateBtn = ( data ) => {
        setDuplicateWorkload( ( prev ) => {
            return {
                ...prev,
                show: true,
                workloadData: {
                    department_id: data.department_id,
                    wl_category_id: data.wl_category_id,
                    parent_ticket_id: data.parent_ticket_id,
                    customer_id: data.customer_id,
                    resolver_id: data.resolver_id,
                    group_id: data.group_id,
                    updated_by: data.updated_by,
                    status: data.status,
                    status_from_categories: data.status_from_categories,
                    ticket_number: data.ticket_number,
                    short_description: data.short_description,
                    long_description: data.long_description,
                    ticket_category: data.ticket_category,
                    ticket_type: data.ticket_type,
                    item: data.item,
                    reference_number: data.reference_number,
                    priority: data.priority,
                    region: data.region,
                    country: data.country,
                }
            };
        } );
    };

    const closeDuplicateWorkload = () => {
        setDuplicateWorkload( {
            show: false,
            workloadData: {}
        } );
        tableRef.current && tableRef.current.onQueryChange( undefined );
    };

    const handleWorkloadDeleteBtn = async ( id ) => {
        await deleteWorkload( id );
        closeDeleteWorkload();
    };

    const urlHandler = ( query,  opc = false )  => {
        let orderby = ( query.orderBy ) ? query.orderBy.field:"createdAt";
        let direction = ( query.orderDirection ==="" ) ? "desc": query.orderDirection;
        let search = query.search !== ""  ? query.search : "";
        let options = ( opc ) ? `&opt=${opc}`: "";
        // send only checked true values
        let q = `advanced_filter=${showFilter}&`;
        const fixCheckedToUrlEncode = ( data = [], field, especial ) => {
            let pairs = [];
            data.map( ( value ) => {
                if ( value.checked === true ) {
                    let val = value.value;
                    if ( especial ) {
                        val = val === "Yes" ? 1: 0;
                    }
                    pairs.push( `${ field }=` + encodeURIComponent( val ) );
                }
            } );
            q+= pairs.join( "&" ) + "&";
        };

        fixCheckedToUrlEncode( filterState.countries, 'country' );
        fixCheckedToUrlEncode( filterState.assignedDepartments, 'department_name' );
        fixCheckedToUrlEncode( filterState.assignedGroups, 'group_name' );
        fixCheckedToUrlEncode( filterState.customerList, "customer_name" );
        fixCheckedToUrlEncode( filterState.resolverList, "resolver_name" );
        fixCheckedToUrlEncode( filterState.statusList, "status" );
        fixCheckedToUrlEncode( filterState.categoryList, "category" );
        fixCheckedToUrlEncode( filterState.priorityList, "priority" );
        fixCheckedToUrlEncode( filterState.region, "region" );
        
        return `${url}&page=${query.page}&limit=${query.pageSize}&orderby=${orderby}&direction=${direction}&search=${search}&${q}${options}`;
    };

    const showBulkDrawer = () => {
        setBulkShow( true );
    };

    const closeBulkDrawer = () => {
        setBulkShow( false );
        reloadTable();
    };

    const showWorkLoadDetail = ( data ) => {
        // console.log( data );
        setWorkloadDetail( ( prev ) =>{
            return {
                ...prev,
                show: true,
                workload_id: data.id,
                permission_level: 1
            };
        } );
    };

    const closeWorkLoadDetail = (  ) => {
        setWorkloadDetail( ( prev ) =>{
            return {
                ...prev,
                show: false
            };
        } );
        // Getting Workload to populate the table with new data when the handleclose is executed
        reloadTable();
    };

    const handleFilterClose = () => {
        setFilterState( { ...filterState, showFilter: false } );
        reloadTable();
    };

    const handleDelete = ( data ) => {
        setDeleteWorkloadModal( ( prev ) =>{
            return {
                ...prev,
                show: true,
                workload_id: data.id,
                workload_title: data.short_description
            };
        } );
    };

    const reloadData = () => tableRef.current && tableRef.current.onQueryChange( undefined );

    useEffect( ()=>{
        reloadTable();
    }, [filterState] );

    useMemo( () => {
        reloadTable();
    }, [workLoadCreated] );
    
    return ( 
        <Fragment>
            <div className="row">
                {showFilter && <Filter
                    handleClose={handleFilterClose}
                    open={filterState.showFilter}
                    reloadData={( d )=>setFilterState( d )}
                />}
                <div className={`col-md-${showFilter ? 10 : 12}`}>
                    <MuiThemeProvider theme={util.defaultTableTheme}>
                        <MaterialTable
                            tableRef={tableRef}
                            columns={tableColumns}
                            onColumnDragged={handleColumnDrag}
                            data={query =>
                                util.axios.get( urlHandler( query, true ) )
                                    .then( response => {
                                        const { error, msg, data, page, total } = response.data;
                                        if ( error ) throw msg;
                                        // console.log( employees );
                                        return ( {
                                            data: data,
                                            page: page,
                                            totalCount: total
                                        } );
                                    } )
                                    .catch( error=>{
                                        util.handleError( error );
                                    } )
                            }
                            isLoading={loading}
                            options={
                                {
                                    searchFieldAlignment: "left",
                                    selection: true,
                                    showTitle: false,
                                    pageSize: 20,
                                    emptyRowsWhenPaging: false,
                                    pageSizeOptions:[20,50,100,150,500],
                                    // columnsButton: true,
                                    sorting: true,
                                    padding: "default",
                                    tableLayout: "auto",
                                    toolbarButtonAlignment: "left",
                                    headerStyle:{ position:'sticky',top:0 },
                                   
                                    maxBodyHeight: 'calc(100vh - 290px)',
                                }
                            }
                            onSelectionChange={( rows ) => setWorkloads( [...rows] )}
                            actions={[
                                { icon:()=><div>
                                    <div className="dropdown">
                                        <div type="text" id="dropdownMenuButton" data-toggle="dropdown" className={"btn"} aria-haspopup="false" aria-expanded="true" >
                                            <span className="ml-2 isCursor text-secondary">
                                                <ViewColumnIcon title='Show/Hide Columns' className="iconGrey" />
                                            </span>
                                        </div>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" >
                                            <div className="drp-contain">
                                                        
                                                        
                                                {columns.map( ( c, index ) => {
                                                    const mycolumn = index % 3;
                                                    const myrow = Math.floor( index / 3 );
                                                    return (
                                                
                                                        <button className="dropdown-item"  key={c.field} style={{ gridColumn: `${mycolumn + 1}`, gridRow: `${myrow + 1}` }}>
                                                            <input
                                                                type="checkbox"
                                                                value={c.field}
                                                                checked={showColumns.includes( c.field )}
                                                                onChange={handleChange}
                                                            />
                                                    
                                                            <label className='ml-1'>{c.title}</label> 
                                                        </button>
                                                    );} )}</div>
                                            <div  className="column-save mr-3 float-right" 
                                                onClick={()=>{handleSave(); }}
                                            >
                                           Save
                                            </div>
                                        </div>
                                    </div>
                                </div>,
                                isFreeAction: true,
                                onClick: () => null
                                },
                                {
                                    icon: () => <div className={"btn btn-secondary sizeBtn activeBtn"}>
                                        <FontAwesomeIcon icon={faFileExport} className="mr-2" />
                                Export All
                                    </div>,
                                    tooltip: "Export All",
                                    isFreeAction: true,
                                    onClick: () => util.DownloadTableAsCsv( 'MuiTable-root', 1, "Workload" ),
                                },
                                {
                                    icon: () => <span className={"btn btn-secondary"}>Edit</span>,
                                    isFreeAction: false,
                                    onClick: () => showBulkDrawer(),
                                },
                                {
                                    icon: () =>
                                        <div className="btn btn-secondary sizeBtn activeBtn">
                                            <FontAwesomeIcon icon={faFilter} className="mr-2" />
                                    Filter
                                        </div>
                                    ,
                                    position: "toolbar",
                                    isFreeAction: true,
                                    onClick: () => {
                                        // setFilterState( { ...filterState } );
                                        setShowFilter( !showFilter );                                    }
                                },
                            ]}
                        />
                    </MuiThemeProvider>
                </div>
            </div>
            {
                workloadDetail.show &&
            <ManageWorkLoadDetail
                workload_id={workloadDetail.workload_id}
                handleClose={closeWorkLoadDetail}
                show={workloadDetail.show}
                permissionLevel={workloadDetail.permission_level}
                reload={reloadData}
            />
            }
            {deleteWorkloadModal.show && 
            <DeleteWorkloadModal
                show={deleteWorkloadModal.show}
                id={deleteWorkloadModal.workload_id}
                title={deleteWorkloadModal.workload_title}
                handleClose={closeDeleteWorkload}
                deleteWorkload={handleWorkloadDeleteBtn}
            />}
            {<CreateDuplicateWorkload
                show={duplicateWorkload.show}
                handleClose={closeDuplicateWorkload}
                data={duplicateWorkload.workloadData}
                reload={reloadData}
            />}
            <BulkEditWorkload
                workloads={workloads}
                bulkShow={bulkShow}
                showBulkDrawer={showBulkDrawer}
                closeBulkDrawer={closeBulkDrawer}
                tableName="managerTable"
            />
        </Fragment>
    );
};
 
export default WorkLoadTable;