import { useState } from 'react';
import util from '../../../utils/miniUtils';

export default function useCertification( talent_id ) {
	
    const [certifications, setCertifications] = useState( [] );
    const [certificationsFromTalent, setCertificationsFromTalent] = useState( [] );
    const [certification, setCertification] = useState( {
        certification: null,
        organization: null,
        notExpire: 1,
        issueDate: "",
        expirationDate: "",
        credential_id: "",
        credential_url: "",
    } );
    const [organizations, setOrganizations] = useState( [] );
    const [loading, setLoading] = useState( false );
    const [showModal, setShowModal] = useState( false );
    const [talentId, setTalentId] = useState( false );
    const [editMode, setEditMode] = useState( false );
	
    const startLoading = () => setLoading( true );
	
    const stopLoading = () => setLoading( false );
	
    const handleChange = ( e ) => {
        const { name, value } = e.target;
        setCertification( { ...certification, [name]: value } );
    };
	
    const getCertificationsFromTalent = async () => {
        try {
            setLoading( true );
            const response = await util.axios.get( `talent/certification_list/${talent_id}/` );
            const { error, msg, data } = response["data"];
            if ( error ) throw new Error( msg );
            setCertificationsFromTalent( Object.assign( [], data ) );
            setLoading( false );
        } catch ( e ) {
            setLoading( false );
            util.handleError( e );
        }
    };
	
    const getOrganizations = async ( search = "" ) => {
        try {
            const response = await util.axios.get( `/talent/organization_search?search=${search}` );
            const { error, msg, data } = response['data'];
            if ( error ) throw new Error( msg );
            setOrganizations( data );
        } catch ( e ) {
            util.handleError( e );
        }
    };
	
    const getCertifications = async ( search = "" ) => {
        try {
            const response = await util.axios.get( `/talent/certification_search?search=${search}` );
            const { error, msg, data } = response['data'];
            if ( error ) throw new Error( msg );
            setCertifications( data );
			
        } catch ( e ) {
            util.handleError( e );
        }
    };
	
    const deleteCertification = async ( row, reloadData ) => {
        try {
            startLoading();
            let { id } = row;
            const response = await util.axios.delete( `talent/certification_delete/${talent_id}/${id}/` );
            const { error, msg, data } = response["data"];
            if ( error ) throw new Error( msg );
            reloadData( data );
            util.toast().success( "Certification Deleted" );
            stopLoading();
        } catch ( e ) {
            stopLoading();
            util.handleError( e );
        }
    };
	
    const saveNewCertification = async ( talent_id = 0, dataCertification = null, reloadData, hideModal ) => {
        try {
            startLoading();
            if ( dataCertification === null ) throw new Error( "Data wasn't received" );
            let temp = { ...dataCertification, talent_id };
            const response = await util.axios.post( `talent/certification_add/${talent_id}/`, temp );
            const { error, msg, data } = response["data"];
            if ( error ) throw new Error( msg );
            hideModal();
            reloadData( data );
            util.toast().success( "Certification Created" );
            stopLoading();
        } catch ( e ) {
            stopLoading();
            util.handleError( e );
        }
    };
	
    const updateCertification = async ( talent_id = 0, reloadData, hideModal ) => {
        try {
            startLoading();
            const response = await util.axios.put( `talent/certification_update/${talent_id}/${certification.id}/`, certification );
            const { error, msg, data } = response["data"];
            if ( error ) throw new Error( msg );
            hideModal();
            reloadData( data );
            util.toast().success( "Certification Updated" );
            stopLoading();
        } catch ( e ) {
            stopLoading();
            util.handleError( e );
        }
    };
	
    const handleChangeNewCertification = ( e ) => {
        const { name, value } = e.target;
        setCertification( { ...certification, [name]: value } );
    };
	
    return {
        showModal, setShowModal,
        talentId, setTalentId,
        editMode, setEditMode,
        certifications,
        organizations,
        handleChange,
        getCertifications,
        getOrganizations,
        certification,
        setCertification,
        saveNewCertification,
        updateCertification,
        handleChangeNewCertification,
        deleteCertification,
        startLoading,
        stopLoading,
        loading,
        getCertificationsFromTalent,
        certificationsFromTalent,
        setCertifications,
        setOrganizations
    };
	
}