import MaterialTable from "material-table";
import React, { Fragment, useState } from "react";
import util from "../../../utils/miniUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import NewSkillDialog from "./NewSkillDialog";
import { MuiThemeProvider } from "@material-ui/core";

export default function TableOfSkills( { data, title, talent_id, skillType, reloadData } ) {
	
    const [show, setShow] = useState( false );
    const [editMode, setEditMode] = useState( false );
    const [skill, setSkill] = useState( { catalog_skill_id: null, skill_name: "", years_experience: 0, skill_type: 0 } );
    const [loading, setLoading] = useState( false );
	
    const startLoading = () => setLoading( true );
    const stopLoading = () => setLoading( false );
	
    const deleteSkill = async ( row ) => {
        try {
            startLoading();
            let { candidate_id, catalog_skill_id } = row;
            const response = await util.axios.delete( `talent/skill_delete/${talent_id}/${catalog_skill_id}/` );
            const { error, msg, data } = response["data"];
            if ( error ) throw new Error( msg );
            reloadData( data );
            util.toast().success( "Skill Deleted" );
            stopLoading();
        } catch ( e ) {
            stopLoading();
            util.handleError( e );
        }
    };
	
    const saveNewSkill = async ( talent_id = 0, skill_type = "", hideSkill ) => {
        try {
            startLoading();
            let temp = { ...skill };
            temp.skill_type = util.typeSkill( skill_type );
            const response = await util.axios.post( `talent/skill_add/${talent_id}`, temp );
            const { error, msg, data } = response["data"];
            if ( error ) throw new Error( msg );
            reloadData( data );
            util.toast().success( "Skill Created" );
            setSkill( { catalog_skill_id: null, skill_name: "", years_experience: 0, skill_type: 0 } );
            setShow( false );
            stopLoading();
        } catch ( e ) {
            stopLoading();
            util.handleError( e );
        }
    };
	
    const updateSkill = async ( talent_id = 0, skill_type = "" ) => {
        try {
            startLoading();
            const x = { skill_name: "", years_experience: 0, skill_type: 0 };
            x.skill_name = skill.skill_name;
            x.years_experience = skill.years_experience;
            x.skill_name = skill.skill_name;
            x.skill_type = skill.skill_type;
            const response = await util.axios.put( `talent/skill_update/${talent_id}/${skill.catalog_skill_id}/`, x );
            const { error, msg, data } = response["data"];
            if ( error ) throw new Error( msg );
            reloadData( data );
            util.toast().success( "Skill Updated" );
            setSkill( { catalog_skill_id: null, skill_name: "", years_experience: 0, skill_type: 0 } );
            setShow( false );
            stopLoading();
        } catch ( e ) {
            stopLoading();
            util.handleError( e );
        }
    };
    const handleChangeNewSkill = ( e ) => {
        const { name, value } = e.target;
        setSkill( { ...skill, [name]: value } );
    };
	
    function editSkill( row ) {
        setSkill( row );
        setEditMode( true );
        setShow( true );
    }
	
    return (
        <Fragment>
            <MuiThemeProvider theme={util.defaultTableThemeSlim}>
                <MaterialTable
                    isLoading={loading}
                    columns={[
                        {
                            title: 'Skill', field: 'skill_name',
                            width: "80%"
                        },
                        {
                            title: 'Years of Experience', field: 'years_experience',
                            width: "10%",
                            render: d => <div className="text-center">{d.years_experience}</div>
                        },
                        {
                            title: '', sorting: false,
                            width: "10%",
                            render: row => {
                                return (
                                    <div className="d-flex justify-content-around">
                                        <FontAwesomeIcon
                                            onClick={() => editSkill( row )}
                                            icon={faPencilAlt}
                                        />
                                        <util.PopOverConfirmation
                                            callBackOk={() => deleteSkill( row )}
                                            callBackNo={() => console.log( "is fail" )}
                                        />
                                    </div>
                                );
                            }
                        },
                    ]}
                    data={data}
                    title={title}
                    options={{
                        pageSize: 10,
                        sorting: true,
                        search: true,
                        showFirstLastPageButtons: true,
                        pageSizeOptions: [10, 30, 50, 100],
                        padding: "dense",
                        paginationType: "normal",
                        tableLayout: "fixed",
                        // addRowPosition: "first",
                        emptyRowsWhenPaging: false,
                        toolbar: true
                    }}
                    actions={[
                        {
                            icon: () => <FontAwesomeIcon icon={faPlus}/>,
                            isFreeAction: true,
                            tooltip: `Add new ${skillType} skill`,
                            onClick: ( event, rowData ) => {
                                setEditMode( false );
                                setShow( true );
                            }
                        }
                    ]}
                />
            </MuiThemeProvider>
            <NewSkillDialog
                talent_id={talent_id}
                show={show}
                hideSkill={() => setShow( false )}
                saveNewSkill={saveNewSkill}
                skillType={skillType}
                skill={skill}
                editMode={editMode}
                handleChangeNewSkill={handleChangeNewSkill}
                updateSkill={updateSkill}
                setSkill={setSkill}
            />
        </Fragment>
    );
}