import React from "react";
import './style.css';
import MyWorkload from "./my_workload";
import ManageWorkLoad from "./manage_workload";
import GroupWorkLoad from "./group_workload";
import { Route, BrowserRouter as Router, Switch, Redirect, NavLink } from "react-router-dom";
import util from "../utils/miniUtils";
import MyTickets from "./my_tickets";

const WorkLoadTabs = () => {

    return (
        <Router>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row wl-navLinkDiv">
                            <NavLink className="wl_tabsLinks" activeClassName="wl_activeTabs" to="/my_workload">My Workload</NavLink>
                            <NavLink className="wl_tabsLinks" activeClassName="wl_activeTabs" to="/group_workload">Group Workload</NavLink>
                            {( util.user.permissions.employee_manager === 1 || util.user.permissions.recruiter_manager === 1 ) && 
                            <NavLink className="wl_tabsLinks" activeClassName="wl_activeTabs" to="/manager_workload">Manage Workload</NavLink>}
                            <NavLink className="wl_tabsLinks" activeClassName="wl_activeTabs" to="/my_tickets">My Tickets</NavLink>
                        </div>
						
                        <div className="bg-white wl-tabContainer">
                            <Switch>
                                <Route exact path="/workload">
                                    <Redirect to={"/my_workload"} />
                                </Route>
                                <Route path="/my_workload">
                                    <MyWorkload />
                                </Route>
                                <Route path="/group_workload">
                                    <GroupWorkLoad />
                                </Route>
                                <Route path={( util.user.permissions.employee_manager || util.user.permissions.recruiter_manager ) ? "/manager_workload" : "/workload"}>
                                    <ManageWorkLoad />
                                </Route>
                                <Route path="/my_tickets">
                                    <MyTickets />
                                </Route>
                                <Route exact path="*">
                                    <Redirect to={"/my_workload"} />
                                </Route>
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </Router>
    );
};
export default WorkLoadTabs;
