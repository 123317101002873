import { useState } from "react";
import util from "../../../utils/miniUtils";

const useJobTalentStatus = () => {
    const [parentStatusList, setParentStatusList] = useState( [] );
    const [childStatusList, setChildStatusList] = useState( [] );
    const [selectedStatus, setSelectedStatus] = useState( "" );

    const handleParentStatusClick = ( value ) => {
        setSelectedStatus( value );
        getChildSatusList( value );
    };

    const getParentSatusList = async () => {
        try{
            const request = await util.axios.get( '/job_talent_status/list/parentStatus' );
            const { data, error, msg } = request.data;
            if( error ) throw msg;
            setParentStatusList( data );
        } catch( e ) {
            util.handleError( e );
        }
    };

    const getChildSatusList = async ( parent_id ) => {
        try{
            const request = await util.axios.get( `/job_talent_status/list/childStatus/${parent_id}` );
            const { data, error, msg } = request.data;
            if( error ) throw msg;
            setChildStatusList( data );
        } catch( e ) {
            util.handleError( e );
        }
    };

    const createParentStatus = async ( statusData ) => {
        try {
            const request = await util.axios.post( '/job_talent_status/new/parentStatus', statusData );
            const { error, msg } = request.data;
            if( error ) throw msg;
            util.toast().success( msg );
            getParentSatusList();
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const createChildStatus = async ( statusData, parent_id ) => {
        try{
            const request = await util.axios.post( `/job_talent_status/new/childStatus/${parent_id}`, statusData );
            const { error, msg } = request.data;
            if( error ) throw msg;
            util.toast().success( msg );
            getChildSatusList( parent_id );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const editParentStatus = async ( statusData ) => {
        try{
            const request = await util.axios.put( `/job_talent_status/updateStatus/${statusData.jts_id}`, statusData );
            const { error, msg } = request.data;
            if( error ) throw msg;
            util.toast().success( msg );
            getParentSatusList();
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const editChildStatus = async ( statusData ) => {
        try{
            const request = await util.axios.put( `/job_talent_status/updateStatus/${statusData.jts_id}`, statusData );
            const { error, msg } = request.data;
            if( error ) throw msg;
            util.toast().success( msg );
            getChildSatusList( selectedStatus );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const deleteParentStatus = async ( statusId ) => {
        try{
            const request = await util.axios.delete( `/job_talent_status/deleteStatus/${statusId}` );
            const { error, msg } = request.data;
            if( error ) throw msg;
            util.toast().success( msg );
            getParentSatusList();
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const deleteChildStatus = async ( statusId ) => {
        try {
            const request = await util.axios.delete( `/job_talent_status/deleteStatus/${statusId}` );
            const { error, msg } = request.data;
            if( error ) throw msg;
            util.toast().success( msg );
            getChildSatusList( selectedStatus );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const updateParentOrder = async ( statusList ) => {
        try {
            const request = await util.axios.put( `/job_talent_status/updateOrder`, statusList );
            const { error, msg } = request.data;
            if( error ) throw msg;
            util.toast().success( msg );
            // getParentSatusList();
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const updateChildOrder = async ( statusList ) => {
        try {
            const request = await util.axios.put( `/job_talent_status/updateOrder`, statusList );
            const { error, msg } = request.data;
            if( error ) throw msg;
            util.toast().success( msg );
            // getChildSatusList( selectedStatus ); 
        } catch ( error ) {
            util.handleError( error );
        }
    };

    return {
        parentStatusList,
        childStatusList,
        getParentSatusList,
        getChildSatusList,
        createParentStatus,
        createChildStatus,
        editParentStatus,
        editChildStatus,
        selectedStatus, setSelectedStatus,
        handleParentStatusClick,
        deleteParentStatus,
        deleteChildStatus,
        updateParentOrder,
        updateChildOrder
    };
};

export default useJobTalentStatus;