import React, { Fragment, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import util from "../../utils/miniUtils";
import {
    faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";

function Settings() {
    const [settings, setSettings] = useState( { session_expire_time:"" } );


    useEffect( () => {
        getSettings().then( null );
    },[] );

    async function getSettings() {
        try {
            const request = await util.axios.get( `${util.url}/app_resources/settings` );
            const { error, msg, appSettings } = request.data;
            setSettings( appSettings );
            if ( error ) throw msg;
        }catch ( e ) {
            util.handleError( e );
        }
    }

    async function saveChanges() {
        try {
            const request = await util.axios.put( `${util.url}/app_resources/settings`, settings );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            util.toast().success( msg );
        }catch ( e ) {
            util.handleError( e );
        }
    }

    function onChangeHandler( e ) {
        const { name, value } = e.target;
        let temp = settings;
        if ( name ==="session_expire_time" ) temp.session_expire_time = value;
        setSettings( Object.assign( {}, temp ) );
    }

    return (
        <Fragment>
            <div className="card container-fluid mt-2">
                <div className="row mt-2 p-2">
                    <div className="col-md-12">
                        <h3>Global Settings</h3>
                        <hr/>
                    </div>
                    <div className="col-md-6">
                        <b>Time for active session</b>
                        <div>
                            <input
                                title="Time is in seconds"
                                placeholder="Session Time"
                                onChange={onChangeHandler}
                                value={settings.session_expire_time}
                                name="session_expire_time"
                            />
                            <button
                                onClick={saveChanges}
                                className="btn btn-sm btn-primary">
                                <FontAwesomeIcon icon={faSyncAlt} className="mr-2" />
                                    Update
                            </button>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <b>Hireability Parsing Credits</b>
                        <div className="statsField">
                            <table className="table">
                                <thead/>
                                <tbody>
                                    <tr>
                                        <td>Number of parses bought</td>
                                        <td className="text-right">{settings.parse_information ? settings.parse_information.usageLimit:""}</td>
                                    </tr>
                                    <tr>
                                        <td>Number of parses completed</td>
                                        <td className="text-right">{settings.parse_information ? settings.parse_information.currentUsage:""}</td>
                                    </tr>
                                    <tr>
                                        <td>Number of parses remaining</td>
                                        <td className="text-right">{settings.parse_information ? settings.parse_information.remainingUsage:""}</td>
                                    </tr>
                                    <tr>
                                        <td>Account Expiration Date</td>
                                        <td className="text-right">{settings.parse_information ? settings.parse_information.accountValidThrough:""}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} className=""><b>Product Code</b></td>
                                    </tr>
                                    <tr>
                                        <td><input className="form-control" type="text" name="product_code" value={settings.product_code ? settings.product_code:""}/></td>
                                        <td><button className="btn btn-sm btn-primary"><FontAwesomeIcon icon={faSyncAlt} className="mr-2" /> Update</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Settings;
