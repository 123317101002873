import React, { Fragment, useEffect, useState } from "react";
import util from "../../../utils/miniUtils";
import { Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Paper } from '@material-ui/core';

export default function JobsApplied( { talent_id } ) {

    const [jobs, setJobs] = useState( [] );
    const [loading, setLoading] = useState( false );

    useEffect( () => {
        getJobsApplied().then( null );
    }, [] );

    const getJobsApplied = async () => {
        try {
            // if (!isValid) return
            setLoading( true );
            const request = await util.axios.get( `${util.url}/talent/applied_jobs/${talent_id}` );
            const { error, msg, jobsApplied } = request.data;
            if ( error ) throw msg;
            setJobs( jobsApplied );
            setLoading( false );
        } catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }
    };

    return (
        <Paper elevation={1} className="mt-2">
            <table className="table text9em">
                <thead>
                    <tr>
                        <th>JobID</th>
                        <th>Job Status</th>
                        <th>Talent Status</th>
                        <th>Job Title</th>
                        <th>Location</th>
                        <th>Talent Expectation</th>
                        <th>Buy Rate</th>
                        <th>Talent Sell Rate</th>
                        <th>Job Sell Rate</th>
                    </tr>
                </thead>
                <tbody>
                    {jobs.map( ( j, i ) => {

                        let location = j.city + " - " + j.state + " - " + j.country;

                        return (
                            <Fragment key={i}>
                                <tr>
                                    <td>
                                        <Link target="new" to={`/jobs/position/detail/${j["jobId"]}`}>
                                            {util.zeroPad( j["jobId"], 5 )}
                                        </Link>
                                    </td>
                                    <td>{util.jobStatusOnlyIcons( parseInt( j["jobStatus"] ) )}</td>
                                    <td>{util.talentStatus( parseInt( j.talentStatus ) )}</td>
                                    <td>
                                        <Link target="new" to={`/jobs/position/detail/${j["jobId"]}`}>
                                            {j["job_title"]}
                                        </Link>
                                    </td>
                                    <td><Tooltip title={location}><span>{location.substring( 0, 25 )}</span></Tooltip></td>
                                    <td>{util.currencyFormat( j["tExpectation"] )}</td>
                                    <td>{util.currencyFormat( j["buyRate"] )}</td>
                                    <td>{util.currencyFormat( j["tSellRate"] )}</td>
                                    <td>{util.currencyFormat( parseFloat( j["jobSellRate"] ) )}</td>
                                </tr>
                            </Fragment>
                        );
                    } )}
                </tbody>
            </table>
            {util.LOADING_SCREEN( loading )}
        </Paper>
    );
}