import util from "../../../../../utils/miniUtils";
import { useState } from "react";

const PARTNER_ROLE = 4;

const user = util.user;

export default function useMyTalents() {
	
    // Get the list of talents inside this position
    const [candidates, setCandidates] = useState( [] );
    // const [ setTalent] = useState(null)
    const [loading, setLoading] = useState( true );
    // const [ setIsOpen] = useState(false)
    const field = user.role === PARTNER_ROLE ? "partner_flag":"client_flag"; // Helps to manage field s of both roles
    const feedback = user.role === PARTNER_ROLE ? "partner_feedback":"client_feedback"; // Helps to manage field s of both roles
	
    async function handleClickLike( opc, candidate_id, position_id ) {
        try {
            // Prepare temp var
            let temp = candidates;
            // Send the change to the server
            let data = { candidate_id,position_id, flag:opc };
            const request = await util.axios.put( `${util.url}/jobs/flag_candidate`, data );
            const { error, msg } = request.data;
            if ( error ) throw  msg;
            // Update locally the option
            temp.forEach( ( c )=>{
                if ( c.candidate_id === candidate_id ){
                    if ( user.role === PARTNER_ROLE ){
                        c.partner_flag = opc;
                    }else{
                        c.client_flag = opc;
                    }
                }
            } );
            // reload candidate array with new opc
            setCandidates( Object.assign( [], temp ) );
        }catch ( e ) {
            util.handleError( e );
        }
    }
	
    async function getTalentList() {
        try {
            const response = await util.axios.get( `/requirements/show_my_talents?hideDrop=true&hasEvaluation=true&isPartner=${user.role === PARTNER_ROLE}` );
            const { error, msg, talents } = response["data"];
            if ( error ) throw  msg;
            setCandidates( talents );
            setLoading( false );
        }catch ( e ) {
            util.handleError( e );
        }
    }
	
    function updateComment( id, comment ) {
        for ( let x = 0; x < candidates.length; x++ ){
            if ( candidates[x].candidate_id === id ){
                candidates[x][feedback] = comment;
                break;
            }
        }
        setCandidates( Object.assign( [], candidates ) );
    }
	
    return {
        candidates, setCandidates,
        loading, setLoading, getTalentList,
        field, feedback, handleClickLike, updateComment
    };
}