import React, { Fragment } from 'react';
import ListOfRequirements from "./components/jobLists/recruitment/ListOfRequirements";
import JobsListTalent from "./components/jobLists/talent/JobsListTalent";
import JobsListPartnerClient from "./components/jobLists/partner_client/JobsListPartnerClient";
import util from '../utils/miniUtils';

const Requirements = () => {

    let list;
    switch ( util.user.role ) {
        case 1: list = <ListOfRequirements />;
            break;
        case 2: list = <JobsListTalent />; break;
        case 7: list = <JobsListTalent />; break;
        case 3: list = <JobsListPartnerClient />;
            break;
        case 4: list = <JobsListPartnerClient />;
            break;
        default:
            break;
    
    }
    return (
        <Fragment>
            <h3 className="text-black-50 mt-2">Jobs</h3>
            <div className="row p-2">
                <div className="col-md-12">
                    {list}
                </div>
            </div>
        </Fragment>
    );
};

export default Requirements;
