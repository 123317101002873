import React, { useEffect, useState } from "react";
import { Paper, Tooltip } from "@material-ui/core";
import { Editor } from "@tinymce/tinymce-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip, faSync, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import useManageWorkload from "../manage_workload/useManageWorkLoad";
import util from "../../utils/miniUtils";
import ParentTicketSearch from "../shared/ParentTicketSearch";
import CustomerSelector from "../shared/CustomerSelector";
import WorkHistoryDiv from "../shared/WorkHistoryDiv";
import ExtraFields from "../shared/ExtraFields";

const EXCEL_FILES = '.xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const WORD_FILES = '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';
const PPT_FILES = '.ppt,.pptx,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation';

function MyTicketDetail( { workload_id = null, show, handleClose, reload } ) {
    const {
        departmentList,
        groupList,
        getGroupList,
        membersOfGroup,
        loading,
        getAssignedMembersToGroup,
        getCategories,
        categoriesByGroup,
        workloadSimple,
        getWorkLoadSimpleList,
        setWorkload,
        getWorkLoadDetail,
        workload,
        handleChange,
        updateWorkLoad,
        statusListByCategory,
        getStatusListToCategory,
        getCustomerList,
        customerList,
        existingAttachments,
        addAttachment,
        deleteAttachment,
        getAttachments,
        getCategoryPrefix,
        categoryPrefix,
        getDepartmentsByUser,
        isChildTicket, 
        setIsChildTicket,
        attachmentFiles,
        removeAttachmentfile,
        handleWorkHistory } = useManageWorkload();
    // const [isRecurrence, setIsRecurrence] = useState( false );
    const [showSLA, setShowSLA] = useState( false );
    const [newWorkHistory, setNewWorkHistory] = useState( '' );
    
    const toggleChildTicket = () => {
        if( !isChildTicket ){
            setWorkload( ( prev ) => {
                return {
                    ...prev,
                    parent_ticket_id: null
                };
            } );
        } 
        setIsChildTicket( !isChildTicket );
    };

    function handleDescription( e ) {
        const value = e.toString( "html" );
        setWorkload( ( prev )=>{
            return {
                ...prev,
                long_description: value,
            };
        } );
    }

    function handleStatusChange( e ) {
        const value = e.target.value;
        setWorkload( ( prev ) => {
            return {
                ...prev,
                status: Number( value.split( " " )[0] ),
                status_from_categories: Number( value.split( " " )[1] )
            };
        } );
    }

    const handleUpdateWorkloadBtn = async ( data ) => {
        const temp = { ...data };
        
        handleWorkHistory( temp, newWorkHistory, setNewWorkHistory );
        // Remove all the read only fields from update
        delete temp.createdAt;
        delete temp.updatedAt;
        delete temp.RO_ticket_hop_count;
        delete temp.RO_assigned_to_group_time;
        delete temp.RO_assigned_to_member_time;
        delete temp.RO_actual_resolution_sla;
        delete temp.RO_actual_response_sla;
        delete temp.RO_wl_log;
        delete temp.RO_sla_caldays;
        delete temp.RO_sla_bizdays;
        delete temp.RO_sla_minutes;
        delete temp.RO_Open_time;
        delete temp.RO_Closed_Time;
        delete temp.RO_assigned_to_solver_time;
        delete temp.RO_group_sla_caldays;
        delete temp.RO_group_sla_minutes;
        delete temp.RO_group_sla_bizdays;
        delete temp.RO_resolver_sla_caldays;
        delete temp.RO_resolver_sla_minutes;
        delete temp.RO_resolver_sla_bizdays;
        delete temp.RO_AcknowledgedTime;

        await updateWorkLoad( temp );
        handleClose();
        reload();
    };

    const RowLeft = ( { children, title } ) => {
        return (
            <div className="row">
                <div className="col mb-1 p-2 d-flex align-items-center">
                    <span className="col-2 mr-2 p-0 font-weight-bold">{title}:</span>
                    <span className="col-10 p-0">{children}</span>
                </div>
            </div>
        );
    };

    useEffect( ()=>{
        // Every time group id changes, need to retrieve new information for status and categories
        if ( workload.group_id !== null ){
            getAssignedMembersToGroup( workload.group_id );
            getCategories( workload.group_id );
            getStatusListToCategory( workload.wl_category_id );
        }
    },[workload.group_id] );

    useEffect( () => {
        // Every Time wl_category_id changes, need to retrieve new information for status
        if( workload.wl_category_id !== null ){
            getStatusListToCategory( workload.wl_category_id );
            getCategoryPrefix( workload.wl_category_id );
        }
    },[workload.wl_category_id] );

    useEffect( () => {
        if( workload.customer_id ){
            getDepartmentsByUser( workload.customer_id );
        }
    },[workload.customer_id] );

    useEffect( () => {
        if( workload.parent_ticket_id ) {
            setIsChildTicket( true );
        }
    },[workload.parent_ticket_id] );

    useEffect( () => {
        if ( workload_id ){
            getWorkLoadDetail( workload_id );
            getAttachments( workload_id );
        }
        getCustomerList();
        getGroupList();
        getWorkLoadSimpleList();
    }, [] );

    return (
        <>
            <Modal show={show} onHide={handleClose} size="xl" backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    {/* <Modal.Title>Modal heading</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    {util.LOADING_SCREEN( loading )}
                    <div className="col">
                        <div className="row m-1 d-flex justify-content-between align-items-center">
                            <div>
                                <div className="d-flex align-items-center">
                                    <span className="rounded-circle border m-1">
                                        <img src="/images/avatar.svg" width="50" />
                                    </span>
                                    <span>
                                        <span className="p-0 font-weight-bold">Customer Name</span>

                                        <CustomerSelector
                                            list={customerList}
                                            value={workload.customer_id}
                                            customerName={workload.customer_name}
                                            setData={setWorkload}
                                            isChildTicket={isChildTicket}
                                            toggleChildTicket={toggleChildTicket}
                                        />
                                    </span>
                                </div>
                            </div>
                            {/* <h4 className="text-danger">ETA</h4> */}
                        </div>
                        <div className="row d-flex justify-content-between">
                            <div className="col-md-7" id="left-side-form">
                                <Paper elevation={3} className="mt-2 py-4 px-5 rounded">
                                    <RowLeft title="Customer Department">
                                        <select disabled className="form-control" value={workload.department_id} name="department_id"  onChange={handleChange}>
                                            <option value={null}> -- </option>
                                            {departmentList.map( ( item ) => (
                                                <option key={item.id} value={item.id}>
                                                    {item.name}
                                                </option>
                                            ) )}
                                        </select>
                                    </RowLeft>

                                    <RowLeft title="Assignment Group">
                                        <select disabled  className="form-control" value={workload.group_id} name="group_id"  onChange={handleChange}>
                                            <option value={null}> -- </option>
                                            {groupList.map( ( item ) => (
                                                <option key={item.id} value={item.id}>
                                                    {item.itemName}
                                                </option>
                                            ) )}
                                        </select>
                                    </RowLeft>

                                    <RowLeft title="Workload Category">
                                        <select disabled className="form-control" value={workload.wl_category_id} name="wl_category_id"  onChange={handleChange}>
                                            <option value={null}> -- </option>
                                            {categoriesByGroup.map( ( item ) => (
                                                <option key={item.id} value={item.id}>
                                                    {item.name}
                                                </option>
                                            ) )}
                                        </select>
                                    </RowLeft>

                                    <RowLeft title="Workload Resolver">
                                        <select disabled className="form-control" value={workload.resolver_id} name="resolver_id"  onChange={handleChange}>
                                            <option value={null}> -- </option>
                                            {membersOfGroup.map( ( item ) => (
                                                <option key={item.employee_id} value={item.employee_id}>
                                                    {item.name}
                                                </option>
                                            ) )}
                                        </select>
                                    </RowLeft>

                                    <div className="row">
                                        <div className="col mb-1 p-2 d-flex align-items-center">
                                            <span className="col-2 mr-2 p-0 font-weight-bold">Workload Title:</span>
                                            <span className="col-10 p-0">
                                                <input className="form-control" type="text" value={workload.short_description} name={"short_description"} onChange={handleChange} />
                                            </span>
                                        </div>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="col mb-1 p-2 d-flex align-items-start">
                                            <span className="col-2 mr-2 p-0 font-weight-bold">Workload Description:</span>
                                            <span className="col-10 p-0">
                                                <Editor
                                                    className="editor"
                                                    apiKey="co5tvvfh4ltli0l9urtqbvfbrevuswtkqdmu3lvf0t3lme9p"
                                                    value={workload.long_description || ""}
                                                    init={{
                                                        height: 150,
                                                        menubar: false,
                                                        branding: false,
                                                        statusbar: false,
                                                        toolbar:
												"formatselect | bold italic| alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat",
                                                    }}
                                                    onEditorChange={handleDescription}
                                                />
                                                <div>
                                                    <Tooltip title="Click to select attachments">
                                                        <span >
                                                            <label htmlFor="wl_attachment"  className="btn btn-sm btn-primary mt-2">
                                                                <FontAwesomeIcon icon={faPaperclip} />
                                                                <span className="p-1">Attachments</span>
                                                            </label>
                                                        </span>
                                                    </Tooltip>
                                                    <input
                                                        type="file"
                                                        multiple name="files"
                                                        id="wl_attachment"
                                                        onChange={( e ) => addAttachment( e )}
                                                        accept={`image/*,.pdf,.txt,text/csv,${WORD_FILES},${EXCEL_FILES},${PPT_FILES}`}
                                                        className="invisible" />
                                                    <div className="mt-1 d-flex flex-wrap">
                                                        {
                                                existingAttachments?.map( ( a,i )=> {
                                                    const { title, id, workload_id } = a;
                                                    return (
                                                        <span key={i} className="mr-1 mb-1 d-flex align-items-center attachmentBtn p-1">
                                                            <a className="text-secondary" target="new" href={`${util.baseUrl()}/workload_attachments/${workload_id}/${title}`}>{title.substring( 0, 20 )}</a>
                                                            <span className="p-1 isPointer text-danger" onClick={()=>deleteAttachment( id )}><FontAwesomeIcon icon={faTimes} /></span>
                                                        </span>
                                                    );
                                                } )
                                                        }
                                                        {
                                                            attachmentFiles.map( ( a, i ) => {
                                                                return <span key={i} className="mr-1 mb-1 d-flex align-items-center attachmentBtn p-1">
                                                                    <span>{a.name}</span>
                                                                    <span className="p-1 isPointer text-danger" onClick={() => removeAttachmentfile( a.name )}><FontAwesomeIcon icon={faTimes} /></span>
                                                                </span>;
                                                            } )
                                                        }
                                                    </div>
                                                </div>
                                            </span>
                                        </div>
                                    </div>

                                    <WorkHistoryDiv
                                        workHistory={workload.work_history}
                                        statusList={statusListByCategory}
                                        employeeList={customerList}
                                        entry={newWorkHistory}
                                        setEntry={setNewWorkHistory}
                                    />
                                    
                                </Paper>
                            </div>

                            <div className="col-md-5" id="right-side-form">
                                <Paper elevation={3} className="mt-2 py-4 px-3 rounded">

                                    <div className="row m-1">
                                        <div className="col mb-1 d-flex align-items-center p-0">
                                            <span className="col-3 font-weight-bold">Ticket Number:</span>
                                            <span className="col-9">
                                                <input disabled className="form-control" value={workload.ticket_number ? workload.ticket_number : `${categoryPrefix ? categoryPrefix: ""}${workload.id || "" }`} />
                                            </span>
                                        </div>
                                    </div>

                                    <div className="row m-1">
                                        <div className="col mb-1 d-flex align-items-center p-0">
                                            <span className="col-3 font-weight-bold">Priority:</span>
                                            <span className="col-9">
                                                <select className="form-control" value={workload.priority} name="priority"  onChange={handleChange}>
                                                    <option value={null}> -- </option>
                                                    {['Low','Medium','High','Critical'].map( ( item ) => (
                                                        <option key={item} value={item}>
                                                            {item}
                                                        </option>
                                                    ) )}
                                                </select>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="row m-1">
                                        <div className="col mb-1 d-flex align-items-center p-0">
                                            <span className="col-3 font-weight-bold">Status:</span>
                                            <span className="col-9">
                                                <select className="form-control" value={workload.status + " " + workload.status_from_categories} name="status_from_categories"  onChange={handleStatusChange}>
                                                    <option value={null} disabled> -- </option>
                                                    {statusListByCategory && statusListByCategory.map( ( item ) => (
                                                        <option disabled={item.disabled} className={item.disabled ? "bg-lightGrey" : ""} key={item.wl_status_category} value={item.wl_status_id + " " + item.wl_status_category}>
                                                            {item.name}
                                                        </option>
                                                    ) )}
                                                </select>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="row m-1">
                                        <div className="col mb-1 d-flex align-items-start p-0">
                                            <span className="col-3 font-weight-bold">SLA:</span>
                                            <span className="col-9">
                                                <div className="row slaDiv">
                                                    {showSLA ? <>
                                                        <table>
                                                            <tr>
                                                                <th colSpan={2}>Response Time SLA:</th>
                                                                <td>{util.time_SLA_render( workload.RO_actual_response_sla )}</td>
                                                            </tr>

                                                            <tr className="bg-light">
                                                                <th colSpan="3">Overall Resolution SLA (Client)</th>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ width: "5%" }}></td>
                                                                <th>Business Days Resolution SLA:</th>
                                                                <td>{util.time_SLA_days_render( workload.RO_sla_bizdays )}</td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <th>Calender Days Resolution SLA:</th>
                                                                <td>{util.time_SLA_days_render( workload.RO_sla_caldays )}</td>
                                                            </tr>

                                                            <tr className="bg-light">
                                                                <th colSpan="4">Group Resolution SLA</th>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <th>Business Days Resolution SLA:</th>
                                                                <td>
                                                                    {util.time_SLA_days_render( workload.RO_group_sla_bizdays )}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <th>Calender Days Resolution SLA:</th>
                                                                <td>
                                                                    {util.time_SLA_days_render( workload.RO_group_sla_caldays )}
                                                                </td>
                                                            </tr>

                                                            <tr className="bg-light">
                                                                <th colSpan="3">Resolver Resolution SLA</th>
                                                            </tr>
                                                            <tr>
                                                                <td> </td>
                                                                <th>Business Days Resolution SLA:</th>
                                                                <td>
                                                                    {util.time_SLA_days_render( workload.RO_resolver_sla_bizdays )}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td> </td>
                                                                <th>Calender Days Resolution SLA:</th>
                                                                <td>
                                                                    {util.time_SLA_days_render( workload.RO_resolver_sla_caldays )}
                                                                </td>
                                                            </tr>
                                                            <span className="text-primary" onClick={() => setShowSLA( !showSLA )}>Hide</span>
                                                        </table>
                                                    </> :  <span className="text-primary" onClick={() => setShowSLA( !showSLA )}>Show</span>}
                                                </div>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="row m-1">
                                        <div className="col mb-1 d-flex align-items-center p-0">
                                            <span className="col-3 font-weight-bold">Country:</span>
                                            <span className="col-9">
                                                <select className="form-control" value={workload.country} name="country"  onChange={handleChange}>
                                                    <option value={null}> -- </option>
                                                    {util.fixCountryList().map( ( item ) => (
                                                        <option key={item.id} value={item.value}>
                                                            {item.value}
                                                        </option>
                                                    ) )}
                                                </select>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="row m-1">
                                        <div className="col mb-1 d-flex align-items-center p-0">
                                            <span className="col-3 font-weight-bold">Region:</span>
                                            <span className="col-9">
                                                <select className="form-control" value={workload.region} name="region"  onChange={handleChange}>
                                                    <option value={null}> -- </option>
                                                    {util.regionList.map( ( item ) => (
                                                        <option key={item} value={item}>
                                                            {item}
                                                        </option>
                                                    ) )}
                                                </select>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="row m-1">
                                        <div className="col mb-1 d-flex align-items-center p-0">
                                            <span className="col-4 font-weight-bold">Child Ticket:</span>
                                            <span className="col-8 d-flex align-items-center wl_childTicketDiv">
                                                <input aria-describedby="text-input-popover" type="checkbox" className="form-check-input" checked={isChildTicket} onChange={toggleChildTicket} />
                                                <ParentTicketSearch
                                                    workloadSimple={workloadSimple}
                                                    setData={setWorkload}
                                                    isChildTicket={isChildTicket}
                                                    toggleChildTicket={toggleChildTicket}
                                                    value={workload.parent_ticket_id}/>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="row m-1">
                                        <div className="col mb-1 d-flex align-items-center p-0">
                                            <span className="col-3 font-weight-bold">Ticket Category:</span>
                                            <span className="col-9">
                                                <input className="form-control" type="text" name={"ticket_category"} onChange={handleChange} value={workload.ticket_category}/>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="row m-1">
                                        <div className="col mb-1 d-flex align-items-center p-0">
                                            <span className="col-3 font-weight-bold">Ticket Type:</span>
                                            <span className="col-9">
                                                <input className="form-control" type="text" name={"ticket_type"} onChange={handleChange} value={workload.ticket_type}/>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="row m-1">
                                        <div className="col mb-1 d-flex align-items-center p-0">
                                            <span className="col-3 font-weight-bold">Ticket Item:</span>
                                            <span className="col-9">
                                                <input className="form-control" type="text" name={"item"} onChange={handleChange} value={workload.ticket_item}/>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="row m-1">
                                        <div className="col mb-1 d-flex align-items-center p-0">
                                            <span className="col-3 font-weight-bold">Reference Number:</span>
                                            <span className="col-9">
                                                <input className="form-control" type="text" name={"reference_number"} onChange={handleChange} value={workload.reference_number}/>
                                            </span>
                                        </div>
                                    </div>

                                    {workload.wl_category_id && <ExtraFields
                                        category={workload. wl_category_id}
                                        handleChange={handleChange}
                                        workload={workload}
                                    />}

                                    {workload.RO_wl_log && <div className="row m-1">
                                        <div className="col mb-1 p-0 d-flex align-items-start">
                                            <span className="col-3 font-weight-bold">Workload Log:</span>
                                            <span className="col-9">
                                                <ul className="wl_logDiv">
                                                    {workload.RO_wl_log && workload.RO_wl_log.split( '|' ).map( ( item, index ) => item && <li key={index}>{item}</li> )}
                                                </ul>
                                            </span>
                                        </div>
                                    </div>}

                                </Paper>
                            </div>
                        </div>
                
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => handleUpdateWorkloadBtn( workload )} className="btn btn-sm btn-primary ml-2">
                        <FontAwesomeIcon icon={faSync} />
                        <span className="p-1">Update</span>
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default MyTicketDetail;