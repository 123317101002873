import Modal from "react-bootstrap/Modal";
import { FormControl, InputGroup } from "react-bootstrap";
import util from "../../../../utils/miniUtils";
import React, { useEffect, useState } from "react";


export default function ModalJobSetting( { ctcSetting, show, handleClose, job, reloadTalentsTable } ) {

    const [ ctc, setCTC ] = useState( null );
    
    useEffect( () => {
        setCTC( ctcSetting );
    }, [ ctcSetting ] );

    if ( ctc === null ) return "";

    // console.log( ctcSetting, job );

    const fullTime      = ctc.data[0];
    const contractor    = ctc.data[1];
    const c2c           = ctc.data[2];

    // Handle only new country ctc setting, nothing more
    function onChangeHandlerNewCountry( e, index ) {
        const { name, value } = e.target;
        let t = { ...ctc } ;
        if ( name === "country" ){
            t.country = value;
        }else{
            t.data[index][name] = value;
        }
        setCTC( Object.assign( {}, t ) );
    }

    // Update values of country ctc setting
    async function updateChanges() {
        try {
            const request = await util.axios.put( `/jobs/ctc_settings/update/${job.id}`, ctc );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            // Pass or reload data to talents
            util.toast().success( msg );
            reloadTalentsTable();
            handleClose();
        }catch ( e ) {
            util.handleError( e );
        }
    }



    return(
        <Modal
            size={"xl"}
            show={show}
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>CTC Settings by Country</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <table className="table">
                        <thead>
                            <tr className="text-center">
                                <th className="border-0">Talent Engagement Type</th>
                                <th className="border-0">Markup (Payroll, Tax, SGA etc Loading)</th>
                                <th className="border-0">Billable Days in a Year</th>
                                <th className="border-0">Working Hours in Day</th>
                                <th className="border-0">Avg. Billable Days in a Month</th>
                                <th className="border-0">Default eJ-Offer-Unit  to Talent</th>
                                <th className="border-0">Default Sell-Unit</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="text-left">
                                <td>{fullTime.employmentType}</td>
                                <td>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                                        <FormControl
                                            onChange={( e )=>onChangeHandlerNewCountry( e, 0 )}
                                            type="number"
                                            name="markUp"
                                            value={fullTime.markUp}
                                        />
                                    </InputGroup>
                                </td>
                                <td><input className="form-control" onChange={( e )=>onChangeHandlerNewCountry( e, 0 )} type="number" name="billableDaysInYear" value={fullTime.billableDaysInYear}/></td>
                                <td><input className="form-control" onChange={( e )=>onChangeHandlerNewCountry( e, 0 )} type="number" name="workingHoursInDay" value={fullTime.workingHoursInDay}/></td>
                                <td><input className="form-control" onChange={( e )=>onChangeHandlerNewCountry( e, 0 )} type="number" name="averageBillableDaysInMonth" value={fullTime.averageBillableDaysInMonth}/></td>
                                <td>
                                    <select  onChange={( e )=>onChangeHandlerNewCountry( e, 0 )} className="form-control" name="defaulteJOfferUnitToTalent" value={fullTime.defaulteJOfferUnitToTalent} >
                                        {
                                            util.ejUnit.map( ( u , i ) => {
                                                return <option key={i} value={u}>{u}</option>;
                                            } )
                                        }
                                    </select>
                                </td>
                                <td>
                                    <select  onChange={( e )=>onChangeHandlerNewCountry( e, 0 )} className="form-control" name="defaultSellUnit" value={fullTime.defaultSellUnit} >
                                        {
                                            util.ejUnit.map( ( u , i ) => {
                                                return <option key={i} value={u}>{u}</option>;
                                            } )
                                        }
                                    </select>
                                </td>
                            </tr>
                            <tr className="text-left">
                                <td>{contractor.employmentType}</td>
                                <td>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                                        <FormControl
                                            onChange={( e )=>onChangeHandlerNewCountry( e, 1 )}
                                            type="number"
                                            name="markUp"
                                            value={contractor.markUp}
                                        />
                                    </InputGroup>
                                    {/*<input className="form-control" onChange={( e )=>onChangeHandlerNewCountry( e, 1 )} type="number" name="markUp" value={contractor.markUp}/>*/}
                                </td>
                                <td><input className="form-control" onChange={( e )=>onChangeHandlerNewCountry( e, 1 )} type="number" name="billableDaysInYear" value={contractor.billableDaysInYear}/></td>
                                <td><input className="form-control" onChange={( e )=>onChangeHandlerNewCountry( e, 1 )} type="number" name="workingHoursInDay" value={contractor.workingHoursInDay}/></td>
                                <td><input className="form-control" onChange={( e )=>onChangeHandlerNewCountry( e, 1 )} type="number" name="averageBillableDaysInMonth" value={contractor.averageBillableDaysInMonth}/></td>
                                <td>
                                    <select  onChange={( e )=>onChangeHandlerNewCountry( e, 0 )} className="form-control" name="defaulteJOfferUnitToTalent" value={contractor.defaulteJOfferUnitToTalent} >
                                        {
                                            util.ejUnit.map( ( u , i ) => {
                                                return <option key={i} value={u}>{u}</option>;
                                            } )
                                        }
                                    </select>
                                </td>
                                <td>
                                    <select  onChange={( e )=>onChangeHandlerNewCountry( e, 0 )} className="form-control" name="defaultSellUnit" value={contractor.defaultSellUnit} >
                                        {
                                            util.ejUnit.map( ( u , i ) => {
                                                return <option key={i} value={u}>{u}</option>;
                                            } )
                                        }
                                    </select>
                                </td>
                            </tr>
                            <tr className="text-left">
                                <td>{c2c.employmentType}</td>
                                <td>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                                        <FormControl
                                            onChange={( e )=>onChangeHandlerNewCountry( e, 2 )}
                                            type="number"
                                            name="markUp"
                                            value={c2c.markUp}
                                        />
                                    </InputGroup>
                                    {/*<input className="form-control" onChange={( e )=>onChangeHandlerNewCountry( e, 2 )} type="number" name="markUp" value={c2c.markUp}/>*/}
                                </td>
                                <td><input className="form-control" onChange={( e )=>onChangeHandlerNewCountry( e, 2 )} type="number" name="billableDaysInYear" value={c2c.billableDaysInYear}/></td>
                                <td><input className="form-control" onChange={( e )=>onChangeHandlerNewCountry( e, 2 )} type="number" name="workingHoursInDay" value={c2c.workingHoursInDay}/></td>
                                <td><input className="form-control" onChange={( e )=>onChangeHandlerNewCountry( e, 2 )} type="number" name="averageBillableDaysInMonth" value={c2c.averageBillableDaysInMonth}/></td>
                                <td>
                                    <select  onChange={( e )=>onChangeHandlerNewCountry( e, 0 )} className="form-control" name="defaulteJOfferUnitToTalent" value={c2c.defaulteJOfferUnitToTalent} >
                                        {
                                            util.ejUnit.map( ( u , i ) => {
                                                return <option key={i} value={u}>{u}</option>;
                                            } )
                                        }
                                    </select>
                                </td>
                                <td>
                                    <select  onChange={( e )=>onChangeHandlerNewCountry( e, 0 )} className="form-control" name="defaultSellUnit" value={c2c.defaultSellUnit} >
                                        {
                                            util.ejUnit.map( ( u , i ) => {
                                                return <option key={i} value={u}>{u}</option>;
                                            } )
                                        }
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-sm" onClick={handleClose}>
                    Close
                </button>
                <button className="btn btn-sm btn-primary" onClick={updateChanges}>
                    Save Changes
                </button>
            </Modal.Footer>
        </Modal>
    );
}