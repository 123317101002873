import { faCheck, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useEffect, useState } from "react";
import util from "../../../utils/miniUtils";
import CategoryItem from "./CategoryItem";
import CreationModal from "./CreationModal";
import DragAndDrop from "./DragAndDrop";
import { Modal } from "react-bootstrap";
import FieldCategoryList from "../../wlField/FieldCategoryList";

const CategoryStatus = ( { settings } ) => {
    const { getListPath, getAssignedStatusPath } = settings;
    const [formData, setFormData] = useState( {} );
    const [statusList, setStatusList] = useState( [] );
    const [fieldList, setFieldList] = useState( [] );

    const [state, setState] = useState( {
        showExtraColumn: false,
        childData: [],
        parentData: [],
        showModal: false,
        id: null,
        actionType: 1,
        showAddStatus: false,
        addStatus: false
    } );

    const reloadData = () => {
        getCategoryList();
        setState( { ...state, showModal: false } );
    };
    // Get Assigned Status list according to category
    const getAssignedStatus = async ( id ) => {
        try {
            const request = await util.axios.get( `${getAssignedStatusPath}/${id}` );
            const { msg, error, data } = request.data;
            if ( error ) throw msg;
            getStatusList();
            setState( { ...state, childData: data, showExtraColumn: true, id: id } );
        } catch ( error ) {
            util.handleError( error );
        }
    };
    // Get all status list
    const getStatusList = async () => {
        try {
            const request = await util.axios.get( "get_all_status" );
            const { msg, error, data } = request.data;
            if( error ) throw msg;
            setStatusList( data );
        } catch ( error ) {
            util.handleError( error );
        }
    };
    // Get field list according to category
    const getFieldList = async ( categoryId ) => {
        try {
            const request = await util.axios.get( `/wl_fieldCategory/by_category/${categoryId}` );
            const { msg, error, data } = request.data;
            if( error ) throw msg;
            setFieldList( data );
        } catch ( error ) {
            util.handleError( error );
        }
    };
    // Save status in wl_status_categories table
    const saveStatusCategory = async () => {
        let newStatus = {
            wl_category_id: state.id,
            wl_status_id: formData.status,
            status_order: state.childData.length + 1
        };
        try {
            const request = await util.axios.post( '/new_status_category', { newStatus } );
            const { error, msg } = request.data;
            if( error ) throw msg;
            getAssignedStatus( state.id );
            util.toast().success( msg );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const onSelect = ( e, id ) => {
        e.stopPropagation();
        getAssignedStatus( id );
        getFieldList( id );
        setState( ( prev ) => {
            return {
                ...prev,
                id: id
            };
        } );
    };

    const deleteCategory = async ( id ) => {
        try{
            const request = await util.axios.delete( `/wl_categories/delete_category/${id}` );
            const { error, msg } = request;
            if( error ) throw msg;
            getCategoryList();
        } catch ( error ) {
            util.handleError( error );
        }
    }; 

    const onDelete = ( e, id ) => {
        e.stopPropagation();
        deleteCategory( id );
    };

    const onEdit = ( e, id, data ) => {
        e.stopPropagation();
        setFormData( data );
        setState( ( prev ) => {
            return {
                ...prev,
                id: id,
                actionType:2,
                showModal: true,
            };
        } );
    };

    // Get request for getting the parent data i.e. the category list here
    const getCategoryList = async () => {
        try {
            const request = await util.axios.get( getListPath );
            const { msg, error, data } = request.data;
            if ( error ) throw msg;
            // Setting up the data from the request into state.parentData
            setState( ( prev ) => {
                return {
                    ...prev,
                    parentData: data
                };
            } );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    // Delete request for deleting the status from categories
    const deleteStatusCategory = async ( id ) => {
        try {
            const request = await util.axios.delete( `/delete_status_category/${id}` );
            const { error, msg } = request.data;
            if( error ) throw msg;
            getAssignedStatus( state.id );
            util.toast().success( msg );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const updateCategoryStatusOrder = async ( id, order ) => {
        try {
            const request = await util.axios.put( `/update_status_category/${id}`, { status_order: order } );
            const { error, msg } = request.data;
            if( error ) throw msg;
            // util.toast().success( msg );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    //Create Field
    const createField = async ( data ) => {
        try {
            const request = await util.axios.post( 'wl_fieldCategory/new', { newField: { ...data } } );
            const { error, msg } = request.data;
            if( error ) throw msg;
            getFieldList( state.id );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    // Delete field
    const deleteField = async ( fieldId, categoryId ) => {
        try {
            const request = await util.axios.delete( `wl_fieldCategory/delete/${fieldId}/${categoryId}` );
            const { msg, error } = request.data;
            if( error ) throw msg;
            getFieldList( state.id );
            util.toast().success( msg );
        } catch ( error ) {
            util.handleError( error );  
        }
    };

    const handleClose = () => {
        setFormData( {} );
        setState( {
            ...state,
            showModal: false 
        } );
    };

    const handleChange = ( e ) => {
        const { name, value } = e.target;
        if( value === "notOnList" ){
            setState( { ...state, addStatus: true } );
        } else {
            setFormData( {
                [name]: value
            } );
        }
    };

    useEffect( () => {
        getCategoryList();
    }, [] );
    
    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-6 bg-white">
                        <div className="d-flex justify-content-between align-items-center mt-1 mb-2">
                            <b>Category</b>
                            <FontAwesomeIcon
                                className="isCursor text-primary"
                                onClick={() => setState( { ...state, showModal: true, actionType: 1 } )}
                                icon={faPlus}
                            />
                        </div>
                        <div className="listContainer">
                            {state.parentData.map( ( d, i ) => {
                                return <CategoryItem isActive={state.id} key={i} data={d} onSelect={onSelect} onDelete={onDelete} onEdit={onEdit} />;
                            } )}
                        </div>
                    </div>
                    {state.id !== null ? (
                        <div className="col-md-3 col-sm-6 ml-1 bg-white">
                            <div className="col-md-12">
                                <div className="d-flex justify-content-between align-items-center mt-1 mb-2">
                                    <b>Status of Category</b>
                                    <FontAwesomeIcon
                                        className="isCursor text-primary"
                                        onClick={() => setState( { ...state, showAddStatus: true, actionType: 1 } )}
                                        icon={faPlus}
                                    />
                                </div>
                                <DragAndDrop
                                    statusList={state.childData}
                                    deleteStatusCategory={deleteStatusCategory}
                                    id={state.id}
                                    updateCategoryStatusOrder={updateCategoryStatusOrder}
                                    getAssignedStatus={getAssignedStatus}
                                />
                            </div>
                        </div>
                    ) : null}
                    {state.id && <div className="col-md-3 col-sm-6 bg-white">
                        <FieldCategoryList
                            list={fieldList} 
                            category={state.id} 
                            deleteItem={deleteField} 
                            createField={createField} 
                            getFieldList={getFieldList}/>
                    </div>}
                </div>
            </div>
            {state.showModal && (
                <CreationModal
                    show={state.showModal}
                    handleClose={handleClose}
                    settings={settings}
                    reloadData={reloadData}
                    actionType={state.actionType}
                    id={state.id}
                    data={formData}
                />
            )}
            {state.showAddStatus && (
                <AddStatusModal
                    show={state.showAddStatus}
                    handleClose={() => {
                        setState( { ...state, showAddStatus: false, addStatus: false } );
                        setFormData( {} );
                    }}
                    data={statusList}
                    handleChange={handleChange}
                    inputStatus={state.addStatus}
                    cancelInput={() => setState( { ...state, addStatus: false } )}
                    getStatusList={getStatusList}
                    saveStatus={saveStatusCategory}
                />
            )}
           
        </Fragment>
    );
};

export default CategoryStatus;

const AddStatusModal = ( { data, show, handleChange, handleClose, saveStatus, inputStatus, getStatusList, cancelInput } ) => {
    const [status, setStatus] = useState( "" );
    
    function handleStatusInput ( e ) {
        const { value } = e.target;
        setStatus( value );
    }

    async function submitStatus() {
        let newStatus = { name: status };
        try {
            const request = await util.axios.post( '/new_status', { newStatus } );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            getStatusList();
            cancelInput();
        } catch ( error ) {
            util.handleError( error );
        }
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <div className="d-flex justify-content-center">
                    <b>Add Status</b>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="form-group">
                        <label htmlFor="statusSelector">Select Status</label>
                        <select className="form-control" id="statusSelector" name="status" onChange={handleChange}>
                            <option value=""></option>
                            {data.map( item => <option key={item.id} value={item.id}>{item.name}</option> )}
                            <option className="bg-warning" value={"notOnList"}>Not on the List</option>
                        </select>
                    </div>
                    {inputStatus && <div>
                        <div className="form-group">
                            <label htmlFor="statusSelector">Status Name</label>
                            <input className="form-control" name="status" value={status} onChange={handleStatusInput} />
                        </div>
                        <div className="d-flex justify-content-end">
                            <span className="btn btn-success" onClick={submitStatus}>Save</span>
                        </div>
                    </div>}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <span className="btn btn-outline-danger" onClick={handleClose}>
                    <FontAwesomeIcon
                        icon={faTimes}
                    />
                </span>
                <span className="btn btn-outline-primary" onClick={saveStatus}>
                    <FontAwesomeIcon
                        icon={faCheck}
                    />
                </span>
            </Modal.Footer>
        </Modal>
    );
};