import { Card, CardContent } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { addQuestionFromLibrary } from '../../../redux/actions/JobSqActions';

const QuestionsLibrary = ( { list } ) => {
    const dispatch = useDispatch();
    function addQuestionToForm( value ){
        dispatch( addQuestionFromLibrary( value ) );
    }

    if( list.length < 1 ) return null;
    return (
        <>
            {list.map( ( item, index ) => <Card key={index} className="my-2" onClick={() => addQuestionToForm( item )}>
                <CardContent>{item.question}</CardContent>
            </Card> )}
        </> );
};

export default QuestionsLibrary;