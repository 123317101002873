import { TextField, Tooltip } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { Fragment } from "react";
import PhoneInput from "react-phone-input-2";
import useAccount from "./useAccount";

const UserAccount = ( { employee_id } ) => {
    
    const { account, setAccount, companies, ImageUrl, updateUser, handleChanges, onChangeProfilePicture } = useAccount( employee_id );

    const ref = React.useRef();

    return ( 
        <Fragment>
            <table className="table table-borderless w-100">
                <tbody>
                    <tr>
                        <td className="d-flex justify-content-center">
                            <Tooltip title="Click to change profile picture">
                                <span>
                                    {
                                        account.profile_picture === null ?
                                            <img width="100" height="100" src="/images/avatar.svg" onClick={()=> ref.current.click()} />
                                            :
                                            <img width="100" height="100" src={ImageUrl()} onClick={()=> ref.current.click()} />
                                    }
                                </span>
                            </Tooltip>
                            <input 
                                accept="image/png, image/gif, image/jpeg"
                                className="displayNone" ref={ref} type="file" name="profile_picture" onChange={onChangeProfilePicture} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <TextField
                                className={"w-100"}
                                label="Name"
                                value={account.name}
                                name={"name"}
                                onChange={handleChanges}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Autocomplete
                                freeSolo
                                title="Company"
                                name="company"
                                onChange={( e, value ) => setAccount( { ...account, company: value } )}
                                value={account.company}
                                options={companies.map( ( option ) => option.company )}
                                renderInput={( params ) => <TextField {...params} label="Company" />}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <TextField
                                className={"w-100"}
                                label={"Email"}
                                value={account.email}
                                name={"email"}
                                onChange={handleChanges}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label className="labelFormControlMini">Phone 1</label>
                            <PhoneInput
                                placeholder={"Phone 1"}
                                country={'us'}
                                name={"phone1"}
                                value={account.phone1}
                                onChange={phone => {
                                    let e = { target: { name: "phone1", value: phone } };
                                    handleChanges( e );
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label className="labelFormControlMini">Phone 2</label>
                            <PhoneInput
                                placeholder={"Phone 2"}
                                country={'us'}
                                name={"phone2"}
                                value={account.phone2}
                                onChange={phone => {
                                    let e = { target: { name: "phone2", value: phone } };
                                    handleChanges( e );
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <button
                                className="float-right btn btn-block btn-primary"
                                onClick={updateUser}>
                                                    UPDATE
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </Fragment>
    );
};
 
export default UserAccount;