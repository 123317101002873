import React, { Fragment, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faSave, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import util from "../../../../../utils/miniUtils";
import { Tooltip } from "@material-ui/core";


export default function WorkHistory( { wh, handleChangeWorkHistory, index, deleteWorkHistory } ) {
	
    const [state, setState] = useState( { inputDate:true, inputWorkHistory:false } );
	
    return(
        <tr key={index}>
            <td>
                <span
                    onMouseEnter={()=> setState( { ...state, inputDate: true } )}
                    onMouseLeave={()=> setState( { ...state, inputDate: false } )}
                >
                    {
                        state.inputDate ?
                            <Fragment>
                                <util.InputSlim
                                    name="from"
                                    type="date"
                                    onChange={( e )=>handleChangeWorkHistory( e,index )}
                                    value={wh.from ? wh.from: ""}
                                />
                                <util.InputSlim
                                    name="to"
                                    type={"date"}
                                    onChange={( e )=>handleChangeWorkHistory( e,index )}
                                    value={wh.to ? wh.to: ""}
                                />
                            </Fragment>
                            :
                            <Fragment>
                                <input className="form-control" type="text" defaultValue={util.fixDate( wh.from )} />
                                <input className="form-control" type="text" defaultValue={util.fixDate( wh.to )} />
                            </Fragment>
                    }
                </span>
            </td>
            <td>
                {
                    state.inputWorkHistory ?
                        <Fragment>
                            <p className="d-flex align-items-center">
                                <util.InputSlim
                                    name="company"
                                    onChange={( e )=>handleChangeWorkHistory( e,index )}
                                    value={wh.company ? wh.company: ""}
                                />
                                <Tooltip title="Save changes on Work History">
                                    <span>
                                        <FontAwesomeIcon
                                            className="ml-1 text-success"
                                            onClick={()=> setState( { ...state, inputWorkHistory: false } )}
                                            icon={faSave}
                                        />
                                    </span>
                                </Tooltip>
                            </p>
                            <p>
                                <textarea
                                    rows={7}
                                    className="form-control"
                                    name="position"
                                    onChange={( e )=>handleChangeWorkHistory( e,index )}
                                    value={wh.position ? wh.position: ""}
                                >
								
                                </textarea>
                            </p>
                        </Fragment>
                        :
                        <Fragment>
                            <div className="d-flex">
                                <b>{wh.company}</b>
                                <Tooltip title="Edit Work History">
                                    <span>
                                        <FontAwesomeIcon
                                            className="ml-1 iconGray"
                                            onClick={()=> setState( { ...state, inputWorkHistory: true } )}
                                            icon={faPencilAlt}
                                        />
                                    </span>
                                </Tooltip>
                            </div>
                            <div className="font-size-sm">{wh.position}</div>
                        </Fragment>
                }
			
            </td>
            <td className="d-flex justify-content-around">
                <util.InputSlim
                    name="location"
                    onChange={( e )=>handleChangeWorkHistory( e,index )}
                    value={wh.location ? wh.location: ""}
                />
                <Tooltip title="Delete this Work History">
                    <span>
                        <button className="btn btn-sm" onClick={()=> deleteWorkHistory( index )}>
                            <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />
                        </button>
                    </span>
                </Tooltip>
            </td>
        </tr>
    );
}