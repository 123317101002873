import React, { Fragment, useEffect } from "react";
import NavBar from "../navbar/NavBar";
import { PositionsStore } from "../stores/PositionsStore";
import WelcomeScreen from "./WelcomeScreen";
import JobsResult from "./JobsResult";
import util from "../../utils/miniUtils";
import JobDetail from "./job_detail/JobDetail";
import RecoverPassword from "../../auth/RecoverPassword";
import JobsAppliedTalent from "./JobsAppliedTalent";
import ProfileComponent from "../../shared/profile_component/ProfileComponent";
import WelcomeCarasoul from "./job_detail/WelcomeCarasoul";

export default function Positions() {
	
    const position = PositionsStore.useState( state => state );
	
    // Listen wish section should be injected
    useEffect( () => {
        injectSection();
    }, [position["sectionSelected"]] );
	
    const injectSection = () => {
        switch ( position["sectionSelected"] ) {
            case 'Main':
                return <JobsWorkFlow position={position}/>;
            case 'JobsAppliedTalent':
                return <JobsAppliedTalent isValid={true} userId={util.user.candidate_id}/>;
            case 'Profile':
                return <ProfileComponent talent_id={util.user.candidate_id} elevation={0}/>;
        }
    };
	
    return (
        <Fragment>
            <NavBar/>
            {
                position.firstTime && window.location.search === "" ?
                    <WelcomeScreen />
                    :
                    <Fragment>
                        <div
                            className={`${position["sectionSelected"] === 'Profile' ? 'container-fluid profilePublicWidth' : 'container'} bg-white`}>
                            {injectSection()}
                        </div>
                    </Fragment>
            }
			
			
            {position.recoveryModal ?
                <RecoverPassword
                    show={position.recoveryModal}
                    handleClose={() => PositionsStore.update( s => {
                        s.recoveryModal = false;
                    } )}/>
                :
                null
            }
            {util.LOADING_SCREEN( position.loading )}
        </Fragment>
    );
}

function JobsWorkFlow( { position } ) {
	
    return <div className="row">
        <div className={`col-md-${util.isFullScreenJob().sidebar} border-right`}>
            {/* List of results of the search */}
            <JobsResult/>
        </div>
		
        {position.job ?
            <div className={`col-md-${util.isFullScreenJob().main}`}>
                <JobDetail job={position.job}/>
            </div>
            :
        // Dont show the image if have a job
            <div className={`col-md-${util.isFullScreenJob().main} backGroundIMage_JP`}>
                <img width="100%"
				     src="https://www.pngitem.com/pimgs/m/58-585400_web-development-company-svg-hd-png-download.png"
				     alt="logo"/>
            </div>
        }
	
    </div>;
}