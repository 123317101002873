import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import { PositionsStore } from "../stores/PositionsStore";
import { toast } from "react-toastify";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import util from "../../utils/miniUtils";
import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import SkillsContainer from "./profile_steps/review_resume_information/SkillsContainer";
import { Button } from "react-bootstrap";
import countryList from '../../utils/location/country.json';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import selector from "../../utils/selector";

export default function FillProfile() {
	
    const show = PositionsStore.useState( state => state.profileProcess );
    const isLogged = PositionsStore.useState( state => state.isLogged );
    const user = PositionsStore.useState( s=> s.user );
    const [step]  = useState( 1 );
    const [section, setSection]  = useState( "Location" );
    const [selectedFile, setSelectedFile] = useState( null );
    const [selectedFileName, setSelectedFileName] = useState( "" );
    const [countries] = useState( countryList );
    const [dataResume, setDataResume]  = useState( {
        relocation: 0,
        country: "",
        salary_type: "Fulltime",
        salary_mode: "Yearly",
        ctc: "",
        currency: "",
        working_status: "No Authorization for Job Site",
        working_status_other : "",
        platform: "",
        platform_other: "",
        skills: [],
        noticePeriod: "2 Weeks"
    } );
	
    const handleClose = () => {
        if ( isLogged ){
            const urlParams = new URLSearchParams( window.location.search );
            const jobId = urlParams.get( 'job' );
			
            if ( jobId !== null ){
                window.history.replaceState( "", "", `/careers?search=&job=${jobId}` );
            }else{
                window.history.replaceState( "", "", "/" );
            }
            // Every time the talent log in, it will be redirected to the main page
            setTimeout( ()=>{
                window.location.reload();
            },1500 );
        }else{
            PositionsStore.update( s => { s.createAccount = false;} );
        }
    };
	
    useEffect( () => {
        switch ( step ) {
            case 1: setSection( 'Location' ); break;
            case 2: setSection( 'SalaryExpectation' ); break;
            case 3: setSection( 'WorkHistory' ); break;
            default: break;
        }
    },[step] );
	
    useEffect( () => {
    },[section] );


    const updateData = async () => {
        try {
            const response = await util.axios.put( `candidates/update/${user?.candidate_id ? user.candidate_id : util.user.candidate_id}`, dataResume );
            const { error, msg } = response.data;
            if ( error ) throw msg;
            if( selectedFileName && await onUploadNewResume( user?.candidate_id ? user.candidate_id : util.user.candidate_id ) );
            toast.success( msg );
            handleClose();
        }catch ( e ) {
            util.handleError( e );
        }
    };

    const onUploadNewResume = async ( talent_id ) => {
        try {
            const data = new FormData();
            data.append( "file", selectedFile );
            // eslint-disable-next-line no-restricted-globals
            data.set( "name", selectedFile.name  );
            const request = await util.axios.post( `/candidates/upload_resume/${talent_id}`, data );
            const { error, msg, resume } = request.data;
            if ( error )  throw msg;
            setSelectedFileName( resume );
            toast.success( msg );
        }catch ( e ) {
            util.handleError( e );
        }
    };

    // Handle changes in inputs for all the talent profile
    const handleChanges = e => {
        let { name, value, checked } = e.target;
        // console.log( name, value, checked );
		
        if ( name === "relocation" ){
            value = checked ? 1:0;
        }
		
        setDataResume( { ...dataResume, [name]:value } );
    };

    // Listen when input file in changed
    const onChangeHandler = event => {
        const fileSize = ( event.target.files[0].size / 1024 / 1024 ).toString();
        if ( fileSize.split( "." )[0] > 2 ) {
            toast.error( "File size is exceeded, max 2 MB" );
            setSelectedFile( null );
        } else {
            setSelectedFile( event.target.files[0] );
        }
    };

    const saveSkill = ( skillsArray ) => {
        setDataResume( { ...dataResume, skills: skillsArray } );
    };
    

    useEffect( () => {
        if( selectedFile ) {
            setSelectedFileName( selectedFile.name );
        }
    },[selectedFile] );

    return(
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            size="lg"
            onExiting={handleClose}
            onExit={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>Complete your profile</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    {/* upload resume */}
                    <div className="d-flex align-items-center">
                        <p className="mr-1">Click on the button to upload your resume</p>
                        <label htmlFor="resumeInput" className="btn btn-info btn-sm">
							Select file
                        </label>
                        <input
                            style={{ display: "none" }}
                            type="file"
                            id="resumeInput"
                            onChange={onChangeHandler}
                            name="file"
                            accept=".pdf,.doc,.docx"
                        />
                    </div>
                    {selectedFileName ? <div className="btn btn-outline-secondary">{selectedFileName}</div> : ""}
                    <div className="row mb-2 align-items-center">
                        {/* employement type */}
                        <util.RowComponent
                            c={6}
                            t={"Employment Type"}
                            n={"salary_type"}
                            m={handleChanges}
                            v={dataResume.salary_type || ""}
                            type={1}
                            d={[{ salary_type: "Fulltime" }, { salary_type: "Part-time" }, { salary_type: "Contractor" }]}
                        />
                        {/* notice period */}
                        <util.RowComponent
                            c={3}
                            t={"Notice Period"}
                            n={"noticePeriod"}
                            m={handleChanges}
                            v={dataResume.noticePeriod}
                            type={1}
                            d={selector.AvailabilityToStartObj}
                        />
                        {/* specify relocation */}
                        <div className="col-lg-3 col-md-3">
                            <div  className="text-center">
                                <FormControlLabel control={<Checkbox onChange={handleChanges} name="relocation" />} label="Open To Relocate" />
                            </div>
                        </div>
                    </div>
                    {/* expected salary */}	
                    <div className="row mb-2">
                        <util.RowComponent
                            c={4}
                            t={"Expected Salary"}
                            ty="number"
                            n={"ctc"}
                            m={handleChanges}
                            v={dataResume.ctc || ""}
                        />
                        <util.RowComponent
                            c={4}
                            t={"Currency"}
                            n={"currency"}
                            m={handleChanges}
                            v={dataResume.currency}
                            type={1}
                            d={[{ currency: "" }, ...util.currencies()]}
                        />
                        <util.RowComponent
                            c={4}
                            t={"Per"}
                            n={"salary_mode"}
                            m={handleChanges}
                            v={dataResume.salary_mode}
                            type={1}
                            d={selector.sell_rate_type} />
                    </div>

                    {/* work authorisation */}
                    <div className="row mb-2">
                        <div className="col-lg-6 col-md-6 p-1 mt-2 align-self-center">
                            <TextField
                                select
                                size="small"
                                name="country"
                                className="w-100"
                                label="Country (of Citizenship) *"
                                value={dataResume.country}
                                onChange={handleChanges}
                                InputLabelProps={{ shrink: true }}
                                SelectProps={{
                                    native: true
                                }}
                                fullWidth
                            >
                                <optgroup label="Most Active">
                                    <option value="USA">USA</option>
                                    <option value="Canada">Canada</option>
                                    <option value="India">India</option>
                                    <option value="Australia">Australia</option>
                                    <option value="Mexico">Mexico</option>
                                    <option value="Japan">Japan</option>
                                    <option value="Germany">Germany</option>
                                    <option value="Puerto Rico">Puerto Rico</option>
                                </optgroup>
                                <optgroup label="All Countries">
                                    <option value=""/>
                                    {countries?.map( ( option, i ) => (
                                        <option key={i} value={option.name}>
                                            {option.name}
                                        </option>
                                    ) )}
                                </optgroup>
                            </TextField>
                        </div>
                        <util.WorkAuthSelector value={dataResume.working_status} handleChange={handleChanges} />
                    </div>
                    {dataResume.working_status=== "other" ? <div className="row mb-2">
                        <util.RowComponent
                            c={12}
                            t={"Work Authorization (Others)"}
                            n={"working_status_other"}
                            m={handleChanges}
                            v={dataResume.working_status_other || ""} />
                            
                    </div> : ""}
                    {/* how did you hear about us */}
                    <div className="row mb-2">
                        <util.RowComponent
                            c={dataResume.platform  === "Other" ? 6 : 12}
                            t={"How did you find us?"}
                            n={"platform"}
                            m={handleChanges}
                            v={dataResume.platform}
                            type={1}
                            d={[
                                { platform: " " },
                                { platform: "Indeed" },
                                { platform: "LinkedIn" },
                                { platform: "Monster" },
                                { platform: "ZipRecruiter" },
                                { platform: "Other" }
                            ]}
                        />
						
                        {dataResume.platform === "Other" ?
                            <util.RowComponent
                                c={6}
                                t={"Other"}
                                n={"platform_other"}
                                m={handleChanges}
                                v={dataResume["platform_other"] || ""}
                            />
                            :""}
                    </div>
                    {/* update skills */}
                    <SkillsContainer
                        data={dataResume}
                        onChange={handleChanges}
                        updateData={saveSkill}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={updateData}> <FontAwesomeIcon icon={faSave} /> Save</Button>
            </Modal.Footer>
        </Modal>
    );
}