import { Drawer } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import util from '../../utils/miniUtils';
import useWorkload from './useWorkload';

const BulkEditWorkload = ( { workloads, bulkShow, closeBulkDrawer, tableName } ) => {   
    const {
        departmentList,
        groupList,
        categoriesByGroup,
        statusListByCategory,
        membersOfGroup,
        getGroupList,
        getDepartments,
        getCategories,
        getAssignedMembersToGroup,
        getStatusListToCategory,
        setLoading } = useWorkload();
    
    const [showInput, setShowInput] = useState( {
        group: true,
        category: true,
        status: true
    } );
    const [form, setForm] = useState( {} );
    const [statusValue, setStatusValue] = useState( {
        status: null,
        status_from_categories: null
    } );
    
    const handleChange = ( e ) => {
        const { name, value } = e.target;
        setForm( ( prev ) => {
            return {
                ...prev,
                [name]: value
            };
        } );
    };

    const handleStatusChange = ( e ) => {
        const { value } = e.target;
        setStatusValue( {
            status: Number( value.split( " " )[0] ),
            status_from_categories: Number( value.split( " " )[1] )
        } );
    };

    const checkWorkloadCategory = () => {
        let temp = workloads.length > 0 ? workloads[0] : {};
        for( let i = 1; i < workloads.length; i++ ){
            if( temp.wl_category_id !== workloads[i].wl_category_id ){
                setShowInput( {
                    group: true,
                    category: false,
                    status: false
                } );
            }else if( temp.group_id !== workloads[i].group_id ){
                setShowInput( {
                    ...showInput,
                    group: false,
                    category: false,
                    status: false
                } );
            } else setShowInput( {
                group: true,
                category: true,
                status: true
            } );
        }
    };

    const updateBulkWorkload = async() => {
        try {
            setLoading( true );
            for( const i of workloads ) {
                let id = i.id;
                const request = await util.axios.put( `/workload/update_workload/${id}`, { ...form, ...statusValue } );
                const { error, msg } = request.data;
                if( error ) throw msg;
            }
            closeBulkDrawer();
            setLoading( false );
            util.toast().success( "Workloads updated" );
        } catch ( error ) {
            util.handleError( error );
            setLoading( false );
        }
    };

    useMemo( () => {
        if( form.wl_category_id ) {
            getStatusListToCategory( form.wl_category_id );

            if( form.wl_category_id ) {
                setShowInput( {
                    group: true,
                    category: true,
                    status: true
                } );
            }
        }
    }, [form.wl_category_id] );

    useMemo( () => {
        if( form.group_id ) {
            getCategories( form.group_id );
            getAssignedMembersToGroup( form.group_id );

            if( form.group_id ) {
                setShowInput( {
                    group: true,
                    category: true,
                    status: false
                } );
            }
        }
    }, [form.group_id] );

    useEffect( () => {
        if( bulkShow ) {
            getDepartments();
            getGroupList();
            // For common status values
            checkWorkloadCategory();

            if( workloads.length > 0 ) {
                getCategories( workloads[0].group_id );
                getAssignedMembersToGroup( workloads[0].group_id );
                getStatusListToCategory( workloads[0].wl_category_id );
            }
        }
    },[bulkShow, workloads] );

    const FormSelectorComponent = ( { name, label, options=[], value, handleChange, valueName, } ) => {
        return <div className="mb-3">
            <div className="form-group px-3">
                <label>{label}</label>
                <select className="form-control" name={name} value={value} onChange={handleChange}>
                    <option></option>
                    {options && options.map( ( item, index ) => <option key={`${name}${index}`} value={name === "resolver_id" ? item.employee_id : item.id}>{item[valueName]}</option> )}
                </select>
            </div>
        </div>;
    };

    return (
        <>
            <Drawer
                // variant="persistent"
                anchor="right"
                open={bulkShow}
                onClose={closeBulkDrawer}
                className="wl-bulkEdit-container">
                <div className="wl-bulkEdit-container">
                    <h2 className="border-bottom text-center mx-5 py-2">Bulk Edit</h2>
                    <div className="wl-bulkEdit-form px-2">
                        { tableName !== "myWorkload" &&  (
                            <>
                                {tableName !== "groupTable" && <FormSelectorComponent
                                    name="department_id"
                                    label="Department"
                                    value={form.department_id}
                                    valueName="itemName"
                                    options={departmentList}
                                    handleChange={handleChange}/>}

                                <FormSelectorComponent

                                    name="group_id"
                                    label="Assignment Group"
                                    value={form.group_id}
                                    valueName="itemName"
                                    options={groupList}
                                    handleChange={handleChange}/>

                                {showInput.category && <FormSelectorComponent 
                                    name="wl_category_id"
                                    label="Workload Category"
                                    value={form.wl_category_id}
                                    valueName="name"
                                    options={categoriesByGroup}
                                    handleChange={handleChange}/>
                                }

                                {showInput.category && <FormSelectorComponent
                                    name="resolver_id"
                                    label="Assign To"
                                    value={form.resolver_id}
                                    valueName="name"
                                    options={membersOfGroup}
                                    handleChange={handleChange}/>
                                }
                            </>
                        )}

                        {showInput.status && <div className="mb-3">
                            <div className="form-group px-3">
                                <label>Status</label>
                                <select
                                    className="form-control"
                                    value={statusValue.status + " " + statusValue.status_from_categories}
                                    name="status_from_categories"  onChange={handleStatusChange}>
                                    <option value={null}></option>
                                    {statusListByCategory && statusListByCategory.map( ( item ) => (
                                        <option key={item.wl_status_category} value={item.wl_status_id + " " + item.wl_status_category}>
                                            {item.name}
                                        </option>
                                    ) )}
                                </select>
                            </div>
                        </div>}

                        <div className="mb-3">
                            <div className="form-group px-3">
                                <label>Priority</label>
                                <select className="form-control" name='priority' value={form.priority} onChange={handleChange}>
                                    <option></option>
                                    {["Low", "Medium", "High", "Critical"].map( ( item, index ) => <option key={index}>{item}</option> )}
                                </select>
                            </div>
                        </div>

                        {tableName !== "myWorkload" && (
                            <>
                                <div className="mb-3">
                                    <div className="form-group px-3">
                                        <label>Ticket Category</label>
                                        <input className="form-control" name="ticket_category" value={form.ticket_category} onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <div className="form-group px-3">
                                        <label>Ticket Type</label>
                                        <input className="form-control" name="ticket_type" value={form.ticket_type} onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <div className="form-group px-3">
                                        <label>Item Name</label>
                                        <input className="form-control" name="item" value={form.item} onChange={handleChange} />
                                    </div>
                                </div>
                            </>
                        )}

                    </div>
                    <div className="d-flex justify-content-end mt-3 px-3">
                        <span className="btn btn-danger mr-1" onClick={() => {
                            closeBulkDrawer();
                            setForm( {} );
                            setStatusValue( {
                                status: null,
                                status_from_categories: null
                            } );
                        }}
                        >
                        Cancel</span>
                        <span className="btn btn-primary" onClick={updateBulkWorkload}>Save</span>
                    </div>
                </div>
            </Drawer>
        </>
    );
};

export default BulkEditWorkload;