import util from "../../../../utils/miniUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import React, { Fragment, useEffect, useState } from "react";
import { Tooltip } from "@material-ui/core";


export default function EmailToTalent( { data, refreshData } ) {
    const [loading, setLoading] = useState( false );
    const [info, setInfo] = useState( data );
	
    useEffect( () => {
        setInfo( data );
    },[data] );
	
    async function sendDocumentEmail() {
        try {
            setLoading( true );
            const request = await util.axios.post( `${util.url}/job_process/documents/send_email`, { data } );
            const { error, msg } = request.data;
            if ( error ) throw  msg;
            setLoading( false );
            refreshData();
            util.toast().success( msg );
        }catch ( e ) {
            setLoading( false );
            util.handleError( e );
        }
    }
	
    // Email sent
    return(
        <Fragment>
            {info["sent_date"] === null ?
            // Email not sent
                <FontAwesomeIcon className="burlywood isPointer" onClick={sendDocumentEmail} icon={faEnvelope} />
                :
                <Fragment>
                    <Tooltip title={`Sent on ${util.humanDate( info["sent_date"] )} by ${info["senderName"]}`}>
                        <span>
                            {util.humanDate( info["sent_date"] )}
                        </span>
                    </Tooltip>
                    <Tooltip title={`Sent again`} className="ml-2 uploadFileColor">
                        <span>
                            <FontAwesomeIcon onClick={sendDocumentEmail} icon={faSyncAlt} />
                        </span>
                    </Tooltip>
                </Fragment>
            }
			
            {util.LOADING_SCREEN( loading )}
        </Fragment>
    );
}