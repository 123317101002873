import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import util from "../../../utils/miniUtils";

export default function SocialMediaContainer( { talent_id } ) {

    const [urls, setUrls] = useState( {
        facebook: "",
        github: "",
        linkedin: "",
        twitter: "",
        web: ""
    } );
    const [loading, setLoading] = useState( false );


    const startLoading = () => setLoading( true );
    const stopLoading = () => setLoading( false );

    useEffect( () => {
        getSocialMedia().then( null );
    }, [] );

    // get all available SocialMedia
    const getSocialMedia = async () => {
        try {
            setLoading( true );
            const response = await util.axios.get( `talent/social_media_list/${talent_id}/` );
            const { error, msg, data } = response["data"];
            if ( error ) throw new Error( msg );
            setUrls( Object.assign( {}, data ) );
            setLoading( false );
        } catch ( e ) {
            setLoading( false );
            util.handleError( e );
        }
    };

    const handleChanges = ( e ) => {
        const { name, value } = e.target;
        setUrls( { ...urls, [name]: value } );
    };

    const updateUrls = async () => {
        try {
            startLoading();
            const response = await util.axios.put( `talent/social_media_update/${talent_id}/`, urls );
            const { error, msg, data } = response["data"];
            if ( error ) throw new Error( msg );
            util.toast().success( "Social Media Updated" );
            setUrls( Object.assign( {}, data ) );
            stopLoading();
        } catch ( e ) {
            stopLoading();
            util.handleError( e );
        }
    };

    return (
        <div className="container-fluid mt-2">
            <div className="row">
                <div className="col-md-12">
                    <table className="table">
                        <thead />
                        <tbody>
                            <SocialMediaSingle 
                                field="linkedin" 
                                handleChanges={handleChanges} 
                                image="LinkedIn"
                                updateUrls={updateUrls}
                                urls={urls}
                            />
                            <SocialMediaSingle 
                                field="facebook" 
                                handleChanges={handleChanges} 
                                image="Facebook"
                                updateUrls={updateUrls}
                                urls={urls}
                            />
                            <SocialMediaSingle 
                                field="twitter" 
                                handleChanges={handleChanges} 
                                image="Twitter"
                                updateUrls={updateUrls}
                                urls={urls}
                            />
                            <SocialMediaSingle 
                                field="github" 
                                handleChanges={handleChanges} 
                                image="Github"
                                updateUrls={updateUrls}
                                urls={urls}
                            />
                            <SocialMediaSingle 
                                field="web" 
                                handleChanges={handleChanges} 
                                image="Web"
                                updateUrls={updateUrls}
                                urls={urls}
                            />
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    );
}

function SocialMediaSingle( { urls, handleChanges, updateUrls, field, image } ) {
    return (
        <tr>
            <td className="text-center">
                {
                    urls[field] ?
                        <a target="new" href={urls[field] ? urls[field] : "#"}>
                            <img width="40px" src={`/images/${image}.png`} alt={`Facebook icon`} />
                        </a>
                        :
                        <img width="40px" src={`/images/${image}.png`} alt={`Facebook icon`} />

                }

            </td>
            <td>
                <util.InputSlim
                    name={field}
                    placeholder={`Your ${field} url`}
                    onChange={handleChanges}
                    value={urls[field] ? urls[field] : ""}
                />
            </td>
            <td className="d-flex justify-content-center align-content-center">
                <button
                    onClick={updateUrls}
                    className="btn btn-sm btn-primary"
                >
                    <FontAwesomeIcon icon={faSave} className="mr-2" />
					Save
                </button>
            </td>
        </tr>
    );
}