import React, { useEffect, useState } from 'react';
import util from "../utils/miniUtils";
import { Tab, Tabs } from "react-bootstrap";
import AssignedAssets from "./AssignedAssets";
import AddAssetDrawer from "./assets_manager/AddAssetDrawer";
import AssetManagerTable from './AssetManagerTable';
import useAssetManager from './useAssetManager';
import { RECRUITER_ROLE } from "../../constants";

const AssetsManager = () => {
    // Getting States using the useAssetManager
    const {
        loading,
        assetsList,
        modalShow, setModalShow,
        showInputFactory, setShowInputFactory,
        isEditing,
        showAlert, setShowAlert,
        assetData, setAssetData,
        assetMake,
        assetModel,
        assetsTypes,
        accounts,
        categories,
        getAssetsList,
        handleChange,
        InputNewElement,
        saveNewAssets,
        updateNewAssets,
        editAsset,
        onConfirmDeleteAsset
    } = useAssetManager();

    const [isAssetManager] = useState( util.user.permissions["asset_manager"] === RECRUITER_ROLE );

    useEffect( () => {
        getAssetsList( null );
        // eslint-disable-next-line
    }, [] );

    return (
        <>
            <Tabs mountOnEnter={true} variant={"tabs"} unmountOnExit={true} defaultActiveKey="assigned_assets" >
                <Tab eventKey="assigned_assets" title="My Assets">
                    <AssignedAssets />
                </Tab>
                {isAssetManager &&
                    <Tab eventKey="manage_assets" title="Manage" id="manageTab">
                        <>
                            <AssetManagerTable assetsList={assetsList} editAssetHandler={editAsset} setAssetData={setAssetData} setModalShow={setModalShow} />

                            <AddAssetDrawer
                                modalShow={modalShow}
                                setModalShow={setModalShow}
                                isEditing={isEditing}
                                assetData={assetData}
                                handleChange={handleChange}
                                categories={categories}
                                showInputFactory={showInputFactory}
                                setShowInputFactory={setShowInputFactory}
                                InputNewElement={InputNewElement}
                                assetsTypes={assetsTypes}
                                assetMake={assetMake}
                                assetModel={assetModel}
                                accounts={accounts}
                                setAssetData={setAssetData}
                                updateNewAssets={updateNewAssets}
                                saveNewAssets={saveNewAssets}
                                setShowAlert={setShowAlert}
                                showAlert={showAlert}
                                onConfirmDeleteAsset={onConfirmDeleteAsset}
                            />
                        </>
                    </Tab>
                }
            </Tabs>
            {util.LOADING_SCREEN( loading )}
        </>
    );

};

export default AssetsManager;