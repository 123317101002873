import React, { useState } from "react";
import { CircularProgress } from "@material-ui/core";

export default function UploadResumeForm( {
	                                         resumeLoading,
	                                         onUploadNewResume,
	                                         onCancelResumeUpload,
	                                         isVisible,
	                                         onChangeHandler,
	                                         updateStoredTalentResume,
	                                         talent_id,
    resumeData
} ) {
	
    const [isUploading] = useState( resumeLoading );
	
	
    if ( isUploading ) {
        return <div className="text-center"><CircularProgress className="m-2"/></div>;
    }
	
    return (
        <div className="">
            {resumeData? <span>{resumeData.name}</span>: null}
            <div className="form-group col-md-12 mb-0">
                <label htmlFor="filesX" className="btn btn-info btn-sm btn-block">
					Select file
                </label>
                <input
                    style={{ display: "none" }}
                    type="file"
                    id="filesX"
                    multiple={false}
                    onChange={onChangeHandler}
                    name="file"
                    accept=".pdf,.doc,.docx"
                />
            </div>
            <div className="form-group col-md-12">
                <div className={`text-center ${isVisible}`}>
                    <button
                        className="btn btn-danger btn-sm m-1"
                        onClick={onCancelResumeUpload}
                    >
						Cancel
                    </button>
                    {
                        resumeData ?
                            <button
                                className="btn btn-primary btn-sm m-1"
                                onClick={()=>onUploadNewResume( talent_id, updateStoredTalentResume )}
                            >
								Upload Resume
                            </button>
                            :
                            ""
                    }
					
                </div>
            </div>
        </div>
    );
	
}