import React, { useState } from "react";
import MaterialTable from "material-table";
import util from "../../../../utils/miniUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { MuiThemeProvider } from "@material-ui/core";
import JobDescription from "./JobDescription";

const tableRef = React.createRef(); // Used to refresh table when candidate apply for a job position

function JobsListTalent() {
    const [jdModalVisible, setJdModalVisible] = useState( false );
    const [jobDescription, setJobDescription] = useState( "" );
    
    function jdHideModal() {
        setJdModalVisible( false );
    }
    
    function openModalJD( data ) {
        setJobDescription( data );
        setJdModalVisible( true );
    }
    
    // console.log( util.user );
    return (
        <div>
            <MuiThemeProvider theme={util.defaultTableTheme}>
                <MaterialTable
                    tableRef={tableRef}
                    title="Jobs positions"
                    columns={[
                        {
                            title: "Job. Id",
                            field: "id",
                            type: "numeric",
                            defaultSort: "desc",
                            render: ( row ) => {
                                return (
                                    <span className="likeLink" onClick={()=> openModalJD( row )}>
                                        {util.zeroPad( row.id, 5 )}
                                    </span>
                                );
                            },
                        },
                        {
                            title: "Date", field: "createdAt",
                            render: data => {
                                return <span className="textSmall">{util.humanDate( data.createdAt )}</span>;
                            }
                        },
                        {
                            title: "Job Title",
                            field: "id",
                            render: ( row ) => {
                                return (
                                    <span  className="likeLink" onClick={()=> openModalJD( row )}>
                                        {row.job_title}
                                    </span>
                                );
                            },
                        },
                        {
                            title: "Location",
                            sorting:false,
                            render: ( row ) => {
                                return util.location( row.country, row.state, row.city );
                            },
                        },
                        {
                            title: "Restricted to",
                            field:"restricted_to"
                        },
                        {
                            title: "Employment Type",
                            field:"employment_type"
                        },
                        {
                            title: "Min Pay rate",
                            field:"min_pay",
                            render: d =>{
                                return util.currencyFormat( d.min_pay );
                            }
                        },
                        {
                            title: "Max Pay rate",
                            field:"max_pay",
                            render: d =>{
                                return util.currencyFormat( d.max_pay );
                            }
                        },
                        {
                            title: "Applied",
                            field:"applied",
                            sorting:true,
                            render: data =>{
                                return data.applied ? <FontAwesomeIcon icon={faCheck} />: "";
                            }
                        },
                        {
                            title: <div className="text-center">Status</div>,
                            field: "statusCandidate",
                            sorting:false,
                            render: ( data ) => {
                                return data.applied ? util.talentStatus( data.statusCandidate, data.status_note ):"";
                            },
                        },
                    ]}
                    data={query =>
                        util.axios.get( util.urlJobPositionsTalent( query ) )
                            .then( response => {
                                const { jobPositions, total, page, error, msg } = response.data;
                                if ( error ) throw  msg;
                                return ( {
                                    data: jobPositions,
                                    page: page,
                                    totalCount: total
                                } );
                            } )
                            .catch( error=>{
                                util.handleError( error );
                            } )
                    }
                    options={{
                        pageSize: 10,
                        sorting: true,
                        showTitle: false,
                        emptyRowsWhenPaging:false,
                        exportButton:true,
                    }}
                    onRowClick={( event, row ) => {
                        openModalJD( row );
                    }}
                />
            </MuiThemeProvider>
            

            {/*Modal for Job Description - FOR TALENT, Client and Partner */}
            <JobDescription
                jdModalVisible={jdModalVisible}
                jdHideModal={jdHideModal}
                jobDescription={jobDescription}
                refreshTable={()=> tableRef.current && tableRef.current.onQueryChange()}
            />
        </div>
    );
}


export default JobsListTalent;
