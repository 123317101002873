import util from "../../../../utils/miniUtils";
import MaterialTable from "material-table";
import { MuiThemeProvider } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";

function TableOnboardedTalents( { job_id, selectedTalents } ) {
	
    const [state, setState] = useState( {
        data:{},
        loading: false,
        showAlert: false,
        talents:[]
    } );
	
    useEffect( () => {
        // In the first launch make a request to get the data
        getOnboardedTalents().then( null );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[] );
	
    const getOnboardedTalents = async () => {
        try {
            setState( { ...state, loading: true } );
            const request = await util.axios.get( `${util.url}/job_process/onboarded_talents/list/${job_id}` );
            const { error, msg, talents } = request.data;
            if ( error ) return msg;
            setState( { ...state, talents: Object.assign( [], talents ), loading: false } );
        } catch ( error ) {
            setState( { ...state, loading: false } );
            util.handleError( error );
        }
    };

    return(
        <Fragment>
            <MuiThemeProvider theme={util.defaultTableTheme}>
                <MaterialTable
                    columns={[
                        {
                            field: "name",
                            title: "Talent"
                        },
                    ]}
                    data={state.talents}
                    options={{
                        pageSize: 10,
                        tableLayout: "auto",
                        showFirstLastPageButtons: false,
                        pageSizeOptions: [],
                        toolbar:false,
                        padding: "dense",
                        paginationType: "stepped",
                        selection:true,
                        emptyRowsWhenPaging:false,
                    }}
                    onSelectionChange={data => selectedTalents( data )}
                />
            </MuiThemeProvider>
            {util.LOADING_SCREEN( state.loading )}
        </Fragment>
    );
}

export default TableOnboardedTalents;