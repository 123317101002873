import React from 'react';
import YelloGrey from '../../../../assets/images/yellow-gry.png';
import Grey from '../../../../assets/images/grey.png';
import Green from '../../../../assets/images/green.png';
import GreenGrey from '../../../../assets/images/green-grey.png';
import RedGrey from '../../../../assets/images/red-grey.png';
import GreenYellow from '../../../../assets/images/green-yellow.png';
import User from "../../../../assets/images/user.png";

const MediumDeviceStatus = ( { appraiseeStatus,l2_exist,l3_exist } ) => {
    return (
        <div className='medium_device_status'>

            <div
                title="status"
                className="d-flex p-1"
            >
                {( appraiseeStatus == 0 || appraiseeStatus == null ) &&
                                    <div>
                                        <span>
                                            <img
                                                className="appraisee  ml-1"
                                                alt=""
                                                src={User} />
                                        </span>
                                        <img
                                            className="img_small"
                                            alt="status"
                                            src={Grey} />
                                    </div>}
                {appraiseeStatus == 1 &&
                                    <div>
                                        <span>
                                            <img
                                                className="appraisee  ml-1"
                                                alt=""
                                                src={User} />
                                        </span>
                                        <img
                                            className="img_small"
                                            alt="status"
                                            src={YelloGrey} />
                                    </div>}
                {appraiseeStatus == 3 &&
                                    <div>
                                        <span>
                                            <img
                                                className="appraisee  ml-1"
                                                alt=""
                                                src={User} />
                                        </span>
                                        <img
                                            className="img_small"
                                            alt="status"
                                            src={RedGrey} />
                                    </div>}
                {( appraiseeStatus == 2 || appraiseeStatus == 4 || appraiseeStatus == 6 || appraiseeStatus == 8 || appraiseeStatus == 10 || appraiseeStatus == 12 || appraiseeStatus == 5|| appraiseeStatus == 7 || appraiseeStatus == 9 || appraiseeStatus == 11 ) &&
                                    <div>
                                        <span>
                                            <img
                                                className="appraisee  ml-1"
                                                alt=""
                                                src={User} />
                                        </span>
                                        <img
                                            className="img_small"
                                            alt="status"
                                            src={GreenGrey} />
                                    </div>}
                {appraiseeStatus == 13 &&
                                    <div>
                                        <span>
                                            <img
                                                className="appraisee  ml-1"
                                                alt=""
                                                src={User} />
                                        </span>
                                        <img
                                            className="img_small"
                                            alt="status"
                                            src={GreenYellow} />
                                    </div>}
                {( appraiseeStatus == 14 || appraiseeStatus == 15 || appraiseeStatus == 16 ||appraiseeStatus == 17 || appraiseeStatus == 18 ) &&
                                    <div>
                                        <span>
                                            <img
                                                className="appraisee  ml-1"
                                                alt=""
                                                src={User} />
                                        </span>
                                        <img
                                            className="img_small"
                                            alt="status"
                                            src={Green} />
                                    </div>}
                                    
                {( appraiseeStatus == 0 || appraiseeStatus == null || appraiseeStatus == 3 || appraiseeStatus == 1  )&&
                                    <div>
                                        <span className="mb-1 ml-1">L1</span>
                                        <img
                                            className="img_small"
                                            alt="status"
                                            src={Grey} />
                                    </div>}
                {appraiseeStatus == 5 &&
                                    <div>
                                        <span className="mb-1 ml-1">L1</span>
                                        <img
                                            className="img_small"
                                            alt="status"
                                            src={RedGrey} />
                                    </div>}
                {appraiseeStatus == 2 &&
                                    <div>
                                        <span className="mb-1 ml-1">L1</span>
                                        <img
                                            className="img_small"
                                            alt="status"
                                            src={YelloGrey} />
                                    </div>}
                {( appraiseeStatus == 4 || appraiseeStatus == 6 ||  appraiseeStatus == 8 || appraiseeStatus == 10  || appraiseeStatus == 12 || appraiseeStatus == 13 || 
                                    appraiseeStatus == 7 || appraiseeStatus == 9 || appraiseeStatus == 11  ) &&
                                    <div>
                                        <span className="mb-1 ml-1">L1</span>
                                        <img
                                            className="img_small"
                                            alt="status"
                                            src={GreenGrey} />
                                    </div>}
                {appraiseeStatus == 14 &&
                                    <div>
                                        <span className="mb-1 ml-1">L1</span>
                                        <img
                                            className="img_small"
                                            alt="status"
                                            src={GreenYellow} />
                                    </div>}
                {( appraiseeStatus == 15 || appraiseeStatus == 16 || appraiseeStatus == 17 || appraiseeStatus == 18 ) &&
                                    <div>
                                        <span className="mb-1 ml-1">L1</span>
                                        <img
                                            className="img_small"
                                            alt="status"
                                            src={Green} />
                                    </div>}


                {l2_exist ? ( <div>
                    {( appraiseeStatus == 0 || appraiseeStatus == null || appraiseeStatus == 3 || appraiseeStatus ==5 || appraiseeStatus == 1 || appraiseeStatus == 2 )&&
                                    <div>
                                        <span className="mb-1 ml-2">L2</span>
                                        <img
                                            className="img_small"
                                            alt="status"
                                            src={Grey} />
                                    </div>}
                    {appraiseeStatus == 7 &&
                                    <div>
                                        <span className="mb-1 ml-1">L2</span>
                                        <img
                                            className="img_small"
                                            alt="status"
                                            src={RedGrey} />
                                    </div>}
                                   

                    {appraiseeStatus == 4 &&
                                    <div>
                                        <span className="mb-1 ml-1">L2</span>
                                        <img
                                            className="img_small"
                                            alt="status"
                                            src={YelloGrey} />
                                    </div>}
                    {( appraiseeStatus == 6 ||  appraiseeStatus == 8 || appraiseeStatus == 10 || appraiseeStatus == 12|| appraiseeStatus == 13 || 
                                    appraiseeStatus == 9 || appraiseeStatus == 11 || appraiseeStatus == 14 ) &&
                                    <div>
                                        <span className="mb-1 ml-1">L2</span>
                                        <img
                                            className="img_small"
                                            alt="status"
                                            src={GreenGrey} />
                                    </div>}
                    {appraiseeStatus == 15 &&
                                    <div>
                                        <span className="mb-1 ml-1">L2</span>
                                        <img
                                            className="img_small"
                                            alt="status"
                                            src={GreenYellow} />
                                    </div>}
                    {( appraiseeStatus == 16 || appraiseeStatus == 17 || appraiseeStatus == 18 ) &&
                                    <div>
                                        <span className="mb-1 ml-1">L2</span>
                                        <img
                                            className="img_small"
                                            alt="status"
                                            src={Green} />
                                    </div>} </div> ) : ""}

                {l3_exist ? ( <div>
                    {( appraiseeStatus == 0 || appraiseeStatus == null ||  appraiseeStatus == 3 || appraiseeStatus ==5 || appraiseeStatus==7 || appraiseeStatus == 1 || appraiseeStatus == 2 || 
                                    appraiseeStatus == 4  )&&
                                    <div>
                                        <span className="mb-1 ml-1">L3</span>
                                        <img
                                            className="img_small"
                                            alt="status"
                                            src={Grey} />
                                    </div>}
                    {appraiseeStatus ==6 &&
                                    <div>
                                        <span className="mb-1 ml-1">L3</span>
                                        <img
                                            className="img_small"
                                            alt="status"
                                            src={YelloGrey} />
                                    </div>}
                    {appraiseeStatus ==9 &&
                                    <div>
                                        <span className="mb-1 ml-1">L3</span>
                                        <img
                                            className="img_small"
                                            alt="status"
                                            src={RedGrey} />
                                    </div>}
                    {( appraiseeStatus ==8 || appraiseeStatus == 10 || appraiseeStatus == 12 || appraiseeStatus == 13 || appraiseeStatus==11 || appraiseeStatus == 14 || appraiseeStatus == 15 ) &&
                                    <div>
                                        <span className="mb-1 ml-1">L3</span>
                                        <img
                                            className="img_small"
                                            alt="status"
                                            src={GreenGrey} />
                                    </div>}
                    {appraiseeStatus == 16 &&
                                    <div>
                                        <span className="mb-1 ml-1">L3</span>
                                        <img
                                            className="img_small"
                                            alt="status"
                                            src={GreenYellow} />
                                    </div>}
                    {( appraiseeStatus == 17 || appraiseeStatus == 18 ) &&
                                    <div>
                                        <span className="mb-1 ml-1">L3</span>
                                        <img
                                            className="img_small"
                                            alt="status"
                                            src={Green} />
                                    </div>}</div> ) : ""}
                                    
                {( appraiseeStatus == 0 ||  appraiseeStatus == null || appraiseeStatus == 3 || appraiseeStatus ==5 || appraiseeStatus==7 || appraiseeStatus==9 || appraiseeStatus == 1 || appraiseeStatus == 2 || appraiseeStatus == 4 || appraiseeStatus ==6 )&&
                                    <div>
                                        <span className="mb-1 ml-1">HR</span>
                                        <img
                                            className="img_small"
                                            alt="status"
                                            src={Grey} />
                                    </div>}
                {appraiseeStatus == 8 &&
                                    <div>

                                        <span className="mb-1 ml-1 ">HR</span>
                                        <img
                                            className="img_small"
                                            alt="status"
                                            src={YelloGrey} />
                                    </div>}
                {appraiseeStatus == 11 &&
                                    <div>

                                        <span className="mb-1 ml-1 ">HR</span>
                                        <img
                                            className="img_small"
                                            alt="status"
                                            src={RedGrey} />
                                    </div>}
                {( appraiseeStatus == 10 || appraiseeStatus == 12 || appraiseeStatus == 13 || appraiseeStatus == 14 || appraiseeStatus == 15 || 
                                     appraiseeStatus == 16 ) &&
                                    <div>

                                        <span className="mb-1 ml-1 ">HR</span>
                                        <img
                                            className="img_small"
                                            alt="status"
                                            src={GreenGrey} />
                                    </div>}
                {appraiseeStatus == 17 &&
                                    <div>

                                        <span className="mb-1 ml-1 ">HR</span>
                                        <img
                                            className="img_small"
                                            alt="status"
                                            src={GreenYellow} />
                                    </div>}
                {appraiseeStatus == 18 &&
                                    <div>

                                        <span className="mb-1 ml-1 ">HR</span>
                                        <img
                                            className="img_small"
                                            alt="status"
                                            src={Green} />
                                    </div>}
            </div>
        </div>
    );
};

export default MediumDeviceStatus;