import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Button from "./button";
import "../../../assets/css/landingPage.css";

const MiddleLeftContent = ( handleRedirect ) => {
    // const title_1 = "Revolutionize Your Recruitment Game!";
    const title_1 = "Explore eJWorkload and its user-friendly interface now";
    const titleFontSize = 18;
    const titleColor = "#0071C1";
    const paragraphText = `eJWorkload is a product from eJAmerica’s Enterprise Efficiency System (eJEES) that offers a suite of comprehensive SaaS products designed to optimize and automate recruitment and hiring processes, especially focusing on the unique needs of modern small enterprises. From tracking workloads across your organization to managing projects, our simple and extremely customizable platform is designed to streamline your business operations and help you achieve leapfrog efficiency gains.
    Our suite of SaaS products includes eJWorkload, a powerful tool for tracking an unlimited variety of work across your organization. It is a versatile platform that offers you extreme flexibility with ease of customization, providing a clear overview of all work being performed across various functions and their linkages. Each department can create its own work item types. Functional leaders from Legal, HR, IT, Finance, Payroll, Corporate Communications, or any other department can create their statuses of the work suiting their needs. Their teams assign work to specific individuals across the organization along with efficient tracking of their acknowledgment and resolution times. 
    With the modern, drag-and-drop interface of eJWorkload, your organization can take the cross-functional work coordination and management of projects to a level of ease and efficiency that has never been imagined before! eJWorkload is your go-to platform for smart working and Streamlining your Business Operations.`;
    const paragraphFont = 15;
    const paragraphWordSpacing = 0.9;
    const bulletColor = "#0071C1";
    const bulletPoints = [
        "No Email Needed",
        "No Credit Card Needed",
        "No Sign Up Needed",
        "Completely Free",
    ];

    const titleStyle = {
        fontSize: titleFontSize,
        color: titleColor,
    };

    const paragraphStyle = {
        wordSpacing: paragraphWordSpacing,
        textAlign: "justify",
        fontFamily: "sans-serif", // Change the font family to sans-serif
        fontSize: paragraphFont, // Specify the desired font size
        lineHeight: "1.5",
    };

    const bulletPointStyle = {
        color: bulletColor,
        // fontWeight: "bold",
    };

    const buttonColor = "black";
    const buttonHoverColor = "#0071C1";
    const buttonText = "SEE IT IN ACTION";
    const buttonLink = "https://www.ejworkload.com/app";
    const buttonFontSize = "15px";

    const containerStyle = {
        background: "white",
        padding: "20px",
    };
    const buttonContainerStyle = {
        display: "flex",
        justifyContent: "center",
        marginTop: "20px", // Adjust the margin top as needed
    };

    return (
        <div style={containerStyle} className="leftSide">
            <div className="para">
                <h1 style={titleStyle}>{title_1}</h1>
                <p style={paragraphStyle}>{paragraphText}</p>
            </div>
            <div className=" keyPoint">
                <ul style={{ listStyleType: "none", padding: 0 }}>
                    {bulletPoints.map( ( point, index ) => (
                        <li key={index} style={{ display: "flex", alignItems: "center" }}>
                            <FontAwesomeIcon
                                icon={faCheck}
                                style={{ color: bulletColor, marginRight: "5px" }}
                            />
                            <span style={{ fontWeight: "bold", color: "black" }}>{point}</span>
                        </li>
                    ) )}
                </ul>
           
                {/* <h1 style={titleStyle} className="text-center">{title_2}</h1> */}
                <div style={buttonContainerStyle}>
                    <Button
                        text={buttonText}
                        link={buttonLink}
                        color={buttonColor}
                        hoverColor={buttonHoverColor}
                        fontSize={buttonFontSize}
                        handleRedirect={handleRedirect}
                    />
                </div>
            </div>
        </div>
    );
};

export default MiddleLeftContent;
