import React, { useEffect } from "react";
import useMyWorkLoad from "../my_workload/useMyWorkLoad";

const TicketNumber = ( { categoryId, id } ) => {
    const { getCategoryPrefix, categoryPrefix } = useMyWorkLoad();
    useEffect( () => {
        getCategoryPrefix( categoryId );
    },[] );

    return <div>{`${categoryPrefix}${id}`}</div>;
};

export default TicketNumber;