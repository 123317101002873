import React from 'react';
import { Fragment } from "react";
import util from '../../../../../utils/miniUtils';

export default function FieldValue( { value, name, extraIcon = null, country } ) {
    
    return (
        <Fragment>
            <div className="row">
                <div className="col-md-6"><b>{name}</b></div>
                <div className="col-md-6">
                    <b className="text-left">
                        {
                            extraIcon ?
                                util.marginColors( parseFloat( util.printValue( value ) ) )
                                :
                                util.currencyFormat( value, country )
                        }
                    </b>
                </div>
            </div>
            <hr className="m-1" />
        </Fragment>
    );
}
