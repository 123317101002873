import  { useState } from 'react';
import util from '../../utils/miniUtils';
function useAllGoalsCompetencyRequest() {
    const [list, setList] = useState( [] );
    const [form, setForm] = useState( {} );
    const [loading, setLoading]=useState( false );
    const [id, setId] = useState();
    const [option, setOption] = useState();
    const [period,setPeriod]=useState( [] );
    const [groupList, setGroupList] = useState( [] ); 

    function handleRichTextChange( e ) {
        //const value = e.toString("html");
        const value = e.target.value;
        setForm( { ...form,['description']: value } );
    }

    function handleMultiSelect( e ){
        const { value, checked } = e.target;
        console.log( value,checked );
        if ( value == "All" ) {
            setGroupList( groupList.map( item => {
                if ( checked ) {
                    return {
                        ...item,
                        checked: checked
                    };
                } else {
                    return {
                        ...item,
                        checked: checked
                    };
                }
            } ) );
        }
        else {
            setGroupList( groupList.map( item => {
                if( item.id == value ) {
                    return {
                        ...item,
                        checked: checked
                    };
                } else {
                    return {
                        ...item,
                    }; 
                }
            } ) );
        }
    }
    //create goals modal pop to show all groups fresh list
    const getGroupList = async() =>{
        try {
            const request = await util.axios.get( '/groups/simple-list' );
            const { error, msg, data } = request.data;
            if ( error ) throw msg;
            setGroupList( data.map( ( item )=>{
                return{
                    ...item,
                    name:item.itemName 

                };} ) );
        } catch ( error ) {
            util.handleError( error );
        }

    };
    const getGroupFromGoals = async ( id ) => {
        try {
            const request = await util.axios.get( `/get_groups_by_goal_competency/${id}` );
            const { error, msg, data } = request.data;
            if ( error ) throw msg;
            setGroupList( data );
        } catch ( error ) {
            util.handleError( error );
        }
    };
    
    const updateApplicableGroup = async ( id ) => {
        try {
            let temp = { groupList: groupList };
            const request = await util.axios.put( `/update_groups_by_goal_competency/${id}`, temp );
            const { error, msg } = request.data;
            if ( error ) throw msg;
        } catch ( error ) {
            util.handleError( error );
        }
    };


    const createaNewGoalOrCompetency = async ( type=1 ) => {
        try {
            setLoading( true );
            let temp = { ...form };
            temp.type = type;
            temp.applicable_group = groupList;
            const request = await util.axios.post( `${util.url}/goals`, temp );
            const { error, msg, data } = request.data;
            if ( error ) throw msg;
            util.toast().success( msg );
            setLoading( false );
            getAllGoalsOrCompetencies( type );
        } catch ( error ) {
            // setLoading( false );
            util.handleError( error );
        }
    };
    
    const getAllGoalsAndCompetencies = async ( ) => {
        try {
            // setLoading( true );
            const request = await util.axios.get( `${util.url}/goals` );
            const { error, data, msg } = request.data;
            if ( error ) throw msg;
            setList( data );
            setGroupList( groupList );
        } catch ( error ) {
            util.handleError( error );
        }
    };
    const getAllGoalsOrCompetencies = async ( option=1 ) => {
        try {
            // setLoading( true );
            setList();
            const request = await util.axios.get( `${util.url}/goals_and_competencies?type=${option}` );
            const { error, data, msg, groupList } = request.data;
            if ( error ) throw msg;
            setList( data );
            setGroupList( groupList );
        } catch ( error ) {
            util.handleError( error );
        }
    };
    const getaSingleGoal = async ( id ) => {
        try {
            // setLoading( true );
            const request = await util.axios.get( `${util.url}/goals/${id}` );
            const { error, data, msg , groupList } = request.data;
            if ( error ) throw msg;
            setList( data );
            setGroupList( groupList );
        } catch ( error ) {
            util.handleError( error );
        }
    };
    const editaSingleGoal = async ( id ) => { //appraisal_item_id
        try {
            // setLoading( true );
            const request = await util.axios.put( `${util.url}/goals/${id}`, form );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            util.toast().success( msg );
            getAllGoalsOrCompetencies( option );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const removeTargetItem = async ( id ) => {
        try {
            const request = await util.axios.put( `${util.url}/delete/goals/${id}` );
            const { error, msg } = request.data;
            getAllGoalsOrCompetencies( option );
            util.toast().success( msg );
            if ( error ) throw msg;
        } catch ( error ) {
            util.handleError( error );
        }
    };

   

    const getAllPeriods = async (  ) => {
        try {
            // setLoading( true );
            setPeriod();
            const request = await util.axios.get( `${util.url}/get_all_periods` );
            const { error, data, msg } = request.data;
            if ( error ) throw msg;
            setPeriod( data );
        } catch ( error ) {
            util.handleError( error );
        }
    };
    const removePeriod = async () => {
        try {
            const request = await util.axios.put( `/delete/period/${id}` );
            const { error, msg } = request.data;
            getAllPeriods();
            util.toast().success( msg );
            if ( error ) throw msg;
        } catch ( error ) {
            util.handleError( error );
        }
    };
    function handleChanges( e ) {
        const { name, value } = e.target;
        setForm( { ...form, [name]: value } );
    }
   
    return (
        {
            list,  setList,
            getAllPeriods,
            period,setPeriod,
            form, setForm,
            loading,setLoading,
            id, setId,
            option,setOption,
            groupList,setGroupList,
            createaNewGoalOrCompetency,
            getAllGoalsOrCompetencies,
            getAllGoalsAndCompetencies,
            getaSingleGoal,
            editaSingleGoal,
            handleChanges,
            handleRichTextChange,
            removeTargetItem,
            removePeriod,
            getGroupFromGoals,
            handleMultiSelect,
            updateApplicableGroup,
            getGroupList 
        }
    );
}

export default useAllGoalsCompetencyRequest;




