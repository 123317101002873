import React,{ Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Badge, Collapse, Grid } from "@material-ui/core";
import util from "../../../utils/miniUtils";
import FileAttachment from "../Upload/FileAttachment";


const ExAchievementsAccordion = ( { item,appraisal_id,role,status } ) => {
    const [state, setState] = useState( {
        expanded: false,
        item: item,
        showSaveButton: false,
    } );
    const[loading,setLoading]=useState( false );
    const [exceptionalAchievements,setExceptionalAchievements]=useState( { attachments: [] } );
    const [exAch,setExAch]=useState( {} );
    // const [attachments,setAttachments]=useState( [] );
   


    

    const expand = () => setState( { ...state, expanded: !state.expanded } );

    //request for creating exceptional achievements
    const createExceptionalAchievements = async ( appraisal_id ) => {
        try {
           
            setLoading( true );
            const request = await util.axios.put( `create_exceptional_achivement/${appraisal_id}`,exceptionalAchievements );
            const { error, msg, exceptionalAchivement } = request.data;
            setExceptionalAchievements( ( prev )=> {
                return  { exceptional_achivement:exceptionalAchivement,attachments:prev.attachments }; } );
            if ( error ) throw msg;
            console.log( exceptionalAchievements );
            setState( {
                ...state,showSaveButton: false
            } );
            setLoading( false );

        } catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }
        
    };


    //request for get all exceptional achievemnts
    const getExceptionalAchievements = async () => {
        try {
            setLoading( true );
            const request = await util.axios.get( `get_exceptional_achivement/${appraisal_id}` );
            const { error, data,msg } = request.data;
            if ( error ) throw msg;
            setExAch( data );
            console.log( data );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };

    //reload attachments whenever we refresh the page
    const reloadAttachmentsOnItem = ( attachments ) => {
        // console.log( "Make another request to get attachments" );
        // uploadAttachments();
        let temp = { ... exceptionalAchievements };
        temp.attachments = attachments;
        setExceptionalAchievements( Object.assign( {}, temp ) );
        console.log( temp );
    };

    //request for delete exceptional achievemnt attachments
    const deleteAttachment = async ( attachment_id ) => {
        try {
            const request = await util.axios.delete( `/exceptional_achivement/delete/file/${attachment_id}` );
            const { error, msg, attachments } = request.data;
            if ( error ) throw msg;
            reloadAttachmentsOnItem( attachments );
        } catch ( error ) {
            util.handleError( error );
        }
    }; 

    // request for delete exceptional achievement attachments
    const getExceptionalAttachments = async () => {
        // After component mount, make the request to get the "positions of the current requirement"
        try {
            setLoading( true );
            const request = await util.axios.get( `exceptional_achivement/get/file/${appraisal_id}` );
            const { error, attachments, msg } = request.data;
            if ( error ) throw msg;

            setExceptionalAchievements( ( prev )=> {
                return  { ...prev,attachments:attachments }; } );
            console.log( exceptionalAchievements );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };

    useEffect( ()=>{
        getExceptionalAchievements();
        getExceptionalAttachments();

    },[] );

    const handleChange=( e )=>{
        const { name,value }=e.target;
        setExceptionalAchievements( ( prev )=>{

            return  { ...prev,[ name]:value }; } );
        setState( {
            ...state,showSaveButton: true
        } );

    };
    // console.log( exceptionalAchievements );
    if ( loading ) return util.LOADING_SCREEN( loading );
    return (
        <Fragment>
            <div className="p-4 mb-3 border-bottom">
                <div onClick={expand}  className="d-flex justify-content-between isPointer">
                   
                    <div className="mb-3" style={{ flex:'10' }}>
                        <span className="position-absolute">
                            <FontAwesomeIcon size="2x" className="my-icon" icon={state.expanded ? faCaretUp: faCaretDown} />
                        </span>  
                        <b className="ml-4">
                    ExceptionalAchievements
                        </b>
                    
                    </div>
                </div>
                <Collapse in={state.expanded}>
                    <div className="p-1 border ap_frame_border mt-3">
                   
                        <div className="container-fluid">
                            <Grid container spacing={1} className="mt-3">
                                <Grid item xs={12} md={12} lg={1}>
                                    <b>Description:</b>
                                </Grid>
                                {( role.includes( 'HR' ) && status === 17  ) &&
                                <>
                                    <Grid item xs={12} md={12} lg={9} className="ml-3">
                                        {/* {gc_desc} */}
                                        <textarea 
                                            className="form-control"  
                                            placeholder="Write Exceptional Achievements" 
                                            name='exceptional_achivement' 
                                            onChange={handleChange}
                                            value={exceptionalAchievements.exceptional_achivement || ""}
                                    
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={1} className="ml-2"><FileAttachment appraisal_id={appraisal_id} reloadAttachmentsOnItem={reloadAttachmentsOnItem} /></Grid></>
                                
                                }
                            
                                {( ( role === "employee" && status === 18  ) || ( role.includes( 'HR' ) && status === 18  ) ) &&
                            <Grid item xs={12} md={12} lg={10} className="ml-3">
                                {/* {gc_desc} */}
                                <textarea 
                                    className="form-control"  
                                    name='exceptional_achivement' 
                                    onChange={handleChange}
                                    value={exAch.exceptional_achivement || ""}
                                    disabled
                                />
                            </Grid>}
                            
                            </Grid>
                            <div>
                                {
                                    state.showSaveButton ?
                                        <Fragment>
                                            <button onClick={()=>createExceptionalAchievements( appraisal_id )} className="btn btn-sm btn-primary m-2 float-right">
                                    Save
                                            </button>
                                        </Fragment>
                                        : null
                                }
                    
                            </div>

                      
                        </div>
                        {( role.includes( 'HR' ) && status === 17 && ( exceptionalAchievements.attachments && exceptionalAchievements.attachments.length>0 ) ) ?
                            ( 
                                <Grid container spacing={2} className="mt-3">
                                    <Grid item xs={12} md={12} lg={1} className="ml-1"> <b>Attachments:</b></Grid>
                                   
                                    <Grid item xs={12} md={12} lg={9} className="ml-3">
                                        {
                            exceptionalAchievements.attachments?.map( ( a,i )=> {
                                const { appraisal_id, name } = a;
                    
                                return (
                                    <Badge key={i} className="ml-3 isPointer customBadge">
                                        <a href={`${util.url}/exceptional_achivement/${appraisal_id}/${name}`} target="_blank" rel="noopener noreferrer">{a.name}</a>
                                        <span className="p-1 isPointer" onClick={()=>deleteAttachment( a.attachment_id )}><FontAwesomeIcon icon={faTimes} className="text-danger mb-1" /></span>
                                    </Badge>
                                );
                            } )
                                        }
                                    </Grid>
                    
                                </Grid> ) : "" }

                            

                        { ( role === "employee" && status === 18 && exceptionalAchievements.attachments.length > 0 ) ?
                            ( 
                                <Grid container spacing={1} className="mt-2">

                                    <Grid item xs={12} md={12} lg={1} className="ml-1">
                                        <b>Attachments:</b>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={9} className="ml-3">
                                        {
                              exceptionalAchievements.attachments?.map( ( a,i )=> {
                                  const { appraisal_id, name } = a;
                                  return (
                                      <Badge key={i} pill bg="secondary" className="ml-3 isPointer customBadge">
                                          <a href={`${util.url}/exceptional_achivement/${appraisal_id}/${name}`} target="_blank" rel="noopener noreferrer">{a.name}</a>
                                      </Badge>
                                      
                                  );
                              } )
                                        }
                                    </Grid>
                       
                                </Grid> ) : "" }
                   
                    </div>
                </Collapse>
            </div>
        </Fragment>
    );
};

export default ExAchievementsAccordion;
