import  { useState } from "react";
import util from "../../utils/miniUtils";

export default function useDropDownOptions(  ) {
    
    const [show, setShow] = useState( false );
    const [reasons, setReasons] = useState( [] );

    const [state, setState] = useState( {
        status: null,
        data: null,
    } );

    const showModal = () => setShow( true );

    const hideModal = () => setShow( false );
    
    const openModal = ( status, data ) => {
        setState( { status:  status, data: data } );
        showModal();
    };
    
    const textToInt = ( text ) =>{
        switch ( text ) {
            case "New":     return 0;
            case "Active":  return 1;
            case "Hold":    return 2;
            case "Closed":  return 3;
            case "Filled":  return 4;
            case "Hot":     return 5;
            case "Dropped": return 6;
        }
    };
    
    async function changeStatus( state, reloadTableData ) {
        try {
            const { data, status, sendEmail } = state;
            let jobsId = [];
            let reasonsId = [];

            jobsId = data.map( d => d.id );
            reasons.map( d => {
                if ( d.checked ) reasonsId.push( d.id );
            } );
            const d = { jobs: jobsId, newStatus: status, sendEmail, reasonsId };
            const request = await util.axios.put( `/job/change_job_status`, d );
            const { error, msg } = request.data;
            if ( error ) return msg;
            util.toast().success( msg );
            hideModal();
            reloadTableData();
        }catch ( e ){
            util.handleError( e );
        }
    }
    
    async function getDroppedReasons( ) {
        try {
            const request = await util.axios.get( `/catalog/data/list/dropped_reasons` );
            const { error, msg, data } = request.data;
            if ( error ) return msg;
            setReasons( data );
        }catch ( e ){
            util.handleError( e );
        }
    }

    const onSelectCheckBox = ( e, index ) => {
        let temp = [...reasons];
        temp[index].checked = e.target.checked;
        setReasons( Object.assign( [], temp ) );
    };

    return {
        show, state,
        showModal, hideModal,
        openModal,
        textToInt, reasons, onSelectCheckBox,
        changeStatus, getDroppedReasons

    };
}