import React, { Fragment, useEffect, useState } from "react";
import util from "../../../../utils/miniUtils";
import Select from "@material-ui/core/Select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import SweetAlert from "react-bootstrap-sweetalert";

const user = util.user;

function DrawBackPosition( { refreshTable, jd, setIsDrawingBack, closeModal } ) {
    let jobData = jd;
    // console.log( jd );
    // This component have the "form" to let the talent to draw back hes request for the position
    const [reason_draw_back, setReason_draw_back] = useState( "" );
    const [show, setShow] = useState( false );
    const [loading, setLoading] = useState( false );
    const [reasons, setReasons] = useState( [] );
	
    useEffect( () => {
        getReasons().then( null );
    },[] );
	
    function onChangeHandler( e ) {
        const{ value } = e.target;
        setReason_draw_back( value );
    }
	
    async function drawBackPosition() {
        try {
            setLoading( true );
            let data = { reason: reason_draw_back };
            const request = await util.axios.put( `${util.url}/jobs/talent/draw_back/${jobData.id}/${user.candidate_id}`, data );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            util.toast().info( msg );
            setShow( false );
            setShow( false );
            setIsDrawingBack( false ); // Hide modal
            refreshTable();
            closeModal(); // Hide all modals
        }catch ( error ) {
            setShow( false );
            util.handleError( error );
        }
    }
	
    const getReasons = async () => {
        try {
            setLoading( true );
            const request = await util.axios.get( `${util.url}/withDrawReasons/list` );
            const { error, msg, reasons } = request["data"];
            if ( error ) throw msg;
            setReasons( reasons );
            setLoading( false );
        }catch ( e ) {
            setLoading( false );
            util.handleError( e );
        }
    };
	
    function showAlert() {
        if ( reason_draw_back === "" ) {
            util.toast().error( "Select a valid reason" );
        } else{
            setShow( true );
        }
    }
	
    return(
        <Fragment>
            <p>Please select a reason for withdraw the position</p>
            <Select
                fullWidth
                native
                name="reason_draw_back"
                value={reason_draw_back}
                onChange={onChangeHandler}
            >
                <option value=""> -- </option>
                {reasons.map( ( r,i ) => {
                    return <option value={r.reason} key={i}>{r.reason}</option>;
                } )}
            </Select>
            <hr/>
            <button className="btn btn-sm btn-danger float-right"
			        onClick={()=> showAlert()}
            >
                <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
				Withdraw
            </button>
            <button className="btn btn-sm btn-default float-right mr-2"
			        onClick={()=>setIsDrawingBack( false )}
            >
				Cancel
            </button>
			
			
            <SweetAlert
                show={show}
                warning
                showCancel
                confirmBtnText="Yes!"
                confirmBtnBsStyle="danger"
                title="Are you sure?"
                onConfirm={()=>drawBackPosition()}
                onCancel={()=>setShow( false )}
                focusCancelBtn
            >
				You will not be able to apply to this Job Position
            </SweetAlert>
            {util.LOADING_SCREEN( loading )}
        </Fragment>
    );
}

export default DrawBackPosition;