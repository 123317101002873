import { Store } from "pullstate";

export const TalentStore = new Store( {
    talent: null
    // 	{
    // 	address: null,
    // 	city: "",
    // 	country: "",
    // 	createdAt: "",
    // 	createdBy: null,
    // 	ctc: null,
    // 	currency: "",
    // 	email: "",
    // 	id: null,
    // 	latest_desc_updated: "",
    // 	latest_updated: "",
    // 	link_sent: null,
    // 	link_sent_date: "",
    // 	location: "",
    // 	name: "",
    // 	notes: [],
    // 	onBoardingDocuments: [],
    // 	phone_home: null,
    // 	phone_mobile: "",
    // 	phone_other: null,
    // 	platform: null,
    // 	platform_other: null,
    // 	profile_picture: null,
    // 	rating: null,
    // 	relocation: 1,
    // 	resume: null,
    // 	salary_mode: null,
    // 	salary_type: null,
    // 	skills: [],
    // 	skillsSet: "",
    // 	state: "",
    // 	test_score: null,
    // 	updatedAt: "",
    // 	url: null,
    // 	wh_desc: "",
    // 	wh_position: "",
    // 	workHistory: [],
    // 	working_status: null,
    // 	working_status_other: null,
    // 	zip_code: null,
    // }
} );