import React, { useState } from 'react';
import Tooltip from "@material-ui/core/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCaretDown,
    faCaretLeft,
} from "@fortawesome/free-solid-svg-icons";

function TableResumeInfo ( { title, data } ) {
    const [show, setShow] = useState( false );
    // console.log(typeof(data))
    return (
        <div className="col-md-4 mt-2">
            <div className="card p-2 addShadowBox">
                <div className="d-flex justify-content-between isPointer" onClick={()=>setShow( !show )}>
                    <div className="">{title}</div>
                    <div className="isCursor text-dark"><FontAwesomeIcon  icon={show ? faCaretDown: faCaretLeft} /></div>

                </div>
                {show ?
                    <table className="table table-hover">
                        <thead>
                        </thead>
                        <tbody>
                            {data && data.map( ( d, i ) =>{
                                return <tr key={i}>
                                    <td className="font-weight-lighter">{
                                        d["userType"] ?
                                            d["userType"] === "client" ?
                                                <Tooltip title="Client">
                                                    <span className="text-primary">{d.title}</span>
                                                </Tooltip>
                                                :
                                                <Tooltip title="Partner">
                                                    <span className="text-danger">{d.title}</span>
                                                </Tooltip>

                                            : d.title
                                    }</td>
                                    <td>{d.total}</td>
                                </tr>;
                            } )}
                        </tbody>
                    </table>:""}
            </div>
        </div>
    );
}

export default TableResumeInfo;