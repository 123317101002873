import { createTheme, MuiThemeProvider, Tooltip } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';

const ParentTicketSearch = ( { value, isChildTicket, setData, workloadSimple } ) => {
    const [searchInput, setSearchInput] = useState( "" );
    const [workloads, setWorkloads] = useState( [] );
    const [displayValue, setDisplayValue] = useState( "" );
    const wrapperRef = useRef( null );

    // Anchor and open variable for the popover. 
    const [anchorEl, setAnchorEl] = React.useState( null );
    const open = Boolean( anchorEl );

    useEffect( () => {
        document.addEventListener( "click", handleClickOutside, true );
    }, [] );

    const handleClickOutside = ( e ) => {
        if( !wrapperRef?.current?.contains( e.target ) ) {
            handleCancel();
        }
    };

    const handleClick = ( event ) => {
        setAnchorEl( event.currentTarget );
    };

    const handleSearchChange = ( e ) => {
        setSearchInput( e.target.value );
        if ( e.target.value.length > 0 ) {
            const newWorkload = workloadSimple.filter( ( item ) =>
                item.short_description.toLowerCase().match( e.target.value.toLowerCase() )
            );
            setWorkloads( newWorkload );
        } else {
            setWorkloads( [] );
        }
    };

    const handleSelect = ( e ) => {
        const { value } = e.target;
        let selectedWorkload = workloadSimple.filter( item => item.id === value );
        setDisplayValue( selectedWorkload[0].id + ". " + selectedWorkload[0].short_description );
        setData( ( prev ) => {
            return {
                ...prev,
                parent_ticket_id: value
            };
        } );
        handleCancel();
    };

    const handleClear = () => {
        setDisplayValue( "" );
        setData( ( prev ) => {
            return {
                ...prev,
                parent_ticket_id: null
            };
        } );
    };

    const handleCancel = () => {
        setAnchorEl( null );
    };

    const theme = createTheme( {
        overrides: {
            MuiTooltip: {
                tooltip: {
                    maxWidth: "250px",
                    fontSize: "0.85em",
                }
            }
        }
    } );

    function editValue() {
        if( open ) {
            return <div className='d-flex wl_parentTicketPopover' ref={wrapperRef}>
                <div className='w-100 p-1'>
                    <div className="d-flex position-relative">
                        <input
                            type="search"
                            className="form-control w-100"
                            placeholder="Search here"
                            onChange={handleSearchChange}
                            value={searchInput}
                            autoFocus
                        />
                        <span className='btn btn-info' onClick={handleClear}>Clear</span>
                    </div>
                </div>
                <div className="wl_parentTicketSearchResult">
                    <div>
                        <span>id |</span>
                        <span>Title</span>
                    </div>
                    {( workloads && workloads.length > 0 ) && workloads.map( item => <li key={item.id} value={item.id} className={item.id == Number( value ) ? "selected" : "notSelected"} onClick={handleSelect}>{item.id}.{item.short_description}</li> )}
                    {!searchInput && workloadSimple.map( item => <li key={item.id} value={item.id} className={item.id == Number( value ) ? "selected" : "notSelected"} onClick={handleSelect}>{item.id}.{item.short_description}</li> )}
                </div>
            </div>;
        }
    }

    useEffect( () => {
        if( workloadSimple.length > 0 && value ) {
            const currentValue = workloadSimple.find( item => item.id === value );
            setDisplayValue( currentValue.id + ". " + currentValue.short_description );
        }
    },[workloadSimple, value] );

    useEffect( () => {
        if( !value ) {
            setDisplayValue( '' );
        }
    }, [value] );

    return <>
        <>
            {editValue()}
            <div className={`w-100${displayValue ? ' parentWorkload' : ''}`} onClick={handleClick}>
                {isChildTicket ? ( displayValue
                    ? <span onClick={handleClick}>{displayValue}</span>
                    :  !open ? <button className="btn btn-outline-primary w-100">Select Parent Ticket</button> : "" ) 
                    : ""}
            </div>
        </>
    </>;
};

export default ParentTicketSearch;