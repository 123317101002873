import React, { Fragment, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import util from "../../../utils/miniUtils";
import { faExclamationCircle, faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@material-ui/core";

const ONLY_NEW_JOBS = "1";
const ALL_JOBS      = "2";
const SELECTED_JOBS = "3";

export default function ModalChangeConfirmation( { show, handleClose, updateChanges }  ) {
    
    const [ optionSelected, setOptionSelected ] = useState( ONLY_NEW_JOBS );
    const [ jobs, setJobs ] = useState( [] );
    const [ jobsBase, setJobsBase ] = useState( [] );
    const [ extraMsg, setExtraMsg ] = useState( false );
    const [ loading, setLoading ] = useState( false );

    
    const selectedRadio = ( e ) => {
        setOptionSelected( e.target.value );
        setExtraMsg( false );
    };
    
    const getJobsSimple = async () => {
        try {
            setLoading( true );
            const request = await util.axios.get( `jobs/list-simple` );
            const { error, msg, data } = request.data;
            if ( error ) throw msg;
            setTimeout( ()=> {
                setLoading( false );
                setJobs( Object.assign( [], data ) );
                setJobsBase( Object.assign( [], data ) );
            }, 500 );
        }catch ( e ) {
            util.handleError( e );
        }
    };

    const quickSearch = async ( e ) => {
        let temp = [];
        let value = e.target.value;
        if ( value === "" ){
            setJobs( jobsBase );
            return;
        }

        for ( let i = 0; i < jobsBase.length; i++ ) {
            if ( jobsBase[i].job_title.toLowerCase().includes( value.toLowerCase() ) ){
                temp.push( jobsBase[i] );
            }
        }

        setJobs( temp );
    };
    
    const checkJob = ( e ) => {
        const { value, checked } = e.target;
        let t = [...jobsBase];
        // eslint-disable-next-line
        t.map( ( s, i ) => {
            if ( s.id === parseInt( value ) ) t[i].checked = checked;
        } );
        setJobsBase( Object.assign( [], t ) );
    };
    
    const countSelected = () => {
        let a = jobsBase.filter( j => j.checked );
        return a.length;
    };
    
    useEffect( () => {
        if ( optionSelected === SELECTED_JOBS )
            getJobsSimple().then( null );
    }, [ optionSelected ] );

    useEffect( () => {

    },[jobs] );

    return (
        <Fragment>
            <Modal show={show} onHide={handleClose}>
                <Modal.Body>
                    <div className="p-2">
                        {/* SUPER WARNING */}
                        <h2 className="text-center">Are you really sure about changing this information?</h2>
                        <h1 className="text-center fa-icon-size"><FontAwesomeIcon icon={ faExclamationCircle } /></h1>
                        <h5 className="text-center"><u>This update will change information permanent, can't undo this action</u></h5>
                        {/*  CHECKBOXES OF OPTIONS  */}
                        <hr/>
                        <h6>Select an action</h6>
                        <div className="btn-group-vertical">
                            <label htmlFor="typeOfChange1">
                                <input type="radio" className="mr-2" onChange={selectedRadio} name="typeOfChange" checked id="typeOfChange1" value={ONLY_NEW_JOBS}/>
                                Apply only to the new jobs
                            </label>
                            <label htmlFor="typeOfChange2">
                                <input disabled type="radio" className="mr-2" onChange={selectedRadio} name="typeOfChange" id="typeOfChange2" value={ALL_JOBS}/>
                                Apply to all the jobs
                            </label>
                            <label htmlFor="typeOfChange3">
                                <input disabled type="radio" className="mr-2" onChange={selectedRadio} name="typeOfChange" id="typeOfChange3" value={SELECTED_JOBS}/>
                                Apply to only selected jobs
                            </label>
                        </div>
                        {
                            optionSelected === SELECTED_JOBS ?
                                loading ?
                                    <div className="text-center mt-2">
                                        <CircularProgress />
                                        <div className="font-size-sm">Loading Jobs</div>
                                    </div>
                                    :
                                    <Fragment>
                                        <input type="search" placeholder="Search Jobs" className="form-control mb-2" onChange={quickSearch} />
                                        <div className="font-size-sm">
                                            <span>Selected: <b>{countSelected()}</b></span> <span>Results: <b>{jobs.length}</b></span>
                                        </div>
                                        <div className="jobSimpleSelection">
                                            {
                                                jobs.map( ( j, i ) => {
                                                    return (
                                                        <div key={i} className="d-flex justify-content-start ctc_option_hover align-content-center pl-1">
                                                            <div><input checked={j.checked ? j.checked: false } onChange={checkJob} value={j.id} id={`jobCKX_${i}`} type="checkbox" className="mr-2"/></div>
                                                            <div>{util.jobStatusOnlyIcons( j.status, false )}</div>
                                                            <div><label htmlFor={`jobCKX_${i}`}> {j.id} - {j.job_title}</label></div>
                                                            <div>
                                                                <a href={`/jobs/position/detail/${j.id}`} target="_new">
                                                                    <FontAwesomeIcon icon={faLink} className="ml-2" />
                                                                </a>
                                                            </div>
                                                        </div>
                                                    );
                                                } )
                                            }

                                        </div>
                                    </Fragment>
                                :
                                ""
                        }
                    </div>
                    <div className="d-flex justify-content-center">
                        <button className="btn btn-sm btn-default" onClick={handleClose}>
                            Close
                        </button>
                        {
                            optionSelected ?
                                <Fragment>
                                    {
                                        extraMsg ?
                                            <button className="btn btn-sm btn-warning" onClick={updateChanges}>
                                                {/* eslint-disable-next-line react/no-unescaped-entities */}
                                                I'm 100% positive
                                            </button>
                                            :
                                            <button className="btn btn-sm btn-primary" onClick={()=>setExtraMsg( true )}>
                                                Save Changes
                                            </button>
                                    }
                                </Fragment>
                                :
                                ""
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </Fragment>
    );
}