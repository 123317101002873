import React from 'react';
import { Tab, Tabs } from "react-bootstrap";
import TalentInfo from './talent/TalentInfo';
import UserAccount from './account/UserAccount';
import Employee from "../../../shared/profile_component/employee/Employee";
import './styles.css';


const EmployeeModule = ( { setSection, employee_id, modal } ) => {
    
    return (
        <Tabs onSelect={( s )=>setSection( s )} defaultActiveKey="user_account" className="mb-3" unmountOnExit mountOnEnter>
            <Tab eventKey="user_account" title="User Account">
                <div className={`userAccount_div ${!modal ? "width-40": "w-100"}`}>
                    <UserAccount employee_id={employee_id}/>
                </div>
            </Tab>
            <Tab eventKey="talent" title="Talent">
                <TalentInfo employee_id={employee_id} modal={modal}/>
            </Tab>
            <Tab eventKey="employee" title="Employee">
                <div className={!modal ? "width-90": "w-100"}>
                    <Employee employee_id={employee_id} />
                </div>
            </Tab>
        </Tabs>
    );
};

export default EmployeeModule;