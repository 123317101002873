import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faSync } from "@fortawesome/free-solid-svg-icons";


const ResumeFile = ( { resume, onChangeResumeFile } ) => {

    return (
        <div className="form-group col-md-12 ">
            <a
                target="new"
                href={resume}
                className="btn btn-primary btn-sm col-md-6 longTextOnButton"
            >
                
                <FontAwesomeIcon icon={faDownload} /> Download
            </a>
            <button
                onClick={onChangeResumeFile}
                className="btn btn-default btn-sm col-md-6"
            >
                <FontAwesomeIcon icon={faSync} /> Upload New
            </button>
        </div>
    );
	
};

export default ResumeFile;