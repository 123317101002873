import  React, { useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import util from '../../../utils/miniUtils';
import usePipeView from './usePipeView';
import { Card } from "react-bootstrap";
import ManageWorkLoadDetail from '../../manage_workload/components/ManageWorkLoadDetail';

const PriorityView = ( { getPath } ) => {
    const {
        loading,
        workloadData,
        getWorkloadPriorityView,
        onDragEnd,
        workloadDetail,
        showWorkLoadDetail,
        closeWorkLoadDetail,
    } = usePipeView();

    useEffect( () => {
        getWorkloadPriorityView( getPath );
    },[] );

    if( !workloadData ){
        return null;
    }

    return (
        <>
            {
                workloadDetail.show &&
            <ManageWorkLoadDetail workload_id={workloadDetail.workload_id} handleClose={closeWorkLoadDetail} show={workloadDetail.show} permissionLevel={workloadDetail.permission_level} />
            }
            <div className='row mb-2 ml-1'>
                <div className='col'>
                    <DragDropContext onDragEnd={( result ) => onDragEnd( result, "priority", getPath )} direction="horizontal">
                        <div className="d-flex flex-nowrap wl-pipeviewContainer">
                            {workloadData.map( ( item, index ) => (
                                <div className="mr-2 d-flex flex-column wl-pipeviewColumn-Priority" key={index}>
                                    <span className='align-self-center'>
                                        <h4>{item.title}</h4>
                                    </span>
                                    <Droppable droppableId={item.title}>
                                        {( provided, snapshot ) => (
                                            <div
                                                {...provided.droppableProps} ref={provided.innerRef}
                                                className={`col border-right pipeColumn rounded m-1 d-flex flex-column ${snapshot.isDraggingOver && "wl-bg-D3E0EA"}`}>
                                                {item.listItems.map( ( a, index ) => (
                                                    <Draggable key={a.id} draggableId={String( a.id )} index={index}>
                                                        {( provided, snapshot ) => {
                                                            return ( <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}>
                                                                <Card className={`rounded mb-2 ${snapshot.isDragging && "wl-bg-F5F4F4"}`}>
                                                                    <Card.Body>
                                                                        <Card.Title className='d-flex justify-content-start'>
                                                                            <span>{a.short_description}</span>
                                                                        </Card.Title>
                                                                        <Card.Subtitle className="mb-2 text-muted d-flex justify-content-between">
                                                                            <span>{a.customer_name}</span>
                                                                            <span>{a.statusName}</span>
                                                                        </Card.Subtitle>
                                                                        <Card.Text>
                                                                            <span className="w-100 d-flex justify-content-start">
                                                                                <span className="text-primary wl_viewBtn" onClick={( e ) => showWorkLoadDetail( e, a.id )}>View</span>
                                                                            </span>
                                                                        </Card.Text>
                                                                    </Card.Body>
                                                                </Card>
                                                            </div> );
                                                        }}
                                                    </Draggable>
                                                ) )}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </div>
                            ) )}
                        </div>
                    </DragDropContext>
                </div>
            </div>
            {util.LOADING_SCREEN( loading )}
        </>
    );
};
 
export default PriorityView;