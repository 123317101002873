import { useState } from "react";
import util from "../../utils/miniUtils";
import { RECRUITER_ROLE } from "./../../../constants";

const useSimpleForm = () => {
    const [catalog, setCatalog] = useState( [] );
    const [show, setShow] = useState( { alert: false, modal: false, id: 0 } );

    async function getCatalog( catalogSelected ) {
        try {
            if ( catalogSelected === 0 ) return;
            const req = await util.axios.get(
              `${util.url}/assets_manager/catalog/${RECRUITER_ROLE}`
            );
            const { error, msg, data } = req.data;
            if ( error ) throw msg;
            setCatalog( data );
        } catch ( e ) {
            util.handleError( e );
        }
    }

    async function deleteItem( catalogSelected, item, reloadData ) {
        try {
            if ( catalogSelected === 0 ) return;
            const req = await util.axios.post(
        `${util.url}/assets_manager/delete_element/${catalogSelected}`,
        { item }
            );
            const { error, msg, data } = req.data;
            if ( error ) throw msg;
            reloadData( data );
        } catch ( e ) {
            util.handleError( e );
        }
    }

    async function recategorizeTypes( catalogSelected, item, reloadData ) {
        try {
            const t = { ...item, selectedReplace: show.selectedReplace };
            if ( catalogSelected === 0 ) return;
            const req = await util.axios.post(
        `${util.url}/assets_manager/delete_category/${catalogSelected}`,
        { item: t }
            );
            const { error, msg, data } = req.data;
            if ( error ) throw msg;
            setShow( { alert: false, modal: false, id: 0 } );
            reloadData( data );
        } catch ( e ) {
            util.handleError( e );
        }
    }

    return {
        catalog,
        setCatalog,
        show,
        setShow,
        getCatalog,
        deleteItem,
        recategorizeTypes,
    };
};

export default useSimpleForm;
