import React, { Fragment, useEffect, useState } from "react";
import util from "../../../../utils/miniUtils";
import ProfileComponent from '../../../../shared/profile_component/ProfileComponent';

const TalentInfo = ( { employee_id, modal } ) => {

    const [talent_id, setTalent_id] = useState( null );

    const getEmployeeTalentId = async () => {
        try {
            const request = await util.axios.get( `/employee/account/${ employee_id }` );
            const { error, msg, data } = request.data;
            if ( error ) throw msg;
            const { candidate_id } = data;
            setTalent_id( candidate_id );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    useEffect( ()=>{
        getEmployeeTalentId();
    },[] );

    if ( talent_id === null ) return "Loading...";

    return ( 
        <Fragment>
            <ProfileComponent talent_id={talent_id} hideEmployeeTab={true} modal={modal}/>
        </Fragment>
    );
};
 
export default TalentInfo;