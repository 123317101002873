import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";

const Button = ( { text, link, color, hoverColor, fontSize, handleRedirect } ) => {
    const handleRedirectZ = () => {
        window.open( link, "_blank" );
    };
    const buttonStyle = {
        display: "flex",
        alignItems: "center",
        backgroundColor: color,
        color: "white",
        padding: "10px",
        borderRadius: "5px",
        textDecoration: "none",
        transition: "background-color 0.3s",
        fontSize: fontSize,
    };

    const handleMouseEnter = ( e ) => {
        e.target.style.backgroundColor = hoverColor;
    };

    const handleMouseLeave = ( e ) => {
        e.target.style.backgroundColor = color;
    };

    return (
        <button
            style={buttonStyle}
            onClick={handleRedirectZ}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <FontAwesomeIcon icon={faPlayCircle} style={{ marginRight: "10px" }} />
            {text}
        </button>
    );
};

export default Button;
