import React, { useState, useEffect } from 'react';
import util from "../../../utils/miniUtils";
import { LoadingStore } from "../../../shared/LoadingStore";
import { ResumeStore } from "../talent_from_resume/ResumeStore";

const tableRef = React.createRef();

function useListOfCandidate() {
    // Flag for handling the visibility of the form.
    const [showForm, setShowForm] = useState( false );
    const [confirmCloseModal, setConfirmCloseModal] = useState( false );
    const [simpleSearch, setSimpleSearch] = useState( "" );
    const loading = LoadingStore.useState( state => state.isLoading );
   
    const [filterState, setFilterState] = useState( {
        showFilter: false,
        skills: [],
        job_title: [],
        country: [],
        state: [],
        city: [],
        resume: [],
        noticePeriod:[],
        min: 0,
        max: 0
    } );
    
    const fromResume = () => {
        setShowForm( true );
    };

    useEffect( () => {
    }, [loading] );
    
    const closeAndReload = ( closeModal ) => {
        // Send message to parent to update state of addModalShow to false
        closeModal();
        tableRef.current && tableRef.current.onQueryChange();
    };
    
    const closeFormAndReloadList = () => {
        // Send message to parent to update state of addModalShow to false
        setShowForm( false );
        tableRef.current && tableRef.current.onQueryChange();
    };
    
    const closeModal = ( closeAddModa ) => {
        // Send message to parent to update state of addModalShow to false
        closeAddModa();
    };

    // const searchData = ( d ) => {
    //     setListState( Object.assign( {}, d, { showFilter: true } ) );
    //     tableRef.current && tableRef.current.onQueryChange();
    // };
    const searchDataVersion2 = ( d ) => {
        setFilterState( Object.assign( {}, d, { showFilter: true } ) );
        tableRef.current && tableRef.current.onQueryChange( undefined );
    };
    
    

    const urlHandlerversion2 = ( query )  => {
        let orderby = ( query.orderBy ) ? query.orderBy.field:"name";
        let direction = ( query.orderDirection ==="" ) ? "asc": query.orderDirection;
        let search = ( query.search === "" ) ? "": filterState.showFilter ? "": query.search;
        setSimpleSearch( search );
        const {
            resume,
            skills,
            job_title,
            country,
            state,
            city, max, min, noticePeriod } = filterState;
        let q = `advanced_filter=${filterState.showFilter}&max=${max}&min=${min}&`;
        const fixCheckedToUrlEncode = ( data = [], field, especial ) => {
            let pairs = [];
            if( field === "skill_name" ) {
                data.map( item => {
                    pairs.push( `${ field }=` + encodeURIComponent( item.id ) );
                } );
            } else {
                data.map( ( value ) => {
                    if ( value.checked === true ) {
                    // console.log( value );
                        let val = value.value;
                        if ( especial ) {
                            val = val === "Yes" ? 1: 0;
                        }
                        pairs.push( `${ field }=` + encodeURIComponent( val ) );
                    }
                } );
            }
            q+= pairs.join( "&" ) + "&";
        };
        fixCheckedToUrlEncode( skills, 'skill_name' );
        fixCheckedToUrlEncode( job_title, 'wh_position' );
        fixCheckedToUrlEncode( country, 'country' );
        fixCheckedToUrlEncode( resume, 'resume' );
        fixCheckedToUrlEncode( noticePeriod, 'noticePeriod' );
        fixCheckedToUrlEncode( state, 'state' );
        fixCheckedToUrlEncode( city, 'city' );
        return `${util.url}/candidates/${query.page}/${query.pageSize}?orderby=${orderby}&direction=${direction}&search=${search}&${q}`;
    };
        
    const handleClose = () =>{
        setShowForm( false );
        ResumeStore.update( s => {
            s.data = {};
            s.file = null;
        } );
    };

    // useEffect( () => {
    // }, [loading] );

    return (
        {
            showForm, setShowForm, 
            confirmCloseModal, setConfirmCloseModal,
            simpleSearch, setSimpleSearch,
            fromResume,
            loading,
            closeAndReload,
            closeFormAndReloadList,
            closeModal,
            searchDataVersion2,
            // urlHandler,
            handleClose,filterState, setFilterState,urlHandlerversion2
        }
    );
}

export default useListOfCandidate;
