import React from "react";
import { Select } from "@material-ui/core";

const SelectSimple = ( props ) => {
    return (
        <Select
            value={props.value || ""}
            native={true}
            name={props.name}
            onChange={e =>{
                let t = e;
                t.rowData = props.rData;
                props.onChange( t );
            }} >
            <option value="0"/>
            {props.data.map( ( item, i ) => {
                return (
                    <option
                        key={i}
                        value={item[props.name]}
                    >
                        {item[props.name]}
                    </option>
                );
            } )}
        </Select>
    );
};

export default SelectSimple;
