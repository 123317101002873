import React, { Fragment, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import UploadResume from "./UploadResume";
import ReviewResumeInformation from "./ReviewResumeInformation";
import { ResumeStore } from "./ResumeStore";
import util from "../../../utils/miniUtils";
import {
    faArrowLeft,
    faCheckCircle, faDownload, faExclamationTriangle, faFileAlt,
    faFileArchive,
    faTimes,
    faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SweetAlert from "react-bootstrap-sweetalert";
import { Tooltip } from "@material-ui/core";
// import TESTPARSED from './resumes2.json'


function TalentFromResumeProcess( { handleClose, showForm, closeFormAndReloadList } ) {
    
    /////////////////////////// TEMPORAL
    // let array = []
    // TESTPARSED.forEach(t =>{
    //     let fixedData = util.parseOldJsonToNewJson(t)
    //
    //     array.push(fixedData)
    // })
    //
    // useEffect(() => {
    //     ResumeStore.update(s => {
    //         s.data =  array[0]
    //         s.datas =  array
    //         s.file =   {}
    //         s.files =   []
    //     })
    // },[])
    ///////////////////////////// TEMPORAL
    const [loading, setLoading] = useState( false );
    const [reviewedEmails, setReviewedEmails] = useState( [] );
    const [show, setShowModal] = useState( showForm );
    const [showSAlert, setSAlert] = useState( false );
    const [showDetail, setShowDetail] = useState( true );
    const [activeStep, setActiveStep] = useState( 1 );
    const [state, setState] = useState( {
        deleteResume:false,
        index:null
    } );
    const tabSection = ResumeStore.useState( s => s.section );
    const talents = ResumeStore.useState( s => s.datas );
    const files = ResumeStore.useState( s => s.files );
    
    useEffect( () => {
        setShowModal( showForm );
    },[showForm] );
    
    useEffect( () => {
        validateEmails().then( null );
        // showResumeDetail(talents[4],4)
        // eslint-disable-next-line
    },[activeStep, showDetail] );
    
    function closeModal() {
        setShowModal( false );
        handleClose();
    }
    
    function showToggleButton() {
        return (
            <div className="text-left">
                <span
                    onClick={()=> setShowDetail( !showDetail )}
                    className="likeLink"><FontAwesomeIcon icon={faArrowLeft} /> Show Talents</span>
            </div>
        );
    }
    
    function showResumeDetail( r, i ) {
        ResumeStore.update( s => {
            s.data =  Object.assign( {}, r );
            s.index = i;
        }, () => setShowDetail( false ) );
        
    }
    
    function isCompleted( d, opc ) {
        switch ( opc ) {
            case 1:
                if ( d.name === "" || d.email === "" || d.phone_mobile === "" ){
                    return <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />;
                }else{
                    return <FontAwesomeIcon icon={faCheckCircle} className="text-success" />;
                }
            case 2:
                if ( d.skills.length === 0 ){
                    return <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />;
                }else{
                    return <FontAwesomeIcon icon={faCheckCircle} className="text-success" />;
                }
            case 3:
                if ( d.workHistory.length === 0 ){
                    return <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />;
                }else{
                    return <FontAwesomeIcon icon={faCheckCircle} className="text-success" />;
                }
            default: break;
        }
    }
    
    function allReadyToSave() {
        let flag = true;
        for( let x = 0; x < talents.length; x++ ) {
            if ( talents[x].name !== "" &&
                talents[x].email !== "" &&
                util.validateEmail( talents[x].email )  &&
                talents[x].phone_mobile !== "" &&
                talents[x].skills.length !== 0 &&
                talents[x].workHistory.length !== 0 ){
                flag = true;
            }else{
                flag = false;
                break;
            }
        }
        // Check if some email is used
        for ( let i = 0; i < reviewedEmails.length; i++ ) {
            if ( reviewedEmails[i].status === 0 ){
                flag = false;
            }
        }
        return flag;
    }
    
    async function confirmTalentInformation() {
        try {
            // Create the new talent from the resume
            setLoading( true );
            // Send information already validated to the server
            const request = await util.axios.post( `${util.url}/candidates/new-candidate-from-resume`, { talents } );
            let { error, msg, ids } = request.data;
            if ( error ) throw msg;
            util.toast().success( msg );
            
            // After we create the talent, we upload the resume
            const data = new FormData();
            // data.append("file", files);
            for ( let i = 0; i < files.length; i++ ) {
                data.append( "file", files[i] );
                data.append( "ids", ids[i] );
            }
            const requestFile = await util.axios.post( `${util.url}/candidates/upload_resumes/`, data );
            let d  = requestFile.data;
            if ( d.error ) throw d.msg;
            // Reset store information
            ResumeStore.update( s => {
                s.data =  {};
                s.datas =  [];
                s.files = null;
            } );
            // Close modal
            setLoading( false );
            setSAlert( false );
            closeFormAndReloadList();
        } catch ( e ) {
            setLoading( false );
            setSAlert( false );
            util.handleError( e );
        }
    }
    
    function removeParsedResume( indexResume ) {
        let t = [...talents];
        let emails = [...reviewedEmails];
        let f = [...files];
        delete t[indexResume];
        delete emails[indexResume];
        delete f[indexResume];
        t = t.filter( val => val ); // reindexing the array
        emails = emails.filter( val => val ); // reindexing the array
        f = f.filter( val => val ); // reindexing the array
        setReviewedEmails( emails );
        setState( { ...state, index: null, deleteResume: false } );
        ResumeStore.update( s => {
            s.datas = t;
            s.files = f;
        } );
    }
    
    async function validateEmails() {
        try {
            // console.log( talents );
            // console.log( state );
            let emails =[];
            talents.forEach( t => {
                emails.push( { email: t.email, status: 1 } );
            } );
            const request = await util.axios.post( `${util.url}/candidates/validate_emails`, { emails } );
            let { error, msg, reviewedEmails } = request.data;
            if ( error ) throw msg;
            setReviewedEmails( reviewedEmails );
        }catch ( e ) {
            util.handleError( e );
        }
    }
    
    function readFileLInk( file ) {
        if ( !file ) return;
        let objectURL = URL.createObjectURL( file );
        return <a target="new" href={objectURL}><FontAwesomeIcon icon={faDownload} /></a>;
    }
    
    function emailValidation( status, email ) {
        switch ( status ) {
            case 0:
                return (
                    <div className="text-danger">
                        <b><u>{email}</u></b>
                        <Tooltip title="Email is already in use">
                            <span>
                                <FontAwesomeIcon className="ml-2" icon={faExclamationTriangle} />
                            </span>
                        </Tooltip>
                    </div>
                );
            case 1:
                return email;
            case 2:
                return(
                    <div className="text-danger">
                        <b><u>{email}</u></b>
                        <Tooltip title="Email is invalid">
                            <span>
                                <FontAwesomeIcon className="ml-2" icon={faExclamationTriangle} />
                            </span>
                        </Tooltip>
                    </div>
                );
            default: return null;
        }
    }
    
    return(
        <Modal
            size="lg"
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{activeStep === 1 ? "Upload Resume":" Review and Update Talent " + tabSection}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {activeStep === 1 ? <UploadResume setResumeInformation={()=> setActiveStep( 2 )} />:""}
                {activeStep === 2 ?
                    
                    <Fragment>
                        {showDetail ?
                            <Fragment>
                                <table className="table table-responsive alignMiddleTd">
                                    <thead>
                                        <tr className="text-center">
                                            <th/>
                                            <th><FontAwesomeIcon icon={faFileAlt} /></th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Personal Info</th>
                                            <th>Skills</th>
                                            <th>Work History</th>
                                            <th/>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {talents.map( ( r,i )=>{
                                            return(
                                                <tr
                                                    className={reviewedEmails[i]?.status === 0 ? "row-with-error":""}
                                                    key={i}>
                                                    <td className="p-1">
                                                        {talents.length > 1 ?
                                                            <Tooltip title="Remove this resume">
                                                                <span
                                                                    className="isPointer text-danger"
                                                                    onClick={()=> {
                                                                        setState( {
                                                                            ...state,
                                                                            index: i,
                                                                            deleteResume: true
                                                                        } );
                                                                    }
                                                                    }
                                                                >
                                                                    <FontAwesomeIcon icon={faTimes} />
                                                                </span>
                                                            </Tooltip>
                                                            :
                                                            null
                                                        }
                                                    </td>
                                                    <td className="p-1">
                                                        {readFileLInk( files[i] )}
                                                    </td>
                                                    <td>{r.name}</td>
                                                    <td>
                                                        {emailValidation( reviewedEmails[i]?.status, r.email )}
                                                    </td>
                                                    <td className="text-center">{isCompleted( r, 1 )}</td>
                                                    <td className="text-center">{isCompleted( r, 2 )}</td>
                                                    <td className="text-center">{isCompleted( r, 3 )}</td>
                                                    <td>
                                                        <button
                                                            onClick={()=> showResumeDetail( r,i )}
                                                            className="btn btn-sm btn-primary">
                                                            <FontAwesomeIcon icon={faFileArchive} className="mr-2" />
                                                        Details
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        } )}
                                    </tbody>
                                </table>
                                
                                <div className="text-center m-2">
                                    <button
                                        onClick={()=> setSAlert( true )}
                                        disabled={!allReadyToSave()}
                                        className="btn btn-sm btn-success">
                                        Create Talents
                                    </button>
                                </div>
                            
                            </Fragment>
                            :
                            showToggleButton()
                        }
                        {!showDetail ?
                            <Fragment>
                                <ReviewResumeInformation closeModal={()=> closeModal()} handleClose={handleClose} />
                            </Fragment>
                            :
                            ""
                        }
                    </Fragment>
                    :""}
            </Modal.Body>
            
            <SweetAlert
                show={showSAlert}
                warning
                showCancel
                confirmBtnText="Yes!"
                confirmBtnBsStyle="primary"
                title="Create a talent"
                onConfirm={() => confirmTalentInformation()}
                onCancel={() => setSAlert( false )}
                focusCancelBtn
            >
            </SweetAlert>
            {state.deleteResume ?
                <SweetAlert
                    show={state.deleteResume}
                    warning
                    showCancel
                    confirmBtnText="Yes!"
                    confirmBtnBsStyle="primary"
                    title="Remove resume file?"
                    onConfirm={() => removeParsedResume( state.index )}
                    onCancel={() => setState( { ...state, deleteResume:false } )}
                    focusCancelBtn
                >
                </SweetAlert>
                :
                ""
            }
            {util.LOADING_SCREEN( loading )}
        </Modal>
    );
}

export default TalentFromResumeProcess;

