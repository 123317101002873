import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import util from "../../../utils/miniUtils";
import {  TextField, Tooltip } from "@material-ui/core";

import PhoneInput from "react-phone-input-2";
import useModalNewCandidate from "./useModalNewCandidate";
import CountryAutoFill from "./CountryAutoFill";

function CreateNewCandidate ( { closeModalAndReloadList, closeModal, show } ) {
    const {
        countries,
        newState,setNewState,
        saveTalent,
        cleanFieldsBeforeClose,
        onChangeHandler,
        onExpChange,
        addSkill,
        removeSkill
    } = useModalNewCandidate();
    const [skillsList, setSkillsList] = useState( [] );
    const [skillName, setSkillName] = useState( '' );
    const [selectedSkills, setSelectedSkills] = useState( [] );

    const getListofSkills = async ( search ) => {
        try {
            search.replace( /\//g, "" );
            const encodedSearch = encodeURIComponent( search );
            const response = await util.axios.get( `catalog/catalog_skills_search/${encodedSearch}` );
            const { error, msg, data } = response.data;
            if ( error ) return util.handleError( msg );
            setSkillsList( data );
        } catch ( e ) {
            util.handleError( e );
        }
    };
    const handleChange = ( e ) => {
        setSkillName( e.target.value );
    };
   
   
    const handleAddSkill = ( skill ) => {
        addSkill( skill );
        setSkillName( '' );
    };
    const handleRemoveSkill = ( index ) => {
        const updatedSkills = [...selectedSkills];
        updatedSkills.splice( index, 1 );
        setSelectedSkills( updatedSkills );
    };
    
    const handleReset = () => {
       
        setSkillName( '' );
        setSkillsList( [] );
        
    };
    const handleCloseModal = () =>{
        cleanFieldsBeforeClose( closeModal );
        setSkillName( '' );
        setSkillsList( [] );
    };
    
    useEffect( () =>{
        if( skillName ) {
            getListofSkills( skillName );
        }
    },[skillName] );
    
    console.log( newState.skills );
    return (
        <div>
            <Modal show={show} onHide={() => cleanFieldsBeforeClose( closeModal )}  >
                <Modal.Header closeButton>
                    <Modal.Title className="mx-auto">Create a new Talent</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row d-flex">
                        {/* <util.RowComponent
                                        c={12}
                                        t={"Name"}
                                        n={"name"}
                                        m={onChangeHandler}
                                        v={newState.name}
                                    /> */}
                        <div className="col-12">
                            <TextField 
                                label={"Name"}
                                size='small'
                                fullWidth
                                variant='outlined'
                                type={"text"}
                                value={newState.name}
                                name={"name"}
                                onChange={onChangeHandler}/>
                        </div>
                        {/* <Tooltip title={newState.country ? newState.country :"No country selected"}>
                            <div className="col-12 mt-3"  >
                                <TextField
                                    select
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    name="country"
                                    label="Country"
                                    value={newState.country ? newState.country : ""}
                                    onChange={onChangeHandler}
                                    InputLabelProps={{ shrink: true }}
                                    SelectProps={{
                                        native: true
                                    }}
                                >
                                    <optgroup label="Most Active">
                                        <option value="USA">USA</option>
                                        <option value="Canada">Canada</option>
                                        <option value="India">India</option>
                                        <option value="Australia">Australia</option>
                                        <option value="Mexico">Mexico</option>
                                        <option value="Japan">Japan</option>
                                        <option value="Germany">Germany</option>
                                        <option value="Puerto Rico">Puerto Rico</option>
                                    </optgroup>
                                    <optgroup label="All Countries">
                                        <option value=""/>
                                        {countries?.map( ( option, i ) => (
                                            <option key={i} value={option.name}>
                                                {option.name}
                                            </option>
                                        ) )}
                                    </optgroup>
                                </TextField>
                            </div>
                        </Tooltip> */}
                        <div className="col-12 mt-3">
                            {/* <label className="labelFormControlMini">Phone</label>
                            <PhoneInput
                                placeholder={"Phone"}
                                country={newState.country}
                                name={"phone_mobile"}
                                value={newState.phone_mobile}
                                onChange={phone => {
                                    let e={ target:{ name:"phone_mobile", value: phone } };
                                    onChangeHandler( e );
                                }}
                            /> */}
                            <CountryAutoFill newState={newState} onChangeHandler={onChangeHandler} setNewState={setNewState}/>
                        </div>
                        {/* <util.RowComponent
                                c={12}
                                t={"Email"}
                                n={"email"}
                                m={onChangeHandler}
                                v={newState.email}
                            /> */}
                        <div className="col-12 mt-1">
                            <TextField 
                                label={"Email"}
                                size='small'
                                fullWidth
                                variant='outlined'
                                type={"Email"}
                                value={newState.email}
                                name={"email"}
                                onChange={onChangeHandler}/>
                        </div>
                        <util.skillTalentForm  removeSkill={removeSkill} onSkillChange={onExpChange} 
                            handleAddSkill={handleAddSkill} handleChange={handleChange} handleRemoveSkill={handleRemoveSkill} handleReset={handleReset}
                            selectedSkills={newState.skills} skillName={skillName} skillsList={skillsList}
                        />
                           
                        {/* <tr>
                                <td colSpan={3}>
                                    <div className="text-center">
                                        {newState.skills.length > 3 ?
                                            ""
                                            :
                                            <button onClick={()=> addSkill()} className="btn btn-sm btn-outline-primary">
                                                <FontAwesomeIcon icon={faPlus} className="mr-2"/>
                                                Add Skill
                                            </button>
                                        }
                                    </div>
                                </td>
                            </tr> */}
                    </div>
                    <Modal.Footer>
                        <Button variant="default" onClick={ handleCloseModal}>Discard</Button>
                        <button className="btn btn-sm btn-primary" onClick={()=> saveTalent( closeModalAndReloadList )} >
                            <FontAwesomeIcon icon={faSave} className="mr-2"/>
                                Save
                        </button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>
            {util.LOADING_SCREEN( newState.loading )}
        </div>
    );
}

export default CreateNewCandidate;




