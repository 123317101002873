import React, { Fragment, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faCheckCircle, faClock, } from "@fortawesome/free-solid-svg-icons";
import util from "../../../utils/miniUtils";
import { Button } from "react-bootstrap";
import UploadFile from "../../../microComponents/UploadFile";
import { connect } from 'react-redux';
import { setCandidateStatus } from "../../../../redux/actions/JobCandidatesAction";
import { CircularProgress } from "@material-ui/core";
const CandidateContext = React.createContext( null );


const Step4 = ( props ) => {
    // This manages the dot in the left to turn it green when this stage is ready
    const [isDisabled, setIsDisabled] = useState( props.candidate.status > 4 );
    const [isLoading, setIsLoading] = useState( false );

    async function finishStep() {
        try {
            setIsLoading( true );
            const candidate = props.candidate;
            const request = await util.axios.post( `${util.url}/job_position/onboarding_documents_cleared/${candidate.position_id}/${candidate.candidate_id}` );
            const { error, msg } =  request.data;
            if ( error ) throw msg;
            util.toast().success( msg );
            // Change dot to green and disabled button
            setIsDisabled( true );
            setIsLoading( false );
            props.setCandidateStatus( { id:candidate.candidate_id, status: 5, talent_desc_updated: "Onboarding Documents Cleared" } );
        } catch ( error ) {
            setIsLoading( false );
            util.handleError( error );
        }
    }

    if ( isLoading ){
        return <div className="text-center"><CircularProgress /></div>;
    }
    return (
        <Fragment>
            <CandidateContext.Provider value={props.candidate}>
                <div className="row">
                    <div className="col-auto text-center flex-column d-none d-sm-flex">
                        <div className="row h-50">
                            <div className="col">&nbsp;</div>
                            <div className="col">&nbsp;</div>
                        </div>
                        <h5 className="m-2">
                            <span
                                className={`badge badge-pill ${
                                    isDisabled ? "bg-success" : "bg-light"
                                } border `}
                            >
              &nbsp;
                            </span>
                        </h5>
                        <div className="row h-50">
                            <div className="col border-right">&nbsp;</div>
                            <div className="col">&nbsp;</div>
                        </div>
                    </div>
                    <div className="col py-2">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title text-muted">Onboarding Documents</h4>
                                <table className="table table-hover customTableTD">
                                    <tbody>
                                        <OnBoardingDocumentRow
                                            title="eJ Application Form"
                                            data={props.data.ejAmericaEmployee}
                                        />
                                        <OnBoardingDocumentRow
                                            title="Offer Letter"
                                            data={props.data.offer_letter}
                                        />
                                        <OnBoardingDocumentRow
                                            title="Contract Agreement"
                                            data={props.data.contractAgreement}
                                        />
                                        <OnBoardingDocumentRow
                                            title="Direct Deposit"
                                            data={props.data.directDeposit}
                                        />
                                        <OnBoardingDocumentRow
                                            title="Background Check"
                                            data={props.data.bgcForm}
                                        />
                                    </tbody>
                                </table>
                                {!isDisabled ? <Button onClick={() => finishStep()}  variant="success" className="float-right">
                                    <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />
                    Onboarding Documents Validated
                                </Button>: "" }

                            </div>
                        </div>
                    </div>
                </div>
            </CandidateContext.Provider>
        </Fragment>
    );
};

function OnBoardingDocumentRow( props ) {
    const [data] = useState( props.data );
    const candidate = React.useContext( CandidateContext );
    /**
   * Status came as 0 or 1
   * 0 = no document added
   * 1 = document added but no validated
   * 2 = document validated & success
   *
   */
    const StatusDocument = ( d ) => {
        if ( d.status === 0 ) {
            return (
                <Fragment>
                    <FontAwesomeIcon className="mr-2 text-warning" icon={faClock} />
                </Fragment>
            );
        }else {
            return (
                <Fragment>
                    <FontAwesomeIcon className="mr-2 text-success" icon={faCheckCircle} />
                </Fragment>
            );
        }
    };

    const settings = {
        candidate_id: candidate.id,
        hasFile: data.status === 1 ? 2 : 0,
        file: data.status === 1 ? data.userFile : "",
        path: `upload/${data.opc}/${candidate.id}/${candidate.position_id}`, // Path have the candidate id and the type of document type
        callback: updateStatus,
        docType: data.opc,
    };

    function updateStatus( newStatus ) {
    }

    return (
        <Fragment>
            <tr>
                <td>
                    <StatusDocument status={data.statusFinal} />
                    <a href={data.file} className="text-decoration-none">
                        {props.title}
                    </a>
                </td>
                <td>
                    <UploadFile config={settings} data={data} />
                </td>
            </tr>
        </Fragment>
    );
}

function mapDispatchToProps( dispatch ) {
    return{
        setCandidateStatus:( data ) => dispatch( setCandidateStatus( data ) ),
    };
}

export default connect( null, mapDispatchToProps )( Step4 );
