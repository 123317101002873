import React, { Fragment, useEffect, useState } from "react";
import util from "../../../../utils/miniUtils";
import InputLabel from "@material-ui/core/InputLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentAlt } from "@fortawesome/free-solid-svg-icons";
import Input from "@material-ui/core/Input";

const user = util.user;

function CommentBox( props ) {
    let info = props.talent;
	
    const [loading, setLoading] = useState( false );
    const [feedBackChange, setFeedBackChange] = useState( false );
    const [commentBase, setCommentBase] = useState( props.comment || "" );
    const [comment, setComment] = useState( props.comment || "" );
	
    useEffect( () => {
        isFeedBackChanged();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [comment] );
	
    function cancelFeedBack() {
        setFeedBackChange( false );
        setComment( commentBase );
    }
	
    function changeHandler( e ) {
        const { value } = e.target;
        setComment( value );
    }
	
    function isFeedBackChanged() {
        if ( comment !== commentBase ){
            setFeedBackChange( true );
        }else{
            setFeedBackChange( false );
        }
    }
	
    async function updateFeedBack() {
        try {
            setLoading( true );
            let data = {
                position_id: info.position_id,
                talent_id: info.candidate_id,
                feedBack: comment,
                role: user.role
            };
            const request = await util.axios.put( `${util.url}/jobs/update_feedback`, data );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            // Update new commentBase
            setCommentBase( comment );
            setFeedBackChange( false );
            util.toast().success( msg );
            setLoading( false );
            props.updateComment( info.candidate_id, comment );
        }catch ( e ) {
            setLoading( false );
            util.handleError( e );
        }
    }
	
    return (
        <Fragment>
            <div className="p-2">
                <InputLabel htmlFor="fbid"><FontAwesomeIcon icon={faCommentAlt} className="mr-2"/> Feedback</InputLabel>
                <Input
                    id="fbid"
                    rows={2}
                    fullWidth
                    placeholder="What do you think about this talent?"
                    multiline={true}
                    label="Write your feedback"
                    value={comment}
                    onChange={changeHandler}
                />
                {util.LOADING_SCREEN( loading )}
            </div>
            {/* Only if the feedback is changed will show the buttons*/}
            {feedBackChange ?
                <div className="float-right p-2">
                    <button onClick={cancelFeedBack} className="btn btn-sm btn-danger mr-3">Cancel</button>
                    <button onClick={updateFeedBack} className="btn btn-sm btn-outline-primary">Save feedback</button>
                </div>:""}
		
        </Fragment>
    );
	
}

export default CommentBox;