import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-ui/core";
import React from "react";

const UserBox = ( { name, title } ) => {
    return (
        <div className="p-1 rounded mt-3">
            <Typography className="p-1">{title}</Typography>
            <div className="d-flex align-items-center border">
                <div className="p-1 bg-light"><FontAwesomeIcon size="1x" icon={faUser} /></div>
                <div className="p-1">
                    <div><b>{name}</b></div>
                </div>
            </div>
        </div>
    );
};
 
export default UserBox;