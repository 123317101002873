import React, { Fragment, useEffect, useRef, useState } from "react";
import { MuiThemeProvider } from "@material-ui/core";
import util from "../../../../utils/miniUtils";
import MaterialTable from "material-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

export default function Documents({ selectedDocuments }) {
    const tableRef = useRef();
    const [state, setState] = useState({
        data: {},
        loading: false,
        showAlert: false,
        document: {},
        documents: [],
    });

    useEffect(() => {
        getDocuments().then(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getDocuments() {
        try {
            const request = await util.axios.get(
                `${util.url}/catalog_documents/list`
            );
            let { error, msg, documents } = request.data;
            documents = documents.filter((d) => d.status === 1);
            setState({ ...state, documents: documents });
            if (error) throw msg;
        } catch (e) {
            util.handleError(e);
        }
    }

    return (
        <Fragment>
            <MuiThemeProvider theme={util.defaultTableTheme}>
                <MaterialTable
                    tableRef={tableRef}
                    columns={[
                        {
                            field: "title",
                            title: "Document",
                            render: (d) =>
                                d.filename ? (
                                    <a href={`${util.url}/documents/${d.filename}`} target="new">
                                        <FontAwesomeIcon icon={faDownload} className="mr-2" />
                                        {d.title}
                                    </a>
                                ) : (
                                    ""
                                ),
                        },
                    ]}
                    data={state.documents}
                    options={{
                        pageSize: 10,
                        tableLayout: "auto",
                        showFirstLastPageButtons: false,
                        pageSizeOptions: [],
                        emptyRowsWhenPaging: false,
                        toolbar: false,
                        padding: "dense",
                        paginationType: "stepped",
                        selection: true,
                    }}
                    onSelectionChange={(data) => selectedDocuments(data)}
                />
            </MuiThemeProvider>
            {util.LOADING_SCREEN(state.loading)}
        </Fragment>
    );
}
